// @flow
import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  byFactory?: any,
  byLine?: any,
  byLines: any[],
  defSort?: any[],
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function ErrorRecList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  // const app = useSelector((state) => state.app);
  const {t} = useTranslation();
  const [errorRecsTblData, setErrorRecsTblData] = useState([]);
  const [errorRecsTblStart, setErrorRecsTblStart] = useState(0);
  const [errorRecsTblLimit] = useState(10);
  const [errorRecsTblNbRows, setErrorRecsTblNbRows] = useState(0);
  const [selErrorRec, setSelErrorRec] = useState();
  const [selErrorRecs, setSelErrorRecs] = useState([]);
  const [sortMeta, setSortMeta] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);

  const {
    byFactory,
    byLine,
    byLines,
    defSort,
    reload,
    selection,
    header,
    command,
  } = props;
  const selMode = props.selectionMode || 'single';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    if (defSort && defSort.length > 0) {
      setSortMeta([...defSort]);
      setSortField(defSort[0].field);
      setSortOrder(defSort[0].order);
    }
  }, [defSort]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelErrorRec({...selection});
      } else {
        setSelErrorRecs([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelErrorRec(null);
      } else {
        setSelErrorRecs([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    // //console.log.*$
    // //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let filters = {};
    let params = {};
    if (byFactory) {
      filters = {
        ...filters,
        byFactory: 'obj.factoryId = :factoryId',
      };
      params = {
        ...params,
        factoryId: {
          type: 'long',
          value: byFactory.id,
        },
      };
    }

    if (byLine) {
      filters = {
        ...filters,
        byLine: 'obj.lineId = :lineId',
      };
      params = {
        ...params,
        lineId: {
          type: 'long',
          value: byLine.id,
        },
      };
    }

    if (byLines && byLines.length > 0) {
      filters = {
        ...filters,
        byLines: 'obj.lineId in (:lineIds)',
      };
      let lineIds = byLines.map((it) => {
        return {
          type: 'long',
          value: it.id,
        };
      });
      params = {
        ...params,
        lineIds,
      };
    }

    let start = errorRecsTblStart;
    let limit = errorRecsTblLimit;

    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });
    // //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);

    let joins = [];

    api
      .getErrorRecs({joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        // //console.log.*$
        const {list, factoryMap, lineMap, operatorMap, shiftMap, errorMap} =
          data;
        list.forEach((rec) => {
          if (rec.factoryId) {
            rec['factory'] = factoryMap[rec.factoryId];
          }
          if (rec.lineId) {
            rec['line'] = lineMap[rec.lineId];
          }
          if (rec.shiftId) {
            rec['shift'] = shiftMap[rec.shiftId];
          }
          if (rec.operatorId) {
            rec['operator'] = operatorMap[rec.operatorId];
          }
          if (rec.errorId) {
            rec['error'] = errorMap[rec.errorId];
          }
        });

        setErrorRecsTblData([...list]);
        setErrorRecsTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, [
    byFactory,
    byLine,
    byLines,
    errorRecsTblStart,
    errorRecsTblLimit,
    reload,
    sortMeta,
  ]);

  const _onErrorRecSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelErrorRec(evt.value);
    } else {
      setSelErrorRecs(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    // //console.log.*$
    setErrorRecsTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };

  const _renderFactoryCol: TblColRender = (row: any, col: any) => {
    let {factory} = row;
    return factory ? `${factory.name}` : null;
  };

  const _renderLineCol: TblColRender = (row: any, col: any) => {
    let {line} = row;
    return line ? `${line.name}` : null;
  };

  const _renderRecDateCol: TblColRender = (row: any, col: any) => {
    let {recDate} = row;
    return recDate ? Utils.reFmtDate(recDate, 'DD/MM/YY') : null;
  };

  const _renderShiftCol: TblColRender = (row: any, col: any) => {
    let {shift} = row;
    return shift ? `${shift.name}` : null;
  };

  const _renderOperatorCol: TblColRender = (row: any, col: any) => {
    let {operator} = row;
    return operator ? `${operator.code} :: ${operator.name}` : null;
  };

  const _renderErrorCol: TblColRender = (row: any, col: any) => {
    let {error} = row;
    return error ? error.code : null;
  };

  const _renderCrCol: TblColRender = (row: any, col: any) => {
    let {createdAt} = row;
    return createdAt ? Utils.reFmtDate(createdAt, 'DD/MM/YY HH:mm') : null;
  };

  const _renderErrRateCol: TblColRender = (row: any, col: any) => {
    let {errRate} = row;
    return errRate ? Utils.round(errRate, 6) : null;
  };

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header}
          value={errorRecsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={errorRecsTblLimit}
          first={errorRecsTblStart}
          totalRecords={errorRecsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selErrorRec}
          onSelectionChange={_onErrorRecSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            body={_renderRecDateCol}
            header="Date"
            headerStyle={{width: 80}}
            sortable
            sortField="obj.recDate"
          />

          <Column
            field="nbMins"
            header="Down Min(s)"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 60}}
          />

          <Column
            body={_renderErrRateCol}
            header="Rate"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 90}}
          />

          <Column
            body={_renderErrorCol}
            header="Error"
            headerStyle={{width: 80}}
          />

          <Column
            field="nbHours"
            header="Hour(s)"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 60}}
          />

          <Column
            body={_renderOperatorCol}
            header="Operator"
            headerStyle={{width: 200}}
          />

          <Column
            body={_renderShiftCol}
            header="Shift"
            headerStyle={{width: 100}}
          />

          <Column
            body={_renderLineCol}
            header="Line"
            headerStyle={{width: 200}}
          />

          <Column
            body={_renderFactoryCol}
            header="Factory"
            headerStyle={{width: 200}}
          />

          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />

          <Column
            body={_renderCrCol}
            header="Created On"
            headerStyle={{width: 120}}
          />

          <Column
            field="createdBy"
            header="Created By"
            headerStyle={{width: 100}}
          />

          <Column
            field="id"
            header="#"
            headerStyle={{width: 60}}
            headerClassName="text-end"
            bodyClassName="text-end"
          />
        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={errorRecsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={errorRecsTblLimit}
          first={errorRecsTblStart}
          totalRecords={errorRecsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selErrorRecs}
          onSelectionChange={_onErrorRecSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            selectionMode="multiple"
            headerStyle={{width: 50}}
            headerClassName="text-left"
            bodyClassName="text-left"></Column>
          <Column
            body={_renderFactoryCol}
            header="Factory"
            headerStyle={{width: 200}}
            sortable
            sortField="factory.name"
          />
          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
        </DataTable>
      )}
    </>
  );
}
