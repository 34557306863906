// @flow
import React, {useState, useEffect, useRef, useCallback,useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
import {Subject} from 'rxjs';
//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';
import {AuthUtils} from '../../service/AuthUtils';
import {AuthConst} from '../../service/AppConstant';
import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
type Props = {
  byFactory?: any,
  byYears?: any[],
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  byAudit?: any,
  onSelect?: (evt: any) => void,
};

export function PfaAuditList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const {t} = useTranslation();
  const [leanAuditsTblData, setLeanAuditsTblData] = useState([]);
  const [leanAuditsTblStart, setLeanAuditsTblStart] = useState(0);
  const [leanAuditsTblLimit] = useState(10);
  const [leanAuditsTblNbRows, setLeanAuditsTblNbRows] = useState(0);
  const [selLeanAudit, setSelLeanAudit] = useState();
  const [selLeanAudits, setSelLeanAudits] = useState([]);
  const [sortMeta, setSortMeta] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);

  const {byFactory, byYears, reload, selection, header, command,byAudit} = props;
  const selMode = props.selectionMode || 'single';
  const {search} = app || '';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const hasNbLv3Role = useMemo(() =>
    AuthUtils.getAuthLevel({auth}) <= AuthConst.AUTH_LVL_NB &&
    AuthUtils.isAuth({auth, roles: ['NB_LV_3']}),
    [auth]
  );

  console.log('hasNbLv3Role:', hasNbLv3Role);

  const _fetchData = useCallback(async (options) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let {
      byFactory,
      byYears,
      search,
      byAudit,
      leanAuditsTblStart,
      leanAuditsTblLimit,
      sortMeta,
    } = options;
    let start = leanAuditsTblStart;
    let limit = leanAuditsTblLimit;
    let joins = [];
    let params = {};
    let filters = {};

    if (byFactory && byFactory.id) {
      filters = {
        ...filters,
        byFactory: 'obj.factory.id = :factoryId',
      };
      params = {
        ...params,
        factoryId: {
          type: 'long',
          value: byFactory.id,
        },
      };
    }

    if (byAudit && byAudit.id) {
        filters = {
            ...filters,
            byAudit: 'obj.id = :auditId',
            };
            params = {
            ...params,
            auditId: {
                type: 'long',
                value: byAudit.id,
            },
        };
    }

    console.log('byAudit:', byAudit);
    byYears = byYears || [];
    byYears = byYears.map((it) => Number(it)).filter((it) => !isNaN(it));

    if (byYears.length > 0) {
      filters = {
        ...filters,
        yearsIn: 'obj.audYear in (:audYears)',
      };
      params = {
        ...params,
        audYears: [...byYears],
      };
    }

    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });

    api
      .getPfaAudits({search, joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {

          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        setLeanAuditsTblData([...data.list]);
        setLeanAuditsTblNbRows(data.count);
      })
      .catch((err) => {

        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      subj.complete();
    };
  }, [_fetchData]);


  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelLeanAudit({...selection});
      } else {
        setSelLeanAudits([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelLeanAudit(null);
      } else {
        setSelLeanAudits([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      byFactory,
      byYears,
      byAudit,
      search,
      leanAuditsTblStart,
      leanAuditsTblLimit,
      sortMeta,
    });
  }, [
    byFactory,
    byYears,
    search,
    byAudit,
    leanAuditsTblStart,
    leanAuditsTblLimit,
    reload,
    sortMeta,
  ]);

  const _onLeanAuditSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelLeanAudit(evt.value);
    } else {
      setSelLeanAudits(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {

    setLeanAuditsTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];

    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };


  const _renderFactoryCol: TblColRender = (row: any, col: any) => {
    let {factory} = row;
    return factory ? `${factory.code} :: ${factory.name}` : null;
  };

  const _renderAudDateCol: TblColRender = (row: any, col: any) => {
    let {audDate} = row;
    return audDate ? `${Utils.reFmtDate(audDate, 'DD/MM/YYYY')}` : null;
  };

  const _onSubmitToNbLvl2 = (row: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let reqData = {
        data:{
            auditId: row.id
        }
    };
     api.submitPfaReportToNbLvl2(reqData).then((resp) => resp.data)
      .then((resp) => {
       let {errors} = resp;
       if (errors.length > 0) {
         UIUtils.showError({errors, toast: toastRef.current});
         return;
       }
       UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
     })

  }
  const _onSubmitToNbLvl3 = (row: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let reqData = {
        data:{
            auditId: row.id
        }
    };
     api.submitPfaReportToNbLvl3(reqData).then((resp) => resp.data)
      .then((resp) => {
       let {errors} = resp;
       if (errors.length > 0) {
         UIUtils.showError({errors, toast: toastRef.current});
         return;
       }
       UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
     })

  }

  const _onApproveAudit = (row: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let reqData = {
        data:{
            auditId: row.id
        }
    };
     api.aprovePfaAudit(reqData).then((resp) => resp.data)
      .then((resp) => {
       let {errors} = resp;
       if (errors.length > 0) {
         UIUtils.showError({errors, toast: toastRef.current});
       }
       UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
     })
  }

  const _onRejectAudit = (row: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let reqData = {
        data:{
            auditId: row.id
        }
    };
     api.rejectPfaAudit(reqData).then((resp) => resp.data)
      .then((resp) => {
       let {errors} = resp;
       if (errors.length > 0) {
         UIUtils.showError({errors, toast: toastRef.current});
       }
       UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
     })
  }
const _renderSubmitCol: TblColRender = (row, col) => {
   if(AuthUtils.getAuthLevel({auth}) < AuthConst.AUTH_LVL_NB ||
      AuthUtils.isAuth({auth, roles: [ 'NB_ADMIN',]})) {
         if(AuthUtils.isAuth({auth, roles: ['NB_LVL_1']})) {
            return <Button label="Submit to lv2" onClick={()=>_onSubmitToNbLvl2(row)} />;
         }
         if(AuthUtils.isAuth({auth, roles: ['NB_LVL_2']})) {
            return <Button label="Submit to lv3" onClick={()=> _onSubmitToNbLvl3(row)}  />;
         }
    }
  }

  const _renderApproveCol: TblColRender = (row, col) => {
    if(AuthUtils.getAuthLevel({auth}) <= AuthConst.AUTH_LVL_NB ||
       AuthUtils.isAuth({auth, roles: [ 'NB_ADMIN']})) {
          if(AuthUtils.isAuth({auth, roles: ['NB_LVL_3']})) {
             return <Button label="Approve" onClick={()=> _onApproveAudit(row)}  />;
          }
     }
   }
   const _renderRejectCol: TblColRender = (row, col) => {
    if(AuthUtils.getAuthLevel({auth}) <= AuthConst.AUTH_LVL_NB ||
       AuthUtils.isAuth({auth, roles: [ 'NB_ADMIN']})) {
          if(AuthUtils.isAuth({auth, roles: ['NB_LVL_3']})) {
             return <Button label="Reject" onClick={()=> _onRejectAudit(row)} />;
          }
     }
   }

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={leanAuditsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={leanAuditsTblLimit}
          first={leanAuditsTblStart}
          totalRecords={leanAuditsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selLeanAudit}
          onSelectionChange={_onLeanAuditSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
           <Column
            field="name"
            header="Name"
            headerStyle={{width: 200}}
            sortable
            sortField="obj.name"
          />
          <Column
            body={_renderFactoryCol}
            header="Factory"
            headerStyle={{width: 250}}
            sortable
            sortField="factory.name"
          />
          <Column
            body={_renderAudDateCol}
            header="Aud. Date"
            headerStyle={{width: 250}}
            sortable
            sortField="obj.audDate"
          />
          <Column
            body={_renderSubmitCol}
            header="Submit"
            headerStyle={{width: '200px'}}
            // headerClassName="text-right"
            // bodyClassName="text-right"
          />


          <Column
              body={_renderApproveCol}
              header="Approve"
              headerStyle={{width: '200px'}}
            />
            <Column
              body={_renderRejectCol}
              header="Reject"
              headerStyle={{width: '200px'}}
            />


           <Column
              field="status"
              header="Status"
              headerStyle={{width: '200px'}}
            />
          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={leanAuditsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={leanAuditsTblLimit}
          first={leanAuditsTblStart}
          totalRecords={leanAuditsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selLeanAudits}
          onSelectionChange={_onLeanAuditSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
           <Column
            field="name"
            header="Name"
            headerStyle={{width: 200}}
            sortable
            sortField="obj.name"
          />
          <Column
            body={_renderFactoryCol}
            header="Factory"
            headerStyle={{width: 250}}
            sortable
            sortField="factory.name"
          />
          <Column
            body={_renderAudDateCol}
            header="Aud. Date"
            headerStyle={{width: 250}}
            sortable
            sortField="obj.audDate"
          />
          <Column
            body={_renderSubmitCol}
            header="Submit"
            headerStyle={{width: '200px'}}
            // headerClassName="text-right"
            // bodyClassName="text-right"
          />


          <Column
              body={_renderApproveCol}
              header="Approve"
              headerStyle={{width: '200px'}}
            />
            <Column
              body={_renderRejectCol}
              header="Reject"
              headerStyle={{width: '200px'}}
            />


           <Column
              field="status"
              header="Status"
              headerStyle={{width: '200px'}}
            />
          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
        </DataTable>
      )}
    </>
  );
}
