// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Subject} from 'rxjs';
//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  byFactory?: any,
  byYears?: any[],
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function LeanReportList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  // const {t} = useTranslation();

  const {byFactory, byYears, reload, selection, header, command} = props;

  const [mltRoadmapsTblData, setLeanReportsTblData] = useState([]);
  const [mltRoadmapsTblStart, setLeanReportsTblStart] = useState(0);
  const [mltRoadmapsTblLimit] = useState(10);
  const [mltRoadmapsTblNbRows, setLeanReportsTblNbRows] = useState(0);
  const [selLeanReport, setSelLeanReport] = useState();
  const [selLeanReports, setSelLeanReports] = useState([]);
  const [sortMeta, setSortMeta] = useState([
    {
      field: 'obj.audYear',
      order: -1,
    },
    {
      field: 'obj.audMonth',
      order: -1,
    },
  ]);
  const [sortField, setSortField] = useState('obj.audYear');
  const [sortOrder, setSortOrder] = useState(-1);

  const selMode = props.selectionMode || 'single';
  const {search} = app || '';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  const _fetchData = useCallback(async (options) => {
    // //console.log.*$
    // //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let {
      byFactory,
      byYears,
      search,
      mltRoadmapsTblStart,
      mltRoadmapsTblLimit,
      sortMeta,
    } = options;

    let start = mltRoadmapsTblStart;
    let limit = mltRoadmapsTblLimit;
    let joins = [];
    let filters = {};
    let params = {};

    if (byFactory && byFactory.id) {
      filters = {
        ...filters,
        byFactory: 'obj.factory.id = :facId',
      };
      params = {
        ...params,
        facId: {
          type: 'long',
          value: byFactory.id,
        },
      };
    }

    byYears = byYears || [];
    if (byYears.length > 0) {
      filters = {
        ...filters,
        byYears: 'obj.audYear in (:years)',
      };
      params = {
        ...params,
        years: byYears,
      };
    }

    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });
    //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);

    api
      .getLeanReports({search, joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        // //console.log.*$
        setLeanReportsTblData([...data.list]);
        //console.log.*$
        setLeanReportsTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    let subj = new Subject();
    //console.log.*$
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      //console.log.*$
      subj.complete();
    };
  }, [_fetchData]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelLeanReport({...selection});
      } else {
        setSelLeanReports([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelLeanReport(null);
      } else {
        setSelLeanReports([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    let years = (byYears || []).map((it) => Number(it) || 0);
    fetchDataEvtSrc.next({
      byFactory,
      byYears: years,
      search,
      mltRoadmapsTblStart,
      mltRoadmapsTblLimit,
      sortMeta,
    });
  }, [
    byFactory,
    byYears,
    search,
    mltRoadmapsTblStart,
    mltRoadmapsTblLimit,
    reload,
    sortMeta,
  ]);

  const _onLeanReportSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelLeanReport(evt.value);
    } else {
      setSelLeanReports(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    // //console.log.*$
    setLeanReportsTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };

  const _renderFactoryCol: TblColRender = (row, col) => {
    let {factory} = row;
    return factory ? `${factory.code}` : null;
  };

  const _renderMonthCol: TblColRender = (row, col) => {
    let {audDate} = row;
    return audDate ? `${Utils.reFmtDate(audDate, 'MM/YYYY')}` : null;
  };

  const _renderManPowerCol: TblColRender = (row, col) => {
    let {manPower} = row;
    return Utils.fmtDecimal(manPower, 0);
  };

  const _renderOtManHourCol: TblColRender = (row, col) => {
    let {otManHour} = row;
    return Utils.fmtDecimal(otManHour, 0);
  };

  const _renderOtRateCol: TblColRender = (row, col) => {
    let {otRate} = row;
    otRate = otRate || 0;
    return otRate > 0 ? `${Utils.fmtDecimal(otRate * 100, 2)}%` : 0;
  };

  const _renderDiManHourCol: TblColRender = (row, col) => {
    let {diManHour} = row;
    return Utils.fmtDecimal(diManHour, 0);
  };

  const _renderActSiCol: TblColRender = (row, col) => {
    let {actSi} = row;
    return Utils.fmtDecimal(actSi, 0);
  };

  const _renderRccpCol: TblColRender = (row, col) => {
    let {rccp} = row;
    return Utils.fmtDecimal(rccp, 0);
  };

  const _renderActLoPlanCol: TblColRender = (row, col) => {
    let {actLoPlan} = row;
    return Utils.fmtDecimal(actLoPlan, 0);
  };

  const _renderNetCapaCol: TblColRender = (row, col) => {
    let {netCapa} = row;
    return Utils.fmtDecimal(netCapa, 0);
  };

  const _renderFilRateCol: TblColRender = (row, col) => {
    let {filRate} = row;
    filRate = (filRate || 0) * 100;
    return `${Utils.fmtDecimal(filRate, 2)}%`;
  };

  const _renderActPphCol: TblColRender = (row, col) => {
    let {actPph} = row;
    return Utils.fmtDecimal(actPph, 2);
  };

  const _renderTarPphCol: TblColRender = (row, col) => {
    let {tarPph} = row;
    return Utils.fmtDecimal(tarPph, 2);
  };

  const _renderPphAchiCol: TblColRender = (row, col) => {
    let {pphAchi} = row;
    let v = pphAchi * 100;
    return `${Utils.fmtDecimal(v, 2)}%`;
  };

  const _renderActMltCol: TblColRender = (row, col) => {
    let {actMlt} = row;
    return Utils.fmtDecimal(actMlt, 0);
  };

  const _renderTarMltCol: TblColRender = (row, col) => {
    let {tarMlt} = row;
    return Utils.fmtDecimal(tarMlt, 0);
  };

  const _renderMltAchiCol: TblColRender = (row, col) => {
    let {mltAchi} = row;
    mltAchi = mltAchi || 0;
    return mltAchi > 0 ? `${Utils.fmtDecimal(mltAchi * 100, 2)}%` : 0;
  };

  const _renderActCssMltCol: TblColRender = (row, col) => {
    let {actCssMlt} = row;
    return Utils.fmtDecimal(actCssMlt, 0);
  };

  const _renderTarCssMltCol: TblColRender = (row, col) => {
    let {tarCssMlt} = row;
    return Utils.fmtDecimal(tarCssMlt, 0);
  };

  const _renderActMinlCol: TblColRender = (row, col) => {
    let {actMinl} = row;
    actMinl = actMinl || 0;
    return actMinl > 0 ? `${Utils.fmtDecimal(actMinl * 100, 2)}%` : 0;
  };

  const _renderTarMinlCol: TblColRender = (row, col) => {
    let {tarMinl} = row;
    tarMinl = tarMinl || 0;
    return tarMinl > 0 ? `${Utils.fmtDecimal(tarMinl * 100, 2)}%` : 0;
  };

  const _renderMinlAchiCol: TblColRender = (row, col) => {
    let {minlAchi} = row;
    minlAchi = minlAchi || 0;
    return minlAchi > 0 ? `${Utils.fmtDecimal(minlAchi * 100, 2)}%` : 0;
  };

  const _renderActScoreCol: TblColRender = (row, col) => {
    let {actScore} = row;
    return Utils.fmtDecimal(actScore, 2);
  };

  const _renderTarScoreCol: TblColRender = (row, col) => {
    let {tarScore} = row;
    return Utils.fmtDecimal(tarScore, 2);
  };

  const _renderScoreAchiCol: TblColRender = (row, col) => {
    let {scoreAchi} = row;
    scoreAchi = scoreAchi || 0;
    return scoreAchi > 0 ? `${Utils.fmtDecimal(scoreAchi * 100, 2)}%` : 0;
  };

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header}
          value={mltRoadmapsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={mltRoadmapsTblLimit}
          first={mltRoadmapsTblStart}
          totalRecords={mltRoadmapsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selLeanReport}
          onSelectionChange={_onLeanReportSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column field="name" header="Name" headerStyle={{width: 200}} />
          <Column
            body={_renderFactoryCol}
            header="Factory"
            headerStyle={{width: 100}}
            sortable
            sortField="obj.factory.code"
          />
          <Column
            body={_renderMonthCol}
            header="Month"
            headerStyle={{width: 90}}
            sortable
            sortField="obj.audDate"
          />
          <Column
            body={_renderManPowerCol}
            header="Man Power"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            field="workDay"
            header="Work Day"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderOtManHourCol}
            header="OT Hours"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderOtRateCol}
            header="OT %"
            headerStyle={{width: 70}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderDiManHourCol}
            header="DI. Hour"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderActSiCol}
            header="Act/Plan SI"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderRccpCol}
            header="RCCP"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderActLoPlanCol}
            header="Act Load Plan"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderNetCapaCol}
            header="Net Capa"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderFilRateCol}
            header="Fil Rate"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderActPphCol}
            header="Act PPH"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderTarPphCol}
            header="Target PPH"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderPphAchiCol}
            header="PPH Achi"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderActMltCol}
            header="Act. MLT"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderTarMltCol}
            header="Target MLT"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderMltAchiCol}
            header="MLT Achi."
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderActCssMltCol}
            header="Act. Css MLT"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderTarCssMltCol}
            header="Target Css MLT"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderActMinlCol}
            header="Act Mini Line"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderTarMinlCol}
            header="Target Mini Line"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderMinlAchiCol}
            header="Mini Line Achi."
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderActScoreCol}
            header="Act Score"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderTarScoreCol}
            header="Target Score"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />

          <Column
            field="createdAt"
            header="Created At"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            field="updatedAt"
            header="Updated At"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />

          <Column
            field="updatedBy"
            header="Updated By"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderScoreAchiCol}
            header="Score Achi"
            headerStyle={{width: 100}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header}
          value={mltRoadmapsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={mltRoadmapsTblLimit}
          first={mltRoadmapsTblStart}
          totalRecords={mltRoadmapsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selLeanReports}
          onSelectionChange={_onLeanReportSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            selectionMode="multiple"
            headerStyle={{width: 50}}
            headerClassName="text-left"
            bodyClassName="text-left"></Column>
          <Column
            field="name"
            header="Name"
            headerStyle={{width: 350}}
            sortable
            sortField="obj.name"
          />

          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
        </DataTable>
      )}
    </>
  );
}
