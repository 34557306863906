// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';
//$FlowFixMe[cannot-resolve-module];
import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
//import {AuthUtils} from '../../service/AuthUtils';
// import {AuthConst} from '../../service/AppConstant';
//$FlowFixMe[cannot-resolve-module];
import {Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {FactoryFilter} from '../common/FactoryFilter';
import {PphReport} from './PphReport';
import {PphWeightedReport} from './PphWeightedReport';
import {ByScopeProdKpi} from '../kpi/ByScopeProdKpi';
import {TablePphOfMonth} from './TablePphOfMonth';
import {PphWeightedByMultiFactory} from './PphWeightedByMultiFactory';
import {SourcingMetricsReport} from './SourcingMetricsReport';
type Props = {};

const defFilter = {
  factory: null,
  month: null,
  years: [2022],
};

export function LeanMetric(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const [filter, setFilter] = useState({...defFilter});
  const [tabInd, setTabInd] = useState(0);
  const [showFactory, setShowFactory] = useState<boolean>(false);
  const [showMonth, setShowMonth] = useState<boolean>(true);
  //  const fetchDataEvtRef = useRef();
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let initFil = {};
    let di: any = Utils.getDateInfo() || {};
    initFil = {
      ...initFil,
      month: di.month,
      years: [di.year],
    };
    setFilter(initFil);
  }, [auth]);

  const _onFilterAction: EvtHandler = (evt: any) => {
    let {type, value} = evt;
    if (type === 'filterChanged') {
      setFilter({...value});
    }
  };

  const changeTab = (e) => {
    if (e === 4) {
      setShowFactory(true);
      setTabInd(e);
    } else if (e === 5) {
      setShowFactory(false);
      setTabInd(e);
      setShowMonth(false);
    } else {
      setTabInd(e);
      setShowFactory(false);
      setShowMonth(true);
    }
  };

  return (
    <>
      <Toast ref={toastRef} />
      <div className="py-2">
        <FactoryFilter
          filter={filter}
          showYears={true}
          showMonth={showMonth}
          multiFactories={showFactory}
          showFactory={showFactory}
          onAction={_onFilterAction}
        />
      </div>

      <TabView activeIndex={tabInd} onTabChange={(e) => changeTab(e.index)}>
        <TabPanel
          header="PPH Roll 6 Months"
          onTabChange={(e) => setTabInd(e.index)}>
          <PphReport filter={filter} />
        </TabPanel>

        <TabPanel
          header="PPH Weighted Report"
          onTabChange={(e) => setTabInd(e.index)}>
          <PphWeightedReport filter={filter} years={filter.years} />
        </TabPanel>

        <TabPanel
          header="End Year Roadmap Target"
          onTabChange={(e) => setTabInd(e.index)}>
          <ByScopeProdKpi scope="factory" kpiType="PPH_YTD" />
        </TabPanel>

        <TabPanel
          header="Table PPH of Month"
          onTabChange={(e) => setTabInd(e.index)}>
          <TablePphOfMonth filter={filter} />
        </TabPanel>

        <TabPanel
          header="PPH Weighted Report Multi Factory"
          onTabChange={(e) => changeTab(e)}>
          <PphWeightedByMultiFactory filter={filter} />
        </TabPanel>

        <TabPanel
          header="Sourcing Metrics Report"
          onTabChange={(e) => changeTab(e)}>
          <SourcingMetricsReport filter={filter} />
        </TabPanel>
      </TabView>
    </>
  );
}
