// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';
//$FlowFixMe[cannot-resolve-module]
import {ColumnGroup} from 'primereact/columngroup';
//$FlowFixMe[cannot-resolve-module];
import {Row} from 'primereact/row';
import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';

//$FlowFixMe[cannot-resolve-module];
import {UIUtils, Utils} from '../../service/Utils';
import type {TblColRender} from '../types';
import {AdminApi} from '../../service/AdminApi';
import {FactoryFilter} from '../common/FactoryFilter';
// import {LeanAuditFilter} from './LeanAuditFilter';
//$FlowFixMe[cannot-resolve-module];
import {ProgressBar} from 'primereact/progressbar';

type Props = {};
const defFilter = {
  factory: null,
  month: null,
  years: [2022],
};

export function ETRReport(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const [etrRecsTblData, setEtrRecsTblData] = useState([]);
  const [etrRecsTblLimit, setetrRecsTblLimit] = useState(15);
  const [ertRecsTblNbRows, setErtRecsTblNbRows] = useState(0);
  const [etrRecsTblStart, setEtrRecsTblStart] = useState(0);
  // const [reload, setReload] = useState(false);
  const [filter, setFilter] = useState({...defFilter});
  const [tabInd, setTabInd] = useState(0);
  //const [initFilter, setInitFilter] = useState({});
  //  const fetchDataEvtRef = useRef();
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  // const selPphRoadmapRef = useRef();
  // useEffect(() => {
  //   selPphRoadmapRef.current = selPphRoadmap;
  // }, [selPphRoadmap]);
  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let initFil = {};
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      initFil['factory'] = factories[0];
    }
    let di: any = Utils.getDateInfo() || {};
    initFil = {
      ...initFil,
      month: di.month,
      years: [di.year],
    };
    setFilter(initFil);
  }, [auth]);

  const _onFilterAction: EvtHandler = (evt: any) => {
    let {type, value} = evt;
    if (type === 'filterChanged') {
      setFilter({...value});
    }
  };

  useEffect(() => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let {factory} = filter;
    if (factory && factory.id) {
      let {month, years} = filter;
      factory = factory || null;
      month = month || 1;
      let year = years[years.length - 1] || '';
      //console.log.*$

      let groupBys = ['obj.month', 'model.code'];
      let joins = [
        {
          type: '',
          expr: 'MachModel',
          alias: 'model',
        },
      ];
      let filters = {
        byFactory: 'obj.factoryId = :factoryId',
        byYear: 'obj.year = :year',
        byMonth: 'obj.month = :month',
        jMd: 'obj.modelId = model.id',
        byFlag: 'model.flag > -1',
      };
      let factoryId = {
        type: 'long',
        value: factory.id,
      };

      let params = {
        factoryId,
        year: Number(year),
        month: Number(month),
      };

      let fields = [
        'model.code',
        'model.style',
        'model.material',
        'avg(obj.pureie)',
        'sum(obj.nbProds)',
        'sum(obj.nbHours)',
      ];

      let start = etrRecsTblStart;
      let limit = etrRecsTblLimit;

      let recs = [];
      let sorts = ['-sum(obj.nbProds)'];
      api
        .fetchProdMdRecData({
          fields,
          joins,
          groupBys,
          filters,
          params,
          start,
          limit,
          sorts,
        })
        .then((resp) => resp.data)
        .then((resp) => {
          let {errors, data} = resp;
          if (errors.length > 0) {
            //console.log.*$
            UIUtils.showError({errors, toast: toastRef.current});
            return [];
          }
          //console.log.*$

          let sumProd = Number(resp.data.SUMPRODUCT);
          //console.log.*$
          recs = [...data.list];
          let sum = 0;
          recs.forEach((dt) => {
            sum += dt[4];
          });
          let dataTbl = [];

          recs.forEach((dt) => {
            let pph = dt[4] / dt[5];
            let obj = {
              upperid: dt[0],
              style: dt[1],
              material: dt[2],
              pureie: dt[3],
              actualpph: pph,
              effiiciency: dt[3] !== 0 ? pph / dt[3] : 0,
              volumes: dt[4],
              prog: dt[4] / sumProd,
              volumesper: dt[4] / sum,
              pureiepph: dt[3] * (dt[4] / sum),
              actualPphWei: pph * (dt[4] / sum),
            };
            dataTbl.push(obj);
          });

          setErtRecsTblNbRows(data.count);
          setEtrRecsTblData(dataTbl);
        })
        .catch((err) => {
          //console.log.*$
          UIUtils.showError({error: err, toast: toastRef.current});
          return [];
        });
    }
    ////console.log.*$
  }, [filter, etrRecsTblStart, etrRecsTblLimit]);

  let headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Upper ID" style={{textAlign: 'center'}} rowSpan={3} />
        <Column header="Style" style={{textAlign: 'center'}} rowSpan={3} />
        <Column header="Material" style={{textAlign: 'center'}} rowSpan={3} />
      </Row>
      <Row>
        <Column
          header=" Analysis  of style"
          colSpan={3}
          style={{textAlign: 'center'}}
        />
        <Column
          header="Analysis of orders"
          colSpan={4}
          style={{textAlign: 'center'}}
        />
      </Row>
      <Row>
        <Column header="Pure IE PPH" style={{textAlign: 'center'}}></Column>
        <Column header="Actual PPH" style={{textAlign: 'center'}}></Column>
        <Column
          header="Effiiciency Level (%)"
          style={{textAlign: 'center'}}></Column>

        <Column header="Volumes (pairs)" style={{textAlign: 'center'}}></Column>
        <Column
          header="Volumes Percentage %"
          style={{textAlign: 'center'}}></Column>
        <Column
          header="Pure IE  PPH (Weighted% *Pure PPH)"
          style={{textAlign: 'center'}}></Column>
        <Column
          header="Actual PPH(Weighted% *Actual PPH)"
          style={{textAlign: 'center'}}></Column>
      </Row>
    </ColumnGroup>
  );

  const _onInvsPaging = (evt: any) => {
    setetrRecsTblLimit(evt.rows);
    setEtrRecsTblStart(evt.first);
  };

  const _renderPureIeCol: TblColRender = (row: any, col: any) => {
    let {pureie} = row;
    return pureie ? Utils.round(pureie) : null;
  };

  const _renderActualPphCol: TblColRender = (row: any, col: any) => {
    let {actualpph} = row;
    return actualpph ? Utils.round(actualpph, 2) : null;
  };

  const _renderEffiCol: TblColRender = (row: any, col: any) => {
    let {effiiciency} = row;

    return effiiciency
      ? Number(Number(effiiciency) * 100).toFixed(0) + '%'
      : null;
  };

  const _renderVolPerCol: TblColRender = (row: any, col: any) => {
    let {volumesper} = row;

    return volumesper
      ? Number(Number(volumesper) * 100).toFixed(2) + '%'
      : null;
  };

  const _renderPureIePphCol: TblColRender = (row: any, col: any) => {
    let {pureiepph} = row;

    return pureiepph ? Number(pureiepph).toFixed(2) : null;
  };
  const _renderActWeightCol: TblColRender = (row: any, col: any) => {
    let {actualPphWei} = row;

    return actualPphWei ? Number(actualPphWei).toFixed(2) : null;
  };

  const _renderVolumns: TblColRender = (row: any, col: any) => {
    let {prog} = row;
    let {volumes} = row;
    //return <span>44444</span>
    // return Utils.fmtDecimal(volumes) ;<ProgressBar value={prog*100} />

    return (
      <>
        <span>{Utils.fmtDecimal(volumes)}</span>
        <ProgressBar
          color="#FFCB66"
          value={prog * 100}
          style={{height: '15px'}}
        />
      </>
    );
  };

  return (
    <>
      <Toast ref={toastRef} />
      <TabView activeIndex={tabInd} onTabChange={(e) => setTabInd(e.index)}>
        <TabPanel header="ETR Report">
          <div className="py-2">
            <FactoryFilter
              scope="factory"
              filter={filter}
              showFactory={true}
              showYears={true}
              showMonth={true}
              onAction={_onFilterAction}
            />
          </div>
        </TabPanel>
      </TabView>

      <DataTable
        value={etrRecsTblData}
        headerColumnGroup={headerGroup}
        //   footerColumnGroup={footerGroup}
        dataKey="id"
        lazy={true}
        paginator={true}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        first={etrRecsTblStart}
        showGridlines
        rows={etrRecsTblLimit}
        totalRecords={ertRecsTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        onPage={_onInvsPaging}
        responsiveLayout="scroll">
        <Column field="upperid"></Column>
        <Column field="style"></Column>
        <Column field="material"></Column>
        <Column field="pureie" body={_renderPureIeCol}>
          {' '}
        </Column>

        <Column field="actualpph" body={_renderActualPphCol}></Column>
        <Column field="effiiciency" body={_renderEffiCol}></Column>
        <Column field="volumes" body={_renderVolumns}></Column>
        <Column field="volumesper" body={_renderVolPerCol}></Column>
        <Column field="pureiepph" body={_renderPureIePphCol}></Column>
        <Column field="actualPphWei" body={_renderActWeightCol}></Column>
      </DataTable>
    </>
  );
}
