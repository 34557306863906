// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
// import {Dialog} from 'primereact/dialog';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';

import type {ApiProps} from '../../service/Api';
import {AuthUtils} from '../../service/AuthUtils';
import {AuthConst} from '../../service/AppConstant';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {FactoryForm} from './FactoryForm';
import {FactoryList} from './FactoryList';
import {FactoryUser} from './FactoryUser';
type Props = {};

export function Factory(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const [selFactory, setSelFactory] = useState<any>();
  // const [showCfmDlg, setShowCfmDlg] = useState<any>(false);
  const [reload, setReload] = useState(false);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  // const selFactoryRef = useRef();
  // useEffect(() => {
  //   selFactoryRef.current = selFactory;
  // }, [selFactory]);

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _onFactorySelected = (evt: any) => {
    // setSelFactory(evt.value);
    //console.log.*$
    setSelFactory(evt.value);
  };

  const _onNewFactory: any = (evt: any) => {
    setSelFactory(null);
  };

  const _onFactoryAction = (evt: any) => {
    setSelFactory(null);
    setReload(!reload);
  };

  const _onDelete: (evt: any) => void = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selFactory.id) {
      return;
    }
    confirmDialog({
      message: 'Are you sure you want to delete factory?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .deleteFactory(selFactory.id)
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
            }
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          })
          .finally(() => {
            setSelFactory(null);
            setReload(!reload);
          });
      },
      reject: () => {},
    });
  };

  // const cfmDlgFooter = (
  //   <div className="text-right">
  //     <Button label="Yes" icon="pi pi-check" onClick={_onDeletFactory} />
  //     <Button
  //       label="No"
  //       icon="pi pi-times"
  //       onClick={(e) => {
  //         setShowCfmDlg(false);
  //       }}
  //     />
  //   </div>
  // );

  let listTitle = 'Available factories';
  let authLevel = AuthUtils.getAuthLevel({auth});
  let canEdit =
    authLevel < AuthConst.AUTH_LVL_NB ||
    AuthUtils.isAuth({auth, roles: ['ADMIN']});

  return (
    <>
      <Toast ref={toastRef} />

      <div className="p-grid">
        <div className="p-col-12 p-lg-8">
          <FactoryList
            header={listTitle}
            reload={reload}
            selection={selFactory}
            onSelect={_onFactorySelected}
          />
          {canEdit && (
            <div className="p-d-flex p-jc-end p-my-2">
              <Button
                label="New factory"
                icon="pi pi-plus"
                className="p-ml-2"
                onClick={_onNewFactory}
              />
              {selFactory && (
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  className="p-ml-2"
                  onClick={_onDelete}
                  disabled={selFactory.flag === 1}
                />
              )}
            </div>
          )}
        </div>
        <div className="p-col-12 p-lg-4">
          <FactoryForm
            factory={selFactory}
            readOnly={!canEdit}
            onAction={_onFactoryAction}
          />
        </div>
      </div>

      {false && (
        <TabView>
          <TabPanel header="Users & Roles">
            <FactoryUser factory={selFactory} />
          </TabPanel>
        </TabView>
      )}
      {/*<Dialog
        header="Confirm delete factory"
        footer={cfmDlgFooter}
        visible={showCfmDlg}
        style={{width: '400px'}}
        modal={true}
        onHide={() => {
          setShowCfmDlg(false);
        }}>
        <span>Are you sure to delete selected factory?</span>
      </Dialog>*/}
    </>
  );
}
