// @flow
import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils,Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  selectionMode?: string,
  selection?: any,
  audit?:any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function ManufacturingInnovationList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const {t} = useTranslation();
  const [andonDetailsTblData, setAndonDetailsTblData] = useState([]);
  const [andonDetailsTblStart, setAndonDetailsTblStart] = useState(0);
  const [andonDetailsTblLimit] = useState(10);
  const [andonDetailsTblNbRows, setMachLinesTblNbRows] = useState(0);
  const [selAndonDetail, setSelAndonDetail] = useState();
  const [selAndonDetails, setSelAndonDetails] = useState([]);
  const [sortMeta, setSortMeta] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);

  const {reload, selection, header, command,audit} = props;
  const selMode = props.selectionMode || 'single';
  const {search} = app || '';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelAndonDetail({...selection});
      } else {
        setSelAndonDetails([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelAndonDetail(null);
      } else {
        setSelAndonDetails([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let start = andonDetailsTblStart;
    let limit = andonDetailsTblLimit;
    let joins = [];
    let filters={};
    let params={};
    if(audit && audit.id){
        filters = {
            ...filters ,
            byAudit: 'obj.pfaAuditGroup.id = :byAuditId'
        }
        params = {
            ...params ,
            byAuditId: {
                type:'long',
                value:audit.id
            }
        }
    }
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });

    api
      .getManufacturingInnovations({search, joins,filters,params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        console.log(data);
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        console.log(data.list);
        setAndonDetailsTblData([...data.list]);
        setMachLinesTblNbRows(data.count);
      })
      .catch((err) => {
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, [search, andonDetailsTblStart, andonDetailsTblLimit, reload, sortMeta,audit]);

  const _onMachLineSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelAndonDetail(evt.value);
    } else {
      setSelAndonDetails(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    setAndonDetailsTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];

    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };

  const _renderWeightingCol = (row: any, col: any) => {
    let {weighting} = row;
    let v = Utils.isNum(weighting) ? Number(weighting) : 0;
    return `${Utils.fmtDecimal(v, 2)*100} %`;
  };

  const _renderScoreCol = (row: any, col: any) => {
        let {score} = row;
        let v = Utils.isNum(score) ? Number(score) : 0;
        return `${Utils.fmtDecimal(v, 2)*100} %`;
    }

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable

          header={header || t('factory.title')}
          value={andonDetailsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={andonDetailsTblLimit}
          first={andonDetailsTblStart}
          totalRecords={andonDetailsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selAndonDetail}
          onSelectionChange={_onMachLineSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            field="name"
            header="Item"

            style={{width: '100px'}}
          />
          <Column body={_renderWeightingCol} header="Weighting" style={{width: '100px'}} />
          <Column field="target" header="Target" style={{width: '100px'}} />
          <Column field="result" header="Result" style={{width: '100px'}} />


          <Column body={_renderScoreCol} header="Score" style={{width: '100px'}} />



        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable

          header={header || t('factory.title')}
          value={andonDetailsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={andonDetailsTblLimit}
          first={andonDetailsTblStart}
          totalRecords={andonDetailsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selAndonDetails}
          onSelectionChange={_onMachLineSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
                 <Column
            field="idNo"
            header="No"
            sortable
            sortField="obj.idNo"
            style={{width: '90px'}}
          />
         <Column body={_renderWeightingCol} header="Weighting" style={{width: '100px'}} />
          <Column field="target" header="Target" style={{width: '100px'}} />
          <Column field="result" header="Result" style={{width: '100px'}} />


          <Column body={_renderScoreCol} header="Score" style={{width: '100px'}} />

        </DataTable>
      )}
    </>
  );
}
