// @flow
import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function SetPointMltList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const {t} = useTranslation();
  const [mltSetPointsTblData, setMltSetPointsTblData] = useState([]);
  const [machLinesTblStart, setMachLinesTblStart] = useState(0);
  const [mltSetPointsTblLimit] = useState(10);
  const [machLinesTblNbRows, setMachLinesTblNbRows] = useState(0);
  const [selMachLine, setSelMachLine] = useState();
  const [selMachLines, setSelMachLines] = useState([]);
  const [sortMeta, setSortMeta] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);

  const {reload, selection, header, command} = props;
  const selMode = props.selectionMode || 'single';
  const {search} = app || '';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelMachLine({...selection});
      } else {
        setSelMachLines([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelMachLine(null);
      } else {
        setSelMachLines([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let start = machLinesTblStart;
    let limit = mltSetPointsTblLimit;

    let sorts = ['obj.month'];
    // let sorts = sortMeta.map((sm) => {
    //   return sm.order > 0 ? sm.field : `-${sm.field}`;
    // });
    ////console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);

    api
      .getMltSetpoint({start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        setMltSetPointsTblData([...data.list]);
        setMachLinesTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, [search, machLinesTblStart, mltSetPointsTblLimit, reload, sortMeta]);

  const _onMachLineSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelMachLine(evt.value);
    } else {
      setSelMachLines(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    // //console.log.*$
    setMachLinesTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };

  const _renderMonthCol: TblColRender = (row: any, col: any) => {
    let {month} = row;
    return month && month === -1 ? '--any--' : month;
  };
  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          // header={header || t('factory.title')}
          value={mltSetPointsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={mltSetPointsTblLimit}
          first={machLinesTblStart}
          totalRecords={machLinesTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selMachLine}
          onSelectionChange={_onMachLineSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            body={_renderMonthCol}
            header="Month"
            headerStyle={{width: 300}}
          />

          <Column field="year" header="Year" headerStyle={{width: 300}} />
          <Column field="value" header="Value" headerStyle={{width: 300}} />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={mltSetPointsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={mltSetPointsTblLimit}
          first={machLinesTblStart}
          totalRecords={machLinesTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selMachLines}
          onSelectionChange={_onMachLineSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            selectionMode="multiple"
            headerStyle={{width: 50}}
            headerClassName="text-left"
            bodyClassName="text-left"></Column>

          <Column
            body={_renderMonthCol}
            header="Month"
            headerStyle={{width: 300}}
          />
          <Column field="year" header="Year" headerStyle={{width: 300}} />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
          <Column field="value" header="Value" headerStyle={{width: 300}} />
        </DataTable>
      )}
    </>
  );
}
