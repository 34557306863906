// @flow
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import type {ApiProps} from './service/Api';
import {logoutAction} from './service/auth/action';
import classNames from 'classnames';
import {useHistory} from 'react-router-dom';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {MegaMenu} from 'primereact/megamenu';
// import {InputText} from 'primereact/inputtext';
// import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {Dialog} from 'primereact/dialog';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
import {CSSTransition} from 'react-transition-group';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
import type {EvtHandler} from './lmrs/types';
// import {AuthUtils} from './service/AuthUtils';
import {UIUtils, Utils} from './service/Utils';
import {CoreApi} from './service/CoreApi';
import {ChangePwd} from './lmrs/profile/ChangePwd';
import {RTLContext} from './App';
import {AdminApi} from './service/AdminApi';
type Props = {
  activeTopbarItem: any,
  mobileTopbarActive: boolean,
  searchActive: any,
  onTopbarItemClick: any,
  onMobileTopbarButtonClick: any,
  onMenuButtonClick: any,
};

function AppTopbar(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [showPwdDlg, setShowPwdDlg] = useState(false);

  const isRTL = useContext(RTLContext);
  const history = useHistory();
  const [alarmsTblData, setAlarmsTblData] = useState([]);
  const apiRef = useRef();
  const adminApiRef = useRef();
  const [alarmsTblNbRows, setAlarmsTblNbRows] = useState(0);
  // Fixed for 6.1.0
  // eslint-disable-next-line
  const searchPanel = useRef(null);

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    adminApiRef.current = api;
  }, [conf, auth]);

  // const toastRef = useRef();

  const toastRef = useRef();
  useEffect(() => {
    // Fixed for 6.1.0
    /*if (props.searchActive) {
            searchPanel.current.element.focus();
        }*/
  }, [props.searchActive]);

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new CoreApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  // const onInputKeydown = (event) => {
  //   const key = event.which;
  //
  //   //escape, tab and enter
  //   if (key === 27 || key === 9 || key === 13) {
  //     props.onSearch(false);
  //   }
  // };

  const _fetchData = useCallback((options) => {

    let {
      byFactory,
      byYear,
      byMonth,
      byAlarmStatus,

    } = options;
    let api: ?AdminApi = adminApiRef.current;
    byAlarmStatus = ['ACTIVE'];
    if (!api) {
      return;
    }

    let joins = [];

    let filters = {};
    let params = {};
    if (byFactory && byFactory.id) {
      filters = {
        ...filters,
        byFactory: 'obj.factory.id = :factoryId',
      };
      params = {
        ...params,
        factoryId: {
          type: 'long',
          value: byFactory.id,
        },
      };
    }

    if (byYear > 0 && byYear !== undefined) {
      filters = {
        ...filters,
        year: 'obj.year = :year',
      };

      params = {
        ...params,
        year: Number(byYear),
      };
    }

    if (byMonth > 0 && byMonth !== undefined) {
      filters = {
        ...filters,
        byMonth: 'obj.month = :month',
      };

      params = {
        ...params,
        month: Number(byMonth),
      };
    }

    if (byAlarmStatus && byAlarmStatus.length > 0) {
      filters = {
        ...filters,
        byAlarmStatus: 'obj.status in (:status)',
      };

      params = {
        ...params,
        status: byAlarmStatus.map((status) => {
          return {
            type: 'enum:com.nb.lmrs.model.AlarmStatus',
            value: 'ACTIVE',
          };
        }),
      };
    }

    let start = 0;
    let limit = 15;

    let sorts = ['-obj.createdAt'];
    // let sorts = sortMeta.map((sm) => {
    //   return sm.order > 0 ? sm.field : `-${sm.field}`;
    // });
    // //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);
    //console.log.*$
 //   console.log(alarmsTblData);
    api
      .getAlarms({joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        setAlarmsTblData([...data.list]);
        setAlarmsTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    _fetchData({});
  }, [_fetchData]);
  const handleClickNotification = (evt) => {
    console.log(evt);
    let filStr = btoa(JSON.stringify(evt));
    history.push(`/notification/notifications/${filStr}`);
};

  const _onLogout: (evt: any) => void = (evt: any) => {
    confirmDialog({
      message: 'Are you sure you want to terminate current working session?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        //console.log.*$
        let api: ?CoreApi = apiRef.current;
        if (api) {
          api
            .logout()
            .then((resp) => resp.data)
            .then((resp) => {
              let {errors} = resp;
              if (errors.length > 0) {
                //console.log.*$
                return;
              }
              //logged out
            })
            .catch((error) => {
              //console.log.*$
            })
            .finally(() => {
              //perform logging out
              dispatch(logoutAction());
            });
        } else {
          dispatch(logoutAction());
        }
      },
      reject: () => {},
    });
  };

  const _onChangePwd: EvtHandler = (evt: any) => {
    //console.log.*$
    setShowPwdDlg(true);
  };

  // //console.log.*$

  const model = [
    // {
    //   label: 'UI KIT',
    //   items: [
    //     [
    //       {
    //         label: 'UI KIT 1',
    //         items: [
    //           {
    //             label: 'Form Layout',
    //             icon: 'pi pi-fw pi-id-card',
    //             command: () => {
    //               history.push('/uikit/formlayout');
    //             },
    //           },
    //           {
    //             label: 'Input',
    //             icon: 'pi pi-fw pi-check-square',
    //             command: () => {
    //               history.push('/uikit/input');
    //             },
    //           },
    //           {
    //             label: 'Float Label',
    //             icon: 'pi pi-fw pi-bookmark',
    //             command: () => {
    //               history.push('/uikit/floatlabel');
    //             },
    //           },
    //           {
    //             label: 'Button',
    //             icon: 'pi pi-fw pi-mobile',
    //             command: () => {
    //               history.push('/uikit/button');
    //             },
    //           },
    //           {
    //             label: 'File',
    //             icon: 'pi pi-fw pi-file',
    //             command: () => {
    //               history.push('/uikit/file');
    //             },
    //           },
    //         ],
    //       },
    //     ],
    //     [
    //       {
    //         label: 'UI KIT 2',
    //         items: [
    //           {
    //             label: 'Table',
    //             icon: 'pi pi-fw pi-table',
    //             command: () => {
    //               history.push('/uikit/table');
    //             },
    //           },
    //           {
    //             label: 'List',
    //             icon: 'pi pi-fw pi-list',
    //             command: () => {
    //               history.push('/uikit/list');
    //             },
    //           },
    //           {
    //             label: 'Tree',
    //             icon: 'pi pi-fw pi-share-alt',
    //             command: () => {
    //               history.push('/uikit/tree');
    //             },
    //           },
    //           {
    //             label: 'Panel',
    //             icon: 'pi pi-fw pi-tablet',
    //             command: () => {
    //               history.push('/uikit/panel');
    //             },
    //           },
    //           {
    //             label: 'Chart',
    //             icon: 'pi pi-fw pi-chart-bar',
    //             command: () => {
    //               history.push('/uikit/chart');
    //             },
    //           },
    //         ],
    //       },
    //     ],
    //     [
    //       {
    //         label: 'UI KIT 3',
    //         items: [
    //           {
    //             label: 'Overlay',
    //             icon: 'pi pi-fw pi-clone',
    //             command: () => {
    //               history.push('/uikit/overlay');
    //             },
    //           },
    //           {
    //             label: 'Menu',
    //             icon: 'pi pi-fw pi-bars',
    //             command: () => {
    //               history.push('/uikit/menu');
    //             },
    //           },
    //           {
    //             label: 'Message',
    //             icon: 'pi pi-fw pi-comment',
    //             command: () => {
    //               history.push('/uikit/message');
    //             },
    //           },
    //           {
    //             label: 'Misc',
    //             icon: 'pi pi-fw pi-circle-off',
    //             command: () => {
    //               history.push('/uikit/misc');
    //             },
    //           },
    //         ],
    //       },
    //     ],
    //   ],
    // },
    // {
    //   label: 'UTILITIES',
    //   items: [
    //     [
    //       {
    //         label: 'UTILITIES 1',
    //         items: [
    //           {
    //             label: 'Display',
    //             icon: 'pi pi-fw pi-desktop',
    //             command: () => {
    //               history.push('/utilities/display');
    //             },
    //           },
    //           {
    //             label: 'Elevation',
    //             icon: 'pi pi-fw pi-external-link',
    //             command: () => {
    //               history.push('/utilities/elevation');
    //             },
    //           },
    //         ],
    //       },
    //       {
    //         label: 'UTILITIES 2',
    //         items: [
    //           {
    //             label: 'FlexBox',
    //             icon: 'pi pi-fw pi-directions',
    //             command: () => {
    //               history.push('/utilities/flexbox');
    //             },
    //           },
    //         ],
    //       },
    //     ],
    //     [
    //       {
    //         label: 'UTILITIES 3',
    //         items: [
    //           {
    //             label: 'Icons',
    //             icon: 'pi pi-fw pi-search',
    //             command: () => {
    //               history.push('/utilities/icons');
    //             },
    //           },
    //         ],
    //       },
    //       {
    //         label: 'UTILITIES 4',
    //         items: [
    //           {
    //             label: 'Text',
    //             icon: 'pi pi-fw pi-pencil',
    //             command: () => {
    //               history.push('/utilities/text');
    //             },
    //           },
    //           {
    //             label: 'Widgets',
    //             icon: 'pi pi-fw pi-star-o',
    //             command: () => {
    //               history.push('/utilities/widgets');
    //             },
    //           },
    //         ],
    //       },
    //     ],
    //     [
    //       {
    //         label: 'UTILITIES 5',
    //         items: [
    //           {
    //             label: 'Grid System',
    //             icon: 'pi pi-fw pi-th-large',
    //             command: (event) => {
    //               history.push('/utilities/grid');
    //             },
    //           },
    //           {
    //             label: 'Spacing',
    //             icon: 'pi pi-fw pi-arrow-right',
    //             command: (event) => {
    //               history.push('/utilities/spacing');
    //             },
    //           },
    //           {
    //             label: 'Typography',
    //             icon: 'pi pi-fw pi-align-center',
    //             command: (event) => {
    //               history.push('/utilities/typography');
    //             },
    //           },
    //         ],
    //       },
    //     ],
    //   ],
    // },
  ];

  return (
    <div className="layout-topbar p-shadow-4">
      <Toast ref={toastRef} />
      <div className="layout-topbar-left">
        <button
          type="button"
          style={{cursor: 'pointer'}}
          className="layout-topbar-logo p-link pt-2"
          onClick={() => history.push('/')}>
          <img
            id="app-logo"
            src="assets/layout/images/nb-log-red-100.png"
            alt="nb-logo-layout"
            style={{height: '3.5rem'}}
          />
        </button>
        <button
          type="button"
          className="layout-menu-button p-shadow-6 p-link"
          onClick={props.onMenuButtonClick}>
          <i className="pi pi-chevron-right"></i>
        </button>
        <button type="button" className="layout-topbar-mobile-button p-link">
          <i
            className="pi pi-ellipsis-v fs-large"
            onClick={props.onMobileTopbarButtonClick}></i>
        </button>
      </div>

      <div
        className={classNames('layout-topbar-right', {
          'layout-topbar-mobile-active': props.mobileTopbarActive,
        })}>
        <div className="layout-topbar-actions-left">
          <MegaMenu model={model} className="layout-megamenu" />
        </div>
        <div className="layout-topbar-actions-right">
          <ul className="layout-topbar-items">
            {/*<li className="layout-topbar-item layout-search-item">
              <button
                className="layout-topbar-action rounded-circle p-link"
                onClick={() => props.onSearch(true)}>
                <i className="pi pi-search fs-large"></i>
              </button>
              <CSSTransition
                classNames="p-toggleable"
                timeout={{enter: 1000, exit: 450}}
                in={props.searchActive}
                unmountOnExit>
                <div className="layout-search-panel p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-search"></i>
                  </span>
                  <InputText
                    type="text"
                    placeholder="Search"
                    onKeyDown={onInputKeydown}
                  />
                  <span className="p-inputgroup-addon">
                    <Button
                      type="button"
                      icon="pi pi-times"
                      className="p-button-rounded p-button-text p-button-plain"
                      onClick={() => props.onSearch(false)}></Button>
                  </span>
                </div>
              </CSSTransition>
            </li>*/}

            <li className="layout-topbar-item notifications">
              <button
                className="layout-topbar-action rounded-circle p-link"
                onClick={(event) =>
                  props.onTopbarItemClick({
                    originalEvent: event,
                    item: 'notifications',
                  })
                }>
                <span className="p-overlay-badge">
                  <i className="pi pi-bell fs-large"></i>
                  <span className="p-badge p-badge-warning p-badge-dot"></span>
                </span>
              </button>

              <CSSTransition
                classNames="p-toggleable"
                timeout={{enter: 1000, exit: 450}}
                in={props.activeTopbarItem === 'notifications'}
                unmountOnExit>
                <ul className="layout-topbar-action-panel p-shadow-6 fadeInDown" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                  <li className="p-mb-3">
                    <span className="p-px-3 fs-small">
                      You have <b>{alarmsTblNbRows}</b> new notifications
                    </span>
                    {/* <span className="p-px-3 fs-small">Notifications</span> */}
                  </li>
                  {alarmsTblData &&
                    alarmsTblData.length > 0 &&
                    alarmsTblData.map((alarm) => {
                      return (
                        <>
                          {' '}
                          <li className="layout-topbar-action-item" onClick={()=>handleClickNotification(alarm)}>
                            <div className="p-d-flex p-flex-row p-ai-center">
                              <img
                                src="assets/demo/images/avatar/notification.png"
                                alt=""
                              />
                              <div
                                className={classNames(
                                  'p-d-flex p-flex-column',
                                  {
                                    'p-ml-3': !isRTL,
                                    'p-mr-3': isRTL,
                                  },
                                )}
                                style={{flexGrow: '1'}}>
                                <div className="p-d-flex p-ai-center p-jc-between p-mb-1">
                                  <span className="fs-small p-text-bold">
                                    Notification {alarm.factory.name} -{' '}
                                    {alarm.thresholdType} Overdue
                                  </span>
                                  <small>
                                    {Utils.convertUnixTimeToDuration(
                                      Number(alarm.duration),
                                    )}
                                  </small>
                                </div>
                                {/* <span className="fs-small">
                                  How to write content about your photographs?
                                </span> */}
                              </div>
                            </div>
                          </li>
                        </>
                      );
                    })}
                </ul>
              </CSSTransition>
            </li>

            {/*<li className="layout-topbar-item app">
              <button
                className="layout-topbar-action rounded-circle p-link"
                onClick={(event) =>
                  props.onTopbarItemClick({originalEvent: event, item: 'apps'})
                }>
                <i className="pi pi-table fs-large"></i>
              </button>

              <CSSTransition
                classNames="p-toggleable"
                timeout={{enter: 1000, exit: 450}}
                in={props.activeTopbarItem === 'apps'}
                unmountOnExit>
                <div className="layout-topbar-action-panel p-shadow-6">
                  <div className="p-grid p-nogutter">
                    <div className="layout-topbar-action-item p-col-4">
                      <button className="p-d-flex p-ai-center p-flex-column text-color p-link">
                        <i className="pi pi-image action indigo-bgcolor white-color"></i>
                        <span>Products</span>
                      </button>
                    </div>
                    <div className="layout-topbar-action-item p-col-4">
                      <button className="p-d-flex p-ai-center p-flex-column text-color p-link">
                        <i className="pi pi-file-pdf action orange-bgcolor white-color"></i>
                        <span>Reports</span>
                      </button>
                    </div>
                    <div className="layout-topbar-action-item p-col-4">
                      <button className="p-d-flex p-ai-center p-flex-column text-color p-link">
                        <i className="pi pi-dollar action teal-bgcolor white-color"></i>
                        <span>Balance</span>
                      </button>
                    </div>
                    <div className="layout-topbar-action-item p-col-4">
                      <button className="p-d-flex p-ai-center p-flex-column text-color p-link">
                        <i className="pi pi-cog action pink-bgcolor white-color"></i>
                        <span>Settings</span>
                      </button>
                    </div>
                    <div className="layout-topbar-action-item p-col-4">
                      <button className="p-d-flex p-ai-center p-flex-column text-color p-link">
                        <i className="pi pi-key action bluegrey-bgcolor white-color"></i>
                        <span>Credentials</span>
                      </button>
                    </div>
                    <div className="layout-topbar-action-item p-col-4">
                      <button className="p-d-flex p-ai-center p-jc-center p-flex-column text-color p-link">
                        <i className="pi pi-sitemap action cyan-bgcolor white-color"></i>
                        <span>Sitemap</span>
                      </button>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            </li>*/}

            <li className="layout-topbar-item">
              <button
                className="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2 rounded-circle p-link"
                onClick={(event) =>
                  props.onTopbarItemClick({
                    originalEvent: event,
                    item: 'profile',
                  })
                }>
                <span>{auth.username}</span>
              </button>

              <CSSTransition
                classNames="p-toggleable"
                timeout={{enter: 1000, exit: 450}}
                in={props.activeTopbarItem === 'profile'}
                unmountOnExit>
                <ul className="layout-topbar-action-panel p-shadow-6">
                  {/*<li className="layout-topbar-action-item">
                    <button className="p-d-flex p-flex-row p-ai-center p-link">
                      <i
                        className={classNames('pi pi-user', {
                          'p-mr-2': !isRTL,
                          'p-ml-2': isRTL,
                        })}></i>
                      <span>{auth.username}</span>
                    </button>
                  </li>*/}

                  <li className="layout-topbar-action-item">
                    <button
                      className="p-d-flex p-flex-row p-ai-center p-link"
                      onClick={_onChangePwd}>
                      <i
                        className={classNames('pi pi-key', {
                          'p-mr-2': !isRTL,
                          'p-ml-2': isRTL,
                        })}></i>
                      <span>Change password</span>
                    </button>
                  </li>
                  {/*<li className="layout-topbar-action-item">
                    <button className="p-d-flex p-flex-row p-ai-center p-link">
                      <i
                        className={classNames('pi pi-file-o', {
                          'p-mr-2': !isRTL,
                          'p-ml-2': isRTL,
                        })}></i>
                      <span>Terms of Usage</span>
                    </button>
                  </li>*/}

                  {/*<li className="layout-topbar-action-item ">
                    <button className="p-d-flex p-flex-row p-ai-center p-link">
                      <i
                        className={classNames('pi pi-compass', {
                          'p-mr-2': !isRTL,
                          'p-ml-2': isRTL,
                        })}></i>
                      <span>Support</span>
                    </button>
                  </li>*/}

                  <li className="layout-topbar-action-item">
                    <button
                      className="p-d-flex p-flex-row p-ai-center p-link"
                      onClick={_onLogout}>
                      <i
                        className={classNames('pi pi-power-off', {
                          'p-mr-2': !isRTL,
                          'p-ml-2': isRTL,
                        })}></i>
                      <span>Logout</span>
                    </button>
                  </li>
                </ul>
              </CSSTransition>
            </li>

            {/*<li className="layout-topbar-item">
              <button
                type="button"
                className="layout-topbar-action rounded-circle p-link"
                onClick={props.onRightMenuButtonClick}>
                <i className="pi fs-large pi-arrow-left"></i>
              </button>
            </li>*/}
          </ul>
        </div>
      </div>
      <Dialog
        header="Change password"
        visible={showPwdDlg}
        modal={true}
        blockScroll
        onHide={() => {
          setShowPwdDlg(false);
        }}>
        <div className="layout-wrapper layout-menu-light layout-topbar-green layout-menu-static layout-menu-active p-input-filled">
          <Panel header="Change current password">
            <ChangePwd
              onAction={(evt) => {
                setShowPwdDlg(false);
                UIUtils.showInfo({
                  detail: 'Change password success',
                  toast: toastRef.current,
                });
              }}
            />
          </Panel>
        </div>
      </Dialog>
    </div>
  );
}

export default AppTopbar;
