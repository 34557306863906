// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';
//$FlowFixMe
import {debounceTime} from 'rxjs/operators';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {Chart} from 'primereact/chart';
//$FlowFixMe
import {Button} from 'primereact/button';

//$FlowFixMe[cannot-resolve-module]
import ChartDataLabels from 'chartjs-plugin-datalabels';

import type {ApiProps} from '../../../service/Api';
import type {EvtHandler} from '../../types';
// import {AuthUtils} from '../service/AuthUtils';
import {UIUtils, Utils} from '../../../service/Utils';
import {AdminApi} from '../../../service/AdminApi';
import {getColor} from '../../../service/util/color';

import {getChartOptions, pphColors, kpiColors} from '../CommonChart';

type Props = {
  scope?: string,
  regions?: any[],
  countries?: any[],
  facGroups?: any[],
  factories?: any[],
  lines?: any[],
  year?: any,
  month?: any,
  expanded?: boolean,
  onAction?: EvtHandler,
};

export const biglColors: any = [
  'purple-700',
  'purple-500',
  'purple-300',
  'purple-100',
  'purple-800',
  'purple-600',
  'purple-400',
  'purple-200',
  'purple-50',
  'purple-900',
].map((c) => getColor(c));

export const minlColors: any = [
  'yellow-700',
  'yellow-500',
  'yellow-300',
  'yellow-100',
  'yellow-800',
  'yellow-600',
  'yellow-400',
  'yellow-200',
  'yellow-50',
  'yellow-900',
].map((c) => getColor(c));

export const minlRmColors: any = [
  'red-700',
  'red-500',
  'red-300',
  'red-100',
  'red-900',
].map((c) => getColor(c));

export const minlRateColors: any = [
  'green-700',
  'green-500',
  'green-300',
  'green-100',
  'green-800',
  'green-600',
  'green-400',
  'green-200',
  'green-50',
  'green-900',
].map((c) => getColor(c));

export const minlVolRateColors: any = [
  'blue-700',
  'blue-500',
  'blue-300',
  'blue-100',
  'blue-800',
  'blue-600',
  'blue-400',
  'blue-200',
  'blue-50',
  'blue-900',
].map((c) => getColor(c));

let chartOptions = getChartOptions();
// const scales = {
//   y: {
//     type: 'linear',
//     display: true,
//     position: 'left',
//   },
//   y1: {
//     type: 'linear',
//     display: true,
//     position: 'right',
//     // grid line settings
//     grid: {
//       drawOnChartArea: false, // only want the grid lines for one axis to show up
//     },
//   },
// };
// chartOptions = {
//   ...chartOptions,
//   ...scales,
// };

const chartPlugins = [ChartDataLabels];

export function MltMonthlyFacV2(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();
  const {
    scope,
    regions,
    countries,
    facGroups,
    factories,
    lines,
    year,
    month,
    expanded,
    onAction,
  } = props;

  const [barData, setBarData] = useState();
  // const [chartOptions] = useState(getChartOptions());

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  // const _apiErrorHandler = useCallback((errInfo: any) => {
  //   UIUtils.showError({...errInfo, toast: toastRef.current});
  // }, []);

  const _fetchFilterMlt = useCallback(async (options) => {
    let {year} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let start = 0;
    let limit = 10;
    // let months = [month, -1];
    let filters = {
      byYear: 'obj.year = :year',
      byMonth: 'obj.month = :month',
    };

    let params = {
      year: year,
      month: -1,
    };

    return await api
      .getFilterMlt({start, limit, filters, params})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        let lstFilter = data.list;
        if (lstFilter.length === 1) {
          let filterMlt = lstFilter[0];
          return {
            minmlt: filterMlt.ucl,
            maxmlt: filterMlt.lcl,
          };
        } else {
          return {
            minmlt: 0,
            maxmlt: 0,
          };
        }
      })
      .catch((errors) => {});
  }, []);
  const _fetchMltData = useCallback(
    async (options: any) => {
      let {factories, year, month} = options;
      let api: ?AdminApi = apiRef.current;
      if (!api) {
        return;
      }

      let joins = [
        {
          type: 'join',
          expr: 'obj.factory',
          alias: 'fac',
        },
        {
          type: 'join',
          expr: 'fac.country',
          alias: 'coun',
        },
        {
          type: 'join',
          expr: 'coun.region',
          alias: 'reg',
        },
      ];
      let filters = {};
      let params = {};
      // let groupBys = ['obj.mlt'];
      let groupBys = [];

      let fields = ['obj.mlt'];

      fields = [...fields];
      let facIds = Utils.getVals(factories || [], 'id', [0]).map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
      filters = {
        ...filters,
        byFacIds: 'fac.id in (:facIds)',
      };
      params = {
        ...params,
        facIds,
      };

      filters = {
        ...filters,
        siYear: 'obj.siYear = :year',
        siMonth: 'obj.siMonth = :month',
      };

      params = {
        ...params,
        year,
        month,
      };

      let filterMlt: any = await _fetchFilterMlt({year});

      let {maxmlt, minmlt} = filterMlt;

      if (minmlt > 0) {
        filters = {
          ...filters,
          byMin: 'obj.mlt > :minmlt',
        };
        params = {
          ...params,
          minmlt,
        };
      }

      if (maxmlt > 0) {
        filters = {
          ...filters,
          byMax: 'obj.mlt < :maxmlt',
        };
        params = {
          ...params,
          maxmlt,
        };
      }
      // let fields = [...groupBys, 'count(obj.mlt)'];
      // let sorts = [...groupBys];
      let sorts = [...fields];
      return await api
        .fetchMltRecData({
          fields,
          joins,
          groupBys,
          filters,
          params,
          sorts,
          start: 0,
          limit: 0,
        })
        .then((resp) => resp.data)
        .then((resp) => {
          // //console.log.*$
          let {errors, data} = resp;
          if (errors.length > 0) {
            //console.log.*$
            UIUtils.showError({errors, toast: toastRef.current});
            return [];
          }
          //console.log.*$
          return [...data.list];
        })
        .catch((error) => {
          //console.log.*$
          UIUtils.showError({error, toast: toastRef.current});
          return [];
        });
    },
    [_fetchFilterMlt],
  );

  const _fetchKpiRegCouFacGroup = async (options) => {
    let api: ?AdminApi = apiRef.current;
    let {scope, year} = options;
    if (scope === 'factory') {
      return [];
    }
    let start = 0;
    let limit = 0;
    let sorts = [];
    let joins = [];
    let filters = {
      byType: 'obj.kpiType = :kpiType',
      byYear: 'obj.year = :year',
    };

    let params = {
      kpiType: {
        type: 'enum:com.nb.lmrs.model.KpiType',
        value: 'MLT',
      },
      year,
    };

    return await api
      .getLeanScoreCard({joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        let scoreCardKpis = [...data.list];
        let kpiDatas = [];
        for (let i = 1; i <= 12; i++) {
          if (i === 1 || i === 2 || i === 3) {
            let scoreCardKpi = scoreCardKpis.find((dt) => dt.qoy === 1);
            if (typeof scoreCardKpi !== 'undefined') {
              kpiDatas.push(scoreCardKpi.targetValue);
            } else {
              kpiDatas.push(0);
            }
          }
          if (i === 4 || i === 5 || i === 6) {
            let scoreCardKpi = scoreCardKpis.find((dt) => dt.qoy === 2);
            if (typeof scoreCardKpi !== 'undefined') {
              kpiDatas.push(scoreCardKpi.targetValue);
            } else {
              kpiDatas.push(0);
            }
          }
          if (i === 7 || i === 8 || i === 9) {
            let scoreCardKpi = scoreCardKpis.find((dt) => dt.qoy === 3);
            if (typeof scoreCardKpi !== 'undefined') {
              kpiDatas.push(scoreCardKpi.targetValue);
            } else {
              kpiDatas.push(0);
            }
          }
          if (i === 10 || i === 11 || i === 12) {
            let scoreCardKpi = scoreCardKpis.find((dt) => dt.qoy === 4);
            if (typeof scoreCardKpi !== 'undefined') {
              kpiDatas.push(scoreCardKpi.targetValue);
            } else {
              kpiDatas.push(0);
            }
          }
        }

        let ytdKpi = scoreCardKpis.find((dt) => dt.qoy === 5);
        if (typeof ytdKpi !== 'undefined') {
          kpiDatas.push(ytdKpi.targetValue);
        } else {
          kpiDatas.push(0);
        }

        return [...kpiDatas];
      })
      .catch((err) => {
        UIUtils.showError({error: err, toast: toastRef.current});
        return [];
      });

    //  //console.log.*$
  };

  const _fetchMltYtdData = useCallback(async (options) => {
    let {scope, factories, year, month} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return [];
    }

    if (factories?.length === 0 && scope === 'factoryv2') return [];

    let reqData = {
      month,
      year,
      scope,
    };
    scope = scope || 'factoryv2';

    if (factories && factories.length > 0) {
      let facIds = factories.map((fac) => fac.id);
      reqData = {
        ...reqData,
        facIds,
      };
    }

    return api
      .getMltYtdRoll6Months({data: {...reqData}})
      .then((resp) => resp.data)
      .then((resp) => {
        let {data, errors} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        return data.value;
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchChartData = useCallback(
    async (options: any) => {
      let months = [];
      for (let i = 0; i < 12; i++) {
        months.push(i + 1);
      }
      let labels = months.map((it) => it);
      labels = labels.map((it) => t(`mon.${it}`));
      let mltYear = [];
      let ytd: any;
      let datas = [];
      //let mltRecs1: any[] = (await _fetchMltData({...options, year})) || [];

      for (let month of months) {
        let mltRecs: any[] = (await _fetchMltData({...options, month})) || [];
        let mlt = Utils.median(mltRecs);
        datas.push(mlt[0] ? mlt[0] : 0);
        mltYear = [...mltYear, ...mltRecs];
      }
      mltYear = [...mltYear].sort((a, b) => a - b);
      ytd = (await _fetchMltYtdData(options)) || 0;
      datas.push(ytd);

      let mltDatasets = [
        {
          label: `MLT`,
          backgroundColor: pphColors[0],
          data: datas,
          datalabels: {
            anchor: 'end',
            align: 'top',
            offset: 1,
            color: '#ff0000',
          },
        },
      ];

      let kpiReg = (await _fetchKpiRegCouFacGroup(options)) || [];

      let kpiDatas = [];

      if (kpiReg.length > 0) {
        kpiDatas.push({
          type: 'line',
          label: 'Target',
          backgroundColor: kpiColors[0],
          borderColor: kpiColors[0],
          borderWidth: 1,
          data: [...kpiReg],
          datalabels: {
            display: false,
          },
        });
      }

      labels.push('6 months roll');

      let chartData = {
        labels,
        datasets: [...kpiDatas, ...mltDatasets],
      };
      setBarData(chartData);
    },
    [t, _fetchMltData, _fetchMltYtdData],
  );

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchChartData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      // //console.log.*$
      subj.complete();
    };
  }, [_fetchChartData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      scope,
      regions,
      countries,
      facGroups,
      factories,
      lines,
      year,
      month,
    });
  }, [scope, regions, countries, facGroups, factories, lines, year, month]);

  const _onToggleExpand: EvtHandler = (evt: any) => {
    onAction &&
      onAction({
        type: 'toggleExpand',
        value: !expanded,
      });
  };

  const iconName = expanded
    ? 'pi pi-angle-double-left'
    : 'pi pi-angle-double-right';

  const icons = (
    <Button
      icon={iconName}
      className="p-button-rounded p-button-text"
      style={{padding: 0, height: '1.2rem', color: '#FFFFFF'}}
      onClick={_onToggleExpand}
    />
  );

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="MLT Monthly (Median) - 6 months roll" icons={icons}>
        <Chart
          type="bar"
          data={barData}
          options={chartOptions}
          plugins={chartPlugins}></Chart>
      </Panel>
    </>
  );
}
