// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../../service/AuthUtils';
// import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {UserGrpList} from './UserGrpList';
import {UserGrpForm} from './UserGrpForm';
import {UserGrpUsers} from './UserGrpUsers';
import {UserGrpRoles} from './UserGrpRoles';

type Props = {
  type: string,
  // pItem?: any,
  // project?: any,
};

export function UserGrp(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  // const {t} = useTranslation();
  const [selUserGrp, setSelUserGrp] = useState();

  const [reloadFlag, setReloadFlag] = useState(false);
  const [tabInd, setTabInd] = useState(0);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  const _onUserGrpSelected = (evt: any) => {
    // //console.log.*$
    setSelUserGrp(evt.value);
  };

  const _onNewUserGrp = (evt: any) => {
    setSelUserGrp(null);
  };

  const _onUserGrpAction = (evt: any) => {
    // //console.log.*$
    // setTabInd(1);
    // let {value} = evt;
    setReloadFlag(!reloadFlag);
    setSelUserGrp(null);
  };

  const tblHeader = 'Available user groups';
  return (
    <>
      <Toast ref={toastRef} />
      {/* <div className="p-grid">
        <div className="p-col-6 p-fluid">
          <div>
            <span className="md-inputfield">
              <InputText />
              <label>PO/SR</label>
            </span>
          </div>
        </div>
      </div> */}
      <div className="p-grid">
        <div className="p-col-12 p-lg-8">
          <UserGrpList
            header={tblHeader}
            selection={selUserGrp}
            reload={reloadFlag}
            onUserGrpSelected={_onUserGrpSelected}
          />
          <div className="p-d-flex p-jc-end p-my-2">
            <Button label="New" icon="pi pi-plus" onClick={_onNewUserGrp} />
          </div>
        </div>
        <div className="p-col-12 p-lg-4">
          <UserGrpForm onAction={_onUserGrpAction} userGrp={selUserGrp} />
        </div>
      </div>
      <TabView
        activeIndex={tabInd}
        onTabChange={(e) => setTabInd(e.index)}
        className="p-nogutter">
        <TabPanel header="Users">
          {selUserGrp && <UserGrpUsers userGrp={selUserGrp} />}
        </TabPanel>
        <TabPanel header="Roles">
          {selUserGrp && <UserGrpRoles userGrp={selUserGrp} />}
        </TabPanel>
      </TabView>
    </>
  );
}
