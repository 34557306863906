// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {AutoComplete} from 'primereact/autocomplete';
//$FlowFixMe[cannot-resolve-module]
import {Calendar} from 'primereact/calendar';

import type {ApiProps} from '../../service/Api';

import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  readOnly?: boolean,
  audit: any,
  onAction: (evt: any) => void,
};

const defQuickOverChangeAudit = {
  __cls__: 'com.nb.lmrs.model.entity.QuickChangeOverAudit',
  flag: 0,
  code: '',
  name: '',
  description: '',
  auditor: '',
  auditee: '',
  avgScore: 0,
  audDate: null,
  status: 'NEW',
  factory: null,
};

export function QuickOverChangeAuditForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {readOnly, audit} = props;
  const [editQuickOverChangeAudit, setEditQuickOverChangeAudit] = useState();
  const [suggFactories, setSuggFactories] = useState([]);
  const [defFactory, setDefFactory] = useState();

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      setDefFactory(factories[0]);
    }
  }, [auth]);

  useEffect(() => {
    let obj = audit || {};
    obj = {
      ...defQuickOverChangeAudit,
      ...obj,
    };
    let {audDate} = obj;
    if (audDate) {
      obj = {
        ...obj,
        audDate: Utils.toJsDate(audDate),
      };
    }

    let isNew = !obj.id;
    if (isNew && (!obj.factory || !obj.factory.id)) {
      obj['factory'] = defFactory;
    }

    let {factory} = obj;
    if (factory) {
      factory.label = `${factory.code} :: ${factory.name}`;
    }

    setEditQuickOverChangeAudit(obj);
  }, [audit, defFactory]);

  const _onSuggFactories: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getFactories({search, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }

        let lst = [...data.list];
        lst.forEach((it) => {
          it.label = `${it.code} :: ${it.name}`;
        });
        setSuggFactories([...lst]);
      })
      .catch((err) => {
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onSave = (evt: any) => {
    let obj = _validateQuickOverChangeAudit();
    if (!obj) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    confirmDialog({
      message: obj.id
        ? 'Are you sure you want to update assessment?'
        : 'Are you sure you want to create assessment?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateQuickOverChangeAudit({...obj})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'machLineSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _validateQuickOverChangeAudit: () => any = () => {
    let obj: any = {...editQuickOverChangeAudit};

    if (Utils.isEmpty(obj.name)) {
      UIUtils.showError({
        detail: 'Missing name',
        toast: toastRef.current,
      });
      return false;
    }

    let {factory} = obj;
    if (!factory || !factory.id) {
      UIUtils.showError({
        detail: 'Please select factory',
        toast: toastRef.current,
      });
      return false;
    }

    let {audDate} = obj;
    if (!audDate) {
      UIUtils.showError({
        detail: 'Missing audit date',
        toast: toastRef.current,
      });
      return false;
    }
    obj['audDate'] = Utils.jsDateToStr(audDate);

    return obj;
  };

  if (!editQuickOverChangeAudit) {
    return null;
  }

  const saveLbl = editQuickOverChangeAudit.id
    ? 'Update Quick over change audit'
    : 'Create Quick over change audit';

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/edit Quick over change audit">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editQuickOverChangeAudit.name}
                onChange={(e) => {
                  setEditQuickOverChangeAudit({
                    ...editQuickOverChangeAudit,
                    name: e.target.value,
                  });
                }}
                disabled={readOnly}
              />
              <label>Name</label>
            </span>
          </div>

          <div className="p-col-12">
            <AutoComplete
              placeholder="Select factory"
              dropdown
              suggestions={suggFactories}
              field="label"
              value={editQuickOverChangeAudit.factory}
              completeMethod={_onSuggFactories}
              onChange={(e) =>
                setEditQuickOverChangeAudit({
                  ...editQuickOverChangeAudit,
                  factory: e.value,
                })
              }
              disabled={readOnly}
            />
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <Calendar
                value={editQuickOverChangeAudit.audDate}
                dateFormat="dd/mm/yy"
                onChange={(e) =>
                  setEditQuickOverChangeAudit({
                    ...editQuickOverChangeAudit,
                    audDate: e.value,
                  })
                }></Calendar>
              <label>Audit date</label>
            </span>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editQuickOverChangeAudit.auditor}
                onChange={(e) => {
                  setEditQuickOverChangeAudit({
                    ...editQuickOverChangeAudit,
                    auditor: e.target.value,
                  });
                }}
                disabled={readOnly}
              />
              <label>Auditor</label>
            </span>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editQuickOverChangeAudit.auditee}
                onChange={(e) => {
                  setEditQuickOverChangeAudit({
                    ...editQuickOverChangeAudit,
                    auditee: e.target.value,
                  });
                }}
                disabled={readOnly}
              />
              <label>Auditee</label>
            </span>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <InputTextarea
                rows={2}
                value={editQuickOverChangeAudit.description}
                onChange={(e) => {
                  setEditQuickOverChangeAudit({
                    ...editQuickOverChangeAudit,
                    description: e.target.value,
                  });
                }}
                disabled={readOnly}
              />
              <label>Summary</label>
            </span>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
