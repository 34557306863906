// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
import {Subject} from 'rxjs';
//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
import {AuthConst} from '../../service/AppConstant';
import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender} from '../types';
import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  byFactory?: any,
  byYears?: any[],
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function QuickOverChangeAuditList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const {t} = useTranslation();
  const [quickOverChangeAuditsTblData, setQuickOverChangeAuditsTblData] =
    useState([]);
  const [quickOverChangeAuditsTblStart, setQuickOverChangeAuditsTblStart] =
    useState(0);
  const [quickOverChangeAuditsTblLimit] = useState(10);
  const [leanAuditsTblNbRows, setLeanAuditsTblNbRows] = useState(0);
  const [selQuickOverChangeAudit, setSelQuickOverChangeAudit] = useState();
  const [selQuickOverChangeAudits, setSelQuickOverChangeAudits] = useState([]);
  const [sortMeta, setSortMeta] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);

  const {byFactory, byYears, reload, selection, header, command} = props;
  const selMode = props.selectionMode || 'single';
  const {search} = app || '';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchData = useCallback(async (options) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let {
      byFactory,
      byYears,
      search,
      quickOverChangeAuditsTblStart,
      quickOverChangeAuditsTblLimit,
      sortMeta,
    } = options;
    let start = quickOverChangeAuditsTblStart;
    let limit = quickOverChangeAuditsTblLimit;
    let joins = [];
    let params = {};
    let filters = {};

    if (byFactory && byFactory.id) {
      filters = {
        ...filters,
        byFactory: 'obj.factory.id = :factoryId',
      };
      params = {
        ...params,
        factoryId: {
          type: 'long',
          value: byFactory.id,
        },
      };
    }

    byYears = byYears || [];
    byYears = byYears.map((it) => Number(it)).filter((it) => !isNaN(it));

    if (byYears.length > 0) {
      filters = {
        ...filters,
        yearsIn: 'obj.audYear in (:audYears)',
      };
      params = {
        ...params,
        audYears: [...byYears],
      };
    }

    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });
    //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);

    api
      .getQuickOverChangeAudits({
        search,
        joins,
        filters,
        params,
        start,
        limit,
        sorts,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
         // console.log('data:', data);
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        // //console.log.*$
        setQuickOverChangeAuditsTblData([...data.list]);
        setLeanAuditsTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    let subj = new Subject();

    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      subj.complete();
    };
  }, [_fetchData]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelQuickOverChangeAudit({...selection});
      } else {
        setSelQuickOverChangeAudits([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelQuickOverChangeAudit(null);
      } else {
        setSelQuickOverChangeAudits([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      byFactory,
      byYears,
      search,
      quickOverChangeAuditsTblStart,
      quickOverChangeAuditsTblLimit,
      sortMeta,
    });
  }, [
    byFactory,
    byYears,
    search,
    quickOverChangeAuditsTblStart,
    quickOverChangeAuditsTblLimit,
    reload,
    sortMeta,
  ]);

  const _onQuickOverChangeAuditSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelQuickOverChangeAudit(evt.value);
    } else {
      setSelQuickOverChangeAudits(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    setQuickOverChangeAuditsTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };

  const _renderFactoryCol: TblColRender = (row: any, col: any) => {
    let {factory} = row;
    return factory ? `${factory.code} :: ${factory.name}` : null;
  };

  const _renderAudDateCol: TblColRender = (row: any, col: any) => {
    let {audDate} = row;
    return audDate ? `${Utils.reFmtDate(audDate, 'DD/MM/YYYY')}` : null;
  };

  const _renderAvgScoreCol: TblColRender = (row, col) => {
    let {avgScore} = row;
    // let w = !Utils.isEmpty(score) ? Utils.fmtDecimal(score, 4) : 0;
    return Utils.isNum(avgScore) ? Utils.fmtDecimal(avgScore, 2) : null;
  };

  const _renderTarScoreCol: TblColRender = (row, col) => {
    let {tarScore} = row;
    // let w = !Utils.isEmpty(score) ? Utils.fmtDecimal(score, 4) : 0;
    return Utils.isNum(tarScore) ? Utils.fmtDecimal(tarScore, 2) : null;
  };

  const _onApproveAudit = (row: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let reqData = {
        data:{
            auditId: row.id
        }
    };
     api.approveQuickOverChangeAudit(reqData).then((resp) => resp.data)
      .then((resp) => {
       let {errors} = resp;
       if (errors.length > 0) {
         UIUtils.showError({errors, toast: toastRef.current});
       }
       UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
     })
  }

  const _onRejectAudit = (row: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let reqData = {
        data:{
            auditId: row.id
        }
    };
     api.rejectQuickOverChangeAudit(reqData).then((resp) => resp.data)
      .then((resp) => {
       let {errors} = resp;
       if (errors.length > 0) {
         UIUtils.showError({errors, toast: toastRef.current});
       }
       UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
     })
  }
const _renderSubmitCol: TblColRender = (row, col) => {
   if(AuthUtils.getAuthLevel({auth}) < AuthConst.AUTH_LVL_NB ||
      AuthUtils.isAuth({auth, roles: [ 'NB_ADMIN',]})) {
         if(AuthUtils.isAuth({auth, roles: ['NB_LVL_1']})) {
            return <Button label="Submit to lv2" onClick={()=>_onSubmitToNbLvl2(row)} />;
         }
         if(AuthUtils.isAuth({auth, roles: ['NB_LVL_2']})) {
            return <Button label="Submit to lv3" onClick={()=> _onSubmitToNbLvl3(row)}  />;
         }
    }
  }

  const _renderApproveCol: TblColRender = (row, col) => {
    if(AuthUtils.getAuthLevel({auth}) <= AuthConst.AUTH_LVL_NB ||
       AuthUtils.isAuth({auth, roles: [ 'NB_ADMIN']})) {
          if(AuthUtils.isAuth({auth, roles: ['NB_LVL_3']})) {
             return <Button label="Approve" onClick={()=> _onApproveAudit(row)}  />;
          }
     }
   }
   const _renderRejectCol: TblColRender = (row, col) => {
    if(AuthUtils.getAuthLevel({auth}) <= AuthConst.AUTH_LVL_NB ||
       AuthUtils.isAuth({auth, roles: [ 'NB_ADMIN']})) {
          if(AuthUtils.isAuth({auth, roles: ['NB_LVL_3']})) {
             return <Button label="Reject" onClick={()=> _onRejectAudit(row)} />;
          }
     }
   }

   const _onSubmitToNbLvl2 = (row: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let reqData = {
        data:{
            auditId: row.id
        }
    };
     api.submitQuickOverChangeAuditToNbLvl2(reqData).then((resp) => resp.data)
      .then((resp) => {
       let {errors} = resp;
       if (errors.length > 0) {
         UIUtils.showError({errors, toast: toastRef.current});
       }
       UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
     })

  }
  const _onSubmitToNbLvl3 = (row: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let reqData = {
        data:{
            auditId: row.id
        }
    };
     api.submitQuickOverChangeAuditToNbLvl3(reqData).then((resp) => resp.data)
      .then((resp) => {
       let {errors} = resp;
       if (errors.length > 0) {
         UIUtils.showError({errors, toast: toastRef.current});
       }
       UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
     })

  }

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={quickOverChangeAuditsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={quickOverChangeAuditsTblLimit}
          first={quickOverChangeAuditsTblStart}
          totalRecords={leanAuditsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selQuickOverChangeAudit}
          onSelectionChange={_onQuickOverChangeAuditSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            field="name"
            header="Name"
            headerStyle={{width: 200}}
            sortable
            sortField="obj.name"
          />
          <Column
            body={_renderFactoryCol}
            header="Factory"
            headerStyle={{width: 250}}
            sortable
            sortField="factory.name"
          />
          <Column
            body={_renderAudDateCol}
            header="Aud. Date"
            headerStyle={{width: 250}}
            sortable
            sortField="obj.audDate"
          />
          <Column
            body={_renderSubmitCol}
            header="Submit"
            headerStyle={{width: '200px'}}
            // headerClassName="text-right"
            // bodyClassName="text-right"
          />

            <Column
              body={_renderApproveCol}
              header="Approve"
              headerStyle={{width: '200px'}}
            />
            <Column
              body={_renderRejectCol}
              header="Reject"
              headerStyle={{width: '200px'}}
            />
            <Column
              field="status"
              header="Status"
              headerStyle={{width: '200px'}}
            />
          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={quickOverChangeAuditsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={quickOverChangeAuditsTblLimit}
          first={quickOverChangeAuditsTblStart}
          totalRecords={leanAuditsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selQuickOverChangeAudits}
          onSelectionChange={_onQuickOverChangeAuditSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            selectionMode="multiple"
            headerStyle={{width: 50}}
            headerClassName="text-left"
            bodyClassName="text-left"></Column>
          <Column
            field="name"
            header="Name"
            headerStyle={{width: 200}}
            sortable
            sortField="obj.name"
          />
          <Column
            body={_renderFactoryCol}
            header="Factory"
            headerStyle={{width: 250}}
            sortable
            sortField="factory.name"
          />
          <Column
            body={_renderAudDateCol}
            header="Aud. Date"
            headerStyle={{width: 250}}
            sortable
            sortField="obj.audDate"
          />
          <Column
            body={_renderAvgScoreCol}
            header="Score"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
            sortable={true}
            sortField="obj.avgScore"
          />
          <Column
            body={_renderTarScoreCol}
            header="Target"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
            sortable={true}
            sortField="obj.tarScore"
          />
          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
        </DataTable>
      )}
    </>
  );
}
