// @flow
import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {Dialog} from 'primereact/dialog';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {RoleList} from './RoleList';

type Props = {
  userGrp: any,
  // pItem?: any,
  // project?: any,
};

export function UserGrpRoles(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();

  const {userGrp} = props;
  const [selAvaiRoles, setSelAvaiRoles] = useState([]);
  const [selGrpRoles, setSelGrpRoles] = useState([]);

  const [reloadGrpRolesFlag, setReloadGrpRolesFlag] = useState(false);
  const [showAssignDlg, setShowAssignDlg] = useState(false);
  const [showUnAssignDlg, setShowUnAssignDlg] = useState(false);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  const _onGrpRoleSelected = (evt: any) => {
    // //console.log.*$
    // setSelRoleGrp(evt.value);
    setSelGrpRoles(evt.value);
  };

  const _onAvaiRoleSelected: (evt: any) => void = (evt: any) => {
    //console.log.*$
    setSelAvaiRoles(evt.value);
  };

  const _onAssignRole: (evt: any) => void = (evt: any) => {
    setShowAssignDlg(false);
    let api: ?AdminApi = apiRef.current;
    if (!api || !userGrp) {
      return;
    }
    if (selAvaiRoles.length < 1) {
      return;
    }
    let roleIds = selAvaiRoles.map((r) => r.id);
    api
      .linkUserGrpToRoles(userGrp.id, [...roleIds])
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        setTimeout(() => {
          setReloadGrpRolesFlag(!reloadGrpRolesFlag);
        }, 100);
        UIUtils.showInfo({detail: 'success', toast: toastRef.current});
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
      });
  };

  const _onUnAssignRole: (evt: any) => void = (evt: any) => {
    setShowUnAssignDlg(false);
    let api: ?AdminApi = apiRef.current;
    if (!api || !userGrp) {
      return;
    }
    if (selGrpRoles.length < 1) {
      return;
    }
    let roleIds = selGrpRoles.map((r) => r.id);
    api
      .unlinkUserGrpToRoles(userGrp.id, [...roleIds])
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        setTimeout(() => {
          setReloadGrpRolesFlag(!reloadGrpRolesFlag);
        }, 100);
        UIUtils.showInfo({detail: 'success', toast: toastRef.current});
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
      });
  };

  const assignDlgFooter = (
    <div>
      <Button
        label={t('g.yes')}
        icon="pi-md-check"
        className="ml-1"
        onClick={_onAssignRole}
      />
      <Button
        label={t('g.no')}
        icon="pi-md-close"
        className="ml-1"
        onClick={() => {
          setShowAssignDlg(false);
        }}
      />
    </div>
  );

  const unAssignDlgFooter = (
    <div>
      <Button
        label={t('g.yes')}
        icon="pi-md-check"
        className="ml-1"
        onClick={_onUnAssignRole}
      />
      <Button
        label={t('g.no')}
        icon="pi-md-close"
        className="ml-1"
        onClick={() => {
          setShowUnAssignDlg(false);
        }}
      />
    </div>
  );

  return (
    <>
      <Toast ref={toastRef} />
      {/* <div className="p-grid">
        <div className="p-col-6 p-fluid">
          <div>
            <span className="md-inputfield">
              <InputText />
              <label>PO/SR</label>
            </span>
          </div>
        </div>
      </div> */}
      <div className="p-grid">
        <div className="p-col-12 p-lg-6">
          <RoleList
            selectionMode="multi"
            selection={selGrpRoles}
            byUserGrp={userGrp}
            simpleMode={true}
            header="Assigned roless"
            reload={reloadGrpRolesFlag}
            onSelect={_onGrpRoleSelected}
          />
          <div className="p-d-flex p-jc-end p-my-2">
            <Button
              label="Un-assign"
              icon="pi pi-minus-circle"
              onClick={(evt) => {
                setShowUnAssignDlg(true);
              }}
              disabled={selGrpRoles.length < 1}
            />
          </div>
        </div>
        <div className="p-col-12 p-lg-6">
          <RoleList
            selectionMode="multi"
            selection={selAvaiRoles}
            simpleMode={true}
            header="Available roless"
            onSelect={_onAvaiRoleSelected}
          />
          <div className="p-d-flex p-jc-end p-my-2">
            <Button
              label="Assign"
              icon="pi pi-plus-circle"
              onClick={(evt) => {
                setShowAssignDlg(true);
              }}
              disabled={selAvaiRoles.length < 1}
            />
          </div>
        </div>
      </div>

      {selAvaiRoles && (
        <Dialog
          header="Confirm assign role"
          footer={assignDlgFooter}
          visible={showAssignDlg}
          modal={true}
          blockScroll
          onHide={() => {
            setShowAssignDlg(false);
          }}>
          Are you sure to assign {selAvaiRoles.length} selected roles to current
          departement?
        </Dialog>
      )}
      {selGrpRoles && (
        <Dialog
          header="Confirm un-assign role"
          footer={unAssignDlgFooter}
          visible={showUnAssignDlg}
          modal={true}
          blockScroll
          onHide={() => {
            setShowUnAssignDlg(false);
          }}>
          Are you sure to un-assign {selGrpRoles.length} selected roles from
          current department?
        </Dialog>
      )}
    </>
  );
}
