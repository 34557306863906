// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';

import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';

// import {AuthConst} from '../../service/AppConstant';
import {Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

import {FactoryFilter} from '../common/FactoryFilter';
import {LeanActivity} from './LeanActivity';

type Props = {};

const defFilter = {
  factory: null,
  years: [],
};

export function LeanActivityPage(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  // const [showCfmDlg, setShowCfmDlg] = useState<any>(false);
  const [filter, setFilter] = useState({...defFilter});
  // const [initFilter, setInitFilter] = useState({});

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  // const selLeanActivityRef = useRef();
  // useEffect(() => {
  //   selLeanActivityRef.current = selLeanActivity;
  // }, [selLeanActivity]);

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let initFil = {};
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      initFil['factory'] = factories[0];
    }
    let di: any = Utils.getDateInfo() || {};
    initFil = {
      ...initFil,
      years: [di.year],
    };

    setFilter(initFil);
  }, [auth]);

  const _onFilterAction: EvtHandler = (evt: any) => {
    let {type, value} = evt;
    if (type === 'filterChanged') {
      setFilter({...value});
    }
  };

  // const cfmDlgFooter = (
  //   <div className="text-right">
  //     <Button label="Yes" icon="pi pi-check" onClick={_onDeletLeanActivity} />
  //     <Button
  //       label="No"
  //       icon="pi pi-times"
  //       onClick={(e) => {
  //         setShowCfmDlg(false);
  //       }}
  //     />
  //   </div>
  // );

  // let authLevel = AuthUtils.getAuthLevel({auth});
  // let canEdit =
  //   authLevel < AuthConst.AUTH_LVL_NB ||
  //   AuthUtils.isAuth({auth, roles: ['FACTORY_ADMIN']});

  let {factory} = filter;
  // let showChart = factory && factory.id && years && years.length > 0;

  return (
    <>
      <Toast ref={toastRef} />

      <div className="py-2">
        {/*<LeanAuditFilter
          filter={initFilter}
          showYears={false}
          onAction={_onFilterAction}
        />*/}
        <FactoryFilter
          scope="factory"
          filter={filter}
          showFactory={true}
          onAction={_onFilterAction}
        />
      </div>

      <TabView>
        <TabPanel header="PPH activity">
          <LeanActivity type="PPH" title="PPH activities" factory={factory} />
        </TabPanel>
        <TabPanel header="MLT activity">
          <LeanActivity type="MLT" title="MLT activities" factory={factory} />
        </TabPanel>
      </TabView>
      {/*<Dialog
        header="Confirm delete production line"
        footer={cfmDlgFooter}
        visible={showCfmDlg}
        style={{width: '400px'}}
        modal={true}
        onHide={() => {
          setShowCfmDlg(false);
        }}>
        <span>Are you sure to delete selected production line?</span>
      </Dialog>*/}
    </>
  );
}
