// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
import {Subject} from 'rxjs';
//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';
import {UIUtils, Utils} from '../../../service/Utils';
import type {TblColRender} from '../../types';
import type {ApiProps} from '../../../service/Api';
import {AdminApi} from '../../../service/AdminApi';

type Props = {
  scope?: string,
  byFacGroup?: any,
  byYears?: any,
  byCountry?: any,
  byRegion?: any,
  onSelect?: (evt: any) => void,
};

export function LeanAuditNbList(props: Props): React$Node {
  const {scope, byFacGroup, byYears, byCountry, byRegion} = props;
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();
  const toastRef = useRef();
  const [leanAuditsTblData, setLeanAuditsTblData] = useState([]);
  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchDataLeanAssessment = useCallback(async (options) => {
    let {scope, byFacGroup, byYears, byCountry, byRegion} = options;

    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    if (scope === 'facGroup' && (!byFacGroup || !byFacGroup?.id)) return [];
    if (scope === 'country' && (!byCountry || !byCountry?.id)) return [];
    if (scope === 'region' && (!byRegion || !byRegion?.id)) return [];

    let year = Number(byYears[byYears.length - 1]);
    let reqData = {};
    reqData = {
      ...reqData,
      scope,
      year,
    };
    let name = '';
    if (scope === 'facGroup' && byFacGroup && byFacGroup.id) {
      reqData = {
        ...reqData,
        scope,
        year,
        groupId: byFacGroup.id,
      };
      name = byFacGroup.code;
    }

    if (scope === 'country' && byCountry && byCountry.id) {
      reqData = {
        ...reqData,
        scope,
        year,
        countryId: byCountry.id,
      };
      name = byCountry.code;
    }

    if (scope === 'region' && byRegion && byRegion.id) {
      reqData = {
        ...reqData,
        scope,
        year,
        regionId: byRegion.id,
      };
      name = byRegion.code;
    }

    return await api
      .getLeanScore({
        data: {
          ...reqData,
        },
      })
      .then((resp) => resp.data)
      .then((resp) => {
        //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        //console.log.*$

        return {data, name};
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchKpiDataReg = useCallback(async (options) => {
    let {scope, byYears} = options;
    let year = Number(byYears[byYears.length - 1]);
    let api: ?AdminApi = apiRef.current;
    if (!api || scope === 'factory') {
      return [];
    }

    let joins = [];
    let filters = {
      byType: 'obj.kpiType = :kpiType',
      byYear: 'obj.year = :byYear',
      byQoy: 'obj.qoy = :byQoy1 or obj.qoy = :byQoy2',
    };
    let params = {
      kpiType: {
        type: 'enum:com.nb.lmrs.model.KpiType',
        value: 'LEAN_SCORE',
      },
      byYear: year,
      byQoy1: 1,
      byQoy2: 3,
    };

    let sorts = ['obj.qoy'];
    let start = 0;
    let limit = 0;
    return await api
      .getLeanScoreCard({joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        // //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }

        return [...data.list].map((dt) => dt.targetValue);
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchData = useCallback(
    async (options) => {
      let pm1 = _fetchDataLeanAssessment(options);
      let pm2 = _fetchKpiDataReg(options);

      Promise.all([pm1, pm2]).then((data: any[]) => {
        let [leanAuditData, kpiData] = data;
        if (leanAuditData && leanAuditData.data) {
          let dataTable = [
            {
              name: leanAuditData.name,
              audHoy: 1,
              avgScore: leanAuditData?.data?.score1
                ? leanAuditData.data.score1
                : 0,
              tarScore: kpiData && kpiData.length > 0 ? kpiData[0] : 0,
            },
            {
              name: leanAuditData.name,
              audHoy: 2,
              avgScore: leanAuditData?.data?.score2
                ? leanAuditData.data.score2
                : 0,
              tarScore: kpiData && kpiData.length > 0 ? kpiData[1] : 0,
            },
          ];

          setLeanAuditsTblData([...dataTable]);
        }
      });
    },
    [_fetchDataLeanAssessment, _fetchKpiDataReg],
  );

  const _relScoreCol: TblColRender = (row, col) => {
    let {avgScore} = row;
    return Utils.fmtDecimal(avgScore, 2);
  };

  const _relTargetCol: TblColRender = (row, col) => {
    let {tarScore} = row;
    return Utils.fmtDecimal(tarScore, 2);
  };

  useEffect(() => {
    let subj = new Subject();
    //console.log.*$
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      //console.log.*$
      subj.complete();
    };
  }, [_fetchData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      scope,
      byFacGroup,
      byYears,
      byCountry,
      byRegion,
    });
  }, [scope, byFacGroup, byYears, byCountry, byRegion]);

  //console.log.*$
  return (
    <>
      <Toast ref={toastRef} />
      <DataTable value={leanAuditsTblData}>
        <Column field="name" header="Name"></Column>
        <Column field="audHoy" header="Half of Year"></Column>
        <Column body={_relScoreCol} header="Avg score"></Column>
        <Column body={_relTargetCol} header="Target score"></Column>
      </DataTable>
    </>
  );
}
