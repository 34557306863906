// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
import type {ApiProps} from '../../service/Api';
import {AuthUtils} from '../../service/AuthUtils';
import {AuthConst} from '../../service/AppConstant';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {ManufacturingInnovationForm} from './ManufacturingInnovationForm';
import {ManufacturingInnovationList} from './ManufacturingInnovationList';

type Props = {
    audit:any
};

export function ManufacturingInnovation(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const [selAndonDetail, setSelAndonDetail] = useState<any>();
  const [reload, setReload] = useState(false);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const {audit} = props;

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _onAndonDetailSelected = (evt: any) => {
    setSelAndonDetail(evt.value);
  };

  const _onNewAndonDetail: any = (evt: any) => {
    setSelAndonDetail(null);
  };

  const _onMachLineAction = (evt: any) => {
    setSelAndonDetail(null);
    setReload(!reload);
  };

  const _onDelete: (evt: any) => void = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selAndonDetail.id) {
      return;
    }
    confirmDialog({
      message: 'Are you sure you want to delete item?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .deleteManufacturingInnovation(selAndonDetail.id)
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors} = resp;
            if (errors.length > 0) {
              UIUtils.showError({errors, toast: toastRef.current});
            }
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          })
          .finally(() => {
            setSelAndonDetail(null);
            setReload(!reload);
          });
      },
      reject: () => {},
    });
  };

  let listTitle = 'Available Manufacturing Innovations';
  let authLevel = AuthUtils.getAuthLevel({auth});

  let canEdit =
    authLevel < AuthConst.AUTH_LVL_NB ||
    AuthUtils.isAuth({auth, roles: ['NB_EDITOR', 'NB_ADMIN']});

  return (
    <>
      <Toast ref={toastRef} />

      <div className="p-grid">
        <div className="p-col-12 p-lg-8">
          <ManufacturingInnovationList
            header={listTitle}
            reload={reload}
            audit={audit}
            onSelect={_onAndonDetailSelected}
            selection={selAndonDetail}
          />
          {canEdit && (
            <div className="p-d-flex p-jc-end p-my-2">
              <Button
                label="New item"
                icon="pi pi-plus"
                className="p-ml-2"
                onClick={_onNewAndonDetail}
              />
              {selAndonDetail && (
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  className="p-ml-2"
                  onClick={_onDelete}
                  disabled={selAndonDetail.flag === 1}
                />
              )}
            </div>
          )}
        </div>
        <div className="p-col-12 p-lg-4">
           <ManufacturingInnovationForm
            audit={audit}
            readOnly={!canEdit}
            andonDetail={selAndonDetail}
            onAction={_onMachLineAction}
          />
        </div>
      </div>
    </>
  );
}
