// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
import {Subject} from 'rxjs';
//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  factory?: any,
  byYears?: any[],
  byAudit?: any,
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function LeanAudItemList1(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const {t} = useTranslation();

  const {reload, selection, header, command, factory, byYears} = props;
  ////console.log.*$
  const [leanAudItemsTblData, setLeanAudItemsTblData] = useState([]);

  const [leanAudItemsTblStart, setLeanAudItemsTblStart] = useState(0);
  const [leanAudItemsTblLimit] = useState(10);
  const [leanAudItemsTblNbRows, setLeanAudItemsTblNbRows] = useState(0);
  const [selLeanAudItem, setSelLeanAudItem] = useState();
  const [selLeanAudItems, setSelLeanAudItems] = useState([]);
  const [sumWeight, setSumWeight] = useState(0);
  const [sortMeta, setSortMeta] = useState([
    {
      field: 'obj.rowNo',
      order: 1,
    },
  ]);
  const [sortField, setSortField] = useState('obj.rowNo');
  const [sortOrder, setSortOrder] = useState(1);

  const selMode = props.selectionMode || 'single';
  const {search} = app || '';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  const _fetchAudit = useCallback(async (options) => {
    let {factory, byYears} = options;
    let api1: ?AdminApi = apiRef.current;
    if (!api1) {
      return;
    }
    let start1 = 0;
    let limit1 = 30;
    let joins1 = [];
    let params1 = {};
    let filters1 = {};

    if (factory && factory.id) {
      filters1 = {
        ...filters1,
        byFactory: 'obj.factory.id = :factoryId',
      };
      params1 = {
        ...params1,
        factoryId: {
          type: 'long',
          value: factory.id,
        },
      };
    }

    byYears = byYears || [];
    byYears = byYears.map((it) => Number(it)).filter((it) => !isNaN(it));

    if (byYears.length > 0) {
      filters1 = {
        ...filters1,
        yearsIn: 'obj.audYear in (:audYears)',
      };
      params1 = {
        ...params1,
        audYears: [...byYears],
      };
    }
    //console.log.*$
    return await api1
      .getLeanAudits({
        joins: joins1,
        filters: filters1,
        params: params1,
        start: start1,
        limit: limit1,
        //sorts: ['obj.rowNo'],
      })
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        //console.log.*$
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        return [...data.list];
        // //console.log.*$
        // setLeanAuditsTblData([...data.list]);
        //setAudit([...data.list]);
        ////console.log.*$
        //setLeanAuditsTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchDataTable = useCallback(
    async (options) => {
      await _fetchAudit(options)
        .then((data) => {
          let audits = data || [];
          let auditIds = audits.map((it) => {
            return {
              type: 'long',
              value: it['id'],
            };
          });
          let api: ?AdminApi = apiRef.current;
          if (!api) {
            return;
          }
          let {search, leanAudItemsTblStart, leanAudItemsTblLimit} = options;

          let start = leanAudItemsTblStart;
          let limit = leanAudItemsTblLimit;
          let joins = [];
          let filters = {};
          let params = {
            __sumWeight: 1,
          };

          filters = {
            ...filters,
            byAdit: 'obj.audit.id in (:auditId)',
          };

          params = {
            ...params,
            auditId: auditIds,
          };

          /*  filters = {
        ...filters,
        byAdit1: 'obj.audit.id = :auditId1',
      };
      params = {
        ...params,
        auditId1: {
          type: 'long',
          value: 2,
        },
      };*/

          //let sorts = ['-obj.audit.audYear', '-obj.audit.audMonth'];
          let sorts = ['obj.rowNo'];
          //console.log.*$
          // let sortFds = sortMeta.map((sm) => sm.field);
          //console.log.*$
          api
            .getLeanAudItems({
              search,
              joins,
              filters,
              params,
              start,
              limit,
              sorts,
            })
            .then((resp) => resp.data)
            .then((resp) => {
              let {errors, data} = resp;
              //console.log.*$
              if (errors.length > 0) {
                //console.log.*$
                UIUtils.showError({errors, toast: toastRef.current});
                return;
              }

              // //console.log.*$
              setLeanAudItemsTblData([...data.list]);
              setLeanAudItemsTblNbRows(data.count);
              let sw = data.sumWeight || 0;
              setSumWeight(sw);
            })
            .catch((err) => {
              //console.log.*$
              UIUtils.showError({error: err, toast: toastRef.current});
            });

          //console.log.*$
        })
        .catch(() => {});
    },
    [_fetchAudit],
  );

  useEffect(() => {
    let subj = new Subject();
    //console.log.*$
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchDataTable(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      //console.log.*$
      subj.complete();
    };
  }, [_fetchDataTable]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelLeanAudItem({...selection});
      } else {
        setSelLeanAudItems([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelLeanAudItem(null);
      } else {
        setSelLeanAudItems([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      factory,
      //  byAudit,
      search,
      byYears,
      leanAudItemsTblStart,
      leanAudItemsTblLimit,
      sortMeta,
    });
  }, [
    factory,
    //byAudit,
    search,
    byYears,
    leanAudItemsTblStart,
    leanAudItemsTblLimit,
    reload,
    sortMeta,
  ]);

  const _onLeanAudItemSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelLeanAudItem(evt.value);
    } else {
      setSelLeanAudItems(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    // //console.log.*$
    setLeanAudItemsTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };

  const _renderScoreCol: TblColRender = (row, col) => {
    let {score} = row;
    // let w = !Utils.isEmpty(score) ? Utils.fmtDecimal(score, 4) : 0;
    return Utils.isNum(score) ? Utils.fmtDecimal(score, 2) : null;
  };

  const _renderAuditCol: TblColRender = (row, col) => {
    let {audit} = row;
    return audit ? `${audit.name}` : null;
  };

  const _renderC2bValCol: TblColRender = (row, col) => {
    let {c2bVal} = row;
    let v = Utils.isNum(c2bVal) ? Number(c2bVal) : 0;
    // v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}`;
  };

  const _renderSfValCol: TblColRender = (row, col) => {
    let {sfVal} = row;
    let v = Utils.isNum(sfVal) ? Number(sfVal) : 0;
    // v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}`;
  };

  const _renderOfflineValCol: TblColRender = (row, col) => {
    let {offlineVal} = row;
    let v = Utils.isNum(offlineVal) ? Number(offlineVal) : 0;
    // v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}`;
  };

  const _renderOfflineWgCol: TblColRender = (row, col) => {
    let {offlineWg} = row;
    let v = Utils.isNum(offlineWg) ? Number(offlineWg) : 0;
    v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}%`;
  };

  const _renderOsPressValCol: TblColRender = (row, col) => {
    let {osPressVal} = row;
    let v = Utils.isNum(osPressVal) ? Number(osPressVal) : 0;
    // v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}`;
  };

  let pagingLeft = <span></span>;
  let pagingRight = (
    <span>Total weight: {Utils.fmtDecimal(sumWeight * 100, 2)}%</span>
  );
  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={leanAudItemsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          paginatorLeft={pagingLeft}
          paginatorRight={pagingRight}
          rows={leanAudItemsTblLimit}
          first={leanAudItemsTblStart}
          totalRecords={leanAudItemsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selLeanAudItem}
          onSelectionChange={_onLeanAudItemSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            field="rowNo"
            header="#"
            headerStyle={{width: 60}}
            sortable={true}
            sortField="obj.rowNo"
          />
          {/*<Column
            field="code"
            header="Code"
            headerStyle={{width: 150}}
            sortable={true}
            sortField="obj.code"
          />*/}
          <Column
            field="name"
            header="Name"
            headerStyle={{width: 350}}
            sortable
            sortField="obj.name"
          />
          <Column
            body={_renderScoreCol}
            header="Score"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
            sortable={true}
            sortField="obj.score"
          />
          {/*<Column
            body={_renderWeightCol}
            header="Weight"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
            sortable={true}
            sortField="obj.weight"
          /> */}
          <Column
            body={_renderC2bValCol}
            header="C2B"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          {/*  <Column
            body={_renderC2bWgCol}
            header="C2B %"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
        />  */}
          <Column
            body={_renderSfValCol}
            header="SF"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          {/* <Column
            body={_renderSfWgCol}
            header="SF %"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />  */}
          <Column
            body={_renderOfflineValCol}
            header="Offline"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          {/* <Column
            body={_renderOfflineWgCol}
            header="Offline %"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
         />*/}
          <Column
            body={_renderOsPressValCol}
            header="Outsole"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          {/* <Column
            body={_renderOsPressWgCol}
            header="Outsole %"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
         />*/}
          <Column
            body={_renderAuditCol}
            header="Assessment"
            headerStyle={{width: 250}}
          />
          {/* <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
         /> */}
          {/*<Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          /> */}
        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={leanAudItemsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={leanAudItemsTblLimit}
          first={leanAudItemsTblStart}
          totalRecords={leanAudItemsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selLeanAudItems}
          onSelectionChange={_onLeanAudItemSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            selectionMode="multiple"
            headerStyle={{width: 50}}
            headerClassName="text-left"
            bodyClassName="text-left"></Column>
          <Column
            field="rowNo"
            header="#"
            headerStyle={{width: 60}}
            sortable={true}
            sortField="obj.rowNo"
          />
          <Column
            field="code"
            header="Code"
            headerStyle={{width: 150}}
            sortable={true}
            sortField="obj.code"
          />
          <Column
            field="name"
            header="Name"
            headerStyle={{width: 350}}
            sortable
            sortField="obj.name"
          />
          <Column
            body={_renderScoreCol}
            header="Score"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
            sortable={true}
            sortField="obj.score"
          />
          {/*  <Column
            body={_renderWeightCol}
            header="Weight"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
            sortable={true}
            sortField="obj.weight"
        />   */}
          <Column
            body={_renderC2bValCol}
            header="C2B"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          {/*   <Column
            body={_renderC2bWgCol}
            header="C2B %"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />  */}
          <Column
            body={_renderSfValCol}
            header="SF"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          {/*<Column
            body={_renderSfWgCol}
            header="SF %"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          /> */}
          <Column
            body={_renderOfflineValCol}
            header="Offline"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderOfflineWgCol}
            header="Offline %"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderOsPressValCol}
            header="Outsole"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          {/*} <Column
            body={_renderOsPressWgCol}
            header="Outsole %"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
        />   */}
          <Column
            body={_renderAuditCol}
            header="Assessment"
            headerStyle={{width: 250}}
          />
          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
        </DataTable>
      )}
    </>
  );
}
