// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';
import type {ApiProps} from '../../service/Api';

// import {AuthConst} from '../../service/AppConstant';
//$FlowFixMe[cannot-resolve-module];
import {UIUtils} from '../../service/Utils';
import type {TblColRender} from '../types';
import {AdminApi} from '../../service/AdminApi';
// import {LeanAuditFilter} from './LeanAuditFilter';

type Props = {
  filter: any,
};

export function PphWeightedByMultiFactory(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const [dataWeighted, setDataWeighted] = useState([]);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  const {filter} = props;

  //console.log.*$

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchDataPph = useCallback(async (options) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let {month, year, factories} = options;
    let factoryIds = [];
    if (factories && factories.length > 0) {
      factoryIds = factories.map((fac) => fac.id);
    }

    ////console.log.*$

    api
      .getPphWeightedByMultiFactory({
        data: {year: year, month: month, factoryIds},
      })
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }

        setDataWeighted([data.data]);
      })
      .catch((err) => {
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    let {month, years, factories} = filter;
    let year = Number(years[years.length - 1]);
    _fetchDataPph({month, year, factories});
  }, [filter, _fetchDataPph]);

  const _renderPphPerformanceQ1: TblColRender = (row: any, col: any) => {
    let {q1Performance} = row;
    return q1Performance
      ? Number(Number(q1Performance) * 100).toFixed(0) + '%'
      : null;
  };

  const _renderPphPerformanceQ2: TblColRender = (row: any, col: any) => {
    let {q2Performance} = row;
    return q2Performance
      ? Number(Number(q2Performance) * 100).toFixed(0) + '%'
      : null;
  };

  const _renderPphPerformanceQ3: TblColRender = (row: any, col: any) => {
    let {q3Performance} = row;
    return q3Performance
      ? Number(Number(q3Performance) * 100).toFixed(0) + '%'
      : null;
  };

  const _renderPerformanceYtd: TblColRender = (row: any, col: any) => {
    let {ytdPerformance} = row;
    return ytdPerformance
      ? Number(Number(ytdPerformance) * 100).toFixed(0) + '%'
      : null;
  };

  return (
    <>
      <Toast ref={toastRef} />
      <h2>{filter.years[filter.years.length - 1]} - Quarter 1</h2>
      <DataTable
        value={dataWeighted}
        dataKey="id"
        //lazy={true}
        // paginator={true}
        //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        //  rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
        //  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        //  first={etrRecsTblStart}
        showGridlines
        // rows={etrRecsTblLimit}
        // totalRecords={ertRecsTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        //onPage={_onInvsPaging}
        responsiveLayout="scroll">
        <Column
          field="pphJan"
          header={filter.years[filter.years.length - 1] + ' - Jan'}
        />

        <Column
          field="pphFeb"
          header={filter.years[filter.years.length - 1] + ' - Feb'}
          //body = {_renderPphT2}
        />

        <Column
          field="pphMar"
          header={filter.years[filter.years.length - 1] + ' - Mar'}
          //body = {_renderPphT3}
        />

        <Column
          field="pphQ1"
          header="6 mths roll weighted avg"
          // body = {_renderPphQ}
        />

        <Column
          field="pphQ1Target"
          header="Q1 Target"
          //  body = {_renderPphQTarget}
        />

        <Column
          field="q1Performance"
          header="Q1 Performance"
          body={_renderPphPerformanceQ1}
        />
      </DataTable>

      <h2>{filter.years[filter.years.length - 1]} - Quarter 2</h2>
      <DataTable
        value={dataWeighted}
        dataKey="id"
        //   lazy={true}
        //   paginator={true}
        //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        //  rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
        //  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        //  first={etrRecsTblStart}
        showGridlines
        // rows={etrRecsTblLimit}
        // totalRecords={ertRecsTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        //onPage={_onInvsPaging}
        responsiveLayout="scroll">
        <Column
          field="pphApr"
          header={filter.years[filter.years.length - 1] + ' - Apr'}
        />

        <Column
          field="pphMay"
          header={filter.years[filter.years.length - 1] + ' - May'}
          //body = {_renderPphT2}
        />

        <Column
          field="pphJun"
          header={filter.years[filter.years.length - 1] + ' - Jun'}
          //body = {_renderPphT3}
        />

        <Column
          field="pphQ2"
          header="6 mths roll weighted avg"
          // body = {_renderPphQ}
        />

        <Column
          field="pphQ2Target"
          header="Q2 Target"
          //  body = {_renderPphQTarget}
        />

        <Column
          field="q2Performance"
          header="Q2 Performance"
          body={_renderPphPerformanceQ2}
        />
      </DataTable>

      <h2>{filter.years[filter.years.length - 1]} - Quarter 3</h2>
      <DataTable
        value={dataWeighted}
        dataKey="id"
        //   lazy={true}
        //  paginator={true}
        //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        //  rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
        //  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        //  first={etrRecsTblStart}
        showGridlines
        // rows={etrRecsTblLimit}
        // totalRecords={ertRecsTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        //onPage={_onInvsPaging}
        responsiveLayout="scroll">
        <Column
          field="pphJul"
          header={filter.years[filter.years.length - 1] + ' - Jul'}
        />

        <Column
          field="pphAug"
          header={filter.years[filter.years.length - 1] + ' - Aug'}
          //body = {_renderPphT2}
        />

        <Column
          field="pphSep"
          header={filter.years[filter.years.length - 1] + ' - Sep'}
          //body = {_renderPphT3}
        />

        <Column
          field="pphQ3"
          header="6 mths roll weighted avg"
          // body = {_renderPphQ}
        />

        <Column
          field="pphQ3Target"
          header="Q3 Target"
          //  body = {_renderPphQTarget}
        />

        <Column
          field="q3Performance"
          header="Q3 Performance"
          body={_renderPphPerformanceQ3}
        />
      </DataTable>

      <h2>{filter.years[filter.years.length - 1]} - Quarter 4</h2>
      <DataTable
        value={dataWeighted}
        dataKey="id"
        //lazy={true}
        // paginator={true}
        //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        //  rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
        //  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        //  first={etrRecsTblStart}
        showGridlines
        // rows={etrRecsTblLimit}
        // totalRecords={ertRecsTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        //onPage={_onInvsPaging}
        responsiveLayout="scroll">
        <Column
          field="pphOct"
          header={filter.years[filter.years.length - 1] + ' - Oct'}
        />

        <Column
          field="pphNov"
          header={filter.years[filter.years.length - 1] + ' - Nov'}
          //body = {_renderPphT2}
        />

        <Column
          field="pphDec"
          header={filter.years[filter.years.length - 1] + ' - Dec'}
          //body = {_renderPphT3}
        />

        <Column
          field="pphQ4"
          header="6 mths roll weighted avg"
          // body = {_renderPphQ}
        />

        <Column
          field="pphQ4Target"
          header="Q4 Target"
          //  body = {_renderPphQTarget}
        />

        <Column
          field="q4Performance"
          header="Q4 Performance"
          // body = {_renderPphPerformanceQ4}
        />
      </DataTable>

      <h2>{filter.years[filter.years.length - 1]} - 6 months roll</h2>
      <DataTable
        value={dataWeighted}
        dataKey="id"
        // lazy={true}
        //  paginator={true}
        //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        //  rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
        //  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        //  first={etrRecsTblStart}
        showGridlines
        // rows={etrRecsTblLimit}
        // totalRecords={ertRecsTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        //onPage={_onInvsPaging}
        responsiveLayout="scroll">
        <Column
          field="pphYtd"
          header={filter.years[filter.years.length - 1] + ' Actual YTD PPH'}
          //body={_renderPphYtd}
        />

        <Column
          field="endYearRoadMapTarget"
          header={
            filter.years[filter.years.length - 1] + ' End Year Roadmap Target'
          }
          //body={_renderTargetYtd}
        />

        <Column
          field="ytdPerformance"
          header={
            filter.years[filter.years.length - 1] +
            ' 6 Months Roll  vs Year end Roadmap target'
          }
          body={_renderPerformanceYtd}
        />
      </DataTable>
    </>
  );
}
