
import {SET_FILTER} from './action';

const initialState = {
    ucl: 0,
    lcl: 0,
};

export default function filtermltReducer(
    state: State = initialState,
    action: any,
  ): any {
    switch (action.type) {
       case SET_FILTER:
       return {
          ...state,
          ucl: Number(action.data.ucl),
          lcl: Number(action.data.lcl),
      }


      default:
        return state;
    }
  }