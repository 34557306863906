// @flow
import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {Dialog} from 'primereact/dialog';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {PermList} from './PermList';

type Props = {
  role: any,
  // pItem?: any,
  // project?: any,
};

export function RolePerms(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();

  const {role} = props;
  const [selAvaiPerms, setSelAvaiPerms] = useState([]);
  const [selRolePerms, setSelRolePerms] = useState([]);

  const [reloadRolePermsFlag, setReloadRolePermsFlag] = useState(false);
  const [showAssignDlg, setShowAssignDlg] = useState(false);
  const [showUnAssignDlg, setShowUnAssignDlg] = useState(false);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  const _onRolePermsSelected = (evt: any) => {
    // //console.log.*$
    // setSelRoleGrp(evt.value);
    setSelRolePerms(evt.value);
  };

  const _onAvaiPermsSelected: (evt: any) => void = (evt: any) => {
    //console.log.*$
    setSelAvaiPerms(evt.value);
  };

  const _onAssignPerm: (evt: any) => void = (evt: any) => {
    setShowAssignDlg(false);
    let api: ?AdminApi = apiRef.current;
    if (!api || !role) {
      return;
    }
    if (selAvaiPerms.length < 1) {
      return;
    }
    let permIds = selAvaiPerms.map((r) => r.id);
    api
      .linkRoleToPerms(role.id, [...permIds])
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        setTimeout(() => {
          setSelAvaiPerms([]);
          setReloadRolePermsFlag(!reloadRolePermsFlag);
        }, 100);
        UIUtils.showInfo({detail: 'success', toast: toastRef.current});
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
      });
  };

  const _onUnAssignPerms: (evt: any) => void = (evt: any) => {
    setShowUnAssignDlg(false);
    let api: ?AdminApi = apiRef.current;
    if (!api || !role) {
      return;
    }
    if (selRolePerms.length < 1) {
      return;
    }
    let permIds = selRolePerms.map((r) => r.id);
    api
      .unlinkRoleToPerms(role.id, [...permIds])
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        setTimeout(() => {
          setSelRolePerms([]);
          setReloadRolePermsFlag(!reloadRolePermsFlag);
        }, 100);
        UIUtils.showInfo({detail: 'success', toast: toastRef.current});
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
      });
  };

  const assignDlgFooter = (
    <div>
      <Button
        label={t('g.yes')}
        icon="pi-md-check"
        className="ml-1"
        onClick={_onAssignPerm}
      />
      <Button
        label={t('g.no')}
        icon="pi-md-close"
        className="ml-1"
        onClick={() => {
          setShowAssignDlg(false);
        }}
      />
    </div>
  );

  const unAssignDlgFooter = (
    <div>
      <Button
        label={t('g.yes')}
        icon="pi-md-check"
        className="ml-1"
        onClick={_onUnAssignPerms}
      />
      <Button
        label={t('g.no')}
        icon="pi-md-close"
        className="ml-1"
        onClick={() => {
          setShowUnAssignDlg(false);
        }}
      />
    </div>
  );

  return (
    <>
      <Toast ref={toastRef} />
      {/* <div className="p-grid">
        <div className="p-col-6 p-fluid">
          <div>
            <span className="md-inputfield">
              <InputText />
              <label>PO/SR</label>
            </span>
          </div>
        </div>
      </div> */}
      <div className="p-grid">
        <div className="p-col-12 p-lg-6">
          <PermList
            selectionMode="multi"
            selection={selRolePerms}
            byRole={role}
            header="Assigned permission"
            reload={reloadRolePermsFlag}
            onSelect={_onRolePermsSelected}
          />
          <div className="p-d-flex p-jc-end p-my-2">
            <Button
              label="Un-assign"
              icon="pi pi-minus-circle"
              onClick={(evt) => {
                setShowUnAssignDlg(true);
              }}
              disabled={selRolePerms.length < 1}
            />
          </div>
        </div>
        <div className="p-col-12 p-lg-6">
          <PermList
            selectionMode="multi"
            selection={selAvaiPerms}
            header="Available permissions"
            onSelect={_onAvaiPermsSelected}
          />
          <div className="p-d-flex p-jc-end p-my-2">
            <Button
              label="Assign"
              icon="pi pi-plus-circle"
              onClick={(evt) => {
                setShowAssignDlg(true);
              }}
              disabled={selAvaiPerms.length < 1}
            />
          </div>
        </div>
      </div>

      {selAvaiPerms && (
        <Dialog
          header="Confirm assign role"
          footer={assignDlgFooter}
          visible={showAssignDlg}
          modal={true}
          blockScroll
          onHide={() => {
            setShowAssignDlg(false);
          }}>
          Are you sure to assign {selAvaiPerms.length} selected permission to
          current role?
        </Dialog>
      )}
      {selRolePerms && (
        <Dialog
          header="Confirm un-assign role"
          footer={unAssignDlgFooter}
          visible={showUnAssignDlg}
          modal={true}
          blockScroll
          onHide={() => {
            setShowUnAssignDlg(false);
          }}>
          Are you sure to un-assign {selRolePerms.length} selected permissions
          from current role?
        </Dialog>
      )}
    </>
  );
}
