// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe
import {InputNumber} from 'primereact/inputnumber';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {AutoComplete} from 'primereact/autocomplete';
//$FlowFixMe[cannot-resolve-module]
import {Dropdown} from 'primereact/dropdown';
//$FlowFixMe[cannot-resolve-module]
import moment from 'moment';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {updateAppAction} from '../../service/app/action';

type Props = {
  scope?: string,
  kpiType?: string,
  factory?: any,
  shift?: any,
  line?: any,
  prodKpi: any,
  readOnly?: boolean,
  onAction?: (evt: any) => void,
};

const defProdKpi = {
  __cls__: 'com.nb.lmrs.model.entity.ProdKpi',
  flag: 0,
  summary: '',
  kpiType: 'PPH',
  periodType: 'YEAR',
  year: -1,
  month: -1,
  day: -1,
  wom: -1,
  qoy: -1,
  hoy: -1,
  targetValue: 0,
  value1: 0,
  value2: 0,
  value3: 0,
  intValue: 0,
  intValue1: 0,
  intValue2: 0,
  intValue3: 0,
  operator: null,
  line: null,
  factory: null,
  lineGrp: null,
  shift: null,
};

export function ProdKpiForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  // const cacheKpi = useSelector((state) => state.app.prodKpi);
  let {t} = useTranslation();
  const dispatch = useDispatch();

  const {factory, kpiType, prodKpi, readOnly} = props;
  const scope = props.scope || 'factory';

  const [editProdKpi, setEditProdKpi] = useState<any>();
  const [suggFactories, setSuggFactories] = useState([]);
  const [suggLines, setSuggLines] = useState([]);
  const [suggShifts, setSuggShifts] = useState([]);
  const [avaiKpiTypes, setAvaiKpiTypes] = useState([]);
  const [avaiPeriodTypes, setAvaiPeriodTypes] = useState([]);
  const [avaiWoms, setAvaiWoms] = useState([]);
  const [avaiMonths, setAvaiMonths] = useState([]);
  const [avaiDays, setAvaiDays] = useState([]);
  const [avaiHoys, setAvaiHoys] = useState([]);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  const _initWeekOpts = useCallback((kpiRec) => {
    let {month, year, wom} = kpiRec;
    let weeks = Utils.getWeeksOfMonth({month, year});
    weeks = weeks.map((it) => {
      return {
        ...it,
        label: `W ${it.wom}`,
        note:
          Utils.jsDateToStr(it.dtFrom, 'DD/MM') +
          ' => ' +
          Utils.jsDateToStr(it.dtTo, 'DD/MM'),
      };
    });
    // //console.log.*$
    setAvaiWoms([
      {
        wom: -1,
        label: '--any--',
        note: '',
      },
      ...weeks,
    ]);
    let w = weeks.find((it) => it.wom === wom);
    if (!w) {
      kpiRec['wom'] = -1;
    }
  }, []);

  const _initDayOpts = useCallback((kpiRec) => {
    const {month, year} = kpiRec;
    if (
      !isNaN(year) ||
      Number(year) <= 0 ||
      !isNaN(month) ||
      Number(month) <= 0
    ) {
      let mm = moment()
        .year(year)
        .month(month - 1)
        .date(1);
      let nbDays = mm.daysInMonth();
      let dayOpts = [
        {
          label: '--any--',
          value: -1,
        },
      ];
      for (let i = 1; i <= nbDays; i++) {
        dayOpts.push({
          label: i,
          value: i,
        });
      }
      setAvaiDays([...dayOpts]);
      let {day} = kpiRec;
      let opt = dayOpts.find((it) => it.value === day);
      if (!opt) {
        kpiRec['day'] = -1;
      }
    }
  }, []);

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let types = [
      'PPH',
      'MLT',
      'SI',
      'MINL_RATE',
      'LEAN_SCORE',
      'PO',
      'BTS',
      'PPH_YTD',
      'PPH_YTD_TAR',
      'MLT_YTD_TAR',
    ];
    let typeOpts = types.map((it) => {
      return {
        label: it,
        value: it,
      };
    });
    setAvaiKpiTypes(typeOpts);

    let pTypes = [
      'MONTH',
      'DAY',
      'WEEK',
      // 'QUARTER', 'HALF_YEAR'
    ];
    if (
      kpiType === 'MLT' ||
      kpiType === 'SI' ||
      kpiType === 'PO' ||
      kpiType === 'BTS'
    ) {
      pTypes = ['MONTH'];
    } else if (kpiType === 'LEAN_SCORE') {
      pTypes = ['HALF_YEAR'];
    } else if (kpiType === 'PPH_YTD') {
      pTypes = ['YEAR'];
    } else if (kpiType === 'PPH_YTD_TAR') {
      pTypes = ['YEAR'];
    } else if (kpiType === 'MLT_YTD_TAR') {
      pTypes = ['YEAR'];
    }

    let pTypeOpts = pTypes.map((it) => {
      return {
        label: it,
        value: it,
      };
    });
    setAvaiPeriodTypes(pTypeOpts);

    let mons = [-1];
    for (let i = 1; i <= 12; i++) {
      mons.push(i);
    }
    let monOpts = mons.map((it) => {
      return {label: t(`mon.${it}`), value: it};
    });
    setAvaiMonths([...monOpts]);

    let hoys = [-1, 1, 2];
    let hoyOpts = hoys.map((it) => {
      return {label: t(`hoy.${it}`), value: it};
    });
    setAvaiHoys([...hoyOpts]);
  }, [t, scope, kpiType]);

  useEffect(() => {
    // //console.log.*$
    // //console.log.*$

    let di = Utils.getDateInfo();

    let obj = prodKpi || {};
    obj = {
      ...defProdKpi,
      year: di.year,
      ...obj,
    };

    // //console.log.*$
    let {periodType} = obj;
    let avaiTypes = avaiPeriodTypes.map((it) => it.value);
    if (avaiTypes.length > 0 && !avaiTypes.includes(periodType)) {
      obj['periodType'] = avaiTypes[0];
    }
    if (periodType === 'WEEK') {
      _initWeekOpts(obj);
    } else if (periodType === 'DAY') {
      _initDayOpts(obj);
    }

    let isNew = !obj.id;
    if (isNew) {
      obj['factory'] = factory;
      obj['kpiType'] = kpiType;
    }

    let {line, operator, shift} = obj;
    let fac = obj.factory;
    if (fac && fac.id) {
      fac.label = `${fac.code} :: ${fac.name}`;
    }
    if (line && line.id) {
      line.label = `${line.code} :: ${line.name}`;
    }
    if (shift && shift.id) {
      shift.label = `${shift.code} :: ${shift.name}`;
    }
    if (operator && operator.id) {
      operator.label = `${operator.code} :: ${operator.name}`;
    }

    // //console.log.*$
    setEditProdKpi(obj);
  }, [prodKpi, _initWeekOpts, _initDayOpts, avaiPeriodTypes, kpiType, factory]);

  const _validateProdKpi: () => any = () => {
    let res: any = {...editProdKpi, scope};
    //console.log.*$

    switch (scope) {
      case 'factory':
        if (!res.factory || !res.factory.id) {
          UIUtils.showError({
            detail: 'Please select factory',
            toast: toastRef.current,
          });
          return false;
        }
        Utils.assign(res, {factoryId: res.factory.id});
        break;
      case 'line':
        res = {
          ...res,
          factoryId: factory ? factory.id : 0,
        };
        let {line, shift} = res;
        Utils.assign(res, {lineId: line && line.id ? line.id : undefined});
        Utils.assign(res, {shiftId: shift && shift.id ? shift.id : undefined});
        break;
      case 'shift':
        res = {
          ...res,
          factoryId: factory ? factory.id : 0,
        };
        if (!res.shift || !res.shift.id) {
          UIUtils.showError({
            detail: 'Please select working shift',
            toast: toastRef.current,
          });
          return false;
        }
        Utils.assign(res, {shiftId: res.shift.id});
        break;
      default:
        break;
    }

    let {kpiType} = res;
    if (!kpiType) {
      UIUtils.showError({
        detail: 'Please select KPI type',
        toast: toastRef.current,
      });
      return false;
    }

    let {periodType} = res;
    if (!periodType) {
      UIUtils.showError({
        detail: 'Please select period type',
        toast: toastRef.current,
      });
      return false;
    }

    let chkYear = true;
    let {year} = res;
    if (chkYear && (isNaN(year) || Number(year) < 1)) {
      UIUtils.showError({
        detail: 'Please enter valid year',
        toast: toastRef.current,
      });
      return;
    }

    if (periodType === 'YEAR') {
      res = {
        ...res,
        month: -1,
        day: -1,
        dow: -1,
        dom: -1,
        doy: -1,
        wom: -1,
        woy: -1,
        qoy: -1,
        hoy: -1,
      };
    } else if (periodType === 'MONTH') {
      res = {
        ...res,
        day: -1,
        dow: -1,
        dom: -1,
        doy: -1,
        wom: -1,
        woy: -1,
        qoy: -1,
        hoy: -1,
      };
    } else if (periodType === 'DAY') {
      res = {
        ...res,
        dow: -1,
        dom: -1,
        doy: -1,
        wom: -1,
        woy: -1,
        qoy: -1,
        hoy: -1,
      };
    } else if (periodType === 'WEEK') {
      res = {
        ...res,
        dow: -1,
        dom: -1,
        doy: -1,
        woy: -1,
        qoy: -1,
        hoy: -1,
      };
    }

    let {targetValue} = res;
    if (isNaN(targetValue) || Number(targetValue) < 0) {
      UIUtils.showError({
        detail: 'Invalid target value',
        toast: toastRef.current,
      });
      return false;
    }
    return res;
  };

  const _processFieldChange: (fdName: string, value: any) => void = (
    fdName: string,
    value: any,
  ) => {
    let newRec = {...editProdKpi};
    newRec[fdName] = value;
    let {periodType} = newRec;
    if (
      (fdName === 'month' && periodType === 'WEEK') ||
      (fdName === 'periodType' && value === 'WEEK')
    ) {
      _initWeekOpts(newRec);
    }

    if (
      (fdName === 'month' && periodType === 'DAY') ||
      (fdName === 'periodType' && value === 'DAY')
    ) {
      _initDayOpts(newRec);
    }

    setEditProdKpi(newRec);
  };

  const _onSuggFactories: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getFactories({search, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        data.list.forEach((it) => (it.label = `${it.code} :: ${it.name}`));
        setSuggFactories([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onSuggLines: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let filters = {};
    let params = {};
    let {factory} = editProdKpi;
    if (factory && factory.id) {
      filters = {
        ...filters,
        byFactory: 'obj.factory.id = :factoryId',
      };
      params = {
        ...params,
        factoryId: {
          type: 'long',
          value: factory.id,
        },
      };
    }
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getMachLines({search, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        data.list.forEach((it) => (it.label = `${it.code} :: ${it.name}`));
        setSuggLines([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onSuggShifts: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let filters = {};
    let params = {};
    let {factory} = editProdKpi;
    if (factory && factory.id) {
      filters = {
        ...filters,
        byFactory: 'obj.factory.id = :factoryId',
      };
      params = {
        ...params,
        factoryId: {
          type: 'long',
          value: factory.id,
        },
      };
    }

    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getShifts({search, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        data.list.forEach((it) => (it.label = `${it.code} :: ${it.name}`));
        setSuggShifts([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onSave = (evt: any) => {
    // setShowCfmDlg(false);
    let obj = _validateProdKpi();
    if (!obj) {
      return;
    }
    //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    if (!obj.id) {
      let prodKpi = {
        factory: obj.factory,
        line: obj.line,
        shift: obj.shift,
      };
      dispatch && dispatch(updateAppAction({prodKpi}));
    }

    confirmDialog({
      message: obj.id
        ? 'Are you sure you want to update KPI record?'
        : 'Are you sure you want to create new KPI record?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateProdKpi({...obj})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'prodKpiSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _onCancel: (evt: any) => any = (evt: any) => {
    let {onAction} = props;
    onAction &&
      onAction({
        type: 'canceled',
      });
  };

  if (!editProdKpi) {
    return null;
  }

  const isNew = !editProdKpi.id;
  const saveLbl = isNew ? 'Create' : 'Save';
  let title = isNew ? 'Create KPI record' : 'Update KPI record';
  // //console.log.*$

  let {periodType} = editProdKpi;
  let lineSelDisabled = !factory || !factory.id;
  let shiftSelDisabled = !factory || !factory.id;
  // let operatorSelDisabled = !factory || !factory.id;

  // //console.log.*$
  let showFac = scope === 'factory';
  let showLine = scope === 'line';
  let showShift = scope === 'shift';

  let showMonth = ['MONTH', 'DAY', 'WEEK'].includes(periodType);
  let showDay = ['DAY'].includes(periodType);
  let showWeek = ['WEEK'].includes(periodType);
  let showQuarter = false;
  let showHalfYear = ['HALF_YEAR'].includes(periodType);

  let disaFacSugg = factory && factory.id;
  // //console.log.*$
  // //console.log.*$

  let isMinl = kpiType === 'MINL_RATE';
  let isLeanScore = kpiType === 'LEAN_SCORE';
  return (
    <>
      <Toast ref={toastRef} />
      <Panel header={title}>
        <div className="p-grid py-3 p-fluid">
          {showFac && (
            <div className="col-12">
              <span className="p-float-label">
                <AutoComplete
                  dropdown
                  suggestions={suggFactories}
                  dataKey="id"
                  field="label"
                  value={editProdKpi.factory}
                  completeMethod={_onSuggFactories}
                  onChange={(e) =>
                    setEditProdKpi({
                      ...editProdKpi,
                      factory: e.value,
                      shift: null,
                      line: null,
                      operator: null,
                    })
                  }
                  disabled={readOnly || disaFacSugg}
                />
                <label>Factory</label>
              </span>
            </div>
          )}

          {showLine && (
            <>
              <div className="col-12">
                <span className="p-float-label">
                  <AutoComplete
                    dropdown
                    suggestions={suggLines}
                    field="label"
                    value={editProdKpi.line}
                    completeMethod={_onSuggLines}
                    onChange={(e) =>
                      setEditProdKpi({
                        ...editProdKpi,
                        line: e.value,
                      })
                    }
                    disabled={lineSelDisabled || readOnly}
                  />
                  <label>Line</label>
                </span>
              </div>
              {/*   <div className="col-12">
                <span className="p-float-label">
                  <AutoComplete
                    dropdown
                    suggestions={suggShifts}
                    field="label"
                    value={editProdKpi.shift}
                    completeMethod={_onSuggShifts}
                    onChange={(e) =>
                      setEditProdKpi({
                        ...editProdKpi,
                        shift: e.value,
                      })
                    }
                    disabled={shiftSelDisabled || readOnly}
                  />
                  <label>Shift</label>
                </span>
              </div>
                  */}
            </>
          )}

          {showShift && (
            <div className="col-12">
              <span className="p-float-label">
                <AutoComplete
                  dropdown
                  suggestions={suggShifts}
                  field="label"
                  value={editProdKpi.shift}
                  completeMethod={_onSuggShifts}
                  onChange={(e) =>
                    setEditProdKpi({
                      ...editProdKpi,
                      shift: e.value,
                    })
                  }
                  disabled={shiftSelDisabled || readOnly}
                />
                <label>Shift</label>
              </span>
            </div>
          )}

          {/*<div className="p-col-12">
            <span className="p-float-label">
              <Calendar
                value={editProdKpi.recDate}
                onChange={(e) =>
                  setEditProdKpi({
                    ...editProdKpi,
                    recDate: e.value,
                  })
                }></Calendar>
              <label>Record date</label>
            </span>
          </div>*/}

          {/*<div className="p-col-12">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                suggestions={suggShifts}
                field="label"
                value={editProdKpi.shift}
                completeMethod={_onSuggShifts}
                onChange={(e) =>
                  setEditProdKpi({
                    ...editProdKpi,
                    shift: e.value,
                  })
                }
                disabled={shiftSelDisabled}
              />
              <label>Shift</label>
            </span>
          </div>*/}

          {/*<div className="p-col-12">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                suggestions={suggOperators}
                field="label"
                value={editProdKpi.operator}
                completeMethod={_onSuggOperators}
                onChange={(e) =>
                  setEditProdKpi({
                    ...editProdKpi,
                    operator: e.value,
                    opName: e.value.name,
                  })
                }
                disabled={operatorSelDisabled}
              />
              <label>Operator</label>
            </span>
          </div>*/}

          <div className="p-col-12 xl:col-6">
            <span className="p-float-label">
              <Dropdown
                optionLabel="label"
                optionValue="value"
                value={editProdKpi.kpiType}
                options={avaiKpiTypes}
                onChange={(e) =>
                  setEditProdKpi({
                    ...editProdKpi,
                    kpiType: e.value,
                  })
                }
                disabled={true}
              />
              <label>KPI Type</label>
            </span>
          </div>

          <div className="p-col-12 xl:col-6">
            <span className="p-float-label">
              <Dropdown
                optionLabel="label"
                optionValue="value"
                value={editProdKpi.periodType}
                options={avaiPeriodTypes}
                onChange={(e) => _processFieldChange('periodType', e.value)}
                disabled={readOnly}
              />
              <label>Period Type</label>
            </span>
          </div>

          <div className="p-col-12 xl:col-6">
            <span className="p-float-label">
              <InputText
                keyfilter="pint"
                value={editProdKpi.year}
                onChange={(e) => {
                  setEditProdKpi({...editProdKpi, year: e.target.value});
                }}
                disabled={readOnly}
              />
              <label>Year</label>
            </span>
          </div>

          {showMonth && (
            <div className="p-col-12 xl:col-6">
              <span className="p-float-label">
                <Dropdown
                  optionLabel="label"
                  optionValue="value"
                  value={editProdKpi.month}
                  options={avaiMonths}
                  onChange={(e) => _processFieldChange('month', e.value)}
                  disabled={readOnly}
                />
                <label>Month</label>
              </span>
            </div>
          )}

          {showDay && (
            <div className="p-col-12 xl:col-6">
              <span className="p-float-label">
                <Dropdown
                  optionLabel="label"
                  optionValue="value"
                  value={editProdKpi.day}
                  options={avaiDays}
                  onChange={(e) =>
                    setEditProdKpi({
                      ...editProdKpi,
                      day: e.value,
                    })
                  }
                  disabled={readOnly}
                />
                <label>Day</label>
              </span>
            </div>
          )}

          {showWeek && (
            <div className="p-col-12 xl:col-6">
              <span className="p-float-label">
                <Dropdown
                  optionLabel="label"
                  optionValue="wom"
                  value={editProdKpi.wom}
                  options={avaiWoms}
                  onChange={(e) => _processFieldChange('wom', e.value)}
                  disabled={readOnly}
                />
                <label>Week</label>
              </span>
            </div>
          )}

          {showQuarter && (
            <div className="p-col-12 xl:col-6">
              <span className="p-float-label">
                <InputText
                  keyfilter="pint"
                  value={editProdKpi.qoy}
                  onChange={(e) => {
                    setEditProdKpi({...editProdKpi, qoy: e.target.value});
                  }}
                  disabled={readOnly}
                />
                <label>Quarter</label>
              </span>
            </div>
          )}

          {showHalfYear && (
            <div className="p-col-12 xl:col-6">
              <span className="p-float-label">
                <Dropdown
                  optionLabel="label"
                  optionValue="value"
                  value={editProdKpi.hoy}
                  options={avaiHoys}
                  onChange={(e) => _processFieldChange('hoy', e.value)}
                  disabled={readOnly}
                />
                <label>Half-year</label>
              </span>
            </div>
          )}

          <div className="p-col-12 xl:col-6">
            <span className="p-float-label">
              <InputNumber
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                value={editProdKpi.targetValue}
                onChange={(e) => {
                  setEditProdKpi({
                    ...editProdKpi,
                    targetValue: e.value,
                  });
                }}
                disabled={readOnly || isLeanScore}
              />
              <label>{isMinl ? 'Target rate' : 'Target'} </label>
            </span>
          </div>

          {isMinl && (
            <div className="p-col-12 xl:col-6">
              <span className="p-float-label">
                <InputNumber
                  value={editProdKpi.intValue1}
                  onChange={(e) => {
                    setEditProdKpi({
                      ...editProdKpi,
                      intValue: e.value,
                    });
                  }}
                  disabled={readOnly}
                />
                <label>Num of Big Lines</label>
              </span>
            </div>
          )}

          {isMinl && (
            <div className="p-col-12 xl:col-6">
              <span className="p-float-label">
                <InputNumber
                  value={editProdKpi.value1}
                  onChange={(e) => {
                    setEditProdKpi({
                      ...editProdKpi,
                      value1: e.value,
                    });
                  }}
                  disabled={readOnly}
                />
                <label>Big Line Cap.</label>
              </span>
            </div>
          )}

          {isMinl && (
            <div className="p-col-12 xl:col-6">
              <span className="p-float-label">
                <InputNumber
                  value={editProdKpi.value2}
                  onChange={(e) => {
                    setEditProdKpi({
                      ...editProdKpi,
                      value2: e.value,
                    });
                  }}
                  disabled={readOnly}
                />
                <label>Mini Line Cap.</label>
              </span>
            </div>
          )}

          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputTextarea
                  rows={2}
                  value={editProdKpi.description}
                  onChange={(e) => {
                    setEditProdKpi({
                      ...editProdKpi,
                      description: e.target.value,
                    });
                  }}
                  disabled={readOnly}
                />
                <label>Summary</label>
              </span>
            </div>
          </div>
        </div>
      </Panel>
      {!readOnly && (
        <div className="p-d-flex p-jc-end p-my-2">
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
          <Button
            label="Cancel"
            icon="pi pi-times"
            className="p-ml-2"
            onClick={_onCancel}
          />
        </div>
      )}
    </>
  );
}
