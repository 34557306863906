// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
// import {TabView, TabPanel} from 'primereact/tabview';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';

import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

import {FactoryUserList} from '../setting/FactoryUserList';
import {FactoryUserForm} from '../setting/FactoryUserForm';

type Props = {
  profile?: any,
};

export function UserFactories(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  // const {t} = useTranslation();

  const {profile} = props;

  const [selFactoryUser, setSelFactoryUser] = useState();
  const [reloadFlag, setReloadFlag] = useState(false);
  // const [tabInd, setTabInd] = useState(0);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    setSelFactoryUser(null);
  }, [profile]);

  const _onFactoryUserSelected = (evt: any) => {
    // //console.log.*$
    setSelFactoryUser(evt.value);
  };

  const _onNewFactoryUser = (evt: any) => {
    setSelFactoryUser(null);
  };

  const _onFactoryUserAction = (evt: any) => {
    // //console.log.*$
    // setTabInd(1);
    // let {value} = evt;
    setReloadFlag(!reloadFlag);
    setSelFactoryUser(null);
  };

  const _onDeleteFactoryUser: EvtHandler = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selFactoryUser || !selFactoryUser.id) {
      return;
    }
    confirmDialog({
      message: 'Are you sure you want to remove user access record?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .deleteFactoryUser(selFactoryUser.id)
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            setSelFactoryUser(null);
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          })
          .finally(() => {
            setReloadFlag(!reloadFlag);
          });
      },
      reject: () => {},
    });
  };

  // //console.log.*$

  const tblHeader = 'Granted Factories';
  return (
    <>
      <Toast ref={toastRef} />
      <div className="grid">
        <div className="col-12 xl:col-8">
          <FactoryUserList
            header={tblHeader}
            byProfile={profile}
            selection={selFactoryUser}
            reload={reloadFlag}
            onSelect={_onFactoryUserSelected}
          />
          <div className="p-d-flex p-jc-end p-my-2">
            <Button label="New" icon="pi pi-plus" onClick={_onNewFactoryUser} />
            <Button
              label="Remove"
              className="ml-2"
              icon="pi pi-plus"
              onClick={_onDeleteFactoryUser}
              disabled={!selFactoryUser || !selFactoryUser.id}
            />
          </div>
        </div>
        <div className="col-12 xl:col-4">
          <FactoryUserForm
            profile={profile}
            onAction={_onFactoryUserAction}
            factoryUser={selFactoryUser}
          />
        </div>
      </div>
    </>
  );
}
