// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {Dropdown} from 'primereact/dropdown';
import type {ApiProps} from '../../service/Api';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  readOnly?: boolean,
  warningThreshold: any,
  onAction: (evt: any) => void,
};
const defWarningThreshold = {
  __cls__: 'com.nb.lmrs.model.entity.WarningThreshold',
  flag: 0,
  timeDuration: 0,
  thresholdType: 'PPH',
};
export function WarningThresholdForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {readOnly, warningThreshold} = props;
  const [editWarningThreshold, setEditWarningThreshold] = useState<any>();

  const [avaiThresholdTypes, setAvaiThresholdTypes] = useState([]);
  let {t} = useTranslation();
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let warningThresholdType = [
      {
        label: 'PPH',
        value: 'PPH',
      },
      {
        label: 'MLT',
        value: 'MLT',
      },
      {
        label: 'LEAN_SCORE',
        value: 'LEAN_SCORE',
      },
      {
        label: 'MINL_RATE',
        value: 'MINL_RATE',
      },
      {
        label: 'BTS',
        value: 'BTS',
      },
    ];
    setAvaiThresholdTypes(warningThresholdType);
  }, [t]);

  useEffect(() => {
    let curWarningThreshold = warningThreshold || {};
    curWarningThreshold = {
      ...defWarningThreshold,
      ...curWarningThreshold,
      timeDuration: curWarningThreshold?.timeDuration / 24 / 60 / 60 || 0,
    };
    setEditWarningThreshold(curWarningThreshold);
  }, [warningThreshold]);

  const _onSave = (evt: any) => {
    let fac = _validateMltSetPoint();
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    console.log(editWarningThreshold);

    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update warning threshold?'
        : 'Are you sure you want to create warning threshold?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateWarningThreshold({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'machLineSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _validateMltSetPoint: () => any = () => {
    let obj = {...editWarningThreshold};
    if (obj.timeDuration !== 0) {
      obj = {
        ...obj,
        timeDuration: obj.timeDuration * 24 * 60 * 60,
      };
    }

    if (obj.timeDuration === 0) {
      UIUtils.showError({
        detail: 'Time duration must be not equal to 0',
        toast: toastRef.current,
      });
      return false;
    }

    return obj;
  };

  if (!editWarningThreshold) {
    return null;
  }

  const saveLbl = editWarningThreshold.id
    ? 'Update Warning Threshold'
    : 'Create Warning Threshold';

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="Warning Threshold Form">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <div class="p-grid">
              <div class="p-col-6">
                <span className="p-float-label">
                  <Dropdown
                    optionLabel="label"
                    optionValue="value"
                    value={editWarningThreshold.thresholdType}
                    options={avaiThresholdTypes}
                    onChange={(e) =>
                      setEditWarningThreshold({
                        ...editWarningThreshold,
                        thresholdType: e.value,
                      })
                    }
                  />
                  <label>Threshold Type</label>
                </span>
              </div>

              <div class="p-col-6">
                <span className="p-float-label">
                  <InputText
                    keyfilter="pint"
                    // optionValue="value"
                    value={editWarningThreshold.timeDuration}
                    onChange={(e) =>
                      setEditWarningThreshold({
                        ...editWarningThreshold,
                        timeDuration: e.target.value,
                      })
                    }
                  />
                  <label>Day</label>
                </span>
              </div>
            </div>
          </div>

          <div className="p-col-12"></div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
