// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';
import {Subject} from 'rxjs';
//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  byId?: any,
  byFactory?: any,
  byMonth?: number,
  byYear?: number,
  byLiveFlag?: number,
  defSort?: any[],
  selectionMode?: string,
  selection?: any,
  byAlarmStatus?: any[],
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
  onAction?: (evt: any) => void,
};

export function AlarmList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();
  const [alarmsTblData, setAlarmsTblData] = useState([]);
  const [alarmsTblStart, setAlarmsTblStart] = useState(0);
  const [alarmsTblLimit, setAlarmsTblLimit] = useState(15);
  const [alarmsTblNbRows, setAlarmsTblNbRows] = useState(0);
  const [selProdRec, setSelProdRec] = useState();
  const [selProdRecs, setSelProdRecs] = useState([]);
  const [sortMeta, setSortMeta] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);

  const {
    byFactory,
    byLiveFlag,
    defSort,
    selection,
    reload,
    header,
    command,
    byId,
    byMonth,
    byYear,
    byAlarmStatus,
  } = props;
  const selMode = props.selectionMode || 'single';
  const toastRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    if (defSort && defSort.length > 0) {
      setSortMeta([...defSort]);
      setSortField(defSort[0].field);
      setSortOrder(defSort[0].order);
    }
  }, [defSort]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelProdRec({...selection});
      } else {
        setSelProdRecs([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelProdRec(null);
      } else {
        setSelProdRecs([]);
      }
    }
  }, [selection, selMode]);

  const _fetchData = useCallback((options) => {
    let {
      byFactory,
      byYear,
      byMonth,
     // byLiveFlag,
      alarmsTblStart,
      alarmsTblLimit,
     // byId,
      byAlarmStatus,
      sortMeta,
    } = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let joins = [];

    let filters = {};
    let params = {};
    if (byFactory && byFactory.id) {
      filters = {
        ...filters,
        byFactory: 'obj.factory.id = :factoryId',
      };
      params = {
        ...params,
        factoryId: {
          type: 'long',
          value: byFactory.id,
        },
      };
    }

    if (byYear > 0 && byYear !== undefined) {
      filters = {
        ...filters,
        year: 'obj.year = :year',
      };

      params = {
        ...params,
        year: Number(byYear),
      };
    }

    if (byMonth > 0 && byMonth !== undefined) {
      filters = {
        ...filters,
        byMonth: 'obj.month = :month',
      };

      params = {
        ...params,
        month: Number(byMonth),
      };
    }

    if (byAlarmStatus && byAlarmStatus.length > 0) {
      filters = {
        ...filters,
        byAlarmStatus: 'obj.status in (:status)',
      };

      params = {
        ...params,
        status: byAlarmStatus.map((status) => {
          return {
            type: 'enum:com.nb.lmrs.model.AlarmStatus',
            value: status,
          };
        }),
      };
    }

    // if (byLiveFlag !== undefined) {
    //   filters = {
    //     ...filters,
    //     liveFlag: 'obj.flag = :liveFlag',
    //   };
    //   params = {
    //     ...params,
    //     liveFlag: byLiveFlag,
    //   };
    // }

    let start = alarmsTblStart;
    let limit = alarmsTblLimit;

    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });
    // //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);
    //console.log.*$

    api
      .getAlarms({joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }

        console.log(data);

        setAlarmsTblData([...data.list]);
        setAlarmsTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }

    //_fetchData({ byFactory, byLine, byLines, byLiveFlag, ...defSort });
  }, [command]);
  ////////////
  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(400)).subscribe({
      next: (data) => {
        _fetchData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      subj.complete();
    };
  }, [_fetchData]);

  useEffect(() => {
    // //console.log.*$
    // //console.log.*$
    let fetchDataSubj: any = fetchDataEvtRef.current;
    if (!fetchDataSubj) {
      return;
    }
    fetchDataSubj.next({
      byFactory,
      byYear,
      byMonth,
      byAlarmStatus,
      byLiveFlag,
      alarmsTblStart,
      alarmsTblLimit,
      byId,
      sortMeta,
    });
  }, [
    byFactory,
    byYear,
    byMonth,
    byLiveFlag,
    alarmsTblStart,
    alarmsTblLimit,
    reload,
    byAlarmStatus,
    byId,
    sortMeta,
  ]);

  const _onProdRecSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelProdRec(evt.value);
    } else {
      setSelProdRecs(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    //console.log.*$
    setAlarmsTblLimit(evt.rows);
    setAlarmsTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
    let {onAction} = props;
    onAction &&
      onAction({
        type: 'sortChanged',
        value: [...newMeta],
      });
  };

  const _renderPphCol: TblColRender = (row: any, col: any) => {
    let {pph} = row;
    return pph ? Utils.round(pph) : null;
  };

  const _renderFactoryCol: TblColRender = (row: any, col: any) => {
    let {factory} = row;
    return factory ? `${factory.name}` : null;
  };

  const _renderLineCol: TblColRender = (row: any, col: any) => {
    let {line} = row;
    return line ? `${line.name}` : null;
  };

  const _renderRecDateCol: TblColRender = (row: any, col: any) => {
    let {recDate} = row;
    return recDate ? Utils.reFmtDate(recDate, 'DD/MM/YY', false) : null;
  };

  // const _renderShiftCol: TblColRender = (row: any, col: any) => {
  //   let {shift} = row;
  //   return shift ? `${shift.name}` : null;
  // };

  // const _renderOperatorCol: TblColRender = (row: any, col: any) => {
  //   let {operator} = row;
  //   return operator ? `${operator.code} :: ${operator.name}` : null;
  // };

  const _renderCrCol: TblColRender = (row: any, col: any) => {
    let {createdAt} = row;
    return createdAt ? Utils.reFmtDate(createdAt, 'DD/MM/YY HH:mm') : null;
  };

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header}
          value={alarmsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={alarmsTblLimit}
          first={alarmsTblStart}
          totalRecords={alarmsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selProdRec}
          onSelectionChange={_onProdRecSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            body={_renderRecDateCol}
            header="Date"
            headerStyle={{width: 80}}
            sortable
            sortField="obj.recDate"
          />
          <Column
            field="nbHours"
            header="Hour(s)"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 60}}
          />
          <Column
            field="nbProds"
            header="Output"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 80}}
          />

          <Column
            field="outSi"
            header="Output SI"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 80}}
          />

          <Column
            body={_renderPphCol}
            header="PPH"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 80}}
          />

          {/*<Column
            body={_renderOperatorCol}
            header="Operator"
            headerStyle={{width: 200}}
          />*/}

          {/* <Column
            body={_renderShiftCol}
            header="Shift"
            headerStyle={{width: 100}}
          /> */}

          <Column
            body={_renderLineCol}
            header="Line"
            headerStyle={{width: 200}}
          />

          <Column
            body={_renderFactoryCol}
            header="Factory"
            headerStyle={{width: 200}}
          />

          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />

          <Column
            body={_renderCrCol}
            header="Created On"
            headerStyle={{width: 120}}
            sortable
            sortField="obj.createdAt"
          />

          <Column
            field="createdBy"
            header="Created By"
            headerStyle={{width: 100}}
          />

          <Column
            field="id"
            header="#"
            headerStyle={{width: 60}}
            headerClassName="text-end"
            bodyClassName="text-end"
          />
        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header}
          value={alarmsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={alarmsTblLimit}
          first={alarmsTblStart}
          totalRecords={alarmsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selProdRecs}
          onSelectionChange={_onProdRecSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            body={_renderRecDateCol}
            header="Date"
            headerStyle={{width: 80}}
            sortable
            sortField="obj.recDate"
          />
          <Column header="Alarm Type" field="alarmType" />
          <Column
            body={_renderFactoryCol}
            header="Factory"
            headerStyle={{width: 200}}
          />
          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />
          <Column
            body={_renderCrCol}
            header="Created On"
            headerStyle={{width: 120}}
            sortable
            sortField="obj.createdAt"
          />
          <Column
            field="createdBy"
            header="Created By"
            headerStyle={{width: 100}}
          />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 60}}
            headerClassName="text-end"
            bodyClassName="text-end"
          />
        </DataTable>
      )}
    </>
  );
}
