// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {Dropdown} from 'primereact/dropdown';
import type {ApiProps} from '../../service/Api';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  readOnly?: boolean,
  mltSetPoint: any,
  onAction: (evt: any) => void,
};

const defMltSetPoint = {
  __cls__: 'com.nb.lmrs.model.entity.MltSetPoint',
  flag: 0,
  month: '',
  year: '',
  value: 0,
};

export function SetPointMltForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {readOnly, mltSetPoint} = props;
  const [editMltSetPoint, setEditMltSetPoint] = useState<any>();
  const [avaiMonths, setAvaiMonths] = useState([]);
  let {t} = useTranslation();
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let mons = [-1];
    for (let i = 1; i <= 12; i++) {
      mons.push(i);
    }
    let monOpts = mons.map((it) => {
      return {label: t(`mon.${it}`), value: it};
    });
    setAvaiMonths([...monOpts]);
  }, [t]);

  useEffect(() => {
    // //console.log.*$
    let curMltSetPoint = mltSetPoint || {};
    curMltSetPoint = {
      ...defMltSetPoint,
      ...curMltSetPoint,
    };
    //console.log.*$
    setEditMltSetPoint(curMltSetPoint);
  }, [mltSetPoint]);

  const _onSave = (evt: any) => {
    let fac = _validateMltSetPoint();
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    //console.log.*$
    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update production line?'
        : 'Are you sure you want to create production line?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateMltSetPoint({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'machLineSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _validateMltSetPoint: () => any = () => {
    let obj = {...editMltSetPoint};
    if (obj.month === '') {
      UIUtils.showError({
        detail: 'Please enter the month',
        toast: toastRef.current,
      });
      return false;
    }

    if (obj.year === '') {
      UIUtils.showError({
        detail: 'Please enter the year',
        toast: toastRef.current,
      });
      return false;
    }

    if (obj.value === '') {
      UIUtils.showError({
        detail: 'Please enter value',
        toast: toastRef.current,
      });
      return false;
    }

    return obj;
  };

  if (!editMltSetPoint) {
    return null;
  }

  const saveLbl = editMltSetPoint.id
    ? 'Update Set Point MLT '
    : 'Create Set Point MLT ';
  //const isNew = !editMachLine.id;
  // //console.log.*$

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="Set Point MLT">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <div class="p-grid">
              <div class="p-col-6">
                <span className="p-float-label">
                  <Dropdown
                    optionLabel="label"
                    optionValue="value"
                    value={editMltSetPoint.month}
                    options={avaiMonths}
                    onChange={(e) => {
                      setEditMltSetPoint({...editMltSetPoint, month: e.value});
                    }}
                    // disabled={readOnly}
                  />
                  <label>Month</label>
                </span>
              </div>
              <div class="p-col-6">
                <span className="p-float-label">
                  <InputText
                    keyfilter="pint"
                    value={editMltSetPoint.year}
                    onChange={(e) => {
                      setEditMltSetPoint({
                        ...editMltSetPoint,
                        year: e.target.value,
                      });
                    }}
                    // disabled={readOnly}
                  />
                  <label>Year</label>
                </span>
              </div>
            </div>
          </div>
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                keyfilter="pint"
                value={editMltSetPoint.value}
                onChange={(e) => {
                  setEditMltSetPoint({
                    ...editMltSetPoint,
                    value: e.target.value,
                  });
                }}
                // disabled={!isNew || readOnly}
              />
              <label>Value</label>
            </span>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
