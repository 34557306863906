// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
import {Subject} from 'rxjs';
//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  byAudit?: any,
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function QuickChangeOverReportList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const {t} = useTranslation();

  const {byAudit, reload, selection, header, command} = props;

  const [leanAudItemsTblData, setLeanAudItemsTblData] = useState([]);
  const [leanAudItemsTblStart, setLeanAudItemsTblStart] = useState(0);
  const [leanAudItemsTblLimit] = useState(10);
  const [leanAudItemsTblNbRows, setLeanAudItemsTblNbRows] = useState(0);
  const [selLeanAudItem, setSelLeanAudItem] = useState();
  const [selLeanAudItems, setSelLeanAudItems] = useState([]);
  const [sortMeta, setSortMeta] = useState([
    {
      field: 'obj.rowNo',
      order: 1,
    },
  ]);
  const [sortField, setSortField] = useState('obj.rowNo');
  const [sortOrder, setSortOrder] = useState(1);

  const selMode = props.selectionMode || 'single';
  const {search} = app || '';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {

    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;

  }, [conf, auth]);

  const _fetchData = useCallback(async (options) => {

    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let {
      byAudit,
      search,
      leanAudItemsTblStart,
      leanAudItemsTblLimit,
      //sortMeta,
    } = options;

    let start = leanAudItemsTblStart;
    let limit = leanAudItemsTblLimit;
    let joins = [];
    let filters = {};
    let params = {};

    if (byAudit && byAudit.id) {
      filters = {
        ...filters,
        byAdit: 'obj.quickChangeOverAudit.id = :auditId'

      };
      params = {
        ...params,
        auditId: {
          type: 'long',
          value: byAudit.id,
        },

      };
    }

     let sorts = [];


    api
      .getQuickOverChangeReports({search, joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }

        setLeanAudItemsTblData([...data.list]);
        setLeanAudItemsTblNbRows(data.count);

   ;
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    let subj = new Subject();
    //console.log.*$
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      //console.log.*$
      subj.complete();
    };
  }, [_fetchData]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelLeanAudItem({...selection});
      } else {
        setSelLeanAudItems([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelLeanAudItem(null);
      } else {
        setSelLeanAudItems([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      byAudit,
      search,
      leanAudItemsTblStart,
      leanAudItemsTblLimit,
      sortMeta,
    });
  }, [
    byAudit,
    search,
    leanAudItemsTblStart,
    leanAudItemsTblLimit,
    reload,
    sortMeta,
  ]);

  const _onLeanAudItemSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelLeanAudItem(evt.value);
    } else {
      setSelLeanAudItems(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    // //console.log.*$
    setLeanAudItemsTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };


  const _renderOsPressValCol = (row, col) => {
    let {outsoleScore} = row;
    return Utils.isNum(outsoleScore) ? Utils.fmtDecimal(outsoleScore, 2) : null;
    }

  const _renderC2bCol = (row, col) => {
    let {c2bScore} = row;
    return Utils.isNum(c2bScore) ? Utils.fmtDecimal(c2bScore, 2) : null;
  }

  const _renderTreatmentCol = (row, col) => {
    let {treatmentScore} = row;
    return Utils.isNum(treatmentScore) ? Utils.fmtDecimal(treatmentScore, 2) : null;
  }

    const _renderFinalCol = (row, col) => {
    let {finalScore} = row;
    return Utils.isNum(finalScore) ? Utils.fmtDecimal(finalScore, 2) : null;
    }



  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={leanAudItemsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={leanAudItemsTblLimit}
          first={leanAudItemsTblStart}
          totalRecords={leanAudItemsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selLeanAudItem}
          onSelectionChange={_onLeanAudItemSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>

          <Column
            body={_renderC2bCol}
            header="C2B"
            headerStyle={{width: '90px'}}

          />

           <Column
            body={_renderTreatmentCol}
            header="Treatment"
            headerStyle={{width: '90px'}}

          />
           <Column
            body={_renderOsPressValCol}
            header="Outsole press"
            headerStyle={{width: '90px'}}

          />

           <Column
            body={_renderFinalCol}
            header="Final Score"
            headerStyle={{width: '90px'}}

          />


        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={leanAudItemsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={leanAudItemsTblLimit}
          first={leanAudItemsTblStart}
          totalRecords={leanAudItemsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selLeanAudItems}
          onSelectionChange={_onLeanAudItemSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            body={_renderC2bCol}
            header="C2B"
            headerStyle={{width: '90px'}}

          />

           <Column
            body={_renderTreatmentCol}
            header="Treatment"
            headerStyle={{width: '90px'}}

          />
           <Column
            body={_renderOsPressValCol}
            header="Outsole press"
            headerStyle={{width: '90px'}}

          />

           <Column
            body={_renderFinalCol}
            header="Final Score"
            headerStyle={{width: '90px'}}

          />
        </DataTable>
      )}
    </>
  );
}
