// @flow
import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  selectionMode?: string,
  selection?: any,
  byProfile?: any,
  byUserGrp?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function RoleList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();
  const [rolesTblData, setRolesTblData] = useState([]);
  const [rolesTblStart, setRolesTblStart] = useState(0);
  const [rolesTblLimit] = useState(10);
  const [rolesTblNbRows, setRolesTblNbRows] = useState(0);
  const [selRole, setSelRole] = useState();
  const [selRoles, setSelRoles] = useState();
  const [sortMeta, setSortMeta] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);

  const {byProfile, byUserGrp, reload, selection, header, command} = props;
  const selMode = props.selectionMode || 'single';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelRole({...selection});
      } else {
        setSelRoles([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelRole(null);
      } else {
        setSelRoles([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    // //console.log.*$
    // //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let start = rolesTblStart;
    let limit = rolesTblLimit;

    let filters = {
      exclSysRoles: 'obj.code not in (:exclSysRoles)',
    };
    let params = {
      exclSysRoles: ['SUPER_ADMIN'],
    };

    if (byProfile && byProfile.id) {
      filters = {
        ...filters,
        byProfile:
          'obj.id in (select lnk.role.id from UserRoleLnk lnk' +
          ' where lnk.user.login = :login)',
      };
      params = {
        ...params,
        login: byProfile.login,
      };
    }

    if (byUserGrp) {
      filters = {
        ...filters,
        byUserGrp:
          'exists (select 1 from UserGrpRoleLnk lnk where lnk.role.id = obj.id' +
          ' and lnk.group.id = :grpId)',
      };
      params = {
        ...params,
        grpId: {
          type: 'long',
          value: byUserGrp.id,
        },
      };
    }

    // //console.log.*$

    let joins = [];
    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });
    // //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);

    api
      .getRoles({start, limit, filters, params, joins, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        // //console.log.*$
        setRolesTblData([...data.list]);
        setRolesTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, [rolesTblStart, rolesTblLimit, reload, byProfile, byUserGrp, sortMeta]);

  const _onSelect = (evt: any) => {
    if (selMode === 'single') {
      setSelRole({...evt.value});
    } else {
      setSelRoles([...evt.value]);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onRolesPaging = (evt: any) => {
    // //console.log.*$
    setRolesTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header || t('vdrole.title')}
          value={rolesTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={rolesTblLimit}
          first={rolesTblStart}
          totalRecords={rolesTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selRole}
          onSelectionChange={_onSelect}
          onPage={_onRolesPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            field="code"
            header="ID"
            style={{width: 150}}
            sortable
            sortField="obj.code"
          />
          <Column field="name" header="Name" style={{width: 200}} />
          <Column
            field="description"
            header="Description"
            style={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerClassName="text-right pr-4"
            bodyClassName="text-right"
            style={{width: 90}}
          />
        </DataTable>
      )}
      {selMode !== 'single' && (
        <DataTable
          header={header || t('vdrole.title')}
          value={rolesTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={rolesTblLimit}
          first={rolesTblStart}
          totalRecords={rolesTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selRoles}
          onSelectionChange={_onSelect}
          onPage={_onRolesPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            selectionMode="multiple"
            headerStyle={{width: 50}}
            headerClassName="text-left"
            bodyClassName="text-left"></Column>
          <Column
            field="code"
            header="ID"
            style={{width: 150}}
            sortable
            sortField="obj.code"
          />
          <Column field="name" header="Name" style={{width: 200}} />
          <Column
            field="description"
            header="Description"
            style={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerClassName="text-right pr-4"
            bodyClassName="text-right"
            style={{width: 90}}
          />
        </DataTable>
      )}
    </>
  );
}
