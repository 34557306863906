// @flow
import React, {useState, useEffect, useCallback, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';
import {Subject} from 'rxjs';
//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  byId?: any,
  byFactory?: any,
  byModel?: any,
  byModels?: any[],
  byLiveFlag?: number,
  defSort?: any[],
  selectionMode?: string,
  selection?: any,
  byYear?: number,
  byMonth?: number,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
  onAction?: (evt: any) => void,
};

export function ProdRecMdList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  // const app = useSelector((state) => state.app);
  const fetchDataEvtRef = useRef();

  const [prodRecsTblData, setProdRecsTblData] = useState([]);
  const [prodRecsTblStart, setProdRecsTblStart] = useState(0);
  const [prodRecsTblLimit, setProdRecsTblLimit] = useState(15);
  const [prodRecsTblNbRows, setProdRecsTblNbRows] = useState(0);
  const [selProdRec, setSelProdRec] = useState();
  const [selProdRecs, setSelProdRecs] = useState([]);
  const [sortMeta, setSortMeta] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);

  const {
    byFactory,
    byModel,
    byModels,
    byLiveFlag,
    defSort,
    selection,
    reload,
    header,
    byYear,
    byMonth,
    byId,
    command,
  } = props;
  const selMode = props.selectionMode || 'single';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    if (defSort && defSort.length > 0) {
      setSortMeta([...defSort]);
      setSortField(defSort[0].field);
      setSortOrder(defSort[0].order);
    }
  }, [defSort]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelProdRec({...selection});
      } else {
        setSelProdRecs([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelProdRec(null);
      } else {
        setSelProdRecs([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  const _fetchData = useCallback((options) => {
    let {
      byFactory,
      byModel,
      byModels,
      byLiveFlag,
      byYear,
      byMonth,
      prodRecsTblStart,
      prodRecsTblLimit,
      byId,
      sortMeta,
    } = options;

    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let joins = [
      // {
      //   type: 'left join',
      //   expr: 'obj.factory',
      //   alias: 'factory',
      // },
      // {
      //   type: 'left join',
      //   expr: 'obj.line',
      //   alias: 'line',
      // },
    ];

    let filters = {};
    let params = {};
    if (byFactory && byFactory.id) {
      filters = {
        ...filters,
        byFactory: 'obj.factoryId = :factoryId',
      };
      params = {
        ...params,
        factoryId: {
          type: 'long',
          value: byFactory.id,
        },
      };
    }

    if (byModel && byModel.id) {
      filters = {
        ...filters,
        byModel: 'obj.modelId = :modelId',
      };
      params = {
        ...params,
        modelId: {
          type: 'long',
          value: byModel.id,
        },
      };
    }

    if (byModels && byModels.length > 0) {
      filters = {
        ...filters,
        byModels: 'obj.modelId in (:modelIds)',
      };
      let modelIds = byModels.map((it) => {
        return {
          type: 'long',
          value: it.id,
        };
      });
      params = {
        ...params,
        modelIds,
      };
    }

    if (byId && byId !== undefined) {
      filters = {
        ...filters,
        byId: 'obj.id = :byId',
      };
      params = {
        ...params,
        byId: {
          type: 'long',
          value: byId,
        },
      };
    }
    if (byLiveFlag !== undefined) {
      filters = {
        ...filters,
        liveFlag: 'obj.flag = :liveFlag',
      };
      params = {
        ...params,
        liveFlag: byLiveFlag,
      };
    }

    if (byYear > 0) {
      filters = {
        ...filters,
        byYear: 'obj.year = :cYear',
      };
      params = {
        ...params,
        cYear: Number(byYear),
      };
    }

    if (byMonth > 0) {
      filters = {
        ...filters,
        byMonth: 'obj.month = :cMonth',
      };

      params = {
        ...params,
        cMonth: Number(byMonth),
      };
    }

    let start = prodRecsTblStart;
    let limit = prodRecsTblLimit;

    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });
    // //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);
    //console.log.*$
    api
      .getProdRecsMd({joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        //console.log.*$
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        const {list, factoryMap, lineMap, modelMap, operatorMap, shiftMap} =
          data;
        list.forEach((rec) => {
          if (rec.factoryId) {
            rec['factory'] = factoryMap[rec.factoryId];
          }
          if (rec.lineId) {
            rec['line'] = lineMap[rec.lineId];
          }
          if (rec.modelId) {
            rec['model'] = modelMap[rec.modelId];
          }
          if (rec.shiftId) {
            rec['shift'] = shiftMap[rec.shiftId];
          }
          if (rec.operatorId) {
            rec['operator'] = operatorMap[rec.operatorId];
          }
        });
        // //console.log.*$
        setProdRecsTblData([...data.list]);
        // //console.log.*$
        setProdRecsTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  const _onProdRecSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelProdRec(evt.value);
    } else {
      setSelProdRecs(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  useEffect(() => {
    let fetchDataSubj: any = fetchDataEvtRef.current;
    if (!fetchDataSubj) {
      return;
    }
    fetchDataSubj.next({
      byFactory,
      byModel,
      byModels,
      byLiveFlag,
      byYear,
      byMonth,
      prodRecsTblStart,
      prodRecsTblLimit,
      byId,
      sortMeta,
    });
  }, [
    byFactory,
    byModel,
    byModels,
    byLiveFlag,
    byYear,
    byMonth,
    prodRecsTblStart,
    prodRecsTblLimit,
    reload,
    byId,
    sortMeta,
  ]);

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(400)).subscribe({
      next: (data) => {
        _fetchData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      subj.complete();
    };
  }, [_fetchData]);

  const _onInvsPaging = (evt: any) => {
    //console.log.*$
    setProdRecsTblLimit(evt.rows);
    setProdRecsTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
    let {onAction} = props;
    onAction &&
      onAction({
        type: 'sortChanged',
        value: [...newMeta],
      });
  };

  const _renderPphCol: TblColRender = (row: any, col: any) => {
    let {pph} = row;
    return pph ? Utils.round(pph) : null;
  };

  const _renderFactoryCol: TblColRender = (row: any, col: any) => {
    let {factory} = row;
    return factory ? `${factory.name}` : null;
  };

  const _renderStyleCol: TblColRender = (row: any, col: any) => {
    let {model} = row;
    return model && model.style ? `${model.style}` : null;
  };

  const _renderMaterialCol: TblColRender = (row: any, col: any) => {
    let {model} = row;
    return model && model.material ? `${model.material}` : null;
  };

  const _renderModelCol: TblColRender = (row: any, col: any) => {
    let {model} = row;
    return model ? `${model.name}` : null;
  };

  const _renderRecDateCol: TblColRender = (row: any, col: any) => {
    let {recDate} = row;
    return recDate ? Utils.reFmtDate(recDate, 'DD/MM/YY', false) : null;
  };

  // const _renderShiftCol: TblColRender = (row: any, col: any) => {
  //   let {shift} = row;
  //   return shift ? `${shift.name}` : null;
  // };

  // const _renderOperatorCol: TblColRender = (row: any, col: any) => {
  //   let {operator} = row;
  //   return operator ? `${operator.code} :: ${operator.name}` : null;
  // };

  const _renderCrCol: TblColRender = (row: any, col: any) => {
    let {createdAt} = row;
    return createdAt ? Utils.reFmtDate(createdAt, 'DD/MM/YY HH:mm') : null;
  };

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header}
          value={prodRecsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rowsPerPageOptions={[10, 15, 20, 25, 50, 100, 1000, 5000]}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={prodRecsTblLimit}
          first={prodRecsTblStart}
          totalRecords={prodRecsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selProdRec}
          onSelectionChange={_onProdRecSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            body={_renderRecDateCol}
            header="Date"
            headerStyle={{width: 80}}
            sortable
            sortField="obj.recDate"
          />
          <Column
            field="nbHours"
            header="Hour(s)"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 60}}
          />
          <Column
            field="nbProds"
            header="Output"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 80}}
          />

          <Column
            body={_renderPphCol}
            header="PPH"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 80}}
          />

          <Column
            field="pureie"
            header="Pure IE"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 80}}
          />

          {/*<Column
            body={_renderOperatorCol}
            header="Operator"
            headerStyle={{width: 200}}
          />*/}

          {/* <Column
            body={_renderShiftCol}
            header="Shift"
            headerStyle={{width: 100}}
          /> */}

          <Column
            body={_renderModelCol}
            header="Model"
            headerStyle={{width: 200}}
          />

          <Column
            body={_renderStyleCol}
            header="Style"
            headerStyle={{width: 200}}
          />

          <Column
            body={_renderMaterialCol}
            header="Material"
            headerStyle={{width: 150}}
          />

          <Column
            body={_renderFactoryCol}
            header="Factory"
            headerStyle={{width: 200}}
          />

          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />

          <Column
            body={_renderCrCol}
            header="Created On"
            headerStyle={{width: 120}}
            sortable
            sortField="obj.createdAt"
          />

          <Column
            field="createdBy"
            header="Created By"
            headerStyle={{width: 100}}
          />

          <Column
            field="id"
            header="#"
            headerStyle={{width: 60}}
            headerClassName="text-end"
            bodyClassName="text-end"
          />
        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header}
          value={prodRecsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rowsPerPageOptions={[10, 15, 20, 25, 50, 100, 1000, 5000]}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={prodRecsTblLimit}
          first={prodRecsTblStart}
          totalRecords={prodRecsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selProdRecs}
          onSelectionChange={_onProdRecSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            selectionMode="multiple"
            headerStyle={{width: 50}}
            headerClassName="text-left"
            bodyClassName="text-left"></Column>
          <Column
            body={_renderRecDateCol}
            header="Date"
            headerStyle={{width: 80}}
            sortable
            sortField="obj.recDate"
          />
          <Column
            field="nbHours"
            header="Hour(s)"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 60}}
          />
          <Column
            field="nbProds"
            header="Output"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 80}}
          />

          <Column
            body={_renderPphCol}
            header="PPH"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 80}}
          />

          <Column
            field="pureie"
            header="Pure IE"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 80}}
          />

          {/*<Column
            body={_renderOperatorCol}
            header="Operator"
            headerStyle={{width: 200}}
          />*/}

          {/* <Column body={_renderShiftCol} header="Shift" headerStyle={{width: 100}} /> */}

          <Column
            body={_renderModelCol}
            header="Model"
            headerStyle={{width: 200}}
          />

          <Column
            body={_renderStyleCol}
            header="Style"
            headerStyle={{width: 200}}
          />

          <Column
            body={_renderMaterialCol}
            header="Material"
            headerStyle={{width: 150}}
          />

          <Column
            body={_renderFactoryCol}
            header="Factory"
            headerStyle={{width: 200}}
          />
          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />
          <Column
            body={_renderCrCol}
            header="Created On"
            headerStyle={{width: 120}}
            sortable
            sortField="obj.createdAt"
          />
          <Column
            field="createdBy"
            header="Created By"
            headerStyle={{width: 100}}
          />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 60}}
            headerClassName="text-end"
            bodyClassName="text-end"
          />
        </DataTable>
      )}
    </>
  );
}
