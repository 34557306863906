// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
// import {Dialog} from 'primereact/dialog';

//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {Card} from 'primereact/card';
import type {ApiProps} from '../../service/Api';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';


type Props = {};
const defaultMailSetting = {
  mailFrom: '',
  smtpProtocol: 'smtp',
  smtpHost: '',
  smtpPort: '587',
  timeout: '10000',
  enableTls: true,
  username: '',
  tlsVersion: 'TLSv1.1',
  enableProxy: false,
  providerId: 'CUSTOM',
  proxyHost: '',
  proxyPort: null,
  proxyUser: '',
  proxyPassword: '',
  enableOauth2: false,
  clientId: '',
  clientSecret: '',
  providerTenantId: '',
  authUri: '',
  tokenUri: '',
  scope: [],
  redirectUri: '',
  tokenGenerated: false,
  password: '',
};
const defaultAppConfig = {
  __cls__: 'com.nb.lmrs.model.entity.AppConfig',
  key: 'mail',
  value: '',
};

export function MailSetting(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const [reload, setReload] = useState(false);
  const [mailSetting, setMailSetting] = useState(defaultMailSetting);
  const [appConfig, setAppConfig] = useState(defaultAppConfig);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;

  }, [conf, auth]);
  useEffect(() => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = '';
    let sorts = [];
    let start = 0;
    let limit = 0;
    let joins = [];
    let filters = {
      byKey: 'obj.key = :key',
    };
    let params = {
      key: 'mail',
    };
    api
      .getAppConfig({search, joins, start, limit,filters,params,sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;

        if (errors.length > 0) {
          //  UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        if (data.list && data.list.length > 0) {
          let mail = JSON.parse(data.list[0].value);
          setAppConfig({...data.list[0]});
          setMailSetting({...mail});
        }
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, [reload]);


  const handleSave = () => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }


    let editAppConfig = {
      ...appConfig,
      value: JSON.stringify(mailSetting),
    };

    api
      .updateAppConfig({...editAppConfig})
      .then((resp) => resp.data)
      .then((resp) => {
        let { errors} = resp;
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        setReload(!reload);
        UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <>
      <Toast ref={toastRef} />

      <Card>
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={mailSetting.mailFrom}
                onChange={(e) => {
                  setMailSetting({...mailSetting, mailFrom: e.target.value});
                }}
              />
              <label>Mail From</label>
            </span>
          </div>
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={mailSetting.smtpHost}
                onChange={(e) => {
                  setMailSetting({...mailSetting, smtpHost: e.target.value});
                }}
              />
              <label>SMTP Host</label>
            </span>
          </div>
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={mailSetting.username}
                onChange={(e) => {
                  setMailSetting({...mailSetting, username: e.target.value});
                }}
              />
              <label>Username</label>
            </span>
          </div>
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={mailSetting.password}
                onChange={(e) => {
                  setMailSetting({...mailSetting, password: e.target.value});
                }}
                type="password"
              />
              <label>Password</label>
            </span>
          </div>
          <Button label="Save" onClick={handleSave} />
        </div>
      </Card>
    </>
  );
}
