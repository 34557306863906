// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
import {Subject} from 'rxjs';
//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  byYears?: any[],
  byFactory?: any,
  byFactories?: any[],
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function MltActivityList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);

  const {byYears, byFactory, byFactories} = props;

  const [sortField, setSortField] = useState('obj.plFinDate');
  const [sortOrder, setSortOrder] = useState(1);
  const [leanActivitiesTblData, setLeanActivitiesTblData] = useState([]);
  const [leanActivitiesTblStart, setLeanActivitiesTblStart] = useState(0);
  const [leanActivitiesTblLimit] = useState(1000);
  const [leanActivitiesTblNbRows, setLeanActivitiesTblNbRows] = useState(0);

  const {reload, header} = props;
  const {search} = app || '';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  const [sortMeta, setSortMeta] = useState([
    {
      field: 'obj.plFinDate',
      order: 1,
    },
  ]);

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    //let newMeta = [];
    newMeta = [
      ...newMeta,
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
    ];
    //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta([...newMeta]);
  };

  const _fetchData = useCallback(async (options) => {
    // //console.log.*$
    // //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let {
      byYears,
      byFactory,
      byFactories,
      search,
      leanActivitiesTblStart,
      leanActivitiesTblLimit,
      sortMeta,
    } = options;
    let start = leanActivitiesTblStart;
    let limit = leanActivitiesTblLimit;

    let joins = [];
    let filters = {};
    let params = {};

    let byType = 'MLT';
    if (byType) {
      filters = {
        ...filters,
        byType: 'obj.type = :byType',
      };
      params = {
        ...params,
        byType: {
          type: 'enum:com.nb.lmrs.model.ActivityType',
          value: byType,
        },
      };
    }

    byYears = (byYears || []).map((it) => Number(it));
    if (byYears.length > 0) {
      filters = {
        ...filters,
        byYears: 'YEAR(obj.plStaDate) in (:years)',
      };
      params = {
        ...params,
        years: [...byYears],
      };
    }

    let factories = byFactories || [];
    if (byFactory && byFactory.id) {
      factories.push(byFactory);
    }
    if (factories.length > 0) {
      filters = {
        ...filters,
        byFactories: 'obj.factory.id in (:byFacIds)',
      };
      let byFacIds = factories.map((it) => {
        return {
          type: 'long',
          value: it.id,
        };
      });
      params = {
        ...params,
        byFacIds,
      };
    }

    //let sorts = ['-obj.plFinDate'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });

    //console.log.*$

    // let sortFds = sortMeta.map((sm) => sm.field);

    api
      .getLeanActivities({
        search,
        joins,
        filters,
        params,
        start,
        limit,
        sorts,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        setLeanActivitiesTblData([...data.list]);
        setLeanActivitiesTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    let subj = new Subject();
    //console.log.*$
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      //console.log.*$
      subj.complete();
    };
  }, [_fetchData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      byYears,
      byFactory,
      byFactories,
      search,
      leanActivitiesTblStart,
      leanActivitiesTblLimit,
      sortMeta,
    });
  }, [
    byYears,
    byFactory,
    byFactories,
    search,
    leanActivitiesTblStart,
    leanActivitiesTblLimit,
    sortMeta,
    reload,
  ]);

  const _onInvsPaging = (evt: any) => {
    // //console.log.*$
    setLeanActivitiesTblStart(evt.first);
  };

  const _renderPlFinDateCol: TblColRender = (row, col) => {
    let {plFinDate} = row;
    return plFinDate ? Utils.reFmtDate(plFinDate, 'DD/MM/YYYY') : null;
  };

  return (
    <>
      <Toast ref={toastRef} />
      <DataTable
        header={header}
        value={leanActivitiesTblData}
        dataKey="id"
        lazy={true}
        paginator={false}
        rows={leanActivitiesTblLimit}
        first={leanActivitiesTblStart}
        totalRecords={leanActivitiesTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        onPage={_onInvsPaging}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={_onSort}>
        <Column
          field="name"
          header="Supportive Initiatives for MLT"
          headerStyle={{width: 150}}
          headerClassName="text-center"
        />
        <Column
          field="impaDesc"
          header="MLT Impact (reduction days)"
          headerStyle={{width: 200}}
          headerClassName="text-center"
        />
        <Column
          field="description"
          header="Current status
          (Trial, Approved, where) "
          headerStyle={{width: 200}}
          headerClassName="text-center"
        />
        <Column
          body={_renderPlFinDateCol}
          header="Due Date"
          headerStyle={{width: 100}}
          headerClassName="text-center"
          sortable={true}
          sortField="obj.plFinDate"
        />
        {/* <Column field="id" header="#" /> */}
      </DataTable>
    </>
  );
}
