// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';
//$FlowFixMe
import {debounceTime} from 'rxjs/operators';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {Chart} from 'primereact/chart';
//$FlowFixMe
import {Button} from 'primereact/button';

//$FlowFixMe[cannot-resolve-module]
import ChartDataLabels from 'chartjs-plugin-datalabels';

import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
// import {AuthUtils} from '../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {getColor} from '../../service/util/color';

import {getChartOptions, kpiColors, pphColors} from './CommonChart';

type Props = {
  scope?: string,
  regions?: any[],
  countries?: any[],
  facGroups?: any[],
  factories?: any[],
  lines?: any[],
  month?: any,
  year?: any,
  expanded?: boolean,
  onAction?: EvtHandler,
};

export const biglColors: any = [
  'purple-700',
  'purple-500',
  'purple-300',
  'purple-100',
  'purple-800',
  'purple-600',
  'purple-400',
  'purple-200',
  'purple-50',
  'purple-900',
].map((c) => getColor(c));

export const minlColors: any = [
  'yellow-700',
  'yellow-500',
  'yellow-300',
  'yellow-100',
  'yellow-800',
  'yellow-600',
  'yellow-400',
  'yellow-200',
  'yellow-50',
  'yellow-900',
].map((c) => getColor(c));

export const minlRmColors: any = [
  'red-700',
  'red-500',
  'red-300',
  'red-100',
  'red-900',
].map((c) => getColor(c));

export const minlRateColors: any = [
  'green-700',
  'green-500',
  'green-300',
  'green-100',
  'green-800',
  'green-600',
  'green-400',
  'green-200',
  'green-50',
  'green-900',
].map((c) => getColor(c));

export const minlVolRateColors: any = [
  'blue-700',
  'blue-500',
  'blue-300',
  'blue-100',
  'blue-800',
  'blue-600',
  'blue-400',
  'blue-200',
  'blue-50',
  'blue-900',
].map((c) => getColor(c));

let chartOptions = getChartOptions();
// const scales = {
//   y: {
//     type: 'linear',
//     display: true,
//     position: 'left',
//   },
//   y1: {
//     type: 'linear',
//     display: true,
//     position: 'right',
//     // grid line settings
//     grid: {
//       drawOnChartArea: false, // only want the grid lines for one axis to show up
//     },
//   },
// };
// chartOptions = {
//   ...chartOptions,
//   ...scales,
// };

const chartPlugins = [ChartDataLabels];

export function BtsChartMonthly(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();
  const {
    scope,
    regions,
    countries,
    facGroups,
    factories,
    lines,
    month,
    year,
    expanded,
    onAction,
  } = props;

  const [barData, setBarData] = useState();
  // const [chartOptions] = useState(getChartOptions());

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchBtsData = useCallback(async (options: any) => {
    let {
      scope,
      regions,
      region,
      countries,
      country,
      facGroups,
      facGroup,
      factory,
      factories,
      month,
      year,
    } = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    if (regions?.length === 0 && scope === 'region') return [];
    if (countries?.length === 0 && scope === 'country') return [];
    if (facGroups?.length === 0 && scope === 'facGroup') return [];
    if (factories?.length === 0 && scope === 'factory') return [];

    if (regions && regions.length > 0 && !region && scope === 'region') {
      let pms = regions.map((reg) => {
        return _fetchBtsData({
          ...options,
          region: reg,
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    if (countries && countries.length > 0 && !country && scope === 'country') {
      let pms = countries.map((coun) => {
        return _fetchBtsData({
          ...options,
          country: coun,
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    if (
      facGroups &&
      facGroups.length > 0 &&
      !facGroup &&
      scope === 'facGroup'
    ) {
      let pms = facGroups.map((gr) => {
        return _fetchBtsData({
          ...options,
          facGroup: gr,
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    if (factories && factories.length > 0 && !factory && scope === 'factory') {
      let pms = factories.map((fac) => {
        return _fetchBtsData({
          ...options,
          factory: fac,
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    let reqData = {
      scope,
      year,
      month,
    };
    let serie = '';
    if (region) {
      reqData = {
        ...reqData,
        regionId: region.id,
      };

      serie = region.code;
    }

    if (country) {
      reqData = {
        ...reqData,
        countryId: country.id,
      };

      serie = country.code;
    }

    if (facGroup) {
      reqData = {
        ...reqData,
        groupId: facGroup.id,
      };

      serie = facGroup.code;
    }

    if (factory) {
      reqData = {
        ...reqData,
        factoryId: factory.id,
      };

      serie = factory.code;
    }

    return await api
      .getProdsRecBtsByMonth({data: {...reqData}})
      .then((resp) => resp.data)
      .then((resp) => {
        //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        return [
          {
            serie,
            ...data,
          },
        ];
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchChartData = useCallback(
    async (options: any) => {
      let months = [];
      for (let i = 0; i < 12; i++) {
        months.push(i + 1);
      }

      let labels = months.map((it) => it);
      labels = labels.map((it) => t(`mon.${it}`));
      labels.push('6 months roll');
      let btsDatas = (await _fetchBtsData(options)) || [];
      if (!btsDatas || btsDatas.length === 0) return;
      let btsDatasets = btsDatas.map((bts, ind) => {
        let ci = ind % pphColors.length;
        let results = [...bts.data].map((res) => {
          return res;
        });

        return {
          label: `BTS-${bts.serie}`,
          backgroundColor: pphColors[ci],
          data: results,
          datalabels: {
            anchor: 'end',
            align: 'top',
            offset: 1,
            color: '#ff0000',
          },
        };
      });

      let kpiDatasets = [];
      if (
        btsDatas &&
        btsDatas.length > 0 &&
        (scope === 'region' || scope === 'country' || scope === 'facGroup')
      ) {
        let kpis = btsDatas[0].kpis || [];
        kpiDatasets = [
          {
            type: 'line',
            label: 'Target',
            backgroundColor: kpiColors[0],
            borderColor: kpiColors[0],
            borderWidth: 1,
            data: [...kpis],
            datalabels: {
              display: false,
            },
          },
        ];
      }

      if (btsDatas && btsDatas.length > 0 && scope === 'factory') {
        kpiDatasets = btsDatas.map((bts, ind) => {
          let ci = ind % kpiColors.length;
          let results = [...bts.kpis].map((res) => {
            if (Utils.isNum(res)) {
              return res;
            } else {
              return 0;
            }
          });
          return {
            type: 'line',
            label: `Target - ${bts.serie}`,
            backgroundColor: kpiColors[ci],
            borderColor: kpiColors[ci],
            borderWidth: 1,
            data: results,
            datalabels: {
              display: false,
            },
          };
        });
      }

      let chartData = {
        labels,
        datasets: [...kpiDatasets, ...btsDatasets],
      };
      setBarData(chartData);
    },
    [t, _fetchBtsData, scope],
  );

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(800)).subscribe({
      next: (data) => {
        _fetchChartData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      subj.complete();
    };
  }, [_fetchChartData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      scope,
      regions,
      countries,
      facGroups,
      factories,
      lines,
      month,
      year,
    });
  }, [scope, regions, countries, facGroups, factories, lines, month, year]);

  const _onToggleExpand: EvtHandler = (evt: any) => {
    onAction &&
      onAction({
        type: 'toggleExpand',
        value: !expanded,
      });
  };

  const iconName = expanded
    ? 'pi pi-angle-double-left'
    : 'pi pi-angle-double-right';

  const icons = (
    <Button
      icon={iconName}
      className="p-button-rounded p-button-text"
      style={{padding: 0, height: '1.2rem', color: '#FFFFFF'}}
      onClick={_onToggleExpand}
    />
  );

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="BTS" icons={icons}>
        <Chart
          type="bar"
          data={barData}
          options={chartOptions}
          plugins={chartPlugins}></Chart>
      </Panel>
    </>
  );
}
