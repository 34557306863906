// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../../service/AuthUtils';
// import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

import {RoleList} from './RoleList';
import {RoleForm} from './RoleForm';
import {RolePerms} from './RolePerms';

type Props = {
  type: string,
  // pItem?: any,
  // project?: any,
};

export function Role(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  // const {t} = useTranslation();
  const [selRole, setSelRole] = useState();

  const [reloadFlag, setReloadFlag] = useState(false);
  const [tabInd, setTabInd] = useState(0);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  const _onRoleSelected = (evt: any) => {
    // //console.log.*$
    setSelRole(evt.value);
  };

  const _onNewRole = (evt: any) => {
    setSelRole(null);
  };

  const _onRoleAction = (evt: any) => {
    // //console.log.*$
    // setTabInd(1);
    // let {value} = evt;
    setReloadFlag(!reloadFlag);
    setSelRole(null);
  };

  const tblHeader = 'Available roles';
  return (
    <>
      <Toast ref={toastRef} />
      {/* <div className="p-grid">
        <div className="p-col-6 p-fluid">
          <div>
            <span className="md-inputfield">
              <InputText />
              <label>PO/SR</label>
            </span>
          </div>
        </div>
      </div> */}
      <div className="p-grid">
        <div className="p-col-12 p-lg-8">
          <RoleList
            header={tblHeader}
            selection={selRole}
            reload={reloadFlag}
            onSelect={_onRoleSelected}
          />
          <div className="p-d-flex p-jc-end p-my-2">
            <Button label="New" icon="pi pi-plus" onClick={_onNewRole} />
          </div>
        </div>
        <div className="p-col-12 p-lg-4">
          <RoleForm onAction={_onRoleAction} role={selRole} />
        </div>
      </div>
      {false && (
        <TabView
          activeIndex={tabInd}
          onTabChange={(e) => setTabInd(e.index)}
          className="p-nogutter">
          <TabPanel header="Permissions">
            {selRole && <RolePerms role={selRole} />}
          </TabPanel>
        </TabView>
      )}
    </>
  );
}
