// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
// import {TabView, TabPanel} from 'primereact/tabview';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';

import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
// import {AuthUtils} from '../service/AuthUtils';
import {Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

import {ChartFilter} from './ChartFilter';
import {PphDaily} from './PphDaily';
import {PphWeekly} from './PphWeekly';
import {PphMonthly} from './PphMonthly';
import {PphDailyData} from './PphDailyData';
import {PphWeeklyData} from './PphWeeklyData';
import {PphMonthlyData} from './PphMonthlyData';

type Props = {
  scope?: string,
};

export function OverviewChart(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const scope = props.scope || 'global';

  const [filter, setFilter] = useState();

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let {year, month} = Utils.getDateInfo();
    setFilter({
      year,
      month,
    });
  }, []);

  const _onFilterAction: EvtHandler = (evt: any) => {
    //console.log.*$
    let {type, value} = evt;
    if (type === 'filterChanged') {
      setFilter({...value});
    }
  };

  if (!filter) {
    return null;
  }

  let filter2: any = {...filter};

  return (
    <>
      <Toast ref={toastRef} />
      <div className="p-my-2">
        <ChartFilter scope={scope} filter={filter} onAction={_onFilterAction} />
      </div>
      <div className="grid">
        <div className="col-12">
          <PphDaily
            scope={scope}
            countries={filter2.countries}
            factories={filter2.factories}
            lines={filter2.lines}
            year={filter2.year}
            month={filter2.month}
          />
          <Panel header="Data" toggleable={true} collapsed={true}>
            <PphDailyData
              scope={scope}
              countries={filter2.countries}
              factories={filter2.factories}
              lines={filter2.lines}
              year={filter2.year}
              month={filter2.month}
            />
          </Panel>
        </div>
        <div className="col-12 pt-5">
          <PphWeekly
            scope={scope}
            countries={filter2.countries}
            factories={filter2.factories}
            lines={filter2.lines}
            year={filter2.year}
            month={filter2.month}
          />
          <Panel header="Data" toggleable={true} collapsed={true}>
            <PphWeeklyData
              scope={scope}
              countries={filter2.countries}
              factories={filter2.factories}
              lines={filter2.lines}
              year={filter2.year}
              month={filter2.month}
            />
          </Panel>
        </div>
        <div className="col-12 pt-5">
          <PphMonthly
            scope={scope}
            countries={filter2.countries}
            factories={filter2.factories}
            lines={filter2.lines}
            year={filter2.year}
          />
          <Panel header="Data" toggleable={true} collapsed={true}>
            <PphMonthlyData
              scope={scope}
              factories={filter2.factories}
              lines={filter2.lines}
              countries={filter2.countries}
              year={filter2.year}
            />
          </Panel>
        </div>
      </div>
    </>
  );
}
