//@flow
import React from 'react';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
// import {Button} from 'primereact/button';
// import {RTLContext} from './App';

type Props = {
  colorMode?: string,
};

export function AppFooter(props: Props): React$Node {
  // const isRTL = useContext(RTLContext);

  return (
    <div className="layout-footer p-d-flex p-ai-center p-py-2 p-px-4 p-shadow-2">
      <img
        id="footer-logo"
        src={`assets/layout/images/nb-logo-red-100.png`}
        alt="nb-footer-logo"
        style={{height: 35}}
      />
      <div className="flex-1 text-right font-medium">
        Copyright &copy; Aucontech Co., Ltd. All right reserved.
      </div>
      {/*<Button
        type="button"
        icon="pi pi-github fs-large"
        className={classNames('p-button-rounded p-button-text p-button-plain', {
          'p-ml-auto p-mr-2': !isRTL,
          'p-ml-2 p-mr-auto': isRTL,
        })}></Button>
      <Button
        type="button"
        icon="pi pi-facebook fs-large"
        className={classNames('p-button-rounded p-button-text p-button-plain', {
          'p-mr-2': !isRTL,
          'p-ml-2': isRTL,
        })}></Button>
      <Button
        type="button"
        icon="pi pi-twitter fs-large"
        className={classNames('p-button-rounded p-button-text p-button-plain', {
          'p-mr-2': !isRTL,
          'p-ml-2': isRTL,
        })}></Button>*/}
    </div>
  );
}
