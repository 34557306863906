// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
// import {AutoComplete} from 'primereact/autocomplete';
//$FlowFixMe[cannot-resolve-module]
import {Checkbox} from 'primereact/checkbox';
//$FlowFixMe[cannot-resolve-module]
import {InputNumber} from 'primereact/inputnumber';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
//$FlowFixMe[cannot-resolve-module]
import {AutoComplete} from 'primereact/autocomplete';

type Props = {
  readOnly?: boolean,
  measure: any,
  onAction: (evt: any) => void,
};

const defAudMeasure = {
  __cls__: 'com.nb.lmrs.model.entity.AndonMeasure',
  flag: 0,
  code: '',
  name: '',
  description: '',
  processType: 'C2B',
  applicable: true,
};

export function AndonMeasureForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {readOnly, measure} = props;
  const [editAudMeasure, setEditAudMeasure] = useState<any>();
  const [suggDepartments, setSuggDepartments] = useState([]);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
    let obj = measure || {};
    obj = {
      ...defAudMeasure,
      ...obj,
    };

    // let {weight} = obj;
    // weight = (weight || 0) * 100;
    // obj['weight'] = weight;
    // //console.log.*$
    obj = Utils.adjustPercField(
      obj,
      ['weight', 'c2bWg', 'sfWg', 'offlineWg', 'osPressWg'],
      true,
    );

    setEditAudMeasure(obj);
  }, [measure]);
  const _onSuggDepartments = () => {
    setSuggDepartments([
      {
        label: 'C2B',
        value: 'C2B',
      },
      {
        label: 'STOCKFIT',
        value: 'STOCKFIT',
      },
      {
        label: 'OUTSOLE',
        value: 'OUTSOLE',
      },
      {
        label: 'TREATMENT',
        value: 'TREATMENT',
      },
    ]);
  };

  const _onSave = (evt: any) => {
    // setShowCfmDlg(false);
    let fac = _validateAudMeasure();
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    console.log('fac', fac);
    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update criteria?'
        : 'Are you sure you want to create criteria?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateAndonMeasure({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'machLineSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _validateAudMeasure: () => any = () => {
    let obj = {...editAudMeasure};

    // obj = Utils.adjustPercField(
    //   obj,
    //   ['weight', 'c2bWg', 'sfWg', 'offlineWg', 'osPressWg'],
    //   false,
    // );
    return obj;
  };

  if (!editAudMeasure) {
    return null;
  }

  const saveLbl = editAudMeasure.id ? 'Update criteria' : 'Create criteria';
  const isNew = !editAudMeasure.id;
  // //console.log.*$

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/edit criteria">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editAudMeasure.code}
                onChange={(e) => {
                  setEditAudMeasure({
                    ...editAudMeasure,
                    code: e.target.value,
                  });
                }}
                disabled={!isNew || readOnly}
              />
              <label>Code</label>
            </span>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editAudMeasure.name}
                onChange={(e) => {
                  setEditAudMeasure({
                    ...editAudMeasure,
                    name: e.target.value,
                  });
                }}
                disabled={readOnly}
              />
              <label>Name</label>
            </span>
          </div>

          <div className="p-col-12">
            <AutoComplete
              placeholder="Select process type"
              dropdown
              suggestions={suggDepartments}
              field="label"
              value={editAudMeasure.processType}
              completeMethod={_onSuggDepartments}
              onChange={(e) =>
                setEditAudMeasure({
                  ...editAudMeasure,
                  processType: e.value.value,
                })
              }
              disabled={readOnly}
            />
          </div>

          {/* <div className="p-col-12 p-xl-6 p-lg-6">
            <Checkbox
              value={editAudMeasure.applicable}
              onChange={(e) =>
                setEditAudMeasure({
                  ...editAudMeasure,
                  applicable: e.checked,
                })
              }
              checked={editAudMeasure.applicable}
            />
            <label className="p-checkbox-label ml-1">Applicable</label>
          </div> */}

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputNumber
                value={editAudMeasure.idNo}
                onValueChange={(e) =>
                  setEditAudMeasure({
                    ...editAudMeasure,
                    idNo: e.value,
                  })
                }
                disabled={readOnly}
              />
              <label>Sort order</label>
            </span>
          </div>

          <div className="p-col-12">
            <Checkbox
              value=""
              onChange={(e) => {
                setEditAudMeasure({
                  ...editAudMeasure,
                  active: e.checked,
                });
              }}
              checked={editAudMeasure.active}></Checkbox>
            <label className="p-checkbox-label ml-1">Active</label>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <InputTextarea
                rows={3}
                value={editAudMeasure.description}
                onChange={(e) => {
                  setEditAudMeasure({
                    ...editAudMeasure,
                    description: e.target.value,
                  });
                }}
                disabled={readOnly}
              />
              <label>Summary</label>
            </span>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
