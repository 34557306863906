// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';
//$FlowFixMe
import {debounceTime} from 'rxjs/operators';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {Chart} from 'primereact/chart';
//$FlowFixMe
import {Button} from 'primereact/button';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../service/AuthUtils';
import type {EvtHandler} from '../types';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {pphColors, siColors, kpiColors, getChartOptions1} from './CommonChart';

type Props = {
  scope?: string,
  regions?: any[],
  countries?: any[],
  facGroups?: any[],
  factories?: any[],
  lines?: any[],
  month?: any,
  year?: any,
  expanded?: boolean,
  onAction?: EvtHandler,
};

export function PphMonthly(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();
  const {
    scope,
    regions,
    countries,
    facGroups,
    factories,
    lines,
    month,
    year,
    expanded,
    onAction,
  } = props;

  const [barData, setBarData] = useState();
  const [chartOptions, setChartOptions] = useState(getChartOptions1());

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchData = useCallback(async (options) => {
    let {
      scope,
      regions,
      region,
      countries,
      country,
      facGroups,
      group,
      factories,
      factory,
      year,
      month,
    } = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return [];
    }
    if (regions?.length === 0 && scope === 'region') return [];
    if (countries?.length === 0 && scope === 'country') return [];
    if (facGroups?.length === 0 && scope === 'facGroup') return [];
    if (factories?.length === 0 && scope === 'factory') return [];

    scope = scope || 'region';
    let pYear = Number(year) || 0;
    let pMonth = Number(month);
    let reqData = {};

    reqData = {
      ...reqData,
      month: pMonth,
      year: pYear,
    };

    if (factories && factories.length > 0 && scope === 'factory' && !factory) {
      let pms = factories.map((fac) => {
        return _fetchData({
          ...options,
          factory: fac,
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    if (facGroups && facGroups.length > 0 && scope === 'facGroup' && !group) {
      let pms = facGroups.map((group) => {
        return _fetchData({
          ...options,
          group: group,
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    if (countries && countries.length > 0 && scope === 'country' && !country) {
      let pms = countries.map((country) => {
        return _fetchData({
          ...options,
          country: country,
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    if (regions && regions.length > 0 && scope === 'region' && !region) {
      let pms = regions.map((region) => {
        return _fetchData({
          ...options,
          region: region,
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    let serie = '';
    if (factory) {
      reqData = {
        ...reqData,
        factoryId: factory.id,
        scope,
      };
      serie = factory.code;
    }
    if (group) {
      reqData = {
        ...reqData,
        groupId: group.id,
        scope,
      };
      serie = group.code;
    }

    if (country) {
      reqData = {
        ...reqData,
        countryId: country.id,
        scope,
      };

      serie = country.code;
    }

    if (region) {
      reqData = {
        ...reqData,
        regionId: region.id,
        scope,
      };

      serie = region.code;
    }

    return await api
      .fetchPphMonthly({
        data: {
          ...reqData,
        },
      })
      .then((resp) => resp.data)
      .then((resp) => {
        //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        if (data.data) {
          return [
            {
              ...data.data,
              serie,
            },
          ];
        } else {
          return [];
        }
      });
  }, []);

  const _fetchKpiData = useCallback(async (options: any) => {
    let {scope, factories, factory, lines, line, year} = options;
    let api: ?AdminApi = apiRef.current;
    if (
      !api ||
      scope === 'region' ||
      scope === 'country' ||
      scope === 'facGroup'
    ) {
      return [];
    }

    if (factories && factories.length > 0) {
      let pms = factories.map((fac) => {
        return _fetchKpiData({
          ...options,
          factory: fac,
          factories: [],
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    if (lines && lines.length > 0) {
      let pms = lines.map((ln) => {
        return _fetchKpiData({
          ...options,
          line: ln,
          lines: [],
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    let reqData = {
      scope,
      periodType: 'MONTH',
      kpiType: 'PPH',
      year,
    };

    let serie = {};
    if (factory) {
      reqData = {
        ...reqData,
        factoryId: factory.id,
      };
      serie = `Target-${factory.code}`;
    }

    if (line) {
      reqData = {
        ...reqData,
        lineId: line.id,
      };
      serie = `TARGET-${line.code}`;
    }

    return await api
      .loadProdKpiData({
        data: {...reqData},
      })
      .then((resp) => resp.data)
      .then((resp) => {
        //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        return [
          {
            ...data,
            serie,
          },
        ];
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchKpiRegion = useCallback(async (options) => {
    let {year, scope, regions, countries, facGroups} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return [];
    }
    let reqData = {
      year,
      kpiType: 'PPH',
      periodType: 'MONTH',
    };

    if (scope === 'region' && regions && regions.length > 0) {
      let pms = regions.map((reg) => {
        let serie = `Target-${reg.code}`;
        return api
          .loadProdKpiData({
            data: {
              ...reqData,
              scope: 'region',
              regionId: reg.id,
            },
          })
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              UIUtils.showError({errors, toast: toastRef.current});
              return [];
            }
            return [
              {
                ...data,
                serie,
              },
            ];
          })
          .catch((error) => {
            UIUtils.showError({error, toast: toastRef.current});
            return [];
          });
      });

      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    } else if (scope === 'country' && countries && countries.length > 0) {
      let pms = countries.map((coun) => {
        let serie = `Target-${coun.code}`;
        return api
          .loadProdKpiData({
            data: {
              ...reqData,
              scope: 'country',
              countryId: coun.id,
            },
          })
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              UIUtils.showError({errors, toast: toastRef.current});
              return [];
            }
            return [
              {
                ...data,
                serie,
              },
            ];
          })
          .catch((error) => {
            UIUtils.showError({error, toast: toastRef.current});
            return [];
          });
      });

      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    } else if (scope === 'facGroup' && facGroups && facGroups.length > 0) {
      let pms = facGroups.map((facGroup) => {
        let serie = `Target-${facGroup.code}`;
        return api
          .loadProdKpiData({
            data: {
              ...reqData,
              scope: 'facgroup',
              facGroupId: facGroup.id,
            },
          })
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              UIUtils.showError({errors, toast: toastRef.current});
              return [];
            }
            return [
              {
                ...data,
                serie,
              },
            ];
          })
          .catch((error) => {
            UIUtils.showError({error, toast: toastRef.current});
            return [];
          });
      });

      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    } else {
      return [];
    }
  }, []);

  const _fetchPphYtdKpi = useCallback(async (options) => {
    let {year} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return [];
    }
    return await api
      .getPphYtdKpi({year: year})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        //console.log.*$
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return 0;
        }
        return data.target;
      })
      .catch((errors) => {
        UIUtils.showError({errors, toast: toastRef.current});
        return 0;
      });
  }, []);

  const _fetchChartData = useCallback(
    async (options: any) => {
      let {scope} = options;
      switch (scope) {
        case 'factory':
          options = {
            ...options,
            line: null,
            lines: [],
          };
          break;
        default:
          break;
      }

      let months = [];
      for (let i = 0; i < 12; i++) {
        months.push(i + 1);
      }
      let labels = months.map((it) => it);
      labels = labels.map((it) => t(`mon.${it}`));
      labels.push('6 months roll');
      let pphYtdKpi = (await _fetchPphYtdKpi(options)) || 0;
      let pm1 = _fetchKpiData(options).then((data) => {
        return data.map((rec, ind) => {
          let ci = ind % kpiColors.length;
          return {
            type: 'line',
            label: rec.serie,
            backgroundColor: kpiColors[ci],
            borderColor: kpiColors[ci],
            borderWidth: 1,
            data: [...rec.targetValues],
          };
        });
      });
      let pm2 = _fetchData(options).then((data) => {
        let pphDatasets = data.map((dt, ind) => {
          let ci = ind % pphColors.length;
          let pphDatas = [];
          for (let i = 0; i < months.length; i++) {
            let values: any[] = Object.values(dt).filter(
              (dt) => dt[3] === i + 1,
            )[0];

            if (values && values.length > 0) {
              pphDatas.push(Utils.round(values[0] / values[2], 2));
            } else {
              pphDatas.push(0);
            }
          }

          let ytd = 0;
          let dataYtd: any = Object.values(dt).filter((dt) => dt[2] === 13)[0];
          if (dataYtd && dataYtd.length > 0) {
            ytd = Utils.round(dataYtd[0], 2);
          }
          pphDatas.push(ytd);

          return {
            label: `EOL-${dt.serie}`,
            backgroundColor: pphColors[ci],
            data: pphDatas,
          };
        });

        let siDatasets = data.map((dt, ind) => {
          let ci = ind % siColors.length;
          let pphDatas = [];
          for (let i = 0; i < months.length; i++) {
            let values: any[] = Object.values(dt).filter(
              (dt) => dt[3] === i + 1,
            )[0];

            if (values && values.length > 0) {
              pphDatas.push(Utils.round(values[1] / values[2], 2));
            } else {
              pphDatas.push(0);
            }
          }

          let ytd = 0;
          let dataYtd = Object.values(dt).filter((dt) => dt[2] === 13)[0];
          if (dataYtd && dataYtd.length > 0) {
            ytd = Utils.round(dataYtd[1], 2);
          }
          pphDatas.push(ytd);
          return {
            label: `SI-${dt.serie}`,
            backgroundColor: siColors[ci],
            data: pphDatas,
          };
        });

        let chartData = {
          labels,
          datasets: [...pphDatasets, ...siDatasets],
        };

        return chartData;
      });

      let pm3 = _fetchKpiRegion(options).then((data) => {
        return data.map((rec, ind) => {
          let ci = ind % kpiColors.length;
          return {
            type: 'line',
            label: rec.serie,
            backgroundColor: kpiColors[ci],
            borderColor: kpiColors[ci],
            borderWidth: 1,
            data: [...rec.targetValues, pphYtdKpi],
          };
        });
      });

      Promise.all([pm1, pm2, pm3]).then((data) => {
        let [kpiData, chartData, kpiDt] = data;
        let {datasets} = chartData;
        if (datasets.length > 0) {
          datasets = [...kpiData, ...datasets, ...kpiDt];
          const dataArrays = datasets.map((item) => item.data).flat();
          let arr = Utils.findMinMax(dataArrays);
          let chartOptionsNew = {
            ...chartOptions,
            scales: {
              ...chartOptions.scales,
              y: {
                ...chartOptions.scales.y,
                max: Utils.round(arr[1] + 0.1, 1),
              },
            },
          };

          setChartOptions(chartOptionsNew);
        }
        setBarData({
          ...chartData,
          datasets,
        });
      });
    },
    [
      _fetchKpiData,
      _fetchKpiRegion,
      _fetchData,
      t,
      chartOptions,
      _fetchPphYtdKpi,
    ],
  );

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchChartData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      subj.complete();
    };
  }, [_fetchChartData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      scope,
      regions,
      countries,
      facGroups,
      factories,
      lines,
      month,
      year,
    });
  }, [scope, regions, countries, facGroups, factories, lines, month, year]);

  const _onToggleExpand: EvtHandler = (evt: any) => {
    onAction &&
      onAction({
        type: 'toggleExpand',
        value: !expanded,
      });
  };

  const iconName = expanded
    ? 'pi pi-angle-double-left'
    : 'pi pi-angle-double-right';

  const icons = (
    <Button
      icon={iconName}
      className="p-button-rounded p-button-text"
      style={{padding: 0, height: '1.2rem', color: '#FFFFFF'}}
      onClick={_onToggleExpand}
    />
  );

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="PPH Monthly" icons={icons}>
        <Chart type="bar" data={barData} options={chartOptions}></Chart>
      </Panel>
    </>
  );
}
