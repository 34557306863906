import {getColor} from '../../service/util/color';

export const pphColors: any = [
  'blue-700',
  'blue-500',
  'blue-300',
  'blue-100',
  'blue-800',
  'blue-600',
  'blue-400',
  'blue-200',
  'blue-50',
  'blue-900',
].map((c) => getColor(c));

export const siColors: any = [
  'green-700',
  'green-500',
  'green-300',
  'green-100',
  'green-800',
  'green-600',
  'green-400',
  'green-200',
  'green-50',
  'green-900',
].map((c) => getColor(c));

export const kpiColors: any = [
  'orange-700',
  'orange-500',
  'orange-300',
  'orange-100',
  'orange-800',
  'orange-600',
  'orange-400',
  'orange-200',
  'orange-50',
  'orange-900',
].map((c) => getColor(c));

export const getStackedChartOptions = () => {
  if (!document || !document.body) {
    return {};
  }
  let body = document.body;
  const textColor =
    getComputedStyle(body).getPropertyValue('--text-color') ||
    'rgba(0, 0, 0, 0.87)';

  const gridLinesColor =
    getComputedStyle(body).getPropertyValue('--divider-color') ||
    'rgba(160, 167, 181, .3)';
  const fontFamily = getComputedStyle(body).getPropertyValue('--font-family');

  return {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      tooltips: {
        mode: 'index',
        intersect: true,
      },
      legend: {
        labels: {
          fontFamily,
          fontColor: textColor,
          fontSize: 8,
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          fontFamily,
          fontColor: textColor,
        },
        grid: {
          color: gridLinesColor,
        },
      },
      y: {
        stacked: true,
        ticks: {
          fontFamily,
          fontColor: textColor,
        },
        grid: {
          color: gridLinesColor,
        },
      },
    },
  };
};
export const getChartOptions = () => {
  if (!document || !document.body) {
    return {};
  }
  let body = document.body;
  const textColor =
    getComputedStyle(body).getPropertyValue('--text-color') ||
    'rgba(0, 0, 0, 0.87)';

  const gridLinesColor =
    getComputedStyle(body).getPropertyValue('--divider-color') ||
    'rgba(160, 167, 181, .3)';
  const fontFamily = getComputedStyle(body).getPropertyValue('--font-family');
  return {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    legend: {
      display: true,
      labels: {
        fontFamily,
        fontColor: textColor,
        fontSize: 8,
      },
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            fontFamily,
            fontColor: 'textColor',
          },
          gridLines: {
            color: gridLinesColor,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontFamily,
            fontColor: textColor,
            maxRotation: 40,
            minRotation: 30,
          },
          gridLines: {
            color: 'red',
          },
        },
      ],
    },
  };
};

export const getChartOptions1 = () => {
  if (!document || !document.body) {
    return {};
  }
  let body = document.body;
  const textColor =
    getComputedStyle(body).getPropertyValue('--text-color') ||
    'rgba(0, 0, 0, 0.87)';

  const gridLinesColor =
    getComputedStyle(body).getPropertyValue('--divider-color') ||
    'rgba(160, 167, 181, .3)';
  const fontFamily = getComputedStyle(body).getPropertyValue('--font-family');
  return {
    maintainAspectRatio: false,
    aspectRatio: 0.9,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    legend: {
      display: true,
      labels: {
        fontFamily,
        fontColor: textColor,
        fontSize: 8,
      },
    },
    responsive: true,
    scales: {
      y: {
        ticks: {
          fontFamily,
          fontColor: 'textColor',
          stepSize: 0.1,
        },

        // max: 0.95 + 0.1,
        // min: 0,
        gridLines: {
          color: gridLinesColor,
        },
      },
      x: {
        ticks: {
          fontFamily,
          fontColor: textColor,
          maxRotation: 40,
          minRotation: 30,
        },
        gridLines: {
          color: 'red',
        },
      },
    },
  };
};

export const getPieChartOptions = () => {
  if (!document || !document.body) {
    return {};
  }
  let body = document.body;
  const textColor =
    getComputedStyle(body).getPropertyValue('--text-color') ||
    'rgba(0, 0, 0, 0.87)';
  const fontFamily = getComputedStyle(body).getPropertyValue('--font-family');
  return {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    legend: {
      display: true,
      labels: {
        fontFamily,
        fontColor: textColor,
        fontSize: 8,
      },
    },
  };
};
