import React, {useEffect, useState, useRef, useCallback} from 'react';
import {Route, Redirect, useLocation,useHistory } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import './boot/i18n';
import {CoreApi} from './service/CoreApi';
import type {ApiProps} from './service/Api';
import {logoutAction} from './service/auth/action';

import App from './App';
import Login from './pages/Login';
import Error from './pages/Error';
import NotFound from './pages/NotFound';
import Access from './pages/Access';
import Landing from './pages/Landing';

import {AppLogin} from './lmrs/login/AppLogin';

type Props = {};

export function AppWrapper(props: Props): React$Node {
  let location = useLocation();
  let history = useHistory();
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {i18n} = useTranslation();
  const {lang} = auth;
  const [loading, setLoading] = useState(true);

  const apiRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new CoreApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    // Save the current URL if the user isn't authenticated
    if (!auth.isAuth && location.pathname !== '/app-login') {
      localStorage.setItem('redirectAfterLogin', location.pathname);
    }
  }, [auth.isAuth, location.pathname]);

  //After login, retrieve the saved URL and redirect to it
  useEffect(() => {
    if (auth.isAuth) {
      const redirectUrl = localStorage.getItem('redirectAfterLogin');
      if (redirectUrl) {
        history.push(redirectUrl);
        localStorage.removeItem('redirectAfterLogin'); // Clear the stored URL after redirect
      }
    }
  }, [auth.isAuth, history]);

  const _initI18n: any = useCallback(async () => {
    let i18nPm = new Promise((resolve, reject) => {
      let check = () => {
        if (!i18n.isInitialized) {
          setTimeout(check, 100);
        } else {
          resolve('i18n loaded');
        }
      };
      check();
    });
    await i18nPm;
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang, () => {
        //console.log.*$
      });
    }
    //console.log.*$
    return 'i18n ready';
  }, [i18n, lang]);

  useEffect(() => {
    _initI18n().then((msg) => {
      //console.log.*$
      setLoading(false);
    });
  }, [_initI18n]);

  const _checkSession: () => any = useCallback(() => {
    let {token, isAuth} = auth;
    if (!isAuth || loading) {
      // //console.log.*$
      return;
    }
    let api: ?CoreApi = apiRef.current;
    if (!api) {
      //console.log.*$
      return;
    }
    api
      .checkToken({token})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors} = resp;
        if (errors.length > 0) {
          //console.log.*$
          let err = errors[0];
          if (err.code === 'SessionExpiredException') {
            //console.log.*$
            dispatch(logoutAction());
          }
          return;
        }
      })
      .catch((err) => {
        //console.log.*$
        // this.props.doLogout();
      });
  }, [auth, loading, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      //console.log.*$
      _checkSession();
    }, 1000);
    let handleInt = setInterval(() => {
      _checkSession();
    }, 15000);
    return () => {
      clearInterval(handleInt);
    };
  }, [_checkSession]);

  //console.log.*$
  // //console.log.*$

  switch (location.pathname) {
    case '/login':
      return <Route path="/login" component={Login} />;
    case '/error':
      return <Route path="/error" component={Error} />;
    case '/notfound':
      return <Route path="/notfound" component={NotFound} />;
    case '/access':
      return <Route path="/access" component={Access} />;
    case '/landing':
      return <Route path="/landing" component={Landing} />;
    case '/app-login':
      return <Route path="/app-login" component={AppLogin} />;
    default:
      if (!auth.isAuth) {
        return <Redirect to="/app-login" />;
      }
      return <App />;
  }
}
