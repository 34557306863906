// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';

import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
import {AuthUtils} from '../../service/AuthUtils';
import {AuthConst} from '../../service/AppConstant';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

import {FactoryFilter} from '../common/FactoryFilter';

import {LeanAuditForm} from './LeanAuditForm';
import {LeanAuditList} from './LeanAuditList';
import {LeanAudItem1} from './LeanAudItem1';
import {LeanAudItem} from './LeanAudItem';
import {LeanAudChart} from './LeanAudChart';

type Props = {};

const defFilter = {
  factory: null,
  facGroups: [],
  years: [],
};

export function LeanAudit(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const globalRef = useSelector((state) => state.global);

  const rtParams = useParams();
  const [selLeanAudit, setSelLeanAudit] = useState<any>();
  const [reload, setReload] = useState(false);
  const [filter, setFilter] = useState({...defFilter});
  const [showFacGroup, setShowFacGroup] = useState(false);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  // const selLeanAuditRef = useRef();
  // useEffect(() => {
  //   selLeanAuditRef.current = selLeanAudit;
  // }, [selLeanAudit]);

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let initFil = {...defFilter};
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      initFil['factory'] = factories[0];
    }
    let di: any = Utils.getDateInfo() || {};
    initFil = {
      ...initFil,
      years: [di.year],
    };

    let filVal = rtParams.filter;
    console.log(filVal);
    if (filVal && globalRef) {
      let inpFilter = JSON.parse(atob(filVal));
      //console.log.*$
      let inpFil = globalRef[inpFilter];
      //console.log.*$
      initFil = {
        ...initFil,
        ...inpFil,
      };
      //console.log.*$
      let {facGroups} = initFil;
      if (facGroups && facGroups.length > 0) {
        setShowFacGroup(true);
      }
    }

    setFilter(initFil);
  }, [auth, globalRef, rtParams]);

  const _onLeanAuditSelected = (evt: any) => {
    // setSelLeanAudit(evt.value);
    //console.log.*$
    setSelLeanAudit(evt.value);
  };

  const _onNewLeanAudit: any = (evt: any) => {
    setSelLeanAudit({});
  };

  const _onLeanAuditAction = (evt: any) => {
    setSelLeanAudit({});
    setReload(!reload);
  };

  const _onDelete: (evt: any) => void = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selLeanAudit.id) {
      return;
    }
    confirmDialog({
      message: 'Are you sure you want to delete assessment?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .deleteLeanAudit(selLeanAudit.id)
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
            }
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          })
          .finally(() => {
            setSelLeanAudit(null);
            setReload(!reload);
          });
      },
      reject: () => {},
    });
  };

  const _onFilterAction: EvtHandler = (evt: any) => {
    let {type, value} = evt;
    if (type === 'filterChanged') {
      setFilter({...value});
      setSelLeanAudit({});
    }
  };

  const _onItemAction: EvtHandler = (evt: any) => {
    //reload audit list
    setReload(!reload);
  };

  // const cfmDlgFooter = (
  //   <div className="text-right">
  //     <Button label="Yes" icon="pi pi-check" onClick={_onDeletLeanAudit} />
  //     <Button
  //       label="No"
  //       icon="pi pi-times"
  //       onClick={(e) => {
  //         setShowCfmDlg(false);
  //       }}
  //     />
  //   </div>
  // );

  let listTitle = 'Available assessments';
  let authLevel = AuthUtils.getAuthLevel({auth});
  let canEdit =
    authLevel < AuthConst.AUTH_LVL_NB ||
    AuthUtils.isAuth({auth, roles: ['FACTORY_ADMIN', 'NB_ADMIN']});
  let disFacGroup = authLevel >= AuthConst.AUTH_LVL_FACTORY;
  let {factory, years} = filter;
  let showChart = factory && factory.id && years && years.length > 0;

  return (
    <>
      <Toast ref={toastRef} />

      <div>
        <FactoryFilter
          scope="factory"
          filter={filter}
          showFacGroup={showFacGroup}
          disFacGroup={disFacGroup}
          multiFacGroups={true}
          showFactory={true}
          showYears={true}
          onAction={_onFilterAction}
        />
        {/*<LeanAuditFilter filter={initFilter} onAction={_onFilterAction} />*/}
      </div>

      <div className="grid grid-nogutter mb-2">
        <div className="col-12 lg:col-8 pr-2">
          <LeanAuditList
            byFactory={factory}
            byYears={years}
            header={listTitle}
            reload={reload}
            selection={selLeanAudit}
            onSelect={_onLeanAuditSelected}
          />
          {canEdit && (
            <div className="p-d-flex p-jc-end p-my-2">
              <Button
                label="New measure"
                icon="pi pi-plus"
                className="p-ml-2"
                onClick={_onNewLeanAudit}
              />
              {selLeanAudit && selLeanAudit.id && (
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  className="p-ml-2"
                  onClick={_onDelete}
                  disabled={selLeanAudit.flag === 1}
                />
              )}
            </div>
          )}
        </div>
        <div className="col-12 lg:col-4">
          <LeanAuditForm
            readOnly={!canEdit}
            audit={selLeanAudit}
            onAction={_onLeanAuditAction}
          />
        </div>
      </div>

      <TabView>
        <TabPanel header="Spider chart">
          <div className="grid">
            <div className="col-12 xl:col-6">
              {!showChart && (
                <div>
                  <h6>
                    Please select factory and year(s) to display spider chart.
                  </h6>
                </div>
              )}
              {showChart && <LeanAudChart factory={factory} years={years} />}
            </div>

            <div className="col-12 xl:col-6">
              {!showChart && (
                <div>
                  <h6>
                    Please select factory and year(s) to display assessment
                    details.
                  </h6>
                </div>
              )}

              {showChart && (
                <LeanAudItem1
                  factory={factory}
                  years={years}
                  /*audit={selLeanAudit}*/ onAction={_onItemAction}
                />
              )}
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Assessment details">
          {selLeanAudit && selLeanAudit.id && (
            <LeanAudItem audit={selLeanAudit} onAction={_onItemAction} />
          )}
        </TabPanel>
      </TabView>

      {/*<Dialog
        header="Confirm delete production line"
        footer={cfmDlgFooter}
        visible={showCfmDlg}
        style={{width: '400px'}}
        modal={true}
        onHide={() => {
          setShowCfmDlg(false);
        }}>
        <span>Are you sure to delete selected production line?</span>
      </Dialog>*/}
    </>
  );
}
