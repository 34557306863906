// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function FacGroupList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  // const {t} = useTranslation();
  const [FacGroupsTblData, setFacGroupsTblData] = useState([]);
  const [FacGroupsTblStart, setFacGroupsTblStart] = useState(0);
  const [FacGroupsTblLimit] = useState(10);
  const [FacGroupsTblNbRows, setFacGroupsTblNbRows] = useState(0);
  const [selFacGroup, setSelFacGroup] = useState();
  const [selFacGroups, setSelFacGroups] = useState([]);
  const [sortMeta, setSortMeta] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);

  const {reload, selection, header, command} = props;
  const selMode = props.selectionMode || 'single';
  const {search} = app || '';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelFacGroup({...selection});
      } else {
        setSelFacGroups([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelFacGroup(null);
      } else {
        setSelFacGroups([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    // //console.log.*$
    // //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let start = FacGroupsTblStart;
    let limit = FacGroupsTblLimit;

    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });
    //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);

    api
      .getFacGroups({search, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        // //console.log.*$
        setFacGroupsTblData([...data.list]);
        setFacGroupsTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, [search, FacGroupsTblStart, FacGroupsTblLimit, reload, sortMeta]);

  const _onFacGroupSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelFacGroup(evt.value);
    } else {
      setSelFacGroups(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    // //console.log.*$
    setFacGroupsTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header}
          value={FacGroupsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={FacGroupsTblLimit}
          first={FacGroupsTblStart}
          totalRecords={FacGroupsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selFacGroup}
          onSelectionChange={_onFacGroupSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            field="code"
            header="Code"
            headerStyle={{width: 90}}
            sortable={true}
            sortField="obj.code"
          />
          <Column
            field="name"
            header="Name"
            headerStyle={{width: 200}}
            sortable
            sortField="obj.name"
          />
          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header}
          value={FacGroupsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={FacGroupsTblLimit}
          first={FacGroupsTblStart}
          totalRecords={FacGroupsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selFacGroups}
          onSelectionChange={_onFacGroupSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            selectionMode="multiple"
            headerStyle={{width: 50}}
            headerClassName="text-left"
            bodyClassName="text-left"></Column>
          <Column
            field="code"
            header="Code"
            headerStyle={{width: 90}}
            sortable={true}
            sortField="obj.code"
          />
          <Column
            field="name"
            header="Name"
            headerStyle={{width: 200}}
            sortable
            sortField="obj.name"
          />
          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
        </DataTable>
      )}
    </>
  );
}
