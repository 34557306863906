// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';

import type {ApiProps} from '../../service/Api';
import {AuthUtils} from '../../service/AuthUtils';
import {AuthConst} from '../../service/AppConstant';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {LeanActivityForm} from './LeanActivityForm';
import {LeanActivityList} from './LeanActivityList';

type Props = {
  type?: string,
  factory?: any,
  years?: number[],
  title?: string,
};

export function LeanActivity(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {type, title, factory, years} = props;

  const [selLeanActivity, setSelLeanActivity] = useState<any>();
  // const [showCfmDlg, setShowCfmDlg] = useState<any>(false);
  const [reload, setReload] = useState(false);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  // const selLeanActivityRef = useRef();
  // useEffect(() => {
  //   selLeanActivityRef.current = selLeanActivity;
  // }, [selLeanActivity]);

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _onLeanActivitySelected = (evt: any) => {
    // setSelLeanActivity(evt.value);
    //console.log.*$
    setSelLeanActivity(evt.value);
  };

  const _onNewLeanActivity: any = (evt: any) => {
    setSelLeanActivity({});
  };

  const _onLeanActivityAction = (evt: any) => {
    setSelLeanActivity({});
    setReload(!reload);
  };

  const _onDelete: (evt: any) => void = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selLeanActivity || !selLeanActivity.id) {
      return;
    }
    confirmDialog({
      message: 'Are you sure you want to delete selected activity?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .deleteLeanActivity(selLeanActivity.id)
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
            }
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          })
          .finally(() => {
            setSelLeanActivity(null);
            setReload(!reload);
          });
      },
      reject: () => {},
    });
  };

  // const cfmDlgFooter = (
  //   <div className="text-right">
  //     <Button label="Yes" icon="pi pi-check" onClick={_onDeletLeanActivity} />
  //     <Button
  //       label="No"
  //       icon="pi pi-times"
  //       onClick={(e) => {
  //         setShowCfmDlg(false);
  //       }}
  //     />
  //   </div>
  // );

  let authLevel = AuthUtils.getAuthLevel({auth});
  let canEdit =
    authLevel < AuthConst.AUTH_LVL_NB ||
    AuthUtils.isAuth({auth, roles: ['FACTORY_ADMIN', 'NB_ADMIN']});

  return (
    <>
      <Toast ref={toastRef} />

      <div className="p-grid">
        <div className="p-col-12 p-lg-8">
          <LeanActivityList
            byType={type}
            byFactory={factory}
            byYears={years}
            header={title}
            reload={reload}
            selection={selLeanActivity}
            onSelect={_onLeanActivitySelected}
          />
          {canEdit && (
            <div className="p-d-flex p-jc-end p-my-2">
              <Button
                label="New activity"
                icon="pi pi-plus"
                className="p-ml-2"
                onClick={_onNewLeanActivity}
              />
              {selLeanActivity && selLeanActivity.id && (
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  className="p-ml-2"
                  onClick={_onDelete}
                  disabled={selLeanActivity.flag === 1}
                />
              )}
            </div>
          )}
        </div>
        <div className="p-col-12 p-lg-4">
          <LeanActivityForm
            type={type}
            factory={factory}
            readOnly={!canEdit}
            leanActivity={selLeanActivity}
            onAction={_onLeanActivityAction}
          />
        </div>
      </div>
      {/*<Dialog
        header="Confirm delete production line"
        footer={cfmDlgFooter}
        visible={showCfmDlg}
        style={{width: '400px'}}
        modal={true}
        onHide={() => {
          setShowCfmDlg(false);
        }}>
        <span>Are you sure to delete selected production line?</span>
      </Dialog>*/}
    </>
  );
}
