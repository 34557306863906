// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';

//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';

//$FlowFixMe[cannot-resolve-module]
import ChartDataLabels from 'chartjs-plugin-datalabels';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-modul
import {Chart} from 'primereact/chart';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {getColor} from '../../service/util/color';

type Props = {
  scope?: string,
  country?: any,
  countries?: any[],
  factory?: any,
  factories?: any[],
  year?: any,
  years?: any,
};
const chartPlugins = [ChartDataLabels];
const getChartOptions = () => {
  if (!document || !document.body) {
    return {};
  }
  // let body = document.body;
  // const textColor =
  //   getComputedStyle(body).getPropertyValue('--text-color') ||
  //   'rgba(0, 0, 0, 0.87)';
  // const gridLinesColor =
  //   getComputedStyle(body).getPropertyValue('--divider-color') ||
  //   'rgba(160, 167, 181, .3)';
  // const fontFamily = getComputedStyle(body).getPropertyValue('--font-family');
  return {
    maintainAspectRatio: true,
    aspectRatio: 0.8,
    scale: {
      display: true,
      min: 0,
      max: 4,
      stepSize: 1,
      ticks: {
        showLabelBackdrop: true,
      },
    },

    legend: {
      display: true,
      labels: {
        // fontFamily,
        //fontColor: textColor,
      },
    },
    responsive: true,
  };
};

let chartOptions1 = getChartOptions();
const defColors: any[] = [
  'red-900',
  'deep-purple-900',
  'green-900',
  'yellow-900',
  'deep-orange-900',
  'teal-900',
  'teal-700',
  'teal-500',
  'teal-300',
  'green-900',
  'green-700',
  'green-500',
  'green-300',
].map((it) => getColor(it));

export function LeanAudChart(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  // const {t} = useTranslation();

  const {scope, country, countries, factory, factories, year, years} = props;

  const [barData, setBarData] = useState();
  //const [chartOptions] = useState(getChartOptions());

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchMeasures = useCallback(async (options: any) => {
    let {factory, years} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let fields = [
      'aud.id',
      'aud.audYear',
      'aud.factory.id',
      'leanAudItem.meaCode',
      'leanAudItem.rowNo',
      'leanAudItem.name',
    ];
    let joins = [
      {
        type: '',
        expr: 'LeanAudItem',
        alias: 'leanAudItem',
      },
      {
        type: 'join',
        expr: 'leanAudItem.audit',
        alias: 'aud',
      },
    ];
    let filters = {
      jnleanAudItem: 'obj.id = leanAudItem.measure.id',
      byFacIds: 'aud.factory.id = :factoryId',
      byYears: 'aud.audYear in (:audYears)',
      activeAud: 'aud.flag > -1',
    };
    let params = {
      factoryId: {type: 'long', value: factory.id},
      audYears: years.map((it) => Number(it)).filter((it) => !isNaN(it)),
    };
    let sorts = ['obj.idNo'];
    let start = 0;
    let limit = 10000;
    let data = await api
      .fetchAudMeasureData({
        fields,
        joins,
        filters,
        params,
        sorts,
        start,
        limit,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        return [...data.list];
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
    return data;
  }, []);
  // const _fetchMeasures = useCallback(async (options: any) => {
  //   let {factory, years} = options;
  //   let api: ?AdminApi = apiRef.current;
  //   if (!api) {
  //     return;
  //   }
  //   let joins = [
  //     {
  //       type: '',
  //       expr: 'LeanAudItem',
  //       alias: 'leanAudItem',
  //     },
  //     {
  //       type: 'join',
  //       expr: 'leanAudItem.audit',
  //       alias: 'aud',
  //     },
  //   ];
  //   let filters = {
  //     jnleanAudItem: 'obj.id = leanAudItem.measure.id',
  //     byFacIds: 'aud.factory.id = :factoryId',
  //     byYears: 'aud.audYear in (:audYears)',

  //     activeAud: 'aud.flag > -1',
  //   };
  //   let params = {
  //     factoryId: {type: 'long', value: factory.id},
  //     audYears: years.map((it) => Number(it)).filter((it) => !isNaN(it)),
  //   };
  //   let sorts = ['obj.idNo'];
  //   let start = 0;
  //   let limit = 10000;
  //   let data = await api
  //     .getAudMeasures({
  //       joins,
  //       filters,
  //       params,
  //       sorts,
  //       start,
  //       limit,
  //     })
  //     .then((resp) => resp.data)
  //     .then((resp) => {
  //       //console.log.*$
  //       let {errors, data} = resp;
  //       if (errors.length > 0) {
  //         //console.log.*$
  //         UIUtils.showError({errors, toast: toastRef.current});
  //         return [];
  //       }
  //       return [...data.list];
  //     })
  //     .catch((error) => {
  //       //console.log.*$
  //       UIUtils.showError({error, toast: toastRef.current});
  //       return [];
  //     });
  //   return data;
  // }, []);

  const _fetchData = useCallback(async (options: any) => {
    //console.log.*$
    let {scope, factory, factories, year, years} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let joins = [
      {
        type: 'join',
        expr: 'obj.audit',
        alias: 'aud',
      },
      {
        type: 'join',
        expr: 'aud.factory',
        alias: 'fac',
      },
      {
        type: 'join',
        expr: 'obj.measure',
        alias: 'mea',
      },
    ];
    let filters = {
      activeAud: 'aud.flag > -1',
    };
    let params = {};
    let groupBys = [
      'aud.id',
      'aud.name',
      'obj.rowNo',
      'obj.meaCode',
      'mea.name',
    ];
    years = years || [];
    if (year) {
      years = [...years, year];
    }
    years = years.map((it) => Number(it)).filter((it) => !isNaN(it));

    factories = factories || [];
    if (factory && factory.id) {
      factories = [...factories, factory];
    }

    scope = scope || 'factory';
    //console.log.*$
    if (scope === 'factory') {
      // groupBys = ['fac.code', ...groupBys];
      if (factories.length > 0) {
        let facIds = Utils.getVals(factories, 'id', []).map((it) => {
          return {
            type: 'long',
            value: it,
          };
        });
        filters = {
          ...filters,
          byFacIds: 'fac.id in (:facIds)',
        };
        params = {
          ...params,
          facIds,
        };
      }
      if (years.length > 0) {
        filters = {
          ...filters,
          yearIn: 'aud.audYear in (:audYears)',
        };
        params = {...params, audYears: [...years]};
      }
      // //console.log.*$
      // //console.log.*$
    }

    let fields = [...groupBys, 'avg(obj.score)', 'avg(obj.tarScore)'];
    let sorts = [...groupBys];
    let data = await api
      .fetchLeanAudItemData({
        fields,
        joins,
        groupBys,
        filters,
        params,
        sorts,
        start: 0,
        limit: 0,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }

        return [...data.list];
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
    return {
      years,
      data,
    };
  }, []);

  const _extractAudData = useCallback((options) => {
    let {audId, data, dpInd, meaCodes} = options;
    data = data.filter((it) => it[0] === audId);
    let resData = meaCodes.map((it) => 0);
    let mcMap = {};
    meaCodes.forEach((it, ind) => (mcMap[it] = ind));
    data.forEach((rec: any[]) => {
      let mcInd = mcMap[rec[3]];
      let dpVal = rec[dpInd];
      resData[mcInd] = resData[mcInd] + dpVal;
    });
    return resData;
  }, []);

  const _loadChartData = useCallback(
    async (options: any) => {
      let meaData = (await _fetchMeasures(options)) || [];
      console.log('meaData', meaData);
      //remove duplicates from meaData based on code
      meaData = meaData.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t[3] === thing[3]),
      );
      //console.log('meaData', meaData);
      //  let meaCodes = meaData.map((it) => it['code']);
      //let labels = meaData.map((it) => `${it.idNo}. ${it.name}`);
      let labels = meaData.map((it) => `${it[4]}. ${it[5]}`);

      let {data} = (await _fetchData(options)) || {};
      // console.log('data', data);
      let audMap = {};
      data.forEach((it) => (audMap[it[0]] = it[1]));
      let audIds = Object.keys(audMap).map((it) => Number(it));
      let audNames = Object.values(audMap);
      let datasets = audIds.map((audId, ind) => {
        let meaCodes = meaData.map((it) => it[3]);

        let sData = _extractAudData({audId, dpInd: 5, data, meaCodes});
        //console.log.*$
        let colorInd = ind % defColors.length;
        let color = defColors[colorInd];
        return {
          label: audNames[ind],
          borderColor: color,
          datalabels: {
            color,
            align: 'right',
            offset: 10,
          },
          data: [...sData],
        };
      });

      let datasetsTar = audIds.map((audId, ind) => {
        let meaCodes = meaData.map((it) => it[3]);
        let sData = _extractAudData({audId, dpInd: 6, data, meaCodes});
        let colorInd = (ind + 2) % defColors.length;
        let color = defColors[colorInd];
        return {
          label: `Target-${String(audNames[ind])}`,
          borderColor: color,
          datalabels: {
            color,
            align: 'right',
            offset: 10,
          },
          data: [...sData],
        };
      });

      let chartData = {
        labels,
        datasets: [...datasets, ...datasetsTar],
      };

      setBarData({
        ...chartData,
      });

      /////

      // let label1="Target"
      // let chartData1 = {
      //   label1,
      //   datasetsTar,
      // };

      // setBarData({
      //   barData,
      //   ...chartData1
      // });
    },
    [_fetchData, _fetchMeasures, _extractAudData],
  );

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _loadChartData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      //console.log.*$
      subj.complete();
    };
  }, [_loadChartData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      scope,
      country,
      countries,
      factory,
      factories,
      year,
      years,
    });
  }, [scope, country, countries, factory, factories, year, years]);

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="Lean Assessment Spider Chart">
        <div className="p-2">
          <Chart
            type="radar"
            data={barData}
            options={chartOptions1}
            plugins={chartPlugins}></Chart>
        </div>
      </Panel>
    </>
  );
}
