// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
// import {AutoComplete} from 'primereact/autocomplete';
//$FlowFixMe[cannot-resolve-module]
import {InputNumber} from 'primereact/inputnumber';
//$FlowFixMe[cannot-resolve-module]

import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  readOnly?: boolean,
  leanAudItem: any,
  onAction: (evt: any) => void,
};

const defLeanAudItem = {
  __cls__: 'com.nb.lmrs.model.entity.LeanAudItem',
  flag: 0,
  code: '',
  name: '',
  description: '',
  score: 0,
  tarScore: 0,
  weight: 0,
  c2bVal: 0,
  c2bWg: 0,
  sfVal: 0,
  sfWg: 0,
  offlineVal: 0,
  offlineWg: 0,
  osPressVal: 0,
  osPressWg: 0,
};

export function LeanAudItemForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {readOnly, leanAudItem} = props;
  const [editLeanAudItem, setEditLeanAudItem] = useState<any>();

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
    let obj = leanAudItem || {};
    obj = {
      ...defLeanAudItem,
      ...obj,
    };

    // let {weight} = obj;
    // obj['weight'] = (weight || 0) * 100;
    obj = Utils.adjustPercField(
      obj,
      ['weight', 'c2bWg', 'sfWg', 'offlineWg', 'osPressWg'],
      true,
    );

    // //console.log.*$
    setEditLeanAudItem(obj);
  }, [leanAudItem]);

  const _calcAudItem = useCallback((audIt: any) => {
    let newIt = Utils.adjustPercField(
      audIt,
      ['weight', 'c2bWg', 'sfWg', 'offlineWg', 'osPressWg'],
      false,
    );
    let {
      c2bVal,
      c2bWg,
      sfVal,
      sfWg,
      offlineVal,
      offlineWg,
      osPressVal,
      osPressWg,
    } = newIt;
    let vals = [c2bVal, sfVal, offlineVal, osPressVal].map(
      (it) => Number(it) || 0,
    );
    let rates = [c2bWg, sfWg, offlineWg, osPressWg].map(
      (it) => Number(it) || 0,
    );
    let score = vals.reduce((acc, it, ind) => {
      return acc + it * rates[ind];
    }, 0);
    score = Number(Utils.fmtDecimal(score, 2));
    let res = {
      ...audIt,
      score,
    };
    return {...res};
  }, []);

  const _processValueChanged: (fd: string, val: any) => any = (
    fd: string,
    val: any,
  ) => {
    let newIt = {...editLeanAudItem};
    newIt[fd] = val;
    newIt = _calcAudItem(newIt);
    //console.log.*$
    setEditLeanAudItem({...newIt});
  };

  const _validateLeanAudItem: () => any = () => {
    let obj = {...editLeanAudItem};

    // if (Utils.isEmpty(obj.code)) {
    //   UIUtils.showError({
    //     detail: 'Missing tax code',
    //     toast: toastRef.current,
    //   });
    //   return false;
    // }

    // if (isNaN(obj.machLineNo) || Number(obj.machLineNo) < 0) {
    //   UIUtils.showError({
    //     detail: 'Invalid machLine',
    //     toast: toastRef.current,
    //   });
    //   return false;
    // }

    // let {weight} = obj;
    // obj['weight'] = (weight || 0) / 100;
    obj = Utils.adjustPercField(
      obj,
      ['weight', 'c2bWg', 'sfWg', 'offlineWg', 'osPressWg'],
      false,
    );
    // obj.rate = Number(obj.rate);
    return obj;
  };

  const _onSave: EvtHandler = (evt: any) => {
    // setShowCfmDlg(false);
    let fac = _validateLeanAudItem();
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update leanAudItem type?'
        : 'Are you sure you want to create leanAudItem type?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateLeanAudItem({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showWarning({
                summary: 'Warning',
                detail: String(errors[0].message),
                toast: toastRef.current,
              });
              let {onAction} = props;
              onAction &&
                onAction({
                  type: 'auditItemSaved',
                  value: data.entity,
                });
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'auditItemSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  // const _onKeyDown: EvtHandler = (e: any) => {
  //   if (e && e.keyCode === 13) {
  //     setTimeout(() => {
  //       _onSave();
  //     }, 500);
  //   }
  // };

  if (!editLeanAudItem) {
    return null;
  }

  const saveLbl = editLeanAudItem.id
    ? 'Update leanAudItem type'
    : 'Create leanAudItem type';
  const isNew = !editLeanAudItem.id;
  // //console.log.*$

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/edit leanAudItem type">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editLeanAudItem.code}
                onChange={(e) => {
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    code: e.target.value,
                  });
                }}
                disabled={!isNew || readOnly}
              />
              <label>Code</label>
            </span>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editLeanAudItem.name}
                onChange={(e) => {
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    name: e.target.value,
                  });
                }}
                // onKeyDown={_onKeyDown}
                disabled={readOnly}
              />
              <label>Name</label>
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputNumber
                inputId="minmaxfraction"
                value={editLeanAudItem.score}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                disabled
              />
              <label>Score</label>
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputNumber
                inputId="minmaxfraction"
                value={editLeanAudItem.weight}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                onValueChange={(e) =>
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    weight: e.value,
                  })
                }
                // onKeyDown={_onKeyDown}
                disabled={readOnly}
              />
              <label>Weight (%)</label>
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputNumber
                value={editLeanAudItem.c2bVal}
                onValueChange={(e) => _processValueChanged('c2bVal', e.value)}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                disabled={readOnly}
              />
              <label>C2B</label>
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputNumber
                value={editLeanAudItem.c2bWg}
                onValueChange={(e) => _processValueChanged('c2bWg', e.value)}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                disabled={readOnly}
              />
              <label>C2B Rate (%)</label>
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputNumber
                value={editLeanAudItem.sfVal}
                onValueChange={(e) => _processValueChanged('sfVal', e.value)}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                disabled={readOnly}
              />
              <label>SF</label>
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputNumber
                value={editLeanAudItem.sfWg}
                onValueChange={(e) => _processValueChanged('sfWg', e.value)}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                disabled={readOnly}
              />
              <label>SF Rate (%)</label>
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputNumber
                value={editLeanAudItem.offlineVal}
                onValueChange={(e) =>
                  _processValueChanged('offlineVal', e.value)
                }
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                disabled={readOnly}
              />
              <label>Offline</label>
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputNumber
                value={editLeanAudItem.offlineWg}
                onValueChange={(e) =>
                  _processValueChanged('offlineWg', e.value)
                }
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                disabled={readOnly}
              />
              <label>Offline Rate (%)</label>
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputNumber
                value={editLeanAudItem.osPressVal}
                onValueChange={(e) =>
                  _processValueChanged('osPressVal', e.value)
                }
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                disabled={readOnly}
              />
              <label>Outsole</label>
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputNumber
                value={editLeanAudItem.osPressWg}
                onValueChange={(e) =>
                  _processValueChanged('osPressWg', e.value)
                }
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                disabled={readOnly}
              />
              <label>Outsole Rate (%)</label>
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputNumber
                value={editLeanAudItem.tarScore}
                onValueChange={(e) => _processValueChanged('tarScore', e.value)}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                disabled
              />
              <label>Target</label>
            </span>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <InputTextarea
                rows={2}
                value={editLeanAudItem.description}
                onChange={(e) => {
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    description: e.target.value,
                  });
                }}
                disabled={readOnly}
              />
              <label>Summary</label>
            </span>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
