// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';
//$FlowFixMe
import {debounceTime} from 'rxjs/operators';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {Chart} from 'primereact/chart';
//$FlowFixMe
import {Button} from 'primereact/button';

//$FlowFixMe[cannot-resolve-module]
import ChartDataLabels from 'chartjs-plugin-datalabels';

import type {ApiProps} from '../../../service/Api';
import type {EvtHandler} from '../../types';
// import {AuthUtils} from '../service/AuthUtils';
import {UIUtils, Utils} from '../../../service/Utils';
import {AdminApi} from '../../../service/AdminApi';
import {pphColors, getChartOptions} from '../CommonChart';

type Props = {
  scope?: string,
  regions?: any[],
  countries?: any[],
  facGroups?: any[],
  factories?: any[],
  year?: any,
  expanded?: boolean,
  onAction?: EvtHandler,
};

const chartPlugins = [ChartDataLabels];

export function LeanScoreQuarterlyFacV2(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const [chartOptions] = useState(getChartOptions);

  const {
    scope,
    regions,
    countries,
    facGroups,
    factories,
    year,
    expanded,
    onAction,
  } = props;
  //console.log.*$

  const [barData, setBarData] = useState();

  const _handleChartClick: EvtHandler = (evt: any) => {
    //console.log.*$
    let {chart} = evt;
    //console.log.*$
    if (!chart) {
      return;
    }
    let actEls = chart.getActiveElements();
    //console.log.*$

    let datasets = Utils.get(chart, 'config._config.data.datasets', []);
    //console.log.*$
    if (actEls.length < 1 || datasets.length < 1) {
      //console.log.*$
      // return;
    }
    setTimeout(() => {
      onAction &&
        onAction({
          type: 'chartClick',
          src: 'leanScoreQuarterly',
          value: {
            evt,
          },
        });
    }, 100);
  };

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchData = useCallback(async (options: any) => {
    let {factories, year, scope} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let pYear = Number(year) || 0;
    let reqData = {
      scope,
      year: pYear,
    };

    if (factories && factories.length > 0 && scope === 'factoryv2') {
      let factoryIds = factories.map((fac) => fac.id);
      reqData = {
        ...reqData,
        factoryIds,
      };
    }
    return await api
      .getLeanScore({
        data: {
          ...reqData,
        },
      })
      .then((resp) => resp.data)
      .then((resp) => {
        //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        //console.log.*$
        return data;
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchChartData = useCallback(
    async (options: any) => {
      let {year} = options;
      let labels = [`1st Half ${year}`, `2nd Half ${year}`];
      let pm2 = _fetchData(options).then((data) => {
        //console.log.*$
        let pphDatasets = [
          {
            label: 'LeanScore',
            backgroundColor: pphColors[0],
            data: [data.score1, data.score2],
            datalabels: {
              // align: 'top',
              // offset: 5,
              color: '#FFFFFF',
            },
          },
        ];
        let chartData = {
          labels,
          datasets: [...pphDatasets],
        };
        return chartData;
      });

      Promise.all([pm2]).then((data) => {
        let [chartData] = data;
        let {datasets} = chartData;
        if (datasets.length > 0) {
          datasets = [...datasets];
        }
        setBarData({
          labels,
          datasets,
        });
      });
    },
    [_fetchData],
  );

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchChartData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      // //console.log.*$
      subj.complete();
    };
  }, [_fetchChartData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      scope,
      regions,
      countries,
      facGroups,
      factories,
      year,
    });
  }, [scope, regions, countries, facGroups, factories, year]);

  const _onToggleExpand: EvtHandler = (evt: any) => {
    onAction &&
      onAction({
        type: 'toggleExpand',
        value: !expanded,
      });
  };

  const iconName = expanded
    ? 'pi pi-angle-double-left'
    : 'pi pi-angle-double-right';

  const icons = (
    <Button
      icon={iconName}
      className="p-button-rounded p-button-text"
      style={{padding: 0, height: '1.2rem', color: '#FFFFFF'}}
      onClick={_onToggleExpand}
    />
  );

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="Half Year Lean Assessment" icons={icons}>
        <Chart
          type="bar"
          data={barData}
          options={{
            ...chartOptions,
            events: ['click'],
            onClick: _handleChartClick,
          }}
          plugins={chartPlugins}></Chart>
      </Panel>
    </>
  );
}
