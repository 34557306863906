// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
// import {Dialog} from 'primereact/dialog';
// import {AutoComplete} from 'primereact/autocomplete';
// import {Dropdown} from 'primereact/dropdown';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  readOnly?: boolean,
  region: any,
  onAction: (evt: any) => void,
};

const defRegion = {
  __cls__: 'com.nb.lmrs.model.entity.Region',
  flag: 0,
  code: '',
  name: '',
  description: '',
  factory: null,
};

export function RegionForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {readOnly, region} = props;
  const [editRegion, setEditRegion] = useState<any>();

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
    let curRegion = region || {};
    curRegion = {
      ...defRegion,
      ...curRegion,
    };
    // //console.log.*$
    setEditRegion(curRegion);
  }, [region]);

  const _onSave = (evt: any) => {
    // setShowCfmDlg(false);
    let fac = _validateRegion();
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update region?'
        : 'Are you sure you want to create region?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateRegion({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'regionSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _validateRegion: () => any = () => {
    let obj = {...editRegion};

    if (Utils.isEmpty(obj.code)) {
      UIUtils.showError({
        detail: 'Please enter region code',
        toast: toastRef.current,
      });
      return false;
    }

    // if (isNaN(obj.regionNo) || Number(obj.regionNo) < 0) {
    //   UIUtils.showError({
    //     detail: 'Invalid region',
    //     toast: toastRef.current,
    //   });
    //   return false;
    // }

    // obj.rate = Number(obj.rate);
    return obj;
  };

  if (!editRegion) {
    return null;
  }

  const saveLbl = editRegion.id ? 'Update region' : 'Create region';
  const isNew = !editRegion.id;
  // //console.log.*$

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/edit region">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editRegion.code}
                onChange={(e) => {
                  setEditRegion({...editRegion, code: e.target.value});
                }}
                disabled={!isNew || readOnly}
              />
              <label>Code</label>
            </span>
          </div>

          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  value={editRegion.name}
                  onChange={(e) => {
                    setEditRegion({
                      ...editRegion,
                      name: e.target.value,
                    });
                  }}
                  disabled={readOnly}
                />
                <label>Name</label>
              </span>
            </div>
          </div>

          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputTextarea
                  rows={2}
                  value={editRegion.description}
                  onChange={(e) => {
                    setEditRegion({
                      ...editRegion,
                      description: e.target.value,
                    });
                  }}
                  disabled={readOnly}
                />
                <label>Summary</label>
              </span>
            </div>
          </div>
        </div>
      </Panel>
      {!readOnly && (
        <div className="p-d-flex p-jc-end p-my-2">
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        </div>
      )}
    </>
  );
}
