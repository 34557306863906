// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';
import {Subject} from 'rxjs';
//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  byId?: any,
  byFactory?: any,
  byMonth?: number,
  byYear?: number,
  byLine?: any,
  byLiveFlag?: number,
  defSort?: any[],
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
  onAction?: (evt: any) => void,
};

export function ProdRecBtsList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();
  const [prodRecsTblData, setProdRecsTblData] = useState([]);
  const [prodRecsTblStart, setProdRecsTblStart] = useState(0);
  const [prodRecsTblLimit, setProdRecsTblLimit] = useState(15);
  const [prodRecsTblNbRows, setProdRecsTblNbRows] = useState(0);
  const [selProdRec, setSelProdRec] = useState();
  const [selProdRecs, setSelProdRecs] = useState([]);
  const [sortMeta, setSortMeta] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);

  const {
    byFactory,
    byLiveFlag,
    defSort,
    selection,
    reload,
    header,
    command,
    byId,
    byMonth,
    byYear,
    byLine,
  } = props;
  const selMode = props.selectionMode || 'single';
  const toastRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    if (defSort && defSort.length > 0) {
      setSortMeta([...defSort]);
      setSortField(defSort[0].field);
      setSortOrder(defSort[0].order);
    }
  }, [defSort]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelProdRec({...selection});
      } else {
        setSelProdRecs([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelProdRec(null);
      } else {
        setSelProdRecs([]);
      }
    }
  }, [selection, selMode]);

  const _fetchData = useCallback((options) => {
    let {
      byFactory,
      byYear,
      byMonth,
      byLiveFlag,
      prodRecsTblStart,
      prodRecsTblLimit,
      byId,
      byLine,
      sortMeta,
    } = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let joins = [
      // {
      //   type: 'left join',
      //   expr: 'obj.factory',
      //   alias: 'factory',
      // },
      // {
      //   type: 'left join',
      //   expr: 'obj.line',
      //   alias: 'line',
      // },
    ];

    let filters = {};
    let params = {};
    if (byFactory && byFactory.id) {
      filters = {
        ...filters,
        byFactory: 'obj.factory.id = :factoryId',
      };
      params = {
        ...params,
        factoryId: {
          type: 'long',
          value: byFactory.id,
        },
      };
    }

    if (byYear > 0 && byYear !== undefined) {
      filters = {
        ...filters,
        year: 'obj.year = :year',
      };

      params = {
        ...params,
        year: Number(byYear),
      };
    }

    if (byMonth > 0 && byMonth !== undefined) {
      filters = {
        ...filters,
        byMonth: 'obj.month = :month',
      };

      params = {
        ...params,
        month: Number(byMonth),
      };
    }

    if (byId && byId !== undefined) {
      filters = {
        ...filters,
        byId: 'obj.id = :byId',
      };
      params = {
        ...params,
        byId: {
          type: 'long',
          value: byId,
        },
      };
    }

    if (byLiveFlag !== undefined) {
      filters = {
        ...filters,
        liveFlag: 'obj.flag = :liveFlag',
      };
      params = {
        ...params,
        liveFlag: byLiveFlag,
      };
    }

    if (byLine && byLine.id) {
      console.log(byLine);
      filters = {
        ...filters,
        byLine: 'obj.line.id = :byLineId',
      };

      params = {
        ...params,
        byLineId: {
          type: 'long',
          value: byLine.id,
        },
      };
    }

    let start = prodRecsTblStart;
    let limit = prodRecsTblLimit;

    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });
    // //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);
    //console.log.*$

    api
      .getProdRecsBts({joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        // //console.log.*$
        setProdRecsTblData([...data.list]);
        setProdRecsTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }

    //_fetchData({ byFactory, byLine, byLines, byLiveFlag, ...defSort });
  }, [command]);
  ////////////
  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(400)).subscribe({
      next: (data) => {
        _fetchData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      subj.complete();
    };
  }, [_fetchData]);

  useEffect(() => {
    // //console.log.*$
    // //console.log.*$
    let fetchDataSubj: any = fetchDataEvtRef.current;
    if (!fetchDataSubj) {
      return;
    }
    fetchDataSubj.next({
      byFactory,
      byYear,
      byMonth,
      byLiveFlag,
      prodRecsTblStart,
      prodRecsTblLimit,
      byId,
      byLine,
      sortMeta,
    });
  }, [
    byFactory,
    byYear,
    byMonth,
    byLiveFlag,
    prodRecsTblStart,
    prodRecsTblLimit,
    reload,
    byId,
    byLine,
    sortMeta,
  ]);

  //   let joins = [
  //     // {
  //     //   type: 'left join',
  //     //   expr: 'obj.factory',
  //     //   alias: 'factory',
  //     // },
  //     // {
  //     //   type: 'left join',
  //     //   expr: 'obj.line',
  //     //   alias: 'line',
  //     // },
  //   ];

  //   let filters = {};
  //   let params = {};
  //   if (byFactory && byFactory.id) {
  //     filters = {
  //       ...filters,
  //       byFactory: 'obj.factoryId = :factoryId',
  //     };
  //     params = {
  //       ...params,
  //       factoryId: {
  //         type: 'long',
  //         value: byFactory.id,
  //       },
  //     };
  //   }

  //   if (byLine && byLine.id) {
  //     filters = {
  //       ...filters,
  //       byLine: 'obj.lineId = :lineId',
  //     };
  //     params = {
  //       ...params,
  //       lineId: {
  //         type: 'long',
  //         value: byLine.id,
  //       },
  //     };
  //   }
  //   //console.log.*$

  //   if(byYear > 0 && byYear !== undefined){
  //     filters ={
  //       ...filters,
  //       year: 'obj.year = :year'
  //     }

  //     params = {
  //       ...params,
  //       year: Number(byYear)
  //     }
  //   }

  //   //console.log.*$

  //   if(byMonth > 0 && byMonth !== undefined){
  //     filters ={
  //       ...filters,
  //       byMonth: 'obj.month = :month'
  //     }

  //     params = {
  //       ...params,
  //       month: Number(byMonth)
  //     }
  //   }

  //   if(byId && byId !== undefined){
  //     filters = {
  //       ...filters,
  //       byId: 'obj.id = :byId',
  //     };
  //     params = {
  //       ...params,
  //       byId: {
  //         type: 'long',
  //         value: byId
  //       },
  //     };
  //    }

  //   if (byLines && byLines.length > 0) {
  //     filters = {
  //       ...filters,
  //       byLines: 'obj.lineId in (:lineIds)',
  //     };
  //     let lineIds = byLines.map((it) => {
  //       return {
  //         type: 'long',
  //         value: it.id,
  //       };
  //     });
  //     params = {
  //       ...params,
  //       lineIds,
  //     };
  //   }

  //   if (byLiveFlag !== undefined) {
  //     filters = {
  //       ...filters,
  //       liveFlag: 'obj.flag = :liveFlag',
  //     };
  //     params = {
  //       ...params,
  //       liveFlag: byLiveFlag,
  //     };
  //   }

  //   let start = prodRecsTblStart;
  //   let limit = prodRecsTblLimit;

  //   // let sorts = ['-obj.invDate', '-obj.createdAt'];
  //   let sorts = sortMeta.map((sm) => {
  //     return sm.order > 0 ? sm.field : `-${sm.field}`;
  //   });
  //   // //console.log.*$
  //   // let sortFds = sortMeta.map((sm) => sm.field);
  //   //console.log.*$

  //   api
  //     .getProdRecs({joins, filters, params, start, limit, sorts})
  //     .then((resp) => resp.data)
  //     .then((resp) => {
  //       let {errors, data} = resp;
  //       if (errors.length > 0) {
  //         //console.log.*$
  //         UIUtils.showError({errors, toast: toastRef.current});
  //         return;
  //       }
  //       const {list, factoryMap, lineMap, operatorMap, shiftMap} = data;
  //       list.forEach((rec) => {
  //         if (rec.factoryId) {
  //           rec['factory'] = factoryMap[rec.factoryId];
  //         }
  //         if (rec.lineId) {
  //           rec['line'] = lineMap[rec.lineId];
  //         }
  //         if (rec.shiftId) {
  //           rec['shift'] = shiftMap[rec.shiftId];
  //         }
  //         if (rec.operatorId) {
  //           rec['operator'] = operatorMap[rec.operatorId];
  //         }
  //       });
  //       // //console.log.*$
  //       setProdRecsTblData([...data.list]);
  //       setProdRecsTblNbRows(data.count);
  //     })
  //     .catch((err) => {
  //       //console.log.*$
  //       UIUtils.showError({error: err, toast: toastRef.current});
  //     });
  // }, [
  //   byFactory,
  //   byLine,
  //   byLines,
  //   byYear,
  //   byMonth,
  //   byLiveFlag,
  //   prodRecsTblStart,
  //   prodRecsTblLimit,
  //   reload,
  //   byId,
  //   sortMeta,
  // ]);

  ///////////////
  // useEffect(() => {
  //   // //console.log.*$
  //   // //console.log.*$
  //   let api: ?AdminApi = apiRef.current;
  //   if (!api) {
  //     return;
  //   }

  //   let joins = [
  //     // {
  //     //   type: 'left join',
  //     //   expr: 'obj.factory',
  //     //   alias: 'factory',
  //     // },
  //     // {
  //     //   type: 'left join',
  //     //   expr: 'obj.line',
  //     //   alias: 'line',
  //     // },
  //   ];

  //   let filters = {};
  //   let params = {};
  //   if (byFactory && byFactory.id) {
  //     filters = {
  //       ...filters,
  //       byFactory: 'obj.factoryId = :factoryId',
  //     };
  //     params = {
  //       ...params,
  //       factoryId: {
  //         type: 'long',
  //         value: byFactory.id,
  //       },
  //     };
  //   }

  //   if (byLine && byLine.id) {
  //     filters = {
  //       ...filters,
  //       byLine: 'obj.lineId = :lineId',
  //     };
  //     params = {
  //       ...params,
  //       lineId: {
  //         type: 'long',
  //         value: byLine.id,
  //       },
  //     };
  //   }
  //   //console.log.*$

  //   if(byYear > 0 && byYear !== undefined){
  //     filters ={
  //       ...filters,
  //       year: 'obj.year = :year'
  //     }

  //     params = {
  //       ...params,
  //       year: Number(byYear)
  //     }
  //   }

  //   //console.log.*$

  //   if(byMonth > 0 && byMonth !== undefined){
  //     filters ={
  //       ...filters,
  //       byMonth: 'obj.month = :month'
  //     }

  //     params = {
  //       ...params,
  //       month: Number(byMonth)
  //     }
  //   }

  //   if(byId && byId !== undefined){
  //     filters = {
  //       ...filters,
  //       byId: 'obj.id = :byId',
  //     };
  //     params = {
  //       ...params,
  //       byId: {
  //         type: 'long',
  //         value: byId
  //       },
  //     };
  //    }

  //   if (byLines && byLines.length > 0) {
  //     filters = {
  //       ...filters,
  //       byLines: 'obj.lineId in (:lineIds)',
  //     };
  //     let lineIds = byLines.map((it) => {
  //       return {
  //         type: 'long',
  //         value: it.id,
  //       };
  //     });
  //     params = {
  //       ...params,
  //       lineIds,
  //     };
  //   }

  //   if (byLiveFlag !== undefined) {
  //     filters = {
  //       ...filters,
  //       liveFlag: 'obj.flag = :liveFlag',
  //     };
  //     params = {
  //       ...params,
  //       liveFlag: byLiveFlag,
  //     };
  //   }

  //   let start = prodRecsTblStart;
  //   let limit = prodRecsTblLimit;

  //   // let sorts = ['-obj.invDate', '-obj.createdAt'];
  //   let sorts = sortMeta.map((sm) => {
  //     return sm.order > 0 ? sm.field : `-${sm.field}`;
  //   });
  //   // //console.log.*$
  //   // let sortFds = sortMeta.map((sm) => sm.field);
  //   //console.log.*$

  //   api
  //     .getProdRecs({joins, filters, params, start, limit, sorts})
  //     .then((resp) => resp.data)
  //     .then((resp) => {
  //       let {errors, data} = resp;
  //       if (errors.length > 0) {
  //         //console.log.*$
  //         UIUtils.showError({errors, toast: toastRef.current});
  //         return;
  //       }
  //       const {list, factoryMap, lineMap, operatorMap, shiftMap} = data;
  //       list.forEach((rec) => {
  //         if (rec.factoryId) {
  //           rec['factory'] = factoryMap[rec.factoryId];
  //         }
  //         if (rec.lineId) {
  //           rec['line'] = lineMap[rec.lineId];
  //         }
  //         if (rec.shiftId) {
  //           rec['shift'] = shiftMap[rec.shiftId];
  //         }
  //         if (rec.operatorId) {
  //           rec['operator'] = operatorMap[rec.operatorId];
  //         }
  //       });
  //       // //console.log.*$
  //       setProdRecsTblData([...data.list]);
  //       setProdRecsTblNbRows(data.count);
  //     })
  //     .catch((err) => {
  //       //console.log.*$
  //       UIUtils.showError({error: err, toast: toastRef.current});
  //     });
  // }, [
  //   byFactory,
  //   byLine,
  //   byLines,
  //   byYear,
  //   byMonth,
  //   byLiveFlag,
  //   prodRecsTblStart,
  //   prodRecsTblLimit,
  //   reload,
  //   byId,
  //   sortMeta,
  // ]);

  const _onProdRecSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelProdRec(evt.value);
    } else {
      setSelProdRecs(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    //console.log.*$
    setProdRecsTblLimit(evt.rows);
    setProdRecsTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
    let {onAction} = props;
    onAction &&
      onAction({
        type: 'sortChanged',
        value: [...newMeta],
      });
  };

  const _renderFactoryCol: TblColRender = (row: any, col: any) => {
    let {factory} = row;
    return factory ? `${factory.name}` : null;
  };

  const _renderLineCol: TblColRender = (row: any, col: any) => {
    let {line} = row;
    return line ? `${line.name}` : null;
  };

  const _renderRecDateCol: TblColRender = (row: any, col: any) => {
    let {recDate} = row;
    return recDate ? Utils.reFmtDate(recDate, 'DD/MM/YY', false) : null;
  };

  const _renderCrCol: TblColRender = (row: any, col: any) => {
    let {createdAt} = row;
    return createdAt ? Utils.reFmtDate(createdAt, 'DD/MM/YY HH:mm') : null;
  };

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header}
          value={prodRecsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rowsPerPageOptions={[10, 15, 20, 25, 50, 100, 1000, 5000]}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={prodRecsTblLimit}
          first={prodRecsTblStart}
          totalRecords={prodRecsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selProdRec}
          onSelectionChange={_onProdRecSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            selectionMode="multiple"
            headerStyle={{width: 50}}
            headerClassName="text-left"
            bodyClassName="text-left"></Column>
          <Column
            body={_renderRecDateCol}
            header="Date"
            headerStyle={{width: 80}}
            sortable
            sortField="obj.recDate"
          />
          <Column
            field="planPo"
            header="Plan PO"
            headerClassName="text-end"
            bodyClassName="text-end"
          />
          <Column
            field="planSize"
            header="Plan Size"
            headerClassName="text-end"
            bodyClassName="text-end"
          />
          <Column
            field="planTotalprs"
            header="Plan Totalprs"
            headerClassName="text-end"
            bodyClassName="text-end"
          />

          <Column
            field="actualPo"
            header="Actual PO"
            headerClassName="text-end"
            bodyClassName="text-end"
          />

          <Column
            field="actualSize"
            header="Actual Size"
            headerClassName="text-end"
            bodyClassName="text-end"
          />

          <Column
            field="actualTotalprs"
            header="Actual Totalprs"
            headerClassName="text-end"
            bodyClassName="text-end"
          />
          <Column
            field="mixMatching"
            header="Mix Matching"
            headerClassName="text-end"
            bodyClassName="text-end"
          />

          <Column body={_renderLineCol} header="Line" />
          <Column body={_renderFactoryCol} header="Factory" />
          <Column field="description" header="Summary" />
          <Column
            body={_renderCrCol}
            header="Created On"
            headerStyle={{width: 120}}
            sortable
            sortField="obj.createdAt"
          />
          <Column field="createdBy" header="Created By" />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 60}}
            headerClassName="text-end"
            bodyClassName="text-end"
          />
        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header}
          value={prodRecsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rowsPerPageOptions={[10, 15, 20, 25, 50, 100, 1000, 5000]}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={prodRecsTblLimit}
          first={prodRecsTblStart}
          totalRecords={prodRecsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selProdRecs}
          onSelectionChange={_onProdRecSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            selectionMode="multiple"
            headerStyle={{width: 50}}
            headerClassName="text-left"
            bodyClassName="text-left"></Column>
          <Column
            body={_renderRecDateCol}
            header="Date"
            headerStyle={{width: 80}}
            sortable
            sortField="obj.recDate"
          />
          <Column
            field="planPo"
            header="Plan PO"
            headerClassName="text-end"
            bodyClassName="text-end"
          />
          <Column
            field="planSize"
            header="Plan Size"
            headerClassName="text-end"
            bodyClassName="text-end"
          />
          <Column
            field="planTotalprs"
            header="Plan Totalprs"
            headerClassName="text-end"
            bodyClassName="text-end"
          />

          <Column
            field="actualPo"
            header="Actual PO"
            headerClassName="text-end"
            bodyClassName="text-end"
          />

          <Column
            field="actualSize"
            header="Actual Size"
            headerClassName="text-end"
            bodyClassName="text-end"
          />

          <Column
            field="actualTotalprs"
            header="Actual Totalprs"
            headerClassName="text-end"
            bodyClassName="text-end"
          />
          <Column
            field="mixMatching"
            header="Mix Matching"
            headerClassName="text-end"
            bodyClassName="text-end"
          />

          <Column body={_renderLineCol} header="Line" />
          <Column body={_renderFactoryCol} header="Factory" />
          <Column field="description" header="Summary" />
          <Column
            body={_renderCrCol}
            header="Created On"
            headerStyle={{width: 120}}
            sortable
            sortField="obj.createdAt"
          />
          <Column field="createdBy" header="Created By" />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 60}}
            headerClassName="text-end"
            bodyClassName="text-end"
          />
        </DataTable>
      )}
    </>
  );
}
