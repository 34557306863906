// @flow
import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';

//$FlowFixMe
import {TabMenu} from 'primereact/tabmenu';

import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';

//$FlowFixMe[cannot-resolve-module]
import {AuthUtils} from '../../service/AuthUtils';
import {AuthConst} from '../../service/AppConstant';
import {Utils} from '../../service/Utils';
//$FlowFixMe[cannot-resolve-module]
import {AdminApi} from '../../service/AdminApi';
import {updateAppAction} from '../../service/app/action';

// import {ChartFilter} from './ChartFilter';
// import {PphDaily} from './PphDaily';
// import {PphWeekly} from './PphWeekly';
// import {PphMonthly} from './PphMonthly';
// import {CountryPphDaily} from './CountryPphDaily';
// import {CountryPphWeekly} from './CountryPphWeekly';
// import {CountryPphMonthly} from './CountryPphMonthly';
// import {LinePphDaily} from './LinePphDaily';
import {RegionOverview} from '../chart/RegionOverview';
import {RegionOverView1} from '../chart/RegionOverView1';
import {RegionOverViewNbCosting} from '../chart/RegionOverViewNbCosting';
import {FactoryV2Overview} from '../chart/factoryv2/FactoryV2Overview';

type Props = {};

const defFilter = {
  year: 0,
  month: 0,
  region: null,
  regions: [],
  countries: [],
  factories: [],
};

export function StdDashboard(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const gloFilter = useMemo(() => app.facFilter || {}, [app]);
  // //console.log.*$
  const {t} = useTranslation();
  const [tabInd, setTabInd] = useState(0);
  // const [scopes, setScopes] = useState([]);
  const [filter, setFilter] = useState({});
  const [scMenuItems, setScMenuItems] = useState([]);
  const [scope, setScope] = useState<string>(null);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    let authLevel = AuthUtils.getAuthLevel({auth});
    let scopes = ['facGroup', 'factory', 'line', 'model'];
    if (authLevel < 5) {
      scopes.push('factoryv2');
    }
    if (authLevel < 10) {
      scopes = ['region', 'country', ...scopes];
    }
    let mis = scopes.map((it) => {
      return {
        label: t('dashboard.scMenu.' + it + 'Lbl'),
        value: it,
      };
    });
    setScMenuItems([...mis]);
    // //console.log.*$
    setScope(scopes[0]);
    // setScopes([...scs]);
  }, [conf, auth, t]);

  const _fetchRegions = useCallback(async () => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let start = 0;
    let limit = 1;
    return await api
      .getRegions({start, limit})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          return [];
        }
        let {list} = data;
        // //console.log.*$
        return [...list];
      })
      .catch((error) => {
        //console.log.*$
        return [];
      });
  }, []);

  const _fetchCountries = useCallback(async (options) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return [];
    }
    options = options || {};
    let factories = options.factories || [];
    if (factories.length < 1) {
      return [];
    }
    let facIds = factories
      .map((it) => it.id)
      .map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
    let start = 0;
    let limit = 1;
    let filters = {
      byFacs:
        'exists (select 1 from Factory fac where fac.country.id = obj.id' +
        ' and fac.id in (:facIds))',
    };
    let params = {
      facIds,
    };
    // return [];
    return await api
      .getCountries({start, limit, filters, params})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          return [];
        }
        let {list} = data;
        // //console.log.*$
        return [...list];
      })
      .catch((error) => {
        //console.log.*$
        return [];
      });
  }, []);

  const _fetchFacGroups = useCallback(async (options) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return [];
    }
    options = options || {};
    let factories = options.factories || [];
    if (factories.length < 1) {
      return [];
    }
    let facIds = factories
      .map((it) => it.id)
      .map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
    let start = 0;
    let limit = 1;
    let filters = {
      byFacs:
        'exists (select 1 from FactoryGrpLnk lnk where lnk.group.id = obj.id' +
        ' and lnk.factory.id in (:facIds))',
    };
    let params = {
      facIds,
    };
    // return [];
    return await api
      .getFacGroups({start, limit, filters, params})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          return [];
        }
        let {list} = data;
        // //console.log.*$
        return [...list];
      })
      .catch((error) => {
        //console.log.*$
        return [];
      });
  }, []);

  const _loadDefFilter = useCallback(
    async (options) => {
      let {auth, gloFilter} = options;
      let {year, month} = Utils.getDateInfo();
      let fil = {...defFilter, year, month, ...gloFilter};
      let {regions} = fil;
      if (!regions || regions.length < 1) {
        let lst = await _fetchRegions();
        fil = {
          ...fil,
          regions: lst,
        };
        // setFilter({...fil});
        // return;
      }

      let {countries} = fil;
      if (!countries || countries.length < 1) {
        let authLevel = AuthUtils.getAuthLevel({auth});
        if (authLevel >= AuthConst.AUTH_LVL_FACTORY) {
          let factories = Utils.get(auth, 'factoryUsers', [])
            .map((it) => it.factory)
            .filter((it) => !!it);
          // //console.log.*$
          let lst = await _fetchCountries({factories});
          fil = {
            ...fil,
            factories,
            countries: lst,
          };
        }
      }

      let {facGroups} = fil;
      if (!facGroups || facGroups.length < 1) {
        let authLevel = AuthUtils.getAuthLevel({auth});
        if (authLevel >= AuthConst.AUTH_LVL_FACTORY) {
          let factories = Utils.get(auth, 'factoryUsers', [])
            .map((it) => it.factory)
            .filter((it) => !!it);
          // //console.log.*$
          let lst = await _fetchFacGroups({factories});
          fil = {
            ...fil,
            factories,
            facGroups: lst,
          };
        }
      }

      return fil;
    },
    [_fetchRegions, _fetchCountries, _fetchFacGroups],
  );

  useEffect(() => {
    //console.log.*$
    _loadDefFilter({auth, gloFilter}).then((newFil) => {
      let gloRegions = gloFilter.regions || [];
      if (gloRegions.length < 1) {
        let filRegions = newFil.regions || [];
        if (filRegions.length > 0) {
          let act = updateAppAction({facFilter: {...newFil}});
          dispatch(act);
          return;
        }
      }
      setFilter({...newFil});
    });
  }, [auth, dispatch, gloFilter, _loadDefFilter]);

  const _onRegionAction: EvtHandler = (evt: any) => {
    // //console.log.*$
    let {type, value} = evt;
    switch (type) {
      case 'filterChanged':
        // setFilter({...value});
        let data = {
          facFilter: {...value},
        };
        // //console.log.*$
        dispatch(updateAppAction(data));
        break;
      case 'showDetail':
        if (tabInd < scMenuItems.length - 1) {
          setTabInd(tabInd + 1);
          setScope(scMenuItems[tabInd + 1].value);
        }
        break;
      default:
        break;
    }
  };

  //console.log.*$
  // //console.log.*$
  // //console.log.*$
  // const showChartPphByLine = (scope)=>{
  //   if(scope && scope !== 'model'){
  //     return (
  //       <RegionOverview
  //       scope={scope}
  //       filter={filter}
  //       onAction={_onRegionAction}
  //     />
  //     )
  //   }
  // }
  // const pphByModel = (scope)=>{
  //   if(scope && scope === 'model'){
  //   return (
  //     <RegionOverView1
  //     scope={scope}
  //     filter={filter}
  //     onAction={_onRegionAction}
  //   />
  //   )
  //   }
  // }

  const showChart = (auth, scope: string) => {
    if (
      AuthUtils.isAuth({auth, roles: ['NB_VIEWER']}) &&
      auth.username === 'NB_LEAN_Costing'
    ) {
      if (scope && scope === 'model') {
        return (
          <RegionOverView1
            scope={scope}
            filter={filter}
            onAction={_onRegionAction}
          />
        );
      }
      return (
        <RegionOverViewNbCosting
          scope={scope}
          filter={filter}
          onAction={_onRegionAction}
        />
      );
    } else if (scope && scope === 'model') {
      return (
        <RegionOverView1
          scope={scope}
          filter={filter}
          onAction={_onRegionAction}
        />
      );
    } else if (scope && scope === 'factoryv2') {
      return (
        <FactoryV2Overview
          scope={scope}
          filter={filter}
          onAction={_onRegionAction}
        />
      );
    } else {
      return (
        <RegionOverview
          scope={scope}
          filter={filter}
          onAction={_onRegionAction}
        />
      );
    }
  };
  return (
    <>
      <Toast ref={toastRef} />
      <div className="p-mt-3"></div>
      <TabMenu
        model={scMenuItems}
        activeIndex={tabInd}
        onTabChange={(e) => {
          //console.log.*$
          setTabInd(e.index);
          setScope(e.value.value);
        }}
      />

      {showChart(auth, scope)}
      {/* {scope &&(
        <RegionOverview
          scope={scope}
          filter={filter}
          onAction={_onRegionAction}
        />
     )}     */}
    </>
  );
}
