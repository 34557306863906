// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';

//$FlowFixMe[cannot-resolve-module]
import {FileUpload} from 'primereact/fileupload';
import type {ApiProps} from '../../service/Api';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
//$FlowFixMe[cannot-resolve-module]
import {Checkbox} from 'primereact/checkbox';

type Props = {
  readOnly?: boolean,
  andonDetail: any,
  audit:any,
  onAction: (evt: any) => void,
};

const defFollowUpItem = {
  __cls__: 'com.nb.lmrs.model.entity.FollowUpItem',
  flag: 0,
  idNo: 0,
  ofi:null,
  ncMinor:null,
  ncMajor:null,
  code: '',
  name: '',
  pfaAuditGroup:null,
  pictureName: '',
  position: '',
  description: '',

};

export function FollowUpItemForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {readOnly, andonDetail, audit} = props;
  const [editFollowUpItem, setEditFollowUpItem] = useState<any>();
  console.log(audit);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);


  useEffect(() => {
    let curAndonDetail = andonDetail || {};
    curAndonDetail = {
      ...defFollowUpItem,
      ...curAndonDetail,
    };

    let isNew = !curAndonDetail.id;
    if (isNew && (!curAndonDetail.factory || !curAndonDetail.factory.id)) {
      //curAndonDetail['factory'] = defFactory;
    }

    setEditFollowUpItem(curAndonDetail);
  }, [andonDetail]);

  const onFileUpload = (event:any) => {
    const file = event.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result.split(',')[1];
      setEditFollowUpItem({
        ...editFollowUpItem,
        pictureName: file.name,
        picture: base64String,
      });
    };
    reader.readAsDataURL(file);
  };

  const onFileRemove = () => {
    setEditFollowUpItem({
      ...editFollowUpItem,
      pictureName: null,
      picture: null,
    });
  };

  const _onSave = (evt: any) => {
    let fac = _validateFollowUpItem();
    console.log('fac', fac);
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update follow up item?'
        : 'Are you sure you want to create follow up item?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateFollowUpItem({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            console.log(data);
            if (errors.length > 0) {
              //console.log.*$
              let {onAction} = props;
            onAction &&
              onAction({
                type: 'machLineSaved',
                value: data.entity,
              });
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'machLineSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _validateFollowUpItem: () => any = () => {
    let obj = {...editFollowUpItem, pfaAuditGroup:audit};

    return obj;
  };

  if (!editFollowUpItem) {
    return null;
  }

  const saveLbl = editFollowUpItem.id
    ? 'Update follow up item'
    : 'Create follow up item';
 // const isNew = !editFollowUpItem.id;

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/edit follow up item">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editFollowUpItem.idNo}
                onChange={(e) => {
                  setEditFollowUpItem({
                    ...editFollowUpItem,
                    idNo: e.target.value,
                  });
                }}
              />
              <label>Number</label>
            </span>
          </div>

          <div className="p-col-12">
          <span className="p-float-label">
              <InputText
                value={editFollowUpItem.name}
                onChange={(e) => {
                  setEditFollowUpItem({
                    ...editFollowUpItem,
                    name: e.target.value,
                  });
                }}
              />
              <label>LA Criteria</label>
            </span>
          </div>
          <div className="p-col-12 p-xl-4 p-lg-4">
          <span>
                <Checkbox
                  onChange={(e) => {
                    setEditFollowUpItem({
                      ...editFollowUpItem,
                      ofi: e.checked,
                    });
                  }}
                  checked={editFollowUpItem.ofi}
                />
                <label className="p-checkbox-label ml-1">OFI</label>
              </span>
          </div>

          <div className="p-col-12 p-xl-4 p-lg-4">
          <span>
                <Checkbox
                  onChange={(e) => {
                    setEditFollowUpItem({
                      ...editFollowUpItem,
                      ncMinor: e.checked,
                    });
                  }}
                  checked={editFollowUpItem.ncMinor}
                />
                <label>NC Minor</label>
              </span>
          </div>

          <div className="p-col-12 p-xl-4 p-lg-4">
          <span>
                <Checkbox
                  onChange={(e) => {
                    setEditFollowUpItem({
                      ...editFollowUpItem,
                      ncMajor: e.checked,
                    });
                  }}
                  checked={editFollowUpItem.ncMajor}
                />
                <label>NC Major</label>
              </span>
          </div>

          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <FileUpload
                  mode="basic"
                  accept="image/*"
                  maxFileSize={1000000}
                  // onUpload={onFileUpload}
                  onSelect={onFileUpload}
                  onRemove={onFileRemove}
                  auto
                  chooseLabel="Choose Image"
                  disabled={false}
                />

              </span>
              {editFollowUpItem.picture && (
                <div>
                  <small className="p-d-block">
                    Uploaded: {editFollowUpItem.pictureName}
                  </small>
                  <img
                    src={`data:image/jpeg;base64,${editFollowUpItem.picture}`}
                    alt="Preview"
                    style={{maxWidth: '100%', maxHeight: '200px'}}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputTextarea
                  rows={2}
                  value={editFollowUpItem.description}
                  onChange={(e) => {
                    setEditFollowUpItem({
                      ...editFollowUpItem,
                      description: e.target.value,
                    });
                  }}
                  disabled={readOnly}
                />
                <label>Description</label>
              </span>
            </div>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
