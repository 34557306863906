// @flow
import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

// import {Toast} from 'primereact/toast';
// import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
// import {InputTextarea} from 'primereact/inputtextarea';
// import {Button} from 'primereact/button';
// import {Dialog} from 'primereact/dialog';
//$FlowFixMe[cannot-resolve-module]
import {Dropdown} from 'primereact/dropdown';
//$FlowFixMe[cannot-resolve-module]
import {AutoComplete} from 'primereact/autocomplete';
// import {confirmDialog} from 'primereact/confirmdialog';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../../service/AuthUtils';
import {Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  filter: any,
  scope?: string,
  showCountry?: boolean,
  showLine?: boolean,
  onAction: (evt: any) => void,
};

const defFilter = {
  region: null,
  regions: [],
  country: null,
  countries: [],
  factory: null,
  factories: [],
  line: null,
  lines: [],
  year: 2000,
  month: 1,
};

export function ChartFilter(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();

  const {filter} = props;
  const scope = props.scope || 'region';

  const [editFilter, setEditFilter] = useState({...defFilter});
  const [suggCountries, setSuggCountries] = useState([]);
  const [suggFactories, setSuggFactories] = useState([]);
  const [suggLines, setSuggLines] = useState([]);
  const [suggRegions, setSuggRegions] = useState([]);
  const [avaiMonths, setAvaiMonths] = useState([]);

  // const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let months = [];
    for (let i = 1; i <= 12; i++) {
      months.push(i);
    }
    let opts = months.map((m) => {
      return {
        label: t(`mon.${m}`),
        value: m,
      };
    });
    // //console.log.*$
    setAvaiMonths([
      {
        label: t('mon.'),
        value: -1,
      },
      ...opts,
    ]);
  }, [t]);

  useEffect(() => {
    let di = Utils.getDateInfo(new Date());

    let newFilter = {
      ...defFilter,
      year: di.year,
      month: di.month,
    };

    let curFilter = filter || {};
    curFilter = {
      ...newFilter,
      ...curFilter,
    };

    let {regions, countries, factories, lines} = curFilter;
    regions && regions.forEach((it) => (it.label = `${it.code} :: ${it.name}`));
    countries &&
      countries.forEach((it) => (it.label = `${it.code} :: ${it.name}`));
    factories &&
      factories.forEach((it) => (it.label = `${it.code} :: ${it.name}`));
    lines && lines.forEach((it) => (it.label = `${it.code} :: ${it.name}`));

    setEditFilter(curFilter);
  }, [filter]);

  const _processFilterChange: (field: string, value: any) => any = (
    field: string,
    value: any,
  ) => {
    let newFil = {...editFilter};
    newFil[field] = value;
    let {regions, countries, factories, lines} = newFil;
    let regIds = regions.map((it) => it.id);
    countries = countries.filter((it) => {
      return regIds.includes(it.region.id);
    });
    let counIds = countries.map((it) => it.id);
    factories = factories.filter((it) => {
      return counIds.includes(it.country.id);
    });
    let facIds = factories.map((it) => it.id);
    lines = lines.filter((it) => {
      return facIds.includes(it.factory.id);
    });
    newFil = {
      ...newFil,
      regions,
      countries,
      factories,
      lines,
    };
    setEditFilter({...newFil});
    let {onAction} = props;
    onAction &&
      onAction({
        type: 'filterChanged',
        value: {...newFil},
      });
  };

  const _onSuggRegions: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let search = evt.query;
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getRegions({search, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          // UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        data.list.forEach((it) => (it.label = `${it.name}`));
        setSuggRegions([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        // UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onSuggCountries: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let joins = [
      {
        type: 'join',
        expr: 'obj.region',
        alias: 'reg',
      },
    ];
    let filters = {};
    let params = {};
    let {regions} = editFilter;
    if (regions && regions.length > 0) {
      filters = {
        ...filters,
        byRegions: 'reg.id in (:regIds)',
      };
      let regIds = regions.map((r) => {
        return {
          type: 'long',
          value: r.id,
        };
      });
      params = {
        ...params,
        regIds,
      };
    }
    let search = evt.query;
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getCountries({search, joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          // UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        data.list.forEach((it) => (it.label = `${it.code} :: ${it.name}`));
        setSuggCountries([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        // UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onSuggFactories: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let params = {};
    let filters = {};

    let countries = editFilter.countries || [];
    filters = {
      ...filters,
      byCountry: 'obj.country.id in (:countryIds)',
    };
    let countryIds = countries.map((c) => {
      return {
        type: 'long',
        value: c.id,
      };
    });
    params = {
      ...params,
      countryIds,
    };

    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getFactories({search, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          // UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        data.list.forEach((it) => (it.label = `${it.code} :: ${it.name}`));
        setSuggFactories([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        // UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onSuggLines: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let filters = {};
    let params = {};

    let factories = editFilter.factories || [];
    filters = {
      ...filters,
      byFactory: 'obj.factory.id in (:facIds)',
    };
    let facIds = factories.map((f) => {
      return {
        type: 'long',
        value: f.id,
      };
    });
    params = {
      ...params,
      facIds,
    };

    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getMachLines({search, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          // UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        setSuggLines([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        // UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  let showRegions = scope === 'region';
  let showCountries = scope === 'country';
  let showFactories = scope === 'factory';
  let showLines = scope === 'line';

  return (
    <>
      <div className="grid p-fluid">
        {/*showCountry && (
          <div className="col-12 lg:col-2">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                suggestions={suggCountries}
                field="label"
                value={editFilter.country}
                completeMethod={_onSuggCountries}
                onChange={(e) => _processFilterChange('country', e.value)}
              />
              <label>Country</label>
            </span>
          </div>
        )*/}
        {/*showFactory && (
          <div className="col-12 lg:col-2">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                suggestions={suggFactories}
                field="label"
                value={editFilter.factory}
                completeMethod={_onSuggFactories}
                onChange={(e) => _processFilterChange('factory', e.value)}
              />
              <label>Factory</label>
            </span>
          </div>
        )*/}
        {/*showLine && (
          <div className="col-12 lg:col-6">
            <span className="p-float-label">
              <AutoComplete
                multiple
                dropdown
                suggestions={suggLines}
                field="name"
                value={editFilter.lines}
                completeMethod={_onSuggLines}
                onChange={(e) => _processFilterChange('lines', e.value)}
                disabled={!editFilter.factory || !editFilter.factory.id}
              />
              <label>Line</label>
            </span>
          </div>
        )*/}
        {showRegions && (
          <div className="col-12 lg:col-6">
            <span className="p-float-label">
              <AutoComplete
                multiple
                dropdown
                suggestions={suggRegions}
                field="label"
                value={editFilter.regions}
                completeMethod={_onSuggRegions}
                onChange={(e) => _processFilterChange('regions', e.value)}
              />
              <label>Region</label>
            </span>
          </div>
        )}
        {showCountries && (
          <div className="col-12 lg:col-6">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                multiple
                suggestions={suggCountries}
                field="label"
                value={editFilter.countries}
                completeMethod={_onSuggCountries}
                onChange={(e) => _processFilterChange('countries', e.value)}
              />
              <label>Countries</label>
            </span>
          </div>
        )}
        {showFactories && (
          <div className="col-12 lg:col-6">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                multiple
                suggestions={suggFactories}
                field="label"
                value={editFilter.factories}
                completeMethod={_onSuggFactories}
                onChange={(e) => _processFilterChange('factories', e.value)}
              />
              <label>Factories</label>
            </span>
          </div>
        )}
        {showLines && (
          <div className="col-12 lg:col-6">
            <span className="p-float-label">
              <AutoComplete
                multiple
                dropdown
                suggestions={suggLines}
                field="name"
                value={editFilter.lines}
                completeMethod={_onSuggLines}
                onChange={(e) => _processFilterChange('lines', e.value)}
              />
              <label>Lines</label>
            </span>
          </div>
        )}
        <div className="col-12 xl:col-1 lg:col-2 md:col-3 sm:col-4">
          <span className="p-float-label">
            <InputText
              keyfilter="pint"
              value={editFilter.year}
              onChange={(e) => _processFilterChange('year', e.target.value)}
            />
            <label>Year</label>
          </span>
        </div>
        <div className="col-12 xl:col-1 lg:col-2 md:col-3 sm:col-4">
          <span className="p-float-label">
            <Dropdown
              value={editFilter.month}
              options={avaiMonths}
              onChange={(e) => _processFilterChange('month', e.value)}
            />
            <label>Month</label>
          </span>
        </div>
      </div>
    </>
  );
}
