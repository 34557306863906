// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';

//$FlowFixMe[cannot-resolve-module]
import {Dialog} from 'primereact/dialog';

//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';
import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
import {useParams} from 'react-router-dom';
import { Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {FactoryFilter} from '../common/FactoryFilter';

import {ProdRecForm} from './ProdRecForm';
import {AlarmList} from './AlarmList';

type Props = {};

export function Alarm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const rtParams = useParams();
  //const [selProdRec, setSelProdRec] = useState<any>();
  const [selRecs, setSelRecs] = useState([]);
  const [editProdRec, setEditProdRec] = useState<any>();
  const [showDlg, setShowDlg] = useState<any>(false);
 // const [reload, setReload] = useState(false);
  const [tabInd, setTabInd] = useState(0);
  const [filter, setFilter] = useState<any>({});
  const [initFilter, setInitFilter] = useState({});
  const [defSort, setDefSort] = useState([]);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let initFil = {};
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      initFil['factory'] = factories[0];
    }

    let filVal = rtParams.filter;
   // console.log(filVal);
    if(filVal){
        let inpFilter = JSON.parse(atob(filVal));

        let filterFac = {
            ...inpFilter.factory,
            label: `${inpFilter.factory.code} :: ${inpFilter.factory.name}`
        }

        initFil = {
          ...initFil,
          factory: filterFac,
        };

     }

    //   //console.log.*$
    let di: any = Utils.getDateInfo() || {};
    initFil = {
      ...initFil,
      month: di.month,
      year: di.year,
    };
    setFilter(initFil);

    setInitFilter({...initFil});
  }, [auth, rtParams]);

  useEffect(() => {
    let sms = [
      {
        field: 'obj.recDate',
        order: -1,
      },
    ];
    setDefSort(sms);
  }, []);

  const _onFilterAction: EvtHandler = (evt: any) => {
    let {type, value} = evt;
    if (type === 'filterChanged') {
      setFilter({...value});
      return;
    }
  };


  const _onProdRecSelected = (evt: any) => {

    let recs = [...evt.value];
    setSelRecs([...recs]);
    let len = recs.length;
    if (len > 0) {
      setEditProdRec({...recs[len - 1]});

    } else {
      setEditProdRec({});

    }
  };



  return (
    <>
      <Toast ref={toastRef} />

      <TabView activeIndex={tabInd} onTabChange={(e) => setTabInd(e.index)}>
        <TabPanel header="Notifications">
          <FactoryFilter
            scope="factory"
            filter={initFilter}
            showFactory={true}
            showYear={true}
            showAlarmStatus={true}
            showMonth={true}
            onAction={_onFilterAction}
          />

          <AlarmList
            byId={filter.id}
            byFactory={filter.factory}
            defSort={defSort}
            byAlarmStatus={filter.alarmStatus}
            byMonth={filter.month}
            byYear={filter.year}
            //reload={reload}
            selectionMode="multi"
            selection={selRecs}
            onSelect={_onProdRecSelected}
          />
          {/* {canEdit && (
            <div className="p-d-flex p-jc-end p-my-2">
              <Button
                label="New"
                icon="pi pi-plus"
                className="p-ml-2"
                onClick={_onNewProdRec}
              />
              {selProdRec && (
                <Button
                  label="Edit"
                  icon="pi pi-pencil"
                  className="p-ml-2"
                  onClick={_onEditProdRec}
                  disabled={selProdRec.flag === 1}
                />
              )}
              {selRecs.length > 0 && (
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  className="p-ml-2"
                  onClick={_onDelete}
                />
              )}
            </div>
          )} */}
        </TabPanel>
      </TabView>

      <Dialog
        header="Enter production record"
        visible={showDlg}
        style={{width: '90vw', height: '90vh'}}
        modal={true}
        onHide={() => {
          setShowDlg(false);
        }}>
        {showDlg && <ProdRecForm prodRec={editProdRec} />}
      </Dialog>
    </>
  );
}
