// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Dropdown} from 'primereact/dropdown';

//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';

//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';

import type {ApiProps} from '../../service/Api';

import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  readOnly?: boolean,
  maxScore: any,
  onAction: (evt: any) => void,
};

const defMaxScore = {
  __cls__: 'com.nb.lmrs.model.entity.MaxScorePotential',
  flag: 0,
  maxScoreType: '',
  value: '',
  month: -1,
  year: '',
};

export function MaxPotentialScoreForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {readOnly, maxScore} = props;
  const [editMaxScore, setEditMaxScore] = useState<any>();

  const [defFactory, setDefFactory] = useState();
  const [avaiKpiTypes, setAvaiKpiTypes] = useState([]);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      setDefFactory(factories[0]);
    }
  }, [auth]);

  useEffect(() => {
    let types = [
      ['PPH', 'PPH'],
      ['MLT', 'MLT'],
      ['MINL_RATE', 'MINI LINE'],
      ['LEAN_SCORE', 'LEAN SCORE'],
      ['PO', 'PO'],
      ['BTS', 'BTS'],
    ];

    let typeOpts = types.map((it) => {
      return {
        label: it[1],
        value: it[0],
      };
    });
    setAvaiKpiTypes(typeOpts);
  }, []);

  useEffect(() => {
    // //console.log.*$
    let curMaxScore = maxScore || {};
    curMaxScore = {
      ...defMaxScore,
      ...curMaxScore,
    };

    let isNew = !curMaxScore.id;
    if (isNew && (!curMaxScore.factory || !curMaxScore.factory.id)) {
      curMaxScore['factory'] = defFactory;
    }

    let {factory} = curMaxScore;
    if (factory) {
      factory.label = `${factory.code} :: ${factory.name}`;
    }

    setEditMaxScore(curMaxScore);
  }, [maxScore, defFactory]);

  const _onSave = (evt: any) => {
    // setShowCfmDlg(false);
    let fac = _validateMachLine();
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update max score potential?'
        : 'Are you sure you want to create max score potential?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateMaxScorePotential({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'machLineSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _validateMachLine: () => any = () => {
    let obj = {...editMaxScore};

    //console.log.*$
    if (obj.month === '' || obj.month === null) {
      UIUtils.showError({
        detail: 'Please enter the month',
        toast: toastRef.current,
      });
      return false;
    }

    if (obj.year === '' || obj.year === null) {
      UIUtils.showError({
        detail: 'Please enter the year',
        toast: toastRef.current,
      });
      return false;
    }

    if (obj.score === '' || obj.score === null) {
      UIUtils.showError({
        detail: 'Please enter score',
        toast: toastRef.current,
      });
      return false;
    }
    return obj;
  };

  if (!editMaxScore) {
    return null;
  }

  const saveLbl = editMaxScore.id
    ? 'Update Max Potential Score '
    : 'Create Max Potential Score ';
  //const isNew = !editMaxScore.id;
  // //console.log.*$

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="Max Potential Score ">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <div class="p-grid">
              {/* <div class="p-col-6">
                <span className="p-float-label">
                  <Dropdown
                    optionLabel="label"
                    optionValue="value"
                    keyfilter="pint"
                    value={editMaxScore.month}
                    options={avaiMonths}
                    onChange={(e) => {
                      setEditMaxScore({...editMaxScore, month: e.value});
                    }}
                    disabled
                  />
                  <label>Month</label>
                </span>
              </div> */}
              <div className="p-col-6">
                <span className="p-float-label">
                  <Dropdown
                    optionLabel="label"
                    optionValue="value"
                    value={editMaxScore.maxScoreType}
                    options={avaiKpiTypes}
                    onChange={(e) =>
                      setEditMaxScore({
                        ...editMaxScore,
                        maxScoreType: e.target.value,
                      })
                    }
                    disabled={false}
                  />
                  <label>Type</label>
                </span>
              </div>
              <div class="p-col-6">
                <span className="p-float-label">
                  <InputText
                    keyfilter="pint"
                    value={editMaxScore.year}
                    onChange={(e) => {
                      setEditMaxScore({...editMaxScore, year: e.target.value});
                    }}
                    // disabled={readOnly}
                  />
                  <label>Year</label>
                </span>
              </div>
            </div>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editMaxScore.value}
                keyfilter={editMaxScore.maxScoreType === 'MLT' ? 'pint' : 'num'}
                onChange={(e) => {
                  setEditMaxScore({...editMaxScore, value: e.target.value});
                }}
                // disabled={!isNew || readOnly}
              />
              <label>Max Score</label>
            </span>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
