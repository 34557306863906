// @flow
import React, {useState, useRef, useEffect} from 'react';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {Dialog} from 'primereact/dialog';
//$FlowFixMe[cannot-resolve-module]
import {Password} from 'primereact/password';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {CoreApi} from '../../service/CoreApi';

type Props = {
  onAction: (evt: any) => void,
};

export function ChangePwd(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const [showCfmDlg, setShowCfmDlg] = useState<any>(false);
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newPass2, setNewPass2] = useState('');

  const toastRef = useRef();
  const apiRef = useRef<?CoreApi>();

  useEffect(() => {
    //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new CoreApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _onSaveClicked = (evt: any) => {
    if (oldPass.length < 1 || newPass.length < 1 || newPass2.length < 1) {
      UIUtils.showError({
        detail: 'Password should not empty',
        toast: toastRef.current,
      });
      return;
    }

    if (newPass2 !== newPass) {
      UIUtils.showError({
        detail: 'New password not matched',
        toast: toastRef.current,
      });
      return;
    }
    setShowCfmDlg(true);
  };

  const _onCfmDlgOk = (evt: any) => {
    setShowCfmDlg(false);

    let api: ?CoreApi = apiRef.current;
    if (!api) {
      return;
    }
    api
      .changePassword({
        login: auth.username,
        oldPass,
        newPass,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //clear form
        UIUtils.showInfo({
          detail: 'Change password success',
          toast: toastRef.current,
        });
        let {onAction} = props;
        setTimeout(() => {
          onAction &&
            onAction({
              type: 'result',
              value: 'success',
            });
        }, 10);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const cfmDlgFooter = (
    <div className="text-right">
      <Button label="Yes" icon="pi-md-check" onClick={_onCfmDlgOk} />
      <Button
        label="No"
        icon="pi-md-cancel"
        onClick={(e) => {
          setShowCfmDlg(false);
        }}
      />
    </div>
  );

  return (
    <>
      <Toast ref={toastRef} />

      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="p-field mt-3">
            <span className="p-float-label">
              <Password
                value={oldPass}
                feedback={false}
                onChange={(e) => {
                  setOldPass(e.target.value);
                }}
              />
              <label>Current password</label>
            </span>
          </div>
        </div>
        <div className="p-col-12">
          <div className="p-field">
            <span className="p-float-label">
              <Password
                value={newPass}
                onChange={(e) => {
                  setNewPass(e.target.value);
                }}
              />
              <label>New password</label>
            </span>
          </div>
        </div>
        <div className="p-col-12">
          <div className="p-field">
            <span className="p-float-label">
              <Password
                value={newPass2}
                onChange={(e) => {
                  setNewPass2(e.target.value);
                }}
              />
              <label>Repeat new password</label>
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end flex-wrap">
        <Button
          className="mt-2"
          label="Save"
          icon="pi-md-save"
          onClick={_onSaveClicked}
        />
      </div>

      <Dialog
        header="Confirm change password"
        footer={cfmDlgFooter}
        visible={showCfmDlg}
        style={{width: '400px'}}
        modal={true}
        onHide={() => {
          setShowCfmDlg(false);
        }}>
        Are you sure to change your password?
      </Dialog>
    </>
  );
}
