// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
// import {Dialog} from 'primereact/dialog';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
// import {TabView, TabPanel} from 'primereact/tabview';
// import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {FileUpload} from 'primereact/fileupload';
//$FlowFixMe[cannot-resolve-module]
import {Dropdown} from 'primereact/dropdown';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';
//$FlowFixMe[cannot-resolve-module]
import {ProgressSpinner} from 'primereact/progressspinner';

import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
// import {AuthUtils} from '../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {ProdRecMdList} from '../prod/ProdRecMdList';
import {FilterById} from '../common/FilterById';

type Props = {
  onAction?: any,
};

export function ImportDataByModels(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const [impTypeOpts, setImpTypeOpts] = useState([]);
  const [selImpType, setSelImpType] = useState();
  const [uploadUrl, setUploadUrl] = useState('');
  const [tabInd, setTabInd] = useState(0);
  const [filById, setFilById] = useState();
  const [selProdRecs, setSelProdRecs] = useState([]);
  const [reloadProdRecsFlag, setReloadProdRecsFlag] = useState(false);
  const [defSort, setDefSort] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    setDefSort([
      {
        field: 'obj.recDate',
        order: -1,
      },
    ]);
  }, []);

  useEffect(() => {
    let types = ['prodRec', 'errorRec'];
    let lbls = ['Production data', 'Error data'];
    let typeOpts = types.map((it, ind) => {
      return {
        label: lbls[ind],
        value: it,
      };
    });
    setImpTypeOpts(typeOpts);
    setSelImpType(types[0]);
  }, []);

  useEffect(() => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let url = api.getProdRecMdUploadUrl();
    //console.log.*$
    setUploadUrl(url);
  }, []);

  const _onBeforeUploadFile: any = (event: any) => {
    //console.log.*$
    //  let {xhr, formData} = event;
    //console.log.*$
    // formData['impType'] = selImpType;
    //console.log.*$
  };

  const _onBeforeSendFile: any = (event: any) => {
    //console.log.*$
    let {xhr} = event;
    xhr.setRequestHeader('token', auth.token);
    // xhr.setRequestHeader('Content-Type', 'multipart/form-data');
    setShowSpinner(true);
  };

  const _onUploadDone: any = (event: any) => {
    setShowSpinner(false);
    //console.log.*$
    setReloadProdRecsFlag(!reloadProdRecsFlag);
    let {xhr} = event;
    if (!xhr || xhr.status !== 200) {
      UIUtils.showError({
        detail: 'Failed to upload report document',
        toast: toastRef.current,
      });
      return;
    }

    let resp = JSON.parse(xhr.response);
    let {errors} = resp;
    if (errors && errors.length > 0) {
      UIUtils.showError({errors, toast: toastRef.current});
      return;
    }

    let {onAction} = props;
    onAction &&
      onAction({
        type: 'fileUploaded',
      });
  };

  const _onUploadError: any = (event: any) => {
    //console.log.*$
    setReloadProdRecsFlag(!reloadProdRecsFlag);
  };

  const _onProdRecSelected: (evt: any) => void = (evt: any) => {
    setSelProdRecs(evt.value);
  };

  const _onApprove: EvtHandler = (evt: any) => {
    if (selProdRecs.length < 1) {
      UIUtils.showError({
        detail: 'No record selected',
        toast: toastRef.current,
      });
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let acceptFn = () => {
      let ids = selProdRecs.map((it) => it.id);
      setShowSpinner(true);
      api
        .approveProdRecsMd(ids)
        .then((resp) => resp.data)
        .then((resp) => {
          let {errors} = resp;
          if (errors.length > 0) {
            //console.log.*$
            UIUtils.showError({errors, toast: toastRef.current});
            return;
          }
          setSelProdRecs([]);
          setReloadProdRecsFlag(!reloadProdRecsFlag);
        })
        .catch((error) => {
          //console.log.*$
          UIUtils.showError({error, toast: toastRef.current});
        })
        .finally(() => {
          setShowSpinner(false);
        });
    };
    confirmDialog({
      message: 'Are you sure to approve selected records?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: acceptFn,
    });
  };

  const _onApproveAll: EvtHandler = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let acceptFn = () => {
      let ids = [];
      setShowSpinner(true);
      api
        .approveProdRecsMd(ids, {__all: true})
        .then((resp) => resp.data)
        .then((resp) => {
          let {errors} = resp;
          if (errors.length > 0) {
            //console.log.*$
            UIUtils.showError({errors, toast: toastRef.current});
            return;
          }
          setSelProdRecs([]);
          setReloadProdRecsFlag(!reloadProdRecsFlag);
        })
        .catch((error) => {
          //console.log.*$
          UIUtils.showError({error, toast: toastRef.current});
        })
        .finally(() => {
          setShowSpinner(false);
        });
    };
    confirmDialog({
      message: 'Are you sure to approve all imported records?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: acceptFn,
    });
  };

  const _onDelete: EvtHandler = (evt: any) => {
    if (selProdRecs.length < 1) {
      UIUtils.showError({
        detail: 'No record selected',
        toast: toastRef.current,
      });
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let acceptFn = () => {
      let ids = selProdRecs.map((it) => it.id);
      setShowSpinner(true);
      api
        .deleteProdRecsMd(ids)
        .then((resp) => resp.data)
        .then((resp) => {
          let {errors} = resp;
          if (errors.length > 0) {
            //console.log.*$
            UIUtils.showError({errors, toast: toastRef.current});
            return;
          }
          setSelProdRecs([]);
          setReloadProdRecsFlag(!reloadProdRecsFlag);
        })
        .catch((error) => {
          //console.log.*$
          UIUtils.showError({error, toast: toastRef.current});
        })
        .finally(() => {
          setShowSpinner(false);
        });
    };
    confirmDialog({
      message: 'Are you sure to remove selected records?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: acceptFn,
    });
  };

  const _onDeleteAll: EvtHandler = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let acceptFn = () => {
      let ids = [];
      setShowSpinner(true);
      api
        .deleteProdRecsMd(ids, {__all: true})
        .then((resp) => resp.data)
        .then((resp) => {
          let {errors} = resp;
          if (errors.length > 0) {
            //console.log.*$
            UIUtils.showError({errors, toast: toastRef.current});
            return;
          }
          setSelProdRecs([]);
          setReloadProdRecsFlag(!reloadProdRecsFlag);
        })
        .catch((error) => {
          //console.log.*$
          UIUtils.showError({error, toast: toastRef.current});
        })
        .finally(() => {
          setShowSpinner(false);
        });
    };
    confirmDialog({
      message: 'Are you sure to remove all imported records?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: acceptFn,
    });
  };

  const onChangeFilter = (value) => {
    setFilById(value);
  };
  return (
    <>
      <Toast ref={toastRef} />
      {showSpinner && <ProgressSpinner className="app-spinner" />}
      <div className="grid p-fluid">
        {false && (
          <div className="col-12 lg:col-3">
            <div className="p-field">
              <span className="p-float-label">
                <Dropdown
                  value={selImpType}
                  options={impTypeOpts}
                  onChange={(e) => setSelImpType(e.value)}
                />
                <label>Import type</label>
              </span>
            </div>
          </div>
        )}
        <div className="col-12 lg:col-6">
          <FileUpload
            name="file"
            contentStyle={{height: 120}}
            url={uploadUrl}
            auto={true}
            onBeforeSend={_onBeforeSendFile}
            onBeforeUpload={_onBeforeUploadFile}
            onUpload={_onUploadDone}
            onError={_onUploadError}></FileUpload>
        </div>
        <div className="col-12 lg:col-6">
          <div>
            (*) Imported records need to be approved, otherwise there will be no
            effect.
            <br />
            Excel format: <br />
            <ul>
              <li>
                Header: Date | Factory | Hours | Output | PureIE | Style |
                Material
              </li>
              <li>Factory => Factory code, Model => Model code</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="p-mt-3"></div>
      <TabView activeIndex={tabInd} onTabChange={(e) => setTabInd(e.index)}>
        <TabPanel header="Imported records">
          <FilterById onAction={onChangeFilter} />
          <ProdRecMdList
            byId={filById}
            byLiveFlag={-3}
            defSort={defSort}
            reload={reloadProdRecsFlag}
            selectionMode="multi"
            selection={selProdRecs}
            onSelect={_onProdRecSelected}
          />
          <div className="p-d-flex p-jc-end p-my-2">
            <Button
              label="Approve"
              icon="pi pi-check"
              className="p-ml-2"
              onClick={_onApprove}
              disabled={selProdRecs.length < 1 || showSpinner}
            />
            <Button
              label="Approve all"
              icon="pi pi-check"
              className="p-ml-2"
              disabled={showSpinner}
              onClick={_onApproveAll}
            />
            <Button
              label="Delete"
              icon="pi pi-trash"
              className="p-ml-2"
              onClick={_onDelete}
              disabled={selProdRecs.length < 1 || showSpinner}
            />
            <Button
              label="Delete all"
              icon="pi pi-trash"
              className="p-ml-2"
              disabled={showSpinner}
              onClick={_onDeleteAll}
            />
          </div>
        </TabPanel>
      </TabView>
    </>
  );
}
