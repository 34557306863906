// @flow
import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';

type Props = {
  selectionMode?: string,
  selection?: any,
  audit?:any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function FollowUpItemList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const {t} = useTranslation();
  const [andonDetailsTblData, setAndonDetailsTblData] = useState([]);
  const [andonDetailsTblStart, setAndonDetailsTblStart] = useState(0);
  const [andonDetailsTblLimit] = useState(10);
  const [andonDetailsTblNbRows, setMachLinesTblNbRows] = useState(0);
  const [selAndonDetail, setSelAndonDetail] = useState();
  const [selAndonDetails, setSelAndonDetails] = useState([]);
  const [sortMeta, setSortMeta] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);

  const {reload, selection, header, command,audit} = props;
  const selMode = props.selectionMode || 'single';
  const {search} = app || '';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelAndonDetail({...selection});
      } else {
        setSelAndonDetails([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelAndonDetail(null);
      } else {
        setSelAndonDetails([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let start = andonDetailsTblStart;
    let limit = andonDetailsTblLimit;
    let joins = [];
    let filters ={}
    let params={}

    if(audit && audit.id){
    filters = {
        ...filters ,
        byAudit: 'obj.pfaAuditGroup.id = :byAuditId'
    }
    params = {
        ...params ,
        byAuditId: {
            type:'long',
            value:audit.id
        }
    }
}
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });


    api
      .getFollowUpItems({search, joins, start,filters,params, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        console.log(data);
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        console.log(data.list);
        setAndonDetailsTblData([...data.list]);
        setMachLinesTblNbRows(data.count);
      })
      .catch((err) => {
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, [search, andonDetailsTblStart, andonDetailsTblLimit, reload, sortMeta,audit]);

  const _onMachLineSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelAndonDetail(evt.value);
    } else {
      setSelAndonDetails(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    setAndonDetailsTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];

    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };


  const _renderPictureCol: TblColRender = (row: any, col: any) => {
    let {picture} = row;
    if (picture) {
      return (
        <img
          src={`data:image/jpeg;base64,${picture}`}
          alt="pic"
          style={{maxWidth: '200px', maxHeight: '200px'}}
        />
      );
    }
    return null;
  };

  const _renderOfiCol = (row: any, col: any) => {
    let {ofi} = row;
    const backgroundColor = ofi === true ? '#ffff00' : '#f8f9fa';
    const iconClassName = ofi === true ? 'pi pi-check' : 'pi pi-times';
    return (
        <div style={{
          backgroundColor,
          position: 'absolute',
          inset: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // borderRadius: '4px'
        }}>
         <i className={iconClassName} style={{fontSize: '1.5rem'}}></i>
        </div>
      );
  };

  const _renderNcMinorCol = (row: any, col: any) => {
    let {ncMinor} = row;
    const backgroundColor = ncMinor === true ? '#ff0000' : '#f8f9fa';
    const iconClassName = ncMinor === true ? 'pi pi-check' : 'pi pi-times';
    return (
        <div style={{
          backgroundColor,
          position: 'absolute',
          inset: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // borderRadius: '4px'
        }}>
         <i className={iconClassName} style={{fontSize: '1.5rem'}}></i>
        </div>
      );
  };
  const _renderNcMajorCol = (row: any, col: any) => {
    let {ncMajor} = row;
    const backgroundColor = ncMajor === true ? '#c00000' : '#f8f9fa';
    const iconClassName = ncMajor === true ? 'pi pi-check' : 'pi pi-times';
    return (
        <div style={{
          backgroundColor,
          position: 'absolute',
          inset: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // borderRadius: '4px'
        }}>
         <i className={iconClassName} style={{fontSize: '1.5rem'}}></i>
        </div>
      );
  };

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="No" sortable sortField="obj.idNo" rowSpan={2} />
        <Column header="LA Criteria" rowSpan={2} style={{ width: '200px' }} />
        <Column header="Description" rowSpan={2} style={{ width: '300px' }} />
        <Column header="Picture" rowSpan={2} style={{ width: '300px' }} />
        <Column
          header="Quantity" // Group header for the 3 columns
          colSpan={3}
          style={{
            textAlign: 'center',
            backgroundColor: '#f8f9fa',
            borderBottom: '1px solid #dee2e6',
          }}
        />
      </Row>
      <Row>
        <Column header="OFI" headerClassName="text-center" style={{ width: '100px', backgroundColor: '#ffff00' }} />
        <Column header="NC - Minor"  headerClassName="text-center" style={{ width: '100px', backgroundColor: '#ff0000' }} />
        <Column header="NC - Major" headerClassName="text-center" style={{ width: '100px', backgroundColor: '#c00000' }} />
      </Row>
    </ColumnGroup>
  );


  return (
    <>
      <Toast ref={toastRef} />
      <div className="datatable-container" style={{
        width: '100%',
        overflowX: 'auto',
        position: 'relative'
      }}>
      {selMode === 'single' && (
        <DataTable
          headerColumnGroup={headerGroup}
          header={header || t('factory.title')}
          value={andonDetailsTblData}
          dataKey="id"
          scrollable
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={andonDetailsTblLimit}
          first={andonDetailsTblStart}
          totalRecords={andonDetailsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selAndonDetail}
          onSelectionChange={_onMachLineSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            field="idNo"
            header="No"
            sortable
            sortField="obj.idNo"
            style={{width: '90px'}}
          />
          <Column field="name" header="LA Criteria" style={{width: '200px'}} />
          <Column field="description" header="Description" style={{width: '300px'}} />
          <Column body={_renderPictureCol} header="Picture" style={{width: '300px'}} />

          <Column body={_renderOfiCol} header="OFI" style={{width: '100px'}}  bodyClassName="relative"/>
          <Column body={_renderNcMinorCol} header="NC - Minor" style={{width: '100px'}} bodyClassName="relative" />
          <Column body={_renderNcMajorCol} header="NC - Major" style={{width: '100px'}} bodyClassName="relative" />

        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
         headerColumnGroup={headerGroup}
          header={header || t('factory.title')}
          value={andonDetailsTblData}
          dataKey="id"
          lazy={true}
          scrollable
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={andonDetailsTblLimit}
          first={andonDetailsTblStart}
          totalRecords={andonDetailsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selAndonDetails}
          onSelectionChange={_onMachLineSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
              <Column
            field="idNo"
            header="No"
            sortable
            sortField="obj.idNo"
            style={{width: '90px'}}
          />
          <Column field="name" header="LA Criteria" style={{width: '200px'}} />
          <Column field="description" header="Description" style={{width: '300px'}} />
          <Column body={_renderPictureCol} header="Picture" style={{width: '300px'}} />

          <Column body={_renderOfiCol} header="OFI" style={{width: '100px'}}  bodyClassName="relative"/>
          <Column body={_renderNcMinorCol} header="NC - Minor" style={{width: '100px'}} bodyClassName="relative" />
          <Column body={_renderNcMajorCol} header="NC - Major" style={{width: '100px'}} bodyClassName="relative" />
        </DataTable>
      )}
      </div>
    </>
  );
}
