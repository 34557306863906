// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';

//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';
import type {ApiProps} from '../../../service/Api';
// import {AuthUtils} from '../service/AuthUtils';
import {UIUtils, Utils} from '../../../service/Utils';
import {AdminApi} from '../../../service/AdminApi';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
import type {TblColRender} from '../../types';

type Props = {
  scope?: string,
  country?: any,
  countries?: any[],
  factory?: any,
  factories?: any[],
  year?: any,
  years?: any,
  byFacGroup?: any,
  byCountry?: any,
  byRegion?: any,
};

export function LeanAudItemNb(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {
    scope,
    byFacGroup,
    country,
    countries,
    factory,
    factories,
    year,
    years,
    byCountry,
    byRegion,
  } = props;
  const [leanAudItemsTblData, setLeanAudItemsTblData] = useState([]);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchData = useCallback(async (options: any) => {
    console.log(options);
    let {scope, year, years, byFacGroup, byCountry, byRegion} = options;

    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let fields = [];
    let joins = [
      {
        type: 'join',
        expr: 'obj.audit',
        alias: 'aud',
      },
      {
        type: 'join',
        expr: 'aud.factory',
        alias: 'fac',
      },
      {
        type: 'join',
        expr: 'obj.measure',
        alias: 'mea',
      },
    ];
    let filters = {
      activeAud: 'aud.flag > -1',
    };
    let params = {};
    let groupBys = ['mea.id', 'obj.meaCode', 'mea.name', 'aud.audHoy'];
    years = years || [];
    if (year) {
      years = [...years, year];
    }
    years = years.map((it) => Number(it)).filter((it) => !isNaN(it));

    if (scope === 'FACGROUP') {
      joins = [
        ...joins,
        {
          type: '',
          expr: 'FacGroup',
          alias: 'fg',
        },

        {
          type: 'join',
          expr: 'fg.facLnks',
          alias: 'facLnk',
        },
      ];

      if (byFacGroup && byFacGroup.id) {
        filters = {
          ...filters,
          byFacGroup: 'fg.id = :facGroupId',
        };

        params = {
          ...params,
          facGroupId: {
            type: 'long',
            value: byFacGroup.id,
          },
        };

        filters = {
          ...filters,
          jnFacGroup: 'aud.factory.id = facLnk.factory.id',
        };
      }
    }
    if (scope === 'COUNTRY') {
      joins = [
        ...joins,
        {
          type: 'join',
          expr: 'fac.country',
          alias: 'coun',
        },
      ];

      filters = {
        ...filters,
      };

      if (byCountry && byCountry.id) {
        filters = {
          ...filters,
          byCountry: 'coun.id = :countryId',
        };

        params = {
          ...params,
          countryId: {
            type: 'long',
            value: byCountry.id,
          },
        };
      }
    }

    if (scope === 'REGION') {
      console.log('REGION');
      joins = [
        ...joins,
        {
          type: 'join',
          expr: 'fac.country',
          alias: 'coun',
        },

        {
          type: 'join',
          expr: 'coun.region',
          alias: 'reg',
        },
      ];

      filters = {
        ...filters,
      };

      if (byRegion && byRegion.id) {
        filters = {
          ...filters,
          byRegion: 'reg.id = :regionId',
        };

        params = {
          ...params,
          regionId: {
            type: 'long',
            value: byRegion.id,
          },
        };
      }
    }

    //console.log.*$

    if (years.length > 0) {
      filters = {
        ...filters,
        yearIn: 'aud.audYear in (:audYears)',
      };
      params = {...params, audYears: [...years]};
    }

    fields = [
      ...fields,
      ...groupBys,
      'avg(obj.score)',
      'avg(obj.tarScore)',
      'avg(obj.c2bVal)',
      'avg(obj.sfVal)',
      'avg(obj.offlineVal)',
      'avg(obj.osPressVal)',
    ];

    let sorts = [...groupBys];

    let data = await api
      .fetchLeanAudItemData({
        fields,
        joins,
        groupBys,
        filters,
        params,
        sorts,
        start: 0,
        limit: 0,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }

        let dataTbl = [];
        [...data.list].forEach((dt) => {
          let recData = {
            name: dt[2],
            score: dt[4],
            c2bVal: dt[6],
            sfVal: dt[7],
            offlineVal: dt[8],
            osPressVal: dt[9],
            hoy: dt[3],
          };
          dataTbl.push(recData);
        });

        setLeanAudItemsTblData([...dataTbl]);

        return [...data.list];
      })
      .catch((error) => {
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
    return {
      years,
      data,
    };
  }, []);

  const _loadChartData = useCallback(
    async (options: any) => {
      let {data} = (await _fetchData(options)) || {};

      let hoyMap = {};

      data.forEach((it) => (hoyMap[it[6]] = it[1]));
    },
    [_fetchData],
  );

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _loadChartData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      //console.log.*$
      subj.complete();
    };
  }, [_loadChartData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      scope,
      country,
      countries,
      factory,

      year,
      years,
      byFacGroup,
      byCountry,
      byRegion,
    });
  }, [
    scope,
    country,
    countries,
    factory,
    factories,
    year,
    years,
    byFacGroup,
    byCountry,
    byRegion,
  ]);

  const _renderScoreCol: TblColRender = (row, col) => {
    let {score} = row;
    // let w = !Utils.isEmpty(score) ? Utils.fmtDecimal(score, 4) : 0;
    return Utils.isNum(score) ? Utils.fmtDecimal(score, 2) : null;
  };

  const _renderC2bValCol: TblColRender = (row, col) => {
    let {c2bVal} = row;
    let v = Utils.isNum(c2bVal) ? Number(c2bVal) : 0;
    // v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}`;
  };

  const _renderSfValCol: TblColRender = (row, col) => {
    let {sfVal} = row;
    let v = Utils.isNum(sfVal) ? Number(sfVal) : 0;
    // v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}`;
  };

  const _renderOfflineValCol: TblColRender = (row, col) => {
    let {offlineVal} = row;
    let v = Utils.isNum(offlineVal) ? Number(offlineVal) : 0;
    // v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}`;
  };

  const _renderOsPressValCol: TblColRender = (row, col) => {
    let {osPressVal} = row;
    let v = Utils.isNum(osPressVal) ? Number(osPressVal) : 0;
    // v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}`;
  };

  const _renderAuditCol: TblColRender = (row, col) => {
    let {hoy} = row;
    return hoy ? `${hoy}` : null;
  };

  return (
    <>
      <Toast ref={toastRef} />
      <DataTable
        // header={header || t('factory.title')}
        value={leanAudItemsTblData}
        // dataKey="id"
        // lazy={true}
        //   paginator={true}
        //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        //  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        //   rows={5}
        // first={10}
        // totalRecords={leanAudItemsTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        // selection={selLeanAudItems}
        // onSelectionChange={_onLeanAudItemSelected}
        // onPage={_onInvsPaging}
        // sortField={sortField}
        // sortOrder={sortOrder}
        // onSort={_onSort}>
      >
        <Column field="name" header="Name" headerStyle={{width: 350}} />

        <Column
          body={_renderScoreCol}
          header="Score"
          headerStyle={{width: 90}}
          headerClassName="text-right"
          bodyClassName="text-right"
        />

        <Column
          body={_renderC2bValCol}
          header="C2B"
          headerStyle={{width: 90}}
          headerClassName="text-right"
          bodyClassName="text-right"
        />

        <Column
          body={_renderSfValCol}
          header="SF"
          headerStyle={{width: 90}}
          headerClassName="text-right"
          bodyClassName="text-right"
        />

        <Column
          body={_renderOfflineValCol}
          header="Offline"
          headerStyle={{width: 90}}
          headerClassName="text-right"
          bodyClassName="text-right"
        />

        <Column
          body={_renderOsPressValCol}
          header="Outsole"
          headerStyle={{width: 90}}
          headerClassName="text-right"
          bodyClassName="text-right"
        />

        <Column
          body={_renderAuditCol}
          header="Half of Year"
          headerStyle={{width: 250}}
        />

        {/* <Column
            selectionMode="multiple"
            headerStyle={{width: 50}}
            headerClassName="text-left"
            bodyClassName="text-left"></Column>
          <Column
            field="rowNo"
            header="#"
            headerStyle={{width: 60}}
            sortable={true}
            sortField="obj.rowNo"
          />
          <Column
            field="code"
            header="Code"
            headerStyle={{width: 150}}
            sortable={true}
            sortField="obj.code"
          />
      
         
          <Column
            body={_renderWeightCol}
            header="Weight"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
            sortable={true}
            sortField="obj.weight"
          />
       
          <Column
            body={_renderC2bWgCol}
            header="C2B %"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
        
          <Column
            body={_renderSfWgCol}
            header="SF %"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderOfflineValCol}
            header="Offline"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderOfflineWgCol}
            header="Offline %"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
         
          <Column
            body={_renderOsPressWgCol}
            header="Outsole %"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderAuditCol}
            header="Assessment"
            headerStyle={{width: 250}}
          />
          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          /> */}
      </DataTable>
    </>
  );
}
