// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {Dialog} from 'primereact/dialog';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
// import {TabView, TabPanel} from 'primereact/tabview';
// import {Panel} from 'primereact/panel';

import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
import {AuthUtils} from '../../service/AuthUtils';
import {AuthConst} from '../../service/AppConstant';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {ProdKpiForm} from './ProdKpiForm';
import {ScoreCardForm} from './ScoreCardForm';
import {ScoreCardList} from './ScoreCardList';

type Props = {
  scope?: string,
  kpiType?: string,
  filter?: any,
  onAction?: (evt: any) => void,
};

export function ByScoreCard(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  // const {onAction} = props;
  const scope = props.scope || 'factory';
  const filter = props.filter || {};
  const kpiType = props.kpiType || 'PPH';
  //console.log.*$
  const [selProdKpi, setSelProdKpi] = useState<any>();
  const [editProdKpi, setEditProdKpi] = useState<any>();
  const [showDlg, setShowDlg] = useState<any>(false);
  const [reload, setReload] = useState(false);
  const [defSort, setDefSort] = useState([]);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let sms = [
      {
        field: 'obj.recDate',
        order: -1,
      },
    ];
    setDefSort(sms);
  }, []);

  const _onProdKpiSelected: EvtHandler = (evt: any) => {
    setSelProdKpi(evt.value);
    setEditProdKpi({...evt.value});
  };

  const _onNewProdKpi: EvtHandler = (evt: any) => {
    setSelProdKpi(null);
    setEditProdKpi({});
  };

  const _onProdKpiAction: EvtHandler = (evt: any) => {
    // setSelProdKpi(null);
    setReload(!reload);
  };

  const _onDelete: EvtHandler = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selProdKpi.id) {
      return;
    }
    confirmDialog({
      message: 'Are you sure you want to delete production KPI record?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .deleteLeanScore(selProdKpi.id)
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
            }
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          })
          .finally(() => {
            setSelProdKpi(null);
          });
        setReload(!reload);
      },
      reject: () => {},
    });
  };

  let authLevel = AuthUtils.getAuthLevel({auth});
  let canEdit =
    authLevel < AuthConst.AUTH_LVL_NB ||
    AuthUtils.isAuth({auth, roles: ['NB_ADMIN']});
  let {factory} = filter;

  return (
    <>
      <Toast ref={toastRef} />

      <div className="p-grid">
        <div className="col-12 lg:col-9">
          <ScoreCardList
            byScope={scope}
            byKpiType={kpiType}
            byFactory={factory}
            byYears={filter.years}
            defSort={defSort}
            reload={reload}
            selection={selProdKpi}
            onSelect={_onProdKpiSelected}
          />
          {canEdit && (
            <div className="p-d-flex p-jc-end p-my-2">
              <Button
                label="New"
                icon="pi pi-plus"
                className="p-ml-2"
                onClick={_onNewProdKpi}
              />
              {selProdKpi && (
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  className="p-ml-2"
                  onClick={_onDelete}
                  disabled={selProdKpi.flag === 1}
                />
              )}
            </div>
          )}
        </div>
        <div className="col-12 lg:col-3">
          <ScoreCardForm
            scope={scope}
            kpiType={kpiType}
            factory={factory}
            prodKpi={editProdKpi}
            filter={filter}
            readOnly={!canEdit}
            onAction={_onProdKpiAction}
          />
        </div>
      </div>

      <Dialog
        header="Enter production KPI record"
        visible={showDlg}
        style={{width: '90vw', height: '90vh'}}
        modal={true}
        onHide={() => {
          setShowDlg(false);
        }}>
        {showDlg && <ProdKpiForm prodKpi={editProdKpi} />}
      </Dialog>
    </>
  );
}
