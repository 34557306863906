// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
import {Subject} from 'rxjs';
//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function AndonMeasureList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const {t} = useTranslation();
  const [audMeasuresTblData, setAudMeasuresTblData] = useState([]);
  const [audMeasuresTblStart, setAudMeasuresTblStart] = useState(0);
  const [audMeasuresTblLimit] = useState(10);
  const [audMeasuresTblNbRows, setAudMeasuresTblNbRows] = useState(0);
  const [selAudMeasure, setSelAudMeasure] = useState();
  const [selAudMeasures, setSelAudMeasures] = useState([]);
  const [sortMeta, setSortMeta] = useState([
    {
      field: 'obj.idNo',
      order: 1,
    },
  ]);
  const [sortField, setSortField] = useState('obj.idNo');
  const [sortOrder, setSortOrder] = useState(1);

  const {reload, selection, header, command} = props;
  const selMode = props.selectionMode || 'single';
  const {search} = app || '';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  const _fetchData = useCallback(async (options) => {
    // //console.log.*$
    // //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let {search, audMeasuresTblStart, audMeasuresTblLimit, sortMeta} = options;
    let start = audMeasuresTblStart;
    let limit = audMeasuresTblLimit;
    let joins = [];
    let filters = {};
    let params = {};

    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });
    //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);

    api
      .getAndonMeasures({search, joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        // //console.log.*$
        setAudMeasuresTblData([...data.list]);
        setAudMeasuresTblNbRows(data.count);

      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    let subj = new Subject();
    //console.log.*$
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      //console.log.*$
      subj.complete();
    };
  }, [_fetchData]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelAudMeasure({...selection});
      } else {
        setSelAudMeasures([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelAudMeasure(null);
      } else {
        setSelAudMeasures([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      search,
      audMeasuresTblStart,
      audMeasuresTblLimit,
      sortMeta,
    });
  }, [search, audMeasuresTblStart, audMeasuresTblLimit, reload, sortMeta]);

  const _onAudMeasureSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelAudMeasure(evt.value);
    } else {
      setSelAudMeasures(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    // //console.log.*$
    setAudMeasuresTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };

  const _renderActiveCol: TblColRender = (row, col) => {
    let {active} = row;
    return `${active ? 'T' : 'F'}`;
  };




  /*const _renderSfValCol: TblColRender = (row, col) => {
    let {sfVal} = row;
    let v = Utils.isNum(sfVal) ? Number(sfVal) : 0;
    // v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}`;
  };*/




  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={audMeasuresTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={audMeasuresTblLimit}
          first={audMeasuresTblStart}
          totalRecords={audMeasuresTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selAudMeasure}
          onSelectionChange={_onAudMeasureSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            field="idNo"
            header="Number"
            headerStyle={{width: 90}}
            sortable={true}
            sortField="obj.idNo"
          />
          <Column
            field="processType"
            header="Process Type"
            headerStyle={{width: 100}}
            sortable={true}
            sortField="obj.processType"
          />
          <Column field="name" header="Name" headerStyle={{width: 350}} />



          <Column
            body={_renderActiveCol}
            header="Active"
            headerStyle={{width: 90}}
            sortable={true}
            sortField="obj.active"
          />
          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={audMeasuresTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={audMeasuresTblLimit}
          first={audMeasuresTblStart}
          totalRecords={audMeasuresTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selAudMeasures}
          onSelectionChange={_onAudMeasureSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            field="idNo"
            header="Number"
            headerStyle={{width: 90}}
            sortable={true}
            sortField="obj.idNo"
          />
          <Column
            field="processType"
            header="Process Type"
            headerStyle={{width: 100}}
            sortable={true}
            sortField="obj.processType"
          />
          <Column field="name" header="Name" headerStyle={{width: 350}} />



          <Column
            body={_renderActiveCol}
            header="Active"
            headerStyle={{width: 90}}
            sortable={true}
            sortField="obj.active"
          />
          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
        </DataTable>
      )}
    </>
  );
}
