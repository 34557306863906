// @flow
import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {Dialog} from 'primereact/dialog';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';

import type {ApiProps} from '../../service/Api';
import {AuthUtils} from '../../service/AuthUtils';
import {AuthConst} from '../../service/AppConstant';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {FacGroupForm} from './FacGroupForm';
import {FacGroupList} from './FacGroupList';
import {FactoryList} from './FactoryList';

type Props = {};

export function FacGroup(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();

  const [selFacGroup, setSelFacGroup] = useState<any>();
  const [selAvaiFac, setSelAvaiFac] = useState<any>();
  const [selLinkedFac, setSelLinkedFac] = useState<any>();
  const [reload, setReload] = useState(false);
  const [reloadFacs, setReloadFacs] = useState(false);
  const [showAssignDlg, setShowAssignDlg] = useState(false);
  const [showUnAssignDlg, setShowUnAssignDlg] = useState(false);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  // const selFacGroupRef = useRef();
  // useEffect(() => {
  //   selFacGroupRef.current = selFacGroup;
  // }, [selFacGroup]);

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _onFacGroupSelected = (evt: any) => {
    // setSelFacGroup(evt.value);
    //console.log.*$
    setSelFacGroup(evt.value);
    setSelLinkedFac(null);
  };

  const _onNewFacGroup: any = (evt: any) => {
    setSelFacGroup(null);
  };

  const _onFacGroupAction = (evt: any) => {
    setSelFacGroup(null);
    setReload(!reload);
  };

  const _onDelete: (evt: any) => void = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selFacGroup.id) {
      return;
    }
    confirmDialog({
      message: 'Are you sure you want to delete FacGroup?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .deleteFacGroup(selFacGroup.id)
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
            }
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          })
          .finally(() => {
            setSelFacGroup(null);
            setReload(!reload);
          });
      },
      reject: () => {},
    });
  };

  const _onAssignFac: (evt: any) => void = (evt: any) => {
    setShowAssignDlg(false);
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selFacGroup || !selAvaiFac) {
      return;
    }
    api
      .linkFacGroupToFacs(selFacGroup.id, [selAvaiFac.id])
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        setSelAvaiFac(null);
        setSelLinkedFac(null);
        setReloadFacs(!reloadFacs);
        UIUtils.showInfo({detail: 'success', toast: toastRef.current});
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
      });
  };

  const _onUnAssignFac: (evt: any) => void = (evt: any) => {
    setShowUnAssignDlg(false);
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selFacGroup || !selLinkedFac) {
      return;
    }
    api
      .unlinkFacGroupToFacs(selFacGroup.id, [selLinkedFac.id])
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        setSelAvaiFac(null);
        setSelLinkedFac(null);
        setReloadFacs(!reloadFacs);
        UIUtils.showInfo({detail: 'success', toast: toastRef.current});
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
      });
  };

  const assignDlgFooter = (
    <div>
      <Button
        label={t('g.yes')}
        icon="pi pi-check"
        className="ml-1"
        onClick={_onAssignFac}
      />
      <Button
        label={t('g.no')}
        icon="pi pi-times"
        className="ml-1"
        onClick={() => {
          setShowAssignDlg(false);
        }}
      />
    </div>
  );

  const unAssignDlgFooter = (
    <div>
      <Button
        label={t('g.yes')}
        icon="pi pi-check"
        className="ml-1"
        onClick={_onUnAssignFac}
      />
      <Button
        label={t('g.no')}
        icon="pi pi-times"
        className="ml-1"
        onClick={() => {
          setShowUnAssignDlg(false);
        }}
      />
    </div>
  );

  let listTitle = 'Available Factory Groups';
  let authLevel = AuthUtils.getAuthLevel({auth});
  let canEdit =
    authLevel < AuthConst.AUTH_LVL_NB ||
    AuthUtils.isAuth({auth, roles: ['ADMIN']});

  return (
    <>
      <Toast ref={toastRef} />

      <div className="p-grid">
        <div className="p-col-12 p-lg-8">
          <FacGroupList
            header={listTitle}
            reload={reload}
            selection={selFacGroup}
            onSelect={_onFacGroupSelected}
          />
          {canEdit && (
            <div className="p-d-flex p-jc-end p-my-2">
              <Button
                label="New FacGroup"
                icon="pi pi-plus"
                className="p-ml-2"
                onClick={_onNewFacGroup}
              />
              {selFacGroup && (
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  className="p-ml-2"
                  onClick={_onDelete}
                  disabled={selFacGroup.flag === 1}
                />
              )}
            </div>
          )}
        </div>
        <div className="p-col-12 p-lg-4">
          <FacGroupForm
            FacGroup={selFacGroup}
            readOnly={!canEdit}
            onAction={_onFacGroupAction}
          />
        </div>
      </div>

      <TabView>
        <TabPanel header="Factories">
          {selFacGroup && (
            <div className="p-grid">
              <div className="p-col-12 p-lg-6">
                <FactoryList
                  byGroup={selFacGroup}
                  header="Assigned factories"
                  reload={reloadFacs}
                  selection={selLinkedFac}
                  onSelect={(evt) => {
                    setSelLinkedFac({...evt.value});
                  }}
                />
                <div className="p-my-2 p-d-flex p-jc-end">
                  <Button
                    label="Un-assign"
                    icon="pi pi-minus-circle"
                    onClick={(evt) => {
                      setShowUnAssignDlg(true);
                    }}
                    disabled={!canEdit}
                  />
                </div>
              </div>
              <div className="p-col-12 p-lg-6">
                <FactoryList
                  excGroup={selFacGroup}
                  header="Available factories"
                  reload={reloadFacs}
                  selection={selAvaiFac}
                  onSelect={(evt) => {
                    setSelAvaiFac({...evt.value});
                  }}
                />
                <div className="p-my-2 p-d-flex p-jc-end">
                  <Button
                    label="Assign"
                    icon="pi pi-plus-circle"
                    onClick={(evt) => {
                      setShowAssignDlg(true);
                    }}
                    disabled={!selAvaiFac}
                  />
                </div>
              </div>
            </div>
          )}
        </TabPanel>
      </TabView>

      {selFacGroup && selAvaiFac && (
        <Dialog
          header="Confirm assign factory"
          footer={assignDlgFooter}
          visible={showAssignDlg}
          modal={true}
          blockScroll
          onHide={() => {
            setShowAssignDlg(false);
          }}>
          Are you sure to assign factory [{selAvaiFac.name}] to group [
          {selFacGroup.name}]?
        </Dialog>
      )}
      {selFacGroup && selLinkedFac && (
        <Dialog
          header="Confirm un-assign factory"
          footer={unAssignDlgFooter}
          visible={showUnAssignDlg}
          modal={true}
          blockScroll
          onHide={() => {
            setShowUnAssignDlg(false);
          }}>
          Are you sure to un-assign factory [{selLinkedFac.name}] from group [
          {selFacGroup.name}]?
        </Dialog>
      )}
    </>
  );
}
