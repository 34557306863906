// @flow
import React, {useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {useLocation} from 'react-router-dom';

import type {ApiProps} from './service/Api';
// import {AuthUtils} from './service/AuthUtils';
// import {UIUtils} from './service/Utils';
import {CoreApi} from './service/CoreApi';
import {logoutAction} from './service/auth/action';

// $FlowFixMe
import {Button} from 'primereact/button';
// $FlowFixMe
import {BreadCrumb} from 'primereact/breadcrumb';
// $FlowFixMe
import {confirmDialog} from 'primereact/confirmdialog';

type Props = {
  routes: any[],
};

export function AppBreadcrumb(props: Props): React$Node {
  const location = useLocation();
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const {routes} = props;

  // const toastRef = useRef();
  const apiRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new CoreApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  const _onLogout: (evt: any) => void = (evt: any) => {
    confirmDialog({
      message: 'Are you sure you want to terminate current working session?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        //console.log.*$
        let api: ?CoreApi = apiRef.current;
        if (api) {
          api
            .logout()
            .then((resp) => resp.data)
            .then((resp) => {
              let {errors} = resp;
              if (errors.length > 0) {
                //console.log.*$
                return;
              }
              //logged out
            })
            .catch((error) => {
              //console.log.*$
            })
            .finally(() => {
              //perform logging out
              dispatch(logoutAction());
            });
        } else {
          dispatch(logoutAction());
        }
      },
      reject: () => {},
    });
  };

  const pathname =
    location.pathname === '/'
      ? ['', '']
      : location.pathname.split('/').slice(1);

  const activeRoute = (routes || []).filter((route) => {
    return (
      route.parent.replace(/\s/g, '').toLowerCase() === pathname[0] &&
      route.label.replace(/\s/g, '').toLowerCase() === pathname[1]
    );
  });

  let model;

  if (!activeRoute.length) {
    model = [{label: ''}];
  } else {
    model =
      activeRoute[0].parent === '' && activeRoute[0].label === ''
        ? [{label: 'Dashboard'}]
        : [{label: activeRoute[0].parent}, {label: activeRoute[0].label}];
  }

  const home = {icon: 'pi pi-home', url: '#/home/std-dashboard'};

  return (
    <div className="layout-breadcrumb-container p-d-flex p-jc-between p-ai-center p-shadow-1">
      <BreadCrumb
        model={model}
        home={home}
        className="layout-breadcrumb p-pl-4 p-py-2"
      />

      <div className="layout-breadcrumb-buttons p-d-flex p-ai-center p-pr-3">
        {/*<Button
          type="button"
          icon="pi pi-cloud-upload"
          className="p-button p-button-rounded p-button-text p-button-plain p-mr-1"></Button>
        <Button
          type="button"
          icon="pi pi-bookmark"
          className="p-button p-button-rounded p-button-text p-button-plain p-mr-1"></Button>*/}
        <Button
          type="button"
          icon="pi pi-power-off"
          className="p-button p-button-rounded p-button-text p-button-plain p-mr-1"
          onClick={_onLogout}></Button>
      </div>
    </div>
  );
}
