// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';

import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
import {AuthUtils} from '../../service/AuthUtils';
import {AuthConst} from '../../service/AppConstant';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

import {FactoryFilter} from '../common/FactoryFilter';

import {LeanReportForm} from './LeanReportForm';
import {LeanReportList} from './LeanReportList';
// import {LeanAuditFilter} from './LeanAuditFilter';
// import {LeanReportChart} from './LeanReportChart';

type Props = {};

const defFilter = {
  factory: null,
  years: [],
};

export function LeanReport(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const [selLeanReport, setSelLeanReport] = useState<any>();
  // const [showCfmDlg, setShowCfmDlg] = useState<any>(false);
  const [reload, setReload] = useState(false);
  const [filter, setFilter] = useState({...defFilter});
  const [tabInd, setTabInd] = useState(0);
  // const [initFilter, setInitFilter] = useState({});

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  // const selLeanReportRef = useRef();
  // useEffect(() => {
  //   selLeanReportRef.current = selLeanReport;
  // }, [selLeanReport]);

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let initFil = {};
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      initFil['factory'] = factories[0];
    }
    let di: any = Utils.getDateInfo() || {};
    initFil = {
      ...initFil,
      years: [di.year],
    };

    setFilter(initFil);
  }, [auth]);

  const _onLeanReportSelected = (evt: any) => {
    // setSelLeanReport(evt.value);
    //console.log.*$
    setSelLeanReport(evt.value);
  };

  const _onNewLeanReport: any = (evt: any) => {
    setSelLeanReport({});
    setTabInd(1);
  };

  const _onEditLeanReport: any = (evt: any) => {
    setTabInd(1);
  };

  const _onLeanReportAction = (evt: any) => {
    setSelLeanReport({});
    setReload(!reload);
    setTabInd(0);
  };

  const _onDelete: (evt: any) => void = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selLeanReport.id) {
      return;
    }
    confirmDialog({
      message: 'Are you sure you want to delete selected roadmap?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .deleteLeanReport(selLeanReport.id)
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
            }
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          })
          .finally(() => {
            setSelLeanReport(null);
            setReload(!reload);
          });
      },
      reject: () => {},
    });
  };

  const _onFilterAction: EvtHandler = (evt: any) => {
    let {type, value} = evt;
    if (type === 'filterChanged') {
      setFilter({...value});
      setSelLeanReport({});
    }
  };

  // const cfmDlgFooter = (
  //   <div className="text-right">
  //     <Button label="Yes" icon="pi pi-check" onClick={_onDeletLeanReport} />
  //     <Button
  //       label="No"
  //       icon="pi pi-times"
  //       onClick={(e) => {
  //         setShowCfmDlg(false);
  //       }}
  //     />
  //   </div>
  // );

  let authLevel = AuthUtils.getAuthLevel({auth});
  let canEdit: boolean =
    authLevel < AuthConst.AUTH_LVL_NB ||
    AuthUtils.isAuth({auth, roles: ['FACTORY_ADMIN']});

  let {factory, years} = filter;

  return (
    <>
      <Toast ref={toastRef} />

      <TabView activeIndex={tabInd} onTabChange={(e) => setTabInd(e.index)}>
        <TabPanel header="Lean reports">
          <div>
            <FactoryFilter
              scope="factory"
              filter={filter}
              showFactory={true}
              showYears={true}
              onAction={_onFilterAction}
            />
            {/*<LeanAuditFilter filter={initFilter} onAction={_onFilterAction} />*/}
          </div>
          <LeanReportList
            byFactory={factory}
            byYears={years}
            reload={reload}
            selection={selLeanReport}
            onSelect={_onLeanReportSelected}
          />
          {canEdit && (
            <div className="p-d-flex p-jc-end p-my-2">
              <Button
                label="New report"
                icon="pi pi-plus"
                className="p-ml-2"
                onClick={_onNewLeanReport}
              />
              {selLeanReport && selLeanReport.id && (
                <Button
                  label="Edit"
                  icon="pi pi-pencil"
                  className="p-ml-2"
                  onClick={_onEditLeanReport}
                  disabled={selLeanReport.flag === 1}
                />
              )}
              {selLeanReport && selLeanReport.id && (
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  className="p-ml-2"
                  onClick={_onDelete}
                  disabled={selLeanReport.flag === 1}
                />
              )}
            </div>
          )}
          <div className="py-2"></div>
        </TabPanel>
        <TabPanel header="Edit">
          <LeanReportForm
            readOnly={!canEdit}
            report={selLeanReport}
            onAction={_onLeanReportAction}
          />
        </TabPanel>
      </TabView>

      {/*<Dialog
        header="Confirm delete production line"
        footer={cfmDlgFooter}
        visible={showCfmDlg}
        style={{width: '400px'}}
        modal={true}
        onHide={() => {
          setShowCfmDlg(false);
        }}>
        <span>Are you sure to delete selected production line?</span>
      </Dialog>*/}
    </>
  );
}
