// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';

//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';

//$FlowFixMe[cannot-resolve-module]
import ChartDataLabels from 'chartjs-plugin-datalabels';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {Chart} from 'primereact/chart';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {getColor} from '../../service/util/color';
import {getChartOptions} from '../chart/CommonChart';

type Props = {
  scope?: string,
  country?: any,
  countries?: any[],
  factory?: any,
  factories?: any[],
  year?: any,
  years?: any,
};

const chartPlugins = [ChartDataLabels];
const pyColors: any[] = ['deep-orange-200'].map((it) => getColor(it));
const planBarColors: any[] = ['blue-grey-300'].map((it) => getColor(it));

const defBarColors: any[] = [
  'orange-900',
  'orange-700',
  'orange-500',
  'orange-300',
  'deep-orange-900',
  'green-300',
  'deep-orange-700',
  'deep-orange-500',
  'deep-orange-300',
  'yellow-900',
  'yellow-700',
  'yellow-500',
  'yellow-300',
].map((it) => getColor(it));

const defLineColors: any[] = [
  'green-900',
  'green-700',
  'green-500',
  'green-300',
  'blue-900',
  'blue-700',
  'blue-500',
  'blue-300',
  'teal-900',
  'teal-700',
  'teal-500',
  'teal-300',
].map((it) => getColor(it));

export function MltRoadmapChart(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();

  const {scope, country, countries, factory, factories, year, years} = props;

  const [barData, setBarData] = useState();
  const [chartOptions] = useState(getChartOptions());
  const [actDateStr, setActDatestr] = useState('');

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchMltYtd = useCallback(async (options) => {
    let api: ?AdminApi = apiRef.current;

    if (!api) {
      return [];
    }
    let {factory, years} = options;
    let year = years[years.length - 1];
    let reqData = {
      year,
    };
    if (factory && factory.id) {
      reqData = {
        ...reqData,
        factoryId: factory.id,
      };
    }

    return await api
      .getMedianMlt({
        data: {
          ...reqData,
        },
      })
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        //console.log.*$
        return data.data;
      })
      .catch((errors) => {
        UIUtils.showError({errors, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchYtdTarget = useCallback(async (options) => {
    let {years, factory} = options;
    let api: ?AdminApi = apiRef.current;
    //console.log.*$

    if (!api) {
      return;
    }
    let joins = [];

    let filters = {
      byType: 'obj.kpiType = :kpiType',
      byScope: 'obj.scope = :scope',
      byPeriod: 'obj.periodType = :periodType',
      byYear: 'obj.year = :year',
      byFactory: 'obj.factoryId = :factoryId',
    };

    let params = {
      kpiType: {
        type: 'enum:com.nb.lmrs.model.KpiType',
        value: 'MLT_YTD_TAR',
      },
      periodType: {
        type: 'enum:com.nb.lmrs.model.PeriodType',
        value: 'YEAR',
      },

      year: years[years.length - 1],
      scope: 'factory',

      factoryId: {
        type: 'long',
        value: factory.id,
      },
    };

    return await api
      .getProdKpis({joins, filters, params, start: 0, limit: 0})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //    //console.log.*$

        let kpis = [...data.list];

        return kpis[0]['targetValue'] || 0;
      })
      .catch((errors) => {
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return 0;
        }
        return 0;
      });
  }, []);

  const _fetchLastActivity = useCallback(async (options) => {
    // //console.log.*$
    // //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let {byYears, byFactories, factory} = options;

    //  //console.log.*$

    let start = 0;
    let limit = 1;

    let joins = [];
    let filters = {};
    let params = {};

    let byType = 'MLT';
    if (byType) {
      filters = {
        ...filters,
        byType: 'obj.type = :byType',
      };
      params = {
        ...params,
        byType: {
          type: 'enum:com.nb.lmrs.model.ActivityType',
          value: byType,
        },
      };
    }

    byYears = (byYears || []).map((it) => Number(it));
    if (byYears.length > 0) {
      filters = {
        ...filters,
        byYears: 'YEAR(obj.plStaDate) in (:years)',
      };
      params = {
        ...params,
        years: [...byYears],
      };
    }

    let factories = byFactories || [];
    if (factory && factory.id) {
      factories.push(factory);
    }
    if (factories.length > 0) {
      filters = {
        ...filters,
        byFactories: 'obj.factory.id in (:byFacIds)',
      };
      let byFacIds = factories.map((it) => {
        return {
          type: 'long',
          value: it.id,
        };
      });
      params = {
        ...params,
        byFacIds,
      };
    }

    let sorts = ['-obj.plStaDate'];
    // //console.log.*$
    return await api
      .getLeanActivities({
        joins,
        filters,
        params,
        start,
        limit,
        sorts,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return null;
        }
        // //console.log.*$
        let lst = data.list;
        if (lst.length > 0) {
          return lst[0];
        }
        return null;
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
        return null;
      });
  }, []);

  const _fetchData = useCallback(async (options: any) => {
    //console.log.*$
    let {scope, factory, factories, year, years} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return null;
    }

    let joins = [];
    let filters = {};
    let params = {};
    let groupBys = ['obj.audYear', 'obj.audMonth'];
    years = years || [];
    if (year) {
      years = [...years, year];
    }
    if (years.length < 1) {
      return null;
    }
    let cy = years[years.length - 1];
    years = [cy - 1, cy];

    factories = factories || [];
    if (factory && factory.id) {
      factories = [...factories, factory];
    }

    scope = scope || 'factory';
    //console.log.*$
    if (scope === 'factory') {
      joins = [
        {
          type: 'join',
          expr: 'obj.factory',
          alias: 'fac',
        },
      ];
      // groupBys = ['fac.code', ...groupBys];

      if (factories.length > 0) {
        let facIds = Utils.getVals(factories, 'id', []).map((it) => {
          return {
            type: 'long',
            value: it,
          };
        });
        filters = {
          ...filters,
          byFacIds: 'fac.id in (:facIds)',
        };
        params = {
          ...params,
          facIds,
        };
      }

      if (years.length > 0) {
        filters = {
          ...filters,
          yearIn: 'obj.audYear in (:audYears)',
        };
        params = {...params, audYears: [...years]};
      }

      // //console.log.*$
      // //console.log.*$
    }

    let fields = [
      ...groupBys,
      'avg(obj.actMlt)',
      'avg(obj.tarMlt)',
      'avg(obj.actCssMlt)',
      'avg(obj.tarCssMlt)',
      'avg(obj.preMlt)',
    ];
    //console.log.*$
    let sorts = [...groupBys];

    let data = await api
      .fetchLeanReportData({
        fields,
        joins,
        groupBys,
        filters,
        params,
        sorts,
        start: 0,
        limit: 0,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        // //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        return [...data.list];
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
    return {
      years,
      data,
    };
  }, []);

  const _extractMonData = useCallback((options) => {
    let {year, data, dpInd, months} = options;
    data = data.filter((it) => it[0] === year);

    let resData = months.map((it) => 0);
    data.forEach((rec: any[]) => {
      let monInd = rec[1] - 1;
      let dpVal = rec[dpInd];
      resData[monInd] = resData[monInd] + dpVal;
    });
    return resData;
  }, []);

  const _loadChartData = useCallback(
    async (options: any) => {
      let emptyData = {
        labels: [],
        datasets: [],
      };
      let rptData = await _fetchData(options);
      if (!rptData) {
        setBarData({...emptyData});
        return;
      }

      //console.log.*$
      let {years, data} = rptData;
      let [py, cy] = years;

      let months = [];
      for (let i = 1; i <= 12; i++) {
        months = [...months, i];
      }
      let datasets: any[] = [];

      //console.log.*$
      let serieData: number[] = _extractMonData({
        year: cy,
        data,
        months,
        dpInd: 3,
      });
      ////console.log.*$
      let di = Utils.getDateInfo(new Date());
      let thisYear = di.year;
      let thisMon = di.month;
      // let subData =
      //   di.year === thisYear ? serieData.slice(0, thisMon - 1) : [...serieData];
      //let ytd = Utils.round(Utils.avg(subData));
      let ytd = await _fetchYtdTarget(options);
      datasets = [
        ...datasets,
        {
          label: `Roadmap Target MLT ${cy}`,
          data: [...serieData, ytd],
          type: 'line',
          backgroundColor: defLineColors[0],
          borderColor: defLineColors[0],
          datalabels: {
            align: 'top',
            offset: 5,
            color: defLineColors[0],
          },
        },
      ];

      serieData = _extractMonData({year: cy, data, months, dpInd: 5});
      // subData =
      //   di.year === thisYear ? serieData.slice(0, thisMon - 1) : [...serieData];
      //  ytd = Utils.round(Utils.avg(subData));
      // bgColors = months.map((it) => defBarColors[5]);
      // if (thisYear === cy) {
      //   bgColors = months.map((it, ind) =>
      //     ind < thisMon - 1 ? defBarColors[5] : planBarColors[0],
      //   );
      // }

      //  ytd = await _fetchYtdTarget(options);
      datasets = [
        ...datasets,
        {
          label: `Target CSS MLT ${cy}`,
          // backgroundColor: [...bgColors, planBarColors[0]],
          data: [...serieData],
          type: 'line',
          backgroundColor: defLineColors[1],
          borderColor: defLineColors[1],
          datalabels: {
            align: 'top',
            offset: 5,
            color: defLineColors[1],
          },
        },
      ];

      serieData = _extractMonData({year: py, data, months, dpInd: 2});

      let dataYtdMlt: any[] = await _fetchMltYtd(options);

      datasets = [
        ...datasets,
        {
          label: `Actual MLT ${py}`,
          backgroundColor: pyColors[0],
          borderColor: pyColors[0],
          data: [...serieData, dataYtdMlt[0] || 0],
          datalabels: {
            align: 'top',
            offset: 5,
            color: 'white',
            rotation: -90,
          },
        },
      ];

      serieData = _extractMonData({year: cy, data, months, dpInd: 2});
      // subData =
      //   di.year === thisYear ? serieData.slice(0, thisMon - 1) : [...serieData];
      //  ytd = Utils.round(Utils.avg(subData));
      /* let bgColors = months.map((it) => defBarColors[4]);
      if (thisYear === cy) {
        bgColors = months.map((it, ind) =>
          ind < thisMon - 1 ? defBarColors[4] : planBarColors[0],
        );
      }*/
      //  ytd = await _fetchMltYtdCurrentYear(options);

      let bgColors = months.map((it) => defBarColors[4]);
      datasets = [
        ...datasets,
        {
          label: `Actual MLT ${cy}`,
          backgroundColor: [...bgColors, defBarColors[4]],
          data: [...serieData, dataYtdMlt[1] || 0],
          datalabels: {
            align: 'top',
            offset: 5,
            color: 'white',
            rotation: -90,
          },
        },
      ];

      serieData = _extractMonData({year: cy, data, months, dpInd: 6});
      // subData =
      //   di.year === thisYear ? serieData.slice(0, thisMon - 1) : [...serieData];
      // ytd = Utils.round(Utils.avg(subData));

      let bgColors1 = months.map((it) => planBarColors[0]);

      datasets = [
        ...datasets,
        {
          label: `Predicted MLT ${cy}`,
          backgroundColor: [...bgColors1, planBarColors[0]],
          data: [...serieData],
          datalabels: {
            align: 'top',
            offset: 5,
            color: 'white',
            rotation: -90,
          },
        },
      ];

      serieData = _extractMonData({year: cy, data, months, dpInd: 4});
      // subData =
      //   di.year === thisYear ? serieData.slice(0, thisMon - 1) : [...serieData];
      // ytd = Utils.round(Utils.avg(subData));
      bgColors = months.map((it) => defBarColors[5]);
      if (thisYear === cy) {
        bgColors = months.map((it, ind) =>
          ind < thisMon - 1 ? defBarColors[5] : planBarColors[0],
        );
      }
      // datasets = [
      //   ...datasets,
      //   {
      //     label: `Actual/Predicted CSS MLT ${cy}`,
      //     backgroundColor: [...bgColors, defBarColors[5]],
      //     data: [...serieData],
      //     datalabels: {
      //       align: 'top',
      //       offset: 5,
      //       color: 'white',
      //       rotation: -90,
      //     },
      //   },
      // ];

      let labels: any[] = months.map((it) => t(`mon.${it}`));
      //  //console.log.*$

      let chartData = {
        labels: [...labels, '6 months rolling'],
        datasets,
      };

      setBarData({
        ...chartData,
      });
    },
    [_fetchData, _extractMonData, t, _fetchMltYtd, _fetchYtdTarget],
  );

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: async (data) => {
        _loadChartData(data);
        let act = await _fetchLastActivity(data);
        //console.log.*$
        if (act) {
          let str = Utils.reFmtDate(act.plStaDate, 'll');
          setActDatestr('Update on ' + str);
        } else {
          setActDatestr('');
        }
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      //console.log.*$
      subj.complete();
    };
  }, [_loadChartData, _fetchLastActivity]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    let adjYears = years
      ? years.map((it) => Number(it)).filter((it) => !isNaN(it))
      : [];
    let adjYear = Number(year) || undefined;

    fetchDataEvtSrc.next({
      scope,
      country,
      countries,
      factory,
      factories,
      year: adjYear,
      years: adjYears,
    });
  }, [scope, country, countries, factory, factories, year, years]);

  //console.log.*$
  // let barData1 = {
  //    ...barData,
  //     datasets: barData.datasets[0]

  // }

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="MLT Tracking" icons={actDateStr}>
        <Chart
          type="bar"
          data={barData}
          options={chartOptions}
          plugins={chartPlugins}></Chart>
      </Panel>
    </>
  );
}
