// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Subject} from 'rxjs';
//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  type?: string,
  byFactory?: any,
  byYears?: any[],
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function LeanReportData(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const {t} = useTranslation();

  const {byFactory, byYears, reload, selection, header, command} = props;
  const type = props.type || 'pph';

  const [leanReportsTblData, setLeanReportsTblData] = useState([]);
  // const [leanReportsTblStart, setLeanReportsTblStart] = useState(0);
  // const [leanReportsTblLimit] = useState(10);
  // const [leanReportsTblNbRows, setLeanReportsTblNbRows] = useState(0);
  const [selLeanReport, setSelLeanReport] = useState();
  // const [selLeanReports, setSelLeanReports] = useState([]);
  // const [sortMeta, setSortMeta] = useState([
  //   {
  //     field: 'obj.audYear',
  //     order: -1,
  //   },
  //   {
  //     field: 'obj.audMonth',
  //     order: -1,
  //   },
  // ]);
  const [sortField, setSortField] = useState('obj.audYear');
  const [sortOrder, setSortOrder] = useState(-1);

  const selMode = props.selectionMode || 'single';
  const {search} = app || '';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  const _fetchData = useCallback(async (options) => {
    // //console.log.*$
    // //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let {type, byFactory, byYears, search} = options;

    let start = 0;
    let limit = 0;
    let joins = [];
    let filters = {};
    let params = {};
    byYears = byYears || [];

    let facId = (byFactory ? byFactory.id : 0) || 0;
    // if (byFactory && byFactory.id) {
    filters = {
      ...filters,
      byFactory: 'obj.factory.id = :facId',
    };
    params = {
      ...params,
      facId: {
        type: 'long',
        value: facId,
      },
    };
    // }
    if (byYears.length < 1) {
      byYears = [0];
    } else {
      let cy = byYears[byYears.length - 1];
      byYears = [cy - 1, cy];
    }
    if (byYears.length > 0) {
      filters = {
        ...filters,
        byYears: 'obj.audYear in (:years)',
      };
      params = {
        ...params,
        years: byYears,
      };
    }
    let groupBys = ['obj.audYear', 'obj.audMonth'];
    let fields = [...groupBys];
    let fdNames = ['audYear', 'audMonth'];
    if (type === 'pph') {
      fields = [
        ...fields,
        'avg(obj.preDiManHour)',
        'avg(obj.diManHour)',
        'avg(obj.plaSi)',
        'avg(obj.actSi)',
        'avg(obj.prePph)',
        'avg(obj.actPph)',
        'avg(obj.tarPph)',
        'avg(obj.pphAchi)',
      ];
      fdNames = [
        ...fdNames,
        'preDiManHour',
        'diManHour',
        'plaSi',
        'actSi',
        'prePph',
        'actPph',
        'tarPph',
        'pphAchi',
      ];
    } else {
      fields = [
        ...fields,
        'avg(obj.actMlt)',
        'avg(obj.preMlt)',
        'avg(obj.tarMlt)',
        'avg(obj.mltAchi)',
        'avg(obj.tarMinl)',
        'avg(obj.actMinl)',
        'avg(obj.minlAchi)',
        'avg(obj.actCssMlt)',
        'avg(obj.tarCssMlt)',
      ];
      fdNames = [
        ...fdNames,
        'actMlt',
        'preMlt',
        'tarMlt',
        'mltAchi',
        'tarMinl',
        'actMinl',
        'minlAchi',
        'actCssMlt',
        'tarCssMlt',
      ];
    }

    //const _fetchYtdMlt = useCallback (async (options)=>{

    //},[]);

    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    // let sorts = sortMeta.map((sm) => {
    //   return sm.order > 0 ? sm.field : `-${sm.field}`;
    // });
    // //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);
    let sorts = [...groupBys];

    let recs = await api
      .fetchLeanReportData({
        search,
        joins,
        fields,
        groupBys,
        filters,
        params,
        start,
        limit,
        sorts,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        //console.log.*$
        // setLeanReportsTblData([...data.list]);
        // setLeanReportsTblNbRows(data.count);
        return [...data.list];
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
        return [];
      });
    return {
      list: [...recs],
      fdNames,
    };
  }, []);

  const _fetchPphYtd = useCallback(async (options) => {
    let {byYears, byFactory} = options;
    let api: ?AdminApi = apiRef.current;
    let year = byYears[byYears.length - 1];
    if (!api) {
      return;
    }
    let reqData = {
      year,
    };
    if (byFactory && byFactory.id) {
      reqData = {
        ...reqData,
        factoryId: byFactory.id,
      };
    }
    return await api
      .getPphYtd({
        data: {
          ...reqData,
        },
      })
      .then((resp) => resp.data)
      .then((resp) => {
        let {data, errors} = resp;
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        //console.log.*$
        return data.data;
      })
      .catch((error) => {
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  const _procesRptData = useCallback(
    (options) => {
      let {fields, year, data, fdNames} = options;
      //console.log.*$
      let fdIndMap: any = {};
      fdNames.forEach((fd, ind) => {
        fdIndMap[fd] = ind;
      });
      let mons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      let yearInd = fdIndMap['audYear'];
      let monInd = fdIndMap['audMonth'];
      data = data.filter((it) => it[yearInd] === year);
      let recs = fields.map((field) => {
        let obj: any = {
          id: `${year}_${field}`,
          field,
          label: t(`leanRpt.${field}`, {year}),
        };
        mons.forEach((mon) => {
          obj[`mon${mon}`] = 0;
        });
        return obj;
      });
      //console.log.*$
      let recIndMap = {};
      recs.forEach((rec, ind) => {
        recIndMap[rec.field] = rec;
      });
      //console.log.*$
      data.forEach((row) => {
        let monVal = row[monInd];
        recs.forEach((rec) => {
          //
          let fd = rec.field;
          let fdInd = fdIndMap[fd];
          let val = row[fdInd];
          if (rec.label.endsWith('(%)')) {
            val = val * 100;
          }
          rec[`mon${monVal}`] = val;
        });
      });
      //console.log.*$
      return [...recs];
    },
    [t],
  );

  const _fectchYtdPphKpi = useCallback(async (options) => {
    let {byYears, byFactory} = options;
    let api: ?AdminApi = apiRef.current;

    if (!api) {
      return;
    }
    let joins = [];

    let filters = {
      byType: 'obj.kpiType = :kpiType',
      byScope: 'obj.scope = :scope',
      byPeriod: 'obj.periodType = :periodType',
      byYear: 'obj.year = :year',
      byFactory: 'obj.factoryId = :factoryId',
    };

    let params = {
      kpiType: {
        type: 'enum:com.nb.lmrs.model.KpiType',
        value: 'PPH_YTD_TAR',
      },
      periodType: {
        type: 'enum:com.nb.lmrs.model.PeriodType',
        value: 'YEAR',
      },

      year: byYears[byYears.length - 1],
      scope: 'factory',

      factoryId: {
        type: 'long',
        value: byFactory['id'],
      },
    };

    return await api
      .getProdKpis({joins, filters, params, start: 0, limit: 0})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }

        let kpis = [...data.list];
        return kpis[0]['targetValue'] || 0;
      })
      .catch((errors) => {
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return 0;
        }
        return 0;
      });
  }, []);

  const _fetchMltYtd = useCallback(async (options) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return [];
    }
    let {byFactory, byYears} = options;
    let year = byYears[byYears.length - 1];
    let reqData = {
      year,
    };
    if (byFactory && byFactory.id) {
      reqData = {
        ...reqData,
        factoryId: byFactory.id,
      };
    }
    return await api
      .getMedianMlt({
        data: {
          ...reqData,
        },
      })
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        //console.log.*$
        return data.data;
      })
      .catch((errors) => {
        UIUtils.showError({errors, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchYtdMltKpi = useCallback(async (options) => {
    let {byFactory, byYears} = options;
    let api: ?AdminApi = apiRef.current;

    if (!api) {
      return;
    }
    let joins = [];

    let filters = {
      byType: 'obj.kpiType = :kpiType',
      byScope: 'obj.scope = :scope',
      byPeriod: 'obj.periodType = :periodType',
      byYear: 'obj.year = :year',
      byFactory: 'obj.factoryId = :factoryId',
    };

    let params = {
      kpiType: {
        type: 'enum:com.nb.lmrs.model.KpiType',
        value: 'MLT_YTD_TAR',
      },
      periodType: {
        type: 'enum:com.nb.lmrs.model.PeriodType',
        value: 'YEAR',
      },

      year: byYears[byYears.length - 1],
      scope: 'factory',

      factoryId: {
        type: 'long',
        value: byFactory.id,
      },
    };

    return await api
      .getProdKpis({joins, filters, params, start: 0, limit: 0})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //    //console.log.*$

        let kpis = [...data.list];

        return kpis[0]['targetValue'] || 0;
      })
      .catch((errors) => {
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return 0;
        }
        return 0;
      });
  }, []);
  const _loadTblData = useCallback(
    async (options) => {
      let rawData: any = await _fetchData(options);
      let {list, fdNames} = rawData;
      let {type, byYears} = options;
      byYears = byYears || [];
      if (byYears.length < 1) {
        return;
      }
      let curYear = byYears[byYears.length - 1];
      let prevYear = curYear - 1;

      let fields = fdNames.slice(2);
      let recs = _procesRptData({fdNames, fields, year: curYear, data: list});

      if (type === 'pph') {
        fields = [fdNames[7]];
      } else {
        fields = [fdNames[2]];
      }

      let pyRecs = _procesRptData({
        fdNames,
        fields,
        year: prevYear,
        data: list,
      });

      if (type === 'pph') {
        recs.splice(6, 0, pyRecs[0]);
        let preDiManHour = recs[0];
        let ytd = Object.keys(preDiManHour)
          .filter((key) => key.startsWith('mon') && preDiManHour[key] !== null)
          .reduce((sum, key) => sum + preDiManHour[key], 0);
        preDiManHour['ytd'] = ytd;

        let diManHour = recs[1];
        ytd = Object.keys(diManHour)
          .filter((key) => key.startsWith('mon') && diManHour[key] !== null)
          .reduce((sum, key) => sum + diManHour[key], 0);
        diManHour['ytd'] = ytd;

        let plaSi = recs[2];
        ytd = Object.keys(plaSi)
          .filter((key) => key.startsWith('mon') && plaSi[key] !== null)
          .reduce((sum, key) => sum + plaSi[key], 0);
        plaSi['ytd'] = ytd;

        let actSi = recs[3];
        ytd = Object.keys(actSi)
          .filter((key) => key.startsWith('mon') && actSi[key] !== null)
          .reduce((sum, key) => sum + actSi[key], 0);
        actSi['ytd'] = ytd;

        let actPph = recs[5];
        let preActPph = recs[6];

        let dataYtd: any[] = await _fetchPphYtd(options);

        actPph['ytd'] = dataYtd[1];
        preActPph['ytd'] = dataYtd[0];
        let targetPph = recs[7];
        targetPph['ytd'] = await _fectchYtdPphKpi(options);
        let pphAchi = recs[8];
        pphAchi['ytd'] = actPph['ytd'] / targetPph['ytd'];
      } else {
        recs.splice(0, 0, pyRecs[0]);
        let temp = recs[1];
        recs[1] = recs[2];
        recs[2] = temp;

        let preActMlt = recs[0];
        let actMlt = recs[2];

        let ytdMlt = await _fetchMltYtd(options);
        preActMlt['ytd'] = ytdMlt[0];
        actMlt['ytd'] = ytdMlt[1];
        let targetMlt = recs[3];
        targetMlt['ytd'] = await _fetchYtdMltKpi(options);
        let mltAchi = recs[4];
        mltAchi['ytd'] = targetMlt['ytd'] / actMlt['ytd'];
      }

      setLeanReportsTblData([...recs]);
    },
    [
      _fetchData,
      _procesRptData,
      _fetchPphYtd,
      _fetchMltYtd,
      _fetchYtdMltKpi,
      _fectchYtdPphKpi,
    ],
  );

  useEffect(() => {
    let subj = new Subject();
    //console.log.*$
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _loadTblData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      //console.log.*$
      subj.complete();
    };
  }, [_loadTblData]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelLeanReport({...selection});
      } else {
        // setSelLeanReports([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelLeanReport(null);
      } else {
        // setSelLeanReports([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        // setSortMeta([
        //   {
        //     field: 'obj.createdAt',
        //     order: -1,
        //   },
        // ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      type,
      byFactory,
      byYears,
      search,
    });
  }, [type, byFactory, byYears, search, reload]);

  const _onLeanReportSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelLeanReport(evt.value);
    } else {
      // setSelLeanReports(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    // let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    // newMeta = [
    //   {
    //     field: evt.sortField,
    //     order: evt.sortOrder,
    //   },
    //   ...newMeta,
    // ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    // setSortMeta(newMeta);
  };

  const _renderMonCol: TblColRender = (row, col) => {
    let {field} = col;
    let val = row[field] || 0;
    if (
      (row['field'] === 'pphAchi' && field === 'ytd') ||
      (row['field'] === 'mltAchi' && field === 'ytd')
    ) {
      return `${Utils.fmtDecimal(val * 100, 2)}`;
    }
    return Utils.fmtDecimal(val);
  };

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          scrollable
          header={header}
          value={leanReportsTblData}
          dataKey="id"
          paginator={false}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selLeanReport}
          selectionMode="single"
          onSelectionChange={_onLeanReportSelected}
          sortField={sortField}
          sortOrder={sortOrder}
          frozenWidth="200px"
          onSort={_onSort}>
          <Column
            field="label"
            header="#"
            headerStyle={{width: 200}}
            headerClassName="text-right"
            bodyClassName="text-right"
            frozen
          />

          <Column
            body={_renderMonCol}
            field="mon1"
            header="Jan"
            headerStyle={{width: 80}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />

          <Column
            body={_renderMonCol}
            field="mon2"
            header="Feb"
            headerStyle={{width: 80}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />

          <Column
            body={_renderMonCol}
            field="mon3"
            header="Mar"
            headerStyle={{width: 80}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />

          <Column
            body={_renderMonCol}
            field="mon4"
            header="Apr"
            headerStyle={{width: 80}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />

          <Column
            body={_renderMonCol}
            header="May"
            field="mon5"
            headerStyle={{width: 80}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />

          <Column
            body={_renderMonCol}
            field="mon6"
            header="Jun"
            headerStyle={{width: 80}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />

          <Column
            body={_renderMonCol}
            field="mon7"
            header="Jul"
            headerStyle={{width: 80}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />

          <Column
            body={_renderMonCol}
            field="mon8"
            header="Aug"
            headerStyle={{width: 80}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />

          <Column
            body={_renderMonCol}
            header="Sep"
            field="mon9"
            headerStyle={{width: 80}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />

          <Column
            body={_renderMonCol}
            field="mon10"
            header="Oct"
            headerStyle={{width: 80}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />

          <Column
            body={_renderMonCol}
            field="mon11"
            header="Nov"
            headerStyle={{width: 80}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />

          <Column
            body={_renderMonCol}
            field="mon12"
            header="Dec"
            headerStyle={{width: 80}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />

          <Column
            body={_renderMonCol}
            field="ytd"
            header="YTD"
            headerStyle={{width: 80}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
        </DataTable>
      )}
    </>
  );
}
