// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';
//$FlowFixMe
import {debounceTime} from 'rxjs/operators';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {Chart} from 'primereact/chart';
//$FlowFixMe
import {Button} from 'primereact/button';

import type {ApiProps} from '../../../service/Api';
import type {EvtHandler} from '../../types';
// import {AuthUtils} from '../service/AuthUtils';
import {UIUtils, Utils} from '../../../service/Utils';
import {AdminApi} from '../../../service/AdminApi';
import {getColor} from '../../../service/util/color';

// import {getChartOptions} from './CommonChart';
import {getStackedChartOptions} from '../CommonChart';

type Props = {
  scope?: string,
  regions?: any[],
  countries?: any[],
  facGroups?: any[],
  factories?: any[],
  lines?: any[],
  year?: any,
  expanded?: boolean,
  onAction?: EvtHandler,
};

export const biglColors: any = [
  'purple-700',
  'purple-500',
  'purple-300',
  'purple-100',
  'purple-800',
  'purple-600',
  'purple-400',
  'purple-200',
  'purple-50',
  'purple-900',
].map((c) => getColor(c));

export const minlColors: any = [
  'yellow-700',
  'yellow-500',
  'yellow-300',
  'yellow-100',
  'yellow-800',
  'yellow-600',
  'yellow-400',
  'yellow-200',
  'yellow-50',
  'yellow-900',
].map((c) => getColor(c));

export const minlRmColors: any = [
  'orange-700',
  'orange-500',
  'orange-300',
  'orange-100',
  'orange-900',
].map((c) => getColor(c));

export const minlRateColors: any = [
  'green-700',
  'green-500',
  'green-300',
  'green-100',
  'green-800',
  'green-600',
  'green-400',
  'green-200',
  'green-50',
  'green-900',
].map((c) => getColor(c));

export const minlVolRateColors: any = [
  'blue-700',
  'blue-500',
  'blue-300',
  'blue-100',
  'blue-800',
  'blue-600',
  'blue-400',
  'blue-200',
  'blue-50',
  'blue-900',
].map((c) => getColor(c));

let chartOptions = getStackedChartOptions();
const scales = {
  y: {
    type: 'linear',
    //stacked: true,
    display: true,
    position: 'left',
  },
  y1: {
    type: 'linear',
    //stacked: true,
    display: true,
    position: 'right',
    // grid line settings
    grid: {
      drawOnChartArea: false, // only want the grid lines for one axis to show up
    },
  },
};
chartOptions = {
  ...chartOptions,
  ...scales,
};

export function MiniLineBarMonthlyFacV2(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();
  const {
    scope,
    regions,
    countries,
    facGroups,
    factories,
    lines,
    year,
    expanded,
    onAction,
  } = props;
  //console.log.*$

  const [barData, setBarData] = useState();
  // const [chartOptions] = useState(getChartOptions());

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchData = useCallback(
    async (options: {factories: any[], year: number}) => {
      let {factories, year} = options;
      let api: ?AdminApi = apiRef.current;
      if (!api) {
        return;
      }
      let pYear = Number(year) || 0;

      let joins = [
        {
          type: 'join',
          expr: 'obj.factory',
          alias: 'fac',
        },
        {
          type: 'join',
          expr: 'fac.country',
          alias: 'coun',
        },
        {
          type: 'join',
          expr: 'coun.region',
          alias: 'reg',
        },
      ];
      let filters = {
        liveFlag: 'obj.flag > -1',
      };

      let params = {};
      let groupBys = ['obj.audMonth'];
      groupBys = [...groupBys];
      let facIds = Utils.getVals(factories || [], 'id', [0]).map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
      filters = {
        ...filters,
        byFacIds: 'fac.id in (:facIds)',
      };
      params = {
        ...params,
        facIds,
      };

      filters = {
        ...filters,
        year: 'obj.audYear = :year',
      };
      params = {
        ...params,
        year: pYear,
      };

      let fields = [
        ...groupBys,
        'sum(obj.nbMiniLines)',
        'sum(obj.nbBigLines)',
        'sum(obj.totalLines)',
        'avg(obj.tarMinlRate)',
        'avg(obj.minlVolRate)',
        'avg(obj.minlRate)',
      ];
      let sorts = [...groupBys];

      return await api
        .fetchMiniLineAuditData({
          fields,
          joins,
          groupBys,
          filters,
          params,
          sorts,
          start: 0,
          limit: 0,
        })
        .then((resp) => resp.data)
        .then((resp) => {
          // //console.log.*$
          let {errors, data} = resp;
          if (errors.length > 0) {
            //console.log.*$
            UIUtils.showError({errors, toast: toastRef.current});
            return [];
          }
          let recs: any[] = [...data.list];
          recs.forEach((it) => {
            it[4] = Utils.round(it[4] * 100, 2);
            it[5] = Utils.round(it[5] * 100, 2);
            it[6] = Utils.round(it[6] * 100, 2);
          });
          return [...recs];
        })
        .catch((error) => {
          //console.log.*$
          UIUtils.showError({error, toast: toastRef.current});
          return [];
        });
    },
    [],
  );

  const _fetchChartData = useCallback(
    async (options: any) => {
      let months = [];
      for (let i = 0; i < 12; i++) {
        months.push(i + 1);
      }
      let labels = months.map((it) => it);
      labels = labels.map((it) => t(`mon.${it}`));
      let datasMiniLine = [];
      let datasBigline = [];
      let miniLine = [];

      _fetchData(options).then((data: any[]) => {
        months.forEach((m) => {
          let dataFil = data.filter((dt) => {
            return dt[0] === m;
          });

          if (dataFil && dataFil.length > 0) {
            datasBigline.push(dataFil[0][2] ? dataFil[0][2] : 0);
            datasMiniLine.push(dataFil[0][1] ? dataFil[0][1] : 0);
            miniLine.push(dataFil[0][6] ? dataFil[0][6] : 0);
          } else {
            datasBigline.push(0);
            datasMiniLine.push(0);
            miniLine.push(0);
          }
        });

        let biglDatasets = [
          {
            label: `BigLine`,
            backgroundColor: minlVolRateColors[0],
            data: datasBigline,
          },
        ];

        let minlVolDatasets = [
          {
            label: `Miniline`,
            backgroundColor: minlRateColors[0],
            data: datasMiniLine,
          },
        ];

        let rmDatasets = [
          {
            type: 'line',
            label: `Miniline %`,
            backgroundColor: minlRmColors[0],
            borderColor: minlRmColors[0],
            borderWidth: 1,
            data: miniLine,
            yAxisID: 'y1',
          },
        ];

        let chartData = {
          labels,
          datasets: [
            ...rmDatasets,
            ...biglDatasets,
            ...minlVolDatasets,
            // ...minlRateDatasets,

            //  ...minlDatasets,
          ],
        };

        setBarData(chartData);
      });
    },
    [_fetchData, t],
  );

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchChartData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      // //console.log.*$
      subj.complete();
    };
  }, [_fetchChartData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      scope,
      regions,
      countries,
      facGroups,
      factories,
      lines,
      year,
    });
  }, [scope, regions, countries, facGroups, factories, lines, year]);

  const _onToggleExpand: EvtHandler = (evt: any) => {
    onAction &&
      onAction({
        type: 'toggleExpand',
        value: !expanded,
      });
  };

  const iconName = expanded
    ? 'pi pi-angle-double-left'
    : 'pi pi-angle-double-right';

  const icons = (
    <Button
      icon={iconName}
      className="p-button-rounded p-button-text"
      style={{padding: 0, height: '1.2rem', color: '#FFFFFF'}}
      onClick={_onToggleExpand}
    />
  );
  // //console.log.*$
  //  //console.log.*$
  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="Mini Line % (Total Line) - Monthly" icons={icons}>
        <Chart type="bar" data={barData} options={chartOptions}></Chart>
      </Panel>
    </>
  );
}
