// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe
import {Button} from 'primereact/button';
// import {Dialog} from 'primereact/dialog';
//$FlowFixMe
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';

import type {ApiProps} from '../../service/Api';
import {AuthUtils} from '../../service/AuthUtils';
import {AuthConst} from '../../service/AppConstant';
import {UIUtils, Utils} from '../../service/Utils';
import {MltRecList} from './MltRecList';
import {AdminApi} from '../../service/AdminApi';
import {FactoryFilter} from '../common/FactoryFilter';
import {EvtHandler} from '../types';

type Props = {};

export function MltRec(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  // const [selMltRec, setSelMltRec] = useState<any>();
  const [selMltRecs, setSelMltRecs] = useState([]);
  const [reload, setReload] = useState(false);
  const [tabInd, setTabInd] = useState(0);
  const [filter, setFilter] = useState({});
  const [initFilter, setInitFilter] = useState({});
  // const [defSort, setDefSort] = useState([]);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let initFil = {};
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      initFil['factory'] = factories[0];
    }

    let di: any = Utils.getDateInfo() || {};
    initFil = {
      ...initFil,
      month: di.month,
      year: di.year,
    };

    setFilter(initFil);
    setInitFilter({...initFil});
  }, [auth]);

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _deleteRecs: (recs: any) => any = async (recs: any[]) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let ok = false;
    for (let rec of recs) {
      ok = await api
        .deleteMltRec(rec.id)
        .then((resp) => resp.data)
        .then((resp) => {
          let {errors} = resp;
          if (errors.length > 0) {
            //console.log.*$
            UIUtils.showError({errors, toast: toastRef.current});
            return false;
          }
          // UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          return true;
        })
        .catch((error) => {
          //console.log.*$
          UIUtils.showError({error, toast: toastRef.current});
          return false;
        });
      if (!ok) {
        break;
      }
    }
    if (ok) {
      UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
    }
  };

  const _onFilterAction: EvtHandler = (evt: any) => {
    let {type, value} = evt;
    if (type === 'filterChanged') {
      setFilter({...value});
      return;
    }

    //console.log.*$
  };

  const _onMltRecSelected = (evt: any) => {
    // setSelMltRec(evt.value);
    // //console.log.*$
    // setSelMltRec(evt.value);
    setSelMltRecs(evt.value);
    // setEditMltRec({...evt.value});
  };

  const _onDelete: (evt: any) => void = (evt: any) => {
    if (selMltRecs.length < 1) {
      return;
    }
    confirmDialog({
      message: 'Are you sure you want to delete production records?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        await _deleteRecs(selMltRecs);
        setSelMltRecs([]);
        setReload(!reload);
      },
      reject: () => {},
    });
  };

  // let listTitle = 'Available production records';
  let authLevel = AuthUtils.getAuthLevel({auth});
  let canEdit =
    authLevel < AuthConst.AUTH_LVL_NB ||
    AuthUtils.isAuth({auth, roles: ['FACTORY_ADMIN', 'FACTORY_EDITOR']});

  //console.log.*$
  return (
    <>
      <Toast ref={toastRef} />

      <TabView activeIndex={tabInd} onTabChange={(e) => setTabInd(e.index)}>
        <TabPanel header="MLT records">
          <FactoryFilter
            scope="factory"
            filter={initFilter}
            showFactory={true}
            showMonth={true}
            showYear={true}
            showFilterId={true}
            onAction={_onFilterAction}
          />

          <MltRecList
            byId={filter.id}
            byFactory={filter.factory}
            reload={reload}
            byYear={filter.year}
            byMonth={filter.month}
            selectionMode="multiple"
            selection={selMltRecs}
            onSelect={_onMltRecSelected}
          />
          {canEdit && (
            <div className="p-d-flex p-jc-end p-my-2">
              {selMltRecs.length > 0 && (
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  className="p-ml-2"
                  onClick={_onDelete}
                />
              )}
            </div>
          )}
        </TabPanel>
      </TabView>
    </>
  );
}
