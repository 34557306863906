// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {Dropdown} from 'primereact/dropdown';
// import {Dialog} from 'primereact/dialog';
// import {Checkbox} from 'primereact/checkbox';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  readOnly?: boolean,
  FacGroup: any,
  onAction: (evt: any) => void,
};

const defFacGroup = {
  __cls__: 'com.nb.lmrs.model.entity.FacGroup',
  flag: 0,
  code: '',
  name: '',
  description: '',
  facGroupType: '',
};

export function FacGroupForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {FacGroup, readOnly} = props;
  const [editFacGroup, setEditFacGroup] = useState<any>();

  const [groupTypes, setGroupTypes] = useState([]);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let groupTypes = ['SPECIALIST', 'STRATEGIC', 'CORE'];
    let typeOpts = groupTypes.map((it) => {
      return {
        label: it,
        value: it,
      };
    });

    setGroupTypes(typeOpts);
  }, []);

  useEffect(() => {
    // //console.log.*$
    let curFacGroup = FacGroup || {};
    curFacGroup = {
      ...defFacGroup,
      ...curFacGroup,
    };
    // //console.log.*$
    setEditFacGroup(curFacGroup);
  }, [FacGroup]);

  const _onSave = (evt: any) => {
    // setShowCfmDlg(false);
    let fac = _validateFacGroup();
    //console.log.*$
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update factory group?'
        : 'Are you sure you want to create factory group?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateFacGroup({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'FacGroupSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _validateFacGroup: () => any = () => {
    let obj = {...editFacGroup};

    if (Utils.isEmpty(obj.code)) {
      UIUtils.showError({
        detail: 'Please enter group code',
        toast: toastRef.current,
      });
      return false;
    }

    if (Utils.isEmpty(obj.name)) {
      UIUtils.showError({
        detail: 'Please enter group name',
        toast: toastRef.current,
      });
      return false;
    }

    if (Utils.isEmpty(obj.facGroupType)) {
      UIUtils.showError({
        detail: 'Please enter group type ',
        toast: toastRef.current,
      });
      return false;
    }

    return obj;
  };

  if (!editFacGroup) {
    return null;
  }

  const saveLbl = editFacGroup.id
    ? 'Update Factory Group'
    : 'Create Factory Group';
  const isNew = !editFacGroup.id;
  // //console.log.*$

  // let authLevel = AuthUtils.getAuthLevel({auth});
  // let canEdit =
  //   authLevel < AuthConst.AUTH_LVL_NB ||
  //   AuthUtils.isAuth({auth, roles: ['ADMIN']});

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/edit Factory Group">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editFacGroup.code}
                onChange={(e) => {
                  setEditFacGroup({...editFacGroup, code: e.target.value});
                }}
                disabled={!isNew || readOnly}
              />
              <label>Group code</label>
            </span>
          </div>

          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  value={editFacGroup.name}
                  onChange={(e) => {
                    setEditFacGroup({
                      ...editFacGroup,
                      name: e.target.value,
                    });
                  }}
                  disabled={readOnly}
                />
                <label>Group name</label>
              </span>
            </div>
          </div>

          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <Dropdown
                  // optionLabel="label"
                  // optionValue="value"
                  value={editFacGroup.facGroupType}
                  options={groupTypes}
                  onChange={(e) =>
                    setEditFacGroup({
                      ...editFacGroup,
                      facGroupType: e.target.value,
                    })
                  }
                  disabled={readOnly}
                />
                <label>Group Type</label>
              </span>
            </div>
          </div>

          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputTextarea
                  rows={2}
                  value={editFacGroup.description}
                  onChange={(e) => {
                    setEditFacGroup({
                      ...editFacGroup,
                      description: e.target.value,
                    });
                  }}
                  disabled={readOnly}
                />
                <label>Summary</label>
              </span>
            </div>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
