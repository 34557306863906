// @flow
import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';
import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
import {UIUtils, Utils} from '../../service/Utils';

import {AdminApi} from '../../service/AdminApi';
import {FactoryFilter} from '../common/FactoryFilter';
import {MaxPotentialScore} from './../lean/MaxPotentialScore';

type Props = {};
const defFilter = {
  // factory: null,
  month: null,
  years: [2022],
};

export function LeanScoreCard(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const [leanScoreCardData, setLeanScoreCardData] = useState([]);
  const [kpiData, setKpiData] = useState([]);
  const [mltSetPoint, setMltSetPoint] = useState(0);
  const [maxKpiData, setMaxKpiData] = useState({});
  const [maxScorePotentinalData, setMaxScorePotentinalData] = useState([]);
  const [nbScoreData, setNbScoreData] = useState([]);
  const [filter, setFilter] = useState({...defFilter});
  const [tabInd, setTabInd] = useState(0);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let initFil = {};
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      initFil['factory'] = factories[0];
    }
    let di: any = Utils.getDateInfo() || {};

    initFil = {
      ...initFil,
      day: di.day,
      month: di.month,
      years: [di.year],
    };
    setFilter(initFil);
  }, [auth]);

  const currentDate = useMemo(() => {
    let currentDate = {};
    let di: any = Utils.getDateInfo() || {};

    currentDate = {
      ...currentDate,
      day: di.day,
      month: di.month,
      years: [di.year],
    };
    return currentDate;
  }, []);

  const _onFilterAction: EvtHandler = (evt: any) => {
    let {type, value} = evt;
    if (type === 'filterChanged') {
      setFilter({...value});
    }
  };

  const _fetchKpi = useCallback(async (options) => {
    //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let {month, years} = options;
    let qoy = Math.floor((month - 1 + 3) / 3);
    let year = Number(years[years.length - 1]) || 0;
    let filters = {
      qoy: 'obj.qoy = :qoy',
      byYear: 'obj.year = :year',
    };
    let params = {
      qoy,
      year,
    };
    //console.log.*$
    return await api
      .getLeanScoreCard({filters, params, start: 0, limit: 0})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        setKpiData([...data.list]);
        return [...data.list];
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
        return [];
      });
  }, []);

  const lstGroup = useMemo(
    () => [
      'CJ Group',
      'Diamond Group',
      'Freetrend',
      'Metro',
      'Parkland',
      'Pouchen Group',
      'Samho Group',
      'Jiahsin',
      'NPI',
      'Elite',
      'Victory',
      'SDI',
      'YFV',
      'Huali',
      'GEC',
    ],
    [],
  );

  const renderKpi = (data, type) => {
    if (data.length > 0) {
      if (type === 'MLT') {
        let kpiMlt = data.filter((dt) => {
          return dt.kpiType === 'MLT';
        });

        return (
          <>
            <td className="col_2345"> {kpiMlt[0]['intValue']}</td>
            <td className="col_6">
              1. MLT &gt; {kpiMlt[0]['value1']} days = 0 points
              <br />
              2. MLT &le; {kpiMlt[0]['targetValue']} but &gt;{' '}
              {kpiMlt[0]['value1']} use formula: 1+ (((minimum MLT- actual
              MLT)/(minimum MLT- target MLT))* (maxpoints -1))
              <br />
              3. MLT &le; {kpiMlt[0]['targetValue']} days = (1-(MLT/
              {kpiMlt[0]['targetValue']}) ) + maxpoints
            </td>
          </>
        );
      } else if (type === 'MINL_RATE') {
        let kpiMiniLine = data.filter((dt) => {
          return dt.kpiType === 'MINL_RATE';
        });

        return (
          <>
            <td className="col_2345"> {kpiMiniLine[0]['intValue']}</td>
            <td className="col_6">
              1. MiniLines &lt; {kpiMiniLine[0]['value1']}% = 0 points
              <br />
              2. MiniLines(ML) &ge; {kpiMiniLine[0]['value1']}% but &lt;
              {kpiMiniLine[0]['targetValue']}% use formula: 1+ (((ML-minimum
              ML)/(target ML-minimum ML)) * (maxpoints-1))
              <br />
              3. MiniLines &ge; = {kpiMiniLine[0]['targetValue']}% = MiniLines/
              {kpiMiniLine[0]['targetValue']}% * maxpoints
            </td>
          </>
        );
      } else if (type === 'PPH') {
        let kpiPph = data.filter((dt) => {
          return dt.kpiType === 'PPH';
        });
        return (
          <>
            <td className="col_2345"> {kpiPph[0]['intValue']}</td>
            <td className="col_6">
              1. pph &lt; {kpiPph[0]['value1']} = 0 points
              <br />
              2. pph &ge; {kpiPph[0]['value1']} but &lt;{' '}
              {kpiPph[0]['targetValue']} use formula: 1+ (((actual pph-minimum
              pph)/(target pph-minimum pph))* (maxpoints -1))
              <br />
              3. pph &ge; {kpiPph[0]['targetValue']} use formula pph/
              {kpiPph[0]['targetValue']}*maxpoints
              <br />
              <span style={{fontSize: '10px'}}>Specialist</span>
              <br></br>
              1. pph &lt; {kpiPph[0]['value3']} = 0 points
              <br />
              2. pph &ge; {kpiPph[0]['value3']} but &lt; {kpiPph[0]['value2']}{' '}
              use formula: 1+ (((actual pph-minimum pph)/(target pph-minimum
              pph))* (maxpoints -1))
              <br />
              3. pph &ge; {kpiPph[0]['value2']} use formula pph/
              {kpiPph[0]['value2']}
              *maxpoints
            </td>
          </>
        );
      } else if (type === 'LEAN_SCORE') {
        let kpiLean = data.filter((dt) => {
          return dt.kpiType === 'LEAN_SCORE';
        });

        return (
          <>
            <td className="col_2345"> {kpiLean[0]['intValue']}</td>
            <td className="col_6">
              1. Lean Assessment &lt; {kpiLean[0]['value1']} = 0 points
              <br />
              2. Lean Assessment(LA) &gt;{kpiLean[0]['value1']} but &lt;{' '}
              {kpiLean[0]['targetValue']} use formula: 1+ (((actual LA-minimum
              LA)/(target LA-minimum LA))* (max. points -1))
              <br />
              3. Lean Assessment &gt;= {kpiLean[0]['targetValue']} =Lean
              Assessment/ {kpiLean[0]['targetValue']}*max. points
            </td>
          </>
        );
      } else if (type === 'PO') {
        let kpiLean = data.filter((dt) => {
          return dt.kpiType === 'PO';
        });

        return (
          <>
            <td className="col_2345"> {kpiLean[0]['intValue']}</td>
            <td className="col_6">
              1. PO &lt; {kpiLean[0]['value1']}% = 0 points
              <br />
              2. PO &ge; {kpiLean[0]['value1']}% but &lt;
              {kpiLean[0]['targetValue']}% use formula: 1+ (((actual PO-minimum
              PO)/(target PO-minimum PO)) * (maxpoints -1))
              <br />
              3. PO &gt;= {kpiLean[0]['targetValue']} use formula PO/
              {kpiLean[0]['targetValue']} * maxpoints
            </td>
          </>
        );
      } else if (type === 'BTS') {
        let kpiLean = data.filter((dt) => {
          return dt.kpiType === 'BTS';
        });

        return (
          <>
            <td className="col_2345"> {kpiLean[0]['intValue']}</td>
            <td className="col_6">
              1. BTS &lt; {kpiLean[0]['value1']}% = 0 points
              <br />
              2. BTS &ge; {kpiLean[0]['value1']}% but &lt;
              {kpiLean[0]['targetValue']}% use formula: 1+ (((actual BTS-minimum
              BTS)/(target BTS-minimum BTS)) * (maxpoints -1))
              <br />
              3. BTS &gt;= {kpiLean[0]['targetValue']} use formula BTS/
              {kpiLean[0]['targetValue']} * maxpoints
            </td>
          </>
        );
      }
    }
  };
  const _renderMaxScorePotential = (maxScorePotentinalData, type) => {
    if (maxScorePotentinalData && maxScorePotentinalData.length > 0) {
      switch (type) {
        case 'PPH':
          if (maxScorePotentinalData[0][0] >= maxKpiData['PPH']) {
            return (
              <td class="score_detail bg_green">
                {maxScorePotentinalData[0][0]}
              </td>
            );
          } else {
            return (
              <td class="score_detail bg_yellow">
                {maxScorePotentinalData[0][0]}
              </td>
            );
          }

        case 'MLT':
          if (maxScorePotentinalData[1][0] <= maxKpiData['MLT']) {
            return (
              <td class="score_detail bg_green">
                {maxScorePotentinalData[1][0]}
              </td>
            );
          } else {
            return (
              <td class="score_detail bg_yellow">
                {maxScorePotentinalData[1][0]}
              </td>
            );
          }

        case 'MINL_RATE':
          if (maxScorePotentinalData[2][0] >= maxKpiData['MINL_RATE']) {
            return (
              <td class="score_detail bg_green">
                {maxScorePotentinalData[2][0]}%
              </td>
            );
          } else {
            return (
              <td class="score_detail bg_yellow">
                {maxScorePotentinalData[2][0]}%
              </td>
            );
          }

        case 'LEAN_SCORE':
          if (maxScorePotentinalData[3][0] >= maxKpiData['LEAN_SCORE']) {
            return (
              <td class="score_detail bg_green">
                {maxScorePotentinalData[3][0]}
              </td>
            );
          } else {
            return (
              <td class="score_detail bg_yellow">
                {maxScorePotentinalData[3][0]}
              </td>
            );
          }

        case 'PO':
          if (maxScorePotentinalData[5][0] >= maxKpiData['PO']) {
            return (
              <td class="score_detail bg_green">
                {maxScorePotentinalData[5][0]}%
              </td>
            );
          } else {
            return (
              <td class="score_detail bg_yellow">
                {maxScorePotentinalData[5][0]}%
              </td>
            );
          }
        case 'BTS':
          if (maxScorePotentinalData[4][0] >= maxKpiData['BTS']) {
            return (
              <td class="score_detail bg_green">
                {maxScorePotentinalData[4][0]}%
              </td>
            );
          } else {
            return (
              <td class="score_detail bg_yellow">
                {maxScorePotentinalData[4][0]}%
              </td>
            );
          }
        case 'SUM':
          return (
            <td className="score_overall">{maxScorePotentinalData[6][1]}</td>
          );

        default:
      }
    } else {
      return <td class="score_overall">NA</td>;
    }
  };

  const _renderNbScore = (nbScoreData, type) => {
    if (nbScoreData && nbScoreData.length > 0) {
      switch (type) {
        case 'PPH':
          if (nbScoreData[0][0] >= maxKpiData['PPH']) {
            return <td class="score_detail bg_green">{nbScoreData[0][0]}</td>;
          } else {
            return <td class="score_detail bg_yellow">{nbScoreData[0][0]}</td>;
          }
        case 'MLT':
          if (nbScoreData[1][0] >= maxKpiData['MLT']) {
            return <td class="score_detail bg_green">{nbScoreData[1][0]}</td>;
          } else {
            return <td class="score_detail bg_yellow">{nbScoreData[1][0]}</td>;
          }
        case 'MINL_RATE':
          if (nbScoreData[2][0] >= maxKpiData['MINL_RATE']) {
            return <td class="score_detail bg_green">{nbScoreData[2][0]}%</td>;
          } else {
            return (
              <td class="score_detail bg_yellow">{nbScoreData[2][0]}% </td>
            );
          }
        case 'LEAN_SCORE':
          if (nbScoreData[3][0] >= maxKpiData['LEAN_SCORE']) {
            return <td class="score_detail bg_green">{nbScoreData[3][0]}</td>;
          } else {
            return <td class="score_detail bg_yellow">{nbScoreData[3][0]}</td>;
          }
        case 'PO':
          if (nbScoreData[5][0] >= maxKpiData['PO']) {
            return <td class="score_detail bg_green">{nbScoreData[5][0]}%</td>;
          } else {
            return <td class="score_detail bg_yellow">{nbScoreData[5][0]}%</td>;
          }
        case 'BTS':
          if (nbScoreData[4][0] >= maxKpiData['BTS']) {
            return <td class="score_detail bg_green">{nbScoreData[4][0]}%</td>;
          } else {
            return <td class="score_detail bg_yellow">{nbScoreData[4][0]}%</td>;
          }
        case 'SUM':
          return <td class="score_overall">{nbScoreData[6][1]}</td>;
        default:
      }
    } else {
      return <td class="score_overall">NA</td>;
    }
  };
  const _fetchLeanScoreCardReport = useCallback(
    async (options, controller?) => {
      let {years, month} = options;
      month = Number(month) || 0;
      let year = Number(years[years.length - 1]) || 0;
      let api = apiRef.current;
      if (!api) {
        return;
      }

      try {
        const resp = await api.getLeanScoreCardReport(
          {data: {month, year}},
          controller,
        );
        let {errors, data} = resp.data;
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }

        setMaxKpiData({...data.maxkpi});
        setMaxScorePotentinalData([...data.datapotential]);
        setNbScoreData([...data.datanbscore]);
        setLeanScoreCardData([...data.data]);
      } catch (errors) {
        if (errors && errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        return [];
      }
    },
    [],
  );

  const _renderPphLeanScoreCardData = (
    lstGroup,
    leanScoreCardData,
    maxKpiData,
  ) => {
    let data = [];

    for (let group of lstGroup) {
      let obj = leanScoreCardData.find((dt) => {
        return dt.facGroup.code === group;
      });
      if (obj) {
        if (obj.pph >= maxKpiData['PPH']) {
          data.push(
            <td class="score_detail bg_green">{Utils.round(obj.pph, 2)}</td>,
          );
        } else {
          data.push(
            <td class="score_detail bg_yellow">{Utils.round(obj.pph, 2)}</td>,
          );
        }
      } else {
        data.push(<td class="score_detail bg_green">NA</td>);
      }
    }

    return data;
  };
  const _renderMltLeanScoreCardData = (
    lstGroup,
    leanScoreCardData,
    maxKpiData,
  ) => {
    let data = [];
    for (let group of lstGroup) {
      let obj = leanScoreCardData.find((dt) => {
        return dt.facGroup.code === group;
      });
      if (obj) {
        if (obj.mlt <= maxKpiData['MLT']) {
          data.push(
            <td class="score_detail bg_green">{Utils.round(obj.mlt, 2)}</td>,
          );
        } else {
          data.push(
            <td class="score_detail bg_yellow">{Utils.round(obj.mlt, 2)}</td>,
          );
        }
      } else {
        data.push(<td class="score_detail bg_green">NA</td>);
      }
    }

    return data;
  };

  const _renderLeanAssessmentLeanScoreCardData = (
    lstGroup,
    leanScoreCardData,
    maxKpiData,
  ) => {
    let data = [];
    for (let group of lstGroup) {
      let obj = leanScoreCardData.find((dt) => {
        return dt.facGroup.code === group;
      });
      if (obj) {
        if (obj.leanScoreAssessment >= maxKpiData['LEAN_SCORE']) {
          data.push(
            <td class="score_detail bg_green">
              {Utils.round(obj.leanScoreAssessment, 2)}
            </td>,
          );
        } else {
          data.push(
            <td class="score_detail bg_yellow">
              {Utils.round(obj.leanScoreAssessment, 2)}
            </td>,
          );
        }
      } else {
        data.push(<td class="score_detail bg_green">NA</td>);
      }
    }

    return data;
  };

  const _renderMiniLineScoreCardData = (
    lstGroup,
    leanScoreCardData,
    maxKpiData,
  ) => {
    let data = [];
    for (let group of lstGroup) {
      let obj = leanScoreCardData.find((dt) => {
        return dt.facGroup.code === group;
      });
      if (obj) {
        if (obj.miniLine >= maxKpiData['MINL_RATE']) {
          data.push(
            <td class="score_detail bg_green">
              {Utils.round(obj.miniLine, 2)}%
            </td>,
          );
        } else {
          data.push(
            <td class="score_detail bg_yellow">
              {Utils.round(obj.miniLine, 2)}%
            </td>,
          );
        }
      } else {
        data.push(<td class="score_detail bg_green">NA</td>);
      }
    }

    return data;
  };

  const _renderPoScoreCardData = (lstGroup, leanScoreCardData, maxKpiData) => {
    let data = [];
    for (let group of lstGroup) {
      let obj = leanScoreCardData.find((dt) => {
        return dt.facGroup.code === group;
      });
      if (obj) {
        if (obj.poCompletion >= maxKpiData['PO']) {
          data.push(
            <td class="score_detail bg_green">
              {Utils.round(obj.poCompletion, 2)}%
            </td>,
          );
        } else {
          data.push(
            <td class="score_detail bg_yellow">
              {Utils.round(obj.poCompletion, 2)}%
            </td>,
          );
        }
      } else {
        data.push(<td class="score_detail bg_green">NA</td>);
      }
    }

    return data;
  };

  const _renderBtsScoreCardData = (lstGroup, leanScoreCardData, maxKpiData) => {
    let data = [];
    for (let group of lstGroup) {
      let obj = leanScoreCardData.find((dt) => {
        return dt.facGroup.code === group;
      });
      if (obj) {
        if (obj.bts >= maxKpiData['BTS']) {
          data.push(
            <td class="score_detail bg_green">{Utils.round(obj.bts, 2)}%</td>,
          );
        } else {
          data.push(
            <td class="score_detail bg_yellow">{Utils.round(obj.bts, 2)}%</td>,
          );
        }
      } else {
        data.push(<td class="score_detail bg_green">NA</td>);
      }
    }

    return data;
  };

  const _renderSumScoreData = (lstGroup, leanScoreCardData) => {
    let data = [];
    for (let group of lstGroup) {
      let obj = leanScoreCardData.find((dt) => {
        return dt.facGroup.code === group;
      });
      if (obj) {
        data.push(<td class="score_overall">{obj.sumScore}</td>);
      } else {
        data.push(<td class="score_overall">NA</td>);
      }
    }
    return data;
  };

  const _renderRankingData = (lstGroup, leanScoreCardData) => {
    let data = [];
    for (let group of lstGroup) {
      let obj = leanScoreCardData.find((dt) => {
        return dt.facGroup.code === group;
      });
      if (obj) {
        data.push(<td class="score_overall">{obj.ranking}</td>);
      } else {
        data.push(<td class="score_overall">NA</td>);
      }
    }
    return data;
  };

  const _fetchMltSetPoint = useCallback(async (options) => {
    let {month, years} = options;
    let api = apiRef.current;
    if (!api) {
      return;
    }
    let year = years[years.length - 1] || 0;
    month = month || 0;
    let filters = {};
    let params = {};
    let start = 0;
    let limit = 0;
    let sorts = ['-obj.month'];

    filters = {
      ...filters,
      byMonth: 'obj.month = :byMonth or obj.month = -1',
      byYear: 'obj.year = :byYear',
    };

    params = {
      ...params,
      byMonth: Number(month),
      byYear: Number(year),
    };

    api
      .getMltSetpoint({filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        console.log(data);
        if (data.list.length > 0) {
          let mltSetPoint = data.list[0];
          setMltSetPoint(mltSetPoint.value);
        }
        //  setMltSetPointsTblData([...data.list]);
        // setMachLinesTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    let {month, years} = filter;
    if (month && years && years.length > 0) {
      _fetchLeanScoreCardReport({years, month}, controller);
      _fetchKpi({years, month});
      _fetchMltSetPoint({month, years});
    }
    return () => {
      controller.abort();
    };
  }, [filter, _fetchLeanScoreCardReport, _fetchKpi, _fetchMltSetPoint]);

  return (
    <>
      <Toast ref={toastRef} />
      <TabView activeIndex={tabInd} onTabChange={(e) => setTabInd(e.index)}>
        <TabPanel header="Lean Score Card">
          <div className="py-2">
            <FactoryFilter
              scope="factory"
              filter={filter}
              showYears={true}
              showMonth={true}
              onAction={_onFilterAction}
            />
          </div>

          <div className="table_container">
            <table className="scorecardtb">
              <tbody>
                <tr>
                  <td colSpan={6} rowSpan={2} className="table_title">
                    <h1>Footwear T1 Scorecard</h1>
                    <h2>
                      updated{' '}
                      {Utils.reFmtDate(
                        `${currentDate.years[currentDate.years.length - 1]}/${
                          currentDate.month
                        }/${currentDate.day}`,
                        'll',
                      )}
                    </h2>
                  </td>
                  <td rowSpan={3} className="max_score_header">
                    Max
                    <br />
                    Potential Score
                  </td>
                  <td rowSpan={3} className="nb_score_header">
                    NB
                    <br />
                    Score
                  </td>
                  <td colSpan={7} className="factory_catogery">
                    Strategic
                  </td>
                  <td colSpan={2} className="factory_catogery">
                    Specialist
                  </td>
                  <td colSpan={7} className="factory_catogery">
                    Core
                  </td>
                </tr>
                <tr>
                  <td className="factory_group">
                    CJ <br />
                    (1,2,3) <br /> CJV{/*CJ Group*/}
                  </td>
                  <td className="factory_group">
                    DIA2 <br /> DIAH <br /> DIAV <br /> DIAI <br />{' '}
                    {/*Diamond Group*/}{' '}
                  </td>
                  <td className="factory_group">FTV {/*Freetrend*/}</td>
                  <td className="factory_group">MPI {/*Metro*/}</td>
                  <td className="factory_group">
                    PWI <br /> PWI3 {/*Parkland */}
                  </td>
                  <td className="factory_group">
                    {' '}
                    PYV <br /> PHV <br /> PYS {/*Pouchen Group*/}
                  </td>
                  <td className="factory_group">
                    SA <br /> (1,2) {/*Samho Group */}
                  </td>

                  {/*specialist */}
                  <td className="factory_group">JHV {/* Jiahsin */}</td>
                  <td className="factory_group">NPI {/* NPI */}</td>
                  {/*specialist */}

                  {/* core */}
                  <td className="factory_group">ESV {/*Elite*/}</td>
                  <td className="factory_group">
                    {' '}
                    VJC <br /> VSV {/*Victory*/}
                  </td>
                  <td className="factory_group">
                    SDI <br /> SDI2 {/*SDI*/}
                  </td>
                  <td className="factory_group">YFV {/* YFV */}</td>
                  <td className="factory_group">HLV {/* HLV */}</td>
                  <td className="factory_group">GEC {/* GEC */}</td>

                  {/* core */}
                </tr>
                <tr>
                  <td className="table_header">Category</td>
                  <td className="table_header">Measurement</td>
                  <td className="table_header">TimePeriod</td>
                  <td className="table_header">DataSource</td>
                  <td className="table_header">
                    Category
                    <br />
                    Weight
                  </td>
                  <td className="table_header">
                    {filter.years[filter.years.length - 1]} NB Target
                  </td>
                  <td className="country_header">
                    China/
                    <br />
                    Vietnam {/*CJ Group*/}
                  </td>
                  <td className="country_header">
                    China/
                    <br />
                    Vietnam/ <br /> Indonesia {/*Diamond Group*/}
                  </td>
                  <td className="country_header">Vietnam {/*Freetrend*/}</td>
                  <td className="country_header">Indonesia {/*Metro*/}</td>
                  <td className="country_header">Indonesia {/*Parkland */}</td>
                  <td className="country_header">
                    Vietnam/ <br /> Indonesia {/*Pouchen Group*/}
                  </td>
                  <td className="country_header">Vietnam {/*Samho Group */}</td>
                  <td className="country_header">Vietnam {/* Jiahsin */}</td>
                  <td className="country_header">Indonesia {/* NPI */}</td>
                  <td className="country_header">Vietnam {/*Elite*/} </td>
                  <td className="country_header">
                    China/ <br /> Vietnam {/*Victory*/}
                  </td>
                  <td className="country_header">Indonesia {/*SDI*/}</td>
                  <td className="country_header">Vietnam {/*YFV*/}</td>
                  <td className="country_header">Vietnam {/*HLV*/}</td>
                  <td className="country_header">China {/*GEC*/}</td>
                </tr>
                <tr>
                  <td colSpan={8} className="score_ranking_header">
                    Lean Section Ranking
                  </td>

                  {/* ranking */}
                  {_renderRankingData(lstGroup, leanScoreCardData)}
                  {/* ranking */}
                </tr>
                <tr>
                  <td colSpan={6} className="score_overall_header">
                    Overall Score
                  </td>

                  {_renderMaxScorePotential(maxScorePotentinalData, 'SUM')}
                  {_renderNbScore(nbScoreData, 'SUM')}
                  {_renderSumScoreData(lstGroup, leanScoreCardData)}
                </tr>
                <tr>
                  <td rowSpan={6} className="col_1">
                    Lean
                  </td>
                  <td className="col_2345">PPH</td>
                  <td className="col_2345">
                    6 months rolling
                    <br />
                    weighted avg
                  </td>
                  <td className="col_2345">
                    Monthly Lean KPI
                    <br />
                    Report
                  </td>
                  {renderKpi(kpiData, 'PPH')}
                  {_renderMaxScorePotential(maxScorePotentinalData, 'PPH')}
                  {_renderNbScore(nbScoreData, 'PPH')}
                  {_renderPphLeanScoreCardData(
                    lstGroup,
                    leanScoreCardData,
                    maxKpiData,
                  )}
                </tr>
                <tr>
                  <td className="col_2345">MLT-Median</td>
                  <td className="col_2345">6 months rolling median</td>
                  <td className="col_2345">
                    Monthly Lean KPI
                    <br />
                    Report
                  </td>

                  {renderKpi(kpiData, 'MLT')}
                  {_renderMaxScorePotential(maxScorePotentinalData, 'MLT')}
                  {_renderNbScore(nbScoreData, 'MLT')}
                  {_renderMltLeanScoreCardData(
                    lstGroup,
                    leanScoreCardData,
                    maxKpiData,
                  )}
                </tr>
                <tr>
                  <td className="col_2345">
                    PO <br />
                    (&le; {mltSetPoint} days MLT)
                  </td>
                  <td className="col_2345">6 months rolling (Totals PO)</td>
                  <td className="col_2345">Monthly Lean KPI Report</td>

                  {renderKpi(kpiData, 'PO')}
                  {_renderMaxScorePotential(maxScorePotentinalData, 'PO')}
                  {_renderNbScore(nbScoreData, 'PO')}
                  {_renderPoScoreCardData(
                    lstGroup,
                    leanScoreCardData,
                    maxKpiData,
                  )}
                </tr>

                <tr className="col_2345">
                  <td className="col_2345">Lean Assessment</td>
                  <td className="col_2345">
                    Latest Score
                    <br />
                    (Q{Math.ceil(filter.month / 3)}{' '}
                    {filter.years[filter.years.length - 1]})
                  </td>
                  <td className="col_2345">Monthly Lean KPI Report</td>

                  {renderKpi(kpiData, 'LEAN_SCORE')}
                  {_renderMaxScorePotential(
                    maxScorePotentinalData,
                    'LEAN_SCORE',
                  )}

                  {_renderNbScore(nbScoreData, 'LEAN_SCORE')}
                  {_renderLeanAssessmentLeanScoreCardData(
                    lstGroup,
                    leanScoreCardData,
                    maxKpiData,
                  )}
                </tr>

                <tr>
                  <td className="col_2345">BTS</td>
                  <td className="col_2345">6 months pure average</td>
                  <td className="col_2345">Monthly Lean KPI Report</td>

                  {renderKpi(kpiData, 'BTS')}
                  {_renderMaxScorePotential(maxScorePotentinalData, 'BTS')}
                  {_renderNbScore(nbScoreData, 'BTS')}
                  {_renderBtsScoreCardData(
                    lstGroup,
                    leanScoreCardData,
                    maxKpiData,
                  )}
                </tr>
                <tr>
                  <td className="col_2345">MiniLine %</td>
                  <td className="col_2345">Latest month</td>
                  <td className="col_2345">Monthly Lean KPI Report</td>
                  {/* <td className="col_2345">2</td> */}

                  {renderKpi(kpiData, 'MINL_RATE')}
                  {_renderMaxScorePotential(
                    maxScorePotentinalData,
                    'MINL_RATE',
                  )}
                  {_renderNbScore(nbScoreData, 'MINL_RATE')}
                  {_renderMiniLineScoreCardData(
                    lstGroup,
                    leanScoreCardData,
                    maxKpiData,
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </TabPanel>
        <TabPanel header="Max Potential Score ">
          <MaxPotentialScore />
        </TabPanel>
      </TabView>

      <br />
    </>
  );
}
