// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';
import type {ApiProps} from '../../service/Api';

// import {AuthConst} from '../../service/AppConstant';
//$FlowFixMe[cannot-resolve-module];
import {UIUtils} from '../../service/Utils';
import type {TblColRender} from '../types';
import {AdminApi} from '../../service/AdminApi';
// import {LeanAuditFilter} from './LeanAuditFilter';

type Props = {
  filter: any,
};

export function TablePphOfMonth(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [dataPphOfMonth, setDataPphOfMonth] = useState([]);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  const {filter} = props;

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchDataPph = useCallback(async (options) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let {month, year} = options;

    api
      .getPphOfMonth({data: {year: year, month: month}})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;

        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        let dataTbl = data.data;

        dataTbl.sort((a, b) => {
          return b.pph - a.pph;
        });
        dataTbl.forEach((dt, index) => {
          dt['ranking'] = index + 1;
        });
        console.log(dataTbl);
        setDataPphOfMonth([...dataTbl]);
      })
      .catch((err) => {
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    let {month, years} = filter;
    let year = Number(years[years.length - 1]);
    _fetchDataPph({month: month, year: year});
  }, [filter, _fetchDataPph]);

  const _onInvsPaging = (evt: any) => {
    //   //console.log.*$
    //   setetrRecsTblLimit(evt.rows);
    //   setEtrRecsTblStart(evt.first);
  };

  const _renderPphAchi: TblColRender = (row: any, col: any) => {
    let {pphAchi} = row;
    return pphAchi ? Number(Number(pphAchi) * 100).toFixed(0) + '%' : null;
  };

  const _renderCountryCol: TblColRender = (row: any, col: any) => {
    let {factory} = row;
    return factory ? factory.country.code : '';
  };

  const _renderFactoryCol: TblColRender = (row: any, col: any) => {
    let {factory} = row;
    return factory ? factory.code : '';
  };

  const onSort = (e) => {
    let {sortField, sortOrder} = e;
    if (sortOrder === 1) {
      setDataPphOfMonth(
        [...dataPphOfMonth].sort((a, b) => a[sortField] - b[sortField]),
      );
    } else {
      setDataPphOfMonth(
        [...dataPphOfMonth].sort((a, b) => b[sortField] - a[sortField]),
      );
    }
    setSortField(sortField);
    setSortOrder(sortOrder);
  };

  return (
    <>
      <Toast ref={toastRef} />

      <DataTable
        // header={header || t('factory.title')}
        value={dataPphOfMonth}
        dataKey="ranking"
        //lazy={true}
        // paginator={true}
        //   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        // currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        // rows={factoriesTblLimit}
        // first={factoriesTblStart}
        //  totalRecords={factoriesTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        // selection={selFactory}
        //  onSelectionChange={_onFactorySelected}
        onPage={_onInvsPaging}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={onSort}>
        <Column
          field="ranking"
          header="Monthly ranking"
          headerStyle={{align: 'center'}}
        />

        <Column
          body={_renderCountryCol}
          header="Country"
          headerStyle={{align: 'center'}}
        />

        <Column
          body={_renderFactoryCol}
          header="Factory"
          headerStyle={{align: 'center'}}
        />

        <Column
          field="outSi"
          header="Output SI"
          headerStyle={{align: 'center'}}
          sortable
        />

        <Column
          field="outProds"
          header="Output EOL"
          headerStyle={{align: 'center'}}
          sortable
        />
        <Column
          field="pph"
          header="PPH - current month"
          headerStyle={{align: 'center'}}
          sortable
        />

        <Column
          field="pphYtd"
          header="PPH - Roll 6 months"
          headerStyle={{align: 'center'}}
          sortable
        />

        <Column
          field="kpi"
          header="Target PPH"
          headerStyle={{align: 'center'}}
          sortable
        />

        <Column
          body={_renderPphAchi}
          field="pphAchi"
          header="PPH Achievement %"
          headerStyle={{align: 'center'}}
          sortable
        />
      </DataTable>
    </>
  );
}
