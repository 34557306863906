// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';
//$FlowFixMe
import {debounceTime} from 'rxjs/operators';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {Chart} from 'primereact/chart';
//$FlowFixMe
import {Button} from 'primereact/button';

import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
// import {AuthUtils} from '../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {getColor} from '../../service/util/color';

import {getChartOptions} from './CommonChart';

type Props = {
  scope?: string,
  regions?: any[],
  countries?: any[],
  facGroups?: any[],
  factories?: any[],
  lines?: any[],
  year?: any,
  expanded?: boolean,
  onAction?: EvtHandler,
};

export const biglColors: any = [
  'purple-700',
  'purple-500',
  'purple-300',
  'purple-100',
  'purple-800',
  'purple-600',
  'purple-400',
  'purple-200',
  'purple-50',
  'purple-900',
].map((c) => getColor(c));

export const minlColors: any = [
  'yellow-700',
  'yellow-500',
  'yellow-300',
  'yellow-100',
  'yellow-800',
  'yellow-600',
  'yellow-400',
  'yellow-200',
  'yellow-50',
  'yellow-900',
].map((c) => getColor(c));

export const minlRmColors: any = [
  'orange-700',
  'orange-500',
  'orange-300',
  'orange-100',
  'orange-900',
].map((c) => getColor(c));

export const minlRateColors: any = [
  'green-700',
  'green-500',
  'green-300',
  'green-100',
  'green-800',
  'green-600',
  'green-400',
  'green-200',
  'green-50',
  'green-900',
].map((c) => getColor(c));

export const minlVolRateColors: any = [
  'blue-700',
  'blue-500',
  'blue-300',
  'blue-100',
  'blue-800',
  'blue-600',
  'blue-400',
  'blue-200',
  'blue-50',
  'blue-900',
].map((c) => getColor(c));

let chartOptions = getChartOptions();
// const scales = {
//   y: {
//     type: 'linear',
//     display: true,
//     position: 'left',
//   },
//   y1: {
//     type: 'linear',
//     display: true,
//     position: 'right',
//     // grid line settings
//     grid: {
//       drawOnChartArea: false, // only want the grid lines for one axis to show up
//     },
//   },
// };
// chartOptions = {
//   ...chartOptions,
//   ...scales,
// };

export function MiniLineVolBarMonthly(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();
  const {
    scope,
    regions,
    countries,
    facGroups,
    factories,
    lines,
    year,
    expanded,
    onAction,
  } = props;

  const [barData, setBarData] = useState();
  // const [chartOptions] = useState(getChartOptions());

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchData = useCallback(async (options: any) => {
    let {scope, regions, countries, facGroups, factories, year} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    scope = scope || 'region';
    let pYear = Number(year) || 0;

    let joins = [
      {
        type: 'join',
        expr: 'obj.factory',
        alias: 'fac',
      },
      {
        type: 'join',
        expr: 'fac.country',
        alias: 'coun',
      },
      {
        type: 'join',
        expr: 'coun.region',
        alias: 'reg',
      },
    ];
    let filters = {
      liveFlag: 'obj.flag > -1',
    };

    let params = {};
    let groupBys = ['obj.audMonth'];

    if (scope === 'region') {
      groupBys = ['reg.code', ...groupBys];
      regions = regions || [];
      if (regions.length > 0) {
        let regIds = Utils.getVals(regions, 'id', [0]).map((it) => {
          return {
            type: 'long',
            value: it,
          };
        });
        filters = {
          ...filters,
          byRegIds: 'reg.id in (:regIds)',
        };
        params = {
          ...params,
          regIds,
        };
      }
    } else if (scope === 'country') {
      groupBys = ['coun.code', ...groupBys];
      countries = countries || [];
      let counIds = Utils.getVals(countries || [], 'id', [0]).map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
      filters = {
        ...filters,
        byCounIds: 'coun.id in (:counIds)',
      };
      params = {
        ...params,
        counIds,
      };
    } else if (scope === 'facGroup') {
      joins = [
        ...joins,
        {
          type: '',
          expr: 'FacGroup',
          alias: 'fg',
        },
        {
          type: 'join',
          expr: 'fg.facLnks',
          alias: 'facLnk',
        },
      ];
      filters = {
        ...filters,
        jnFacGroup: 'fac.id = facLnk.factory.id',
      };
      groupBys = ['fg.code', ...groupBys];
      let fgIds = Utils.getVals(facGroups || [], 'id', [0]).map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
      filters = {
        ...filters,
        byFacGroupIds: 'fg.id in (:fgIds)',
      };
      params = {
        ...params,
        fgIds,
      };
    } else if (scope === 'factory') {
      groupBys = ['fac.code', ...groupBys];
      let facIds = Utils.getVals(factories || [], 'id', [0]).map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
      filters = {
        ...filters,
        byFacIds: 'fac.id in (:facIds)',
      };
      params = {
        ...params,
        facIds,
      };
    }
    filters = {
      ...filters,
      year: 'obj.audYear = :year',
    };
    params = {
      ...params,
      year: pYear,
    };

    let fields = [
      ...groupBys,
      'sum(obj.nbMiniLines  * obj.minlCap)',
      'sum(obj.nbBigLines * obj.biglCap)',
      'sum(obj.totalLines)',
      'avg(obj.tarMinlRate)',
      'avg(obj.minlVolRate)',
      'avg(obj.minlRate)',
    ];
    let sorts = [...groupBys];

    return await api
      .fetchMiniLineAuditData({
        fields,
        joins,
        groupBys,
        filters,
        params,
        sorts,
        start: 0,
        limit: 0,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        // //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        let recs = [...data.list];
        //console.log.*$
        recs.forEach((it) => {
          it[5] = Utils.round(it[5] * 100, 2);
          it[6] = Utils.round(it[6] * 100, 2);
          it[7] = Utils.round(it[7] * 100, 2);
          it[8] = Utils.round((it[2] / (it[2] + it[3])) * 100, 2);
        });
        return [...recs];
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  // const _fetchKpiData = useCallback(async (options: any) => {
  // return [];
  // let {scope, factories, factory, lines, line, year} = options;
  // let api: ?AdminApi = apiRef.current;
  // if (!api || scope === 'region' || scope === 'country') {
  //   return [];
  // }
  //
  // if (factories && factories.length > 0) {
  //   let pms = factories.map((fac) => {
  //     return _fetchKpiData({
  //       ...options,
  //       factory: fac,
  //       factories: [],
  //     });
  //   });
  //   let datas = await Promise.all(pms);
  //   return datas.map((it) => it[0]);
  // }
  //
  // if (lines && lines.length > 0) {
  //   let pms = lines.map((ln) => {
  //     return _fetchKpiData({
  //       ...options,
  //       line: ln,
  //       lines: [],
  //     });
  //   });
  //   let datas = await Promise.all(pms);
  //   return datas.map((it) => it[0]);
  // }
  //
  // let reqData = {
  //   scope,
  //   periodType: 'MONTH',
  //   kpiType: 'PPH',
  //   year,
  // };
  //
  // let serie = '';
  // if (factory) {
  //   reqData = {
  //     ...reqData,
  //     factoryId: factory.id,
  //   };
  //   serie = `TARGET-${factory.code}`;
  // }
  //
  // if (line) {
  //   reqData = {
  //     ...reqData,
  //     lineId: line.id,
  //   };
  //   serie = `TARGET-${line.code}`;
  // }
  //
  // return await api
  //   .loadProdKpiData({
  //     data: {...reqData},
  //   })
  //   .then((resp) => resp.data)
  //   .then((resp) => {
  //     // //console.log.*$
  //     let {errors, data} = resp;
  //     if (errors.length > 0) {
  //       //console.log.*$
  //       UIUtils.showError({errors, toast: toastRef.current});
  //       return [];
  //     }
  //     return [
  //       {
  //         ...data,
  //         serie,
  //       },
  //     ];
  //   })
  //   .catch((error) => {
  //     //console.log.*$
  //     UIUtils.showError({error, toast: toastRef.current});
  //     return [];
  //   });
  // }, []);

  const _fetchChartData = useCallback(
    async (options: any) => {
      let {scope} = options;
      switch (scope) {
        case 'factory':
          options = {
            ...options,
            line: null,
            lines: [],
          };
          break;
        default:
          break;
      }

      let months = [];
      for (let i = 0; i < 12; i++) {
        months.push(i + 1);
      }
      let labels = months.map((it) => it);
      labels = labels.map((it) => t(`mon.${it}`));

      // let pm1 = _fetchKpiData(options).then((data) => {
      //   // //console.log.*$
      //   return data.map((rec, ind) => {
      //     let ci = ind % kpiColors.length;
      //     return {
      //       type: 'line',
      //       label: rec.serie,
      //       backgroundColor: kpiColors[ci],
      //       borderColor: kpiColors[ci],
      //       borderWidth: 1,
      //       data: [...rec.targetValues],
      //     };
      //   });
      // });

      let pm2 = _fetchData(options).then((data) => {
        let series = [];
        let recs: any[] = data || [];
        recs
          .map((it) => it[0])
          .forEach((it) => {
            if (!series.includes(it)) {
              series = [...series, it];
            }
          });
        //console.log.*$

        const _evalDataPoint: (dpInd: number) => any[] = (dpInd: number) => {
          let datas = series.map((serie, ind) => {
            let dtMap = {};
            recs
              .filter((rc) => rc[0] === serie)
              .forEach((rc) => {
                dtMap[rc[1]] = rc[dpInd];
              });
            let lst = labels.map((it, ind) => {
              let val = dtMap[ind + 1];
              return val ? val : 0;
            });
            return lst;
          });
          return datas;
        };

        // let minlDatas = _evalDataPoint(2);
        // //console.log.*$
        // let minlDatasets = series.map((serie, ind) => {
        //   let ci = ind % minlColors.length;
        //   return {
        //     label: `Miniline-${serie}`,
        //     backgroundColor: minlColors[ci],
        //     data: minlDatas[ind],
        //   };
        // });

        // let biglDatas = _evalDataPoint(3);
        // //console.log.*$
        // let biglDatasets = series.map((serie, ind) => {
        //   let ci = ind % biglColors.length;
        //   return {
        //     label: `BigLine-${serie}`,
        //     backgroundColor: biglColors[ci],
        //     data: biglDatas[ind],
        //   };
        // });

        let rmDatas = _evalDataPoint(5);
        //console.log.*$
        let rmDatasets = series.map((serie, ind) => {
          let ci = ind % minlRmColors.length;
          return {
            type: 'line',
            label: `Roadmap-${serie}`,
            backgroundColor: minlRmColors[ci],
            borderColor: minlRmColors[ci],
            borderWidth: 1,
            data: rmDatas[ind],
            // yAxisID: 'y1',
          };
        });

        let minlVolDatas = _evalDataPoint(8);
        //console.log.*$
        let minlVolDatasets = series.map((serie, ind) => {
          let ci = ind % minlRateColors.length;
          return {
            // type: 'line',
            label: `Miniline %(vol)-${serie}`,
            backgroundColor: minlRateColors[ci],
            borderColor: minlRateColors[ci],
            borderWidth: 1,
            data: minlVolDatas[ind],
            // yAxisID: 'y1',
          };
        });

        // let minlRateDatas = _evalDataPoint(7);
        // //console.log.*$
        // let minlRateDatasets = series.map((serie, ind) => {
        //   let ci = ind % minlRateColors.length;
        //   return {
        //     type: 'line',
        //     label: `Miniline %-${serie}`,
        //     backgroundColor: minlRateColors[ci],
        //     borderColor: minlRateColors[ci],
        //     borderWidth: 1,
        //     data: minlRateDatas[ind],
        //     yAxisID: 'y1',
        //   };
        // });

        let chartData = {
          labels,
          datasets: [
            ...rmDatasets,
            ...minlVolDatasets,
            // ...minlRateDatasets,
            // ...biglDatasets,
            // ...minlDatasets,
          ],
        };

        // setBarData(chartData);
        return chartData;
      });

      Promise.all([pm2]).then((data) => {
        let [chartData] = data;
        let {datasets} = chartData;
        if (datasets.length > 0) {
          datasets = [...datasets];
        }
        setBarData({
          ...chartData,
          datasets,
        });
      });
    },
    [_fetchData, t],
  );

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchChartData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      // //console.log.*$
      subj.complete();
    };
  }, [_fetchChartData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      scope,
      regions,
      countries,
      facGroups,
      factories,
      lines,
      year,
    });
  }, [scope, regions, countries, facGroups, factories, lines, year]);

  const _onToggleExpand: EvtHandler = (evt: any) => {
    onAction &&
      onAction({
        type: 'toggleExpand',
        value: !expanded,
      });
  };

  const iconName = expanded
    ? 'pi pi-angle-double-left'
    : 'pi pi-angle-double-right';

  const icons = (
    <Button
      icon={iconName}
      className="p-button-rounded p-button-text"
      style={{padding: 0, height: '1.2rem', color: '#FFFFFF'}}
      onClick={_onToggleExpand}
    />
  );

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="Mini Line % (Volume) - Monthly" icons={icons}>
        <Chart type="bar" data={barData} options={chartOptions}></Chart>
      </Panel>
    </>
  );
}
