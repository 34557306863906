// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
// import {AutoComplete} from 'primereact/autocomplete';
import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
//$FlowFixMe[cannot-resolve-module]
import {AutoComplete} from 'primereact/autocomplete';

type Props = {
  readOnly?: boolean,
  leanAudItem: any,
  onAction: (evt: any) => void,
};

const defLeanAudItem = {
  __cls__: 'com.nb.lmrs.model.entity.QuickChangeOverAudItem',
  flag: 0,
  code: '',
  name: '',
  description: '',
  processType: 'C2B',
  applicable: false,
  tarScore: 0,
  c2bScore: null,
  treatmentScore: null,
  outsolePressScore: null,
  weight: 0,

};

export function QuickChangeOverAudItemForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {readOnly, leanAudItem} = props;
  const [editLeanAudItem, setEditLeanAudItem] = useState<any>();
  const [suggDepartments, setSuggDepartments] = useState([]);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let obj = leanAudItem || {};
    obj = {
      ...defLeanAudItem,
      ...obj,

    };
    let {c2bScore, treatmentScore, outsolePressScore} = obj;
    if (c2bScore !== undefined && c2bScore !== null) {
        obj.c2bScore = c2bScore + '';
    }
    if (treatmentScore !== undefined && treatmentScore !== null) {
        obj.treatmentScore = treatmentScore + '';
    }
    if (outsolePressScore !== undefined && outsolePressScore !== null) {
        obj.outsolePressScore = outsolePressScore + '';
    }

    setEditLeanAudItem(obj);
  }, [leanAudItem]);





  const _validateLeanAudItem: () => any = () => {
    let obj = {...editLeanAudItem};
    obj = Utils.adjustPercField(
      obj,
      ['evaluation', 'c2bWg', 'sfWg', 'offlineWg', 'osPressWg'],
      false,
    );
    console.log(obj);
    // obj.rate = Number(obj.rate);
    return obj;
  };

  const _onSave: EvtHandler = (evt: any) => {
    // setShowCfmDlg(false);
    let fac = _validateLeanAudItem();
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update leanAudItem type?'
        : 'Are you sure you want to create leanAudItem type?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateQuickOverChangeAuditItem({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showWarning({
                summary: 'Warning',
                detail: String(errors[0].message),
                toast: toastRef.current,
              });
              let {onAction} = props;
              onAction &&
                onAction({
                  type: 'auditItemSaved',
                  value: data.entity,
                });
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'auditItemSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _onSuggDepartments = () => {
    setSuggDepartments([
      {
        label: '0',
        value: '0',
      },
      {
        label: '0.5',
        value: '0.5',
      },
      {
        label: '1',
        value: '1',
      },
    ]);
  };

  if (!editLeanAudItem) {
    return null;
  }

  const saveLbl = editLeanAudItem.id
    ? 'Update leanAudItem type'
    : 'Create leanAudItem type';
  const isNew = !editLeanAudItem.id;
  // //console.log.*$

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/edit leanAudItem type">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editLeanAudItem.code}
                onChange={(e) => {
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    code: e.target.value,
                  });
                }}
                disabled={!isNew || readOnly}
              />
              <label>Code</label>
            </span>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editLeanAudItem.name}
                onChange={(e) => {
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    name: e.target.value,
                  });
                }}
                // onKeyDown={_onKeyDown}
                disabled={readOnly}
              />
              <label>Name</label>
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <AutoComplete
                placeholder="Select C2B score"
                dropdown
                suggestions={suggDepartments}
                field="label"
                value={editLeanAudItem.c2bScore}
                completeMethod={_onSuggDepartments}
                onChange={(e) =>
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    c2bScore: e.value.value,
                  })
                }
                disabled={readOnly}
              />
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <AutoComplete
                placeholder="Select Treatment score"
                dropdown
                suggestions={suggDepartments}
                field="label"
                value={editLeanAudItem.treatmentScore}
                completeMethod={_onSuggDepartments}
                onChange={(e) =>
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    treatmentScore: e.value.value,
                  })
                }
                disabled={readOnly}
              />
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <AutoComplete
                placeholder="Select Outsole press score"
                dropdown
                suggestions={suggDepartments}
                field="label"
                value={editLeanAudItem.outsolePressScore}
                completeMethod={_onSuggDepartments}
                onChange={(e) =>
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    outsolePressScore: e.value.value,
                  })
                }
                disabled={readOnly}
              />
            </span>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <InputTextarea
                rows={2}
                value={editLeanAudItem.comment}
                onChange={(e) => {
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    comment: e.target.value,
                  });
                }}
                disabled={readOnly}
              />
              <label>Observation/Comment</label>
            </span>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
