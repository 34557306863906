// @flow
import React, {useEffect, useRef, useState} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
//import {useHistory} from 'react-router-dom';

//$FlowFixMe
import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
//import {Toast} from 'primereact/toast';
// import {TabView, TabPanel} from 'primereact/tabview';

//$FlowFixMe[cannot-resolve-module]
//import {Button} from 'primereact/button';

import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
// import {AuthUtils} from '../service/AuthUtils';
import {Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
// import {updateAppAction} from '../../service/app/action';
import {PphMonthlyMd} from './PphMonthlyMd';
import {ModelFilter} from './ModelFilter';

// import {PphDailyData} from './PphDailyData';
// import {PphWeeklyData} from './PphWeeklyData';
// import {PphMonthlyData} from './PphMonthlyData';

type Props = {
  scope?: string,
  filter?: any,
  onAction?: (evt: any) => void,
};

export function RegionOverView1(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  //const globalRef = useSelector((stat) => stat.global);
  // const history = useHistory();
  // const dispatch = useDispatch();

  const [pphDailyExpanded, setPphDailyExpanded] = useState(false);
  // const [pphWeeklyExpanded, setPphWeeklyExpanded] = useState(false);
  //const [pphMonthlyExpanded, setPphMonthlyExpanded] = useState(false);

  const {onAction, filter} = props;
  const scope = props.scope || 'region';

  //const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
  }, [filter, scope]);

  const _onFilterAction: EvtHandler = (evt: any) => {
    // //console.log.*$
    let {type, value} = evt;
    //console.log.*$
    if (type === 'filterChanged') {
      // setFilter({...value});
      onAction &&
        onAction({
          type,
          value: {
            ...value,
            scope,
          },
        });
    }
  };

  /*const _onShowDetails: EvtHandler = (evt: any) => {
    onAction &&
      onAction({
        type: 'showDetail',
        value: {
          scope,
        },
      });
  };*/

  /*const _handleLeanScoreChartEvent: EvtHandler = (evt: any) => {
    let {type} = evt;
    if (!filter) {
      return;
    }
    // //console.log.*$
    switch (type) {
      case 'chartClick':
        let facs = filter.factories || [];
        let factory = facs.length > 0 ? facs[0] : undefined;
        let chartFilter = {
          ...filter,
          years: [filter.year],
          factory,
        };
        let facGroups = filter.facGroups || [];
        chartFilter = {
          ...chartFilter,
          facGroups,
        };
        //console.log.*$
        // //console.log.*$
        if (globalRef) {
          globalRef['chartFilter'] = {...chartFilter};
        }
        // dispatch(updateAppAction({chartFilter}));
        // let filStr: string = JSON.stringify('chartFilter') || '';
        let filStr = btoa('chartFilter');
        //console.log.*$
          'Lean score chart clicked // redirect to audit page: ',
          filStr,
        );
        setTimeout(() => {
          history.push(`/lean/leanaudits/${filStr}`);
        }, 100);
        break;
      default:
        break;
    }
  };*/

  /* const _onChartAction: EvtHandler = (evt: any) => {
    //console.log.*$
    let {src} = evt;
    switch (src) {
      case 'leanScoreQuarterly':
        _handleLeanScoreChartEvent(evt);
        break;
      default:
        break;
    }
  };*/

  if (!filter) {
    return null;
  }

  let {year, month, regions, countries, facGroups, factories, lines, models} =
    filter;
  // //console.log.*$

  if (
    !year ||
    (scope === 'country' && Utils.isEmpty(regions)) ||
    (scope === 'factory' && Utils.isEmpty(countries))
  ) {
    return null;
  }

  /* let showDetailBtn =
    (scope === 'region' && regions && regions.length > 0) ||
    (scope === 'country' && countries && countries.length > 0) ||
    (scope === 'factory' && factories && factories.length > 0);*/
  let scRegion = scope === 'region';
  let scCountry = scope === 'country';
  let scGroup = scope === 'facGroup';
  let scFactory = scope === 'factory';
  //let scLine = scope === 'line';
  //let scModel = scope === 'model';
  //let scModel = 'model';
  // //console.log.*$
  // let filter2: any = filter;
  // let {regions} = filter2;
  // //console.log.*$
  // //console.log.*$
  // //console.log.*$
  return (
    <>
      <ModelFilter
        filter={filter}
        showRegion={scRegion}
        multiRegions={true}
        showCountry={scCountry}
        multiCountries={true}
        showFacGroup={scGroup}
        multiFacGroups={true}
        showFactory={scFactory}
        multiFactories={true}
        // showLine={scLine}
        // multiLines={true}
        showModel={true}
        multiModels={true}
        showYear={true}
        showMonth={true}
        onAction={_onFilterAction}
      />
      <div className="grid">
        {/* <div
          className={classNames({
            'col-12': true,
            'xl:col-4': !pphDailyExpanded,
          })}>
             <PphDailyMd
            scope={scope}
            regions={regions}
            countries={countries}
            facGroups={facGroups}
            factories={factories}
            lines={lines}
            models={models}
            year={year}
            month={month}
            expanded={pphDailyExpanded}
            onAction={(e) => {
              let {type} = e;
              if (type === 'toggleExpand') {
                setPphDailyExpanded(!pphDailyExpanded);
              }
            }} />
        </div> */}

        {/* <div
          className={classNames({
            'col-12': true,
            'xl:col-4': !pphDailyExpanded,
          })}>
             <PphWeeklyMd
            scope={scope}
            regions={regions}
            countries={countries}
            facGroups={facGroups}
            factories={factories}
            lines={lines}
            models={models}
            year={year}
            month={month}
            expanded={pphDailyExpanded}
            onAction={(e) => {
              let {type} = e;
              if (type === 'toggleExpand') {
                setPphDailyExpanded(!pphDailyExpanded);
              }
            }} />
        </div> */}

        <div
          className={classNames({
            'col-12': true,
            'xl:col-4': !pphDailyExpanded,
          })}>
          <PphMonthlyMd
            scope={scope}
            regions={regions}
            countries={countries}
            facGroups={facGroups}
            factories={factories}
            lines={lines}
            models={models}
            year={year}
            month={month}
            expanded={pphDailyExpanded}
            onAction={(e) => {
              let {type} = e;
              if (type === 'toggleExpand') {
                setPphDailyExpanded(!pphDailyExpanded);
              }
            }}
          />
        </div>
      </div>
    </>
  );
}
