// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';
//$FlowFixMe
import {debounceTime} from 'rxjs/operators';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {Chart} from 'primereact/chart';
//$FlowFixMe
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../service/AuthUtils';
import type {EvtHandler} from '../../types';
import {UIUtils, Utils} from '../../../service/Utils';
import {AdminApi} from '../../../service/AdminApi';
import {pphColors, siColors, kpiColors, getChartOptions1} from '../CommonChart';

type Props = {
  scope?: string,
  regions?: any[],
  countries?: any[],
  facGroups?: any[],
  factories?: any[],
  lines?: any[],
  year?: any,
  month?: any,
  expanded?: boolean,
  onAction?: EvtHandler,
};

export function PphWeeklyFacV2(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {
    scope,
    regions,
    countries,
    facGroups,
    factories,
    lines,
    year,
    month,
    expanded,
    onAction,
  } = props;

  const [barData, setBarData] = useState();
  const [chartOptions, setChartOptions] = useState(getChartOptions1());

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  // const _apiErrorHandler = useCallback((errInfo: any) => {
  //   UIUtils.showError({...errInfo, toast: toastRef.current});
  // }, []);

  const _fetchMonthInfo = useCallback(async (options) => {
    let {year, month} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return {};
    }
    year = year || 0;
    month = month || 0;
    return await api
      .getMonthInfo({
        year,
        month,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        // //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return {};
        }
        return {...data};
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return {};
      });
  }, []);

  const _fetchData = useCallback(async (options) => {
    let {factories, year, month} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return [];
    }
    // let di = Utils.getDateInfo(new Date());
    let pYear = year || 0;
    let pMonth = month || 0;

    // let pYear =
    let joins = [
      {
        type: '',
        expr: 'Factory',
        alias: 'fac',
      },
      {
        type: 'join',
        expr: 'fac.country',
        alias: 'coun',
      },
      {
        type: 'join',
        expr: 'coun.region',
        alias: 'reg',
      },
    ];
    let filters = {
      liveFlag: 'obj.flag > -1',
      jnFac: 'fac.id = obj.factoryId',
    };
    let params = {};
    let groupBys = [];

    if (pMonth > 0) {
      groupBys = [...groupBys, 'obj.wom'];
      filters = {
        ...filters,
        month: 'obj.month = :month',
      };
      params = {
        ...params,
        month: Number(pMonth),
      };
    } else {
      groupBys = [...groupBys, 'obj.woy'];
    }

    let facIds = Utils.getVals(factories || [], 'id', [0]).map((it) => {
      return {
        type: 'long',
        value: it,
      };
    });
    filters = {
      ...filters,
      byFacIds: 'obj.factoryId in (:facIds)',
    };
    params = {
      ...params,
      facIds,
    };

    filters = {
      ...filters,
      year: 'obj.year = :year',
    };
    params = {
      ...params,
      year: Number(pYear),
    };

    let fields = [
      ...groupBys,
      'sum(obj.nbProds)',
      'sum(obj.outSi)',
      'sum(obj.nbHours)',
    ];
    //console.log.*$
    let sorts = [];
    return await api
      .fetchProdRecData({
        fields,
        joins,
        groupBys,
        filters,
        params,
        sorts,
        start: 0,
        limit: 0,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        // //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        let lst = [...data.list];
        //console.log.*$
        lst.forEach((it) => {
          let v1 = 0;
          let v2 = 0;
          let hours = it[3];
          if (hours > 0) {
            v1 = Utils.round((1.0 * it[1]) / hours);
            v2 = Utils.round((1.0 * it[2]) / hours);
          }
          it.splice(4, 0, v1, v2);
        });
        return [...lst];
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchKpiData = useCallback(async (options: any) => {
    let {factories, year, month} = options;

    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return [];
    }
    let factoryIds = factories.map((fac) => fac.id);

    //console.log.*$

    let reqData = {
      scope: 'factory',
      periodType: 'WEEK',
      kpiType: 'PPH',
      year,
      month,
      factoryIds,
    };

    return await api
      .loadProdKpiDataByMultiFactory({
        data: {...reqData},
      })
      .then((resp) => resp.data)
      .then((resp) => {
        // //console.log.*$
        let {errors, data} = resp;
        //console.log.*$
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        return [
          {
            ...data,
          },
        ];
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  //  const _fetchKpiRegion = useCallback(async (options) =>{
  //   let {month, year, scope, regions, countries, facGroups} = options;
  //  let api: ?AdminApi = apiRef.current;
  //  if (!api
  //    ) {
  //    return [];
  //  }
  //  let reqData = {
  //    month,
  //    year,

  //    kpiType: "PPH",
  //    periodType: "DAY"
  //  }

  //  if(scope === 'region' && regions && regions.length > 0){
  //    let pms = regions.map((reg) => {
  //      let serie = `Target-${reg.code}`;
  //      return api
  //      .loadProdKpiData({
  //        data: {
  //                 ...reqData,
  //                 scope: 'region',
  //                 regionId: reg.id
  //             },
  //      })
  //      .then((resp) => resp.data)
  //      .then((resp) => {
  //        // //console.log.*$
  //        let {errors, data} = resp;
  //        //console.log.*$
  //        if (errors.length > 0) {
  //          //console.log.*$
  //          UIUtils.showError({errors, toast: toastRef.current});
  //          return [];
  //        }
  //        return [
  //          {
  //            ...data,
  //            serie,

  //          },
  //        ];
  //      })
  //      .catch((error) => {
  //        //console.log.*$
  //        UIUtils.showError({error, toast: toastRef.current});
  //        return [];
  //      });

  //    });

  //    let datas = await Promise.all(pms);
  //    //console.log.*$
  //    return datas.map((it) => it[0]);

  //  } else if(scope === 'country' &&  countries && countries.length > 0){
  //    let pms = countries.map((coun) => {
  //      let serie = `Target-${coun.code}`;
  //      return api
  //      .loadProdKpiData({
  //        data: {
  //                 ...reqData,
  //                 scope: 'country',
  //                 countryId: coun.id
  //             },
  //      })
  //      .then((resp) => resp.data)
  //      .then((resp) => {
  //        // //console.log.*$
  //        let {errors, data} = resp;
  //        //console.log.*$
  //        if (errors.length > 0) {
  //          //console.log.*$
  //          UIUtils.showError({errors, toast: toastRef.current});
  //          return [];
  //        }
  //        return [
  //          {
  //            ...data,
  //            serie,

  //          },
  //        ];
  //      })
  //      .catch((error) => {
  //        //console.log.*$
  //        UIUtils.showError({error, toast: toastRef.current});
  //        return [];
  //      });

  //    });

  //    let datas = await Promise.all(pms);
  //    //console.log.*$
  //    return datas.map((it) => it[0]);

  //  } else if(scope === 'facGroup' && facGroups && facGroups.length > 0 ){
  //   let pms = facGroups.map((facGroup) => {
  //     let serie = `Target-${facGroup.code}`;
  //     return api
  //     .loadProdKpiData({
  //       data: {
  //                ...reqData,
  //                scope: 'facgroup',
  //                facGroupId: facGroup.id
  //            },
  //     })
  //     .then((resp) => resp.data)
  //     .then((resp) => {
  //       // //console.log.*$
  //       let {errors, data} = resp;
  //       //console.log.*$
  //       if (errors.length > 0) {
  //         //console.log.*$
  //         UIUtils.showError({errors, toast: toastRef.current});
  //         return [];
  //       }
  //       return [
  //         {
  //           ...data,
  //           serie,

  //         },
  //       ];
  //     })
  //     .catch((error) => {
  //       //console.log.*$
  //       UIUtils.showError({error, toast: toastRef.current});
  //       return [];
  //     });

  //   });

  //   let datas = await Promise.all(pms);
  //   //console.log.*$
  //   return datas.map((it) => it[0]);

  //  } else {
  //    return [];
  //  }

  // },[])
  const _fetchChartData = useCallback(
    async (options: any) => {
      let monInfo = await _fetchMonthInfo(options);
      // //console.log.*$
      let labels = [];
      for (let i = 0; i < monInfo.weeks; i++) {
        labels.push(`w ${i + 1}`);
      }

      //console.log.*$
      let pm1 = _fetchKpiData(options).then((rec) => {
        //console.log.*$
        let datasets = [
          {
            type: 'line',
            label: 'Target',
            backgroundColor: kpiColors[0],
            borderColor: kpiColors[0],
            borderWidth: 1,
            data: [...rec[0].targetValues],
          },
        ];

        let kpiDataChart = {
          datasets,
        };

        return kpiDataChart;
      });

      let pm2 = _fetchData(options).then((recs) => {
        //console.log.*$
        let dataPPhSis = [];
        let dataPPhEols = [];
        labels.forEach((label, ind) => {
          let dtFil = recs.filter((rec) => {
            return rec[0] === ind + 1;
          });
          if (dtFil && dtFil.length > 0) {
            dataPPhSis.push(dtFil[0][5]);
            dataPPhEols.push(dtFil[0][4]);
          } else {
            dataPPhSis.push(0);
            dataPPhEols.push(0);
          }
        });

        let datasets = [
          {
            label: 'EOL',
            data: dataPPhEols,
            fill: false,
            backgroundColor: pphColors[0],
          },

          {
            label: 'SI',
            data: dataPPhSis,
            fill: false,
            backgroundColor: siColors[0],
          },
        ];

        let chartData = {
          datasets,
        };

        return chartData;
      });

      Promise.all([pm1, pm2]).then((data) => {
        let [kpiData, chartData] = data;
        let datasets = [...kpiData.datasets, ...chartData.datasets];
        if (datasets.length > 0) {
          const dataArrays = datasets.map((item) => item.data).flat();
          let arr = Utils.findMinMax(dataArrays);
          let chartOptionsNew = {
            ...chartOptions,
            scales: {
              ...chartOptions.scales,
              y: {
                ...chartOptions.scales.y,

                //   min: arr[0] < 0.2 ? arr[0] : Utils.round(arr[1] - 0.2, 1),
                max: Utils.round(arr[1] + 0.1, 1),
              },
            },
          };

          setChartOptions(chartOptionsNew);
        }
        let dataChart = {
          labels,
          datasets: datasets,
        };
        setBarData(dataChart);
      });
    },
    [_fetchMonthInfo, _fetchData, _fetchKpiData, chartOptions],
  );

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchChartData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      // //console.log.*$
      subj.complete();
    };
  }, [_fetchChartData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      scope,
      regions,
      countries,
      facGroups,
      factories,
      lines,
      year,
      month,
    });
  }, [scope, regions, countries, facGroups, factories, lines, year, month]);

  const _onToggleExpand: EvtHandler = (evt: any) => {
    onAction &&
      onAction({
        type: 'toggleExpand',
        value: !expanded,
      });
  };

  const iconName = expanded
    ? 'pi pi-angle-double-left'
    : 'pi pi-angle-double-right';

  const icons = (
    <Button
      icon={iconName}
      className="p-button-rounded p-button-text"
      style={{padding: 0, height: '1.2rem', color: '#FFFFFF'}}
      onClick={_onToggleExpand}
    />
  );

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="PPH Weekly" icons={icons}>
        <Chart type="bar" data={barData} options={chartOptions}></Chart>
      </Panel>
    </>
  );
}
