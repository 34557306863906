// @flow
import type {
  ApiProps,
  PostType,
  DelType,
  QueryObjsType,
  SendObjType,
} from './Api';
import {Api} from './Api';

const API_MONTH_INFO = '/app/month-info';
const API_APP_REPORT = '/app/report';
const API_DOWNLOAD = '/download/{file}';
const API_IMAGE = '/image/{file}';

const API_APP_CONFIG = '/appconfigs';
const API_APP_CONFIG_UPDATE = '/appconfig/update';
//const API_APP_CONFIG_DELETE = '/appconfigs/{id}';
//const API_APP_CONFIG_GET = '/appconfigs/{id}';

const API_AUDITLOGS = '/auditlogs';
const API_AUDITLOG_DATA = '/auditlogs/{id}/data';

const API_PROFILES = '/profiles';
const API_PROFILE_UPDATE = '/profile/update';
const API_PROFILE_DELETE = '/profiles/{id}';
const API_PROFILE_LINK_ROLES = '/profiles/{id}/link-roles';
const API_PROFILE_UNLINK_ROLES = '/profiles/{id}/unlink-roles';

const API_REPORT_TPLS = '/reporttpls';
const API_REPORT_TPL_UPDATE = '/reporttpl/update';
const API_REPORT_TPL_UPLOAD = '/reporttpls/{id}/upload';
const API_REPORT_TPL_DELETE = '/reporttpls/{id}';
const API_REPORT_TPL_GETFILES = '/reporttpls/{id}';
const API_REPORT_TPL_DELFILES = '/reporttpl/delete-files';

const API_ROLES = '/roles';
const API_ROLE_UPDATE = '/role/update';
const API_ROLE_DELETE = '/roles/{id}';
// const API_ROLE_ASSIGNPERMS = '/roles/{id}/assignperms';
const API_ROLE_LINK_PERMS = '/roles/{id}/link-perms';
const API_ROLE_UNLINK_PERMS = '/roles/{id}/unlink-perms';

const API_SECPERMS = '/secperms';
const API_SECPERM_UPDATE = '/secperm/update';
const API_SECPERM_DELETE = '/secperms/{id}';

const API_USERS = '/users';
const API_USER_UPDATE = '/user/update';
const API_USER_DELETE = '/users/{id}';
const API_USER_CHANGEPWD = '/user/changepwd';

const API_USERGRPS = '/usergroups';
const API_USERGRP_UPDATE = '/usergroup/update';
const API_USERGRP_DELETE = '/usergroups/{id}';
const API_USERGRP_ROLES = '/usergroups/{id}/roles';
const API_USERGRP_USERS = '/usergroups/{id}/users';
const API_USERGRP_LINK_ROLES = '/usergroups/{id}/link-roles';
const API_USERGRP_UNLINK_ROLES = '/usergroups/{id}/unlink-roles';
const API_USERGRP_LINK_USERS = '/usergroups/{id}/link-users';
const API_USERGRP_UNLINK_USERS = '/usergroups/{id}/unlink-users';

const API_COUNTRIES = '/countries';
const API_COUNTRY_UPDATE = '/country/update';
const API_COUNTRY_DELETE = '/countries/{id}';

const API_REGIONS = '/regions';
const API_REGION_UPDATE = '/region/update';
const API_REGION_DELETE = '/regions/{id}';

const API_FACTORIES = '/factories';
const API_FACTORY_UPDATE = '/factory/update';
const API_FACTORY_DELETE = '/factories/{id}';

const API_FACTORYUSERS = '/factoryusers';
const API_FACTORYUSER_UPDATE = '/factoryuser/update';
const API_FACTORYUSER_DELETE = '/factoryusers/{id}';

const API_FACGROUPS = '/facgroups';
const API_FACGROUP_UPDATE = '/facgroup/update';
const API_FACGROUP_DELETE = '/facgroups/{id}';
const API_FACGROUP_LINK_FACS = '/facgroups/{id}/link-factories';
const API_FACGROUP_UNLINK_FACS = '/facgroups/{id}/unlink-factories';

const API_MACHLINES = '/machlines';
const API_MACHLINE_UPDATE = '/machline/update';
const API_MACHLINE_DELETE = '/machlines/{id}';

const API_OPERATORS = '/operators';
const API_OPERATOR_UPDATE = '/operator/update';
const API_OPERATOR_DELETE = '/operators/{id}';

const API_SHIFTS = '/shifts';
const API_SHIFT_UPDATE = '/shift/update';
const API_SHIFT_DELETE = '/shifts/{id}';

const API_PRODKPIS = '/prodkpis';
const API_PRODKPI_UPDATE = '/prodkpi/update';
const API_PRODKPI_DELETE = '/prodkpis/{id}';
const API_PRODKPI_KPI_DATA = '/prodkpi/kpi-data';
const API_PRODKPI_DATA_GROUP = '/prodkpi/kpi-data-group';
const API_PRODKPI_MULTIFAC = '/prodkpi/loadkpibymultifac';

const API_MACHMODELS = '/machmodels';
const API_MACHMODEL_UPDATE = '/machmodel/update';
const API_MACHMODEL_DELETE = '/machmodels/{id}';

const API_PRODRECS = '/prodrecs';
const API_PRODREC_UPDATE = '/prodrec/update';
const API_PRODREC_DELETE = '/prodrecs/{id}';
const API_PRODREC_UPLOAD = '/prodrec/upload';
const API_PRODREC_APPROVE_PRODRECS = '/prodrec/approve-prodrecs';
const API_PRODREC_APPROVE_PRODRECS_V2 = '/prodrec/approve-prodrecsv2';
const API_PRODREC_DELETE_PRODRECS = '/prodrec/delete-prodrecs';
const API_PRODREC_FETCH_DATA = '/prodrec/fetch-data';
const API_PRODREC_SOURCING_METRICS = '/prodrec/sourcingmetrics';
const API_PRODREC_GET_PPH_ROLL_6_MONTHS = '/prodrec/getpphroll6months';
const API_PRODREC_GET_PPH_MONTHLY = '/prodrec/getpphmonthly';
const API_PRODREC_GET_PPH_YTD = '/prodrec/getpphytd';

const API_PRODRECBTS_UPLOAD = '/prodrecbts/upload';
const API_PRODRECSBTSS = '/prodrecbtss';
const API_PRODREC_APPROVE_PRODRECSBTSS = '/prodrecbtss/approve-prodrecs';
const API_PRODRECSBTS_DELETE_PRODRECSBTSS = '/prodrecbts/delete-prodrecs';
const API_PRODRECSBTS_UPDATE = '/prodrecbts/update';
const API_GET_PRODRECSBTS_BY_MONTH = '/prodrecbts/getprodrecbtsbymonth';
const API_PRODRECSBTS_DELELTE = '/prodrecbtss/{id}';
const API_GET_APPROVAL_PROCESS = '/prodrecbts/getapprovalprocess';

const API_LINEGROUPS = '/linegroups';
const API_LINEGROUP_UPDATE = '/linegroup/update';
const API_LINEGROUP_DELETE = '/linegroups/{id}';

const API_ERRORRECS = '/errorrecs';
const API_ERRORREC_UPDATE = '/errorrec/update';
const API_ERRORREC_DELETE = '/errorrecs/{id}';

const API_MACHERRORS = '/macherrors';
const API_MACHERROR_UPDATE = '/macherror/update';
const API_MACHERROR_DELETE = '/macherrors/{id}';

const API_PRODRECMD_DELETE = '/prodrecsmd/{id}';
const API_PRODRECMD_UPLOAD = '/prodrecmd/upload';
const API_PRODRECSMD = '/prodrecsmd';
const API_PRODRECMD_APPROVE_PRODRECSMD = '/prodrecmd/approve-prodrecsmd';
const API_PRODRECMD_DELETE_PRODRECSMD = '/prodrecmd/delete-prodrecsmd ';
const API_PRODRECMD_FECTH_DATA = '/prodrecmd/fetch-data';
const API_PRODRECMD_UPDATE = '/prodrecmd/update';

////////////////////////////////////////////////////////////////////////////////
// Lean
////////////////////////////////////////////////////////////////////////////////
const API_AUDMEASURES = '/audmeasures';
const API_AUDMEASURE_UPDATE = '/audmeasure/update';
const API_AUDMEASURE_DELETE = '/audmeasures/{id}';
const API_AUDMEASURE_FETCH_DATA = '/audmeasure/fetch-data';

const API_LEANAUDITS = '/leanaudits';
const API_LEANAUDIT_FETCH_DATA = '/leanaudit/fetch-data';
const API_LEANAUDIT_UPDATE = '/leanaudit/update';
const API_LEANAUDIT_DELETE = '/leanaudits/{id}';
const API_LEANAUDIT_GET_LEANSCORE = '/leanauditem/getleanscore';

const API_LEANAUD_ITEMS = '/leanauditems';
const API_LEANAUD_ITEM_UPDATE = '/leanauditem/update';
const API_LEANAUD_ITEM_DELETE = '/leanauditems/{id}';
const API_LEANAUD_ITEM_FETCH_DATA = '/leanauditem/fetch-data';

const API_PPHROADMAPS = '/pphroadmaps';
const API_PPHROADMAP_UPDATE = '/pphroadmap/update';
const API_PPHROADMAP_DELETE = '/pphroadmaps/{id}';
const API_PPHROADMAP_FETCH_DATA = '/pphroadmap/fetch-data';

const API_MLTRECS = '/extsimltdatas';
const API_MLTREC_UPDATE = '/extsimltdata/update';
const API_MLTREC_DELETE = '/extsimltdatas/{id}';
const API_MLTREC_IMPORT_MLT = '/extsimltdata/import-mlt';
const API_MLTREC_APPROVE_MLTRECS = '/extsimltdata/approve-mltrecs';
const API_MLTREC_DELETE_MLTRECS = '/extsimltdata/delete-mltrecs';
const API_MLTREC_FETCH_DATA = '/extsimltdata/fetch-data';
const API_MEDIAN_MLT = '/extsimltdata/getytdmlt';
const API_POCOMPLETION = '/extsimltdata/getpocompletion';
const API_GET_MLT_ROLL_6_MONTHS = '/extsimltdata/getytdmltroll6months';

const API_MLTROADMAPS = '/mltroadmaps';
const API_MLTROADMAP_UPDATE = '/mltroadmap/update';
const API_MLTROADMAP_DELETE = '/mltroadmaps/{id}';
const API_MLTROADMAP_FETCH_DATA = '/mltroadmap/fetch-data';

const API_LEANREPORTS = '/leanreports';
const API_LEANREPORT_UPDATE = '/leanreport/update';
const API_LEANREPORT_DELETE = '/leanreports/{id}';
const API_LEANREPORT_FETCH_DATA = '/leanreport/fetch-data';

const API_MINILINEAUDITS = '/minilineaudits';
const API_MINILINEAUDIT_UPDATE = '/minilineaudit/update';
const API_MINILINEAUDIT_DELETE = '/minilineaudits/{id}';
const API_MINILINEAUDIT_FETCH_DATA = '/minilineaudit/fetch-data';

const API_LEAN_ACTIVITIES = '/leanactivities';
const API_LEAN_ACTIVITY_UPDATE = '/leanactivity/update';
const API_LEAN_ACTIVITY_DELETE = '/leanactivities/{id}';

const API_LEAN_SCORE_CARD_UPDATE = '/leanscorecard/update';
const API_LEAN_SCORE_CARD = '/leanscorecard';
const API_LEAN_SCORE_CARD_DELETE = '/leanscorecard/{id}';
const API_GET_YTD_PPH = '/leanscorecard/getpphytdkpi';
const API_GET_LEAN_ASSESSMENT_KPI = '/leanscorecard/getleanassessmentkpi';

const API_LEAN_SCORE_RES = '/getleanscorepph';
const API_LEAN_SCORE_MLT_RES = '/getleanscoremlt';
const API_MINILINE_SCORE = '/getleanscoreminiline';
const API_LEAN_ASSESSMENT_SCORE = '/getleanscoreassessment';

const API_GET_PPHWEIGHTED = '/pphweighted';

const API_GET_PPHREPORT_FACTORY = '/pphreportbyfac';
const API_GET_PPHWEIGHTED_MULTI_FACTORY = '/getweightedreportbymultifactory';

const API_FILTERMLT = '/filtermlts';
const API_FILTERMLT_UPDATE = '/filtermlts/update';
const API_FILTERMLT_DELETE = '/filtermlts/{id}';

const API_TABLE_PPH_OF_MONTH = '/getpphofmonth';

const API_ROLL_6_MONTH = '/getPphRoll6Months';

const API_LEAN_MAX_SCORE_UPDATE = '/maxscorepotential/update';
const API_LEAN_MAX_SCORE = '/maxscorepotentials';
const API_LEAN_MAX_DELETE = '/maxscorepotential/{id}';

const API_MLT_SETPOINT_UPDATE = '/mltsetpoint/update';
const API_MLT_SETPOINTS = '/mltsetpoints';
const API_MLT_POINTS_DELETE = '/mltsetpoints/{id}';

const API_GET_LEAN_SCORE_CARD_REPORT = '/getleanscorecard';

const API_WARNING_THRESHOLD_UPDATE = '/warningthreshold/update';
const API_WARNING_THRESHOLDS = '/warningthresholds';
const API_WARNING_THRESHOLD_DELETE = '/warningthreshold/{id}';


const API_ALARMS = '/alarms';
const API_ANDON_DETAILS = '/andondetails';
const API_ANDON_DETAIL_DELETE = '/andondetails/{id}';
const API_ANDON_DETAIL_UPDATE = '/andondetail/update';

const API_ANDON_MEASURES = '/andonmeasures';
const API_ANDON_MEASURE_UPDATE = '/andonmeasure/update';
const API_ANDON_MEASURE_DELETE = '/andonmeasures/{id}';

const API_ANDON_AUDIT_UPDATE = '/andonaudit/update';
const API_ANDON_AUDITS = '/andonaudits';
const API_ANDON_AUDIT_DELETE = '/andonaudits/{id}';
const API_SUBMIT_ANDON_AUDIT_TO_NB_LVL_2 = '/andonaudit/submittonblvl2';
const API_SUBMIT_ANDON_AUDIT_TO_NB_LVL_3 = '/andonaudit/submittonblvl3';
const API_ANDON_AUDIT_APPROVE = '/andonaudit/approve';
const API_ANDON_AUDIT_REJECT = '/andonaudit/reject';

const API_ANDON_AUDIT_ITEMS = '/andonauditems';
const API_ANDON_AUDIT_ITEM_UPDATE = '/andonauditem/update';

const API_PFA_MEASURES = '/pfameasures';
const API_PFA_MEASURE_UPDATE = '/pfameasure/update';
const API_PFA_MEASURE_DELETE = '/pfameasures/{id}';

const API_PFA_AUDITS = '/pfaaudits';
const API_PFA_AUDIT_UPDATE = '/pfaaudit/update';
const API_PFA_AUDIT_DELETE = '/pfaaudits/{id}';
const API_SUBMIT_PFA_AUDIT_TO_NB_LVL_2 = '/pfaaudit/submittonblvl2';
const API_SUBMIT_PFA_AUDIT_TO_NB_LVL_3 = '/pfaaudit/submittonblvl3';
const API_PFA_AUDIT_APPROVE = '/pfaaudit/approve';
const API_PFA_AUDIT_REJECT = '/pfaaudit/reject';

const API_PFA_AUDIT_ITEMS = '/pfaudititems';
const API_PFA_AUDIT_ITEM_UPDATE = '/pfaudititem/update';

const API_QUICKOVERCHANGE_MEASURES = '/quickchangeovermeasures';
const API_QUICKOVERCHANGE_MEASURE_UPDATE = '/quickchangeovermeasure/update';
const API_QUICKOVERCHANGE_MEASURE_DELETE = '/quickchangeovermeasures/{id}';

const API_QUICKOVERCHANGE_AUDITS = '/quickchangeoveraudits';
const API_QUICKOVERCHANGE_AUDIT_UPDATE = '/quickchangeoveraudit/update';
const API_QUICKOVERCHANGE_AUDIT_DELETE = '/quickchangeoveraudits/{id}';
const API_SUBMIT_QUICKOVERCHANGE_AUDIT_TO_NB_LVL_2 = '/quickchangeoveraudit/submittonblvl2';
const API_SUBMIT_QUICKOVERCHANGE_AUDIT_TO_NB_LVL_3 = '/quickchangeoveraudit/submittonblvl3';
const API_QUICKOVERCHANGE_AUDIT_APPROVE = '/quickchangeoveraudit/approve';
const API_QUICKOVERCHANGE_AUDIT_REJECT = '/quickchangeoveraudit/reject';

const API_QUICKOVERCHANGE_AUDIT_ITEMS = '/quickchangeoverauditems';
const API_QUICKOVERCHANGE_AUDIT_ITEM_UPDATE = '/quickchangeoverauditem/update';


const API_FOLLOW_UP_ITEM_UPDATE = '/followupitem/update';
const API_FOLLOW_UP_ITEMS = '/followupitems'
const API_FOLLOW_UP_ITEM_DELETE = '/followupitems/{id}';

const API_MANUFACTURING_EFFICIENCY_UPDATE = '/manufacturingefficiency/update';
const API_MANUFACTURING_EFFICIENCIES = '/manufacturingefficiencies';
const API_MANUFACTURING_EFFICIENCY_DELETE = '/manufacturingefficiencies/{id}';

const API_MANUFACTURING_INNOVATION_UPDATE = '/manufacturinginnovation/update';
const API_MANUFACTURING_INNOVATIONS = '/manufacturinginnovations';
const API_MANUFACTURING_INNOVATION_DELETE = '/manufacturinginnovations/{id}';


const API_PFA_REPORTS = '/pfareports'
const API_PFA_REPORT_UPDATE = '/pfareport/update'

const API_QUICKOVERCHANGE_REPORTS = '/quickchangeoverreports'


const API_ANDON_REPORTS = '/andonreports';









type GenReportOpts = {
  name: any,
  format?: any,
  params?: any,
  extra?: any,
};

export class AdminApi {
  api: Api;
  constructor(props: ApiProps) {
    this.api = new Api(props);
  }

  getDownloadUrl(file: string): string {
    let buf = new Buffer(file);
    let url = this.api.prepareUrl(API_DOWNLOAD, {file: buf.toString('base64')});
    return url;
  }

  getImageUrl(file: string): string {
    let buf = new Buffer(file);
    let url = this.api.prepareUrl(API_IMAGE, {file: buf.toString('base64')});
    return url;
  }

  getMonthInfo(params: any): Promise<any> {
    params = params || {};
    let url = this.api.prepareUrl(API_MONTH_INFO, {});
    let data = {
      data: {},
      params: {
        ...params,
      },
    };
    let args: PostType = {url, data};
    return this.api.post(args);
  }

  generateReport(opts: GenReportOpts): Promise<any> {
    let url = this.api.prepareUrl(API_APP_REPORT, {});
    let data = {
      data: {
        ...opts,
      },
      params: {
        ...opts.params,
      },
    };
    let args: PostType = {url, data};
    return this.api.post(args);
  }

  getAuditLogs(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_AUDITLOGS, {});
    return this.api.post({url, data: {...args}});
  }

  getAuditData(id: ?number): Promise<any> {
    let url = this.api.prepareUrl(API_AUDITLOG_DATA, {id});
    return this.api.post({url, data: {}});
  }

  getRoles(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_ROLES, {});
    return this.api.post({url, data: {...args}});
  }

  updateRole(obj: any): Promise<any> {
    let url = this.api.prepareUrl(API_ROLE_UPDATE, {});
    let args: PostType = {url, data: {data: {...obj}}};
    return this.api.post(args);
  }

  deleteRole(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_ROLE_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  linkRoleToPerms(id: number, permIds: any[]): Promise<any> {
    let url = this.api.prepareUrl(API_ROLE_LINK_PERMS, {id});
    let args: PostType = {
      url,
      data: {
        data: {
          permIds,
        },
      },
    };
    return this.api.post(args);
  }

  unlinkRoleToPerms(id: number, permIds: any[]): Promise<any> {
    let url = this.api.prepareUrl(API_ROLE_UNLINK_PERMS, {id});
    let args: PostType = {
      url,
      data: {
        data: {
          permIds,
        },
      },
    };
    return this.api.post(args);
  }

  // assignRolePerms(id: number, perms: any[]): Promise<any> {
  //   let url = this.api.prepareUrl(API_ROLE_ASSIGNPERMS, {id});
  //   let args: PostType = {url, data: [...perms]};
  //   return this.api.post(args);
  // }

  getRolePerms(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_SECPERMS, {});
    return this.api.post({url, data: {...args}});
  }

  updateRolePerm(obj: any): Promise<any> {
    let url = this.api.prepareUrl(API_SECPERM_UPDATE, {});
    let args: PostType = {url, data: {data: {...obj}}};
    return this.api.post(args);
  }

  deleteRolePerm(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_SECPERM_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getSecPerms(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_SECPERMS, {});
    return this.api.post({url, data: {...args}});
  }

  updateSecPerm(obj: any): Promise<any> {
    let url = this.api.prepareUrl(API_SECPERM_UPDATE, {});
    let args: PostType = {url, data: {data: {...obj}}};
    return this.api.post(args);
  }

  deleteSecPerm(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_SECPERM_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getProfiles(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_PROFILES, {});
    return this.api.post({url, data: {...args}});
  }

  updateProfile(obj: any): Promise<any> {
    let url = this.api.prepareUrl(API_PROFILE_UPDATE, {});
    let args: PostType = {url, data: {data: {...obj}}};
    return this.api.post(args);
  }

  deleteProfile(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_PROFILE_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  linkProfileRoles(id: number, roles: any[]): Promise<any> {
    let url = this.api.prepareUrl(API_PROFILE_LINK_ROLES, {id});
    let args: PostType = {
      url,
      data: {
        data: [...roles],
      },
    };
    return this.api.post(args);
  }

  unlinkProfileRoles(id: number, roles: any[]): Promise<any> {
    let url = this.api.prepareUrl(API_PROFILE_UNLINK_ROLES, {id});
    let args: PostType = {
      url,
      data: {
        data: [...roles],
      },
    };
    return this.api.post(args);
  }

  getUsers(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_USERS, {});
    return this.api.post({url, data: {...args}});
  }

  updateUser(obj: any): Promise<any> {
    let url = this.api.prepareUrl(API_USER_UPDATE, {});
    let args: PostType = {url, data: {data: {...obj}}};
    return this.api.post(args);
  }

  deleteUser(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_USER_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  changePwd({login, newPass}: {login: string, newPass: string}): Promise<any> {
    let url = this.api.prepareUrl(API_USER_CHANGEPWD);
    let args: PostType = {
      url,
      data: {
        data: {
          login,
          newPass,
        },
      },
    };
    return this.api.post(args);
  }

  getUserGrps(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_USERGRPS, {});
    return this.api.post({url, data: {...args}});
  }

  getUserGrpRoles(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_USERGRP_ROLES, {id});
    let args: PostType = {url, data: {}};
    return this.api.post(args);
  }

  getUserGrpUsers(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_USERGRP_USERS, {id});
    let args: PostType = {url, data: {}};
    return this.api.post(args);
  }

  linkUserGrpToRoles(id: number, roleIds: string[]): Promise<any> {
    let url = this.api.prepareUrl(API_USERGRP_LINK_ROLES, {id});
    let args: PostType = {
      url,
      data: {
        data: {
          roleIds,
        },
      },
    };
    return this.api.post(args);
  }

  unlinkUserGrpToRoles(id: number, roleIds: string[]): Promise<any> {
    let url = this.api.prepareUrl(API_USERGRP_UNLINK_ROLES, {id});
    let args: PostType = {
      url,
      data: {
        data: {
          roleIds,
        },
      },
    };
    return this.api.post(args);
  }

  linkUserGrpToUsers(id: number, logins: string[]): Promise<any> {
    let url = this.api.prepareUrl(API_USERGRP_LINK_USERS, {id});
    let args: PostType = {
      url,
      data: {
        data: {
          logins,
        },
      },
    };
    return this.api.post(args);
  }

  unlinkUserGrpToUsers(id: number, logins: string[]): Promise<any> {
    let url = this.api.prepareUrl(API_USERGRP_UNLINK_USERS, {id});
    let args: PostType = {
      url,
      data: {
        data: {
          logins,
        },
      },
    };
    return this.api.post(args);
  }

  updateUserGrp(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_USERGRP_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteUserGrp(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_USERGRP_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getReportTpls(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_REPORT_TPLS, {});
    return this.api.post({url, data: {...args}});
  }

  getReportTplUploadUrl(id: number): string {
    let url = this.api.prepareUrl(API_REPORT_TPL_UPLOAD, {id});
    // return this.api.post({url, data: {}});
    return url;
  }

  updateReportTpl(obj: any): Promise<any> {
    let url = this.api.prepareUrl(API_REPORT_TPL_UPDATE, {});
    let args: PostType = {url, data: {data: {...obj}}};
    return this.api.post(args);
  }

  deleteReportTpl(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_REPORT_TPL_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getReportTplFiles(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_REPORT_TPL_GETFILES, {id});
    let args = {url};
    return this.api.get(args);
  }

  deleteReportTplFiles(paths: string[]): Promise<any> {
    let url = this.api.prepareUrl(API_REPORT_TPL_DELFILES);
    let args: PostType = {
      url,
      data: {
        data: {
          paths,
        },
      },
    };
    return this.api.post(args);
  }

  getCountries(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_COUNTRIES, {});
    return this.api.post({url, data: {...args}});
  }

  updateCountry(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_COUNTRY_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteCountry(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_COUNTRY_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getRegions(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_REGIONS, {});
    return this.api.post({url, data: {...args}});
  }

  updateRegion(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_REGION_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteRegion(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_REGION_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getFactories(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_FACTORIES, {});
    return this.api.post({url, data: {...args}});
  }

  updateFactory(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_FACTORY_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteFactory(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_FACTORY_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getFactoryUsers(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_FACTORYUSERS, {});
    return this.api.post({url, data: {...args}});
  }

  updateFactoryUser(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_FACTORYUSER_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteFactoryUser(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_FACTORYUSER_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getFacGroups(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_FACGROUPS, {});
    return this.api.post({url, data: {...args}});
  }

  updateFacGroup(obj: any): Promise<any> {
    let url = this.api.prepareUrl(API_FACGROUP_UPDATE, {});
    let args: PostType = {url, data: {data: {...obj}}};
    return this.api.post(args);
  }

  deleteFacGroup(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_FACGROUP_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  linkFacGroupToFacs(id: number, factoryIds: any[]): Promise<any> {
    let url = this.api.prepareUrl(API_FACGROUP_LINK_FACS, {id});
    let args: PostType = {
      url,
      data: {
        data: {
          factoryIds,
        },
      },
    };
    return this.api.post(args);
  }

  unlinkFacGroupToFacs(id: number, factoryIds: any[]): Promise<any> {
    let url = this.api.prepareUrl(API_FACGROUP_UNLINK_FACS, {id});
    let args: PostType = {
      url,
      data: {
        data: {
          factoryIds,
        },
      },
    };
    return this.api.post(args);
  }

  getMachLines(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_MACHLINES, {});
    return this.api.post({url, data: {...args}});
  }

  updateMachLine(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_MACHLINE_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteMachLine(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_MACHLINE_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getMltRecs(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_MLTRECS, {});
    return this.api.post({url, data: {...args}});
  }

  updateMltRec(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_MLTREC_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteMltRec(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_MLTREC_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getMltYtdRoll6Months(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_GET_MLT_ROLL_6_MONTHS, {});
    return this.api.post({url, data: {...args}});
  }
  fetchMltRecData(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_MLTREC_FETCH_DATA, {});
    return this.api.post({url, data: {...args}});
  }

  getMltRecImportUrl(): string {
    let url = this.api.prepareUrl(API_MLTREC_IMPORT_MLT, {});
    return url;
  }

  approveMltRecs(ids: any[], params?: any): Promise<any> {
    let body = {
      data: {ids},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let url = this.api.prepareUrl(API_MLTREC_APPROVE_MLTRECS, {});
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteMltRecs(ids: any[], params?: any): Promise<any> {
    let body = {
      data: {ids},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let url = this.api.prepareUrl(API_MLTREC_DELETE_MLTRECS, {});
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  getOperators(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_OPERATORS, {});
    return this.api.post({url, data: {...args}});
  }

  updateOperator(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_OPERATOR_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteOperator(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_OPERATOR_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getShifts(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_SHIFTS, {});
    return this.api.post({url, data: {...args}});
  }

  updateShift(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_SHIFT_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteShift(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_SHIFT_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getProdKpis(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_PRODKPIS, {});
    return this.api.post({url, data: {...args}});
  }

  updateProdKpi(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_PRODKPI_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteProdKpi(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_PRODKPI_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  loadProdKpiData(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_PRODKPI_KPI_DATA, {});
    return this.api.post({url, data: {...args}});
  }

  loadProdKpiDataByMultiFactory(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_PRODKPI_MULTIFAC, {});
    return this.api.post({url, data: {...args}});
  }

  getProdRecs(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_PRODRECS, {});
    return this.api.post({url, data: {...args}});
  }

  updateProdRec(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_PRODREC_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  fetchPphMonthly(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_PRODREC_GET_PPH_MONTHLY, {});
    return this.api.post({url, data: {...args}});
  }

  getPphYtd(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_PRODREC_GET_PPH_YTD, {});
    return this.api.post({url, data: {...args}});
  }

  deleteProdRec(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_PRODREC_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getProdRecUploadUrl(): string {
    let url = this.api.prepareUrl(API_PRODREC_UPLOAD, {});
    return url;
  }

  approveProdRecs(ids: any[], params?: any): Promise<any> {
    let body = {
      data: {ids},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let url = this.api.prepareUrl(API_PRODREC_APPROVE_PRODRECS, {});
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  approveProdRecsV2(ids: any[], params?: any): Promise<any> {
    let body = {
      data: {ids},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let url = this.api.prepareUrl(API_PRODREC_APPROVE_PRODRECS_V2, {});
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteProdRecs(ids: any[], params?: any): Promise<any> {
    let body = {
      data: {ids},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let url = this.api.prepareUrl(API_PRODREC_DELETE_PRODRECS, {});
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  fetchProdRecData(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_PRODREC_FETCH_DATA, {});
    return this.api.post({url, data: {...args}});
  }

  getPphRoll6MonthsByFactory(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_PRODREC_GET_PPH_ROLL_6_MONTHS, {});
    return this.api.post({url, data: {...args}});
  }

  getLineGroups(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_LINEGROUPS, {});
    return this.api.post({url, data: {...args}});
  }

  updateLineGroup(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_LINEGROUP_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteLineGroup(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_LINEGROUP_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getErrorRecs(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_ERRORRECS, {});
    return this.api.post({url, data: {...args}});
  }

  updateErrorRec(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_ERRORREC_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteErrorRec(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_ERRORREC_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getMachErrors(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_MACHERRORS, {});
    return this.api.post({url, data: {...args}});
  }

  updateMachError(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_MACHERROR_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteMachError(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_MACHERROR_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  //////////////////////////////////////////////////////////////////////////////
  // Lean
  //////////////////////////////////////////////////////////////////////////////
  getAudMeasures(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_AUDMEASURES, {});
    return this.api.post({url, data: {...args}});
  }
  //fectch data mesures
  fetchAudMeasureData(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_AUDMEASURE_FETCH_DATA, {});
    return this.api.post({url, data: {...args}});
  }

  updateAudMeasure(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_AUDMEASURE_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteMeasure(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_AUDMEASURE_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getLeanAudits(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_LEANAUDITS, {});
    return this.api.post({url, data: {...args}});
  }

  updateLeanAudit(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_LEANAUDIT_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteLeanAudit(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_LEANAUDIT_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  fetchLeanAuditData(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_LEANAUDIT_FETCH_DATA, {});
    return this.api.post({url, data: {...args}});
  }
  getLeanScore(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_LEANAUDIT_GET_LEANSCORE, {});
    return this.api.post({url, data: {...args}});
  }

  getLeanAudItems(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_LEANAUD_ITEMS, {});
    return this.api.post({url, data: {...args}});
  }

  updateLeanAudItem(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_LEANAUD_ITEM_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteLeanAudItem(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_LEANAUD_ITEM_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  fetchLeanAudItemData(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_LEANAUD_ITEM_FETCH_DATA, {});
    return this.api.post({url, data: {...args}});
  }

  getPphRoadmaps(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_PPHROADMAPS, {});
    return this.api.post({url, data: {...args}});
  }

  updatePphRoadmap(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_PPHROADMAP_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deletePphRoadmap(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_PPHROADMAP_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  fetchPphRoadmapData(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_PPHROADMAP_FETCH_DATA, {});
    return this.api.post({url, data: {...args}});
  }

  getMltRoadmaps(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_MLTROADMAPS, {});
    return this.api.post({url, data: {...args}});
  }

  updateMltRoadmap(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_MLTROADMAP_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteMltRoadmap(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_MLTROADMAP_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  fetchMltRoadmapData(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_MLTROADMAP_FETCH_DATA, {});
    return this.api.post({url, data: {...args}});
  }

  getLeanReports(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_LEANREPORTS, {});
    return this.api.post({url, data: {...args}});
  }

  updateLeanReport(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_LEANREPORT_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteLeanReport(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_LEANREPORT_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  fetchLeanReportData(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_LEANREPORT_FETCH_DATA, {});
    return this.api.post({url, data: {...args}});
  }

  getMiniLineAudits(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_MINILINEAUDITS, {});
    return this.api.post({url, data: {...args}});
  }

  updateMiniLineAudit(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_MINILINEAUDIT_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteMiniLineAudit(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_MINILINEAUDIT_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  fetchMiniLineAuditData(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_MINILINEAUDIT_FETCH_DATA, {});
    return this.api.post({url, data: {...args}});
  }

  getLeanActivities(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_LEAN_ACTIVITIES, {});
    return this.api.post({url, data: {...args}});
  }

  updateLeanActivity(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_LEAN_ACTIVITY_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteLeanActivity(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_LEAN_ACTIVITY_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  deleteMachModel(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_MACHMODEL_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  updateMachModel(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_MACHMODEL_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteProdRecMd(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_PRODRECMD_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getProdRecMdUploadUrl(): string {
    let url = this.api.prepareUrl(API_PRODRECMD_UPLOAD, {});
    return url;
  }

  getMachModels(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_MACHMODELS, {});
    return this.api.post({url, data: {...args}});
  }

  getProdRecsMd(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_PRODRECSMD, {});
    return this.api.post({url, data: {...args}});
  }

  deleteProdRecsMd(ids: any[], params?: any): Promise<any> {
    let body = {
      data: {ids},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let url = this.api.prepareUrl(API_PRODRECMD_DELETE_PRODRECSMD, {});
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  updateProdRecMd(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_PRODRECMD_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  approveProdRecsMd(ids: any[], params?: any): Promise<any> {
    let body = {
      data: {ids},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let url = this.api.prepareUrl(API_PRODRECMD_APPROVE_PRODRECSMD, {});
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  fetchProdMdRecData(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_PRODRECMD_FECTH_DATA, {});
    return this.api.post({url, data: {...args}});
  }

  updateLeanScoreCard(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_LEAN_SCORE_CARD_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  getPphYtdKpi(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_GET_YTD_PPH, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  getLeanAssessmenKpi(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_GET_LEAN_ASSESSMENT_KPI, {});
    return this.api.post({url, data: {...args}});
  }

  getLeanScoreCard(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_LEAN_SCORE_CARD, {});
    return this.api.post({url, data: {...args}});
  }

  deleteLeanScore(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_LEAN_SCORE_CARD_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getKpiByGroup(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_PRODKPI_DATA_GROUP, {});
    return this.api.post({url, data: {...args}});
  }

  getLeanScoreRs(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_LEAN_SCORE_RES, {});
    return this.api.post({url, data: {...args}});
  }

  getLeanScoreCardReport(
    args: SendObjType,
    controller?: AbortController,
  ): Promise<any> {
    let url = this.api.prepareUrl(API_GET_LEAN_SCORE_CARD_REPORT, {});
    return this.api.post({url, data: {...args}}, controller);
  }

  getLeanScoreMltRs(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_LEAN_SCORE_MLT_RES, {});
    return this.api.post({url, data: {...args}});
  }

  getLeanScoreMiniLineRs(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_MINILINE_SCORE, {});
    return this.api.post({url, data: {...args}});
  }

  getLeanScoreAssessmentRs(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_LEAN_ASSESSMENT_SCORE, {});
    return this.api.post({url, data: {...args}});
  }

  getMedianMlt(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_MEDIAN_MLT, {});
    return this.api.post({url, data: {...args}});
  }

  getPoComletion(
    args: SendObjType,
    controller?: AbortController,
  ): Promise<any> {
    let url = this.api.prepareUrl(API_POCOMPLETION, {});
    return this.api.post({url, data: {...args}}, controller);
  }

  getPphWeighted(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_GET_PPHWEIGHTED, {});
    return this.api.post({url, data: {...args}});
  }

  getPphrReportByFactory(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_GET_PPHREPORT_FACTORY, {});
    return this.api.post({url, data: {...args}});
  }

  getFilterMlt(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_FILTERMLT, {});
    return this.api.post({url, data: {...args}});
  }

  updateFilterMlt(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_FILTERMLT_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteFilterMlt(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_FILTERMLT_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getPphOfMonth(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_TABLE_PPH_OF_MONTH, {});
    return this.api.post({url, data: {...args}});
  }

  updateMaxScorePotential(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_LEAN_MAX_SCORE_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  getMaxScorePotentials(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_LEAN_MAX_SCORE, {});
    return this.api.post({url, data: {...args}});
  }

  deleteMaxScorePotential(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_LEAN_MAX_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getPphRoll6Month(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_ROLL_6_MONTH, {});
    return this.api.post({url, data: {...args}});
  }

  getPphWeightedByMultiFactory(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_GET_PPHWEIGHTED_MULTI_FACTORY, {});
    return this.api.post({url, data: {...args}});
  }

  getSourcingMetric(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_PRODREC_SOURCING_METRICS, {});
    return this.api.post({url, data: {...args}});
  }

  updateMltSetPoint(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_MLT_SETPOINT_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  getProdRecBtsUploadUrl(): string {
    let url = this.api.prepareUrl(API_PRODRECBTS_UPLOAD, {});
    return url;
  }

  getProdRecsBts(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_PRODRECSBTSS, {});
    return this.api.post({url, data: {...args}});
  }

  approveProdRecsBts(ids: any[], params?: any): Promise<any> {
    let body = {
      data: {ids},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let url = this.api.prepareUrl(API_PRODREC_APPROVE_PRODRECSBTSS, {});
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteProdRecsBtss(ids: any[], params?: any): Promise<any> {
    let body = {
      data: {ids},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let url = this.api.prepareUrl(API_PRODRECSBTS_DELETE_PRODRECSBTSS, {});
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteProdRecsBts(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_PRODRECSBTS_DELELTE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  updateProdrecsBts(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_PRODRECSBTS_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  getProdsRecBtsByMonth(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_GET_PRODRECSBTS_BY_MONTH, {});
    return this.api.post({url, data: {...args}});
  }
  getApprovalProcess(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_GET_APPROVAL_PROCESS, {});
    return this.api.post({url, data: {...args}});
  }

  getMltSetpoint(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_MLT_SETPOINTS, {});
    return this.api.post({url, data: {...args}});
  }

  deleteMltSetPoint(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_MLT_POINTS_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }


  updateWarningThreshold(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_WARNING_THRESHOLD_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }
  getWarningThreshold(args: SendObjType): Promise<any> {
    let url = this.api.prepareUrl(API_WARNING_THRESHOLDS, {});
    return this.api.post({url, data: {...args}});
  }

  deleteWarningThreshold(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_WARNING_THRESHOLD_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getAppConfig(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_APP_CONFIG, {});
    return this.api.post({url, data: {...args}});
  }

  updateAppConfig(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_APP_CONFIG_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  getAlarms(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_ALARMS, {});
    return this.api.post({url, data: {...args}});
  }

  updateAndonDetail(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_ANDON_DETAIL_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }
  getAndonDetails(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_ANDON_DETAILS, {});
    return this.api.post({url, data: {...args}});
  }
  deleteAndonDetail(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_ANDON_DETAIL_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getAndonMeasures(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_ANDON_MEASURES, {});
    return this.api.post({url, data: {...args}});
  }
  updateAndonMeasure(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_ANDON_MEASURE_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  deleteAndonMeasure(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_ANDON_MEASURE_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  updateAndonAudit(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_ANDON_AUDIT_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }
  getAndonAudits(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_ANDON_AUDITS, {});
    return this.api.post({url, data: {...args}});
  }
  deleteAndonAudit(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_ANDON_AUDIT_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }
  submitAndonAuditToNbLvl2(args: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_SUBMIT_ANDON_AUDIT_TO_NB_LVL_2, {});
    return this.api.post({url, data: {...args}});
 }
 submitAndonAuditToNbLvl3(args: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_SUBMIT_ANDON_AUDIT_TO_NB_LVL_3, {});
    return this.api.post({url, data: {...args}});
 }
  approveAndonAudit(args: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_ANDON_AUDIT_APPROVE, {});
    return this.api.post({url, data: {...args}});
}
    rejectAndonAudit(args: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_ANDON_AUDIT_REJECT, {});
    return this.api.post({url, data: {...args}});
   }





  getAndonAuditItems(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_ANDON_AUDIT_ITEMS, {});
    return this.api.post({url, data: {...args}});
  }
  updateAndonAuditItem(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_ANDON_AUDIT_ITEM_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  getPfaMeasures(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_PFA_MEASURES, {});
    return this.api.post({url, data: {...args}});
  }
  updatePfaMeasure(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_PFA_MEASURE_UPDATE, {});
    let body = {
        data: {...obj},
    };
    if (params) {
        body = {
            ...body,
            params: {...params},
        };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
    }

    deletePfaMeasure(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_PFA_MEASURE_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
    }

  getPfaAudits(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_PFA_AUDITS, {});
    return this.api.post({url, data: {...args}});
  }
  updatePfaAudit(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_PFA_AUDIT_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }


  deletePfaAudit(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_PFA_AUDIT_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getPfaAuditItems(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_PFA_AUDIT_ITEMS, {});
    return this.api.post({url, data: {...args}});
  }
  updatePfaAuditItem(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_PFA_AUDIT_ITEM_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  getQuickOverChangeMeasures(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_QUICKOVERCHANGE_MEASURES, {});
    return this.api.post({url, data: {...args}});
  }

  updateQuickOverChangeMeasure(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_QUICKOVERCHANGE_MEASURE_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }
  deleteQuickOverChangeMeasure(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_QUICKOVERCHANGE_MEASURE_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }

  getQuickOverChangeAudits(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_QUICKOVERCHANGE_AUDITS, {});
    return this.api.post({url, data: {...args}});
  }
  updateQuickOverChangeAudit(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_QUICKOVERCHANGE_AUDIT_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }
  deleteQuickOverChangeAudit(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_QUICKOVERCHANGE_AUDIT_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
  }
  submitQuickOverChangeAuditToNbLvl2(args: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_SUBMIT_QUICKOVERCHANGE_AUDIT_TO_NB_LVL_2, {});
    return this.api.post({url, data: {...args}});
    }
    submitQuickOverChangeAuditToNbLvl3(args: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_SUBMIT_QUICKOVERCHANGE_AUDIT_TO_NB_LVL_3, {});
    return this.api.post({url, data: {...args}});
    }
  getQuickOverChangeAuditItems(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_QUICKOVERCHANGE_AUDIT_ITEMS, {});
    return this.api.post({url, data: {...args}});
  }

  approveQuickOverChangeAudit(args: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_QUICKOVERCHANGE_AUDIT_APPROVE, {});
    return this.api.post({url, data: {...args}});
  }

    rejectQuickOverChangeAudit(args: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_QUICKOVERCHANGE_AUDIT_REJECT, {});
    return this.api.post({url, data: {...args}});
    }
  updateQuickOverChangeAuditItem(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_QUICKOVERCHANGE_AUDIT_ITEM_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  updateFollowUpItem(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_FOLLOW_UP_ITEM_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
  }

  getFollowUpItems(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_FOLLOW_UP_ITEMS, {});
    return this.api.post({url, data: {...args}});
  }

   updateManufacturingEfficiency(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_MANUFACTURING_EFFICIENCY_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
    }
    deleteFollowUpItem(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_FOLLOW_UP_ITEM_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
    }


     getManufacturingEfficiencies(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_MANUFACTURING_EFFICIENCIES, {});
    return this.api.post({url, data: {...args}});
     }
     deleteManufacturingEfficiency(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_MANUFACTURING_EFFICIENCY_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
        }

     getPfaReports(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_PFA_REPORTS, {});
    return this.api.post({url, data: {...args}});
     }

     updatePfaReport(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_PFA_REPORT_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
     }

    submitPfaReportToNbLvl2(args: any, params?: any): Promise<any> {
        let url = this.api.prepareUrl(API_SUBMIT_PFA_AUDIT_TO_NB_LVL_2, {});
        return this.api.post({url, data: {...args}});
      }
    submitPfaReportToNbLvl3(args: any, params?: any): Promise<any> {
        let url = this.api.prepareUrl(API_SUBMIT_PFA_AUDIT_TO_NB_LVL_3, {});
        return this.api.post({url, data: {...args}});
      }

      aprovePfaAudit(args: any, params?: any): Promise<any> {
        let url = this.api.prepareUrl(API_PFA_AUDIT_APPROVE, {});
        return this.api.post({url, data: {...args}});
      }

    rejectPfaAudit(args: any, params?: any): Promise<any> {
        let url = this.api.prepareUrl(API_PFA_AUDIT_REJECT, {});
        return this.api.post({url, data: {...args}});
      }


    updateManufacturingInnovation(obj: any, params?: any): Promise<any> {
    let url = this.api.prepareUrl(API_MANUFACTURING_INNOVATION_UPDATE, {});
    let body = {
      data: {...obj},
    };
    if (params) {
      body = {
        ...body,
        params: {...params},
      };
    }
    let args: PostType = {url, data: body};
    return this.api.post(args);
    }

    getManufacturingInnovations(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_MANUFACTURING_INNOVATIONS, {});
    return this.api.post({url, data: {...args}});
    }
    deleteManufacturingInnovation(id: number): Promise<any> {
    let url = this.api.prepareUrl(API_MANUFACTURING_INNOVATION_DELETE, {id});
    let args: DelType = {url};
    return this.api.delete(args);
    }
  getQuickOverChangeReports(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_QUICKOVERCHANGE_REPORTS, {});
    return this.api.post({url, data: {...args}});
  }

 getAndonReports(args: QueryObjsType): Promise<any> {
    let url = this.api.prepareUrl(API_ANDON_REPORTS, {});
    return this.api.post({url, data: {...args}});
  }


}
