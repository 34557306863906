// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';
//$FlowFixMe[cannot-resolve-module]
import type {ApiProps} from '../../service/Api';
//$FlowFixMe[cannot-resolve-module]
import {AuthUtils} from '../../service/AuthUtils';
// import {Utils} from '../../service/Utils';
//$FlowFixMe[cannot-resolve-module]
import {AdminApi} from '../../service/AdminApi';

// import {ChartFilter} from './ChartFilter';
// import {PphDaily} from './PphDaily';
// import {PphWeekly} from './PphWeekly';
// import {PphMonthly} from './PphMonthly';
// import {CountryPphDaily} from './CountryPphDaily';
// import {CountryPphWeekly} from './CountryPphWeekly';
// import {CountryPphMonthly} from './CountryPphMonthly';
// import {LinePphDaily} from './LinePphDaily';
import {OverviewChart} from './OverviewChart';

type Props = {};

export function ChartPage(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const [tabInd, setTabInd] = useState(0);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  let tabs = [];

  //let authLevel = AuthUtils.getAuthLevel({auth});
  //console.log.*$
  //console.log.*$
  if (AuthUtils.getAuthLevel({auth}) < 10) {
    tabs.push(
      <TabPanel key="countryTab" header="Country Overview">
        <OverviewChart />
      </TabPanel>,
    );
  }

  tabs.push(
    <TabPanel key="factoryTab" header="Factory Overview">
      <OverviewChart scope="factory" />
    </TabPanel>,
  );

  tabs.push(
    <TabPanel key="lineTab" header="Line Details">
      <OverviewChart scope="line" />
    </TabPanel>,
  );
  return (
    <>
      <Toast ref={toastRef} />
      <div className="p-mt-3"></div>
      <TabView
        activeIndex={tabInd}
        onTabChange={(e) => {
          setTabInd(e.index);
        }}>
        {tabs}
      </TabView>
    </>
  );
}
