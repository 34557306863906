// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';

//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';

//$FlowFixMe[cannot-resolve-module]
import {Dropdown} from 'primereact/dropdown';

import type {ApiProps} from '../../service/Api';

import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  readOnly?: boolean,
  machLine: any,
  onAction: (evt: any) => void,
};

const defMachLine = {
  __cls__: 'com.nb.lmrs.model.entity.FilterMlt',
  flag: 0,
  factoryId: '',
  month: '',
  year: '',
  ucl: '',
  lcl: '',
};

export function FilterMltForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {readOnly, machLine} = props;
  const [editMachLine, setEditMachLine] = useState<any>();
  // const [suggFactories, setSuggFactories] = useState([]);
  //const [defFactory, setDefFactory] = useState();
  const [avaiMonths, setAvaiMonths] = useState([]);
  let {t} = useTranslation();

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  // useEffect(() => {
  //   //console.log.*$
  //   let authLvl = AuthUtils.getAuthLevel({auth});
  //   //console.log.*$
  //   //init filter
  //   let {factoryUsers} = auth;
  //   let factories = (factoryUsers || [])
  //     .filter((it) => it.role)
  //     .map((it) => it.factory)
  //     .filter((it) => it);
  //   if (factories.length > 0) {
  //     setDefFactory(factories[0]);
  //   }
  // }, [auth]);

  useEffect(() => {
    let mons = [-1];
    for (let i = 1; i <= 12; i++) {
      mons.push(i);
    }
    let monOpts = mons.map((it) => {
      return {label: t(`mon.${it}`), value: it};
    });
    setAvaiMonths([...monOpts]);
  }, [t]);

  useEffect(() => {
    // //console.log.*$
    let curMachLine = machLine || {};
    curMachLine = {
      ...defMachLine,
      ...curMachLine,
    };

    // let isNew = !curMachLine.id;
    // let {recDate} = curMachLine;
    // if (isNew && (!curMachLine.factory || !curMachLine.factory.id)) {
    //   curMachLine['factory'] = defFactory;
    // }else{
    //   curMachLine['recDate'] = Utils.toJsDate(recDate);
    // }

    // let {factory} = curMachLine;
    // if (factory) {
    //   factory.label = `${factory.code} :: ${factory.name}`;
    // }

    //console.log.*$
    setEditMachLine(curMachLine);
  }, [machLine]);

  // const _onSuggFactories: (evt: any) => any = (evt: any) => {
  //   let api: ?AdminApi = apiRef.current;
  //   if (!api) {
  //     return;
  //   }
  //   let search = evt.query;
  //   let start = 0;
  //   let limit = 1000;
  //   let sorts = ['obj.name', 'obj.createdAt'];
  //   api
  //     .getFactories({search, start, limit, sorts})
  //     .then((resp) => resp.data)
  //     .then((resp) => {
  //       let {errors, data} = resp;
  //       if (errors.length > 0) {
  //         //console.log.*$
  //         UIUtils.showError({errors, toast: toastRef.current});
  //         return;
  //       }
  //       // //console.log.*$
  //       data.list.forEach((it) => (it.label = `${it.code} :: ${it.name}`));
  //       //console.log.*$
  //      // setSuggFactories([...data.list]);
  //     })
  //     .catch((err) => {
  //       //console.log.*$
  //       UIUtils.showError({error: err, toast: toastRef.current});
  //     });
  // };

  const _onSave = (evt: any) => {
    // setShowCfmDlg(false);
    let fac = _validateMachLine();
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update filter ?'
        : 'Are you sure you want to create filter ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateFilterMlt({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'machLineSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _validateMachLine: () => any = () => {
    let obj = {...editMachLine};

    ////console.log.*$
    // if (obj.ucl === null) {
    //   UIUtils.showError({
    //     detail: 'Please enter max mlt',
    //     toast: toastRef.current,
    //   });
    //   return false;
    // }

    if (obj.month === '') {
      UIUtils.showError({
        detail: 'Please enter the month',
        toast: toastRef.current,
      });
      return false;
    }

    if (obj.year === '') {
      UIUtils.showError({
        detail: 'Please enter the year',
        toast: toastRef.current,
      });
      return false;
    }

    if (obj.ucl === '') {
      UIUtils.showError({
        detail: 'Please enter min mlt',
        toast: toastRef.current,
      });
      return false;
    }

    if (obj.lcl === '') {
      UIUtils.showError({
        detail: 'Please enter max mlt',
        toast: toastRef.current,
      });
      return false;
    }

    return obj;
  };

  if (!editMachLine) {
    return null;
  }

  const saveLbl = editMachLine.id ? 'Update filter ' : 'Create filter ';
  //const isNew = !editMachLine.id;
  // //console.log.*$

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="MLT Filter">
        <div className="p-grid py-3 p-fluid">
          {/* <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editMachLine.code}
                onChange={(e) => {
                  setEditMachLine({...editMachLine, code: e.target.value});
                }}
                disabled={!isNew || readOnly}
              />
              <label>Code</label>
            </span>
          </div> */}

          {/* <div className="p-col-12">
            <AutoComplete
              placeholder="Select factory"
              dropdown
              suggestions={suggFactories}
              field="label"
              value={editMachLine.factory}
              completeMethod={_onSuggFactories}
              onChange={(e) =>
                setEditMachLine({
                  ...editMachLine,
                  factory: e.value,
                })
              }
              disabled={readOnly}
            />
          </div> */}

          <div className="p-col-12">
            <div class="p-grid">
              <div class="p-col-6">
                <span className="p-float-label">
                  <Dropdown
                    optionLabel="label"
                    optionValue="value"
                    value={editMachLine.month}
                    options={avaiMonths}
                    onChange={(e) => {
                      setEditMachLine({...editMachLine, month: e.value});
                    }}
                    // disabled={readOnly}
                  />
                  <label>Month</label>
                </span>
              </div>
              <div class="p-col-6">
                <span className="p-float-label">
                  <InputText
                    keyfilter="pint"
                    value={editMachLine.year}
                    onChange={(e) => {
                      setEditMachLine({...editMachLine, year: e.target.value});
                    }}
                    // disabled={readOnly}
                  />
                  <label>Year</label>
                </span>
              </div>
            </div>
          </div>
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editMachLine.ucl}
                onChange={(e) => {
                  setEditMachLine({...editMachLine, ucl: e.target.value});
                }}
                // disabled={!isNew || readOnly}
              />
              <label>Min MLT</label>
            </span>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editMachLine.lcl}
                onChange={(e) => {
                  setEditMachLine({...editMachLine, lcl: e.target.value});
                }}
                disabled={false}
              />
              <label>Max MLT</label>
            </span>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
