// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';

import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender, EvtHandler} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  scope?: string,
  countries?: any[],
  factories?: any[],
  lines?: any[],
  year?: any,
  month?: any,
  defSort?: any[],
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
  onAction?: (evt: any) => void,
};

export function PphWeeklyData(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  // const app = useSelector((state) => state.app);
  // const {t} = useTranslation();
  const [prodRecsTblData, setProdRecsTblData] = useState([]);
  const [prodRecsTblStart, setProdRecsTblStart] = useState(0);
  const [prodRecsTblLimit] = useState(10);
  const [prodRecsTblNbRows, setProdRecsTblNbRows] = useState(0);
  const [selProdRec, setSelProdRec] = useState();
  // const [selProdRecs, setSelProdRecs] = useState([]);
  const [sortMeta, setSortMeta] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);

  const {
    countries,
    factories,
    lines,
    year,
    month,
    defSort,
    selection,
    reload,
    header,
    command,
  } = props;
  const scope = props.scope || 'global';
  const selMode = props.selectionMode || 'single';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    if (defSort && defSort.length > 0) {
      setSortMeta([...defSort]);
      setSortField(defSort[0].field);
      setSortOrder(defSort[0].order);
    }
  }, [defSort]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelProdRec({...selection});
      } else {
        // setSelProdRecs([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelProdRec(null);
      } else {
        // setSelProdRecs([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    // //console.log.*$
    // //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let groupBys = ['obj.year'];
    let joins = [];
    let filters = {};
    let params = {
      // year: Number(pYear),
      // month: Number(pMonth),
    };
    let fdNames = ['year'];
    if (scope === 'global') {
      joins = [
        {
          type: '',
          expr: 'Factory',
          alias: 'fac',
        },
        {
          type: 'join',
          expr: 'fac.country',
          alias: 'coun',
        },
      ];
      fdNames = ['counCode', 'counName', ...fdNames];
      groupBys = ['coun.code', 'coun.name', ...groupBys];
      let counIds = Utils.getVals(countries || [], 'id', [0]).map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
      filters = {
        jnFac: 'fac.id = obj.factoryId',
        byFacIds: 'coun.id in (:counIds)',
      };
      params = {
        counIds,
      };
    } else if (scope === 'factory') {
      joins = [
        {
          type: '',
          expr: 'Factory',
          alias: 'fac',
        },
      ];
      groupBys = ['fac.code', 'fac.name', ...groupBys];
      fdNames = ['facCode', 'facName', ...fdNames];
      let facIds = Utils.getVals(factories || [], 'id', [0]).map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
      filters = {
        jnFac: 'fac.id = obj.factoryId',
        byFacIds: 'obj.factoryId in (:facIds)',
      };
      params = {
        facIds,
      };
    } else if (scope === 'line') {
      joins = [
        {
          type: '',
          expr: 'MachLine',
          alias: 'line',
        },
      ];
      groupBys = ['line.code', 'line.name', ...groupBys];
      fdNames = ['lineCode', 'lineName', ...fdNames];
      let lineIds = Utils.getVals(lines || [], 'id', [0]).map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
      filters = {
        jnLine: 'line.id = obj.lineId',
        byLineIds: 'obj.lineId in (:lineIds)',
      };
      params = {
        lineIds,
      };
    }

    let pYear = Number(year) || 0;
    let pMonth = Number(month) || 0;
    filters = {
      ...filters,
      byYear: 'obj.year = :year',
    };
    params = {
      ...params,
      year: pYear,
    };

    if (pMonth > 0) {
      filters = {
        ...filters,
        byMonth: 'obj.month = :month',
      };
      params = {
        ...params,
        month: pMonth,
      };
      groupBys = [...groupBys, 'obj.month', 'obj.wom'];
      fdNames = [...fdNames, 'month', 'wom'];
    } else {
      groupBys = [...groupBys, 'obj.woy'];
      fdNames = [...fdNames, 'woy'];
    }

    fdNames = [...fdNames, 'sumHours', 'sumProds', 'avgPph'];
    let fields = [
      ...groupBys,
      'sum(obj.nbHours)',
      'sum(obj.nbProds)',
      'avg(obj.pph)',
    ];

    let sorts = [...groupBys];

    let start = prodRecsTblStart;
    let limit = prodRecsTblLimit;

    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    // let sorts = sortMeta.map((sm) => {
    //   return sm.order > 0 ? sm.field : `-${sm.field}`;
    // });
    // //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);

    api
      .fetchProdRecData({
        fields,
        joins,
        groupBys,
        filters,
        params,
        sorts,
        start,
        limit,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        let {list} = data;
        let id = start;
        list = list.map((rec) => {
          let newRec = {};
          rec.forEach((it, ind) => {
            newRec[fdNames[ind]] = it;
          });
          newRec['id'] = id++;
          if (scope === 'global') {
            newRec['counLbl'] =
              newRec['counCode'] + ' :: ' + newRec['counName'];
          } else if (scope === 'factory') {
            newRec['facLbl'] = newRec['facCode'] + ' :: ' + newRec['facName'];
          } else if (scope === 'line') {
            newRec['lineLbl'] =
              newRec['lineCode'] + ' :: ' + newRec['lineName'];
          }
          return newRec;
        });

        //console.log.*$
        setProdRecsTblData([...list]);
        setProdRecsTblNbRows(0);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, [
    scope,
    countries,
    factories,
    lines,
    year,
    month,
    reload,
    sortMeta,
    prodRecsTblStart,
    prodRecsTblLimit,
  ]);

  const _onProdRecSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelProdRec(evt.value);
    } else {
      // setSelProdRecs(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    // //console.log.*$
    setProdRecsTblStart(evt.first);
  };

  const _onNextPage: EvtHandler = (evt: any) => {
    if (prodRecsTblData.length < prodRecsTblLimit) {
      return;
    }
    setProdRecsTblStart(prodRecsTblStart + prodRecsTblLimit);
  };

  const _onPrevPage: EvtHandler = (evt: any) => {
    let val = prodRecsTblStart - prodRecsTblLimit;
    if (val < 0) {
      val = 0;
    }
    setProdRecsTblStart(val);
  };

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
    let {onAction} = props;
    onAction &&
      onAction({
        type: 'sortChanged',
        value: [...newMeta],
      });
  };

  const _renderAvgPphCol: TblColRender = (row: any, col: any) => {
    let {avgPph} = row;
    return avgPph ? Utils.round(avgPph) : null;
  };

  return (
    <>
      <Toast ref={toastRef} />
      <DataTable
        header={header}
        value={prodRecsTblData}
        dataKey="id"
        lazy={true}
        paginator={false}
        rows={prodRecsTblLimit}
        first={prodRecsTblStart}
        totalRecords={prodRecsTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        selection={selProdRec}
        onSelectionChange={_onProdRecSelected}
        onPage={_onInvsPaging}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={_onSort}>
        {scope === 'global' && (
          <Column field="counLbl" header="Country" headerStyle={{width: 120}} />
        )}

        {scope === 'factory' && (
          <Column field="facLbl" header="Factory" headerStyle={{width: 120}} />
        )}

        {scope === 'line' && (
          <Column field="lineLbl" header="Line" headerStyle={{width: 120}} />
        )}

        <Column field="year" header="Year" headerStyle={{width: 80}} />

        {!isNaN(month) && Number(month) > 0 && (
          <Column field="month" header="Month" headerStyle={{width: 80}} />
        )}

        {!isNaN(month) && Number(month) > 0 && (
          <Column field="wom" header="Week" headerStyle={{width: 80}} />
        )}

        {(isNaN(month) || Number(month) < 1) && (
          <Column field="woy" header="Week" headerStyle={{width: 80}} />
        )}

        <Column
          field="sumProds"
          header="Output(s)"
          headerClassName="text-end"
          bodyClassName="text-end"
          headerStyle={{width: 60}}
        />

        <Column
          field="sumHours"
          header="Hour(s)"
          headerClassName="text-end"
          bodyClassName="text-end"
          headerStyle={{width: 60}}
        />

        <Column
          body={_renderAvgPphCol}
          header="PPH"
          headerClassName="text-end"
          bodyClassName="text-end"
          headerStyle={{width: 80}}
        />

        <Column
          field="id"
          header="#"
          headerStyle={{width: 60}}
          headerClassName="text-end"
          bodyClassName="text-end"
        />
      </DataTable>

      <div className="flex justify-content-end py-2">
        <Button
          icon="pi pi-chevron-left"
          className="p-ml-2"
          onClick={_onPrevPage}
        />
        <Button
          icon="pi pi-chevron-right"
          className="p-ml-2 ml-2"
          onClick={_onNextPage}
        />
      </div>
    </>
  );
}
