// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';

//$FlowFixMe
import type {ApiProps} from '../../service/Api';
import {Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {FactoryFilter} from '../common/FactoryFilter';
import type {EvtHandler} from '../types';
import {CommonKpiList} from './CommonKpiList';
type Props = {};
export function CommonKpi(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const [initFilter, setInitFilter] = useState({});
  const [filter, setFilter] = useState({});

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    //init filter
    let initFil = {};

    let di: any = Utils.getDateInfo() || {};
    initFil = {
      ...initFil,
      month: di.month,
      years: [di.year],
      year: di.year,
    };
    setFilter({...initFil});
    setInitFilter({...initFil});
  }, []);

  const _onFilterAction: EvtHandler = (evt: any) => {
    let {type, value} = evt;
    //console.log.*$
    if (type === 'filterChanged') {
      setFilter({...value});
      return;
    }
  };

  return (
    <>
      <Toast ref={toastRef} />
      <FactoryFilter
        scope="factory"
        filter={initFilter}
        showYear={true}
        onAction={_onFilterAction}
      />
      <div className="p-col-12 p-lg-12">
        <CommonKpiList filter={filter} />
      </div>
    </>
  );
}
