// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';
import type {ApiProps} from '../../service/Api';

// import {AuthConst} from '../../service/AppConstant';
//$FlowFixMe[cannot-resolve-module];
import {UIUtils, Utils} from '../../service/Utils';
import type {TblColRender} from '../types';
import {AdminApi} from '../../service/AdminApi';
// import {LeanAuditFilter} from './LeanAuditFilter';

type Props = {
  filter: any,
};

export function SourcingMetricsReport(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const [dataSourcingMetricByCountryTbl, setDataSourcingMetricByCountryTbl] =
    useState([]);
  const [dataSourcingMetricByRegionnTbl, setDataSourcingMetricByRegionnTbl] =
    useState([]);
  const [dataPphTargetTbl, setDataPphTargetTbl] = useState([]);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  const {filter} = props;
  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchData = useCallback(async (options) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let {month, year} = options;

    return api
      .getSourcingMetric({data: {year: year, month: month}})
      .then((resp) => resp.data)
      .then((resp) => {
        //console.log.*$
        let {errors, data} = resp;

        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        return data;
      })
      .catch((err) => {
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  const _fetchDataKpi = useCallback(async (options: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let {year} = options;
    //console.log.*$

    let joins = [];

    let byKpiType = 'PPH';
    let filters = {
      byType: 'obj.kpiType = :kpiType',
    };
    let params = {
      kpiType: {
        type: 'enum:com.nb.lmrs.model.KpiType',
        value: byKpiType,
      },
    };

    if (year) {
      filters = {
        ...filters,
        byYear: 'obj.year = :year',
      };

      params = {
        ...params,
        year: year,
      };
    }

    /*if (byScope) {
    filters = {
      ...filters,
      byScope: 'obj.scope = :scope',
    };
    params = {
      ...params,
      scope: byScope,
    };
  }
*/
    // if (byLiveFlag !== undefined) {
    //   filters = {
    //     ...filters,
    //     liveFlag: 'obj.flag = :liveFlag',
    //   };
    //   params = {
    //     ...params,
    //     liveFlag: byLiveFlag,
    //   };
    // }
    let start = 0;
    let limit = 0;

    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = [];
    // //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);

    return await api
      .getLeanScoreCard({joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        return [...data.list];
        // setProdKpisTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
        return [];
      });
  }, []);

  useEffect(() => {
    let {month, years} = filter;
    let year = Number(years[years.length - 1]);
    _fetchData({month: month, year: year})
      .then((data: any) => {
        let {datasourcingbycountry, datasourcingbyregion} = data;
        setDataSourcingMetricByCountryTbl([...datasourcingbycountry]);
        setDataSourcingMetricByRegionnTbl([...datasourcingbyregion]);

        _fetchDataKpi({month: month, year: year})
          .then((dataKpi) => {
            setDataPphTargetTbl([]);
            if (dataKpi && dataKpi.length > 0) {
              //console.log.*$
              let dataTargetMeasurement = [];
              let pphTarget = {};
              let pphPerToTarget = {};
              let pphTargetMin = {};
              let pphPerToTargetMin = {};

              let pphTargetDataQ1 = dataKpi.filter((dt) => dt.qoy === 1)[0];
              let pphTargetDataQ2 = dataKpi.filter((dt) => dt.qoy === 2)[0];
              let pphTargetDataQ3 = dataKpi.filter((dt) => dt.qoy === 3)[0];
              let pphTargetDataQ4 = dataKpi.filter((dt) => dt.qoy === 4)[0];

              pphTarget = {
                ...pphTarget,
                type: `PPH Stretch Target ${year} (Factory facing goal)`,
                month1: pphTargetDataQ1.targetValue,
                month2: pphTargetDataQ1.targetValue,
                month3: pphTargetDataQ1.targetValue,
                month4: pphTargetDataQ2.targetValue,
                month5: pphTargetDataQ2.targetValue,
                month6: pphTargetDataQ2.targetValue,
                month7: pphTargetDataQ3.targetValue,
                month8: pphTargetDataQ3.targetValue,
                month9: pphTargetDataQ3.targetValue,
                month10: pphTargetDataQ4.targetValue,
                month11: pphTargetDataQ4.targetValue,
                month12: pphTargetDataQ4.targetValue,
              };

              pphPerToTarget = {
                ...pphPerToTarget,
                type: `PPH % Perf to Target ${year}`,
                month1: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphJan / pphTarget.month1,
                  2,
                ),
                month2: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphFeb / pphTarget.month2,
                  2,
                ),
                month3: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphMar / pphTarget.month3,
                  2,
                ),
                month4: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphApr / pphTarget.month4,
                  2,
                ),
                month5: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphMay / pphTarget.month5,
                  2,
                ),
                month6: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphJun / pphTarget.month6,
                  2,
                ),
                month7: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphJul / pphTarget.month7,
                  2,
                ),
                month8: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphAug / pphTarget.month8,
                  2,
                ),
                month9: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphSep / pphTarget.month9,
                  2,
                ),
                month10: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphOct / pphTarget.month10,
                  2,
                ),
                month11: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphNov / pphTarget.month11,
                  2,
                ),
                month12: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphDec / pphTarget.month12,
                  2,
                ),
              };

              pphTargetMin = {
                ...pphTargetMin,
                type: `PPH - Minimum Expectation ${year}`,
                month1: pphTargetDataQ1.value1,
                month2: pphTargetDataQ1.value1,
                month3: pphTargetDataQ1.value1,
                month4: pphTargetDataQ2.value1,
                month5: pphTargetDataQ2.value1,
                month6: pphTargetDataQ2.value1,
                month7: pphTargetDataQ3.value1,
                month8: pphTargetDataQ3.value1,
                month9: pphTargetDataQ3.value1,
                month10: pphTargetDataQ4.value1,
                month11: pphTargetDataQ4.value1,
                month12: pphTargetDataQ4.value1,
              };

              pphPerToTargetMin = {
                ...pphPerToTargetMin,
                type: `PPH % Perf to Minnimum Expectation ${year}`,
                month1: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphJan / pphTargetMin.month1,
                  2,
                ),
                month2: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphFeb / pphTargetMin.month2,
                  2,
                ),
                month3: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphMar / pphTargetMin.month3,
                  2,
                ),
                month4: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphApr / pphTargetMin.month4,
                  2,
                ),
                month5: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphMay / pphTargetMin.month5,
                  2,
                ),
                month6: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphJun / pphTargetMin.month6,
                  2,
                ),
                month7: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphJul / pphTargetMin.month7,
                  2,
                ),
                month8: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphAug / pphTargetMin.month8,
                  2,
                ),
                month9: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphSep / pphTargetMin.month9,
                  2,
                ),
                month10: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphOct / pphTargetMin.month10,
                  2,
                ),
                month11: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphNov / pphTargetMin.month11,
                  2,
                ),
                month12: Utils.fmtDecimal(
                  datasourcingbyregion[0].pphDec / pphTargetMin.month12,
                  2,
                ),
              };

              //dataTargetMeasurement.push(pphPerToTargetMin);
              dataTargetMeasurement = [
                pphTarget,
                pphPerToTarget,
                pphTargetMin,
                pphPerToTargetMin,
              ];
              setDataPphTargetTbl([...dataTargetMeasurement]);
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  }, [filter, _fetchData, _fetchDataKpi]);

  const _onInvsPaging = (evt: any) => {
    //   //console.log.*$
    //   setetrRecsTblLimit(evt.rows);
    //   setEtrRecsTblStart(evt.first);
  };

  const _renderCountry: TblColRender = (row: any, col: any) => {
    let {country} = row;
    return country ? `PPH ${country.name} (Direct) SI based ` : '';
  };

  const _renderRegion: TblColRender = (row: any, col: any) => {
    let {region} = row;
    return region ? `PPH (All ${region.name} factory, Direct) - SI based` : '';
  };

  const _renderTarget: TblColRender = (row: any, col: any) => {
    let {type} = row;
    let {field} = col;
    let val = row[field] || 0;
    //console.log.*$
    let value = 0;
    if (type.includes('%', 0)) {
      //console.log.*$
      switch (field) {
        case 'month1':
          value = dataSourcingMetricByRegionnTbl[0]['pphJan'];
          if (type.includes('Minnimum', 0)) {
            let minTarget = dataPphTargetTbl[2]['month1'];
            if (value >= minTarget) {
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget && value >= minTarget * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget * 0.98 && value >= minTarget * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          } else {
            let target = dataPphTargetTbl[0]['month1'];
            if (value >= target) {
              ////console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target && value >= target * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target * 0.98 && value >= target * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          }
        case 'month2':
          value = dataSourcingMetricByRegionnTbl[0]['pphFeb'];
          if (type.includes('Minnimum', 0)) {
            let minTarget = dataPphTargetTbl[2]['month2'];
            if (value >= minTarget) {
              //console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget && value >= minTarget * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget * 0.98 && value >= minTarget * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          } else {
            let target = dataPphTargetTbl[0]['month2'];
            if (value >= target) {
              ////console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target && value >= target * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target * 0.98 && value >= target * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          }

        case 'month3':
          value = dataSourcingMetricByRegionnTbl[0]['pphMar'];
          if (type.includes('Minnimum', 0)) {
            let minTarget = dataPphTargetTbl[2]['month3'];
            if (value >= minTarget) {
              //  //console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget && value >= minTarget * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget * 0.98 && value >= minTarget * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          } else {
            let target = dataPphTargetTbl[0]['month3'];
            if (value >= target) {
              ////console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target && value >= target * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target * 0.98 && value >= target * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          }

        case 'month4':
          value = dataSourcingMetricByRegionnTbl[0]['pphApr'];
          if (type.includes('Minnimum', 0)) {
            let minTarget = dataPphTargetTbl[2]['month4'];
            if (value >= minTarget) {
              //   //console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget && value >= minTarget * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget * 0.98 && value >= minTarget * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          } else {
            let target = dataPphTargetTbl[0]['month4'];
            if (value >= target) {
              ////console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target && value >= target * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target * 0.98 && value >= target * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          }

        case 'month5':
          value = dataSourcingMetricByRegionnTbl[0]['pphMay'];
          if (type.includes('Minnimum', 0)) {
            let minTarget = dataPphTargetTbl[2]['month5'];
            if (value >= minTarget) {
              ////console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget && value >= minTarget * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget * 0.98 && value >= minTarget * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          } else {
            let target = dataPphTargetTbl[0]['month5'];
            if (value >= target) {
              ////console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target && value >= target * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target * 0.98 && value >= target * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          }
        case 'month6':
          value = dataSourcingMetricByRegionnTbl[0]['pphJun'];
          if (type.includes('Minnimum', 0)) {
            let minTarget = dataPphTargetTbl[2]['month6'];
            if (value >= minTarget) {
              //    //console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget && value >= minTarget * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget * 0.98 && value >= minTarget * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          } else {
            let target = dataPphTargetTbl[0]['month6'];
            if (value >= target) {
              ////console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target && value >= target * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target * 0.98 && value >= target * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          }

        case 'month7':
          value = dataSourcingMetricByRegionnTbl[0]['pphJul'];
          if (type.includes('Minnimum', 0)) {
            let minTarget = dataPphTargetTbl[2]['month7'];
            if (value >= minTarget) {
              // //console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget && value >= minTarget * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget * 0.98 && value >= minTarget * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          } else {
            let target = dataPphTargetTbl[0]['month7'];
            if (value >= target) {
              ////console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target && value >= target * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target * 0.98 && value >= target * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          }
        case 'month8':
          value = dataSourcingMetricByRegionnTbl[0]['pphAug'];
          if (type.includes('Minnimum', 0)) {
            let minTarget = dataPphTargetTbl[2]['month8'];
            if (value >= minTarget) {
              // //console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget && value >= minTarget * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget * 0.98 && value >= minTarget * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          } else {
            let target = dataPphTargetTbl[0]['month8'];
            if (value >= target) {
              ////console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target && value >= target * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target * 0.98 && value >= target * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          }
        case 'month9':
          value = dataSourcingMetricByRegionnTbl[0]['pphSep'];
          if (type.includes('Minnimum', 0)) {
            let minTarget = dataPphTargetTbl[2]['month9'];
            if (value >= minTarget) {
              // //console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget && value >= minTarget * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget * 0.98 && value >= minTarget * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          } else {
            let target = dataPphTargetTbl[0]['month9'];
            if (value >= target) {
              ////console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target && value >= target * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target * 0.98 && value >= target * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          }

        case 'month10':
          value = dataSourcingMetricByRegionnTbl[0]['pphOct'];
          if (type.includes('Minnimum', 0)) {
            let minTarget = dataPphTargetTbl[2]['month10'];
            if (value >= minTarget) {
              ////console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget && value >= minTarget * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget * 0.98 && value >= minTarget * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          } else {
            let target = dataPphTargetTbl[0]['month10'];
            if (value >= target) {
              ////console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target && value >= target * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target * 0.98 && value >= target * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          }
        case 'month11':
          value = dataSourcingMetricByRegionnTbl[0]['pphNov'];
          if (type.includes('Minnimum', 0)) {
            let minTarget = dataPphTargetTbl[2]['month11'];
            if (value >= minTarget) {
              ////console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget && value >= minTarget * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget * 0.98 && value >= minTarget * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          } else {
            let target = dataPphTargetTbl[0]['month11'];
            if (value >= target) {
              ////console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target && value >= target * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target * 0.98 && value >= target * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          }
        case 'month12':
          value = dataSourcingMetricByRegionnTbl[0]['pphDec'];
          if (type.includes('Minnimum', 0)) {
            let minTarget = dataPphTargetTbl[2]['month12'];
            if (value >= minTarget) {
              // //console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget && value >= minTarget * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < minTarget * 0.98 && value >= minTarget * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          } else {
            let target = dataPphTargetTbl[0]['month12'];
            if (value >= target) {
              ////console.log.*$
              return (
                <div
                  style={{
                    backgroundColor: '#8db3e2',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target && value >= target * 0.98) {
              return (
                <div
                  style={{
                    backgroundColor: '#02b04f',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else if (value < target * 0.98 && value >= target * 0.95) {
              return (
                <div
                  style={{
                    backgroundColor: '#ffff00',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: '#fe0000',
                    padding: '5px',
                  }}>{`${Utils.fmtDecimal(val * 100, 0)}%`}</div>
              );
            }
          }

        default:
        // code block
      }
    }
    return val;
  };

  ////console.log.*$

  return (
    <>
      <Toast ref={toastRef} />
      <DataTable
        // header={header || t('factory.title')}
        value={dataSourcingMetricByCountryTbl}
        dataKey="ranking"
        lazy={true}
        // paginator={true}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        // rows={factoriesTblLimit}
        // first={factoriesTblStart}
        //  totalRecords={factoriesTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        // selection={selFactory}
        //  onSelectionChange={_onFactorySelected}
        onPage={_onInvsPaging}
        //sortField={sortField}
        // sortOrder={sortOrder}
        //</> onSort={_onSort}
      >
        <Column
          field="country"
          header="#"
          headerStyle={{align: 'center'}}
          body={_renderCountry}
        />

        <Column
          field="pphJan"
          header={`Jan - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphFeb"
          header={`Feb - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphMar"
          header={`Mar - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphApr"
          header={`Apr - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphMay"
          header={`May - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphJun"
          header={`Jun - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphJul"
          header={`Jul - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphAug"
          header={`Aug - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphSep"
          header={`Sep - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphOct"
          header={`Oct - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphNov"
          header={`Nov - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphDec"
          header={`Dec - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />
      </DataTable>

      <DataTable
        // header={header || t('factory.title')}
        value={dataSourcingMetricByRegionnTbl}
        dataKey="ranking"
        lazy={true}
        // paginator={true}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        // rows={factoriesTblLimit}
        // first={factoriesTblStart}
        //  totalRecords={factoriesTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        // selection={selFactory}
        //  onSelectionChange={_onFactorySelected}
        onPage={_onInvsPaging}
        //sortField={sortField}
        // sortOrder={sortOrder}
        //</> onSort={_onSort}
      >
        <Column
          field="region"
          header="#"
          headerStyle={{align: 'center'}}
          body={_renderRegion}
        />

        <Column
          field="pphJan"
          header={`Jan - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphFeb"
          header={`Feb - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphMar"
          header={`Mar - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphApr"
          header={`Apr - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphMay"
          header={`May - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphJun"
          header={`Jun - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphJul"
          header={`Jul - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphAug"
          header={`Aug - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphSep"
          header={`Sep - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphOct"
          header={`Oct - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphNov"
          header={`Nov - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />

        <Column
          field="pphDec"
          header={`Dec - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
        />
      </DataTable>

      <DataTable
        // header={header || t('factory.title')}
        value={dataPphTargetTbl}
        dataKey="ranking"
        lazy={true}
        // paginator={true}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        // rows={factoriesTblLimit}
        // first={factoriesTblStart}
        //  totalRecords={factoriesTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        // selection={selFactory}
        //  onSelectionChange={_onFactorySelected}
        onPage={_onInvsPaging}
        //sortField={sortField}
        // sortOrder={sortOrder}
        //</> onSort={_onSort}
      >
        <Column field="type" header="#" headerStyle={{align: 'center'}} />

        <Column
          field="month1"
          header={`Jan - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
          body={_renderTarget}
        />

        <Column
          field="month2"
          header={`Feb - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
          body={_renderTarget}
        />

        <Column
          field="month3"
          header={`Mar - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
          body={_renderTarget}
        />

        <Column
          field="month4"
          header={`Apr - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
          body={_renderTarget}
        />

        <Column
          field="month5"
          header={`May - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
          body={_renderTarget}
        />

        <Column
          field="month6"
          header={`Jun - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
          body={_renderTarget}
        />

        <Column
          field="month7"
          header={`Jul - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
          body={_renderTarget}
        />

        <Column
          field="month8"
          header={`Aug - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
          body={_renderTarget}
        />

        <Column
          field="month9"
          header={`Sep - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
          body={_renderTarget}
        />

        <Column
          field="month10"
          header={`Oct - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
          body={_renderTarget}
        />

        <Column
          field="month11"
          header={`Nov - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
          body={_renderTarget}
        />

        <Column
          field="month12"
          header={`Dec - ${filter.years[filter.years.length - 1]}`}
          headerStyle={{align: 'center'}}
          body={_renderTarget}
        />
      </DataTable>
    </>
  );
}
