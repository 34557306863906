// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {AutoComplete} from 'primereact/autocomplete';
//$FlowFixMe[cannot-resolve-module]
import {Calendar} from 'primereact/calendar';
//$FlowFixMe[cannot-resolve-module]
import {InputNumber} from 'primereact/inputnumber';
import type {ApiProps} from '../../service/Api';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  readOnly?: boolean,
  audit: any,
  onAction: (evt: any) => void,
};

const defMiniLineAudit = {
  __cls__: 'com.nb.lmrs.model.entity.MiniLineAudit',
  flag: 0,
  description: '',
  audDate: null,
  planedNewMinls: 0,
  nbMiniLines: 0,
  nbBigLines: 0,
  totalLines: 0,
  minlCap: 0,
  biglCap: 0,
  tarMinlRate: 0,
  minlVolRate: 0,
  minlRate: 0,
  factory: null,
};

export function MiniLineAuditForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {readOnly, audit} = props;
  const [editMiniLineAudit, setEditMiniLineAudit] = useState();
  const [suggFactories, setSuggFactories] = useState([]);
  const [defFactory, setDefFactory] = useState();

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      setDefFactory(factories[0]);
    }
  }, [auth]);

  useEffect(() => {
    // console.log('Effect // init machLine', machLine);
    let obj = audit || {};
    obj = {
      ...defMiniLineAudit,
      ...obj,
    };
    let {audDate} = obj;
    if (audDate) {
      obj = {
        ...obj,
        audDate: Utils.toJsDate(audDate),
      };
    }

    let {tarMinlRate, minlRate, minlVolRate} = obj;
    obj = {
      ...obj,
      tarMinlRate: tarMinlRate * 100 || 0,
      minlRate: minlRate * 100 || 0,
      minlVolRate: minlVolRate * 100 || 0,
    };

    let isNew = !obj.id;
    if (isNew && (!obj.factory || !obj.factory.id)) {
      obj['factory'] = defFactory;
    }

    let {factory} = obj;
    if (factory) {
      factory.label = `${factory.code} :: ${factory.name}`;
    }

    // console.log('edit plan', plan);
    setEditMiniLineAudit(obj);
  }, [audit, defFactory]);

  const _calcMiniLineAudit = useCallback((rec: any) => {
    let res = {...rec};
    let {nbMiniLines, nbBigLines, minlCap, biglCap} = res;
    let totalLines = nbBigLines + nbMiniLines;
    res['totalLines'] = totalLines;

    let minlRate = 0;
    let minlVolRate = 0;
    if (totalLines > 0) {
      minlRate = Utils.round((nbMiniLines * 100) / totalLines, 2);
      let totalCap = nbMiniLines * minlCap + nbBigLines * biglCap;
      if (totalCap > 0) {
        let miCap = nbMiniLines * minlCap;
        minlVolRate = Utils.round((miCap * 100) / totalCap, 2);
      }
    }
    res['minlRate'] = minlRate;
    res['minlVolRate'] = minlVolRate;

    return res;
  }, []);

  const _validateMiniLineAudit: () => any = () => {
    let obj: any = {...editMiniLineAudit};

    let {factory} = obj;
    if (!factory || !factory.id) {
      UIUtils.showError({
        detail: 'Please select factory',
        toast: toastRef.current,
      });
      return false;
    }

    let {audDate} = obj;
    if (!audDate) {
      UIUtils.showError({
        detail: 'Missing month',
        toast: toastRef.current,
      });
      return false;
    }
    obj['audDate'] = Utils.jsDateToStr(audDate);

    let {tarMinlRate, minlRate, minlVolRate} = obj;
    obj = {
      ...obj,
      tarMinlRate: tarMinlRate / 100,
      minlRate: minlRate / 100,
      minlVolRate: minlVolRate / 100,
    };

    return obj;
  };

  const _processFieldChanged: (fdName: string, value: any) => any = (
    fdName: string,
    value: any,
  ) => {
    let rec = {...editMiniLineAudit};
    rec[fdName] = value;
    rec = _calcMiniLineAudit(rec);
    setEditMiniLineAudit({...rec});
  };

  const _onSuggFactories: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getFactories({search, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          console.log('getFactories // return errors', errors);
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        console.log('getFactories // resp data', data);
        let lst = [...data.list];
        lst.forEach((it) => {
          it.label = `${it.code} :: ${it.name}`;
        });
        setSuggFactories([...lst]);
      })
      .catch((err) => {
        console.log('getFactories // network error', err);
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onSave = (evt: any) => {
    // setShowCfmDlg(false);
    let obj = _validateMiniLineAudit();
    if (!obj) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    confirmDialog({
      message: obj.id
        ? 'Are you sure you want to update mini line record?'
        : 'Are you sure you want to create mini line record?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateMiniLineAudit({...obj})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              console.log('updateMiniLineAudit // return errors', errors);
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'machLineSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            console.log('updateMiniLineAudit // network error', error);
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  if (!editMiniLineAudit) {
    return null;
  }

  const saveLbl = editMiniLineAudit.id ? 'Update' : 'Create';
  // const isNew = !editMiniLineAudit.id;
  // console.log('isnew', isNew);

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/edit mini line record">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <AutoComplete
              placeholder="Select factory"
              dropdown
              suggestions={suggFactories}
              field="label"
              value={editMiniLineAudit.factory}
              completeMethod={_onSuggFactories}
              onChange={(e) => _processFieldChanged('factory', e.value)}
              disabled={readOnly}
            />
          </div>

          <div className="p-col-6">
            <span className="p-float-label">
              <Calendar
                value={editMiniLineAudit.audDate}
                dateFormat="mm/yy"
                onChange={(e) => _processFieldChanged('audDate', e.value)}
                disabled={readOnly || editMiniLineAudit.id}
              />
              <label>Month</label>
            </span>
          </div>

          <div className="p-col-6">
            <span className="p-float-label">
              <InputNumber
                value={editMiniLineAudit.planedNewMinls}
                onValueChange={(e) =>
                  _processFieldChanged('planedNewMinls', e.value)
                }
                disabled={readOnly}
              />
              <label>New mini lines</label>
            </span>
          </div>

          <div className="p-col-6">
            <span className="p-float-label">
              <InputNumber
                value={editMiniLineAudit.nbMiniLines}
                onValueChange={(e) =>
                  _processFieldChanged('nbMiniLines', e.value)
                }
                disabled={readOnly}
              />
              <label>Total mini lines</label>
            </span>
          </div>

          <div className="p-col-6">
            <span className="p-float-label">
              <InputNumber
                value={editMiniLineAudit.nbBigLines}
                onValueChange={(e) =>
                  _processFieldChanged('nbBigLines', e.value)
                }
                disabled={readOnly}
              />
              <label>Number of big lines/Traditional lines</label>
            </span>
          </div>

          <div className="p-col-6">
            <span className="p-float-label">
              <InputNumber
                value={editMiniLineAudit.totalLines}
                onValueChange={(e) => console.log('e')}
                disabled={true}
              />
              <label>Total lines</label>
            </span>
          </div>

          <div className="p-col-6">
            <span className="p-float-label">
              <InputNumber
                inputId="minmaxfraction"
                value={editMiniLineAudit.tarMinlRate}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                onValueChange={(e) =>
                  _processFieldChanged('tarMinlRate', e.value)
                }
                disabled={true}
              />
              <label>Mini line roadmap target(%)</label>
            </span>
          </div>

          <div className="p-col-6">
            <span className="p-float-label">
              <InputNumber
                value={editMiniLineAudit.minlCap}
                onValueChange={(e) => _processFieldChanged('minlCap', e.value)}
                disabled={readOnly}
              />
              <label>Mini line designed output (/8h)</label>
            </span>
          </div>

          <div className="p-col-6">
            <span className="p-float-label">
              <InputNumber
                value={editMiniLineAudit.biglCap}
                onValueChange={(e) => _processFieldChanged('biglCap', e.value)}
                disabled={readOnly}
              />
              <label>Big line designed output (/8h)</label>
            </span>
          </div>

          <div className="p-col-6">
            <span className="p-float-label">
              <InputNumber
                inputId="minmaxfraction"
                value={editMiniLineAudit.minlVolRate}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                disabled={true}
              />
              <label>Mini line % by volume</label>
            </span>
          </div>

          <div className="p-col-6">
            <span className="p-float-label">
              <InputNumber
                inputId="minmaxfraction"
                value={editMiniLineAudit.minlRate}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                disabled={true}
              />
              <label>Mini line % by total lines</label>
            </span>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <InputTextarea
                rows={2}
                value={editMiniLineAudit.description}
                onChange={(e) => {
                  setEditMiniLineAudit({
                    ...editMiniLineAudit,
                    description: e.target.value,
                  });
                }}
                disabled={readOnly}
              />
              <label>Summary</label>
            </span>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
