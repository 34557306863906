// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';
//$FlowFixMe
import {debounceTime} from 'rxjs/operators';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {Chart} from 'primereact/chart';
//$FlowFixMe
import {Button} from 'primereact/button';

//$FlowFixMe[cannot-resolve-module]
import ChartDataLabels from 'chartjs-plugin-datalabels';

import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
// import {AuthUtils} from '../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {pphColors, kpiColors, getChartOptions} from './CommonChart';

type Props = {
  scope?: string,
  regions?: any[],
  countries?: any[],
  facGroups?: any[],
  factories?: any[],
  year?: any,
  expanded?: boolean,
  onAction?: EvtHandler,
};

const chartPlugins = [ChartDataLabels];

export function LeanScoreQuarterly(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const [chartOptions] = useState(getChartOptions);

  const {
    scope,
    regions,
    countries,
    facGroups,
    factories,
    year,
    expanded,
    onAction,
  } = props;
  // console.log('scope', scope);

  const [barData, setBarData] = useState();

  const _handleChartClick: EvtHandler = (evt: any) => {
    //  console.log('Chart click event', evt);
    let {chart} = evt;
    //  console.log('chart', chart);
    if (!chart) {
      return;
    }
    let actEls = chart.getActiveElements();
    //  console.log('Active elms', actEls);

    let datasets = Utils.get(chart, 'config._config.data.datasets', []);
    // console.log('Datasets: ', datasets);
    if (actEls.length < 1 || datasets.length < 1) {
      // console.log('No active element or dataset');
      // return;
    }
    setTimeout(() => {
      onAction &&
        onAction({
          type: 'chartClick',
          src: 'leanScoreQuarterly',
          value: {
            evt,
          },
        });
    }, 100);
  };

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // console.log('effect // init api');
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchData = useCallback(async (options: any) => {
    let {scope, regions, countries, facGroups, factories, year} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    if (scope === 'region' || scope === 'country' || scope === 'facGroup') {
      return [];
    }
    scope = scope || 'region';
    let pYear = Number(year) || 0;

    let joins = [
      {
        type: 'join',
        expr: 'obj.factory',
        alias: 'fac',
      },
      {
        type: 'join',
        expr: 'fac.country',
        alias: 'coun',
      },
      {
        type: 'join',
        expr: 'coun.region',
        alias: 'reg',
      },
    ];

    let filters = {
      liveFlag: 'obj.flag > -1',
    };

    let params = {};
    let groupBys = ['obj.audHoy'];

    if (scope === 'region') {
      groupBys = ['reg.code', ...groupBys];
      regions = regions || [];
      if (regions.length > 0) {
        let regIds = Utils.getVals(regions, 'id', [0]).map((it) => {
          return {
            type: 'long',
            value: it,
          };
        });
        filters = {
          ...filters,
          byRegIds: 'reg.id in (:regIds)',
        };
        params = {
          ...params,
          regIds,
        };
      }
    } else if (scope === 'country') {
      groupBys = ['coun.code', ...groupBys];
      countries = countries || [];
      let counIds = Utils.getVals(countries || [], 'id', [0]).map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
      filters = {
        ...filters,
        byCounIds: 'coun.id in (:counIds)',
      };
      params = {
        ...params,
        counIds,
      };
    } else if (scope === 'facGroup') {
      groupBys = ['fg.code', ...groupBys];
      joins = [
        ...joins,
        {
          type: '',
          expr: 'FacGroup',
          alias: 'fg',
        },
        {
          type: 'join',
          expr: 'fg.facLnks',
          alias: 'facLnk',
        },
      ];
      filters = {
        ...filters,
        jnFacGroup: 'fac.id = facLnk.factory.id',
      };
      let fgIds = Utils.getVals(facGroups || [], 'id', [0]).map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
      filters = {
        ...filters,
        byFacGroupIds: 'fg.id in (:fgIds)',
      };
      params = {
        ...params,
        fgIds,
      };
    } else if (scope === 'factory') {
      groupBys = ['fac.code', ...groupBys];
      let facIds = Utils.getVals(factories || [], 'id', [0]).map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
      filters = {
        ...filters,
        byFacIds: 'fac.id in (:facIds)',
      };
      params = {
        ...params,
        facIds,
      };
    }
    filters = {
      ...filters,
      year: 'obj.audYear = :year',
    };
    params = {
      ...params,
      year: pYear,
    };

    let fields = [...groupBys, 'avg(obj.avgScore)'];
    let sorts = [...groupBys];

    return await api
      .fetchLeanAuditData({
        fields,
        joins,
        groupBys,
        filters,
        params,
        sorts,
        start: 0,
        limit: 0,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        // console.log('fetch production data response', resp);
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log('Fetch lean audit data // return errors', errors);
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        let recs = [...data.list];
        recs.forEach((it) => {
          it[2] = Utils.round(it[2]);
        });
        return [...recs];
      })
      .catch((error) => {
        // console.log('Fetch lean audit data // network error', error);
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchKpiDataReg = useCallback(async (options: any) => {
    let {scope, year} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api || scope === 'factory') {
      return [];
    }
    let pYear = Number(year) || 0;

    let joins = [];
    let filters = {
      byType: 'obj.kpiType = :kpiType',
      byYear: 'obj.year = :byYear',
      byQoy: 'obj.qoy = :byQoy1 or obj.qoy = :byQoy2',
    };
    let params = {
      kpiType: {
        type: 'enum:com.nb.lmrs.model.KpiType',
        value: 'LEAN_SCORE',
      },
      byYear: pYear,
      byQoy1: 1,
      byQoy2: 3,
    };

    let sorts = ['obj.qoy'];
    let start = 0;
    let limit = 0;
    return await api
      .getLeanScoreCard({joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        // console.log('load production kpi response', resp);
        let {errors, data} = resp;
        if (errors.length > 0) {
          // console.log('Load production kpi // return errors', errors);
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }

        return [...data.list].map((dt) => dt.targetValue);
      })
      .catch((error) => {
        //  console.log('Load production kpi // network error', error);
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchKpiData = useCallback(async (options: any) => {
    let {scope, factories, factory, lines, line, year} = options;
    let api: ?AdminApi = apiRef.current;
    if (
      !api ||
      scope === 'region' ||
      scope === 'country' ||
      scope === 'facGroup'
    ) {
      return [];
    }

    if (factories && factories.length > 0) {
      let pms = factories.map((fac) => {
        return _fetchKpiData({
          ...options,
          factory: fac,
          factories: [],
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    if (lines && lines.length > 0) {
      let pms = lines.map((ln) => {
        return _fetchKpiData({
          ...options,
          line: ln,
          lines: [],
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    let reqData = {
      scope,
      periodType: 'HALF_YEAR',
      kpiType: 'LEAN_SCORE',
      year,
    };

    let serie = '';
    if (factory) {
      reqData = {
        ...reqData,
        factoryId: factory.id,
      };
      serie = `Target-${factory.code}`;
    }

    if (line) {
      reqData = {
        ...reqData,
        lineId: line.id,
      };
      serie = `TARGET-${line.code}`;
    }

    return await api
      .loadProdKpiData({
        data: {...reqData},
      })
      .then((resp) => resp.data)
      .then((resp) => {
        // console.log('load production kpi response', resp);
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log('Load production kpi // return errors', errors);
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        return [
          {
            ...data,
            serie,
          },
        ];
      })
      .catch((error) => {
        //   console.log('Load production kpi // network error', error);
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchDataByGroupCountryRegion = useCallback(async (options: any) => {
    let {
      scope,
      regions,
      region,
      countries,
      country,
      facGroups,
      facGroup,

      year,
    } = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (scope === 'factory') return [];

    if ((regions?.length === 0 || !regions) && scope === 'region') return [];
    if ((countries?.length === 0 || !countries) && scope === 'country')
      return [];
    if ((facGroups?.length === 0 || !facGroups) && scope === 'facGroup')
      return [];

    let pYear = Number(year) || 0;

    if (regions && regions.length > 0 && !region && scope === 'region') {
      let pms = regions.map((reg) => {
        return _fetchDataByGroupCountryRegion({
          ...options,
          region: reg,
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    if (countries && countries.length > 0 && !country && scope === 'country') {
      let pms = countries.map((coun) => {
        return _fetchDataByGroupCountryRegion({
          ...options,
          country: coun,
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    if (
      facGroups &&
      facGroups.length > 0 &&
      !facGroup &&
      scope === 'facGroup'
    ) {
      let pms = facGroups.map((facGr) => {
        //  console.log('hihihhi');
        return _fetchDataByGroupCountryRegion({
          ...options,
          facGroup: facGr,
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }
    let reqData = {};

    reqData = {...reqData, year: pYear, scope};
    let serie = '';
    if (region) {
      reqData = {...reqData, regionId: region.id};
      serie = region.code;
    }

    if (country) {
      reqData = {...reqData, countryId: country.id};
      serie = country.code;
    }

    if (facGroup) {
      reqData = {...reqData, groupId: facGroup.id};
      serie = facGroup.code;
    }

    return await api
      .getLeanScore({
        data: {
          ...reqData,
        },
      })
      .then((resp) => resp.data)
      .then((resp) => {
        //  console.log('fetch production data response', resp);
        let {errors, data} = resp;
        if (errors.length > 0) {
          // console.log('Fetch lean audit data // return errors', errors);
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }

        return [{...data, serie}];
      })
      .catch((error) => {
        // console.log('Fetch lean audit data // network error', error);
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchChartData = useCallback(
    async (options: any) => {
      let {scope, year} = options;
      switch (scope) {
        case 'factory':
          options = {
            ...options,
            line: null,
            lines: [],
          };
          break;
        default:
          break;
      }
      let labels = [`1st Half ${year}`, `2nd Half ${year}`];

      let pm1 = _fetchKpiData(options).then((data) => {
        // console.log('Got kpi data', data);
        return data.map((rec, ind) => {
          let ci = ind % kpiColors.length;
          return {
            type: 'line',
            label: rec.serie,
            backgroundColor: kpiColors[ci],
            borderColor: kpiColors[ci],
            borderWidth: 1,
            data: [...rec.targetValues],
            datalabels: {
              align: 'top',
              offset: 2,
              color: kpiColors[ci],
            },
          };
        });
      });

      let pm2 = _fetchData(options).then((data) => {
        let series = [];
        let recs: any[] = data || [];
        //  console.log('audit recs', recs);
        recs
          .map((it) => it[0])
          .forEach((it) => {
            if (!series.includes(it)) {
              series = [...series, it];
            }
          });
        // console.log('count coes', series);

        const _evalDataPoint: (dpInd: number) => any[] = (dpInd: number) => {
          let datas = series.map((serie, ind) => {
            let dtMap = {};
            recs
              .filter((rc) => rc[0] === serie)
              .forEach((rc) => {
                dtMap[rc[1]] = rc[dpInd];
              });
            let lst = labels.map((it, ind) => {
              let val = dtMap[ind + 1];
              return val ? val : 0;
            });
            return lst;
          });
          return datas;
        };

        let pphDatas = _evalDataPoint(2);
        // console.log('datas', datas);
        let pphDatasets = series.map((serie, ind) => {
          let ci = ind % pphColors.length;
          return {
            label: serie,
            backgroundColor: pphColors[ci],
            data: pphDatas[ind],
            datalabels: {
              // align: 'top',
              // offset: 5,
              color: '#FFFFFF',
            },
          };
        });

        let chartData = {
          labels,
          datasets: [...pphDatasets],
        };

        // setBarData(chartData);
        return chartData;
      });

      let pm3 = _fetchDataByGroupCountryRegion(options).then((data) => {
        //  console.log(data);
        let pphDatasets = data.map((dt, ind) => {
          let ci = ind % pphColors.length;
          return {
            label: dt.serie,
            backgroundColor: pphColors[ci],
            data: [dt.score1, dt.score2],
            datalabels: {
              // align: 'top',
              // offset: 5,
              color: '#FFFFFF',
            },
          };
        });

        let chartDatakk = {
          labels,
          datasets: [...pphDatasets],
        };

        // setBarData(chartData);
        return chartDatakk;
      });

      let pm4 = _fetchKpiDataReg(options).then((data) => {
        return [
          {
            type: 'line',
            label: 'Target',
            backgroundColor: kpiColors[0],
            borderColor: kpiColors[0],
            borderWidth: 1,
            data: [...data],
            datalabels: {
              align: 'top',
              offset: 2,
              color: kpiColors[0],
            },
          },
        ];
      });
      Promise.all([pm1, pm2, pm3, pm4]).then((data) => {
        let datasets = [];
        let [kpiData, chartData, chartDataReg, kpiDataReg] = data;
        let datasetsFac = [...chartData.datasets];
        let datasetsReg = [...chartDataReg.datasets];

        if (datasetsFac.length > 0) {
          datasets = [...kpiData, ...datasetsFac];
        }
        if (datasetsReg.length > 0) {
          datasets = [...kpiDataReg, ...datasetsReg];
        }
        setBarData({
          ...chartData,
          datasets,
        });
      });
    },
    [
      _fetchData,
      _fetchKpiData,
      _fetchDataByGroupCountryRegion,
      _fetchKpiDataReg,
    ],
  );

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchChartData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      // console.log('Stop fetch data event source');
      subj.complete();
    };
  }, [_fetchChartData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      scope,
      regions,
      countries,
      facGroups,
      factories,
      year,
    });
  }, [scope, regions, countries, facGroups, factories, year]);

  const _onToggleExpand: EvtHandler = (evt: any) => {
    onAction &&
      onAction({
        type: 'toggleExpand',
        value: !expanded,
      });
  };

  // const [chartOptions] = useState({
  //   ...getChartOptions(),
  //   events: ['click'],
  //   onClick: _handleChartClick,
  // });

  // const chartOptions = getChartOptions();
  // const rtChartOpts = {
  //   ...(chartOptions || {}),
  //   events: ['click'],
  //   onClick: _handleChartClick,
  // };

  const iconName = expanded
    ? 'pi pi-angle-double-left'
    : 'pi pi-angle-double-right';

  const icons = (
    <Button
      icon={iconName}
      className="p-button-rounded p-button-text"
      style={{padding: 0, height: '1.2rem', color: '#FFFFFF'}}
      onClick={_onToggleExpand}
    />
  );

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="Half Year Lean Assessment" icons={icons}>
        <Chart
          type="bar"
          data={barData}
          options={{
            ...chartOptions,
            events: ['click'],
            onClick: _handleChartClick,
          }}
          plugins={chartPlugins}></Chart>
      </Panel>
    </>
  );
}
