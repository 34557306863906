//$FlowFixMe[cannot-resolve-module]
import { InputNumber } from 'primereact/inputnumber';
type Props = {
  onAction: (evt: any) => void,
};
export function FilterById(props: Props): React$Node {
 const onChange = (e) =>{
    let {onAction} = props;
   onAction && onAction(e.value);
 }

  return (
    <>
      Filter by id:  <InputNumber  useGrouping={false}  onChange={(e) => onChange(e)}> </InputNumber>
    </>
  );
}
