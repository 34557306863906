// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
// import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
// import {Dialog} from 'primereact/dialog';
//$FlowFixMe[cannot-resolve-module]
import {AutoComplete} from 'primereact/autocomplete';
// import {Dropdown} from 'primereact/dropdown';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  profile?: any,
  factory?: any,
  factoryUser?: any,
  onAction: (evt: any) => void,
};

const defFactoryUser = {
  __cls__: 'com.nb.lmrs.model.entity.FactoryUser',
  flag: 0,
  code: '',
  name: '',
  description: '',
  factory: null,
  profile: null,
  role: null,
};

export function FactoryUserForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {profile, factory, factoryUser} = props;
  const [editFactoryUser, setEditFactoryUser] = useState<any>();
  const [suggFactories, setSuggFactories] = useState([]);
  const [suggProfiles, setSuggProfiles] = useState([]);
  const [suggRoles, setSuggRoles] = useState([]);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let curFactoryUser = factoryUser || {};
    curFactoryUser = {
      ...defFactoryUser,
      ...curFactoryUser,
    };
    setEditFactoryUser(curFactoryUser);
  }, [factoryUser]);

  const _onSuggFactories: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getFactories({search, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        setSuggFactories([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onSuggProfiles: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getProfiles({search, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        setSuggProfiles([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onSuggRoles: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let start = 0;
    let limit = 1000;
    let filters = {
      facRoles: "obj.name like 'FACTORY%'",
    };
    let params = {};

    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getRoles({search, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        setSuggRoles([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onSave = (evt: any) => {
    // setShowCfmDlg(false);
    let fac = _validateFactoryUser();
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update user access record?'
        : 'Are you sure you want to create user access record?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateFactoryUser({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'factoryUserSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _validateFactoryUser: () => any = () => {
    let obj = {...editFactoryUser};

    if (factory && factory.id) {
      obj = {
        ...obj,
        factory,
      };
    }

    if (profile && profile.id) {
      obj = {
        ...obj,
        profile,
      };
    }

    if (!obj.factory || !obj.factory.id) {
      UIUtils.showError({
        detail: 'Please select factory',
        toast: toastRef.current,
      });
      return false;
    }

    if (!obj.profile || !obj.profile.id) {
      UIUtils.showError({
        detail: 'Please select user',
        toast: toastRef.current,
      });
      return false;
    }

    let {role} = obj;
    if (!role || !role.id) {
      UIUtils.showError({
        detail: 'Please select role',
        toast: toastRef.current,
      });
      return false;
    }

    // obj.rate = Number(obj.rate);
    return obj;
  };

  if (!editFactoryUser) {
    return null;
  }

  const saveLbl = editFactoryUser.id ? 'Grant Access' : 'Grant Access';
  // const isNew = !editFactoryUser.id;
  // //console.log.*$

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/edit user access">
        <div className="p-grid py-3 p-fluid">
          {(!factory || !factory.id) && (
            <div className="p-col-12">
              <AutoComplete
                placeholder="Select factory"
                dropdown
                suggestions={suggFactories}
                field="name"
                value={editFactoryUser.factory}
                completeMethod={_onSuggFactories}
                onChange={(e) =>
                  setEditFactoryUser({
                    ...editFactoryUser,
                    factory: e.value,
                  })
                }
              />
            </div>
          )}

          {(!profile || !profile.id) && (
            <div className="p-col-12">
              <AutoComplete
                placeholder="Select user"
                dropdown
                suggestions={suggProfiles}
                field="name"
                value={editFactoryUser.profile}
                completeMethod={_onSuggProfiles}
                onChange={(e) =>
                  setEditFactoryUser({
                    ...editFactoryUser,
                    profile: e.value,
                  })
                }
              />
            </div>
          )}

          <div className="p-col-12">
            <AutoComplete
              placeholder="Select role"
              dropdown
              suggestions={suggRoles}
              field="name"
              value={editFactoryUser.role}
              completeMethod={_onSuggRoles}
              onChange={(e) =>
                setEditFactoryUser({
                  ...editFactoryUser,
                  role: e.value,
                })
              }
            />
          </div>

          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputTextarea
                  rows={2}
                  value={editFactoryUser.description}
                  onChange={(e) => {
                    setEditFactoryUser({
                      ...editFactoryUser,
                      description: e.target.value,
                    });
                  }}
                />
                <label>Summary</label>
              </span>
            </div>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
      </div>
    </>
  );
}
