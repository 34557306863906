// @flow
import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {Dialog} from 'primereact/dialog';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';
//$FlowFixMe
import {InputText} from 'primereact/inputtext';

import type {ApiProps} from '../../service/Api';
import {AuthUtils} from '../../service/AuthUtils';
import {AuthConst} from '../../service/AppConstant';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {ProfileList} from './ProfileList';
import {ProfileForm} from './ProfileForm';
import {ProfilePwdForm} from './ProfilePwdForm';
import {RoleList} from './RoleList';

// import {UserGrpList} from './UserGrpList';
import {UserFactories} from './UserFactories';

type Props = {
  type: string,
  // pItem?: any,
  // project?: any,
};

export function Profile(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();
  const [selProfile, setSelProfile] = useState();

  const [search, setSearch] = useState('');
  const [reloadFlag, setReloadFlag] = useState(false);
  const [tabInd, setTabInd] = useState(0);
  const [showAssignDlg, setShowAssignDlg] = useState(false);
  const [showUnAssignDlg, setShowUnAssignDlg] = useState(false);

  const [selAvaiRole, setSelAvaiRole] = useState();
  const [selPfRole, setSelPfRole] = useState();
  const [reloadPfRolesFlag, setReloadPfRolesFlag] = useState(false);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  const _onProfileSelected = (evt: any) => {
    // //console.log.*$
    setSelProfile(evt.value);
  };

  const _onNewProfile = (evt: any) => {
    setSelProfile(null);
  };

  const _onProfileAction = (evt: any) => {
    // //console.log.*$
    // setTabInd(1);
    // let {value} = evt;
    setReloadFlag(!reloadFlag);
    setSelProfile(null);
  };

  const _onAssignRole: (evt: any) => void = (evt: any) => {
    setShowAssignDlg(false);
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selProfile || !selAvaiRole) {
      return;
    }
    api
      .linkProfileRoles(selProfile.id, [selAvaiRole])
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        setReloadPfRolesFlag(!reloadPfRolesFlag);
        UIUtils.showInfo({detail: 'success', toast: toastRef.current});
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
      });
  };

  const _onUnAssignRole: (evt: any) => void = (evt: any) => {
    setShowUnAssignDlg(false);
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selProfile || !selPfRole) {
      return;
    }
    api
      .unlinkProfileRoles(selProfile.id, [selPfRole])
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        setReloadPfRolesFlag(!reloadPfRolesFlag);
        UIUtils.showInfo({detail: 'success', toast: toastRef.current});
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
      });
  };

  const assignDlgFooter = (
    <div>
      <Button
        label={t('g.yes')}
        icon="pi pi-check"
        className="ml-1"
        onClick={_onAssignRole}
      />
      <Button
        label={t('g.no')}
        icon="pi pi-times"
        className="ml-1"
        onClick={() => {
          setShowAssignDlg(false);
        }}
      />
    </div>
  );

  const unAssignDlgFooter = (
    <div>
      <Button
        label={t('g.yes')}
        icon="pi pi-check"
        className="ml-1"
        onClick={_onUnAssignRole}
      />
      <Button
        label={t('g.no')}
        icon="pi pi-times"
        className="ml-1"
        onClick={() => {
          setShowUnAssignDlg(false);
        }}
      />
    </div>
  );

  let authLvl = AuthUtils.getAuthLevel({auth});
  let {factoryUsers} = auth;
  let showPfForm =
    authLvl < AuthConst.AUTH_LVL_FACTORY ||
    (authLvl >= AuthConst.AUTH_LVL_FACTORY &&
      factoryUsers &&
      factoryUsers.length > 0);

  const _renderTabs = () => {
    let tabs = [];
    tabs.push(
      <TabPanel key="general" header="General">
        <div className="p-grid">
          <div className="p-col-12 p-lg-6">
            {showPfForm && (
              <ProfileForm
                profile={selProfile}
                onAction={_onProfileAction}></ProfileForm>
            )}
          </div>
          <div className="p-col-12 p-lg-6">
            {selProfile && (
              <ProfilePwdForm
                profile={selProfile}
                onAction={_onProfileAction}
              />
            )}
          </div>
        </div>
      </TabPanel>,
    );
    tabs.push(
      <TabPanel key="accessControl" header="Access control">
        {selProfile && (
          <div className="p-grid">
            <div className="p-col-12 p-lg-6">
              <RoleList
                byProfile={selProfile}
                header="Assigned roles"
                reload={reloadPfRolesFlag}
                selection={selPfRole}
                onSelect={(evt) => {
                  setSelPfRole({...evt.value});
                }}
              />
              <div className="p-my-2 p-d-flex p-jc-end">
                <Button
                  label="Un-assign"
                  icon="pi pi-minus-circle"
                  onClick={(evt) => {
                    setShowUnAssignDlg(true);
                  }}
                />
              </div>
            </div>
            <div className="p-col-12 p-lg-6">
              <RoleList
                header="Available roles"
                selection={selAvaiRole}
                onSelect={(evt) => {
                  setSelAvaiRole({...evt.value});
                }}
              />
              <div className="p-my-2 p-d-flex p-jc-end">
                <Button
                  label="Assign"
                  icon="pi pi-plus-circle"
                  onClick={(evt) => {
                    setShowAssignDlg(true);
                  }}
                  disabled={!selAvaiRole}
                />
              </div>
            </div>
          </div>
        )}
      </TabPanel>,
    );
    if (
      authLvl < AuthConst.AUTH_LVL_NB ||
      authLvl >= AuthConst.AUTH_LVL_FACTORY
    ) {
      tabs.push(
        <TabPanel key="factoryAccess" header="Factory Access">
          {selProfile && <UserFactories profile={selProfile} />}
        </TabPanel>,
      );
    }
    return tabs;
  };
  // const canEdit =
  //   authLvl < AuthConst.AUTH_LVL_FACTORY ||
  //   AuthUtils.isAuth({
  //     auth,
  //     roles: ['FACTORY_ADMIN'],
  //   });

  const tblHeader = 'Available users';
  return (
    <>
      <Toast ref={toastRef} />
      <div className="p-grid">
        <div className="p-col-6 p-fluid">
          <div className="p-field">
            <span className="p-float-label">
              <InputText
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <label>Search...</label>
            </span>
          </div>
        </div>
      </div>
      <div className="p-grid">
        <div className="p-col-12">
          <ProfileList
            search={search}
            header={tblHeader}
            selection={selProfile}
            reload={reloadFlag}
            onSelect={_onProfileSelected}
          />
          <div className="p-my-2 p-d-flex p-jc-end">
            <Button label="New" icon="pi pi-plus" onClick={_onNewProfile} />
          </div>
        </div>
      </div>
      <TabView
        activeIndex={tabInd}
        onTabChange={(e) => setTabInd(e.index)}
        className="p-nogutter">
        {_renderTabs()}
      </TabView>
      {selProfile && selAvaiRole && (
        <Dialog
          header="Confirm assign role"
          footer={assignDlgFooter}
          visible={showAssignDlg}
          modal={true}
          blockScroll
          onHide={() => {
            setShowAssignDlg(false);
          }}>
          Are you sure to assign role [{selAvaiRole.name}] to user [
          {selProfile.login}]?
        </Dialog>
      )}
      {selProfile && selPfRole && (
        <Dialog
          header="Confirm un-assign role"
          footer={unAssignDlgFooter}
          visible={showUnAssignDlg}
          modal={true}
          blockScroll
          onHide={() => {
            setShowUnAssignDlg(false);
          }}>
          Are you sure to un-assign role [{selPfRole.name}] from user [
          {selProfile.login}]?
        </Dialog>
      )}
    </>
  );
}
