// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  role: any,
  onAction: (evt: any) => void,
};

const defRole = {
  __cls__: 'com.nb.lmrs.model.entity.Role',
  flag: 0,
  name: '',
  description: '',
  active: false,
};

export function RoleForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {role} = props;
  const [editRole, setEditRole] = useState<any>();

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
    let pf = {
      ...defRole,
      ...role,
    };
    setEditRole(pf);
  }, [role]);

  const _onSave = (evt: any) => {
    let saveRole = _validateRole();
    if (!saveRole) {
      return;
    }

    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    confirmDialog({
      message: role.id
        ? 'Are you sure you want to update security role?'
        : 'Are you sure you want to create security role?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateRole({...saveRole})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'roleSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
    });
  };

  const _validateRole = (): any => {
    //console.log.*$
    let res = {
      ...editRole,
    };
    return res;
  };

  if (!editRole) {
    return null;
  }

  const saveLbl = editRole.id ? 'Update role' : 'Create role';
  const isEdit = !!editRole.id;
  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/Update role">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  value={editRole.name}
                  onChange={(e) => {
                    setEditRole({
                      ...editRole,
                      name: e.target.value,
                    });
                  }}
                  disabled={isEdit}
                />
                <label>Role name</label>
              </span>
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputTextarea
                  rows={2}
                  value={editRole.description}
                  onChange={(e) => {
                    setEditRole({
                      ...editRole,
                      description: e.target.value,
                    });
                  }}
                />
                <label>Description</label>
              </span>
            </div>
          </div>
        </div>
      </Panel>

      <div className="p-d-flex p-jc-end p-my-2">
        <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
      </div>
    </>
  );
}
