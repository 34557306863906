// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';

import {Utils} from '../../../service/Utils';
import {AdminApi} from '../../../service/AdminApi';

import {FactoryFilter} from '../../common/FactoryFilter';

import {LeanAuditNbList} from './LeanAuditNbList';
import {LeanAudChartNb} from './LeanAudChartNb';
import {LeanAudItemNb} from './LeanAudItemNb';
import type {ApiProps} from '../../../service/Api';
import type {EvtHandler} from '../../types';

type Props = {};

const defFilter = {
  factory: null,
  facGroups: [],
  years: [],
  facGroup: null,
  country: null,
  region: null,
};

export function LeanAuditNb(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  //  const globalRef = useSelector((state) => state.global);
  const [tabInd, setTabInd] = useState(0);
  //  const rtParams = useParams();
  const [filter, setFilter] = useState<any>({...defFilter});
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  // const selLeanAuditRef = useRef();
  // useEffect(() => {
  //   selLeanAuditRef.current = selLeanAudit;
  // }, [selLeanAudit]);

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let di: any = Utils.getDateInfo() || {};
    let initFil = {};
    initFil = {
      ...initFil,
      years: [di.year],
    };
    setFilter(initFil);
  }, []);
  //   let filVal = rtParams.filter;
  //   if (filVal && globalRef) {
  //     let inpFilter = atob(filVal);
  //     //console.log.*$
  //     let inpFil = globalRef[inpFilter];
  //     //console.log.*$
  //     initFil = {
  //       ...initFil,
  //       ...inpFil,
  //     };
  //     //console.log.*$
  //     let {facGroups} = initFil;
  //     if (facGroups && facGroups.length > 0) {
  //       setShowFacGroup(true);
  //     }
  //   }

  //   setFilter(initFil);
  // }, [auth, globalRef, rtParams]);

  const _onFilterAction: EvtHandler = (evt: any) => {
    let {type, value} = evt;
    //console.log.*$
    if (type === 'filterChanged') {
      setFilter({...value});
      //   setSelLeanAudit({});
    }
  };

  //let listTitle = 'Available assessments';
  //  let authLevel = AuthUtils.getAuthLevel({auth});
  // let canEdit =
  //   authLevel < AuthConst.AUTH_LVL_NB ||
  //   AuthUtils.isAuth({auth, roles: ['FACTORY_ADMIN','NB_ADMIN']});
  //  let disFacGroup = authLevel >= AuthConst.AUTH_LVL_FACTORY;
  let {facGroup, country, years, region} = filter;
  //  //console.log.*$
  let showChartFacGroup = facGroup && facGroup.id && years && years.length > 0;
  let showChartCountry = country && country.id && years && years.length > 0;
  let showChartRegion = region && region.id && years && years.length > 0;

  return (
    <>
      <Toast ref={toastRef} />

      <TabView activeIndex={tabInd} onTabChange={(e) => setTabInd(e.index)}>
        <TabPanel
          header="Factory Group"
          onTabChange={(e) => {
            setTabInd(e.index);
          }}>
          <FactoryFilter
            filter={filter}
            showYears={true}
            showFacGroup={true}
            onAction={_onFilterAction}
          />

          <LeanAuditNbList
            scope="facGroup"
            byFacGroup={filter.facGroup}
            byYears={filter.years}
          />

          <div className="grid">
            <div className="col-12 xl:col-6">
              {!showChartFacGroup && (
                <div>
                  <h6>
                    Please select group and year(s) to display spider chart.
                  </h6>
                </div>
              )}
              {showChartFacGroup && (
                <div>
                  <h6>
                    <LeanAudChartNb
                      byFacGroup={facGroup}
                      years={filter.years}
                      scope="FACGROUP"
                    />
                  </h6>
                </div>
              )}
            </div>

            <div className="col-12 xl:col-6">
              {!showChartFacGroup && (
                <div>
                  <h6>
                    Please select group and year(s) to display assessment
                    details.
                  </h6>
                </div>
              )}
              {showChartFacGroup && (
                <div>
                  <h6>
                    <LeanAudItemNb
                      byFacGroup={facGroup}
                      years={filter.years}
                      scope="FACGROUP"
                    />
                  </h6>
                </div>
              )}
            </div>
          </div>
        </TabPanel>

        <TabPanel
          header="Country"
          onTabChange={(e) => {
            setTabInd(e.index);
          }}>
          <FactoryFilter
            filter={filter}
            showYears={true}
            showCountry={true}
            onAction={_onFilterAction}
          />
          <LeanAuditNbList
            scope="country"
            byCountry={filter.country}
            byYears={filter.years}
          />
          <div className="grid">
            <div className="col-12 xl:col-6">
              {!showChartCountry && (
                <div>
                  <h6>
                    Please select country and year(s) to display spider chart.
                  </h6>
                </div>
              )}
              {showChartCountry && (
                <div>
                  <h6>
                    <LeanAudChartNb
                      byCountry={country}
                      years={filter.years}
                      scope="COUNTRY"
                    />
                  </h6>
                </div>
              )}
            </div>

            <div className="col-12 xl:col-6">
              {!showChartCountry && (
                <div>
                  <h6>
                    Please select country and year(s) to display assessment
                    details.
                  </h6>
                </div>
              )}
              {showChartCountry && (
                <div>
                  <h6>
                    <LeanAudItemNb
                      byCountry={country}
                      years={filter.years}
                      scope="COUNTRY"
                    />
                  </h6>
                </div>
              )}
            </div>
          </div>
        </TabPanel>

        <TabPanel
          header="Region"
          onTabChange={(e) => {
            setTabInd(e.index);
          }}>
          <FactoryFilter
            filter={filter}
            showYears={true}
            showRegion={true}
            onAction={_onFilterAction}
          />
          <LeanAuditNbList
            scope="region"
            byRegion={filter.region}
            byYears={filter.years}
          />
          <div className="grid">
            <div className="col-12 xl:col-6">
              {!showChartRegion && (
                <div>
                  <h6>
                    Please select region and year(s) to display spider chart.
                  </h6>
                </div>
              )}
              {showChartRegion && (
                <div>
                  <h6>
                    <LeanAudChartNb
                      byRegion={filter.region}
                      years={filter.years}
                      scope="REGION"
                    />
                  </h6>
                </div>
              )}
            </div>

            <div className="col-12 xl:col-6">
              {!showChartRegion && (
                <div>
                  <h6>
                    Please select region and year(s) to display assessment
                    details.
                  </h6>
                </div>
              )}
              {showChartRegion && (
                <div>
                  <h6>
                    <LeanAudItemNb
                      byRegion={region}
                      years={filter.years}
                      scope="REGION"
                    />
                  </h6>
                </div>
              )}
            </div>
          </div>
        </TabPanel>
      </TabView>
    </>
  );
}
