// @flow
import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  byLogin?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onUserGrpSelected?: (evt: any) => void,
};

export function UserGrpList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();
  const [userGrpsTblData, setUserGrpsTblData] = useState([]);
  const [userGrpsTblStart, setUserGrpsTblStart] = useState(0);
  const [userGrpsTblLimit] = useState(10);
  const [userGrpsTblNbRows, setUserGrpsTblNbRows] = useState(0);
  const [selUserGrp, setSelUserGrp] = useState();
  const [sortMeta, setSortMeta] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);

  const {byLogin, reload, selection, header, command} = props;

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      setSelUserGrp({...selection});
    } else {
      setSelUserGrp(null);
    }
  }, [selection]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    //console.log.*$
    // //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let start = userGrpsTblStart;
    let limit = userGrpsTblLimit;
    let filters = {};
    let params = {
      fetchProjInfo: true,
    };

    if (byLogin) {
      filters = {
        ...filters,
        byLogin:
          'exists (select 1 from UserGrpLnk lnk where lnk.user.login = :byLogin' +
          ' and lnk.group.id = obj.id)',
      };
      params = {
        ...params,
        byLogin,
      };
    }

    // //console.log.*$

    let joins = [];
    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });
    //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);

    api
      .getUserGrps({start, limit, filters, params, joins, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        // //console.log.*$
        setUserGrpsTblData([...data.list]);
        setUserGrpsTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, [byLogin, userGrpsTblStart, userGrpsTblLimit, reload, sortMeta]);

  const _onUserGrpSelected = (evt: any) => {
    setSelUserGrp({...evt.value});
    let {onUserGrpSelected} = props;
    onUserGrpSelected && onUserGrpSelected(evt);
  };

  const _onUserGrpsPaging = (evt: any) => {
    // //console.log.*$
    setUserGrpsTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };

  const _renderUpCol = (row, col) => {
    let {updatedAt} = row;
    if (updatedAt) {
      return `${Utils.reFmtDate(updatedAt, 'DD/MM/YY HH:mm')}`;
    }
  };

  // const _renderActiveCol: (row: any, col: any) => any = (
  //   row: any,
  //   col: any,
  // ) => {
  //   let {active} = row;
  //   return `${active ? 'True' : 'False'}`;
  // };

  return (
    <>
      <Toast ref={toastRef} />
      <DataTable
        header={header || t('vdinvoice.title')}
        value={userGrpsTblData}
        dataKey="id"
        lazy={true}
        paginator={true}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        rows={userGrpsTblLimit}
        first={userGrpsTblStart}
        totalRecords={userGrpsTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        selection={selUserGrp}
        onSelectionChange={_onUserGrpSelected}
        onPage={_onUserGrpsPaging}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={_onSort}>
        <Column
          field="name"
          header="Name"
          style={{width: 200}}
          sortable
          sortField="obj.name"
        />
        <Column field="description" header="Description" style={{width: 300}} />
        <Column
          body={_renderUpCol}
          header="Updated On"
          style={{width: 130}}
          sortable
          sortField="obj.updatedAt"
        />
        <Column
          field="updatedBy"
          header="Updated By"
          style={{width: 150}}
          sortable
          sortField="obj.updatedBy"
        />
        <Column
          field="id"
          header="#"
          headerClassName="text-right pr-4"
          bodyClassName="text-right"
          style={{width: 90}}
        />
      </DataTable>
    </>
  );
}
