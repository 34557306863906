// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
// import {InputNumber} from 'primereact/inputnumber';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {Dialog} from 'primereact/dialog';
//$FlowFixMe[cannot-resolve-module]
import {Checkbox} from 'primereact/checkbox';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  profile: any,
  onAction: (evt: any) => void,
};

const defProfile = {
  __cls__: 'com.nb.lmrs.model.entity.UserProfile',
  flag: 0,
  login: '',
  fullName: '',
  email: '',
  phone: '',
  active: false,
};

export function ProfileForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {profile} = props;
  const [editProfile, setEditProfile] = useState<any>();
  const [showCfmDlg, setShowCfmDlg] = useState<any>(false);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
    let pf = {
      ...defProfile,
      ...profile,
    };
    setEditProfile(pf);
  }, [profile]);

  const _onSave = (evt: any) => {
    setShowCfmDlg(false);
    let saveProfile = _validateProfile();
    if (!saveProfile) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    //console.log.*$
    api
      .updateProfile({...saveProfile})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        let {onAction} = props;
        onAction &&
          onAction({
            type: 'profileSaved',
            value: data.entity,
          });
        UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
      });
  };

  const _validateProfile = (): any => {
    //console.log.*$
    let res = {
      ...editProfile,
    };

    return res;
  };

  const cfmDlgFooter = (
    <div className="text-right">
      <Button label="Yes" icon="pi pi-check" onClick={_onSave} />
      <Button
        label="No"
        icon="pi pi-times"
        onClick={(e) => {
          setShowCfmDlg(false);
        }}
      />
    </div>
  );

  if (!editProfile) {
    return null;
  }

  const saveLbl = editProfile.id ? 'Update user' : 'Create user';
  const isEdit = !!editProfile.id;
  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="User information">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  value={editProfile.login}
                  onChange={(e) => {
                    setEditProfile({
                      ...editProfile,
                      login: e.target.value,
                    });
                  }}
                  disabled={isEdit}
                />
                <label>Username</label>
              </span>
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  value={editProfile.fullName}
                  onChange={(e) => {
                    setEditProfile({
                      ...editProfile,
                      fullName: e.target.value,
                    });
                  }}
                />
                <label>Full name</label>
              </span>
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  value={editProfile.email}
                  onChange={(e) => {
                    setEditProfile({
                      ...editProfile,
                      email: e.target.value,
                    });
                  }}
                />
                <label>Email</label>
              </span>
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  value={editProfile.phone}
                  onChange={(e) => {
                    setEditProfile({
                      ...editProfile,
                      phone: e.target.value,
                    });
                  }}
                />
                <label>Phone</label>
              </span>
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-field-checkbox">
              <Checkbox
                onChange={(e) => {
                  setEditProfile({
                    ...editProfile,
                    active: e.checked,
                  });
                }}
                checked={editProfile.active}></Checkbox>
              <label>Active</label>
            </div>
          </div>
        </div>
      </Panel>

      <div className="p-my-2 p-d-flex p-jc-end">
        <Button
          label={saveLbl}
          icon="pi pi-save"
          onClick={(e) => {
            if (!_validateProfile()) {
              return;
            }
            setShowCfmDlg(true);
          }}
        />
      </div>
      <Dialog
        header="Confirm save profile"
        footer={cfmDlgFooter}
        visible={showCfmDlg}
        style={{width: '400px'}}
        modal={true}
        onHide={() => {
          setShowCfmDlg(false);
        }}>
        {editProfile.id && <span>Are you sure to update profile?</span>}
        {!editProfile.id && <span>Are you sure to create profile?</span>}
      </Dialog>
    </>
  );
}
