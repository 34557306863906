// @flow
import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  selectionMode?: string,
  selection?: any,
  byRole?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function PermList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();
  const [permsTblData, setPermsTblData] = useState([]);
  const [permsTblStart, setPermsTblStart] = useState(0);
  const [permsTblLimit] = useState(10);
  const [permsTblNbRows, setPermsTblNbRows] = useState(0);
  const [selPerm, setSelPerm] = useState();
  const [selPerms, setSelPerms] = useState();
  const [sortMeta, setSortMeta] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);

  const {byRole, reload, selection, header, command} = props;
  const selMode = props.selectionMode || 'single';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelPerm({...selection});
      } else {
        setSelPerms([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelPerm(null);
      } else {
        setSelPerms([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    // //console.log.*$
    // //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let start = permsTblStart;
    let limit = permsTblLimit;
    let filters = {};
    let params = {};
    if (byRole && byRole.id) {
      filters = {
        ...filters,
        byRole:
          'obj.id in (select lnk.permission.id from RolePermLnk lnk' +
          ' where lnk.role.id = :roleId)',
      };
      params = {
        ...params,
        roleId: {
          type: 'long',
          value: byRole.id,
        },
      };
    }

    // //console.log.*$
    let joins = [];
    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });
    // //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);
    api
      .getSecPerms({start, limit, filters, params, joins, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        setPermsTblData([...data.list]);
        setPermsTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, [permsTblStart, permsTblLimit, reload, byRole, sortMeta]);

  const _onPermSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelPerm({...evt.value});
    } else {
      setSelPerms([...evt.value]);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onPermsPaging = (evt: any) => {
    // //console.log.*$
    setPermsTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header || t('vdrole.title')}
          value={permsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={permsTblLimit}
          first={permsTblStart}
          totalRecords={permsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selPerm}
          onSelectionChange={_onPermSelected}
          onPage={_onPermsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            field="code"
            header="ID"
            style={{width: 150}}
            sortable
            sortField="obj.code"
          />
          <Column field="name" header="Name" style={{width: 200}} />
          <Column
            field="description"
            header="Description"
            style={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerClassName="text-right pr-4"
            bodyClassName="text-right"
            style={{width: 90}}
          />
        </DataTable>
      )}
      {selMode !== 'single' && (
        <DataTable
          header={header || t('vdrole.title')}
          value={permsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={permsTblLimit}
          first={permsTblStart}
          totalRecords={permsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selPerms}
          onSelectionChange={_onPermSelected}
          onPage={_onPermsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            selectionMode="multiple"
            headerStyle={{width: 50}}
            headerClassName="text-left"
            bodyClassName="text-left"></Column>
          <Column
            field="code"
            header="ID"
            style={{width: 150}}
            sortable
            sortField="obj.code"
          />
          <Column field="name" header="Name" style={{width: 200}} />
          <Column
            field="description"
            header="Description"
            style={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerClassName="text-right pr-4"
            bodyClassName="text-right"
            style={{width: 90}}
          />
        </DataTable>
      )}
    </>
  );
}
