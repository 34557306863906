// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
// import {Dialog} from 'primereact/dialog';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {LineGroupForm} from './LineGroupForm';
import {LineGroupList} from './LineGroupList';

type Props = {};

export function LineGroup(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const [editLineGroup, setEditLineGroup] = useState();
  const [selLineGroup, setSelLineGroup] = useState();
  // const [showCfmDlg, setShowCfmDlg] = useState<any>(false);
  const [reload, setReload] = useState(false);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  // const selLineGroupRef = useRef();
  // useEffect(() => {
  //   selLineGroupRef.current = selLineGroup;
  // }, [selLineGroup]);

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _onLineGroupSelected = (evt: any) => {
    // setSelLineGroup(evt.value);
    //console.log.*$
    setSelLineGroup(evt.value);
    setEditLineGroup(evt.value);
  };

  const _onNewLineGroup: any = (evt: any) => {
    setSelLineGroup(null);
    setEditLineGroup({});
  };

  const _onLineGroupAction = (evt: any) => {
    setSelLineGroup(null);
    setEditLineGroup({});
    setReload(!reload);
  };

  const _onDelete: (evt: any) => void = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selLineGroup || !selLineGroup.id) {
      return;
    }
    confirmDialog({
      message: 'Are you sure you want to delete line group?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .deleteLineGroup(selLineGroup.id)
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
            }
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          })
          .finally(() => {
            setSelLineGroup({});
            setReload(!reload);
          });
      },
      reject: () => {},
    });
  };

  // const cfmDlgFooter = (
  //   <div className="text-right">
  //     <Button label="Yes" icon="pi pi-check" onClick={_onDeletLineGroup} />
  //     <Button
  //       label="No"
  //       icon="pi pi-times"
  //       onClick={(e) => {
  //         setShowCfmDlg(false);
  //       }}
  //     />
  //   </div>
  // );

  let listTitle = 'Available line groups';

  return (
    <>
      <Toast ref={toastRef} />

      <div className="p-grid">
        <div className="p-col-12 p-lg-8">
          <LineGroupList
            header={listTitle}
            reload={reload}
            selection={selLineGroup}
            onSelect={_onLineGroupSelected}
          />
          <div className="p-d-flex p-jc-end p-my-2">
            <Button
              label="New country"
              icon="pi pi-plus"
              className="p-ml-2"
              onClick={_onNewLineGroup}
            />
            {selLineGroup && (
              <Button
                label="Delete"
                icon="pi pi-trash"
                className="p-ml-2"
                onClick={_onDelete}
                disabled={selLineGroup.flag === 1}
              />
            )}
          </div>
        </div>
        <div className="p-col-12 p-lg-4">
          <LineGroupForm
            lineGroup={editLineGroup}
            onAction={_onLineGroupAction}
          />
        </div>
      </div>
      {/*<Dialog
        header="Confirm delete country"
        footer={cfmDlgFooter}
        visible={showCfmDlg}
        style={{width: '400px'}}
        modal={true}
        onHide={() => {
          setShowCfmDlg(false);
        }}>
        <span>Are you sure to delete selected country?</span>
      </Dialog>*/}
    </>
  );
}
