// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';
//$FlowFixMe
import {debounceTime} from 'rxjs/operators';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {Chart} from 'primereact/chart';
//$FlowFixMe
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {useTranslation} from 'react-i18next';
//$FlowFixMe[cannot-resolve-module]
import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../service/AuthUtils';
import type {EvtHandler} from '../../types';
import {UIUtils, Utils} from '../../../service/Utils';
import {AdminApi} from '../../../service/AdminApi';
import {pphColors, siColors, kpiColors, getChartOptions1} from '../CommonChart';

type Props = {
  scope?: string,
  regions?: any[],
  countries?: any[],
  facGroups?: any[],
  factories?: any[],
  lines?: any[],
  year?: any,
  month?: any,
  expanded?: boolean,
  onAction?: EvtHandler,
};

export function PphMonthlyFacV2(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();
  const {
    scope,
    regions,
    countries,
    facGroups,
    factories,
    lines,
    year,
    month,
    expanded,
    onAction,
  } = props;

  const [barData, setBarData] = useState();
  const [chartOptions, setChartOptions] = useState(getChartOptions1());

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  // const _apiErrorHandler = useCallback((errInfo: any) => {
  //   UIUtils.showError({...errInfo, toast: toastRef.current});
  // }, []);

  // const _fetchMonthInfo = useCallback(async (options) => {
  //   let {year, month} = options;
  //   let api: ?AdminApi = apiRef.current;
  //   if (!api) {
  //     return {};
  //   }
  //   year = year || 0;
  //   month = month || 0;
  //   return await api
  //     .getMonthInfo({
  //       year,
  //       month,
  //     })
  //     .then((resp) => resp.data)
  //     .then((resp) => {
  //       // //console.log.*$
  //       let {errors, data} = resp;
  //       if (errors.length > 0) {
  //         //console.log.*$
  //         UIUtils.showError({errors, toast: toastRef.current});
  //         return {};
  //       }
  //       return {...data};
  //     })
  //     .catch((error) => {
  //       //console.log.*$
  //       UIUtils.showError({error, toast: toastRef.current});
  //       return {};
  //     });
  // }, []);

  const _fetchData = useCallback(async (options) => {
    let {factories, year, month, scope} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return [];
    }
    let facIds = [];
    if (factories && factories.length > 0) {
      facIds = factories.map((fac) => fac.id);
    }

    let pYear = year || 0;
    let pMonth = month || 0;

    let reqData = {};

    reqData = {
      ...reqData,
      scope,
      month: pMonth,
      year: pYear,
      factoryIds: facIds,
    };

    return await api
      .fetchPphMonthly({
        data: {
          ...reqData,
        },
      })
      .then((resp) => resp.data)
      .then((resp) => {
        //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        return data.data ? {...data.data} : [];
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchKpiData = useCallback(async (options: any) => {
    let {factories, year, month} = options;

    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return [];
    }
    let factoryIds = factories.map((fac) => fac.id);
    let reqData = {
      scope: 'factory',
      periodType: 'MONTH',
      kpiType: 'PPH',
      year,
      month,
      factoryIds,
    };
    return await api
      .loadProdKpiDataByMultiFactory({
        data: {...reqData},
      })
      .then((resp) => resp.data)
      .then((resp) => {
        // //console.log.*$
        let {errors, data} = resp;

        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        return [
          {
            ...data,
          },
        ];
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchChartData = useCallback(
    async (options: any) => {
      let months = [];
      for (let i = 0; i < 12; i++) {
        months.push(i + 1);
      }
      //   months.push(13);
      let labels = months.map((it) => it);
      labels = labels.map((it) => t(`mon.${it}`));
      labels.push('6 months roll');

      let pm1 = _fetchKpiData(options).then((rec) => {
        let datasets = [
          {
            type: 'line',
            label: 'Target',
            backgroundColor: kpiColors[0],
            borderColor: kpiColors[0],
            borderWidth: 1,
            data: [...rec[0].targetValues],
          },
        ];

        return datasets;
      });

      let pm2 = _fetchData(options).then((data) => {
        console.log(data);
        let pphDatas = [];
        let siDatas = [];
        for (let i = 0; i < months.length; i++) {
          let values: any[] = Object.values(data).filter(
            (dt) => dt[3] === i + 1,
          )[0];

          if (values && values.length > 0) {
            console.log(values);
            pphDatas.push(Utils.round(values[0] / values[2], 2));
            siDatas.push(Utils.round(values[1] / values[2], 2));
          } else {
            pphDatas.push(0);
            siDatas.push(0);
          }
        }

        let dataYtd = Object.values(data).filter((dt) => dt[2] === 13)[0];
        if (dataYtd && dataYtd.length > 0) {
          siDatas.push(Utils.round(dataYtd[0], 2));
          pphDatas.push(Utils.round(dataYtd[1], 2));
        } else {
          siDatas.push(0);
          pphDatas.push(0);
        }

        let pphDatasets = [
          {
            label: `EOL`,
            backgroundColor: pphColors[0],
            data: [...pphDatas],
          },
        ];

        let siDatasets = [
          {
            label: `SI`,
            backgroundColor: siColors[0],
            data: [...siDatas],
          },
        ];

        let chartData = {
          labels,
          datasets: [...pphDatasets, ...siDatasets],
        };
        return chartData;
      });

      Promise.all([pm1, pm2]).then((data) => {
        //console.log.*$
        let [kpiData, chartData] = data;
        let {datasets} = chartData;

        if (datasets.length > 0) {
          datasets = [...kpiData, ...datasets];
          const dataArrays = datasets.map((item) => item.data).flat();
          let arr = Utils.findMinMax(dataArrays);
          let chartOptionsNew = {
            ...chartOptions,
            scales: {
              ...chartOptions.scales,
              y: {
                ...chartOptions.scales.y,

                max: Utils.round(arr[1] + 0.1, 1),
              },
            },
          };

          setChartOptions(chartOptionsNew);
        }
        let dataChart = {
          labels,
          datasets: datasets,
        };
        setBarData(dataChart);
      });
    },
    [_fetchData, _fetchKpiData, t, chartOptions],
  );

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchChartData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      // //console.log.*$
      subj.complete();
    };
  }, [_fetchChartData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      scope,
      regions,
      countries,
      facGroups,
      factories,
      lines,
      year,
      month,
    });
  }, [scope, regions, countries, facGroups, factories, lines, year, month]);

  const _onToggleExpand: EvtHandler = (evt: any) => {
    onAction &&
      onAction({
        type: 'toggleExpand',
        value: !expanded,
      });
  };

  const iconName = expanded
    ? 'pi pi-angle-double-left'
    : 'pi pi-angle-double-right';

  const icons = (
    <Button
      icon={iconName}
      className="p-button-rounded p-button-text"
      style={{padding: 0, height: '1.2rem', color: '#FFFFFF'}}
      onClick={_onToggleExpand}
    />
  );

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="PPH Monthly" icons={icons}>
        <Chart type="bar" data={barData} options={chartOptions}></Chart>
      </Panel>
    </>
  );
}
