// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useSelector} from 'react-redux';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
import type {TblColRender} from '../types';
import {AdminApi} from '../../service/AdminApi';
import type {ApiProps} from '../../service/Api';
import {UIUtils, Utils} from '../../service/Utils';

type Props = {
  years: any[],
  filter: any,
};

export function PphWeightedReport(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const [pphReportTblDataQ1, setPphReportTblDataQ1] = useState([]);
  const [pphReportTblDataQ2, setPphReportTblDataQ2] = useState([]);
  const [pphReportTblDataQ3, setPphReportTblDataQ3] = useState([]);
  const [pphReportTblDataQ4, setPphReportTblDataQ4] = useState([]);
  const [pphReportTblDataYtd, setPphReportTblDataYtd] = useState([]);

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const {filter, years} = props;
  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  // const _onFilterAction: EvtHandler = (evt: any) => {
  //   let {type, value} = evt;
  //   if (type === 'filterChanged') {
  //     setFilter({...value});
  //   }
  // };

  const fetchPph = useCallback(async (options) => {
    let {years, scope} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let joins = [
      {
        type: '',
        expr: 'Factory',
        alias: 'fac',
      },
      {
        type: 'join',
        expr: 'fac.country',
        alias: 'coun',
      },
      {
        type: 'join',
        expr: 'coun.region',
        alias: 'reg',
      },
    ];
    let filters = {
      liveFlag: 'obj.flag > -1',
      jnFac: 'fac.id = obj.factoryId',
      years: 'obj.year in (:years)',
    };
    let params = {
      years: years[1],
    };

    let groupBys = ['obj.month', 'fac.code'];

    if (scope === 'country') {
      groupBys = [...groupBys, 'coun.code'];
    }
    if (scope === 'region') {
      groupBys = [...groupBys, 'reg.code'];
    }

    let fields = [
      ...groupBys,
      'obj.year',
      'sum(obj.nbProds)',
      'sum(obj.outSi)',
      'sum(obj.nbHours)',
      'coun.code',
      'reg.code',
    ];
    let sorts = ['obj.year'];

    return api
      .fetchProdRecData({
        fields,
        joins,
        groupBys,
        filters,
        sorts,
        params,
        start: 0,
        limit: 0,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        let dataPph = [...data.list];

        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        dataPph.forEach((it) => {
          let pph = 0;
          let hours = it[5] || 0;
          if (hours > 0) {
            pph = Utils.round((1.0 * it[4]) / hours);
          }
          it.splice(6, 0, pph);
        });

        return dataPph;
      })
      .catch((err) => {
        return;
      });
  }, []);
  const fetchKpis = useCallback(async (options) => {
    let {years} = options;

    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let joins = [];

    let filters = {
      byType: 'obj.kpiType = :kpiType',
      byScope: 'obj.scope = :scope',
      byPeriod: 'obj.periodType = :periodType',
      byYear: 'obj.year = :year',
    };

    let params = {
      kpiType: {
        type: 'enum:com.nb.lmrs.model.KpiType',
        value: 'PPH',
      },
      periodType: {
        type: 'enum:com.nb.lmrs.model.PeriodType',
        value: 'MONTH',
      },

      year: years[years.length - 1],
      scope: 'factory',
    };

    return api
      .getProdKpis({joins, filters, params, start: 0, limit: 0})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        const {list, factoryMap} = data;
        ////console.log.*$
        list.forEach((rec) => {
          if (rec.factoryId) {
            rec['factory'] = factoryMap[rec.factoryId];
          }
        });

        return [...list];
      })
      .catch((errors) => {
        UIUtils.showError({errors, toast: toastRef.current});
        return [];
      });

    //return [2,3,4];
  }, []);
  const fetchKpisPphYtd = useCallback(async (options) => {
    let {years} = options;

    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let joins = [];

    let filters = {
      byType: 'obj.kpiType = :kpiType',
      byScope: 'obj.scope = :scope',
      byPeriod: 'obj.periodType = :periodType',
      byYear: 'obj.year = :year',
    };

    let params = {
      kpiType: {
        type: 'enum:com.nb.lmrs.model.KpiType',
        value: 'PPH_YTD',
      },
      periodType: {
        type: 'enum:com.nb.lmrs.model.PeriodType',
        value: 'YEAR',
      },

      year: years[years.length - 1],

      scope: 'factory',
    };

    return api
      .getProdKpis({joins, filters, params, start: 0, limit: 0})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        const {list, factoryMap} = data;
        ////console.log.*$
        list.forEach((rec) => {
          if (rec.factoryId) {
            rec['factory'] = factoryMap[rec.factoryId];
          }
        });

        return [...list];
      })
      .catch((errors) => {
        UIUtils.showError({errors, toast: toastRef.current});
        return [];
      });

    //return [2,3,4];
  }, []);

  const _fetchPphWeigted = useCallback(async (options) => {
    let {year, month} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    return await api
      .getPphWeighted({data: {year, month}})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        //console.log.*$
        if (errors.length > 0) {
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        return data['data'];
      })
      .catch((err) => {});
  }, []);

  const _fetchPphData = useCallback(
    async (options) => {
      let {years, quarter} = options;
      let cy = years[years.length - 1];
      years = [cy - 1, cy];

      let api: ?AdminApi = apiRef.current;
      if (!api) {
        return;
      }

      let joins = [
        {
          type: '',
          expr: 'Factory',
          alias: 'fac',
        },
        {
          type: 'join',
          expr: 'fac.country',
          alias: 'coun',
        },
        {
          type: 'join',
          expr: 'coun.region',
          alias: 'reg',
        },
      ];
      let filters = {
        liveFlag: 'obj.flag > -1',
        jnFac: 'fac.id = obj.factoryId',
        years: 'obj.year in (:years)',
      };
      let params = {
        years: years,
      };
      let groupBys = ['obj.month', 'fac.code'];
      let fields = [
        ...groupBys,
        'obj.year',
        'sum(obj.nbProds)',
        'sum(obj.outSi)',
        'sum(obj.nbHours)',
        'coun.code',
        'reg.code',
      ];
      let sorts = ['obj.year'];
      let factories = await api.getFactories({
        filters: {
          liveFlag: 'obj.flag > -1',
        },
        start: 0,
        limit: 0,
      });

      let countries = api.getCountries({
        filters: {
          liveFlag: 'obj.flag > -1',
        },
        start: 0,
        limit: 0,
      });

      let regions = api.getRegions({
        filters: {
          liveFlag: 'obj.flag > -1',
        },
        start: 0,
        limit: 0,
      });

      api
        .fetchProdRecData({
          fields,
          joins,
          groupBys,
          filters,
          sorts,
          params,
          start: 0,
          limit: 0,
        })
        .then((resp) => resp.data)
        .then((resp) => {
          let {errors, data} = resp;
          let dataPph = [...data.list];

          if (errors.length > 0) {
            UIUtils.showError({errors, toast: toastRef.current});
            return;
          }
          dataPph.forEach((it) => {
            let pph = 0;
            let hours = it[5] || 0;
            if (hours > 0) {
              pph = Utils.round((1.0 * it[4]) / hours);
            }
            it.splice(6, 0, pph);
          });

          ////console.log.*$

          if (quarter === 1) {
            let dataPphTb = [];
            Promise.all([factories])
              .then((value) => {
                let lstFactory = value[0].data.data.list;
                lstFactory.forEach((fac) => {
                  let obj = {
                    type: fac.code,
                    T1: 0,
                    T2: 0,
                    T3: 0,
                    Q1: 0,
                    Kpi: 0,
                    Performance: 0,
                  };
                  dataPphTb.push(obj);
                });
                /// pph thang 1
                dataPphTb.forEach((rec) => {
                  for (let i = 0; i < dataPph.length; i++) {
                    if (
                      dataPph[i][1] === rec['type'] &&
                      dataPph[i][0] === 1 &&
                      dataPph[i][2] === years[1]
                    ) {
                      rec['T1'] = dataPph[i][6];
                    }
                  }
                });
                //pph thang 2
                dataPphTb.forEach((rec) => {
                  for (let i = 0; i < dataPph.length; i++) {
                    if (
                      dataPph[i][1] === rec['type'] &&
                      dataPph[i][0] === 2 &&
                      dataPph[i][2] === years[1]
                    ) {
                      rec['T2'] = dataPph[i][6];
                      break;
                    }
                  }
                });
                //pph thang 3
                dataPphTb.forEach((rec) => {
                  for (let i = 0; i < dataPph.length; i++) {
                    //  //console.log.*$
                    if (
                      dataPph[i][1] === rec['type'] &&
                      dataPph[i][0] === 3 &&
                      dataPph[i][2] === years[1]
                    ) {
                      rec['T3'] = dataPph[i][6];
                      break;
                    }
                  }
                });
                /////////Q1
                dataPphTb.forEach((rec) => {
                  let dataTempQ1 = [];
                  for (let i = 0; i < dataPph.length; i++) {
                    if (
                      dataPph[i][1] === rec['type'] &&
                      ((dataPph[i][0] === 10 && dataPph[i][2] === years[0]) ||
                        (dataPph[i][0] === 11 && dataPph[i][2] === years[0]) ||
                        (dataPph[i][0] === 12 && dataPph[i][2] === years[0]) ||
                        (dataPph[i][0] === 1 && dataPph[i][2] === years[1]) ||
                        (dataPph[i][0] === 2 && dataPph[i][2] === years[1]) ||
                        (dataPph[i][0] === 3 && dataPph[i][2] === years[1]))
                    ) {
                      dataTempQ1.push(dataPph[i]);
                    }
                  }

                  let sumProductQ1 = 0;
                  let sumSiQ1 = 0;

                  if (dataTempQ1.length > 0) {
                    dataTempQ1.forEach((dt) => {
                      sumSiQ1 += dt[4];
                      sumProductQ1 += dt[4] * dt[6];
                    });
                  }
                  rec['Q1'] = sumProductQ1 / sumSiQ1;
                });

                fetchKpis({years: years})
                  .then((value) => {
                    let dataKpis: any[] = value || [];
                    dataPphTb.forEach((rec) => {
                      let temp = [];
                      for (let i = 0; i < dataKpis.length; i++) {
                        if (
                          dataKpis[i]['factory']['code'] === rec['type'] &&
                          (dataKpis[i]['month'] === 1 ||
                            dataKpis[i]['month'] === 2 ||
                            dataKpis[i]['month'] === 3 ||
                            dataKpis[i]['month'] === -1)
                        ) {
                          temp.push(dataKpis[i]);
                        }
                      }
                      if (temp.length > 0) {
                        let lstKpi = temp.map((dt) => {
                          return dt['targetValue'];
                        });

                        rec['Kpi'] = Utils.avg(lstKpi);
                      }
                    });

                    dataPphTb.forEach((rec) => {
                      if (!isNaN(rec['Q1']) && rec['Kpi'] !== 0) {
                        rec['Performance'] = rec['Q1'] / rec['Kpi'];
                      }
                    });

                    countries
                      .then((resp) => resp.data)
                      .then((resp) => {
                        let {errors, data} = resp;
                        if (errors.length > 0) {
                          UIUtils.showError({errors, toast: toastRef.current});
                          return;
                        }
                        let listFactory = [...data.list];
                        //console.log.*$
                        let dataPphTblCoun = listFactory.map((fac) => {
                          return {
                            type: fac.code,
                            T1: 0,
                            T2: 0,
                            T3: 0,
                            Q1: 0,
                            Kpi: 0,
                            Performance: 0,
                          };
                        });

                        //console.log.*$
                        fetchPph({scope: 'factory', years: years})
                          .then((value) => {
                            let dataPphCou: any[] = value || [];
                            //console.log.*$
                            ///pph thang 1
                            ////console.log.*$
                            dataPphTblCoun.forEach((rec) => {
                              let lstTemp = [];
                              let sumProduct = 0;
                              let sumSi = 0;
                              for (let i = 0; i < dataPphCou.length; i++) {
                                if (
                                  dataPphCou[i][0] === 1 &&
                                  dataPphCou[i][7] === rec['type']
                                ) {
                                  lstTemp.push(dataPphCou[i]);
                                }
                                if (lstTemp.length > 0) {
                                  for (let i = 0; i < lstTemp.length; i++) {
                                    sumSi += lstTemp[i][4];
                                    sumProduct += lstTemp[i][4] * lstTemp[i][6];
                                  }
                                }
                                ////console.log.*$
                                ////console.log.*$
                                rec['T1'] = sumProduct / sumSi;
                              }
                            });
                            //pph thang 2
                            dataPphTblCoun.forEach((rec) => {
                              let lstTemp = [];
                              let sumProduct = 0;
                              let sumSi = 0;
                              for (let i = 0; i < dataPphCou.length; i++) {
                                if (
                                  dataPphCou[i][0] === 2 &&
                                  dataPphCou[i][7] === rec['type']
                                ) {
                                  lstTemp.push(dataPphCou[i]);
                                }

                                if (lstTemp.length > 0) {
                                  for (let i = 0; i < lstTemp.length; i++) {
                                    sumSi += lstTemp[i][4];
                                    sumProduct += lstTemp[i][4] * lstTemp[i][6];
                                  }
                                }
                                rec['T2'] = sumProduct / sumSi;
                              }
                            });
                            //pph thang 3
                            dataPphTblCoun.forEach((rec) => {
                              let lstTemp = [];
                              let sumProduct = 0;
                              let sumSi = 0;
                              for (let i = 0; i < dataPphCou.length; i++) {
                                if (
                                  dataPphCou[i][0] === 3 &&
                                  dataPphCou[i][7] === rec['type']
                                ) {
                                  lstTemp.push(dataPphCou[i]);
                                }

                                if (lstTemp.length > 0) {
                                  for (let i = 0; i < lstTemp.length; i++) {
                                    sumSi += lstTemp[i][4];
                                    sumProduct += lstTemp[i][4] * lstTemp[i][6];
                                  }
                                }
                                rec['T2'] = sumProduct / sumSi;
                              }
                            });

                            //console.log.*$
                            //tinh Q1
                            dataPphTblCoun.forEach((rec) => {
                              let dataTemp = [];
                              for (let i = 0; i < dataPph.length; i++) {
                                if (
                                  dataPph[i][7] === rec['type'] &&
                                  ((dataPph[i][0] === 10 &&
                                    dataPph[i][2] === years[0]) ||
                                    (dataPph[i][0] === 11 &&
                                      dataPph[i][2] === years[0]) ||
                                    (dataPph[i][0] === 12 &&
                                      dataPph[i][2] === years[0]) ||
                                    (dataPph[i][0] === 1 &&
                                      dataPph[i][2] === years[1]) ||
                                    (dataPph[i][0] === 2 &&
                                      dataPph[i][2] === years[1]) ||
                                    (dataPph[i][0] === 3 &&
                                      dataPph[i][2] === years[1]))
                                ) {
                                  dataTemp.push(dataPph[i]);
                                }

                                let sumProduct = 0;
                                let sumSi = 0;

                                if (dataTemp.length > 0) {
                                  dataTemp.forEach((dt) => {
                                    sumSi += dt[4];
                                    sumProduct += dt[4] * dt[6];
                                  });
                                }
                                rec['Q1'] = sumProduct / sumSi;
                              }
                            });
                            ///kpi
                            dataPphTblCoun.forEach((rec) => {
                              let dataTemp = [];
                              for (let i = 0; i < dataKpis.length; i++) {
                                if (
                                  dataKpis[i]['factory']['country']['code'] ===
                                    rec['type'] &&
                                  (dataKpis[i]['month'] === 1 ||
                                    dataKpis[i]['month'] === 2 ||
                                    dataKpis[i]['month'] === 3 ||
                                    dataKpis[i]['month'] === -1)
                                ) {
                                  dataTemp.push(dataKpis[i]);
                                }

                                // //console.log.*$
                              }
                              if (dataTemp.length > 0) {
                                let lstKpi = dataTemp.map((dt) => {
                                  return dt['targetValue'];
                                });
                                rec['Kpi'] = Utils.avg(lstKpi);
                              }
                            });

                            dataPphTblCoun.forEach((rec) => {
                              if (!isNaN(rec['Q1']) && rec['Kpi'] !== 0) {
                                rec['Performance'] = rec['Q1'] / rec['Kpi'];
                              }
                            });

                            regions
                              .then((resp) => resp.data)
                              .then((resp) => {
                                let {errors, data} = resp;
                                if (errors.length > 0) {
                                  UIUtils.showError({
                                    errors,
                                    toast: toastRef.current,
                                  });
                                  return;
                                }
                                let listRegion = [...data.list];
                                let dataPphTblReg2 = [];

                                let dataPphTblReg = listRegion.map((reg) => {
                                  return {
                                    type: reg.code,
                                    T1: 0,
                                    T2: 0,
                                    T3: 0,
                                    Q1: 0,
                                    Kpi: 0,
                                    Performance: 0,
                                  };
                                });

                                fetchPph({scope: 'factory', years: years})
                                  .then((value) => {
                                    let dataPphReg: any[] = value || [];

                                    //console.log.*$
                                    //pph thang 1

                                    dataPphTblReg.forEach((rec) => {
                                      let sumProduct = 0;
                                      let sumSi = 0;
                                      let lstTemp = [];
                                      for (
                                        let i = 0;
                                        i < dataPphReg.length;
                                        i++
                                      ) {
                                        if (
                                          rec['type'] === dataPphReg[i][8] &&
                                          dataPphReg[i][0] === 1
                                        ) {
                                          lstTemp.push(dataPphReg[i]);
                                        }
                                      }

                                      if (lstTemp.length > 0) {
                                        for (
                                          let i = 0;
                                          i < lstTemp.length;
                                          i++
                                        ) {
                                          sumSi += lstTemp[i][4];
                                          sumProduct +=
                                            lstTemp[i][4] * lstTemp[i][6];
                                        }
                                      }
                                      rec['T1'] = sumProduct / sumSi;
                                    });

                                    dataPphTblReg.forEach((rec) => {
                                      let sumProduct = 0;
                                      let sumSi = 0;
                                      let lstTemp = [];
                                      for (
                                        let i = 0;
                                        i < dataPphReg.length;
                                        i++
                                      ) {
                                        if (
                                          rec['type'] === dataPphReg[i][8] &&
                                          dataPphReg[i][0] === 2
                                        ) {
                                          lstTemp.push(dataPphReg[i]);
                                        }
                                      }

                                      if (lstTemp.length > 0) {
                                        for (
                                          let i = 0;
                                          i < lstTemp.length;
                                          i++
                                        ) {
                                          sumSi += lstTemp[i][4];
                                          sumProduct +=
                                            lstTemp[i][4] * lstTemp[i][6];
                                        }
                                      }
                                      rec['T2'] = sumProduct / sumSi;
                                    });

                                    dataPphTblReg.forEach((rec) => {
                                      let sumProduct = 0;
                                      let sumSi = 0;
                                      let lstTemp = [];
                                      for (
                                        let i = 0;
                                        i < dataPphReg.length;
                                        i++
                                      ) {
                                        if (
                                          rec['type'] === dataPphReg[i][8] &&
                                          dataPphReg[i][0] === 3
                                        ) {
                                          lstTemp.push(dataPphReg[i]);
                                        }
                                      }

                                      if (lstTemp.length > 0) {
                                        for (
                                          let i = 0;
                                          i < lstTemp.length;
                                          i++
                                        ) {
                                          sumSi += lstTemp[i][4];
                                          sumProduct +=
                                            lstTemp[i][4] * lstTemp[i][6];
                                        }
                                      }
                                      rec['T3'] = sumProduct / sumSi;
                                    });

                                    dataPphTblReg.forEach((rec) => {
                                      let dataTemp = [];
                                      for (let i = 0; i < dataPph.length; i++) {
                                        if (
                                          dataPph[i][8] === rec['type'] &&
                                          ((dataPph[i][0] === 10 &&
                                            dataPph[i][2] === years[0]) ||
                                            (dataPph[i][0] === 11 &&
                                              dataPph[i][2] === years[0]) ||
                                            (dataPph[i][0] === 12 &&
                                              dataPph[i][2] === years[0]) ||
                                            (dataPph[i][0] === 1 &&
                                              dataPph[i][2] === years[1]) ||
                                            (dataPph[i][0] === 2 &&
                                              dataPph[i][2] === years[1]) ||
                                            (dataPph[i][0] === 3 &&
                                              dataPph[i][2] === years[1]))
                                        ) {
                                          dataTemp.push(dataPph[i]);
                                        }
                                      }
                                      let sumProduct = 0;
                                      let sumSi = 0;
                                      if (dataTemp.length > 0) {
                                        dataTemp.forEach((dt) => {
                                          sumSi += dt[4];
                                          sumProduct += dt[4] * dt[6];
                                        });
                                      }
                                      rec['Q1'] = sumProduct / sumSi;
                                    });

                                    // kpis
                                    //gan region

                                    dataKpis.forEach((rec) => {
                                      for (
                                        let i = 0;
                                        i < listRegion.length;
                                        i++
                                      ) {
                                        if (
                                          rec['factory']['country'][
                                            'region'
                                          ] === listRegion[i]['id']
                                        ) {
                                          rec['regionCode'] =
                                            listRegion[i]['code'];
                                        }
                                      }
                                    });

                                    dataPphTblReg.forEach((rec) => {
                                      let dataTemp = [];
                                      for (
                                        let i = 0;
                                        i < dataKpis.length;
                                        i++
                                      ) {
                                        if (
                                          dataKpis[i]['regionCode'] ===
                                            rec['type'] &&
                                          (dataKpis[i]['month'] === 1 ||
                                            dataKpis[i]['month'] === 2 ||
                                            dataKpis[i]['month'] === 3 ||
                                            dataKpis[i]['month'] === -1)
                                        ) {
                                          dataTemp.push(dataKpis[i]);
                                        }
                                      }

                                      if (dataTemp.length > 0) {
                                        let lstKpi = dataTemp.map((dt) => {
                                          return dt['targetValue'];
                                        });

                                        rec['Kpi'] = Utils.avg(lstKpi);
                                      }
                                    });
                                    // //console.log.*$
                                    //perform
                                    dataPphTblReg.forEach((rec) => {
                                      if (rec['Kpi'] > 0) {
                                        rec['Performance'] =
                                          rec['Q1'] / rec['Kpi'];
                                      }
                                    });
                                    // target region
                                    // let dataTblTargetRegion = [];
                                    let dataPphTblReg1 = [...dataPphTblReg];

                                    for (
                                      let j = 0;
                                      j < dataPphTblReg1.length;
                                      j++
                                    ) {
                                      dataPphTblReg2.push(dataPphTblReg1[j]);
                                      //  if(dataPphTblReg1[j]['type'] === listRegion[i]['code']){
                                      let obj1 = {
                                        type:
                                          dataPphTblReg1[j]['type'] + ' Target',
                                        T1: dataPphTblReg1[j]['Kpi'],
                                        T2: dataPphTblReg1[j]['Kpi'],
                                        T3: dataPphTblReg1[j]['Kpi'],
                                        Q1: dataPphTblReg1[j]['Kpi'],
                                        Kpi: 0,
                                        Performance: 0,
                                      };
                                      dataPphTblReg2.push(obj1);
                                      // dataPphTblReg.splice(j+1,0,obj1);
                                      let obj2 = {
                                        type:
                                          dataPphTblReg1[j]['type'] +
                                          ' Perf vs Trgt',
                                        T1: isFinite(
                                          dataPphTblReg1[j]['T1'] /
                                            dataPphTblReg1[j]['Kpi'],
                                        )
                                          ? dataPphTblReg1[j]['T1'] /
                                            dataPphTblReg1[j]['Kpi']
                                          : 0,
                                        T2: isFinite(
                                          dataPphTblReg1[j]['T2'] /
                                            dataPphTblReg1[j]['Kpi'],
                                        )
                                          ? dataPphTblReg1[j]['T2'] /
                                            dataPphTblReg1[j]['Kpi']
                                          : 0,
                                        T3: isFinite(
                                          dataPphTblReg1[j]['T3'] /
                                            dataPphTblReg1[j]['Kpi'],
                                        )
                                          ? dataPphTblReg1[j]['T3'] /
                                            dataPphTblReg1[j]['Kpi']
                                          : 0,
                                        Q1: isFinite(
                                          dataPphTblReg1[j]['Q1'] /
                                            dataPphTblReg1[j]['Kpi'],
                                        )
                                          ? dataPphTblReg1[j]['Q1'] /
                                            dataPphTblReg1[j]['Kpi']
                                          : 0,
                                        Kpi: 0,
                                        Performance: 0,
                                      };
                                      dataPphTblReg2.push(obj2);

                                      // }
                                    }

                                    // }
                                    let dataTbl = [
                                      ...dataPphTb,
                                      ...dataPphTblCoun,
                                      ...dataPphTblReg2,
                                    ];

                                    setPphReportTblDataQ1(dataTbl);

                                    //gioi han 4
                                  })
                                  .catch((errors) => {
                                    UIUtils.showError({
                                      errors,
                                      toast: toastRef.current,
                                    });
                                    return;
                                  });

                                //gioi han 3
                              })
                              .catch((errors) => {
                                UIUtils.showError({
                                  errors,
                                  toast: toastRef.current,
                                });
                                return;
                              });

                            //gioi han 2
                          })
                          .catch((errors) => {
                            UIUtils.showError({
                              errors,
                              toast: toastRef.current,
                            });
                            return;
                          });

                        //gioi han
                      })
                      .catch((errors) => {
                        UIUtils.showError({errors, toast: toastRef.current});
                        return;
                      });

                    //// gioi han
                  })
                  .catch((errors) => {
                    UIUtils.showError({errors, toast: toastRef.current});
                    return;
                  });
                ////////////////gioihan
              })
              .catch((errors) => {
                UIUtils.showError({errors, toast: toastRef.current});
                return;
              });
          }
          if (quarter === 2) {
            let dataPphTb = [];
            Promise.all([factories])
              .then((value) => {
                let lstFactory = value[0].data.data.list;
                lstFactory.forEach((fac) => {
                  let obj = {
                    type: fac.code,
                    T1: 0,
                    T2: 0,
                    T3: 0,
                    Q1: 0,
                    Kpi: 0,
                    Performance: 0,
                  };
                  dataPphTb.push(obj);
                });
                /// pph thang 1
                dataPphTb.forEach((rec) => {
                  for (let i = 0; i < dataPph.length; i++) {
                    if (
                      dataPph[i][1] === rec['type'] &&
                      dataPph[i][0] === 4 &&
                      dataPph[i][2] === years[1]
                    ) {
                      rec['T1'] = dataPph[i][6];
                    }
                  }
                });
                //pph thang 2
                dataPphTb.forEach((rec) => {
                  for (let i = 0; i < dataPph.length; i++) {
                    if (
                      dataPph[i][1] === rec['type'] &&
                      dataPph[i][0] === 5 &&
                      dataPph[i][2] === years[1]
                    ) {
                      rec['T2'] = dataPph[i][6];
                      break;
                    }
                  }
                });
                //pph thang 3
                dataPphTb.forEach((rec) => {
                  for (let i = 0; i < dataPph.length; i++) {
                    //  //console.log.*$
                    if (
                      dataPph[i][1] === rec['type'] &&
                      dataPph[i][0] === 6 &&
                      dataPph[i][2] === years[1]
                    ) {
                      rec['T3'] = dataPph[i][6];
                      break;
                    }
                  }
                });
                /////////Q1
                dataPphTb.forEach((rec) => {
                  let dataTempQ1 = [];
                  for (let i = 0; i < dataPph.length; i++) {
                    if (
                      dataPph[i][1] === rec['type'] &&
                      ((dataPph[i][0] === 1 && dataPph[i][2] === years[1]) ||
                        (dataPph[i][0] === 2 && dataPph[i][2] === years[1]) ||
                        (dataPph[i][0] === 3 && dataPph[i][2] === years[1]) ||
                        (dataPph[i][0] === 4 && dataPph[i][2] === years[1]) ||
                        (dataPph[i][0] === 5 && dataPph[i][2] === years[1]) ||
                        (dataPph[i][0] === 6 && dataPph[i][2] === years[1]))
                    ) {
                      dataTempQ1.push(dataPph[i]);
                    }
                  }

                  let sumProductQ1 = 0;
                  let sumSiQ1 = 0;

                  if (dataTempQ1.length > 0) {
                    dataTempQ1.forEach((dt) => {
                      sumSiQ1 += dt[4];
                      sumProductQ1 += dt[4] * dt[6];
                    });
                  }
                  rec['Q1'] = sumProductQ1 / sumSiQ1;
                });

                fetchKpis({years: years})
                  .then((value) => {
                    let dataKpis: any[] = value || [];
                    dataPphTb.forEach((rec) => {
                      let temp = [];
                      for (let i = 0; i < dataKpis.length; i++) {
                        if (
                          dataKpis[i]['factory']['code'] === rec['type'] &&
                          (dataKpis[i]['month'] === 4 ||
                            dataKpis[i]['month'] === 5 ||
                            dataKpis[i]['month'] === 6 ||
                            dataKpis[i]['month'] === -1)
                        ) {
                          temp.push(dataKpis[i]);
                        }
                      }
                      if (temp.length > 0) {
                        let lstKpi = temp.map((dt) => {
                          return dt['targetValue'];
                        });

                        rec['Kpi'] = Utils.avg(lstKpi);
                      }
                    });

                    dataPphTb.forEach((rec) => {
                      if (!isNaN(rec['Q1']) && rec['Kpi'] !== 0) {
                        rec['Performance'] = rec['Q1'] / rec['Kpi'];
                      }
                    });

                    countries
                      .then((resp) => resp.data)
                      .then((resp) => {
                        let {errors, data} = resp;
                        if (errors.length > 0) {
                          UIUtils.showError({errors, toast: toastRef.current});
                          return;
                        }
                        let listFactory = [...data.list];
                        let dataPphTblCoun = listFactory.map((fac) => {
                          return {
                            type: fac.code,
                            T1: 0,
                            T2: 0,
                            T3: 0,
                            Q1: 0,
                            Kpi: 0,
                            Performance: 0,
                          };
                        });

                        //console.log.*$
                        fetchPph({scope: 'factory', years: years})
                          .then((value) => {
                            let dataPphCou: any[] = value || [];
                            ////console.log.*$
                            ///pph thang 4
                            dataPphTblCoun.forEach((rec) => {
                              let lstTemp = [];
                              let sumProduct = 0;
                              let sumSi = 0;
                              for (let i = 0; i < dataPphCou.length; i++) {
                                if (
                                  dataPphCou[i][0] === 4 &&
                                  dataPphCou[i][7] === rec['type']
                                ) {
                                  lstTemp.push(dataPphCou[i]);
                                }

                                if (lstTemp.length > 0) {
                                  for (let i = 0; i < lstTemp.length; i++) {
                                    sumSi += lstTemp[i][4];
                                    sumProduct += lstTemp[i][4] * lstTemp[i][6];
                                  }
                                }
                                rec['T1'] = sumProduct / sumSi;
                              }
                            });

                            ///pph thang 5
                            dataPphTblCoun.forEach((rec) => {
                              let lstTemp = [];
                              let sumProduct = 0;
                              let sumSi = 0;
                              for (let i = 0; i < dataPphCou.length; i++) {
                                if (
                                  dataPphCou[i][0] === 5 &&
                                  dataPphCou[i][7] === rec['type']
                                ) {
                                  lstTemp.push(dataPphCou[i]);
                                }

                                if (lstTemp.length > 0) {
                                  for (let i = 0; i < lstTemp.length; i++) {
                                    sumSi += lstTemp[i][4];
                                    sumProduct += lstTemp[i][4] * lstTemp[i][6];
                                  }
                                }
                                rec['T2'] = sumProduct / sumSi;
                              }
                            });

                            //pph thang 6
                            dataPphTblCoun.forEach((rec) => {
                              let lstTemp = [];
                              let sumProduct = 0;
                              let sumSi = 0;
                              for (let i = 0; i < dataPphCou.length; i++) {
                                if (
                                  dataPphCou[i][0] === 6 &&
                                  dataPphCou[i][7] === rec['type']
                                ) {
                                  lstTemp.push(dataPphCou[i]);
                                }

                                if (lstTemp.length > 0) {
                                  for (let i = 0; i < lstTemp.length; i++) {
                                    sumSi += lstTemp[i][4];
                                    sumProduct += lstTemp[i][4] * lstTemp[i][6];
                                  }
                                }
                                rec['T2'] = sumProduct / sumSi;
                              }
                            });

                            //tinh Q1
                            dataPphTblCoun.forEach((rec) => {
                              let dataTemp = [];
                              for (let i = 0; i < dataPph.length; i++) {
                                if (
                                  dataPph[i][7] === rec['type'] &&
                                  ((dataPph[i][0] === 1 &&
                                    dataPph[i][2] === years[1]) ||
                                    (dataPph[i][0] === 2 &&
                                      dataPph[i][2] === years[1]) ||
                                    (dataPph[i][0] === 3 &&
                                      dataPph[i][2] === years[1]) ||
                                    (dataPph[i][0] === 4 &&
                                      dataPph[i][2] === years[1]) ||
                                    (dataPph[i][0] === 5 &&
                                      dataPph[i][2] === years[1]) ||
                                    (dataPph[i][0] === 6 &&
                                      dataPph[i][2] === years[1]))
                                ) {
                                  dataTemp.push(dataPph[i]);
                                }

                                let sumProduct = 0;
                                let sumSi = 0;

                                if (dataTemp.length > 0) {
                                  dataTemp.forEach((dt) => {
                                    sumSi += dt[4];
                                    sumProduct += dt[4] * dt[6];
                                  });
                                }
                                rec['Q1'] = sumProduct / sumSi;
                              }
                            });
                            ///kpi
                            dataPphTblCoun.forEach((rec) => {
                              let dataTemp = [];
                              for (let i = 0; i < dataKpis.length; i++) {
                                if (
                                  dataKpis[i]['factory']['country']['code'] ===
                                    rec['type'] &&
                                  (dataKpis[i]['month'] === 4 ||
                                    dataKpis[i]['month'] === 5 ||
                                    dataKpis[i]['month'] === 6 ||
                                    dataKpis[i]['month'] === -1)
                                ) {
                                  dataTemp.push(dataKpis[i]);
                                }

                                // //console.log.*$
                              }
                              if (dataTemp.length > 0) {
                                let lstKpi = dataTemp.map((dt) => {
                                  return dt['targetValue'];
                                });
                                rec['Kpi'] = Utils.avg(lstKpi);
                              }
                            });

                            dataPphTblCoun.forEach((rec) => {
                              if (!isNaN(rec['Q1']) && rec['Kpi'] !== 0) {
                                rec['Performance'] = rec['Q1'] / rec['Kpi'];
                              }
                            });

                            regions
                              .then((resp) => resp.data)
                              .then((resp) => {
                                let {errors, data} = resp;
                                if (errors.length > 0) {
                                  UIUtils.showError({
                                    errors,
                                    toast: toastRef.current,
                                  });
                                  return;
                                }
                                let listRegion = [...data.list];
                                let dataPphTblReg2 = [];

                                let dataPphTblReg = listRegion.map((reg) => {
                                  return {
                                    type: reg.code,
                                    T1: 0,
                                    T2: 0,
                                    T3: 0,
                                    Q1: 0,
                                    Kpi: 0,
                                    Performance: 0,
                                  };
                                });

                                fetchPph({scope: 'factory', years: years})
                                  .then((value) => {
                                    let dataPphReg: any[] = value || [];

                                    //pph thang 4
                                    dataPphTblReg.forEach((rec) => {
                                      let sumProduct = 0;
                                      let sumSi = 0;
                                      let lstTemp = [];
                                      for (
                                        let i = 0;
                                        i < dataPphReg.length;
                                        i++
                                      ) {
                                        if (
                                          rec['type'] === dataPphReg[i][8] &&
                                          dataPphReg[i][0] === 4
                                        ) {
                                          lstTemp.push(dataPphReg[i]);
                                        }
                                      }

                                      if (lstTemp.length > 0) {
                                        for (
                                          let i = 0;
                                          i < lstTemp.length;
                                          i++
                                        ) {
                                          sumSi += lstTemp[i][4];
                                          sumProduct +=
                                            lstTemp[i][4] * lstTemp[i][6];
                                        }
                                      }
                                      rec['T1'] = sumProduct / sumSi;
                                    });
                                    //pph thang 5
                                    dataPphTblReg.forEach((rec) => {
                                      let sumProduct = 0;
                                      let sumSi = 0;
                                      let lstTemp = [];
                                      for (
                                        let i = 0;
                                        i < dataPphReg.length;
                                        i++
                                      ) {
                                        if (
                                          rec['type'] === dataPphReg[i][8] &&
                                          dataPphReg[i][0] === 5
                                        ) {
                                          lstTemp.push(dataPphReg[i]);
                                        }
                                      }

                                      if (lstTemp.length > 0) {
                                        for (
                                          let i = 0;
                                          i < lstTemp.length;
                                          i++
                                        ) {
                                          sumSi += lstTemp[i][4];
                                          sumProduct +=
                                            lstTemp[i][4] * lstTemp[i][6];
                                        }
                                      }
                                      rec['T2'] = sumProduct / sumSi;
                                    });
                                    //pph thang 6

                                    dataPphTblReg.forEach((rec) => {
                                      let sumProduct = 0;
                                      let sumSi = 0;
                                      let lstTemp = [];
                                      for (
                                        let i = 0;
                                        i < dataPphReg.length;
                                        i++
                                      ) {
                                        if (
                                          rec['type'] === dataPphReg[i][8] &&
                                          dataPphReg[i][0] === 6
                                        ) {
                                          lstTemp.push(dataPphReg[i]);
                                        }
                                      }

                                      if (lstTemp.length > 0) {
                                        for (
                                          let i = 0;
                                          i < lstTemp.length;
                                          i++
                                        ) {
                                          sumSi += lstTemp[i][4];
                                          sumProduct +=
                                            lstTemp[i][4] * lstTemp[i][6];
                                        }
                                      }
                                      rec['T1'] = sumProduct / sumSi;
                                    });

                                    dataPphTblReg.forEach((rec) => {
                                      let dataTemp = [];
                                      for (let i = 0; i < dataPph.length; i++) {
                                        if (
                                          dataPph[i][8] === rec['type'] &&
                                          ((dataPph[i][0] === 1 &&
                                            dataPph[i][2] === years[1]) ||
                                            (dataPph[i][0] === 2 &&
                                              dataPph[i][2] === years[1]) ||
                                            (dataPph[i][0] === 3 &&
                                              dataPph[i][2] === years[1]) ||
                                            (dataPph[i][0] === 4 &&
                                              dataPph[i][2] === years[1]) ||
                                            (dataPph[i][0] === 5 &&
                                              dataPph[i][2] === years[1]) ||
                                            (dataPph[i][0] === 6 &&
                                              dataPph[i][2] === years[1]))
                                        ) {
                                          dataTemp.push(dataPph[i]);
                                        }
                                      }
                                      let sumProduct = 0;
                                      let sumSi = 0;
                                      if (dataTemp.length > 0) {
                                        dataTemp.forEach((dt) => {
                                          sumSi += dt[4];
                                          sumProduct += dt[4] * dt[6];
                                        });
                                      }
                                      rec['Q1'] = sumProduct / sumSi;
                                    });

                                    // kpis
                                    //gan region
                                    dataKpis.forEach((rec) => {
                                      for (
                                        let i = 0;
                                        i < listRegion.length;
                                        i++
                                      ) {
                                        if (
                                          rec['factory']['country'][
                                            'region'
                                          ] === listRegion[i]['id']
                                        ) {
                                          rec['regionCode'] =
                                            listRegion[i]['code'];
                                        }
                                      }
                                    });

                                    dataPphTblReg.forEach((rec) => {
                                      let dataTemp = [];
                                      for (
                                        let i = 0;
                                        i < dataKpis.length;
                                        i++
                                      ) {
                                        if (
                                          dataKpis[i]['regionCode'] ===
                                            rec['type'] &&
                                          (dataKpis[i]['month'] === 4 ||
                                            dataKpis[i]['month'] === 5 ||
                                            dataKpis[i]['month'] === 6 ||
                                            dataKpis[i]['month'] === -1)
                                        ) {
                                          dataTemp.push(dataKpis[i]);
                                        }
                                      }

                                      if (dataTemp.length > 0) {
                                        let lstKpi = dataTemp.map((dt) => {
                                          return dt['targetValue'];
                                        });

                                        rec['Kpi'] = Utils.avg(lstKpi);
                                      }
                                    });
                                    // //console.log.*$
                                    //perform
                                    dataPphTblReg.forEach((rec) => {
                                      if (rec['Kpi'] > 0) {
                                        rec['Performance'] =
                                          rec['Q1'] / rec['Kpi'];
                                      }
                                    });
                                    // target region
                                    //let dataTblTargetRegion = [];
                                    let dataPphTblReg1 = [...dataPphTblReg];

                                    for (
                                      let j = 0;
                                      j < dataPphTblReg1.length;
                                      j++
                                    ) {
                                      dataPphTblReg2.push(dataPphTblReg1[j]);
                                      //  if(dataPphTblReg1[j]['type'] === listRegion[i]['code']){
                                      let obj1 = {
                                        type:
                                          dataPphTblReg1[j]['type'] + ' Target',
                                        T1: dataPphTblReg1[j]['Kpi'],
                                        T2: dataPphTblReg1[j]['Kpi'],
                                        T3: dataPphTblReg1[j]['Kpi'],
                                        Q1: dataPphTblReg1[j]['Kpi'],
                                        Kpi: 0,
                                        Performance: 0,
                                      };
                                      dataPphTblReg2.push(obj1);
                                      // dataPphTblReg.splice(j+1,0,obj1);
                                      let obj2 = {
                                        type:
                                          dataPphTblReg1[j]['type'] +
                                          ' Perf vs Trgt',
                                        T1: isFinite(
                                          dataPphTblReg1[j]['T1'] /
                                            dataPphTblReg1[j]['Kpi'],
                                        )
                                          ? dataPphTblReg1[j]['T1'] /
                                            dataPphTblReg1[j]['Kpi']
                                          : 0,
                                        T2: isFinite(
                                          dataPphTblReg1[j]['T2'] /
                                            dataPphTblReg1[j]['Kpi'],
                                        )
                                          ? dataPphTblReg1[j]['T2'] /
                                            dataPphTblReg1[j]['Kpi']
                                          : 0,
                                        T3: isFinite(
                                          dataPphTblReg1[j]['T3'] /
                                            dataPphTblReg1[j]['Kpi'],
                                        )
                                          ? dataPphTblReg1[j]['T3'] /
                                            dataPphTblReg1[j]['Kpi']
                                          : 0,
                                        Q1: isFinite(
                                          dataPphTblReg1[j]['Q1'] /
                                            dataPphTblReg1[j]['Kpi'],
                                        )
                                          ? dataPphTblReg1[j]['Q1'] /
                                            dataPphTblReg1[j]['Kpi']
                                          : 0,
                                        Kpi: 0,
                                        Performance: 0,
                                      };
                                      dataPphTblReg2.push(obj2);

                                      // }
                                    }

                                    // }
                                    let dataTbl = [
                                      ...dataPphTb,
                                      ...dataPphTblCoun,
                                      ...dataPphTblReg2,
                                    ];

                                    setPphReportTblDataQ2(dataTbl);

                                    //gioi han 4
                                  })
                                  .catch((errors) => {
                                    UIUtils.showError({
                                      errors,
                                      toast: toastRef.current,
                                    });
                                    return;
                                  });

                                //gioi han 3
                              })
                              .catch((errors) => {
                                UIUtils.showError({
                                  errors,
                                  toast: toastRef.current,
                                });
                                return;
                              });

                            //gioi han 2
                          })
                          .catch((errors) => {
                            UIUtils.showError({
                              errors,
                              toast: toastRef.current,
                            });
                            return;
                          });

                        //gioi han
                      })
                      .catch((errors) => {
                        UIUtils.showError({errors, toast: toastRef.current});
                        return;
                      });

                    //// gioi han
                  })
                  .catch((errors) => {
                    UIUtils.showError({errors, toast: toastRef.current});
                    return;
                  });
                ////////////////gioihan
              })
              .catch((errors) => {
                UIUtils.showError({errors, toast: toastRef.current});
                return;
              });
          }
          if (quarter === 3) {
            let dataPphTb = [];
            Promise.all([factories])
              .then((value) => {
                let lstFactory = value[0].data.data.list;
                lstFactory.forEach((fac) => {
                  let obj = {
                    type: fac.code,
                    T1: 0,
                    T2: 0,
                    T3: 0,
                    Q1: 0,
                    Kpi: 0,
                    Performance: 0,
                  };
                  dataPphTb.push(obj);
                });
                /// pph thang 1
                dataPphTb.forEach((rec) => {
                  for (let i = 0; i < dataPph.length; i++) {
                    if (
                      dataPph[i][1] === rec['type'] &&
                      dataPph[i][0] === 7 &&
                      dataPph[i][2] === years[1]
                    ) {
                      rec['T1'] = dataPph[i][6];
                    }
                  }
                });
                //pph thang 2
                dataPphTb.forEach((rec) => {
                  for (let i = 0; i < dataPph.length; i++) {
                    if (
                      dataPph[i][1] === rec['type'] &&
                      dataPph[i][0] === 8 &&
                      dataPph[i][2] === years[1]
                    ) {
                      rec['T2'] = dataPph[i][6];
                      break;
                    }
                  }
                });
                //pph thang 3
                dataPphTb.forEach((rec) => {
                  for (let i = 0; i < dataPph.length; i++) {
                    //  //console.log.*$
                    if (
                      dataPph[i][1] === rec['type'] &&
                      dataPph[i][0] === 9 &&
                      dataPph[i][2] === years[1]
                    ) {
                      rec['T3'] = dataPph[i][6];
                      break;
                    }
                  }
                });
                /////////Q1
                dataPphTb.forEach((rec) => {
                  let dataTempQ1 = [];
                  for (let i = 0; i < dataPph.length; i++) {
                    if (
                      dataPph[i][1] === rec['type'] &&
                      ((dataPph[i][0] === 4 && dataPph[i][2] === years[1]) ||
                        (dataPph[i][0] === 5 && dataPph[i][2] === years[1]) ||
                        (dataPph[i][0] === 6 && dataPph[i][2] === years[1]) ||
                        (dataPph[i][0] === 7 && dataPph[i][2] === years[1]) ||
                        (dataPph[i][0] === 8 && dataPph[i][2] === years[1]) ||
                        (dataPph[i][0] === 9 && dataPph[i][2] === years[1]))
                    ) {
                      dataTempQ1.push(dataPph[i]);
                    }
                  }

                  let sumProductQ1 = 0;
                  let sumSiQ1 = 0;

                  if (dataTempQ1.length > 0) {
                    dataTempQ1.forEach((dt) => {
                      sumSiQ1 += dt[4];
                      sumProductQ1 += dt[4] * dt[6];
                    });
                  }
                  rec['Q1'] = sumProductQ1 / sumSiQ1;
                });

                fetchKpis({years: years})
                  .then((value) => {
                    let dataKpis: any[] = value || [];
                    dataPphTb.forEach((rec) => {
                      let temp = [];
                      for (let i = 0; i < dataKpis.length; i++) {
                        if (
                          dataKpis[i]['factory']['code'] === rec['type'] &&
                          (dataKpis[i]['month'] === 7 ||
                            dataKpis[i]['month'] === 8 ||
                            dataKpis[i]['month'] === 9 ||
                            dataKpis[i]['month'] === -1)
                        ) {
                          temp.push(dataKpis[i]);
                        }
                      }
                      if (temp.length > 0) {
                        let lstKpi = temp.map((dt) => {
                          return dt['targetValue'];
                        });

                        rec['Kpi'] = Utils.avg(lstKpi);
                      }
                    });

                    dataPphTb.forEach((rec) => {
                      if (!isNaN(rec['Q1']) && rec['Kpi'] !== 0) {
                        rec['Performance'] = rec['Q1'] / rec['Kpi'];
                      }
                    });

                    countries
                      .then((resp) => resp.data)
                      .then((resp) => {
                        let {errors, data} = resp;
                        if (errors.length > 0) {
                          UIUtils.showError({errors, toast: toastRef.current});
                          return;
                        }
                        let listFactory = [...data.list];
                        let dataPphTblCoun = listFactory.map((fac) => {
                          return {
                            type: fac.code,
                            T1: 0,
                            T2: 0,
                            T3: 0,
                            Q1: 0,
                            Kpi: 0,
                            Performance: 0,
                          };
                        });

                        //  //console.log.*$
                        fetchPph({scope: 'factory', years: years})
                          .then((value) => {
                            let dataPphCou: any[] = value || [];
                            // //console.log.*$
                            ///pph thang 7
                            dataPphTblCoun.forEach((rec) => {
                              let lstTemp = [];
                              let sumProduct = 0;
                              let sumSi = 0;
                              for (let i = 0; i < dataPphCou.length; i++) {
                                if (
                                  dataPphCou[i][0] === 7 &&
                                  dataPphCou[i][7] === rec['type']
                                ) {
                                  lstTemp.push(dataPphCou[i]);
                                }

                                if (lstTemp.length > 0) {
                                  for (let i = 0; i < lstTemp.length; i++) {
                                    sumSi += lstTemp[i][4];
                                    sumProduct += lstTemp[i][4] * lstTemp[i][6];
                                  }
                                }
                                rec['T1'] = sumProduct / sumSi;
                              }
                            });
                            ///pph thang 8

                            dataPphTblCoun.forEach((rec) => {
                              let lstTemp = [];
                              let sumProduct = 0;
                              let sumSi = 0;
                              for (let i = 0; i < dataPphCou.length; i++) {
                                if (
                                  dataPphCou[i][0] === 8 &&
                                  dataPphCou[i][7] === rec['type']
                                ) {
                                  lstTemp.push(dataPphCou[i]);
                                }

                                if (lstTemp.length > 0) {
                                  for (let i = 0; i < lstTemp.length; i++) {
                                    sumSi += lstTemp[i][4];
                                    sumProduct += lstTemp[i][4] * lstTemp[i][6];
                                  }
                                }
                                rec['T2'] = sumProduct / sumSi;
                              }
                            });
                            ///pph thang 9
                            dataPphTblCoun.forEach((rec) => {
                              let lstTemp = [];
                              let sumProduct = 0;
                              let sumSi = 0;
                              for (let i = 0; i < dataPphCou.length; i++) {
                                if (
                                  dataPphCou[i][0] === 9 &&
                                  dataPphCou[i][7] === rec['type']
                                ) {
                                  lstTemp.push(dataPphCou[i]);
                                }

                                if (lstTemp.length > 0) {
                                  for (let i = 0; i < lstTemp.length; i++) {
                                    sumSi += lstTemp[i][4];
                                    sumProduct += lstTemp[i][4] * lstTemp[i][6];
                                  }
                                }
                                rec['T3'] = sumProduct / sumSi;
                              }
                            });
                            //tinh Q1
                            dataPphTblCoun.forEach((rec) => {
                              let dataTemp = [];
                              for (let i = 0; i < dataPph.length; i++) {
                                if (
                                  dataPph[i][7] === rec['type'] &&
                                  ((dataPph[i][0] === 4 &&
                                    dataPph[i][2] === years[1]) ||
                                    (dataPph[i][0] === 5 &&
                                      dataPph[i][2] === years[1]) ||
                                    (dataPph[i][0] === 6 &&
                                      dataPph[i][2] === years[1]) ||
                                    (dataPph[i][0] === 7 &&
                                      dataPph[i][2] === years[1]) ||
                                    (dataPph[i][0] === 8 &&
                                      dataPph[i][2] === years[1]) ||
                                    (dataPph[i][0] === 9 &&
                                      dataPph[i][2] === years[1]))
                                ) {
                                  dataTemp.push(dataPph[i]);
                                }

                                let sumProduct = 0;
                                let sumSi = 0;

                                if (dataTemp.length > 0) {
                                  dataTemp.forEach((dt) => {
                                    sumSi += dt[4];
                                    sumProduct += dt[4] * dt[6];
                                  });
                                }
                                rec['Q1'] = sumProduct / sumSi;
                              }
                            });
                            ///kpi
                            dataPphTblCoun.forEach((rec) => {
                              let dataTemp = [];
                              for (let i = 0; i < dataKpis.length; i++) {
                                if (
                                  dataKpis[i]['factory']['country']['code'] ===
                                    rec['type'] &&
                                  (dataKpis[i]['month'] === 7 ||
                                    dataKpis[i]['month'] === 8 ||
                                    dataKpis[i]['month'] === 9 ||
                                    dataKpis[i]['month'] === -1)
                                ) {
                                  dataTemp.push(dataKpis[i]);
                                }

                                // //console.log.*$
                              }
                              if (dataTemp.length > 0) {
                                let lstKpi = dataTemp.map((dt) => {
                                  return dt['targetValue'];
                                });
                                rec['Kpi'] = Utils.avg(lstKpi);
                              }
                            });

                            dataPphTblCoun.forEach((rec) => {
                              if (!isNaN(rec['Q1']) && rec['Kpi'] !== 0) {
                                rec['Performance'] = rec['Q1'] / rec['Kpi'];
                              }
                            });

                            regions
                              .then((resp) => resp.data)
                              .then((resp) => {
                                let {errors, data} = resp;
                                if (errors.length > 0) {
                                  UIUtils.showError({
                                    errors,
                                    toast: toastRef.current,
                                  });
                                  return;
                                }
                                let listRegion = [...data.list];
                                let dataPphTblReg2 = [];

                                let dataPphTblReg = listRegion.map((reg) => {
                                  return {
                                    type: reg.code,
                                    T1: 0,
                                    T2: 0,
                                    T3: 0,
                                    Q1: 0,
                                    Kpi: 0,
                                    Performance: 0,
                                  };
                                });

                                fetchPph({scope: 'factory', years: years})
                                  .then((value) => {
                                    let dataPphReg: any[] = value || [];
                                    //pph thang 7
                                    dataPphTblReg.forEach((rec) => {
                                      let sumProduct = 0;
                                      let sumSi = 0;
                                      let lstTemp = [];
                                      for (
                                        let i = 0;
                                        i < dataPphReg.length;
                                        i++
                                      ) {
                                        if (
                                          rec['type'] === dataPphReg[i][8] &&
                                          dataPphReg[i][0] === 7
                                        ) {
                                          lstTemp.push(dataPphReg[i]);
                                        }
                                      }

                                      if (lstTemp.length > 0) {
                                        for (
                                          let i = 0;
                                          i < lstTemp.length;
                                          i++
                                        ) {
                                          sumSi += lstTemp[i][4];
                                          sumProduct +=
                                            lstTemp[i][4] * lstTemp[i][6];
                                        }
                                      }
                                      rec['T1'] = sumProduct / sumSi;
                                    });
                                    //pph thang 8
                                    dataPphTblReg.forEach((rec) => {
                                      let sumProduct = 0;
                                      let sumSi = 0;
                                      let lstTemp = [];
                                      for (
                                        let i = 0;
                                        i < dataPphReg.length;
                                        i++
                                      ) {
                                        if (
                                          rec['type'] === dataPphReg[i][8] &&
                                          dataPphReg[i][0] === 8
                                        ) {
                                          lstTemp.push(dataPphReg[i]);
                                        }
                                      }

                                      if (lstTemp.length > 0) {
                                        for (
                                          let i = 0;
                                          i < lstTemp.length;
                                          i++
                                        ) {
                                          sumSi += lstTemp[i][4];
                                          sumProduct +=
                                            lstTemp[i][4] * lstTemp[i][6];
                                        }
                                      }
                                      rec['T2'] = sumProduct / sumSi;
                                    });

                                    //pph thang 9
                                    dataPphTblReg.forEach((rec) => {
                                      let sumProduct = 0;
                                      let sumSi = 0;
                                      let lstTemp = [];
                                      for (
                                        let i = 0;
                                        i < dataPphReg.length;
                                        i++
                                      ) {
                                        if (
                                          rec['type'] === dataPphReg[i][8] &&
                                          dataPphReg[i][0] === 9
                                        ) {
                                          lstTemp.push(dataPphReg[i]);
                                        }
                                      }

                                      if (lstTemp.length > 0) {
                                        for (
                                          let i = 0;
                                          i < lstTemp.length;
                                          i++
                                        ) {
                                          sumSi += lstTemp[i][4];
                                          sumProduct +=
                                            lstTemp[i][4] * lstTemp[i][6];
                                        }
                                      }
                                      rec['T3'] = sumProduct / sumSi;
                                    });

                                    dataPphTblReg.forEach((rec) => {
                                      let dataTemp = [];
                                      for (let i = 0; i < dataPph.length; i++) {
                                        if (
                                          dataPph[i][8] === rec['type'] &&
                                          ((dataPph[i][0] === 4 &&
                                            dataPph[i][2] === years[1]) ||
                                            (dataPph[i][0] === 5 &&
                                              dataPph[i][2] === years[1]) ||
                                            (dataPph[i][0] === 6 &&
                                              dataPph[i][2] === years[1]) ||
                                            (dataPph[i][0] === 7 &&
                                              dataPph[i][2] === years[1]) ||
                                            (dataPph[i][0] === 8 &&
                                              dataPph[i][2] === years[1]) ||
                                            (dataPph[i][0] === 9 &&
                                              dataPph[i][2] === years[1]))
                                        ) {
                                          dataTemp.push(dataPph[i]);
                                        }
                                      }
                                      let sumProduct = 0;
                                      let sumSi = 0;
                                      if (dataTemp.length > 0) {
                                        dataTemp.forEach((dt) => {
                                          sumSi += dt[4];
                                          sumProduct += dt[4] * dt[6];
                                        });
                                      }
                                      rec['Q1'] = sumProduct / sumSi;
                                    });

                                    // kpis
                                    //gan region
                                    dataKpis.forEach((rec) => {
                                      for (
                                        let i = 0;
                                        i < listRegion.length;
                                        i++
                                      ) {
                                        if (
                                          rec['factory']['country'][
                                            'region'
                                          ] === listRegion[i]['id']
                                        ) {
                                          rec['regionCode'] =
                                            listRegion[i]['code'];
                                        }
                                      }
                                    });

                                    dataPphTblReg.forEach((rec) => {
                                      let dataTemp = [];
                                      for (
                                        let i = 0;
                                        i < dataKpis.length;
                                        i++
                                      ) {
                                        if (
                                          dataKpis[i]['regionCode'] ===
                                            rec['type'] &&
                                          (dataKpis[i]['month'] === 7 ||
                                            dataKpis[i]['month'] === 8 ||
                                            dataKpis[i]['month'] === 9 ||
                                            dataKpis[i]['month'] === -1)
                                        ) {
                                          dataTemp.push(dataKpis[i]);
                                        }
                                      }

                                      if (dataTemp.length > 0) {
                                        let lstKpi = dataTemp.map((dt) => {
                                          return dt['targetValue'];
                                        });

                                        rec['Kpi'] = Utils.avg(lstKpi);
                                      }
                                    });
                                    // //console.log.*$
                                    //perform
                                    dataPphTblReg.forEach((rec) => {
                                      if (rec['Kpi'] > 0) {
                                        rec['Performance'] =
                                          rec['Q1'] / rec['Kpi'];
                                      }
                                    });
                                    // target region
                                    // let dataTblTargetRegion = [];
                                    let dataPphTblReg1 = [...dataPphTblReg];

                                    for (
                                      let j = 0;
                                      j < dataPphTblReg1.length;
                                      j++
                                    ) {
                                      dataPphTblReg2.push(dataPphTblReg1[j]);
                                      //  if(dataPphTblReg1[j]['type'] === listRegion[i]['code']){
                                      let obj1 = {
                                        type:
                                          dataPphTblReg1[j]['type'] + ' Target',
                                        T1: dataPphTblReg1[j]['Kpi'],
                                        T2: dataPphTblReg1[j]['Kpi'],
                                        T3: dataPphTblReg1[j]['Kpi'],
                                        Q1: dataPphTblReg1[j]['Kpi'],
                                        Kpi: 0,
                                        Performance: 0,
                                      };
                                      dataPphTblReg2.push(obj1);
                                      // dataPphTblReg.splice(j+1,0,obj1);
                                      let obj2 = {
                                        type:
                                          dataPphTblReg1[j]['type'] +
                                          ' Perf vs Trgt',
                                        T1: isFinite(
                                          dataPphTblReg1[j]['T1'] /
                                            dataPphTblReg1[j]['Kpi'],
                                        )
                                          ? dataPphTblReg1[j]['T1'] /
                                            dataPphTblReg1[j]['Kpi']
                                          : 0,
                                        T2: isFinite(
                                          dataPphTblReg1[j]['T2'] /
                                            dataPphTblReg1[j]['Kpi'],
                                        )
                                          ? dataPphTblReg1[j]['T2'] /
                                            dataPphTblReg1[j]['Kpi']
                                          : 0,
                                        T3: isFinite(
                                          dataPphTblReg1[j]['T3'] /
                                            dataPphTblReg1[j]['Kpi'],
                                        )
                                          ? dataPphTblReg1[j]['T3'] /
                                            dataPphTblReg1[j]['Kpi']
                                          : 0,
                                        Q1: isFinite(
                                          dataPphTblReg1[j]['Q1'] /
                                            dataPphTblReg1[j]['Kpi'],
                                        )
                                          ? dataPphTblReg1[j]['Q1'] /
                                            dataPphTblReg1[j]['Kpi']
                                          : 0,
                                        Kpi: 0,
                                        Performance: 0,
                                      };
                                      dataPphTblReg2.push(obj2);

                                      // }
                                    }

                                    // }
                                    let dataTbl = [
                                      ...dataPphTb,
                                      ...dataPphTblCoun,
                                      ...dataPphTblReg2,
                                    ];

                                    setPphReportTblDataQ3(dataTbl);

                                    //gioi han 4
                                  })
                                  .catch((errors) => {
                                    UIUtils.showError({
                                      errors,
                                      toast: toastRef.current,
                                    });
                                    return;
                                  });

                                //gioi han 3
                              })
                              .catch((errors) => {
                                UIUtils.showError({
                                  errors,
                                  toast: toastRef.current,
                                });
                                return;
                              });

                            //gioi han 2
                          })
                          .catch((errors) => {
                            UIUtils.showError({
                              errors,
                              toast: toastRef.current,
                            });
                            return;
                          });

                        //gioi han
                      })
                      .catch((errors) => {
                        UIUtils.showError({errors, toast: toastRef.current});
                        return;
                      });

                    //// gioi han
                  })
                  .catch((errors) => {
                    UIUtils.showError({errors, toast: toastRef.current});
                    return;
                  });
                ////////////////gioihan
              })
              .catch((errors) => {
                UIUtils.showError({errors, toast: toastRef.current});
                return;
              });
          }
          if (quarter === 4) {
            let dataPphTb = [];
            Promise.all([factories])
              .then((value) => {
                let lstFactory = value[0].data.data.list;
                lstFactory.forEach((fac) => {
                  let obj = {
                    type: fac.code,
                    T1: 0,
                    T2: 0,
                    T3: 0,
                    Q1: 0,
                    Kpi: 0,
                    Performance: 0,
                  };
                  dataPphTb.push(obj);
                });
                /// pph thang 1
                dataPphTb.forEach((rec) => {
                  for (let i = 0; i < dataPph.length; i++) {
                    if (
                      dataPph[i][1] === rec['type'] &&
                      dataPph[i][0] === 10 &&
                      dataPph[i][2] === years[1]
                    ) {
                      rec['T1'] = dataPph[i][6];
                    }
                  }
                });
                //pph thang 2
                dataPphTb.forEach((rec) => {
                  for (let i = 0; i < dataPph.length; i++) {
                    if (
                      dataPph[i][1] === rec['type'] &&
                      dataPph[i][0] === 11 &&
                      dataPph[i][2] === years[1]
                    ) {
                      rec['T2'] = dataPph[i][6];
                      break;
                    }
                  }
                });
                //pph thang 3
                dataPphTb.forEach((rec) => {
                  for (let i = 0; i < dataPph.length; i++) {
                    //  //console.log.*$
                    if (
                      dataPph[i][1] === rec['type'] &&
                      dataPph[i][0] === 12 &&
                      dataPph[i][2] === years[1]
                    ) {
                      rec['T3'] = dataPph[i][6];
                      break;
                    }
                  }
                });
                /////////Q1
                dataPphTb.forEach((rec) => {
                  let dataTempQ1 = [];
                  for (let i = 0; i < dataPph.length; i++) {
                    if (
                      dataPph[i][1] === rec['type'] &&
                      ((dataPph[i][0] === 7 && dataPph[i][2] === years[1]) ||
                        (dataPph[i][0] === 8 && dataPph[i][2] === years[1]) ||
                        (dataPph[i][0] === 9 && dataPph[i][2] === years[1]) ||
                        (dataPph[i][0] === 10 && dataPph[i][2] === years[1]) ||
                        (dataPph[i][0] === 11 && dataPph[i][2] === years[1]) ||
                        (dataPph[i][0] === 12 && dataPph[i][2] === years[1]))
                    ) {
                      dataTempQ1.push(dataPph[i]);
                    }
                  }

                  let sumProductQ1 = 0;
                  let sumSiQ1 = 0;

                  if (dataTempQ1.length > 0) {
                    dataTempQ1.forEach((dt) => {
                      sumSiQ1 += dt[4];
                      sumProductQ1 += dt[4] * dt[6];
                    });
                  }
                  rec['Q1'] = sumProductQ1 / sumSiQ1;
                });

                fetchKpis({years: years})
                  .then((value) => {
                    let dataKpis: any[] = value || [];
                    dataPphTb.forEach((rec) => {
                      let temp = [];
                      for (let i = 0; i < dataKpis.length; i++) {
                        if (
                          dataKpis[i]['factory']['code'] === rec['type'] &&
                          (dataKpis[i]['month'] === 10 ||
                            dataKpis[i]['month'] === 11 ||
                            dataKpis[i]['month'] === 12 ||
                            dataKpis[i]['month'] === -1)
                        ) {
                          temp.push(dataKpis[i]);
                        }
                      }
                      if (temp.length > 0) {
                        let lstKpi = temp.map((dt) => {
                          return dt['targetValue'];
                        });

                        rec['Kpi'] = Utils.avg(lstKpi);
                      }
                    });

                    dataPphTb.forEach((rec) => {
                      if (!isNaN(rec['Q1']) && rec['Kpi'] !== 0) {
                        rec['Performance'] = rec['Q1'] / rec['Kpi'];
                      }
                    });

                    countries
                      .then((resp) => resp.data)
                      .then((resp) => {
                        let {errors, data} = resp;
                        if (errors.length > 0) {
                          UIUtils.showError({errors, toast: toastRef.current});
                          return;
                        }
                        let listFactory = [...data.list];
                        let dataPphTblCoun = listFactory.map((fac) => {
                          return {
                            type: fac.code,
                            T1: 0,
                            T2: 0,
                            T3: 0,
                            Q1: 0,
                            Kpi: 0,
                            Performance: 0,
                          };
                        });

                        //  //console.log.*$
                        fetchPph({scope: 'factory', years: years})
                          .then((value) => {
                            let dataPphCou: any[] = value || [];
                            //  //console.log.*$
                            ///pph thang 10
                            dataPphTblCoun.forEach((rec) => {
                              let lstTemp = [];
                              let sumProduct = 0;
                              let sumSi = 0;
                              for (let i = 0; i < dataPphCou.length; i++) {
                                if (
                                  dataPphCou[i][0] === 10 &&
                                  dataPphCou[i][7] === rec['type']
                                ) {
                                  lstTemp.push(dataPphCou[i]);
                                }

                                if (lstTemp.length > 0) {
                                  for (let i = 0; i < lstTemp.length; i++) {
                                    sumSi += lstTemp[i][4];
                                    sumProduct += lstTemp[i][4] * lstTemp[i][6];
                                  }
                                }
                                rec['T1'] = sumProduct / sumSi;
                              }
                            });

                            //pph thamg 11
                            dataPphTblCoun.forEach((rec) => {
                              let lstTemp = [];
                              let sumProduct = 0;
                              let sumSi = 0;
                              for (let i = 0; i < dataPphCou.length; i++) {
                                if (
                                  dataPphCou[i][0] === 11 &&
                                  dataPphCou[i][7] === rec['type']
                                ) {
                                  lstTemp.push(dataPphCou[i]);
                                }

                                if (lstTemp.length > 0) {
                                  for (let i = 0; i < lstTemp.length; i++) {
                                    sumSi += lstTemp[i][4];
                                    sumProduct += lstTemp[i][4] * lstTemp[i][6];
                                  }
                                }
                                rec['T2'] = sumProduct / sumSi;
                              }
                            });
                            //pph thang 12
                            dataPphTblCoun.forEach((rec) => {
                              let lstTemp = [];
                              let sumProduct = 0;
                              let sumSi = 0;
                              for (let i = 0; i < dataPphCou.length; i++) {
                                if (
                                  dataPphCou[i][0] === 12 &&
                                  dataPphCou[i][7] === rec['type']
                                ) {
                                  lstTemp.push(dataPphCou[i]);
                                }

                                if (lstTemp.length > 0) {
                                  for (let i = 0; i < lstTemp.length; i++) {
                                    sumSi += lstTemp[i][4];
                                    sumProduct += lstTemp[i][4] * lstTemp[i][6];
                                  }
                                }
                                rec['T3'] = sumProduct / sumSi;
                              }
                            });

                            //tinh Q1
                            dataPphTblCoun.forEach((rec) => {
                              let dataTemp = [];
                              for (let i = 0; i < dataPph.length; i++) {
                                if (
                                  dataPph[i][7] === rec['type'] &&
                                  ((dataPph[i][0] === 7 &&
                                    dataPph[i][2] === years[1]) ||
                                    (dataPph[i][0] === 8 &&
                                      dataPph[i][2] === years[1]) ||
                                    (dataPph[i][0] === 9 &&
                                      dataPph[i][2] === years[1]) ||
                                    (dataPph[i][0] === 10 &&
                                      dataPph[i][2] === years[1]) ||
                                    (dataPph[i][0] === 11 &&
                                      dataPph[i][2] === years[1]) ||
                                    (dataPph[i][0] === 12 &&
                                      dataPph[i][2] === years[1]))
                                ) {
                                  dataTemp.push(dataPph[i]);
                                }

                                let sumProduct = 0;
                                let sumSi = 0;

                                if (dataTemp.length > 0) {
                                  dataTemp.forEach((dt) => {
                                    sumSi += dt[4];
                                    sumProduct += dt[4] * dt[6];
                                  });
                                }
                                rec['Q1'] = sumProduct / sumSi;
                              }
                            });
                            ///kpi
                            dataPphTblCoun.forEach((rec) => {
                              let dataTemp = [];
                              for (let i = 0; i < dataKpis.length; i++) {
                                if (
                                  dataKpis[i]['factory']['country']['code'] ===
                                    rec['type'] &&
                                  (dataKpis[i]['month'] === 10 ||
                                    dataKpis[i]['month'] === 11 ||
                                    dataKpis[i]['month'] === 12 ||
                                    dataKpis[i]['month'] === -1)
                                ) {
                                  dataTemp.push(dataKpis[i]);
                                }

                                // //console.log.*$
                              }
                              if (dataTemp.length > 0) {
                                let lstKpi = dataTemp.map((dt) => {
                                  return dt['targetValue'];
                                });
                                rec['Kpi'] = Utils.avg(lstKpi);
                              }
                            });

                            dataPphTblCoun.forEach((rec) => {
                              if (!isNaN(rec['Q1']) && rec['Kpi'] !== 0) {
                                rec['Performance'] = rec['Q1'] / rec['Kpi'];
                              }
                            });

                            regions
                              .then((resp) => resp.data)
                              .then((resp) => {
                                let {errors, data} = resp;
                                if (errors.length > 0) {
                                  UIUtils.showError({
                                    errors,
                                    toast: toastRef.current,
                                  });
                                  return;
                                }
                                let listRegion = [...data.list];
                                let dataPphTblReg2 = [];

                                let dataPphTblReg = listRegion.map((reg) => {
                                  return {
                                    type: reg.code,
                                    T1: 0,
                                    T2: 0,
                                    T3: 0,
                                    Q1: 0,
                                    Kpi: 0,
                                    Performance: 0,
                                  };
                                });

                                fetchPph({scope: 'factory', years: years})
                                  .then((value) => {
                                    let dataPphReg: any[] = value || [];
                                    ///
                                    dataPphTblReg.forEach((rec) => {
                                      let sumProduct = 0;
                                      let sumSi = 0;
                                      let lstTemp = [];
                                      for (
                                        let i = 0;
                                        i < dataPphReg.length;
                                        i++
                                      ) {
                                        if (
                                          rec['type'] === dataPphReg[i][8] &&
                                          dataPphReg[i][0] === 10
                                        ) {
                                          lstTemp.push(dataPphReg[i]);
                                        }
                                      }

                                      if (lstTemp.length > 0) {
                                        for (
                                          let i = 0;
                                          i < lstTemp.length;
                                          i++
                                        ) {
                                          sumSi += lstTemp[i][4];
                                          sumProduct +=
                                            lstTemp[i][4] * lstTemp[i][6];
                                        }
                                      }
                                      rec['T1'] = sumProduct / sumSi;
                                    });
                                    ////
                                    dataPphTblReg.forEach((rec) => {
                                      let sumProduct = 0;
                                      let sumSi = 0;
                                      let lstTemp = [];
                                      for (
                                        let i = 0;
                                        i < dataPphReg.length;
                                        i++
                                      ) {
                                        if (
                                          rec['type'] === dataPphReg[i][8] &&
                                          dataPphReg[i][0] === 11
                                        ) {
                                          lstTemp.push(dataPphReg[i]);
                                        }
                                      }

                                      if (lstTemp.length > 0) {
                                        for (
                                          let i = 0;
                                          i < lstTemp.length;
                                          i++
                                        ) {
                                          sumSi += lstTemp[i][4];
                                          sumProduct +=
                                            lstTemp[i][4] * lstTemp[i][6];
                                        }
                                      }
                                      rec['T2'] = sumProduct / sumSi;
                                    });

                                    ///
                                    dataPphTblReg.forEach((rec) => {
                                      let sumProduct = 0;
                                      let sumSi = 0;
                                      let lstTemp = [];
                                      for (
                                        let i = 0;
                                        i < dataPphReg.length;
                                        i++
                                      ) {
                                        if (
                                          rec['type'] === dataPphReg[i][8] &&
                                          dataPphReg[i][0] === 12
                                        ) {
                                          lstTemp.push(dataPphReg[i]);
                                        }
                                      }

                                      if (lstTemp.length > 0) {
                                        for (
                                          let i = 0;
                                          i < lstTemp.length;
                                          i++
                                        ) {
                                          sumSi += lstTemp[i][4];
                                          sumProduct +=
                                            lstTemp[i][4] * lstTemp[i][6];
                                        }
                                      }
                                      rec['T3'] = sumProduct / sumSi;
                                    });
                                    dataPphTblReg.forEach((rec) => {
                                      let dataTemp = [];
                                      for (let i = 0; i < dataPph.length; i++) {
                                        if (
                                          dataPph[i][8] === rec['type'] &&
                                          ((dataPph[i][0] === 7 &&
                                            dataPph[i][2] === years[1]) ||
                                            (dataPph[i][0] === 8 &&
                                              dataPph[i][2] === years[1]) ||
                                            (dataPph[i][0] === 9 &&
                                              dataPph[i][2] === years[1]) ||
                                            (dataPph[i][0] === 10 &&
                                              dataPph[i][2] === years[1]) ||
                                            (dataPph[i][0] === 11 &&
                                              dataPph[i][2] === years[1]) ||
                                            (dataPph[i][0] === 12 &&
                                              dataPph[i][2] === years[1]))
                                        ) {
                                          dataTemp.push(dataPph[i]);
                                        }
                                      }
                                      let sumProduct = 0;
                                      let sumSi = 0;
                                      if (dataTemp.length > 0) {
                                        dataTemp.forEach((dt) => {
                                          sumSi += dt[4];
                                          sumProduct += dt[4] * dt[6];
                                        });
                                      }
                                      rec['Q1'] = sumProduct / sumSi;
                                    });

                                    // kpis
                                    //gan region
                                    dataKpis.forEach((rec) => {
                                      for (
                                        let i = 0;
                                        i < listRegion.length;
                                        i++
                                      ) {
                                        if (
                                          rec['factory']['country'][
                                            'region'
                                          ] === listRegion[i]['id']
                                        ) {
                                          rec['regionCode'] =
                                            listRegion[i]['code'];
                                        }
                                      }
                                    });

                                    dataPphTblReg.forEach((rec) => {
                                      let dataTemp = [];
                                      for (
                                        let i = 0;
                                        i < dataKpis.length;
                                        i++
                                      ) {
                                        if (
                                          dataKpis[i]['regionCode'] ===
                                            rec['type'] &&
                                          (dataKpis[i]['month'] === 10 ||
                                            dataKpis[i]['month'] === 11 ||
                                            dataKpis[i]['month'] === 12 ||
                                            dataKpis[i]['month'] === -1)
                                        ) {
                                          dataTemp.push(dataKpis[i]);
                                        }
                                      }

                                      if (dataTemp.length > 0) {
                                        let lstKpi = dataTemp.map((dt) => {
                                          return dt['targetValue'];
                                        });

                                        rec['Kpi'] = Utils.avg(lstKpi);
                                      }
                                    });
                                    ////console.log.*$
                                    //perform
                                    dataPphTblReg.forEach((rec) => {
                                      if (rec['Kpi'] > 0) {
                                        rec['Performance'] =
                                          rec['Q1'] / rec['Kpi'];
                                      }
                                    });
                                    // target region
                                    // let dataTblTargetRegion = [];
                                    let dataPphTblReg1 = [...dataPphTblReg];

                                    for (
                                      let j = 0;
                                      j < dataPphTblReg1.length;
                                      j++
                                    ) {
                                      dataPphTblReg2.push(dataPphTblReg1[j]);
                                      //  if(dataPphTblReg1[j]['type'] === listRegion[i]['code']){
                                      let obj1 = {
                                        type:
                                          dataPphTblReg1[j]['type'] + ' Target',
                                        T1: dataPphTblReg1[j]['Kpi'],
                                        T2: dataPphTblReg1[j]['Kpi'],
                                        T3: dataPphTblReg1[j]['Kpi'],
                                        Q1: dataPphTblReg1[j]['Kpi'],
                                        Kpi: 0,
                                        Performance: 0,
                                      };
                                      dataPphTblReg2.push(obj1);
                                      // dataPphTblReg.splice(j+1,0,obj1);
                                      let obj2 = {
                                        type:
                                          dataPphTblReg1[j]['type'] +
                                          ' Perf vs Trgt',
                                        T1: isFinite(
                                          dataPphTblReg1[j]['T1'] /
                                            dataPphTblReg1[j]['Kpi'],
                                        )
                                          ? dataPphTblReg1[j]['T1'] /
                                            dataPphTblReg1[j]['Kpi']
                                          : 0,
                                        T2: isFinite(
                                          dataPphTblReg1[j]['T2'] /
                                            dataPphTblReg1[j]['Kpi'],
                                        )
                                          ? dataPphTblReg1[j]['T2'] /
                                            dataPphTblReg1[j]['Kpi']
                                          : 0,
                                        T3: isFinite(
                                          dataPphTblReg1[j]['T3'] /
                                            dataPphTblReg1[j]['Kpi'],
                                        )
                                          ? dataPphTblReg1[j]['T3'] /
                                            dataPphTblReg1[j]['Kpi']
                                          : 0,
                                        Q1: isFinite(
                                          dataPphTblReg1[j]['Q1'] /
                                            dataPphTblReg1[j]['Kpi'],
                                        )
                                          ? dataPphTblReg1[j]['Q1'] /
                                            dataPphTblReg1[j]['Kpi']
                                          : 0,
                                        Kpi: 0,
                                        Performance: 0,
                                      };
                                      dataPphTblReg2.push(obj2);

                                      // }
                                    }

                                    // }
                                    let dataTbl = [
                                      ...dataPphTb,
                                      ...dataPphTblCoun,
                                      ...dataPphTblReg2,
                                    ];

                                    setPphReportTblDataQ4(dataTbl);

                                    //gioi han 4
                                  })
                                  .catch((errors) => {
                                    UIUtils.showError({
                                      errors,
                                      toast: toastRef.current,
                                    });
                                    return;
                                  });

                                //gioi han 3
                              })
                              .catch((errors) => {
                                UIUtils.showError({
                                  errors,
                                  toast: toastRef.current,
                                });
                                return;
                              });

                            //gioi han 2
                          })
                          .catch((errors) => {
                            UIUtils.showError({
                              errors,
                              toast: toastRef.current,
                            });
                            return;
                          });

                        //gioi han
                      })
                      .catch((errors) => {
                        UIUtils.showError({errors, toast: toastRef.current});
                        return;
                      });

                    //// gioi han
                  })
                  .catch((errors) => {
                    UIUtils.showError({errors, toast: toastRef.current});
                    return;
                  });
                ////////////////gioihan
              })
              .catch((errors) => {
                UIUtils.showError({errors, toast: toastRef.current});
                return;
              });
          }
          if (quarter === -1) {
            let dataPphTb = [];
            Promise.all([factories])
              .then((value) => {
                let lstFactory = value[0].data.data.list;
                lstFactory.forEach((fac) => {
                  let obj = {
                    type: fac.code,
                    ytd: 0,
                    target: 0,
                    performance: 0,
                  };
                  dataPphTb.push(obj);
                });
                dataPphTb.forEach((rec) => {
                  let dataTemp = [];
                  for (let i = 0; i < dataPph.length; i++) {
                    if (
                      dataPph[i][1] === rec['type'] &&
                      dataPph[i][2] === years[1]
                    ) {
                      dataTemp.push(dataPph[i]);
                    }
                  }

                  if (dataTemp.length) {
                    let sumSi = 0;
                    let sumProduct = 0;
                    for (let i = 0; i < dataTemp.length; i++) {
                      sumSi += dataTemp[i][4];
                      sumProduct += dataTemp[i][4] * dataTemp[i][6];
                    }

                    rec['ytd'] = sumProduct / sumSi;
                  }
                });

                fetchKpisPphYtd({years: years})
                  .then((value) => {
                    let dataKpis: any[] = value || [];
                    ////console.log.*$
                    dataPphTb.forEach((rec) => {
                      for (let i = 0; i < dataKpis.length; i++) {
                        if (rec['type'] === dataKpis[i]['factory']['code']) {
                          rec['target'] = dataKpis[i]['targetValue'];
                        }
                      }
                    });

                    dataPphTb.forEach((rec) => {
                      if (rec['target'] > 0) {
                        rec['performance'] = rec['ytd'] / rec['target'];
                      }
                    });

                    ////console.log.*$

                    regions
                      .then((resp) => resp.data)
                      .then((resp) => {
                        let {errors, data} = resp;
                        if (errors.length > 0) {
                          UIUtils.showError({errors, toast: toastRef.current});
                          return;
                        }
                        let lstRegion = [...data.list];
                        let dataTblReg = lstRegion.map((reg) => {
                          return {
                            type: reg.code,
                            ytd: 0,
                            target: 0,
                            performance: 0,
                          };
                        });

                        // //console.log.*$
                        dataTblReg.forEach((rec) => {
                          let dataTemp = [];
                          for (let i = 0; i < dataPph.length; i++) {
                            if (
                              rec['type'] === dataPph[i][8] &&
                              dataPph[i][2] === years[1]
                            ) {
                              dataTemp.push(dataPph[i]);
                            }
                          }
                          if (dataTemp.length) {
                            let sumSi = 0;
                            let sumProduct = 0;
                            for (let i = 0; i < dataTemp.length; i++) {
                              sumSi += dataTemp[i][4];
                              sumProduct += dataTemp[i][4] * dataTemp[i][6];
                            }

                            rec['ytd'] = sumProduct / sumSi;
                          }
                        });
                        //  //console.log.*$
                        //gan kpi
                        ////console.log.*$
                        dataKpis.forEach((rec) => {
                          for (let i = 0; i < lstRegion.length; i++) {
                            if (
                              rec['factory']['country']['id'] ===
                              lstRegion[i]['id']
                            ) {
                              rec['regionCode'] = lstRegion[i]['code'];
                            }
                          }
                        });

                        dataTblReg.forEach((rec) => {
                          let dataTemp = [];
                          for (let i = 0; i < dataKpis.length; i++) {
                            if (rec['type'] === dataKpis[i]['regionCode']) {
                              dataTemp.push(dataKpis[i]);
                            }
                          }
                          let lstTarget = dataTemp.map((dt) => {
                            return dt['targetValue'];
                          });

                          rec['target'] = Utils.avg(lstTarget);
                        });

                        dataTblReg.forEach((rec) => {
                          if (rec['target'] > 0) {
                            rec['performance'] = rec['ytd'] / rec['target'];
                          }
                        });

                        countries
                          .then((resp) => resp.data)
                          .then((resp) => {
                            let {errors, data} = resp;
                            if (errors.length > 0) {
                              UIUtils.showError({
                                errors,
                                toast: toastRef.current,
                              });
                              return;
                            }

                            let lstCountry = [...data.list];

                            let dataTblCoun = lstCountry.map((cou) => {
                              return {
                                type: cou.code,
                                ytd: 0,
                                target: 0,
                                performance: 0,
                              };
                            });
                            // //console.log.*$
                            dataTblCoun.forEach((rec) => {
                              let dataTemp = [];
                              for (let i = 0; i < dataPph.length; i++) {
                                if (
                                  rec['type'] === dataPph[i][7] &&
                                  dataPph[i][2] === years[1]
                                ) {
                                  dataTemp.push(dataPph[i]);
                                }
                              }

                              if (dataTemp.length > 0) {
                                let sumSi = 0;
                                let sumProduct = 0;
                                for (let i = 0; i < dataTemp.length; i++) {
                                  sumSi += dataTemp[i][4];
                                  sumProduct += dataTemp[i][4] * dataTemp[i][6];
                                }

                                rec['ytd'] = sumProduct / sumSi;
                              }
                            });

                            dataTblCoun.forEach((rec) => {
                              let dataTemp = [];
                              for (let i = 0; i < dataKpis.length; i++) {
                                if (
                                  rec['type'] ===
                                  dataKpis[i]['factory']['country']['code']
                                ) {
                                  dataTemp.push(dataKpis[i]);
                                }
                              }
                              if (dataTemp.length > 0) {
                                let lstTarget = dataTemp.map((dt) => {
                                  return dt['targetValue'];
                                });

                                rec['target'] = Utils.avg(lstTarget);
                              }
                            });

                            dataTblCoun.forEach((rec) => {
                              if (rec['target'] > 0) {
                                rec['performance'] = rec['ytd'] / rec['target'];
                              }
                            });

                            let dataTbl = [
                              ...dataPphTb,
                              ...dataTblCoun,
                              ...dataTblReg,
                            ];
                            setPphReportTblDataYtd(dataTbl);

                            //gioihan 3
                          })
                          .catch((errors) => {});

                        //gioihan 2
                      })
                      .catch((errors) => {});

                    //gioihan 1
                  })
                  .catch((errors) => {});

                //    //console.log.*$

                ///gioihan
              })
              .catch((errors) => {});
          }
        })
        .catch((errors) => {
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        });
    },
    [fetchKpis, fetchPph, fetchKpisPphYtd],
  );

  const _renderPphPerformanceQ1: TblColRender = (row: any, col: any) => {
    let {q1Performance} = row;
    return q1Performance
      ? Number(Number(q1Performance) * 100).toFixed(0) + '%'
      : null;
  };

  const _renderPphPerformanceQ2: TblColRender = (row: any, col: any) => {
    let {q2Performance} = row;
    return q2Performance
      ? Number(Number(q2Performance) * 100).toFixed(0) + '%'
      : null;
  };

  const _renderPphPerformanceQ3: TblColRender = (row: any, col: any) => {
    let {q3Performance} = row;
    return q3Performance
      ? Number(Number(q3Performance) * 100).toFixed(0) + '%'
      : null;
  };

  const _renderPphPerformanceQ4: TblColRender = (row: any, col: any) => {
    let {q4Performance} = row;
    return q4Performance
      ? Number(Number(q4Performance) * 100).toFixed(0) + '%'
      : null;
  };

  const _renderPphYtd: TblColRender = (row: any, col: any) => {
    let {ytd} = row;
    return ytd ? Utils.round(ytd) : 0;
  };

  const _renderTargetYtd: TblColRender = (row: any, col: any) => {
    let {target} = row;
    return target ? Utils.round(target) : 0;
  };

  const _renderPerformanceYtd: TblColRender = (row: any, col: any) => {
    let {performance} = row;
    return performance
      ? Number(Number(performance) * 100).toFixed(0) + '%'
      : null;
  };

  useEffect(() => {
    let {years, month} = filter;
    years = years.length > 0 ? years : [2000];
    years = years.map((it) => Number(it)).filter((it) => !isNaN(it));
    let cy = years[years.length - 1];
    years = [cy - 1, cy];

    _fetchPphWeigted({year: cy, month})
      .then((resp) => {
        let data: any[] = resp || [];
        setPphReportTblDataQ1([...data]);
        setPphReportTblDataQ2([...data]);
        setPphReportTblDataQ3([...data]);
        setPphReportTblDataQ4([...data]);
      })
      .catch((errors) => {
        //console.log.*$
      });

    //  _fetchPphData({years:years,quarter: 1});
    /// _fetchPphData({years:years,quarter: 2});
    // _fetchPphData({years:years,quarter: 3});
    // _fetchPphData({years:years,quarter: 4});
    _fetchPphData({years: years, quarter: -1});
  }, [filter, _fetchPphWeigted, _fetchPphData]);

  const onSort = (e) => {
    let {sortField, sortOrder} = e;
    if (sortOrder === 1) {
      setPphReportTblDataQ1(
        [...pphReportTblDataQ1].sort((a, b) => a[sortField] - b[sortField]),
      );
      setPphReportTblDataQ2(
        [...pphReportTblDataQ2].sort((a, b) => a[sortField] - b[sortField]),
      );
      setPphReportTblDataQ3(
        [...pphReportTblDataQ3].sort((a, b) => a[sortField] - b[sortField]),
      );
      setPphReportTblDataQ4(
        [...pphReportTblDataQ4].sort((a, b) => a[sortField] - b[sortField]),
      );
      setPphReportTblDataYtd(
        [...pphReportTblDataYtd].sort((a, b) => a[sortField] - b[sortField]),
      );
    } else {
      setPphReportTblDataQ1(
        [...pphReportTblDataQ1].sort((a, b) => b[sortField] - a[sortField]),
      );
      setPphReportTblDataQ2(
        [...pphReportTblDataQ2].sort((a, b) => b[sortField] - a[sortField]),
      );
      setPphReportTblDataQ3(
        [...pphReportTblDataQ3].sort((a, b) => b[sortField] - a[sortField]),
      );
      setPphReportTblDataQ4(
        [...pphReportTblDataQ4].sort((a, b) => b[sortField] - a[sortField]),
      );
      setPphReportTblDataYtd(
        [...pphReportTblDataYtd].sort((a, b) => b[sortField] - a[sortField]),
      );
    }
    setSortField(sortField);
    setSortOrder(sortOrder);
  };

  return (
    <>
      <Toast ref={toastRef} />
      <h2>{years[years.length - 1]} - Quarter 1</h2>
      <DataTable
        value={pphReportTblDataQ1}
        dataKey="id"
        lazy={true}
        paginator={true}
        //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        //  rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
        //  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        //  first={etrRecsTblStart}
        showGridlines
        // rows={etrRecsTblLimit}
        // totalRecords={ertRecsTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        //onPage={_onInvsPaging}
        responsiveLayout="scroll"
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={onSort}>
        <Column field="type" header="Factory" />

        <Column
          field="pphJan"
          header={years[years.length - 1] + ' - Jan'}
          //  body = {_renderPphT1}
          sortable
        />

        <Column
          field="pphFeb"
          header={years[years.length - 1] + ' - Feb'}
          // body = {_renderPphT2}
          sortable
        />

        <Column
          field="pphMar"
          header={years[years.length - 1] + ' - Mar'}
          // body = {_renderPphT3}
          sortable
        />

        <Column
          field="pphQ1"
          header="6 mths roll weighted avg"
          //  body = {_renderPphQ}
          sortable
        />

        <Column
          field="pphQ1Target"
          header="Q1 Target"
          //body = {_renderPphQTarget}
          sortable
        />

        <Column
          field="q1Performance"
          header="Q1 Performance"
          body={_renderPphPerformanceQ1}
          sortable
        />
      </DataTable>

      <h2>{years[years.length - 1]} - Quarter 2</h2>
      <DataTable
        value={pphReportTblDataQ2}
        dataKey="id"
        lazy={true}
        paginator={true}
        //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        //  rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
        //  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        //  first={etrRecsTblStart}
        showGridlines
        // rows={etrRecsTblLimit}
        // totalRecords={ertRecsTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        //onPage={_onInvsPaging}
        responsiveLayout="scroll"
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={onSort}>
        <Column field="type" header="Factory" />

        <Column
          field="pphApr"
          header={years[years.length - 1] + ' - Apr'}
          //body = {_renderPphT1}
          sortable
        />

        <Column
          field="pphMay"
          header={years[years.length - 1] + ' - May'}
          // body = {_renderPphT2}
          sortable
        />

        <Column
          field="pphJun"
          header={years[years.length - 1] + ' - Jun'}
          // body = {_renderPphT3}
          sortable
        />

        <Column
          field="pphQ2"
          header="6 mths roll weighted avg"
          //  body = {_renderPphQ}
          sortable
        />

        <Column
          field="pphQ2Target"
          header="Q2 Target"
          // body = {_renderPphQTarget}
          sortable
        />

        <Column
          field="q2Performance"
          header="Q2 Performance"
          body={_renderPphPerformanceQ2}
          sortable
        />
      </DataTable>

      <h2>{years[years.length - 1]} - Quarter 3</h2>

      <DataTable
        value={pphReportTblDataQ3}
        dataKey="id"
        lazy={true}
        paginator={true}
        //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        //  rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
        //  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        //  first={etrRecsTblStart}
        showGridlines
        // rows={etrRecsTblLimit}
        // totalRecords={ertRecsTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        //onPage={_onInvsPaging}
        responsiveLayout="scroll"
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={onSort}>
        <Column field="type" header="Factory" />

        <Column
          field="pphJul"
          header={years[years.length - 1] + ' - Jul'}
          // body = {_renderPphT1}
          sortable
        />

        <Column
          field="pphAug"
          header={years[years.length - 1] + ' - Aug'}
          //  body = {_renderPphT2}
          sortable
        />

        <Column
          field="pphSep"
          header={years[years.length - 1] + ' - Sep'}
          //  body = {_renderPphT3}
          sortable
        />

        <Column
          field="pphQ3"
          header="6 mths roll weighted avg"
          //  body = {_renderPphQ}
          sortable
        />

        <Column
          field="pphQ3Target"
          header="Q3 Target"
          // body = {_renderPphQTarget}
          sortable
        />

        <Column
          field="q3Performance"
          header="Q3 Performance"
          body={_renderPphPerformanceQ3}
          sortable
        />
      </DataTable>

      <h2>{years[years.length - 1]} - Quarter 4</h2>

      <DataTable
        value={pphReportTblDataQ4}
        dataKey="id"
        lazy={true}
        paginator={true}
        //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        //  rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
        //  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        //  first={etrRecsTblStart}
        showGridlines
        // rows={etrRecsTblLimit}
        // totalRecords={ertRecsTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        //onPage={_onInvsPaging}
        responsiveLayout="scroll"
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={onSort}>
        <Column field="type" header="Factory" />

        <Column
          field="pphOct"
          header={years[years.length - 1] + ' - Oct'}
          //body = {_renderPphT1}
          sortable
        />

        <Column
          field="pphNov"
          header={years[years.length - 1] + ' - Nov'}
          //body = {_renderPphT2}
          sortable
        />

        <Column
          field="pphDec"
          header={years[years.length - 1] + ' - Dec'}
          //body = {_renderPphT3}
          sortable
        />

        <Column
          field="pphQ4"
          header="6 mths roll weighted avg"
          // body = {_renderPphQ}
          sortable
        />

        <Column
          field="pphQ4Target"
          header="Q4 Target"
          //  body = {_renderPphQTarget}
          sortable
        />

        <Column
          field="q4Performance"
          header="Q4 Performance"
          body={_renderPphPerformanceQ4}
          sortable
        />
      </DataTable>

      <h2>{years[years.length - 1]} - YTD</h2>
      <DataTable
        value={pphReportTblDataYtd}
        dataKey="id"
        lazy={true}
        paginator={true}
        //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        //  rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
        //  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        //  first={etrRecsTblStart}
        showGridlines
        // rows={etrRecsTblLimit}
        // totalRecords={ertRecsTblNbRows}
        resizableColumns={true}
        columnResizeMode="expand"
        selectionMode="single"
        //onPage={_onInvsPaging}
        responsiveLayout="scroll"
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={onSort}>
        <Column field="type" header="Factory" />

        <Column
          field="ytd"
          header={years[years.length - 1] + ' Actual YTD PPH'}
          body={_renderPphYtd}
          sortable
        />

        <Column
          field="target"
          header={years[years.length - 1] + ' End Year Roadmap Target'}
          body={_renderTargetYtd}
          sortable
        />

        <Column
          field="performance"
          header={
            years[years.length - 1] + ' Actual YTD  vs Year end Roadmap target'
          }
          body={_renderPerformanceYtd}
          sortable
        />
      </DataTable>
    </>
  );
}
