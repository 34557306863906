// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {AutoComplete} from 'primereact/autocomplete';

import type {ApiProps} from '../../service/Api';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  readOnly?: boolean,
  machModel: any,
  onAction: (evt: any) => void,
};

const defMachModel = {
  __cls__: 'com.nb.lmrs.model.entity.MachModel',
  flag: 0,
  code: '',
  name: '',
  material: '',
  style: '',
  pureie: '',
  description: '',
  factory: null,
};

export function MachModelForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {readOnly, machModel} = props;
  //console.log.*$
  const [editMachModel, setEditMachModel] = useState<any>();
  const [suggFactories, setSuggFactories] = useState([]);
  const [defFactory, setDefFactory] = useState();

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      setDefFactory(factories[0]);
    }
  }, [auth]);

  useEffect(() => {
    // //console.log.*$
    let curMachModel = machModel || {};
    curMachModel = {
      ...defMachModel,
      ...curMachModel,
    };

    let isNew = !curMachModel.id;
    if (isNew && (!curMachModel.factory || !curMachModel.factory.id)) {
      curMachModel['factory'] = defFactory;
    }

    let {factory} = curMachModel;
    if (factory) {
      factory.label = `${factory.code} :: ${factory.name}`;
    }

    // //console.log.*$
    setEditMachModel(curMachModel);
  }, [machModel, defFactory]);

  const _onSuggFactories: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getFactories({search, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        // //console.log.*$
        data.list.forEach((it) => (it.label = `${it.code} :: ${it.name}`));
        setSuggFactories([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onSave = (evt: any) => {
    // setShowCfmDlg(false);
    let fac = _validateMachModel();
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update production model?'
        : 'Are you sure you want to create production model?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateMachModel({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'machLineSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _validateMachModel: () => any = () => {
    let obj = {...editMachModel};

    if (Utils.isEmpty(obj.code)) {
      UIUtils.showError({
        detail: 'Please enter model code',
        toast: toastRef.current,
      });
      return false;
    }

    let {factory} = obj;
    if (!factory || !factory.id) {
      UIUtils.showError({
        detail: 'Please select factory',
        toast: toastRef.current,
      });
      return false;
    }

    // if (isNaN(obj.machLineNo) || Number(obj.machLineNo) < 0) {
    //   UIUtils.showError({
    //     detail: 'Invalid machLine',
    //     toast: toastRef.current,
    //   });
    //   return false;
    // }

    // obj.rate = Number(obj.rate);
    return obj;
  };

  if (!editMachModel) {
    return null;
  }

  const saveLbl = editMachModel.id
    ? 'Update production model'
    : 'Create production model';
  const isNew = !editMachModel.id;
  // //console.log.*$

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/edit production model">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editMachModel.code}
                onChange={(e) => {
                  setEditMachModel({...editMachModel, code: e.target.value});
                }}
                disabled={!isNew || readOnly}
              />
              <label>Code</label>
            </span>
          </div>

          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  value={editMachModel.name}
                  onChange={(e) => {
                    setEditMachModel({
                      ...editMachModel,
                      name: e.target.value,
                    });
                  }}
                  disabled={readOnly}
                />
                <label>Name</label>
              </span>
            </div>
          </div>

          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  value={editMachModel.style}
                  onChange={(e) => {
                    setEditMachModel({
                      ...editMachModel,
                      style: e.target.value,
                    });
                  }}
                  disabled={readOnly}
                />
                <label>Style</label>
              </span>
            </div>
          </div>

          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  value={editMachModel.material}
                  onChange={(e) => {
                    setEditMachModel({
                      ...editMachModel,
                      material: e.target.value,
                    });
                  }}
                  disabled={readOnly}
                />
                <label>Material</label>
              </span>
            </div>
          </div>

          <div className="p-col-12">
            <AutoComplete
              placeholder="Select factory"
              dropdown
              suggestions={suggFactories}
              field="label"
              value={editMachModel.factory}
              completeMethod={_onSuggFactories}
              onChange={(e) =>
                setEditMachModel({
                  ...editMachModel,
                  factory: e.value,
                })
              }
              disabled={readOnly}
            />
          </div>

          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputTextarea
                  rows={2}
                  value={editMachModel.description}
                  onChange={(e) => {
                    setEditMachModel({
                      ...editMachModel,
                      description: e.target.value,
                    });
                  }}
                  disabled={readOnly}
                />
                <label>Summary</label>
              </span>
            </div>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
