// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
import {Subject} from 'rxjs';
//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  byAudit?: any,
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function PfaAudItemListII(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const {t} = useTranslation();

  const {byAudit, reload, selection, header, command} = props;

  const [leanAudItemsTblData, setLeanAudItemsTblData] = useState([]);
  const [leanAudItemsTblStart, setLeanAudItemsTblStart] = useState(0);
  const [leanAudItemsTblLimit] = useState(10);
  const [leanAudItemsTblNbRows, setLeanAudItemsTblNbRows] = useState(0);
  const [selLeanAudItem, setSelLeanAudItem] = useState();
  const [selLeanAudItems, setSelLeanAudItems] = useState([]);
  const [sortMeta, setSortMeta] = useState([
    {
      field: 'obj.rowNo',
      order: 1,
    },
  ]);
  const [sortField, setSortField] = useState('obj.rowNo');
  const [sortOrder, setSortOrder] = useState(1);

  const selMode = props.selectionMode || 'single';
  const {search} = app || '';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {

    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;

  }, [conf, auth]);

  const _fetchData = useCallback(async (options) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let {
      byAudit,
      search,
      leanAudItemsTblStart,
      leanAudItemsTblLimit,
      sortMeta,
    } = options;

    let start = leanAudItemsTblStart;
    let limit = leanAudItemsTblLimit;
    let joins = [];
    let filters = {};
    let params = {};

    if (byAudit && byAudit.id) {
      filters = {
        ...filters,
        byAdit: 'obj.audit.id = :auditId',
        byCompliance: 'obj.compliance = :compliance',
      };
      params = {
        ...params,
        auditId: {
          type: 'long',
          value: byAudit.id,
        },
        compliance: {
          type: 'enum:com.nb.lmrs.model.ManufacturingProcessCompliance',
          value: 'PROCESS_COMPLIANCE_OIB_SOP_BPFC',
        },
      };
    }


    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });


    api
      .getPfaAuditItems({search, joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        console.log('data', data);
        setLeanAudItemsTblData([...data.list]);
        setLeanAudItemsTblNbRows(data.count);

      })
      .catch((err) => {
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    let subj = new Subject();

    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      subj.complete();
    };
  }, [_fetchData]);

  useEffect(() => {
    if (selection) {
      if (selMode === 'single') {
        setSelLeanAudItem({...selection});
      } else {
        setSelLeanAudItems([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelLeanAudItem(null);
      } else {
        setSelLeanAudItems([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      byAudit,
      search,
      leanAudItemsTblStart,
      leanAudItemsTblLimit,
      sortMeta,
    });
  }, [
    byAudit,
    search,
    leanAudItemsTblStart,
    leanAudItemsTblLimit,
    reload,
    sortMeta,
  ]);

  const _onLeanAudItemSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelLeanAudItem(evt.value);
    } else {
      setSelLeanAudItems(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {

    setLeanAudItemsTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];

    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };


  const _renderApplicableCol = (row, col) => {
    let {applicable} = row;
    const backgroundColor = applicable ? 'green' : 'red';

    return (
      <div style={{ backgroundColor, color: 'white',position:'absolute',inset:'0',display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', }}>
        {applicable ? (
          <i className="pi pi-check" style={{ fontSize: '1rem' }}></i>
        ) : (
          <i className="pi pi-times" style={{ fontSize: '1rem' }}></i>
        )}
      </div>
    );
  };

  const _renderComplianceWorkInstruction = (row, col) => {
    let {complianceWorkInstruction} = row;

    // Determine the background color based on the value
    let backgroundColor;
    let color = 'black'; // Default text color for contrast

    if (complianceWorkInstruction === 0 || complianceWorkInstruction === null) {
      backgroundColor = 'red';
    } else if (complianceWorkInstruction === 1) {
      backgroundColor = 'green';
    } else {
      backgroundColor = 'white';
      color = 'black'; // Black text on a white background for readability
    }

    return (
      <div style={{
       backgroundColor,
        color,
        position: 'absolute',
        inset: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // borderRadius: '4px'
      }}>
        {complianceWorkInstruction !==null ? complianceWorkInstruction : ''}

      </div>
    );
  };
  const _renderCompliancePms = (row, col) => {
    let {compliancePms} = row;

    // Determine the background color based on the value
    let backgroundColor;
    let color = 'black'; // Default text color for contrast

    if (compliancePms === 0 || compliancePms === null) {
      backgroundColor = 'red';
    } else if (compliancePms === 1) {
      backgroundColor = 'green';
    } else {
      backgroundColor = 'white';
      color = 'black'; // Black text on a white background for readability
    }

    return (
      <div style={{
        backgroundColor,
        color,
        position: 'absolute',
        inset: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // borderRadius: '4px'
      }}>
        {compliancePms !==null ? compliancePms : ''}

      </div>
    );
  }

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={leanAudItemsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={leanAudItemsTblLimit}
          first={leanAudItemsTblStart}
          totalRecords={leanAudItemsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selLeanAudItem}
          onSelectionChange={_onLeanAudItemSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            field="rowNo"
            header="#"
            headerStyle={{width: 60}}
            sortable={true}
            sortField="obj.rowNo"
          />
          <Column
            field="processType"
            header="Process type"
            headerStyle={{width: 90}}
            sortable
            sortField="obj.processType"
          />
          <Column
            field="name"
            header="Name"
            headerStyle={{width: 350}}
            sortable
            sortField="obj.name"
          />

          <Column
            body={_renderApplicableCol}
            header="Applicable"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right relative"
          />
          <Column
            //field="complianceWorkInstruction"
            body={_renderComplianceWorkInstruction}
            header="Work instruction"
            headerStyle={{width: 150}}
            headerClassName="text-right"
            bodyClassName="text-center relative"

          />
          <Column
            //field="compliancePms"
            body={_renderCompliancePms}
            header="PMS"
            headerStyle={{width: 150}}
            headerClassName="text-right"
            bodyClassName="text-right relative"
            // sortable={true}
            // sortField="obj.compliancePms"
          />

          <Column
            field="comment"
            header="Observation/Comment"
            headerStyle={{width: 300}}
            headerClassName="text-right"
            bodyClassName="text-right"
            body={(rowData) => (
              <div style={{whiteSpace: 'pre-wrap'}}>{rowData.comment}</div>
            )}
          />
        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={leanAudItemsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={leanAudItemsTblLimit}
          first={leanAudItemsTblStart}
          totalRecords={leanAudItemsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selLeanAudItems}
          onSelectionChange={_onLeanAudItemSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            field="rowNo"
            header="#"
            headerStyle={{width: 60}}
            sortable={true}
            sortField="obj.rowNo"
          />
          <Column
            field="processType"
            header="Process type"
            headerStyle={{width: 90}}
            sortable
            sortField="obj.processType"
          />
          <Column
            field="name"
            header="Name"
            headerStyle={{width: 350}}
            sortable
            sortField="obj.name"
          />

          <Column
            body={_renderApplicableCol}
            header="Applicable"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right relative"
          />
          <Column
            //field="complianceWorkInstruction"
            body={_renderComplianceWorkInstruction}
            header="Work instruction"
            headerStyle={{width: 150}}
            headerClassName="text-right"
            bodyClassName="text-center relative"

          />
          <Column
            //field="compliancePms"
            body={_renderCompliancePms}
            header="PMS"
            headerStyle={{width: 150}}
            headerClassName="text-right"
            bodyClassName="text-right relative"
            // sortable={true}
            // sortField="obj.compliancePms"
          />

          <Column
            field="comment"
            header="Observation/Comment"
            headerStyle={{width: 300}}
            headerClassName="text-right"
            bodyClassName="text-right"
            body={(rowData) => (
              <div style={{whiteSpace: 'pre-wrap'}}>{rowData.comment}</div>
            )}
          />
        </DataTable>
      )}
    </>
  );
}
