// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {Dialog} from 'primereact/dialog';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';
import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
import {AuthUtils} from '../../service/AuthUtils';
import {AuthConst} from '../../service/AppConstant';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {FactoryFilter} from '../common/FactoryFilter';

import {ProdRecBtsForm} from './ProdRecBtsForm';
import {ProdRecBtsList} from './ProdRecBtsList';

type Props = {};

export function ProdRecBts(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const [selProdRec, setSelProdRec] = useState<any>();
  const [selRecs, setSelRecs] = useState([]);
  const [editProdRec, setEditProdRec] = useState<any>();
  const [showDlg, setShowDlg] = useState<any>(false);
  const [reload, setReload] = useState(false);
  const [tabInd, setTabInd] = useState(0);
  const [filter, setFilter] = useState<any>({});
  const [initFilter, setInitFilter] = useState({});
  const [defSort, setDefSort] = useState([]);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  // const selProdRecRef = useRef();
  // useEffect(() => {
  //   selProdRecRef.current = selProdRec;
  // }, [selProdRec]);

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let initFil = {};
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      initFil['factory'] = factories[0];
    }

    let di: any = Utils.getDateInfo() || {};
    initFil = {
      ...initFil,
      month: di.month,
      year: di.year,
    };

    setFilter(initFil);
    setInitFilter({...initFil});
  }, [auth]);

  useEffect(() => {
    let sms = [
      {
        field: 'obj.recDate',
        order: -1,
      },
    ];
    setDefSort(sms);
  }, []);

  const _onFilterAction: EvtHandler = (evt: any) => {
    let {type, value} = evt;
    if (type === 'filterChanged') {
      setFilter({...value});
      return;
    }
  };

  const _onProdRecSelected = (evt: any) => {
    // setSelProdRec(evt.value);
    // //console.log.*$
    // setSelProdRec(evt.value);
    // setEditProdRec({...evt.value});
    let recs = [...evt.value];
    setSelRecs([...recs]);
    let len = recs.length;
    if (len > 0) {
      setEditProdRec({...recs[len - 1]});
      setSelProdRec(recs[len - 1]);
    } else {
      setEditProdRec({});
      setSelProdRec(null);
    }
  };

  const _onNewProdRec: any = (evt: any) => {
    setSelProdRec(null);
    setEditProdRec({});
    setTabInd(1);
  };

  const _onEditProdRec: any = (evt: any) => {
    setTabInd(1);
  };

  const _onProdRecAction = (evt: any) => {
    // setSelProdRec(null);
    setReload(!reload);
    setTabInd(0);
  };

  const _deleteRecs: (recs: any) => any = async (recs: any[]) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let ok = false;
    console.log(recs);
    for (let rec of recs) {
      ok = await api
        .deleteProdRecsBts(rec.id)
        .then((resp) => resp.data)
        .then((resp) => {
          let {errors} = resp;
          if (errors.length > 0) {
            //console.log.*$
            UIUtils.showError({errors, toast: toastRef.current});
            return false;
          }
          return true;
        })
        .catch((error) => {
          //console.log.*$
          UIUtils.showError({error, toast: toastRef.current});
          return false;
        });
      if (!ok) {
        break;
      }
    }
    if (ok) {
      UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
    }
  };

  const _onDelete: (evt: any) => void = (evt: any) => {
    if (selRecs.length < 1) {
      return;
    }
    confirmDialog({
      message: 'Are you sure you want to delete production records?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        await _deleteRecs(selRecs);
        setSelRecs([]);
        setSelProdRec(null);
        setEditProdRec({});
        setReload(!reload);
      },
      reject: () => {},
    });
  };

  // const dlgFooter = (
  //   <div className="text-right">
  //     <Button label="Yes" icon="pi pi-check" />
  //     <Button
  //       label="No"
  //       icon="pi pi-times"
  //       onClick={(e) => {
  //         setShowDlg(false);
  //       }}
  //     />
  //   </div>
  // );

  // let listTitle = 'Available production records';
  let authLevel = AuthUtils.getAuthLevel({auth});
  let canEdit =
    authLevel < AuthConst.AUTH_LVL_NB ||
    AuthUtils.isAuth({auth, roles: ['FACTORY_ADMIN', 'FACTORY_EDITOR']});

  return (
    <>
      <Toast ref={toastRef} />

      <TabView activeIndex={tabInd} onTabChange={(e) => setTabInd(e.index)}>
        <TabPanel header="Production records">
          <FactoryFilter
            scope="factory"
            filter={initFilter}
            showFactory={true}
            showYear={true}
            showLine={true}
            showFilterId={true}
            showMonth={true}
            onAction={_onFilterAction}
          />

          <ProdRecBtsList
            byId={filter.id}
            byFactory={filter.factory}
            byLine={filter.line}
            byLines={filter.lines}
            defSort={defSort}
            byMonth={filter.month}
            byYear={filter.year}
            reload={reload}
            selectionMode="multi"
            selection={selRecs}
            onSelect={_onProdRecSelected}
          />
          {canEdit && (
            <div className="p-d-flex p-jc-end p-my-2">
              <Button
                label="New"
                icon="pi pi-plus"
                className="p-ml-2"
                onClick={_onNewProdRec}
              />
              {selProdRec && (
                <Button
                  label="Edit"
                  icon="pi pi-pencil"
                  className="p-ml-2"
                  onClick={_onEditProdRec}
                  disabled={selProdRec.flag === 1}
                />
              )}
              {selRecs.length > 0 && (
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  className="p-ml-2"
                  onClick={_onDelete}
                />
              )}
            </div>
          )}
        </TabPanel>
        <TabPanel header="Edit">
          {canEdit && (
            <ProdRecBtsForm
              prodRec={editProdRec}
              filter={filter}
              onAction={_onProdRecAction}
            />
          )}
        </TabPanel>
      </TabView>

      <Dialog
        header="Enter production record"
        visible={showDlg}
        style={{width: '90vw', height: '90vh'}}
        modal={true}
        onHide={() => {
          setShowDlg(false);
        }}>
        {showDlg && <ProdRecBtsForm prodRec={editProdRec} />}
      </Dialog>
    </>
  );
}
