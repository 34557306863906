// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';
//$FlowFixMe
import {debounceTime} from 'rxjs/operators';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {Chart} from 'primereact/chart';
//$FlowFixMe
import {Button} from 'primereact/button';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../service/AuthUtils';
import type {EvtHandler} from '../types';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {pphColors, kpiColors, getChartOptions1} from './CommonChart';

type Props = {
  scope?: string,
  regions?: any[],
  countries?: any[],
  facGroups?: any[],
  factories?: any[],
  lines?: any[],
  year?: any,
  month?: any,
  expanded?: boolean,
  onAction?: EvtHandler,
};

export function PphDaily(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {
    scope,
    regions,
    countries,
    facGroups,
    factories,
    lines,
    year,
    month,
    expanded,
    onAction,
  } = props;

  const [barData, setBarData] = useState();
  const [chartOptions, setChartOptions] = useState(getChartOptions1());
  //console.log.*$
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  // const _apiErrorHandler = useCallback((errInfo: any) => {
  //   UIUtils.showError({...errInfo, toast: toastRef.current});
  // }, []);

  const _fetchMonthInfo = useCallback(async (options) => {
    let {year, month} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return {};
    }
    year = year || 0;
    month = month || 0;
    return await api
      .getMonthInfo({
        year,
        month,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        // //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return {};
        }
        return {...data};
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return {};
      });
  }, []);

  const _fetchData = useCallback(async (options) => {
    let {scope, regions, countries, facGroups, factories, lines, year, month} =
      options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return [];
    }
    scope = scope || 'region';
    // let di = Utils.getDateInfo(new Date());
    let pYear = year || 0;
    let pMonth = month || 0;

    // let pYear =
    let joins = [
      {
        type: '',
        expr: 'Factory',
        alias: 'fac',
      },
      {
        type: 'join',
        expr: 'fac.country',
        alias: 'coun',
      },
      {
        type: 'join',
        expr: 'coun.region',
        alias: 'reg',
      },
    ];
    let filters = {
      liveFlag: 'obj.flag > -1',
      jnFac: 'fac.id = obj.factoryId',
    };
    let params = {};
    let groupBys = ['obj.day'];
    if (scope === 'region') {
      groupBys = ['reg.code', ...groupBys];
      regions = regions || [];
      if (regions.length > 0) {
        let regIds = Utils.getVals(regions, 'id', [0]).map((it) => {
          return {
            type: 'long',
            value: it,
          };
        });
        filters = {
          ...filters,
          byRegIds: 'reg.id in (:regIds)',
        };
        params = {
          ...params,
          regIds,
        };
      }
    } else if (scope === 'country') {
      groupBys = ['coun.code', ...groupBys];
      countries = countries || [];
      let counIds = Utils.getVals(countries || [], 'id', [0]).map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
      filters = {
        ...filters,
        byCounIds: 'coun.id in (:counIds)',
      };
      params = {
        ...params,
        counIds,
      };
    } else if (scope === 'facGroup') {
      joins = [
        ...joins,
        {
          type: '',
          expr: 'FacGroup',
          alias: 'fg',
        },
        {
          type: 'join',
          expr: 'fg.facLnks',
          alias: 'facLnk',
        },
      ];
      filters = {
        ...filters,
        jnFacGroup: 'obj.factoryId = facLnk.factory.id',
      };
      groupBys = ['fg.code', ...groupBys];
      let fgIds = Utils.getVals(facGroups || [], 'id', [0]).map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
      filters = {
        ...filters,
        byFacGroupIds: 'fg.id in (:fgIds)',
      };
      params = {
        ...params,
        fgIds,
      };
    } else if (scope === 'factory') {
      groupBys = ['fac.code', ...groupBys];
      let facIds = Utils.getVals(factories || [], 'id', [0]).map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
      filters = {
        ...filters,
        byFacIds: 'obj.factoryId in (:facIds)',
      };
      params = {
        ...params,
        facIds,
      };
    } else if (scope === 'line') {
      groupBys = ['line.code', ...groupBys];
      joins = [
        ...joins,
        {
          type: '',
          expr: 'MachLine',
          alias: 'line',
        },
      ];
      let lineIds = Utils.getVals(lines || [], 'id', [0]).map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
      filters = {
        ...filters,
        byLineIds: 'obj.lineId in (:lineIds)',
        jnLine: 'line.id = obj.lineId',
      };
      params = {
        ...params,
        lineIds,
      };
    }
    filters = {
      ...filters,
      year: 'obj.year = :year',
      month: 'obj.month = :month',
    };
    params = {
      ...params,
      year: Number(pYear),
      month: Number(pMonth),
    };

    let fields = [...groupBys, 'sum(obj.nbProds)', 'sum(obj.nbHours)'];
    let sorts = ['obj.day'];
    return await api
      .fetchProdRecData({
        fields,
        joins,
        groupBys,
        filters,
        params,
        sorts,
        start: 0,
        limit: 0,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        // //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        let lst = [...data.list];
        lst.forEach((it) => {
          let v = 0;
          let hours = it[3];
          if (hours > 0) {
            v = Utils.round((1.0 * it[2]) / hours);
          }
          it.splice(2, 0, v);
        });
        return [...lst];
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchKpiData = useCallback(async (options: any) => {
    let {scope, factories, factory, lines, line, year, month} = options;
    //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (
      !api ||
      scope === 'region' ||
      scope === 'country' ||
      scope === 'facGroup'
    ) {
      return [];
    }
    if (!factory && factories && factories.length > 0) {
      let pms = factories.map((fac) => {
        return _fetchKpiData({
          ...options,
          factory: fac,
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    if (!line && lines && lines.length > 0) {
      let pms = lines.map((ln) => {
        return _fetchKpiData({
          ...options,
          line: ln,
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    let reqData = {
      scope,
      periodType: 'DAY',
      kpiType: 'PPH',
      year,
      month,
    };

    let serie = '';
    if (factory) {
      reqData = {
        ...reqData,
        factoryId: factory.id,
      };
      serie = `Target-${factory.code}`;
    }

    if (line) {
      reqData = {
        ...reqData,
        lineId: line.id,
      };
      serie = `Target-${line.code}`;
    }

    return await api
      .loadProdKpiData({
        data: {...reqData},
      })
      .then((resp) => resp.data)
      .then((resp) => {
        // //console.log.*$
        let {errors, data} = resp;
        //console.log.*$
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        return [
          {
            ...data,
            serie,
          },
        ];
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchKpiRegion = useCallback(async (options) => {
    let {month, year, scope, regions, countries, facGroups} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return [];
    }
    let reqData = {
      month,
      year,

      kpiType: 'PPH',
      periodType: 'DAY',
    };

    if (scope === 'region' && regions && regions.length > 0) {
      let pms = regions.map((reg) => {
        let serie = `Target-${reg.code}`;
        return api
          .loadProdKpiData({
            data: {
              ...reqData,
              scope: 'region',
              regionId: reg.id,
            },
          })
          .then((resp) => resp.data)
          .then((resp) => {
            // //console.log.*$
            let {errors, data} = resp;
            //console.log.*$
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
              return [];
            }
            return [
              {
                ...data,
                serie,
              },
            ];
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
            return [];
          });
      });

      let datas = await Promise.all(pms);
      //console.log.*$
      return datas.map((it) => it[0]);
    } else if (scope === 'country' && countries && countries.length > 0) {
      let pms = countries.map((coun) => {
        let serie = `Target-${coun.code}`;
        return api
          .loadProdKpiData({
            data: {
              ...reqData,
              scope: 'country',
              countryId: coun.id,
            },
          })
          .then((resp) => resp.data)
          .then((resp) => {
            // //console.log.*$
            let {errors, data} = resp;
            //console.log.*$
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
              return [];
            }
            return [
              {
                ...data,
                serie,
              },
            ];
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
            return [];
          });
      });

      let datas = await Promise.all(pms);
      //console.log.*$
      return datas.map((it) => it[0]);
    } else if (scope === 'facGroup' && facGroups && facGroups.length > 0) {
      let pms = facGroups.map((facGroup) => {
        let serie = `Target-${facGroup.code}`;
        return api
          .loadProdKpiData({
            data: {
              ...reqData,
              scope: 'facgroup',
              facGroupId: facGroup.id,
            },
          })
          .then((resp) => resp.data)
          .then((resp) => {
            // //console.log.*$
            let {errors, data} = resp;
            //console.log.*$
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
              return [];
            }
            return [
              {
                ...data,
                serie,
              },
            ];
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
            return [];
          });
      });

      let datas = await Promise.all(pms);
      //console.log.*$
      return datas.map((it) => it[0]);
    } else {
      return [];
    }
  }, []);

  const _fetchChartData = useCallback(
    async (options: any) => {
      let {scope} = options;
      switch (scope) {
        case 'factory':
          options = {
            ...options,
            line: null,
            lines: [],
          };
          break;
        default:
          break;
      }
      let monInfo = await _fetchMonthInfo(options);
      //console.log.*$
      let labels = [];
      for (let i = 0; i < monInfo.days; i++) {
        labels.push(i + 1);
      }

      // //console.log.*$
      let pm1 = _fetchKpiData(options).then((data) => {
        //console.log.*$
        //console.log.*$
        // let colors: any[] = randHighColors(data.length) || [];
        return data.map((rec, ind) => {
          let ci = ind % kpiColors.length;
          return {
            type: 'line',
            label: rec.serie,
            backgroundColor: kpiColors[ci],
            borderColor: kpiColors[ci],
            borderWidth: 1,
            data: [...rec.targetValues],
          };
        });
      });

      //console.log.*$

      let pm2 = _fetchData(options).then((recs) => {
        // //console.log.*$
        let series = [];
        recs
          .map((it) => it[0])
          .forEach((it) => {
            if (!series.includes(it)) {
              series = [...series, it];
            }
          });
        // //console.log.*$
        // let colors: any[] = randHighColors(series.length) || [];

        // let labels = [];
        // // let labels = recs.map((it) => it[1]);
        // recs
        //   .map((it) => it[1])
        //   .forEach((it) => {
        //     if (!labels.includes(it)) {
        //       labels = [...labels, it];
        //     }
        //   });
        // // //console.log.*$

        let datas = series.map((serie, ind) => {
          let dtMap = {};
          recs
            .filter((rc) => rc[0] === serie)
            .forEach((rc) => {
              dtMap[rc[1]] = rc[2];
            });
          let lst = labels.map((it, ind) => {
            let val = dtMap[ind + 1];
            return val ? val : 0;
          });
          return lst;
        });
        //console.log.*$

        let datasets = series.map((serie, ind) => {
          let ci = ind % pphColors.length;
          return {
            label: `EOL-${serie}`,
            backgroundColor: pphColors[ci],
            data: datas[ind],
          };
        });

        let chartData = {
          datasets,
        };
        //console.log.*$
        // setBarData(chartData);
        return chartData;
      });

      let pm3 = _fetchKpiRegion(options).then((data) => {
        //console.log.*$
        return data.map((rec, ind) => {
          let ci = ind % kpiColors.length;
          return {
            type: 'line',
            label: rec.serie,
            backgroundColor: kpiColors[ci],
            borderColor: kpiColors[ci],
            borderWidth: 1,
            data: [...rec.targetValues],
          };
        });
      });

      Promise.all([pm1, pm2, pm3]).then((data) => {
        let [kpiData, chartData, kpiDt] = data;
        //console.log.*$
        //console.log.*$
        let {datasets} = chartData;
        if (datasets.length > 0) {
          datasets = [...kpiData, ...datasets, ...kpiDt];
          const dataArrays = datasets.map((item) => item.data).flat();
          let arr = Utils.findMinMax(dataArrays);
          let chartOptionsNew = {
            ...chartOptions,
            scales: {
              ...chartOptions.scales,
              y: {
                ...chartOptions.scales.y,

                //      min: arr[0] < 0.2 ? arr[0] : Utils.round(arr[1] - 0.2, 1),
                max: Utils.round(arr[1] + 0.1, 1),
              },
            },
          };

          setChartOptions(chartOptionsNew);
        }

        //console.log.*$
        setBarData({
          labels,
          datasets,
        });
      });
    },
    [_fetchData, _fetchKpiData, _fetchMonthInfo, _fetchKpiRegion, chartOptions],
  );

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchChartData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      // //console.log.*$
      subj.complete();
    };
  }, [_fetchChartData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      scope,
      regions,
      countries,
      facGroups,
      factories,
      lines,
      year,
      month,
    });
  }, [scope, regions, countries, facGroups, factories, lines, year, month]);

  const _onToggleExpand: EvtHandler = (evt: any) => {
    onAction &&
      onAction({
        type: 'toggleExpand',
        value: !expanded,
      });
  };

  const iconName = expanded
    ? 'pi pi-angle-double-left'
    : 'pi pi-angle-double-right';

  const icons = (
    <Button
      icon={iconName}
      className="p-button-rounded p-button-text"
      style={{padding: 0, height: '1.2rem', color: '#FFFFFF'}}
      onClick={_onToggleExpand}
    />
  );

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="PPH Daily" icons={icons}>
        <Chart type="bar" data={barData} options={chartOptions}></Chart>
      </Panel>
    </>
  );
}
