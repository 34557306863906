// @flow
import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

// import {Toast} from 'primereact/toast';
// import {Panel} from 'primereact/panel';
// import {InputText} from 'primereact/inputtext';
// $FlowFixMe
import {InputNumber} from 'primereact/inputnumber';
// import {InputTextarea} from 'primereact/inputtextarea';
// import {Button} from 'primereact/button';
// import {Dialog} from 'primereact/dialog';
//$FlowFixMe[cannot-resolve-module]
import {Dropdown} from 'primereact/dropdown';
//$FlowFixMe[cannot-resolve-module]
import {AutoComplete} from 'primereact/autocomplete';
// import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Chips} from 'primereact/chips';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../../service/AuthUtils';
import {Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  filter: any,
  showRegion?: any,
  multiRegions?: boolean,
  disRegion?: boolean,
  showCountry?: boolean,
  multiCountries?: boolean,
  disCountry?: boolean,
  showFacGroup?: boolean,
  multiFacGroups?: boolean,
  disFacGroup?: boolean,
  showFactory?: boolean,
  multiFactories?: boolean,
  disFactory?: boolean,
  showLine?: boolean,
  multiLines?: boolean,
  showModel?: boolean,
  multiModels?: boolean,
  disLine?: boolean,
  showShift?: boolean,
  multiShifts?: boolean,
  disShifts?: boolean,
  showYear?: boolean,
  showYears?: boolean,
  disYears?: boolean,
  showMonth?: boolean,
  showFilterId?: boolean,
  onAction: (evt: any) => void,
};

const defFilter = {
  region: null,
  regions: [],
  country: null,
  countries: [],
  facGroup: null,
  facGroups: [],
  factory: null,
  factories: [],
  line: null,
  lines: [],
  model: null,
  models: [],
  shift: null,
  shifts: [],
  year: 2000,
  years: [2000],
  month: 1,
  id: null,
};

export function ModelFilter(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();

  const {
    filter,
    showRegion,
    multiRegions,
    showCountry,
    multiCountries,
    showFacGroup,
    multiFacGroups,
    disFacGroup,
    showFactory,
    multiFactories,
    showLine,
    multiLines,
    showModel,
    multiModels,
    // showShift,
    // multiShifts,
    showYear,
    showYears,
    showMonth,
    showFilterId,
  } = props;

  const [editFilter, setEditFilter] = useState({...defFilter});
  const [suggCountries, setSuggCountries] = useState([]);
  const [suggFacGroups, setSuggFacGroups] = useState([]);
  const [suggFactories, setSuggFactories] = useState([]);
  const [suggLines, setSuggLines] = useState([]);
  const [suggModels, setSuggModels] = useState([]);
  const [suggRegions, setSuggRegions] = useState([]);
  const [avaiMonths, setAvaiMonths] = useState([]);

  // const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let months = [];
    for (let i = 1; i <= 12; i++) {
      months.push(i);
    }
    let opts = months.map((m) => {
      return {
        label: t(`mon.${m}`),
        value: m,
      };
    });
    // //console.log.*$
    setAvaiMonths([
      {
        label: t('mon.'),
        value: -1,
      },
      ...opts,
    ]);
  }, [t]);

  useEffect(() => {
    // //console.log.*$
    let di = Utils.getDateInfo(new Date());

    let newFilter = {
      ...defFilter,
      year: di.year,
      years: [di.year],
      month: di.month,
      ...filter,
    };

    let curFilter = filter || {};
    curFilter = {
      ...newFilter,
      ...curFilter,
    };

    let {regions, countries, facGroups, factories, lines, models} = curFilter;
    [...regions, ...countries, ...facGroups, ...factories, ...lines, ...models]
      .filter((it) => !!it)
      .filter((it) => it.id)
      .forEach((it) => (it.label = `${it.code} :: ${it.name}`));

    let {region, country, facGroup, factory, line, shift, model} = filter;
    [region, country, facGroup, factory, line, shift, model]
      .filter((it) => !!it)
      .filter((it) => it.id)
      .forEach((it) => (it.label = `${it.code} :: ${it.name}`));

    [line, ...lines]
      .filter((it) => !!it)
      .forEach((it) => {
        // //console.log.*$
        let {factory} = it;
        if (factory) {
          it.label = `${factory.code} :: ${it.name}`;
        }
      });

    [model, ...models]
      .filter((it) => !!it)
      .forEach((it) => {
        // //console.log.*$
        let {factory} = it;
        if (factory) {
          it.label = `${factory.code} :: ${it.name}`;
        }
      });

    setEditFilter(curFilter);
  }, [filter]);

  const _processFilterChange: (field: string, value: any) => any = (
    field: string,
    value: any,
  ) => {
    // //console.log.*$

    let newFil = {...editFilter};

    newFil[field] = value;
    //console.log.*$

    // //console.log.*$
    let {
      region,
      regions,
      country,
      countries,
      factory,
      factories,
      line,
      model,
      models,
      lines,
      shift,
      shifts,
    } = newFil;

    let regIds = [...regions, region]
      .map((it) => (it ? it.id : 0))
      .filter((it) => it > 0);
    //console.log.*$

    if (regIds.length > 0) {
      countries = countries.filter((it) => {
        return it && it.region && regIds.includes(it.region.id);
      });
      if (!country || !country.region || !regIds.includes(country.region.id)) {
        country = null;
        newFil['country'] = null;
      }
      //console.log.*$
    }

    let counIds = [...countries, country]
      .map((it) => (it ? it.id : 0))
      .filter((it) => it > 0);
    //console.log.*$

    if (counIds.length > 0) {
      factories = factories.filter((it) => {
        return it && it.country && counIds.includes(it.country.id);
      });
      if (
        !factory ||
        !factory.country ||
        !counIds.includes(factory.country.id)
      ) {
        factory = null;
        newFil['factory'] = null;
      }
      //console.log.*$
    }

    let facIds = [...factories, factory]
      .map((it) => (it ? it.id : 0))
      .filter((it) => it > 0);
    //console.log.*$

    //console.log.*$
    lines = lines.filter((it) => {
      return it && it.factory && facIds.includes(it.factory.id);
    });
    if (!line || !line.factory || !facIds.includes(line.factory.id)) {
      line = null;
      newFil['line'] = null;
    }
    //console.log.*$

    shifts = shifts.filter((it) => {
      return it && it.factory && facIds.includes(it.factory.id);
    });
    if (!shift || !shift.factory || !facIds.includes(shift.factory.id)) {
      line = null;
      newFil['shift'] = null;
    }
    if (!model || !model.factory || !facIds.includes(model.factory.id)) {
      model = null;
      newFil['model'] = null;
    }

    newFil = {
      ...newFil,
      countries,
      factories,
      lines,
      models,
      shifts,
    };

    setEditFilter({...newFil});
    //console.log.*$

    let {onAction} = props;
    onAction &&
      onAction({
        type: 'filterChanged',
        value: {...newFil},
      });
    ////console.log.*$
    //alert(editFilter.model.label);
  };

  const _onSuggRegions: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let search = evt.query;
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getRegions({search, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          // UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        data.list.forEach((it) => (it.label = `${it.name}`));
        setSuggRegions([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        // UIUtils.showError({error: err, toast: toastRef.current});
        setSuggRegions([]);
      });
  };

  const _onSuggCountries: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let joins = [
      {
        type: 'join',
        expr: 'obj.region',
        alias: 'reg',
      },
    ];
    let filters = {};
    let params = {};
    let {regions} = editFilter;
    if (regions && regions.length > 0) {
      filters = {
        ...filters,
        byRegions: 'reg.id in (:regIds)',
      };
      let regIds = regions.map((r) => {
        return {
          type: 'long',
          value: r.id,
        };
      });
      params = {
        ...params,
        regIds,
      };
    }
    let search = evt.query;
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getCountries({search, joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          // UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        data.list.forEach((it) => (it.label = `${it.code} :: ${it.name}`));
        setSuggCountries([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        // UIUtils.showError({error: err, toast: toastRef.current});
        setSuggCountries([]);
      });
  };

  const _onSuggFacGroups: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let joins = [];
    let filters = {};
    let params = {};
    let search = evt.query;
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getFacGroups({search, joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          // UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        data.list.forEach((it) => (it.label = `${it.code} :: ${it.name}`));
        setSuggFacGroups([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        // UIUtils.showError({error: err, toast: toastRef.current});
        setSuggFacGroups([]);
      });
  };

  const _onSuggFactories: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let params = {};
    let filters = {};

    let {countries, country} = editFilter;
    countries = [...countries, country].filter((it) => it && it.id);
    if (countries.length > 0) {
      filters = {
        ...filters,
        byCountry: 'obj.country.id in (:countryIds)',
      };
      let countryIds = countries.map((c) => {
        return {
          type: 'long',
          value: c ? c.id : 0,
        };
      });
      params = {
        ...params,
        countryIds,
      };
    }

    let {facGroups, facGroup} = editFilter;
    facGroups = [...facGroups, facGroup].filter((it) => it && it.id);
    if (facGroups.length > 0) {
      let fgIds = facGroups.map((it: any) => it.id);
      params = {
        ...params,
        __facGroupIds: [...fgIds],
      };
    }

    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getFactories({search, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          // UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        data.list.forEach((it) => (it.label = `${it.code} :: ${it.name}`));
        setSuggFactories([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        // UIUtils.showError({error: err, toast: toastRef.current});
        setSuggFactories([]);
      });
  };

  const _onSuggLines: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let filters = {};
    let params = {};

    let {factories, factory} = editFilter;

    filters = {
      ...filters,
      byFactory: 'obj.factory.id in (:facIds)',
    };
    let facIds = [...factories, factory]
      .filter((f) => f && f.id)
      .map((f) => {
        return {
          type: 'long',
          value: f ? f.id : 0,
        };
      });
    params = {
      ...params,
      facIds,
    };

    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getMachLines({search, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          // UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        let lst = [...data.list];
        lst.forEach((it) => {
          let {factory} = it;
          if (factory) {
            it.label = `${factory.code} :: ${it.name}`;
          } else {
            it.label = `${it.name}`;
          }
        });
        setSuggLines([...lst]);
      })
      .catch((err) => {
        //console.log.*$
        // UIUtils.showError({error: err, toast: toastRef.current});
        setSuggLines([]);
      });
  };

  const _onSuggModels: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let filters = {};
    let params = {};

    let {factories, factory} = editFilter;

    filters = {
      ...filters,
      byFactory: 'obj.factory.id in (:facIds)',
    };
    let facIds = [...factories, factory]
      .filter((f) => f && f.id)
      .map((f) => {
        return {
          type: 'long',
          value: f ? f.id : 0,
        };
      });
    params = {
      ...params,
      facIds,
    };

    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getMachModels({search, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          // UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        let lst = [...data.list];
        lst.forEach((it) => {
          let {factory} = it;
          if (factory) {
            it.label = `${factory.code} :: ${it.name}`;
          } else {
            it.label = `${it.name}`;
          }
        });
        setSuggModels([...lst]);
      })
      .catch((err) => {
        //console.log.*$
        // UIUtils.showError({error: err, toast: toastRef.current});
        setSuggModels([]);
      });
  };

  let {factory, factories} = editFilter;

  let disableLines = multiFactories
    ? !factories || factories.length < 1
    : !factory || !factory.id;

  let disableModels = multiFactories
    ? !factories || factories.length < 1
    : !factory || !factory.id;

  //console.log.*$

  return (
    <>
      <div className="grid p-fluid">
        {showRegion && !multiRegions && (
          <div className="col-12 lg:col-3">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                suggestions={suggRegions}
                field="label"
                value={editFilter.region}
                completeMethod={_onSuggRegions}
                onChange={(e) => _processFilterChange('region', e.value)}
              />
              <label>Region</label>
            </span>
          </div>
        )}

        {showRegion && multiRegions && (
          <div className="col-12 lg:col-6">
            <span className="p-float-label">
              <AutoComplete
                multiple
                dropdown
                suggestions={suggRegions}
                field="label"
                value={editFilter.regions}
                completeMethod={_onSuggRegions}
                onChange={(e) => _processFilterChange('regions', e.value)}
              />
              <label>Region</label>
            </span>
          </div>
        )}

        {showCountry && !multiCountries && (
          <div className="col-12 lg:col-3">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                suggestions={suggCountries}
                field="label"
                value={editFilter.country}
                completeMethod={_onSuggCountries}
                onChange={(e) => _processFilterChange('country', e.value)}
              />
              <label>Country</label>
            </span>
          </div>
        )}

        {showCountry && multiCountries && (
          <div className="col-12 lg:col-6">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                multiple
                suggestions={suggCountries}
                field="label"
                value={editFilter.countries}
                completeMethod={_onSuggCountries}
                onChange={(e) => _processFilterChange('countries', e.value)}
              />
              <label>Countries</label>
            </span>
          </div>
        )}

        {showFacGroup && !multiFacGroups && (
          <div className="col-12 lg:col-3">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                suggestions={suggFacGroups}
                field="label"
                value={editFilter.facGroup}
                completeMethod={_onSuggFacGroups}
                onChange={(e) => _processFilterChange('facGroup', e.value)}
              />
              <label>Group</label>
            </span>
          </div>
        )}

        {showFacGroup && multiFacGroups && (
          <div className="col-12 lg:col-6">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                multiple
                suggestions={suggFacGroups}
                field="label"
                value={editFilter.facGroups}
                disabled={disFacGroup}
                completeMethod={_onSuggFacGroups}
                onChange={(e) => _processFilterChange('facGroups', e.value)}
              />
              <label>Groups</label>
            </span>
          </div>
        )}
        {showFilterId && (
          <div className="col-12 xl:col-1 lg:col-2 md:col-3 sm:col-4">
            <span className="p-float-label">
              <InputNumber
                useGrouping={false}
                value={editFilter.id}
                onValueChange={(e) => _processFilterChange('id', e.value)}
              />
              <label>Id</label>
            </span>
          </div>
        )}

        {showFactory && !multiFactories && (
          <div className="col-12 lg:col-3">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                suggestions={suggFactories}
                field="label"
                value={editFilter.factory}
                completeMethod={_onSuggFactories}
                onChange={(e) => _processFilterChange('factory', e.value)}
              />
              <label>Factory</label>
            </span>
          </div>
        )}

        {showFactory && multiFactories && (
          <div className="col-12 lg:col-6">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                multiple
                suggestions={suggFactories}
                field="label"
                value={editFilter.factories}
                completeMethod={_onSuggFactories}
                onChange={(e) => _processFilterChange('factories', e.value)}
              />
              <label>Factories</label>
            </span>
          </div>
        )}

        {showLine && !multiLines && (
          <div className="col-12 lg:col-3">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                suggestions={suggLines}
                field="label"
                value={editFilter.line}
                completeMethod={_onSuggLines}
                onChange={(e) => _processFilterChange('line', e.value)}
                disabled={disableLines}
              />
              <label>Line</label>
            </span>
          </div>
        )}
        {showLine && multiLines && (
          <div className="col-12 lg:col-6">
            <span className="p-float-label">
              <AutoComplete
                multiple
                dropdown
                suggestions={suggLines}
                field="label"
                value={editFilter.lines}
                completeMethod={_onSuggLines}
                onChange={(e) => _processFilterChange('lines', e.value)}
                disabled={disableLines}
              />
              <label>Lines</label>
            </span>
          </div>
        )}

        {showModel && !multiModels && (
          <div className="col-12 lg:col-3">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                suggestions={suggModels}
                field="label"
                value={editFilter.model}
                completeMethod={_onSuggModels}
                onChange={(e) => _processFilterChange('model', e.value)}
                disabled={disableModels}
              />
              <label>Model</label>
            </span>
          </div>
        )}

        {showModel && multiModels && (
          <div className="col-12 lg:col-6">
            <span className="p-float-label">
              <AutoComplete
                multiple
                dropdown
                suggestions={suggModels}
                field="label"
                value={editFilter.models}
                completeMethod={_onSuggModels}
                onChange={(e) => _processFilterChange('models', e.value)}
                disabled={disableModels}
              />
              <label>Models</label>
            </span>
          </div>
        )}

        {showYear && (
          <div className="col-12 xl:col-1 lg:col-2 md:col-3 sm:col-4">
            <span className="p-float-label">
              <InputNumber
                value={editFilter.year}
                onValueChange={(e) => _processFilterChange('year', e.value)}
              />
              <label>Year</label>
            </span>
          </div>
        )}
        {showYears && (
          <div className="col-12 xl:col-4 lg:col-6">
            <span className="p-float-label">
              <Chips
                max={5}
                value={editFilter.years}
                onChange={(e) =>
                  _processFilterChange('years', e.value)
                }></Chips>
              <label>Years</label>
            </span>
          </div>
        )}
        {showMonth && (
          <div className="col-12 xl:col-1 lg:col-2 md:col-3 sm:col-4">
            <span className="p-float-label">
              <Dropdown
                value={editFilter.month}
                options={avaiMonths}
                onChange={(e) => _processFilterChange('month', e.value)}
              />
              <label>Month</label>
            </span>
          </div>
        )}
      </div>
    </>
  );
}
