// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {AutoComplete} from 'primereact/autocomplete';
//$FlowFixMe[cannot-resolve-module]
import {InputNumber} from 'primereact/inputnumber';
//$FlowFixMe[cannot-resolve-module]
import {Calendar} from 'primereact/calendar';
//$FlowFixMe
import {Accordion, AccordionTab} from 'primereact/accordion';

import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  readOnly?: boolean,
  report: any,
  onAction: (evt: any) => void,
};

const defLeanReport = {
  __cls__: 'com.nb.lmrs.model.entity.LeanReport',
  flag: 0,
  code: '',
  name: '',
  description: '',
  factory: null,
  audDate: null,
  manPower: 0,
  workDay: 0,
  otManHour: 0,
  otRate: 0,
  diManHour: 0,
  preDiManHour: 0,
  actSi: 0,
  tarSi: 0,
  prePph: 0,
  plaSi: 0,
  rccp: 0,
  actLoPlan: 0,
  netCapa: 0,
  filRate: 0,
  actPph: 0,
  expPph: 0,
  tarPph: 0,
  pphAchi: 0,
  actMlt: 0,
  tarMlt: 0,
  mltAchi: 0,
  actCssMlt: 0,
  tarCssMlt: 0,
  actMinl: 0,
  tarMinl: 0,
  minlAchi: 0,
  actScore: 0,
  tarScore: 0,
  scoreAchi: 0,
};

export function LeanReportForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {readOnly, report} = props;
  const [editLeanReport, setEditLeanReport] = useState<any>();
  const [suggFactories, setSuggFactories] = useState([]);
  const [activeIndex] = useState([0, 1]);
  const [defFactory, setDefFactory] = useState();

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchPphKpi = async (options) => {
    let api: ?AdminApi = apiRef.current;
    let {month, year} = options;

    if (!month || !year) {
      return 0;
    }

    let qoy = 0;
    if (month === 1 || month === 2 || month === 3) {
      qoy = 1;
    }
    if (month === 4 || month === 5 || month === 6) {
      qoy = 2;
    }
    if (month === 7 || month === 8 || month === 9) {
      qoy = 3;
    }
    if (month === 10 || month === 11 || month === 12) {
      qoy = 4;
    }

    let start = 0;
    let limit = 0;
    let sorts = [];
    let joins = [];
    let filters = {
      byType: 'obj.kpiType = :kpiType',
      byYear: 'obj.year = :byYear',
      byQoy: 'obj.qoy = :byQoy',
    };

    let params = {
      kpiType: {
        type: 'enum:com.nb.lmrs.model.KpiType',
        value: 'PPH',
      },
      byYear: Number(year),
      byQoy: Number(qoy),
    };

    return await api
      .getLeanScoreCard({joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return 0;
        }

        if (data.list && data.list.length > 0) {
          return data.list[0].targetValue;
        }

        return 0;
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
        return 0;
      });

    //  //console.log.*$
  };

  const _fetchMltKpi = async (options) => {
    let api: ?AdminApi = apiRef.current;
    let {month, year} = options;

    if (!month || !year) {
      return 0;
    }

    let qoy = 0;
    if (month === 1 || month === 2 || month === 3) {
      qoy = 1;
    }
    if (month === 4 || month === 5 || month === 6) {
      qoy = 2;
    }
    if (month === 7 || month === 8 || month === 9) {
      qoy = 3;
    }
    if (month === 10 || month === 11 || month === 12) {
      qoy = 4;
    }

    let start = 0;
    let limit = 0;
    let sorts = [];
    let joins = [];
    let filters = {
      byType: 'obj.kpiType = :kpiType',
      byYear: 'obj.year = :byYear',
      byQoy: 'obj.qoy = :byQoy',
    };

    let params = {
      kpiType: {
        type: 'enum:com.nb.lmrs.model.KpiType',
        value: 'MLT',
      },
      byYear: Number(year),
      byQoy: Number(qoy),
    };

    return await api
      .getLeanScoreCard({joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return 0;
        }

        if (data.list && data.list.length > 0) {
          return data.list[0].targetValue;
        }

        return 0;
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
        return 0;
      });

    //  //console.log.*$
  };

  const _fetchMinilineKpi = async (options) => {
    let api: ?AdminApi = apiRef.current;
    let {month, year} = options;

    if (!month || !year) {
      return 0;
    }

    let qoy = 0;
    if (month === 1 || month === 2 || month === 3) {
      qoy = 1;
    }
    if (month === 4 || month === 5 || month === 6) {
      qoy = 2;
    }
    if (month === 7 || month === 8 || month === 9) {
      qoy = 3;
    }
    if (month === 10 || month === 11 || month === 12) {
      qoy = 4;
    }

    let start = 0;
    let limit = 0;
    let sorts = [];
    let joins = [];
    let filters = {
      byType: 'obj.kpiType = :kpiType',
      byYear: 'obj.year = :byYear',
      byQoy: 'obj.qoy = :byQoy',
    };

    let params = {
      kpiType: {
        type: 'enum:com.nb.lmrs.model.KpiType',
        value: 'MINL_RATE',
      },
      byYear: Number(year),
      byQoy: Number(qoy),
    };

    return await api
      .getLeanScoreCard({joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return 0;
        }

        if (data.list && data.list.length > 0) {
          return data.list[0].targetValue;
        }

        return 0;
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
        return 0;
      });

    //  //console.log.*$
  };

  const _fetchLeanAssessmentKpi = async (options) => {
    let api: ?AdminApi = apiRef.current;
    let {month, year} = options;

    if (!month || !year) {
      return 0;
    }

    let qoy = 0;
    if (
      month === 1 ||
      month === 2 ||
      month === 3 ||
      month === 4 ||
      month === 5 ||
      month === 6
    ) {
      qoy = 1;
    }

    if (
      month === 7 ||
      month === 8 ||
      month === 9 ||
      month === 10 ||
      month === 11 ||
      month === 12
    ) {
      qoy = 3;
    }

    let start = 0;
    let limit = 0;
    let sorts = [];
    let joins = [];
    let filters = {
      byType: 'obj.kpiType = :kpiType',
      byYear: 'obj.year = :byYear',
      byQoy: 'obj.qoy = :byQoy',
    };

    let params = {
      kpiType: {
        type: 'enum:com.nb.lmrs.model.KpiType',
        value: 'LEAN_SCORE',
      },
      byYear: Number(year),
      byQoy: Number(qoy),
    };

    return await api
      .getLeanScoreCard({joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return 0;
        }

        if (data.list && data.list.length > 0) {
          return data.list[0].targetValue;
        }

        return 0;
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
        return 0;
      });

    //  //console.log.*$
  };

  useEffect(() => {
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      setDefFactory(factories[0]);
    }
  }, [auth]);

  useEffect(() => {
    //console.log.*$
    const initLeanReport = async () => {
      // //console.log.*$
      let tarPph = await _fetchPphKpi({
        year: report.audYear ? report.audYear : 0,
        month: report.audMonth ? report.audMonth : 0,
      });
      let tarMlt = await _fetchMltKpi({
        year: report.audYear ? report.audYear : 0,
        month: report.audMonth ? report.audMonth : 0,
      });

      let tarMinl = await _fetchMinilineKpi({
        year: report.audYear ? report.audYear : 0,
        month: report.audMonth ? report.audMonth : 0,
      });

      let tarScore = await _fetchLeanAssessmentKpi({
        year: report.audYear ? report.audYear : 0,
        month: report.audMonth ? report.audMonth : 0,
      });

      let obj: any = report || {};
      obj = {
        ...defLeanReport,
        ...obj,
        tarPph,
        tarMlt,
        tarMinl,
        tarScore,
      };

      let isNew = !obj.id;
      if (isNew && (!obj.factory || !obj.factory.id)) {
        obj['factory'] = defFactory;
      }

      let {factory} = obj;
      if (factory) {
        factory.label = `${factory.code}::${factory.name}`;
      }

      let {audDate} = obj;
      if (isNew) {
        audDate = new Date();
        obj['audDate'] = audDate;
        let tarPph = await _fetchPphKpi({
          year: audDate.getFullYear(),
          month: audDate.getMonth() + 1,
        });

        let tarMlt = await _fetchMltKpi({
          year: report.audYear ? report.audYear : 0,
          month: report.audMonth ? report.audMonth : 0,
        });

        let tarMinl = await _fetchMinilineKpi({
          year: report.audYear ? report.audYear : 0,
          month: report.audMonth ? report.audMonth : 0,
        });

        let tarScore = await _fetchLeanAssessmentKpi({
          year: report.audYear ? report.audYear : 0,
          month: report.audMonth ? report.audMonth : 0,
        });
        obj = {
          ...obj,
          tarPph,
          tarMlt,
          tarMinl,
          tarScore,
        };
      } else {
        obj['audDate'] = Utils.toJsDate(audDate);
        let {actMinl} = obj;
        obj = {
          ...obj,
          actMinl: actMinl * 100,
          //  tarMinl: tarMinl * 100,
        };
      }

      // //console.log.*$
      setEditLeanReport(obj);
    };
    if (report) {
      initLeanReport();
    }
    let obj: any = report || {};
    obj = {
      ...defLeanReport,
      ...obj,
    };

    let isNew = !obj.id;
    if (isNew && (!obj.factory || !obj.factory.id)) {
      obj['factory'] = defFactory;
    }

    let {factory} = obj;
    if (factory) {
      factory.label = `${factory.code}::${factory.name}`;
    }

    let {audDate} = obj;
    if (isNew) {
      audDate = new Date();
      obj['audDate'] = audDate;
    } else {
      obj['audDate'] = Utils.toJsDate(audDate);
      let {actMinl, tarMinl} = obj;
      obj = {
        ...obj,
        actMinl: actMinl * 100,
        tarMinl: tarMinl * 100,
      };
    }

    // //console.log.*$
    setEditLeanReport(obj);

    // //console.log.*$
  }, [report, defFactory]);

  const _validateLeanReport: () => any = () => {
    let obj = {...editLeanReport};

    let {factory} = obj;
    if (!factory || !factory.id) {
      UIUtils.showError({
        detail: 'Please select factory',
        toast: toastRef.current,
      });
      return false;
    }

    let {audDate} = obj;
    if (!audDate) {
      UIUtils.showError({
        detail: 'Please select month',
        toast: toastRef.current,
      });
      return false;
    }
    Utils.assign(obj, {
      audDate: Utils.jsDateToStr(audDate),
    });

    let {name} = obj;
    if (Utils.isEmpty(name)) {
      name = 'Lean report of ' + Utils.jsDateToStr(audDate, 'MM/YYYY');
      obj = {
        ...obj,
        name,
      };
    }

    let {actMinl, tarMinl} = obj;
    if (actMinl < 0 || actMinl > 100) {
      UIUtils.showError({
        detail: 'Please enter valid actual mini line value',
        toast: toastRef.current,
      });
      return false;
    }
    if (tarMinl < 0 || tarMinl > 100) {
      UIUtils.showError({
        detail: 'Please enter valid target mini line value',
        toast: toastRef.current,
      });
      return false;
    }
    obj = {
      ...obj,
      actMinl: actMinl / 100,
      tarMinl: tarMinl / 100,
    };

    // if (Utils.isEmpty(obj.code)) {
    //   UIUtils.showError({
    //     detail: 'Missing tax code',
    //     toast: toastRef.current,
    //   });
    //   return false;
    // }

    // if (isNaN(obj.machLineNo) || Number(obj.machLineNo) < 0) {
    //   UIUtils.showError({
    //     detail: 'Invalid machLine',
    //     toast: toastRef.current,
    //   });
    //   return false;
    // }

    // obj.rate = Number(obj.rate);
    return obj;
  };
  const checkDate = (leanrptMonth, leanrptYear) => {
    let di = Utils.getDateInfo(new Date());
    let thisYear = di.year;
    let thisMon = di.month;
    if (thisYear > leanrptYear) {
      return true;
    } else {
      if (thisMon > leanrptMonth) {
        return true;
      } else {
        return false;
      }
    }
  };

  const _processFieldChanged = async (fdName: string, value: any) => {
    let obj: any = {
      ...editLeanReport,
    };
    obj[fdName] = value;
    let di = Utils.getDateInfo(new Date());
    let thisYear = di.year;
    let thisMon = di.month;
    let leanrptMonth = editLeanReport.audDate.getMonth() + 1;
    let leanrptYear = editLeanReport.audDate.getFullYear();
    switch (fdName) {
      case 'diManHour':
        if (obj['diManHour'] > 0) {
          if (obj['actSi'] > 0) {
            let actPph = Utils.round(obj['actSi'] / obj['diManHour'], 4);
            obj['actPph'] = actPph;
          }
        } else if (obj['diManHour'] === 0) {
          obj['actPph'] = 0;
        }

        if (
          checkDate(
            editLeanReport.audDate.getMonth() + 1,
            editLeanReport.audDate.getFullYear(),
          )
        ) {
          let pphAchi = Utils.round(obj['actPph'] / obj['tarPph'], 4);
          obj['pphAchi'] = pphAchi;

          if (obj['diManHour'] > 0) {
            let otRate = Utils.round(obj['otManHour'] / obj['diManHour'], 4);
            obj['otRate'] = otRate;
          }
        }

        break;
      case 'otManHour':
        if (thisYear > leanrptYear) {
          let otRate = Utils.round(obj['otManHour'] / obj['diManHour'], 4);
          obj['otRate'] = otRate;
        } else {
          if (thisMon > leanrptMonth) {
            let otRate = Utils.round(obj['otManHour'] / obj['diManHour'], 4);
            obj['otRate'] = otRate;
          } else {
            let otRate = Utils.round(obj['otManHour'] / obj['preDiManHour'], 4);
            obj['otRate'] = otRate;
          }
        }

        // //console.log.*$
        break;
      case 'actPph':
        // alert("I am an alert box!");
        break;
      case 'plaSi':
        if (obj['plaSi'] > 0) {
          if (obj['preDiManHour'] > 0) {
            let prePph = Utils.round(obj['plaSi'] / obj['preDiManHour'], 4);
            obj['prePph'] = prePph;
          } else {
            obj['prePph'] = 0;
          }
        } else if (obj['plaSi'] === 0) {
          obj['prePph'] = 0;
        }

        if (
          !checkDate(
            editLeanReport.audDate.getMonth() + 1,
            editLeanReport.audDate.getFullYear(),
          )
        ) {
          let pphAchi = Utils.round(obj['prePph'] / obj['tarPph'], 4);
          obj['pphAchi'] = pphAchi;
        }
        break;
      case 'preDiManHour':
        if (obj['preDiManHour'] > 0) {
          if (obj['plaSi'] > 0) {
            let prePph = Utils.round(obj['plaSi'] / obj['preDiManHour'], 4);
            obj['prePph'] = prePph;
          } else {
            obj['prePph'] = 0;
          }
        } else if (obj['preDiManHour'] === 0) {
          obj['prePph'] = 0;
        }

        if (
          !checkDate(
            editLeanReport.audDate.getMonth() + 1,
            editLeanReport.audDate.getFullYear(),
          )
        ) {
          let pphAchi = Utils.round(obj['prePph'] / obj['tarPph'], 4);
          obj['pphAchi'] = pphAchi;

          if (obj['preDiManHour'] > 0) {
            let otRate = Utils.round(obj['otManHour'] / obj['preDiManHour'], 4);
            obj['otRate'] = otRate;
          }
        }

        break;
      case 'actSi':
        if (obj['actSi'] > 0) {
          if (obj['diManHour'] > 0) {
            let actPph = Utils.round(obj['actSi'] / obj['diManHour'], 4);
            obj['actPph'] = actPph;
          } else {
            obj['actPph'] = 0;
          }
        } else if (obj['actSi'] === 0) {
          obj['actPph'] = 0;
        }

        if (obj['actSi'] > 0) {
          let filRate = Utils.round(obj['actSi'] / obj['netCapa'], 4);
          obj['filRate'] = filRate;
        }

        if (obj['diManHour'] > 0) {
          if (obj['actSi'] > 0) {
            let actPph = Utils.round(obj['actSi'] / obj['diManHour'], 4);
            obj['actPph'] = actPph;
          }
        } else if (obj['diManHour'] === 0) {
          obj['actPph'] = 0;
        }

        if (
          checkDate(
            editLeanReport.audDate.getMonth() + 1,
            editLeanReport.audDate.getFullYear(),
          )
        ) {
          let pphAchi = Utils.round(obj['actPph'] / obj['tarPph'], 4);
          obj['pphAchi'] = pphAchi;
        }

        break;
      case 'tarPph':
        /**/

        let di1 = Utils.getDateInfo(new Date());
        let thisYear1 = di1.year;
        let thisMon1 = di1.month;

        let leanrptMonth1 = editLeanReport.audDate.getMonth() + 1;
        let leanrptYear1 = editLeanReport.audDate.getFullYear();
        if (thisYear1 > leanrptYear1) {
          if (obj['tarPph'] > 0) {
            let pphAchi = Utils.round(obj['actPph'] / obj['tarPph'], 4);
            obj['pphAchi'] = pphAchi;
          }
        } else {
          if (thisMon1 > leanrptMonth1) {
            if (obj['tarPph'] > 0) {
              let pphAchi = Utils.round(obj['actPph'] / obj['tarPph'], 4);
              obj['pphAchi'] = pphAchi;
            }
          } else {
            if (obj['tarPph'] > 0) {
              let pphAchi = Utils.round(obj['prePph'] / obj['tarPph'], 4);
              obj['pphAchi'] = pphAchi;
            }
          }
        }

        break;
      case 'actLoPlan':
      case 'netCapa':
        if (obj['netCapa'] > 0) {
          let filRate = Utils.round(obj['actSi'] / obj['netCapa'], 4);
          obj['filRate'] = filRate;
        }
        break;
      case 'actMlt':
        if (checkDate(leanrptMonth, leanrptYear)) {
          if (obj['actMlt'] > 0) {
            let mltAchi = Utils.round(obj['tarMlt'] / obj['actMlt'], 4);
            obj['mltAchi'] = mltAchi;
          }
        }
        break;
      case 'tarMlt':
        if (checkDate(leanrptMonth, leanrptYear)) {
          if (obj['tarMlt'] > 0) {
            let mltAchi = Utils.round(obj['tarMlt'] / obj['actMlt'], 4);
            obj['mltAchi'] = mltAchi;
          }
        } else {
          if (obj['tarMlt'] > 0) {
            let mltAchi = Utils.round(obj['tarMlt'] / obj['preMlt'], 4);
            obj['mltAchi'] = mltAchi;
          }
        }

        break;
      case 'actMinl':
      case 'tarMinl':
        if (obj['tarMinl'] > 0) {
          let minlAchi = Utils.round(obj['actMinl'] / obj['tarMinl'], 4);
          obj['minlAchi'] = minlAchi;
        }
        break;
      case 'preMlt':
        if (!checkDate(leanrptMonth, leanrptYear)) {
          if (obj['preMlt'] > 0) {
            let mltAchi = Utils.round(obj['tarMlt'] / obj['preMlt'], 4);
            obj['mltAchi'] = mltAchi;
          }
        }
        break;
      case 'actScore':
      case 'tarScore':
        if (obj['tarScore'] > 0) {
          let scoreAchi = Utils.round(obj['actScore'] / obj['tarScore'], 4);
          obj['scoreAchi'] = scoreAchi;
        }
        break;
      case 'audDate':
        let pphKpi = await _fetchPphKpi({
          year: value.getFullYear(),
          month: value.getMonth() + 1,
        });

        let mltKpi = await _fetchMltKpi({
          year: value.getFullYear(),
          month: value.getMonth() + 1,
        });

        let miniLineKpi = await _fetchMinilineKpi({
          year: value.getFullYear(),
          month: value.getMonth() + 1,
        });

        let leanAsessmentKpi = await _fetchLeanAssessmentKpi({
          year: value.getFullYear(),
          month: value.getMonth() + 1,
        });

        obj['tarPph'] = pphKpi;
        obj['tarMlt'] = mltKpi;
        obj['tarMinl'] = miniLineKpi;
        obj['tarScore'] = leanAsessmentKpi;
        break;
      default:
    }
    setEditLeanReport({...obj});
  };

  const _onSave = (evt: any) => {
    // setShowCfmDlg(false);
    let fac = _validateLeanReport();
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update lean report?'
        : 'Are you sure you want to create lean report?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateLeanReport({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'machLineSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _onSuggFactories: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getFactories({search, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        data.list.forEach((it) => (it.label = `${it.code} :: ${it.name}`));
        setSuggFactories([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onKeyDown: EvtHandler = (e: any) => {
    if (e && e.keyCode === 13) {
      setTimeout(() => {
        _onSave();
      }, 10);
    }
  };

  if (!editLeanReport) {
    return null;
  }

  const saveLbl = editLeanReport.id
    ? 'Update Lean Report'
    : 'Create Lean Report';
  const isNew = !editLeanReport.id;
  // //console.log.*$
  ////console.log.*$
  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/edit lean report">
        <div className="grid py-3 p-fluid">
          <div className="col-12 xl:col-4 lg:col-6">
            <AutoComplete
              placeholder="Select factory"
              dropdown
              suggestions={suggFactories}
              field="label"
              value={editLeanReport.factory}
              completeMethod={_onSuggFactories}
              onChange={(e) => _processFieldChanged('factory', e.value)}
              disabled={!isNew}
            />
          </div>

          <div className="col-12 xl:col-4 lg:col-6">
            <span className="p-float-label">
              <InputText
                value={editLeanReport.name}
                onChange={(e) => _processFieldChanged('name', e.target.value)}
                onKeyDown={_onKeyDown}
                disabled={readOnly}
              />
              <label>Name</label>
            </span>
          </div>

          <div className="col-12 xl:col-2 lg:col-3">
            <span className="p-float-label">
              <Calendar
                dateFormat="mm/yy"
                value={editLeanReport.audDate}
                onChange={(e) => _processFieldChanged('audDate', e.value)}
              />
              <label>Assess month</label>
            </span>
          </div>
          <div className="col-12">
            <span className="p-float-label">
              <InputTextarea
                rows={2}
                value={editLeanReport.description}
                onChange={(e) => {
                  setEditLeanReport({
                    ...editLeanReport,
                    description: e.target.value,
                  });
                }}
                disabled={readOnly}
              />
              <label>Summary</label>
            </span>
          </div>
        </div>

        <Accordion multiple activeIndex={activeIndex}>
          <AccordionTab header="PPH">
            <div className="grid py-3 p-fluid">
              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.manPower}
                    onValueChange={(e) =>
                      _processFieldChanged('manPower', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled={readOnly}
                  />
                  <label>Man power</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.workDay}
                    mode="decimal"
                    minFractionDigits={1}
                    maxFractionDigits={1}
                    onValueChange={(e) =>
                      _processFieldChanged('workDay', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled={readOnly}
                  />
                  <label>Working days</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.otManHour}
                    onValueChange={(e) =>
                      _processFieldChanged('otManHour', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled={readOnly}
                  />
                  <label>OT man hours</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.otRate * 100}
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={4}
                    disabled={true}
                  />
                  <label>OT rate(%)</label>
                </span>
              </div>
              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.preDiManHour}
                    onValueChange={(e) =>
                      _processFieldChanged('preDiManHour', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled={readOnly}
                  />
                  <label>Predicted direct man hours (hours)</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.diManHour}
                    onValueChange={(e) =>
                      _processFieldChanged('diManHour', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled={readOnly}
                  />
                  <label>Actual direct man hours (hours)</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.plaSi}
                    onValueChange={(e) =>
                      _processFieldChanged('plaSi', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled={readOnly}
                  />
                  <label>Planned SI (pairs)</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.actSi}
                    onValueChange={(e) =>
                      _processFieldChanged('actSi', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled={readOnly}
                  />
                  <label>Actual SI (pairs)</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.rccp}
                    onValueChange={(e) => _processFieldChanged('rccp', e.value)}
                    onKeyDown={_onKeyDown}
                    disabled={readOnly}
                  />
                  <label>RCCP</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.actLoPlan}
                    onValueChange={(e) =>
                      _processFieldChanged('actLoPlan', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled={readOnly}
                  />
                  <label>Actual Loading Plan</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.netCapa}
                    onValueChange={(e) =>
                      _processFieldChanged('netCapa', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled={readOnly}
                  />
                  <label>Net Capa</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber value={editLeanReport.filRate * 100} disabled />
                  <label>Actual Fill Rate (%)</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    inputId="minmaxfraction"
                    value={editLeanReport.prePph}
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    onKeyDown={_onKeyDown}
                    disabled
                  />
                  <label>Predicted PPH</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    inputId="minmaxfraction"
                    value={editLeanReport.actPph}
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    onValueChange={(e) =>
                      _processFieldChanged('actPph', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled
                  />
                  <label>Actual PPH</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    inputId="minmaxfraction"
                    value={editLeanReport.tarPph}
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    onValueChange={(e) =>
                      _processFieldChanged('tarPph', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled
                  />
                  <label>Target PPH</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    inputId="minmaxfraction"
                    value={editLeanReport.pphAchi * 100}
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    disabled
                  />
                  <label>PPH Achievement (%)</label>
                </span>
              </div>
            </div>
          </AccordionTab>
          <AccordionTab header="MLT">
            <div className="grid py-3 p-fluid">
              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.preMlt}
                    onValueChange={(e) =>
                      _processFieldChanged('preMlt', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled={readOnly}
                  />
                  <label>Predicted MLT</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.actMlt}
                    onValueChange={(e) =>
                      _processFieldChanged('actMlt', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled={readOnly}
                  />
                  <label>Actual MLT</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.tarMlt}
                    onValueChange={(e) =>
                      _processFieldChanged('tarMlt', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled
                  />
                  <label>Target MLT</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.mltAchi * 100}
                    minFractionDigits={2}
                    maxFractionDigits={4}
                    disabled
                  />
                  <label>MLT Achievement (%)</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.actCssMlt}
                    onValueChange={(e) =>
                      _processFieldChanged('actCssMlt', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled={readOnly}
                  />
                  <label>Actual/Predicted CSS MLT</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.tarCssMlt}
                    onValueChange={(e) =>
                      _processFieldChanged('tarCssMlt', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled={readOnly}
                  />
                  <label>Target CSS MLT</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.actMinl}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    onValueChange={(e) =>
                      _processFieldChanged('actMinl', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled={readOnly}
                  />
                  <label>Actual Mini Line (%)</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.tarMinl}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    onValueChange={(e) =>
                      _processFieldChanged('tarMinl', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled
                  />
                  <label>Target Mini Line (%)</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.minlAchi * 100}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    disabled
                  />
                  <label>Mini Line Achievement (%)</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.actScore}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    onValueChange={(e) =>
                      _processFieldChanged('actScore', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled={readOnly}
                  />
                  <label>Actual Assessment Score</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.tarScore}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    onValueChange={(e) =>
                      _processFieldChanged('tarScore', e.value)
                    }
                    onKeyDown={_onKeyDown}
                    disabled
                  />
                  <label>Target Assessment Score</label>
                </span>
              </div>

              <div className="col-12 xl:col-2 lg:col-3">
                <span className="p-float-label">
                  <InputNumber
                    value={editLeanReport.scoreAchi * 100}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    disabled
                  />
                  <label>Assessment Score Achievement (%)</label>
                </span>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
