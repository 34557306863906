// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';
//$FlowFixMe
import {debounceTime} from 'rxjs/operators';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {Chart} from 'primereact/chart';
//$FlowFixMe
import {Button} from 'primereact/button';

import type {ApiProps} from '../../../service/Api';
import type {EvtHandler} from '../../types';
// import {AuthUtils} from '../service/AuthUtils';
import {UIUtils, Utils} from '../../../service/Utils';
import {AdminApi} from '../../../service/AdminApi';
import {getColor} from '../../../service/util/color';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import {getPieChartOptions} from '../CommonChart';

type Props = {
  scope?: string,
  regions?: any[],
  countries?: any[],
  facGroups?: any[],
  factories?: any[],
  lines?: any[],
  year?: any,
  month?: any,
  expanded?: boolean,
  onAction?: EvtHandler,
};

export const biglColors: any = [
  'purple-700',
  'purple-500',
  'purple-300',
  'purple-100',
  'purple-800',
  'purple-600',
  'purple-400',
  'purple-200',
  'purple-50',
  'purple-900',
].map((c) => getColor(c));

export const minlColors: any = [
  'yellow-700',
  'yellow-500',
  'yellow-300',
  'yellow-100',
  'yellow-800',
  'yellow-600',
  'yellow-400',
  'yellow-200',
  'yellow-50',
  'yellow-900',
].map((c) => getColor(c));

export const minlRmColors: any = [
  'red-700',
  'red-500',
  'red-300',
  'red-100',
  'red-900',
].map((c) => getColor(c));

export const minlRateColors: any = [
  'green-700',
  'green-500',
  'green-300',
  'green-100',
  'green-800',
  'green-600',
  'green-400',
  'green-200',
  'green-50',
  'green-900',
].map((c) => getColor(c));

export const minlVolRateColors: any = [
  'blue-700',
  'blue-500',
  'blue-300',
  'blue-100',
  'blue-800',
  'blue-600',
  'blue-400',
  'blue-200',
  'blue-50',
  'blue-900',
].map((c) => getColor(c));

const chartPlugins = [ChartDataLabels];

let chartOptions = getPieChartOptions();
chartOptions = {
  ...chartOptions,
  events: [],
  plugins: {
    datalabels: {
      formatter: (value, ctx) => {
        return Number(value).toFixed(2) + '%';
      },
      color: 'white',
    },
  },
};
// const scales = {
//   y: {
//     type: 'linear',
//     display: true,
//     position: 'left',
//   },
//   y1: {
//     type: 'linear',
//     display: true,
//     position: 'right',
//     // grid line settings
//     grid: {
//       drawOnChartArea: false, // only want the grid lines for one axis to show up
//     },
//   },
// };
// chartOptions = {
//   ...chartOptions,
//   ...scales,
// };

export function MiniLineVolPieMonthlyFacV2(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  // const {t} = useTranslation();
  const {
    regions,
    countries,
    facGroups,
    factories,
    lines,
    year,
    month,
    expanded,
    onAction,
  } = props;

  const [barData, setBarData] = useState();
  // const [chartOptions] = useState(getChartOptions());

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchData = useCallback(async (options: any) => {
    let {factories, year, month} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let pYear = Number(year) || 0;

    let joins = [
      {
        type: 'join',
        expr: 'obj.factory',
        alias: 'fac',
      },
      {
        type: 'join',
        expr: 'fac.country',
        alias: 'coun',
      },
      {
        type: 'join',
        expr: 'coun.region',
        alias: 'reg',
      },
    ];
    let filters = {
      liveFlag: 'obj.flag > -1',
    };

    let params = {};
    //let groupBys = ['obj.audMonth'];
    // if (month < 1) {
    //   groupBys = ['obj.audYear'];
    // }
    let groupBys = [];
    // groupBys = ['fac.code', ...groupBys];
    let facIds = Utils.getVals(factories || [], 'id', [0]).map((it) => {
      return {
        type: 'long',
        value: it,
      };
    });
    filters = {
      ...filters,
      byFacIds: 'fac.id in (:facIds)',
    };
    params = {
      ...params,
      facIds,
    };

    filters = {
      ...filters,
      year: 'obj.audYear = :year',
    };
    params = {
      ...params,
      year: pYear,
    };
    if (month > 0) {
      filters = {...filters, month: 'obj.audMonth = :month'};
      params = {...params, month};
    }

    let fields = [
      ...groupBys,
      'sum(obj.nbMiniLines * obj.minlCap)',
      'sum(obj.nbBigLines * obj.biglCap)',
      'sum(obj.totalLines)',
    ];
    let sorts = [...groupBys];

    return await api
      .fetchMiniLineAuditData({
        fields,
        joins,
        groupBys,
        filters,
        params,
        sorts,
        start: 0,
        limit: 0,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        // //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        let recs = [...data.list];
        //console.log.*$
        return [...recs];
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  const _fetchChartData = useCallback(
    async (options: any) => {
      let recs = (await _fetchData(options)) || [];
      if (recs.length < 1) {
        return;
      }

      let labels = ['Mini Line %', 'Big Line %'];
      let rec = recs[0];
      let mv = Number(rec[0]);
      let bv = Number(rec[1]);
      let total = mv + bv;
      //console.log.*$
      let mr = Utils.round((mv * 100.0) / total, 2);
      let br = 100 - mr;

      let datasets = [
        {
          data: [mr, br],
          backgroundColor: [minlRateColors[0], minlVolRateColors[0]],
          hoverBackgroundColor: [minlRateColors[1], minlVolRateColors[1]],
        },
      ];

      let chartData = {
        labels,
        datasets,
      };

      setBarData({
        ...chartData,
      });
    },
    [_fetchData],
  );

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchChartData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      // //console.log.*$
      subj.complete();
    };
  }, [_fetchChartData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      regions,
      countries,
      facGroups,
      factories,
      lines,
      year,
      month,
    });
  }, [regions, countries, facGroups, factories, lines, year, month]);

  const _onToggleExpand: EvtHandler = (evt: any) => {
    onAction &&
      onAction({
        type: 'toggleExpand',
        value: !expanded,
      });
  };

  const iconName = expanded
    ? 'pi pi-angle-double-left'
    : 'pi pi-angle-double-right';

  const icons = (
    <Button
      icon={iconName}
      className="p-button-rounded p-button-text"
      style={{padding: 0, height: '1.2rem', color: '#FFFFFF'}}
      onClick={_onToggleExpand}
    />
  );

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="Mini Line % (Volume) - Monthly" icons={icons}>
        <Chart
          type="pie"
          data={barData}
          options={chartOptions}
          plugins={chartPlugins}></Chart>
      </Panel>
    </>
  );
}
