// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
// import {Dialog} from 'primereact/dialog';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {FactoryUserForm} from './FactoryUserForm';
import {FactoryUserList} from './FactoryUserList';

type Props = {
  factory?: any,
};

export function FactoryUser(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {factory} = props;

  const [selFactoryUser, setSelFactoryUser] = useState<any>();
  // const [showCfmDlg, setShowCfmDlg] = useState<any>(false);
  const [reload, setReload] = useState(false);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  // const selFactoryUserRef = useRef();
  // useEffect(() => {
  //   selFactoryUserRef.current = selFactoryUser;
  // }, [selFactoryUser]);

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _onFactoryUserSelected = (evt: any) => {
    // setSelFactoryUser(evt.value);
    //console.log.*$
    setSelFactoryUser(evt.value);
  };

  const _onNewFactoryUser: any = (evt: any) => {
    setSelFactoryUser(null);
  };

  const _onFactoryUserAction = (evt: any) => {
    setSelFactoryUser(null);
    setReload(!reload);
  };

  const _onDelete: (evt: any) => void = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selFactoryUser.id) {
      return;
    }
    confirmDialog({
      message: 'Are you sure you want to delete production line?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .deleteFactoryUser(selFactoryUser.id)
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
            }
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          })
          .finally(() => {
            setSelFactoryUser(null);
            setReload(!reload);
          });
      },
      reject: () => {},
    });
  };

  if (!factory) {
    return null;
  }

  // const cfmDlgFooter = (
  //   <div className="text-right">
  //     <Button label="Yes" icon="pi pi-check" onClick={_onDeletFactoryUser} />
  //     <Button
  //       label="No"
  //       icon="pi pi-times"
  //       onClick={(e) => {
  //         setShowCfmDlg(false);
  //       }}
  //     />
  //   </div>
  // );

  let listTitle = 'Available factory users';

  return (
    <>
      <Toast ref={toastRef} />

      <div className="p-grid">
        <div className="p-col-12 p-lg-8">
          <FactoryUserList
            byFactory={factory}
            header={listTitle}
            reload={reload}
            onSelect={_onFactoryUserSelected}
          />
          <div className="p-d-flex p-jc-end p-my-2">
            <Button
              label="New"
              icon="pi pi-plus"
              className="p-ml-2"
              onClick={_onNewFactoryUser}
            />
            {selFactoryUser && (
              <Button
                label="Delete"
                icon="pi pi-trash"
                className="p-ml-2"
                onClick={_onDelete}
                disabled={selFactoryUser.flag === 1}
              />
            )}
          </div>
        </div>
        <div className="p-col-12 p-lg-4">
          <FactoryUserForm
            factory={factory}
            factoryUsre={selFactoryUser}
            onAction={_onFactoryUserAction}
          />
        </div>
      </div>
      {/*<Dialog
        header="Confirm delete production line"
        footer={cfmDlgFooter}
        visible={showCfmDlg}
        style={{width: '400px'}}
        modal={true}
        onHide={() => {
          setShowCfmDlg(false);
        }}>
        <span>Are you sure to delete selected production line?</span>
      </Dialog>*/}
    </>
  );
}
