// @flow
import React, {useState, useEffect, useRef} from 'react';
//$FlowFixMe[cannot-resolve-module]
//import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {AdminApi} from '../../service/AdminApi';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {UIUtils} from '../../service/Utils';
//import {setFilter} from '../../service/filtermlt/action';
import {SetPointMltList} from './SetPointMltList';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
import {SetPointMltForm} from './SetPointMltForm';
import {AuthUtils} from '../../service/AuthUtils';

import type {ApiProps} from '../../service/Api';

type Props = {};

export function SetPointMlt(props: Props): React$Node {
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const [selMltSetPoint, setSelMltSetPoint] = useState<any>();
  const [reload, setReload] = useState(false);

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _onNewMachLine: any = (evt: any) => {
    setSelMltSetPoint(null);
  };
  const _onMltSetPointSelected = (evt: any) => {
    //console.log.*$
    setSelMltSetPoint(evt.value);
  };
  const _onMachLineAction = (evt: any) => {
    setSelMltSetPoint(null);
    setReload(!reload);
  };

  const _onDelete: (evt: any) => void = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selMltSetPoint.id) {
      return;
    }
    confirmDialog({
      message: 'Are you sure you want to delete filter mlt',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .deleteMltSetPoint(selMltSetPoint.id)
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
            }
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            UIUtils.showError({error, toast: toastRef.current});
          })
          .finally(() => {
            setSelMltSetPoint(null);
            setReload(!reload);
          });
      },
      reject: () => {},
    });
  };

  let canEdit = AuthUtils.isAuth({auth, roles: ['NB_ADMIN']});

  return (
    <>
      <Toast ref={toastRef} />

      <div className="p-grid">
        <div className="p-col-12 p-lg-8">
          <SetPointMltList
            // header={listTitle}
            reload={reload}
            onSelect={_onMltSetPointSelected}
          />
          <div className="p-d-flex p-jc-end p-my-2">
            {canEdit && (
              <>
                <Button
                  label="New filter"
                  icon="pi pi-plus"
                  className="p-ml-2"
                  onClick={_onNewMachLine}
                />
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  className="p-ml-2"
                  onClick={_onDelete}
                />
              </>
            )}
          </div>
        </div>

        <div className="p-col-12 p-lg-4">
          <SetPointMltForm
            readOnly={!canEdit}
            mltSetPoint={selMltSetPoint}
            onAction={_onMachLineAction}
          />
        </div>
      </div>
    </>
  );
}
