// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
// import {Dialog} from 'primereact/dialog';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';

import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
import {AuthUtils} from '../../service/AuthUtils';
import {AuthConst} from '../../service/AppConstant';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

import {FactoryFilter} from '../common/FactoryFilter';

import {ErrorRecForm} from './ErrorRecForm';
import {ErrorRecList} from './ErrorRecList';

type Props = {};

export function ErrorRec(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const [selErrorRec, setSelErrorRec] = useState<any>();
  const [editErrorRec, setEditErrorRec] = useState<any>();
  // const [showDlg, setShowDlg] = useState<any>(false);
  const [reload, setReload] = useState(false);
  const [defSort, setDefSort] = useState([]);
  const [tabInd, setTabInd] = useState(0);
  const [filter, setFilter] = useState({});

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  // const selErrorRecRef = useRef();
  // useEffect(() => {
  //   selErrorRecRef.current = selErrorRec;
  // }, [selErrorRec]);

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let initFil = {};
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      initFil['factory'] = factories[0];
    }
    setFilter(initFil);
  }, [auth]);

  useEffect(() => {
    setDefSort([
      {
        field: 'obj.recDate',
        order: -1,
      },
    ]);
  }, []);

  const _onFilterAction: EvtHandler = (evt: any) => {
    let {type, value} = evt;
    if (type === 'filterChanged') {
      setFilter({...value});
      return;
    }
  };

  const _onErrorRecSelected = (evt: any) => {
    // setSelErrorRec(evt.value);
    // //console.log.*$
    setSelErrorRec(evt.value);
    setEditErrorRec({...evt.value});
  };

  const _onNewErrorRec: any = (evt: any) => {
    setSelErrorRec(null);
    setEditErrorRec({});
    setTabInd(1);
  };

  const _onEditErrorRec: any = (evt: any) => {
    setTabInd(1);
  };

  const _onErrorRecAction = (evt: any) => {
    // setSelErrorRec(null);
    setReload(!reload);
    setTabInd(0);
  };

  const _onDelete: (evt: any) => void = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selErrorRec.id) {
      return;
    }
    confirmDialog({
      message: 'Are you sure you want to delete error record?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .deleteErrorRec(selErrorRec.id)
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
            }
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          })
          .finally(() => {
            setSelErrorRec(null);
            setReload(!reload);
          });
      },
      reject: () => {},
    });
  };

  // const dlgFooter = (
  //   <div className="text-right">
  //     <Button label="Yes" icon="pi pi-check" />
  //     <Button
  //       label="No"
  //       icon="pi pi-times"
  //       onClick={(e) => {
  //         setShowDlg(false);
  //       }}
  //     />
  //   </div>
  // );

  // let listTitle = 'Available production records';
  let authLevel = AuthUtils.getAuthLevel({auth});
  let canEdit =
    authLevel < AuthConst.AUTH_LVL_NB ||
    AuthUtils.isAuth({auth, roles: ['FACTORY_ADMIN', 'FACTORY_EDITOR']});

  return (
    <>
      <Toast ref={toastRef} />

      <TabView activeIndex={tabInd} onTabChange={(e) => setTabInd(e.index)}>
        <TabPanel header="Error records">
          <FactoryFilter
            scope="factory"
            filter={filter}
            showFactory={true}
            showLine={true}
            onAction={_onFilterAction}
          />
          {/*<ErrorRecFilter filter={filter} onAction={_onFilterAction} />*/}
          <ErrorRecList
            byFactory={filter.factory}
            byLine={filter.line}
            byLines={filter.lines}
            defSort={defSort}
            reload={reload}
            selection={selErrorRec}
            onSelect={_onErrorRecSelected}
          />
          {canEdit && (
            <div className="p-d-flex p-jc-end p-my-2">
              <Button
                label="New"
                icon="pi pi-plus"
                className="p-ml-2"
                onClick={_onNewErrorRec}
              />
              {selErrorRec && (
                <Button
                  label="Edit"
                  icon="pi pi-pencil"
                  className="p-ml-2"
                  onClick={_onEditErrorRec}
                  disabled={selErrorRec.flag === 1}
                />
              )}
              {selErrorRec && (
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  className="p-ml-2"
                  onClick={_onDelete}
                  disabled={selErrorRec.flag === 1}
                />
              )}
            </div>
          )}
        </TabPanel>
        <TabPanel header="Edit">
          {canEdit && (
            <ErrorRecForm
              errorRec={editErrorRec}
              filter={filter}
              onAction={_onErrorRecAction}
            />
          )}
        </TabPanel>
      </TabView>
    </>
  );
}
