// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';
//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  byId?: any,
  byFactory?: any,
  byLine?: any,
  byLines?: any[],
  byLiveFlag?: number,
  defSort?: any[],
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  byYear?: number,
  byMonth?: number,
  command?: CommandType,
  onSelect?: (evt: any) => void,
  onAction?: (evt: any) => void,
};

export function MltRecList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  // const app = useSelector((state) => state.app);
  // const {t} = useTranslation();
  const [mltRecsTblData, setMltRecsTblData] = useState([]);
  const [mltRecsTblStart, setMltRecsTblStart] = useState(0);
  const [mltRecsTblLimit, setMltRecsTblLimit] = useState(15);
  const [mltRecsTblNbRows, setMltRecsTblNbRows] = useState(0);
  const [selMltRec, setSelMltRec] = useState();
  const [selMltRecs, setSelMltRecs] = useState([]);
  const [sortMeta, setSortMeta] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);

  const {
    byFactory,
    byLine,
    byLines,
    byLiveFlag,
    defSort,
    selection,
    reload,
    header,
    command,
    byId,
    byMonth,
    byYear,
  } = props;
  const selMode = props.selectionMode || 'single';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  const _fetchData = useCallback((options) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let {
      byFactory,
      byLine,
      byLines,
      byLiveFlag,
      mltRecsTblStart,
      mltRecsTblLimit,
      sortMeta,
      byId,
      byMonth,
      byYear,
    } = options;

    let joins = [
      // {
      //   type: 'left join',
      //   expr: 'obj.factory',
      //   alias: 'factory',
      // },
      // {
      //   type: 'left join',
      //   expr: 'obj.line',
      //   alias: 'line',
      // },
    ];

    let filters = {};
    let params = {};
    if (byFactory && byFactory.id) {
      filters = {
        ...filters,
        byFactory: 'obj.factory.id = :factoryId',
      };
      params = {
        ...params,
        factoryId: {
          type: 'long',
          value: byFactory.id,
        },
      };
    }

    if (byLine) {
      filters = {
        ...filters,
        byLine: 'obj.lineId = :lineId',
      };
      params = {
        ...params,
        lineId: {
          type: 'long',
          value: byLine.id,
        },
      };
    }

    if (byMonth > 0) {
      filters = {
        ...filters,
        byMonth: 'obj.siMonth = :byMonth',
      };

      params = {
        ...params,
        byMonth: Number(byMonth),
      };
    }

    if (byYear > 0) {
      filters = {
        ...filters,
        byLine: 'obj.siYear = :byYear',
      };

      params = {
        ...params,
        byYear: Number(byYear),
      };
    }
    //console.log.*$

    if (byLines && byLines.length > 0) {
      filters = {
        ...filters,
        byLines: 'obj.lineId in (:lineIds)',
      };
      let lineIds = byLines.map((it) => {
        return {
          type: 'long',
          value: it.id,
        };
      });
      params = {
        ...params,
        lineIds,
      };
    }

    if (byId && byId !== undefined) {
      filters = {
        ...filters,
        byId: 'obj.id = :byId',
      };
      params = {
        ...params,
        byId: {
          type: 'long',
          value: Number(byId),
        },
      };
    }

    if (byLiveFlag !== undefined) {
      filters = {
        ...filters,
        liveFlag: 'obj.flag = :liveFlag',
      };
      params = {
        ...params,
        liveFlag: byLiveFlag,
      };
    }

    let start = mltRecsTblStart;
    let limit = mltRecsTblLimit;

    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });
    //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);

    api
      .getMltRecs({joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        const {list, factoryMap, lineMap, operatorMap, shiftMap} = data;
        list.forEach((rec) => {
          if (rec.factoryId) {
            rec['factory'] = factoryMap[rec.factoryId];
          }
          if (rec.lineId) {
            rec['line'] = lineMap[rec.lineId];
          }
          if (rec.shiftId) {
            rec['shift'] = shiftMap[rec.shiftId];
          }
          if (rec.operatorId) {
            rec['operator'] = operatorMap[rec.operatorId];
          }
        });
        // //console.log.*$
        setMltRecsTblData([...data.list]);
        setMltRecsTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(400)).subscribe({
      next: (data) => {
        _fetchData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      subj.complete();
    };
  }, [_fetchData]);

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    if (defSort && defSort.length > 0) {
      setSortMeta([...defSort]);
      setSortField(defSort[0].field);
      setSortOrder(defSort[0].order);
    }
  }, [defSort]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelMltRec({...selection});
      } else {
        setSelMltRecs([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelMltRec(null);
      } else {
        setSelMltRecs([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    // //console.log.*$
    // //console.log.*$
    let fetchDataSubj: any = fetchDataEvtRef.current;
    if (!fetchDataSubj) {
      return;
    }
    fetchDataSubj.next({
      byId,
      byFactory,
      byLine,
      byLines,
      byLiveFlag,
      mltRecsTblStart,
      mltRecsTblLimit,
      sortMeta,
      byMonth,
      byYear,
    });
  }, [
    byId,
    reload,
    byFactory,
    byLine,
    byLines,
    byLiveFlag,
    mltRecsTblStart,
    mltRecsTblLimit,
    sortMeta,
    byMonth,
    byYear,
  ]);

  const _onMltRecSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelMltRec(evt.value);
    } else {
      setSelMltRecs(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    // //console.log.*$
    setMltRecsTblStart(evt.first);
    setMltRecsTblLimit(evt.rows);
  };

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
    let {onAction} = props;
    onAction &&
      onAction({
        type: 'sortChanged',
        value: [...newMeta],
      });
  };

  // const _renderPoTypeCol: TblColRender = (row: any, col: any) => {
  //   let {poType} = row;
  //   return poType ? poType.code : null;
  // };

  // const _renderRegionCol: TblColRender = (row: any, col: any) => {
  //   let {region} = row;
  //   return region ? `${region.code}` : null;
  // };

  // const _renderMarketCol: TblColRender = (row: any, col: any) => {
  //   let {market} = row;
  //   return market ? `${market.code}` : null;
  // };

  const _renderProdTypeCol: TblColRender = (row: any, col: any) => {
    let {prodType} = row;
    return prodType ? `${prodType.code}` : null;
  };

  // const _renderPartStatusCol: TblColRender = (row: any, col: any) => {
  //   let {partStatus} = row;
  //   return partStatus ? `${partStatus.code}` : null;
  // };

  // const _renderShipModeCol: TblColRender = (row: any, col: any) => {
  //   let {shipMode} = row;
  //   return shipMode ? `${shipMode.code}` : null;
  // };

  // const _renderPoReleaseDateCol: TblColRender = (row: any, col: any) => {
  //   let {poReleaseDate} = row;
  //   return poReleaseDate ? Utils.reFmtDate(poReleaseDate, 'YYYY-MM-DD') : null;
  // };

  // const _renderOrigReqXfdCol: TblColRender = (row: any, col: any) => {
  //   let {origReqXfd} = row;
  //   return origReqXfd ? Utils.reFmtDate(origReqXfd, 'YYYY-MM-DD') : null;
  // };

  // const _renderOrigCfmXfdCol: TblColRender = (row: any, col: any) => {
  //   let {origCfmXfd} = row;
  //   return origCfmXfd ? Utils.reFmtDate(origCfmXfd, 'YYYY-MM-DD') : null;
  // };

  // const _renderActXfdCol: TblColRender = (row: any, col: any) => {
  //   let {actXfd} = row;
  //   return actXfd ? Utils.reFmtDate(actXfd, 'YYYY-MM-DD') : null;
  // };

  const _renderStartProdDateCol: TblColRender = (row: any, col: any) => {
    let {startProdDate} = row;
    return startProdDate ? Utils.reFmtDate(startProdDate, 'YYYY-MM-DD') : null;
  };

  // const _renderEndProdDateCol: TblColRender = (row: any, col: any) => {
  //   let {endProdDate} = row;
  //   return endProdDate ? Utils.reFmtDate(endProdDate, 'YYYY-MM-DD') : null;
  // };

  const _renderSiDateCol: TblColRender = (row: any, col: any) => {
    let {siDate} = row;
    return siDate ? Utils.reFmtDate(siDate, 'YYYY-MM-DD') : null;
  };

  const _renderCrCol: TblColRender = (row: any, col: any) => {
    let {createdAt} = row;
    return createdAt ? Utils.reFmtDate(createdAt, 'DD/MM/YY HH:mm') : null;
  };

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header}
          value={mltRecsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={mltRecsTblLimit}
          first={mltRecsTblStart}
          totalRecords={mltRecsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selMltRec}
          onSelectionChange={_onMltRecSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            body={_renderProdTypeCol}
            header="Product Type"
            headerStyle={{width: 170}}
            sortable
            sortField="prodType.code"
          />

          <Column
            field="poNo"
            header="PO No."
            headerStyle={{width: 100}}
            sortable
            sortField="obj.poNo"
          />

          <Column
            field="facCode"
            header="Vendor"
            headerStyle={{width: 100}}
            sortable
            sortField="obj.facCode"
          />

          <Column
            field="partNo"
            header="Style /Part No."
            headerStyle={{width: 150}}
            sortable
            sortField="obj.partNo"
          />

          <Column
            field="lineNo"
            header="Line No."
            headerStyle={{width: 70}}
            sortable
            sortField="obj.lineNo"
          />

          <Column
            field="qty"
            header="Quantity"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 100}}
            sortable
            sortField="obj.qty"
          />

          <Column
            body={_renderStartProdDateCol}
            header="Start Prod Date"
            headerStyle={{width: 100}}
            sortable
            sortField="obj.startProdDate"
          />

          <Column
            body={_renderSiDateCol}
            header="SI Date"
            headerStyle={{width: 100}}
            sortable
            sortField="obj.siDate"
          />

          <Column
            field="mlt"
            header="MLT"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 80}}
            sortable
            sortField="obj.mlt"
          />

          <Column
            body={_renderCrCol}
            header="Created On"
            headerStyle={{width: 120}}
            sortable
            sortField="obj.createdAt"
          />

          <Column
            field="createdBy"
            header="Created By"
            headerStyle={{width: 100}}
          />

          <Column
            field="id"
            header="#"
            headerStyle={{width: 60}}
            headerClassName="text-end"
            bodyClassName="text-end"
          />
        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header}
          value={mltRecsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={mltRecsTblLimit}
          first={mltRecsTblStart}
          totalRecords={mltRecsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selMltRecs}
          onSelectionChange={_onMltRecSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            selectionMode="multiple"
            headerStyle={{width: 50}}
            headerClassName="text-left"
            bodyClassName="text-left"></Column>

          <Column
            body={_renderProdTypeCol}
            header="Product Type"
            headerStyle={{width: 170}}
            sortable
            sortField="prodType.code"
          />

          <Column
            field="poNo"
            header="PO No."
            headerStyle={{width: 100}}
            sortable
            sortField="obj.poNo"
          />

          <Column
            field="facCode"
            header="Vendor"
            headerStyle={{width: 100}}
            sortable
            sortField="obj.facCode"
          />

          <Column
            field="partNo"
            header="Style /Part No."
            headerStyle={{width: 150}}
            sortable
            sortField="obj.partNo"
          />

          <Column
            field="lineNo"
            header="Line No."
            headerStyle={{width: 70}}
            sortable
            sortField="obj.lineNo"
          />

          <Column
            field="qty"
            header="Quantity"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 100}}
            sortable
            sortField="obj.qty"
          />

          <Column
            body={_renderStartProdDateCol}
            header="Start Prod Date"
            headerStyle={{width: 100}}
            sortable
            sortField="obj.startProdDate"
          />

          <Column
            body={_renderSiDateCol}
            header="SI Date"
            headerStyle={{width: 100}}
            sortable
            sortField="obj.siDate"
          />

          <Column
            field="mlt"
            header="MLT"
            headerClassName="text-end"
            bodyClassName="text-end"
            headerStyle={{width: 80}}
            sortable
            sortField="obj.mlt"
          />

          <Column
            body={_renderCrCol}
            header="Created On"
            headerStyle={{width: 120}}
            sortable
            sortField="obj.createdAt"
          />

          <Column
            field="createdBy"
            header="Created By"
            headerStyle={{width: 100}}
          />

          <Column
            field="id"
            header="#"
            headerStyle={{width: 60}}
            headerClassName="text-end"
            bodyClassName="text-end"
          />
        </DataTable>
      )}
    </>
  );
}
