// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
import type {ApiProps} from '../../service/Api';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
//$FlowFixMe[cannot-resolve-module]
import {InputNumber} from 'primereact/inputnumber';


type Props = {
  readOnly?: boolean,
  andonDetail: any,
  audit:any,
  onAction: (evt: any) => void,
};

const defFollowUpItem = {
  __cls__: 'com.nb.lmrs.model.entity.ManufacturingInnovation',
  flag: 0,
 result: null,
  weighting: null,
    target: null,
    score: null,
  code: '',
  name: '',
  pfaAuditGroup:null,

  description: '',

};

export function ManufacturingInnovationForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {readOnly, andonDetail, audit} = props;
  const [editFollowUpItem, setEditFollowUpItem] = useState<any>();
  console.log(audit);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);


  useEffect(() => {
    let curAndonDetail = andonDetail || {};
    curAndonDetail = {
      ...defFollowUpItem,
      ...curAndonDetail,
    };
    curAndonDetail = Utils.adjustPercField(
        curAndonDetail,
        ['weighting'],
        true,
      );

    let isNew = !curAndonDetail.id;
    if (isNew && (!curAndonDetail.factory || !curAndonDetail.factory.id)) {
      //curAndonDetail['factory'] = defFactory;
    }

    setEditFollowUpItem(curAndonDetail);
  }, [andonDetail]);


  const _onSave = (evt: any) => {
    let fac = _validateFollowUpItem();
    //console.log('fac', fac);
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update item?'
        : 'Are you sure you want to create item?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateManufacturingInnovation({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            console.log(data);
            if (errors.length > 0) {
              //console.log.*$
              let {onAction} = props;
            onAction &&
              onAction({
                type: 'machLineSaved',
                value: data.entity,
              });
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'machLineSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _validateFollowUpItem: () => any = () => {
    console.log(audit);
    let obj = {...editFollowUpItem,pfaAuditGroup:audit};
    // obj = {
    //     ...obj,
    //     pfaAudit: audit,
    // }
    obj = Utils.adjustPercField(
        obj,
        ['weighting', 'target', 'actual', 'perf', 'finalScore'],
        false,
    );

    return obj;
  };

  if (!editFollowUpItem) {
    return null;
  }

  const _processValueChanged: (fd: string, val: any) => any = (
    fd: string,
    val: any,
  ) => {
    let newIt = {...editFollowUpItem};
    newIt[fd] = val;


    setEditFollowUpItem(newIt);
  };
  const saveLbl = editFollowUpItem.id
    ? 'Update item'
    : 'Create item';
  //const isNew = !editFollowUpItem.id;

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/edit item">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editFollowUpItem.name}
                onChange={(e) => {
                  setEditFollowUpItem({
                    ...editFollowUpItem,
                    name : e.target.value,
                  });
                }}
              />
              <label>Item</label>
            </span>
          </div>


          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputNumber
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                value={editFollowUpItem.weighting}
                disabled={false}
                onChange={(e) => {
                    _processValueChanged('weighting', e.value);
                }}
              />
                <label>Weighting (%)</label>

                 </span>
            </div>
            <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputText
                value={editFollowUpItem.target}
                disabled={false}
                onChange={(e) => {
                   _processValueChanged('target', e.target.value);
                }}
              />
                <label>Target</label>
           </span>
            </div>

            <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputText
                value={editFollowUpItem.result}
                disabled={false}
                onChange={(e) => {
                   _processValueChanged('result', e.target.value);
                }}
              />
                <label>Result</label>
           </span>
            </div>

            <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputNumber
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                value={editFollowUpItem.score}
                disabled={false}
                onChange={(e) => {
                    _processValueChanged('score', e.value);
                }}
              />
                <label>Score</label>

                 </span>
            </div>



          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputTextarea
                  rows={2}
                  value={editFollowUpItem.description}
                  onChange={(e) => {
                    setEditFollowUpItem({
                      ...editFollowUpItem,
                      description: e.target.value,
                    });
                  }}
                  disabled={readOnly}
                />
                <label>Description</label>
              </span>
            </div>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
