// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {Dialog} from 'primereact/dialog';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  userGrp: any,
  onAction: (evt: any) => void,
};

const defUserGrp = {
  __cls__: 'com.nb.lmrs.model.entity.UserGroup',
  flag: 0,
  name: '',
  description: '',
  active: false,
};

export function UserGrpForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {userGrp} = props;
  const [editUserGrp, setEditUserGrp] = useState<any>();
  const [showCfmDlg, setShowCfmDlg] = useState<any>(false);

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
    let pf = {
      ...defUserGrp,
      ...userGrp,
    };
    setEditUserGrp(pf);
  }, [userGrp]);

  const _onSave = (evt: any) => {
    setShowCfmDlg(false);
    let saveUserGrp = _validateUserGrp();
    if (!saveUserGrp) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    //console.log.*$
    api
      .updateUserGrp({...saveUserGrp})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        let {onAction} = props;
        onAction &&
          onAction({
            type: 'userGrpSaved',
            value: data.entity,
          });
        UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
      });
  };

  const _validateUserGrp = (): any => {
    //console.log.*$
    let res = {
      ...editUserGrp,
    };

    return res;
  };

  const cfmDlgFooter = (
    <div className="text-right">
      <Button label="Yes" icon="pi-md-check" onClick={_onSave} />
      <Button
        label="No"
        icon="pi-md-cancel"
        onClick={(e) => {
          setShowCfmDlg(false);
        }}
      />
    </div>
  );

  if (!editUserGrp) {
    return null;
  }

  const saveLbl = editUserGrp.id ? 'Update group' : 'Create group';
  const isEdit = !!editUserGrp.id;
  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/Update group">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  value={editUserGrp.name}
                  onChange={(e) => {
                    setEditUserGrp({
                      ...editUserGrp,
                      name: e.target.value,
                    });
                  }}
                  disabled={isEdit}
                />
                <label>Group name</label>
              </span>
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputTextarea
                  rows={2}
                  value={editUserGrp.description}
                  onChange={(e) => {
                    setEditUserGrp({
                      ...editUserGrp,
                      description: e.target.value,
                    });
                  }}
                />
                <label>Description</label>
              </span>
            </div>
          </div>
        </div>
      </Panel>

      <div className="p-d-flex p-jc-end p-my-2">
        <Button
          label={saveLbl}
          icon="pi pi-save"
          onClick={(e) => {
            if (!_validateUserGrp()) {
              return;
            }
            setShowCfmDlg(true);
          }}
        />
      </div>
      <Dialog
        header="Confirm save group"
        footer={cfmDlgFooter}
        visible={showCfmDlg}
        style={{width: '400px'}}
        modal={true}
        onHide={() => {
          setShowCfmDlg(false);
        }}>
        {editUserGrp.id && <span>Are you sure to update user group?</span>}
        {!editUserGrp.id && <span>Are you sure to create group?</span>}
      </Dialog>
    </>
  );
}
