// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
import {Subject} from 'rxjs';
//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function AudMeasureList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const {t} = useTranslation();
  const [audMeasuresTblData, setAudMeasuresTblData] = useState([]);
  const [audMeasuresTblStart, setAudMeasuresTblStart] = useState(0);
  const [audMeasuresTblLimit] = useState(10);
  const [audMeasuresTblNbRows, setAudMeasuresTblNbRows] = useState(0);
  const [selAudMeasure, setSelAudMeasure] = useState();
  const [selAudMeasures, setSelAudMeasures] = useState([]);
  const [sumWeight, setSumWeight] = useState(0);
  const [sortMeta, setSortMeta] = useState([
    {
      field: 'obj.idNo',
      order: 1,
    },
  ]);
  const [sortField, setSortField] = useState('obj.idNo');
  const [sortOrder, setSortOrder] = useState(1);

  const {reload, selection, header, command} = props;
  const selMode = props.selectionMode || 'single';
  const {search} = app || '';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  const _fetchData = useCallback(async (options) => {
    // //console.log.*$
    // //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let {search, audMeasuresTblStart, audMeasuresTblLimit, sortMeta} = options;
    let start = audMeasuresTblStart;
    let limit = audMeasuresTblLimit;
    let joins = [];
    let filters = {};
    let params = {
      __sumWeight: 1,
    };

    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });
    //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);

    api
      .getAudMeasures({search, joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        // //console.log.*$
        setAudMeasuresTblData([...data.list]);
        setAudMeasuresTblNbRows(data.count);
        let sw = data.sumWeight || 0;
        setSumWeight(sw);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    let subj = new Subject();
    //console.log.*$
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      //console.log.*$
      subj.complete();
    };
  }, [_fetchData]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelAudMeasure({...selection});
      } else {
        setSelAudMeasures([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelAudMeasure(null);
      } else {
        setSelAudMeasures([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      search,
      audMeasuresTblStart,
      audMeasuresTblLimit,
      sortMeta,
    });
  }, [search, audMeasuresTblStart, audMeasuresTblLimit, reload, sortMeta]);

  const _onAudMeasureSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelAudMeasure(evt.value);
    } else {
      setSelAudMeasures(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    // //console.log.*$
    setAudMeasuresTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };

  const _renderActiveCol: TblColRender = (row, col) => {
    let {active} = row;
    return `${active ? 'T' : 'F'}`;
  };

  const _renderWeightCol: TblColRender = (row, col) => {
    let {weight} = row;
    let w = Utils.isNum(weight) ? Number(weight) : 0;
    w = w * 100;
    return `${Utils.fmtDecimal(w, 2)}%`;
  };

  /* const _renderC2bValCol: TblColRender = (row, col) => {
    let {c2bVal} = row;
    let v = Utils.isNum(c2bVal) ? Number(c2bVal) : 0;
    // v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}`;
  };*/

  const _renderC2bWgCol: TblColRender = (row, col) => {
    let {c2bWg} = row;
    let v = Utils.isNum(c2bWg) ? Number(c2bWg) : 0;
    v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}%`;
  };

  /*const _renderSfValCol: TblColRender = (row, col) => {
    let {sfVal} = row;
    let v = Utils.isNum(sfVal) ? Number(sfVal) : 0;
    // v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}`;
  };*/

  const _renderSfWgCol: TblColRender = (row, col) => {
    let {sfWg} = row;
    let v = Utils.isNum(sfWg) ? Number(sfWg) : 0;
    v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}%`;
  };

  /* const _renderOfflineValCol: TblColRender = (row, col) => {
    let {offlineVal} = row;
    let v = Utils.isNum(offlineVal) ? Number(offlineVal) : 0;
    // v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}`;
  };*/

  const _renderOfflineWgCol: TblColRender = (row, col) => {
    let {offlineWg} = row;
    let v = Utils.isNum(offlineWg) ? Number(offlineWg) : 0;
    v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}%`;
  };

  /* const _renderOsPressValCol: TblColRender = (row, col) => {
    let {osPressVal} = row;
    let v = Utils.isNum(osPressVal) ? Number(osPressVal) : 0;
    // v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}`;
  };*/

  const _renderOsPressWgCol: TblColRender = (row, col) => {
    let {osPressWg} = row;
    let v = Utils.isNum(osPressWg) ? Number(osPressWg) : 0;
    v = v * 100;
    return `${Utils.fmtDecimal(v, 2)}%`;
  };

  let pagingLeft = <span></span>;
  let pagingRight = (
    <span>Total weight: {Utils.fmtDecimal(sumWeight * 100, 2)}%</span>
  );

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={audMeasuresTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          paginatorLeft={pagingLeft}
          paginatorRight={pagingRight}
          rows={audMeasuresTblLimit}
          first={audMeasuresTblStart}
          totalRecords={audMeasuresTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selAudMeasure}
          onSelectionChange={_onAudMeasureSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          {/* <Column
            field="code"
            header="Code"
            headerStyle={{width: 150}}
            sortable={true}
            sortField="obj.code"
          /> */}
          <Column
            field="name"
            header="Name"
            headerStyle={{width: 350}}
            sortable
            sortField="obj.name"
          />
          <Column
            body={_renderWeightCol}
            header="Weight"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
            sortable={true}
            sortField="obj.weight"
          />
          <Column
            field="idNo"
            header="Sort"
            headerStyle={{width: 90}}
            sortable={true}
            sortField="obj.idNo"
          />
          {/*   <Column
            body={_renderC2bValCol}
            header="C2B"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
       /> */}
          <Column
            body={_renderC2bWgCol}
            header="C2B %"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          {/* <Column
            body={_renderSfValCol}
            header="SF"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
        /> */}
          <Column
            body={_renderSfWgCol}
            header="SF %"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          {/*  <Column
            body={_renderOfflineValCol}
            header="Offline"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
        /> */}
          <Column
            body={_renderOfflineWgCol}
            header="Offline %"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          {/* <Column
            body={_renderOsPressValCol}
            header="Outsole"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
         /> */}
          <Column
            body={_renderOsPressWgCol}
            header="Outsole %"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
          />
          <Column
            body={_renderActiveCol}
            header="Active"
            headerStyle={{width: 90}}
            sortable={true}
            sortField="obj.active"
          />
          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={audMeasuresTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={audMeasuresTblLimit}
          first={audMeasuresTblStart}
          totalRecords={audMeasuresTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selAudMeasures}
          onSelectionChange={_onAudMeasureSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            selectionMode="multiple"
            headerStyle={{width: 50}}
            headerClassName="text-left"
            bodyClassName="text-left"></Column>
          <Column
            field="code"
            header="Code"
            headerStyle={{width: 150}}
            sortable={true}
            sortField="obj.code"
          />
          <Column
            field="name"
            header="Name"
            headerStyle={{width: 350}}
            sortable
            sortField="obj.name"
          />
          <Column
            body={_renderWeightCol}
            header="Weight"
            headerStyle={{width: 90}}
            headerClassName="text-right"
            bodyClassName="text-right"
            sortable={true}
            sortField="obj.weight"
          />
          <Column
            body={_renderActiveCol}
            header="Active"
            headerStyle={{width: 90}}
            sortable={true}
            sortField="obj.active"
          />
          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />

          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
        </DataTable>
      )}
    </>
  );
}
