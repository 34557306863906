// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';

//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';
//$FlowFixMe[cannot-resolve-module];
import type {ApiProps} from '../../service/Api';
import {Utils, UIUtils} from '../../service/Utils';

import type {TblColRender} from '../types';
import {AdminApi} from '../../service/AdminApi';
import IsLoading from '../common/Loading';
type Props = {
  filter: any,
};

export function PphReport(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const [pphReportTblDataByCountry, setPphReportTblDataByCountry] = useState(
    [],
  );
  const [pphReportTblDataByRegion, setPphReportTblDataByRegion] = useState([]);
  const [pphReportTblData, setPphReportTblData] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const {filter} = props;
  // console.log(filter);
  useEffect(() => {
    // console.log('effect // init api');
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchDataPph = useCallback((options) => {
    setIsLoading(true);
    let {years, month} = options;
    console.log('fetchDataPph', month);
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let dataReq = {};
    if (years && years.length > 0) {
      dataReq = {
        ...dataReq,
        year: years[years.length - 1],
      };
    }

    if (month > 0) {
      dataReq = {
        ...dataReq,
        month: month,
      };
    }
    //  console.log(dataReq);

    api
      .getPphRoll6Month({data: dataReq})
      .then((resp) => resp.data)
      .then((resp) => {
        let {data, errors} = resp;
        if (errors.length > 0) {
          console.log('updateMachLine // return errors', errors);
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        setPphReportTblData([...data.factory]);
        setPphReportTblDataByCountry([...data.country]);
        setPphReportTblDataByRegion([...data.region]);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    let {years, month} = filter;
    years = years.length > 0 ? years : [2000];
    // console.log(filter);
    years = years.map((it) => Number(it)).filter((it) => !isNaN(it));
    let cy = years[years.length - 1];
    years = [cy - 1, cy];
    if (month && years && years.length > 0) {
      _fetchDataPph({years: years, scope: 'factory', month: month});
    }
    // _fetchDataPph({years:years,scope:'country'});
    // _fetchDataPph({years:years,scope:'region'});
    //setIsLoading(false);
  }, [filter, _fetchDataPph]);

  const _onInvsPaging = (evt: any) => {
    //  console.log('Paging event', evt);
    // setetrRecsTblLimit(evt.rows);
    // setEtrRecsTblStart(evt.first);
  };

  const _renderFactory: TblColRender = (row: any, col: any) => {
    let {fac} = row;
    return fac ? fac.code : '';
  };

  const _renderCountry: TblColRender = (row: any, col: any) => {
    let {coun} = row;
    return coun ? coun.code : '';
  };

  const _renderRegion: TblColRender = (row: any, col: any) => {
    let {reg} = row;
    return reg ? reg.code : '';
  };

  const _renderPphQ1: TblColRender = (row: any, col: any) => {
    let {pphQ1} = row;
    return pphQ1 ? Utils.round(pphQ1) : 0;
  };

  const _renderPphQ2: TblColRender = (row: any, col: any) => {
    let {pphQ2} = row;
    return pphQ2 ? Utils.round(pphQ2) : 0;
  };

  const _renderPphQ3: TblColRender = (row: any, col: any) => {
    let {pphQ3} = row;
    return pphQ3 ? Utils.round(pphQ3) : 0;
  };

  const _renderPphQ4: TblColRender = (row: any, col: any) => {
    let {pphQ4} = row;

    return pphQ4 ? Utils.round(pphQ4) : 0;
  };

  const _renderYtd: TblColRender = (row: any, col: any) => {
    let {ytd} = row;

    return ytd ? Utils.round(ytd) : 0;
  };

  const onSort = (e) => {
    let {sortField, sortOrder} = e;
    if (sortOrder === 1) {
      setPphReportTblData(
        [...pphReportTblData].sort((a, b) => a[sortField] - b[sortField]),
      );
    } else {
      setPphReportTblData(
        [...pphReportTblData].sort((a, b) => b[sortField] - a[sortField]),
      );
    }
    setSortField(sortField);
    setSortOrder(sortOrder);
  };

  //console.log(pphReportTblData);
  pphReportTblData.forEach((dt) => console.log(dt.pphQ3));

  const isShowColumn = (data, qoy) => {
    let obj = {};
    if (qoy === 1) {
      if (data.every((dt) => dt.pphQ1 === null || dt.pphQ1 === 0)) {
        obj = {
          ...obj,
          display: 'none',
        };
        return obj;
      }
    }

    if (qoy === 2) {
      if (data.every((dt) => dt.pphQ2 === null || dt.pphQ2 === 0)) {
        obj = {
          ...obj,
          display: 'none',
        };
        return obj;
      }
    }

    if (qoy === 3) {
      if (data.every((dt) => dt.pphQ3 === null || dt.pphQ3 === 0)) {
        obj = {
          ...obj,
          display: 'none',
        };
        return obj;
      }
    }

    if (qoy === 4) {
      if (data.every((dt) => dt.pphQ4 === null || dt.pphQ4 === 0)) {
        obj = {
          ...obj,
          display: 'none',
        };
        return obj;
      }
    }

    if (qoy === -1) {
      if (data.every((dt) => dt.ytd === null || dt.ytd === 0)) {
        obj = {
          ...obj,
          display: 'none',
        };
        return obj;
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <IsLoading /> // Render the IsLoading component while isLoading is true
      ) : (
        <>
          <Toast ref={toastRef} />
          <h2>By Factory</h2>
          <DataTable
            className="pphroll"
            value={pphReportTblData}
            // headerColumnGroup={headerGroup}
            //   footerColumnGroup={footerGroup}
            dataKey="fac"
            // lazy={true}
            // paginator={true}
            //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            //  rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
            //  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            //  first={etrRecsTblStart}
            showGridlines
            // rows={etrRecsTblLimit}
            // totalRecords={ertRecsTblNbRows}
            resizableColumns={true}
            columnResizeMode="expand"
            selectionMode="single"
            onPage={_onInvsPaging}
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}>
            <Column body={_renderFactory} header="Factory"></Column>

            <Column
              field="pphQ1"
              header="Q1 Weighted Average 6 months roll"
              body={_renderPphQ1}
              sortable
              style={isShowColumn(pphReportTblData, 1)}></Column>

            <Column
              field="pphQ2"
              header="Q2 Weighted Average 6 months roll"
              body={_renderPphQ2}
              sortable
              style={isShowColumn(pphReportTblData, 2)}></Column>

            <Column
              field="pphQ3"
              header="Q3 Weighted Average 6 months roll"
              body={_renderPphQ3}
              sortable
              style={isShowColumn(pphReportTblData, 3)}></Column>

            <Column
              field="pphQ4"
              header="Q4 Weighted Average 6 months roll"
              body={_renderPphQ4}
              sortable
              style={isShowColumn(pphReportTblData, 4)}></Column>

            <Column
              field="ytd"
              header="YTD - 6 months roll"
              body={_renderYtd}
              sortable
              style={isShowColumn(pphReportTblData, -1)}></Column>
          </DataTable>

          <h2>By Country</h2>

          <DataTable
            value={pphReportTblDataByCountry}
            dataKey="id"
            // lazy={true}
            //  paginator={true}
            //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            //  rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
            //  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            //  first={etrRecsTblStart}
            showGridlines
            // rows={etrRecsTblLimit}
            // totalRecords={ertRecsTblNbRows}
            resizableColumns={true}
            columnResizeMode="expand"
            selectionMode="single"
            onPage={_onInvsPaging}
            responsiveLayout="scroll">
            <Column body={_renderCountry} header="Country"></Column>

            <Column
              field="pphQ1"
              header="Q1"
              style={isShowColumn(pphReportTblDataByCountry, 1)}
              body={_renderPphQ1}></Column>

            <Column
              field="pphQ2"
              header="Q2"
              body={_renderPphQ2}
              style={isShowColumn(pphReportTblDataByCountry, 2)}></Column>

            <Column
              field="pphQ3"
              header="Q3"
              body={_renderPphQ3}
              style={isShowColumn(pphReportTblDataByCountry, 3)}></Column>

            <Column
              field="pphQ4"
              header="Q4"
              body={_renderPphQ4}
              style={isShowColumn(pphReportTblDataByCountry, 4)}></Column>

            <Column
              field="ytd"
              header="YTD - 6 months roll"
              body={_renderYtd}
              style={isShowColumn(pphReportTblDataByCountry, -1)}></Column>
          </DataTable>

          <h2>By Region</h2>
          <DataTable
            value={pphReportTblDataByRegion}
            dataKey="id"
            //  lazy={true}
            // paginator={true}
            //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            //  rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
            //  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            //  first={etrRecsTblStart}
            showGridlines
            // rows={etrRecsTblLimit}
            // totalRecords={ertRecsTblNbRows}
            resizableColumns={true}
            columnResizeMode="expand"
            selectionMode="single"
            onPage={_onInvsPaging}
            responsiveLayout="scroll">
            <Column body={_renderRegion} header="Region" />

            <Column
              field="pphQ1"
              header="Q1"
              body={_renderPphQ1}
              style={isShowColumn(pphReportTblDataByRegion, 1)}></Column>

            <Column
              field="pphQ2"
              header="Q2"
              body={_renderPphQ2}
              style={isShowColumn(pphReportTblDataByRegion, 2)}></Column>

            <Column
              field="pphQ3"
              header="Q3"
              body={_renderPphQ3}
              style={isShowColumn(pphReportTblDataByRegion, 3)}></Column>

            <Column
              field="pphQ4"
              header="Q4"
              body={_renderPphQ4}
              style={isShowColumn(pphReportTblDataByRegion, 4)}></Column>

            <Column
              field="ytd"
              header="YTD - 6 months roll"
              body={_renderYtd}
              style={isShowColumn(pphReportTblDataByRegion, -1)}></Column>
          </DataTable>
        </>
      )}
    </>
  );
}
