// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';
//$FlowFixMe
import {debounceTime} from 'rxjs/operators';
import {useTranslation} from 'react-i18next';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {Chart} from 'primereact/chart';
//$FlowFixMe
import {Button} from 'primereact/button';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../service/AuthUtils';
import type {EvtHandler} from '../types';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {pphColors, getChartOptions} from './CommonChart';

type Props = {
  scope?: string,
  regions?: any[],
  countries?: any[],
  facGroups?: any[],
  factories?: any[],
  lines?: any[],
  models?: any[],
  year?: any,
  month?: any,
  expanded?: boolean,
  onAction?: EvtHandler,
};

export function PphMonthlyMd(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();
  const {
    scope,
    regions,
    countries,
    facGroups,
    factories,
    lines,
    models,
    year,
    expanded,
    onAction,
  } = props;

  //console.log.*$
  const [barData, setBarData] = useState();
  const [chartOptions] = useState(getChartOptions());

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchData = useCallback(async (options) => {
    let {year, models} = options;
    //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return [];
    }
    //scope = scope || 'region';
    // let di = Utils.getDateInfo(new Date());
    let pYear = year || 0;
    // let pMonth = month || 0;
    let joins = [
      {
        type: '',
        expr: 'Factory',
        alias: 'fac',
      },
      {
        type: 'join',
        expr: 'fac.country',
        alias: 'coun',
      },
      {
        type: 'join',
        expr: 'coun.region',
        alias: 'reg',
      },
    ];

    let filters = {
      liveFlag: 'obj.flag > -1',
      jnFac: 'fac.id = obj.factoryId',
    };

    let params = {};
    let groupBys = ['obj.month'];

    ////////////
    groupBys = ['model.code', ...groupBys];

    joins = [
      ...joins,
      {
        type: '',
        expr: 'MachModel',
        alias: 'model',
      },
    ];

    let modelIds = Utils.getVals(models || [], 'id', [0]).map((it) => {
      return {
        type: 'long',
        value: it,
      };
    });
    //  //console.log.*$
    filters = {
      ...filters,
      byModelIds: 'obj.modelId in (:modelIds)',
      jnModel: 'model.id = obj.modelId',
    };

    params = {
      ...params,
      modelIds,
    };

    filters = {
      ...filters,
      year: 'obj.year = :year',
    };
    params = {
      ...params,
      year: Number(pYear),
    };

    let fields = [...groupBys, 'sum(obj.nbProds)', 'sum(obj.nbHours)'];
    let sorts = ['obj.month'];
    //console.log.*$
    return await api
      .fetchProdMdRecData({
        fields,
        joins,
        groupBys,
        filters,
        params,
        sorts,
        start: 0,
        limit: 0,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        // //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        let lst = [...data.list];
        ////console.log.*$
        lst.forEach((it) => {
          let v = 0;
          let hours = it[3];
          if (hours > 0) {
            v = Utils.round((1.0 * it[2]) / hours);
          }
          it.splice(2, 0, v);
        });
        return [...lst];
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []);

  /*const _fetchKpiData = useCallback(async (options: any) => {
    let {scope, factories, factory, lines, line, year, month} = options;
    let api: ?AdminApi = apiRef.current;
    if (
      !api ||
      scope === 'region' ||
      scope === 'country' ||
      scope === 'facGroup'
    ) {
      return [];
    }
    if (!factory && factories && factories.length > 0) {
      let pms = factories.map((fac) => {
        return _fetchKpiData({
          ...options,
          factory: fac,
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    if (!line && lines && lines.length > 0) {
      let pms = lines.map((ln) => {
        return _fetchKpiData({
          ...options,
          line: ln,
        });
      });
      let datas = await Promise.all(pms);
      return datas.map((it) => it[0]);
    }

    let reqData = {
      scope,
      periodType: 'DAY',
      kpiType: 'PPH',
      year,
      month,
    };

    let serie = '';
    if (factory) {
      reqData = {
        ...reqData,
        factoryId: factory.id,
      };
      serie = `Target-${factory.code}`;
    }

    if (line) {
      reqData = {
        ...reqData,
        lineId: line.id,
      };
      serie = `TARGET-${line.code}`;
    }

    return await api
      .loadProdKpiData({
        data: {...reqData},
      })
      .then((resp) => resp.data)
      .then((resp) => {
        // //console.log.*$
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return [];
        }
        return [
          {
            ...data,
            serie,
          },
        ];
      })
      .catch((error) => {
        //console.log.*$
        UIUtils.showError({error, toast: toastRef.current});
        return [];
      });
  }, []); */
  ///
  const _fetchChartData = useCallback(
    async (options: any) => {
      let {scope} = options;
      switch (scope) {
        case 'factory':
          options = {
            ...options,
            line: null,
            lines: [],
          };
          break;
        default:
          break;
      }
      //  let monInfo = await _fetchMonthInfo(options);
      //console.log.*$

      let months = [];
      for (let i = 0; i < 12; i++) {
        months.push(i + 1);
      }
      let labels = months.map((it) => it);
      labels = labels.map((it) => t(`mon.${it}`));

      // //console.log.*$
      /*let pm1 = _fetchKpiData(options).then((data) => {
        //console.log.*$
        // let colors: any[] = randHighColors(data.length) || [];
        return data.map((rec, ind) => {
          let ci = ind % kpiColors.length;
          return {
            type: 'line',
            label: rec.serie,
            backgroundColor: kpiColors[ci],
            borderColor: kpiColors[ci],
            borderWidth: 1,
            data: [...rec.targetValues],
          };
        });
      });*/

      let pm2 = _fetchData(options).then((recs) => {
        //console.log.*$
        //console.log.*$
        let series = [];
        recs
          .map((it) => it[0])
          .forEach((it) => {
            if (!series.includes(it)) {
              series = [...series, it];
            }
          });
        // //console.log.*$
        // let colors: any[] = randHighColors(series.length) || [];

        // let labels = [];
        // // let labels = recs.map((it) => it[1]);
        // recs
        //   .map((it) => it[1])
        //   .forEach((it) => {
        //     if (!labels.includes(it)) {
        //       labels = [...labels, it];
        //     }
        //   });
        // // //console.log.*$

        let datas = series.map((serie, ind) => {
          let dtMap = {};
          recs
            .filter((rc) => rc[0] === serie)
            .forEach((rc) => {
              dtMap[rc[1]] = rc[2];
            });
          let lst = labels.map((it, ind) => {
            let val = dtMap[ind + 1];
            return val ? val : 0;
          });
          return lst;
        });
        //console.log.*$

        let datasets = series.map((serie, ind) => {
          let ci = ind % pphColors.length;
          return {
            label: `EOL-${serie}`,
            backgroundColor: pphColors[ci],
            data: datas[ind],
          };
        });

        let chartData = {
          datasets,
        };
        // setBarData(chartData);
        return chartData;
      });

      Promise.all([pm2]).then((data) => {
        let [chartData] = data;
        //  //console.log.*$
        //console.log.*$
        let {datasets} = chartData;
        if (datasets.length > 0) {
          datasets = [...datasets];
        }
        setBarData({
          labels,
          datasets,
        });
      });
    },
    [_fetchData, t],
  );

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchChartData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      // //console.log.*$
      subj.complete();
    };
  }, [_fetchChartData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      scope,
      regions,
      countries,
      models,
      facGroups,
      factories,
      lines,
      year,
    });
  }, [scope, regions, countries, facGroups, factories, lines, year, models]);

  const _onToggleExpand: EvtHandler = (evt: any) => {
    onAction &&
      onAction({
        type: 'toggleExpand',
        value: !expanded,
      });
  };

  const iconName = expanded
    ? 'pi pi-angle-double-left'
    : 'pi pi-angle-double-right';

  const icons = (
    <Button
      icon={iconName}
      className="p-button-rounded p-button-text"
      style={{padding: 0, height: '1.2rem', color: '#FFFFFF'}}
      onClick={_onToggleExpand}
    />
  );

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="PPH Monthly" icons={icons}>
        <Chart type="bar" data={barData} options={chartOptions}></Chart>
      </Panel>
    </>
  );
}
