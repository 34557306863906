// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';
//$FlowFixMe
import {debounceTime} from 'rxjs/operators';
// import classNames from 'classnames';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  search?: string,
  selectionMode?: string,
  selection?: any,
  byUserGrp?: any,
  header?: string,
  reload?: boolean,
  simpleMode?: boolean,
  showSumAmt?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function ProfileList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {t} = useTranslation();
  const [profilesTblData, setProfilesTblData] = useState([]);
  const [profilesTblStart, setProfilesTblStart] = useState(0);
  const [profilesTblLimit] = useState(10);
  const [profilesTblNbRows, setProfilesTblNbRows] = useState(0);
  const [selProfile, setSelProfile] = useState();
  const [selProfiles, setSelProfiles] = useState([]);
  const [sortMeta, setSortMeta] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);

  const {
    byUserGrp,
    reload,
    selection,
    simpleMode,
    showSumAmt,
    header,
    command,
  } = props;
  const search = props.search || '';
  const selMode = props.selectionMode || 'single';
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  const _fetchData = useCallback((options) => {
    let {search, profilesTblStart, profilesTblLimit, byUserGrp, sortMeta} =
      options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let start = profilesTblStart;
    let limit = profilesTblLimit;
    let filters = {};
    let params = {
      fetchProjInfo: true,
    };

    if (byUserGrp) {
      filters = {
        ...filters,
        byUserGrp:
          'exists (select 1 from UserGrpLnk lnk where lnk.user.login = obj.login' +
          ' and lnk.group.id = :grpId)',
      };
      params = {
        ...params,
        grpId: {
          type: 'long',
          value: byUserGrp.id,
        },
      };
    }

    // //console.log.*$

    let joins = [];
    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });
    //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);

    api
      .getProfiles({search, start, limit, filters, params, joins, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        // //console.log.*$
        setProfilesTblData([...data.list]);
        setProfilesTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      subj.complete();
    };
  }, [_fetchData]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelProfile({...selection});
      } else {
        setSelProfiles([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelProfile(null);
      } else {
        setSelProfiles([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    //console.log.*$
    let fetchDataEvtSrc = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      search,
      profilesTblStart,
      profilesTblLimit,
      byUserGrp,
      sortMeta,
    });
  }, [search, profilesTblStart, profilesTblLimit, reload, byUserGrp, sortMeta]);

  const _onSelect = (evt: any) => {
    if (selMode === 'single') {
      setSelProfile({...evt.value});
    } else {
      setSelProfiles([...evt.value]);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onProfilesPaging = (evt: any) => {
    // //console.log.*$
    setProfilesTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };

  const _renderUpCol = (row, col) => {
    let {updatedAt} = row;
    if (updatedAt) {
      return `${Utils.reFmtDate(updatedAt, 'DD/MM/YY HH:mm')}`;
    }
  };

  const _renderActiveCol: (row: any, col: any) => any = (
    row: any,
    col: any,
  ) => {
    let {active} = row;
    return `${active ? 'True' : 'False'}`;
  };

  const grandTotal = profilesTblData
    .map((it) => {
      let total = Number(it.invTotalAmt) || 0;
      return total;
    })
    .reduce((acc, it) => acc + it, 0);

  const pagingRight = showSumAmt ? (
    <>
      <span>Grand total: </span>
      <span>{Utils.fmtDecimal(grandTotal)}</span>
    </>
  ) : (
    <span></span>
  );

  const pagingLeft = <span></span>;

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header || t('vdinvoice.title')}
          value={profilesTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          paginatorRight={pagingRight}
          paginatorLeft={pagingLeft}
          rows={profilesTblLimit}
          first={profilesTblStart}
          totalRecords={profilesTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selProfile}
          onSelectionChange={_onSelect}
          onPage={_onProfilesPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            field="login"
            header="Login"
            style={{width: 150}}
            sortable
            sortField="obj.login"
          />
          <Column
            body={_renderActiveCol}
            header="Active"
            style={{width: 100}}
            sortable
            sortField="obj.active"
          />
          <Column
            field="fullName"
            header="Full name"
            style={{width: 200}}
            sortable
            sortField="obj.fullName"
          />
          <Column
            field="email"
            header="Email"
            style={{width: 200}}
            sortable
            sortField="obj.email"
          />
          <Column
            field="phone"
            header="Phone"
            style={{width: 150}}
            sortable
            sortField="obj.phone"
          />
          {!simpleMode && (
            <Column
              body={_renderUpCol}
              header="Updated On"
              style={{width: 130}}
              sortable
              sortField="obj.updatedAt"
            />
          )}
          {!simpleMode && (
            <Column
              field="updatedBy"
              header="Updated By"
              style={{width: 150}}
              sortable
              sortField="obj.updatedBy"
            />
          )}
          {!simpleMode && (
            <Column
              field="id"
              header="#"
              headerClassName="text-right pr-4"
              bodyClassName="text-right"
              style={{width: 90}}
            />
          )}
        </DataTable>
      )}
      {selMode !== 'single' && (
        <DataTable
          header={header || t('vdinvoice.title')}
          value={profilesTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          paginatorRight={pagingRight}
          paginatorLeft={pagingLeft}
          rows={profilesTblLimit}
          first={profilesTblStart}
          totalRecords={profilesTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selProfiles}
          onSelectionChange={_onSelect}
          onPage={_onProfilesPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            selectionMode="multiple"
            headerStyle={{width: 50}}
            headerClassName="text-left"
            bodyClassName="text-left"></Column>
          <Column
            field="login"
            header="Login"
            style={{width: 150}}
            sortable
            sortField="obj.login"
          />
          <Column
            body={_renderActiveCol}
            header="Active"
            style={{width: 100}}
            sortable
            sortField="obj.active"
          />
          <Column
            field="fullName"
            header="Full name"
            style={{width: 200}}
            sortable
            sortField="obj.fullName"
          />
          <Column
            field="email"
            header="Email"
            style={{width: 200}}
            sortable
            sortField="obj.email"
          />
          <Column
            field="phone"
            header="Phone"
            style={{width: 150}}
            sortable
            sortField="obj.phone"
          />
          {!simpleMode && (
            <Column
              body={_renderUpCol}
              header="Updated On"
              style={{width: 130}}
              sortable
              sortField="obj.updatedAt"
            />
          )}
          {!simpleMode && (
            <Column
              field="updatedBy"
              header="Updated By"
              style={{width: 150}}
              sortable
              sortField="obj.updatedBy"
            />
          )}
          {!simpleMode && (
            <Column
              field="id"
              header="#"
              headerClassName="text-right pr-4"
              bodyClassName="text-right"
              style={{width: 90}}
            />
          )}
        </DataTable>
      )}
    </>
  );
}
