// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
// import {AutoComplete} from 'primereact/autocomplete';


import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
//$FlowFixMe[cannot-resolve-module]
import {AutoComplete} from 'primereact/autocomplete';
//$FlowFixMe[cannot-resolve-module]
import {Checkbox} from 'primereact/checkbox';
type Props = {
  readOnly?: boolean,
  leanAudItem: any,
  onAction: (evt: any) => void,
};

const defLeanAudItem = {
  __cls__: 'com.nb.lmrs.model.entity.AndonAudItem',
  flag: 0,
  code: '',
  name: '',
  description: '',
  processType: 'C2B',
  applicable: false,
  tarScore: 0,
  weight: 0,
  c2bVal: 0,
  c2bWg: 0,
  sfVal: 0,
  sfWg: 0,
  offlineVal: 0,
  offlineWg: 0,
  osPressVal: 0,
  osPressWg: 0,
};

export function AndonAudItemForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {readOnly, leanAudItem} = props;
  const [editLeanAudItem, setEditLeanAudItem] = useState<any>();
  const [suggEvaluations, setSuggEvaluations] = useState([]);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
    let obj = leanAudItem || {};
    obj = {
      ...defLeanAudItem,
      ...obj,
    };


     let {evaluation} = obj;
     console.log(obj);
    if (evaluation !== undefined && evaluation !== null) {
        obj.evaluation = evaluation +'';
    }
    // obj['weight'] = (weight || 0) * 100;


    // //console.log.*$
    setEditLeanAudItem(obj);
  }, [leanAudItem]);




  const _validateLeanAudItem: () => any = () => {
    let obj = {...editLeanAudItem};
    // obj = Utils.adjustPercField(
    //   obj,
    //   ['evaluation', 'c2bWg', 'sfWg', 'offlineWg', 'osPressWg'],
    //   false,
    // );
    console.log(obj);
    // obj.rate = Number(obj.rate);
    return obj;
  };

  const _onSave: EvtHandler = (evt: any) => {
    // setShowCfmDlg(false);
    let fac = _validateLeanAudItem();
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update leanAudItem type?'
        : 'Are you sure you want to create leanAudItem type?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateAndonAuditItem({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showWarning({
                summary: 'Warning',
                detail: String(errors[0].message),
                toast: toastRef.current,
              });
              let {onAction} = props;
              onAction &&
                onAction({
                  type: 'auditItemSaved',
                  value: data.entity,
                });
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'auditItemSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };


  const _onSuggEvaluations = () => {
    setSuggEvaluations([
        {
            label: '0',
            value: '0',
         },
         {
            label: '0.25',
            value: '0.25',
         },
            {
                label: '0.5',
                value: '0.5',
            },
            {
                label: '0.75',
                value: '0.75',
            },
            {
                label: '1',
                value: '1',
            }
])
    };

  if (!editLeanAudItem) {
    return null;
  }

  const saveLbl = editLeanAudItem.id
    ? 'Update leanAudItem type'
    : 'Create leanAudItem type';
  const isNew = !editLeanAudItem.id;
  // //console.log.*$

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/edit leanAudItem type">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editLeanAudItem.code}
                onChange={(e) => {
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    code: e.target.value,
                  });
                }}
                disabled={!isNew || readOnly}
              />
              <label>Code</label>
            </span>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editLeanAudItem.name}
                onChange={(e) => {
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    name: e.target.value,
                  });
                }}
                // onKeyDown={_onKeyDown}
                disabled={readOnly}
              />
              <label>Name</label>
            </span>
          </div>


          <div className="p-col-12">
            <Checkbox
              value={editLeanAudItem.weight}
              onChange={(e) =>
                setEditLeanAudItem({
                  ...editLeanAudItem,
                  applicable: e.checked,
                })
              }
              checked={editLeanAudItem.applicable}
            />
            <label className="p-checkbox-label ml-1">Applicable</label>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
             <AutoComplete
                placeholder="Select evaluation"
                dropdown
                suggestions={suggEvaluations}
                field="label"
                value={editLeanAudItem.evaluation}
                completeMethod={_onSuggEvaluations}
                onChange={(e) =>
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    evaluation: e.value.value,
                  })
                }
                disabled={readOnly}
              />

            </span>
          </div>



          <div className="p-col-12">
            <span className="p-float-label">
              <InputTextarea
                rows={2}
                value={editLeanAudItem.method}
                onChange={(e) => {
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    method: e.target.value,
                  });
                }}
                disabled={readOnly}
              />
              <label>Method</label>
            </span>
          </div>
          <div className="p-col-12">
            <span className="p-float-label">
              <InputTextarea
                rows={2}
                value={editLeanAudItem.comment}
                onChange={(e) => {
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    comment: e.target.value,
                  });
                }}
                disabled={readOnly}
              />
              <label>Observation/Comment</label>
            </span>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
