// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
import {AuthUtils} from '../../service/AuthUtils';
import {AuthConst} from '../../service/AppConstant';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {QuickChangeOverReportList} from './QuickChangeOverReportList';

type Props = {
  audit?: any,
  onAction?: EvtHandler,
};

export function QuickChangeOverReport(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const {audit} = props;
  const [selLeanAudItem, setSelLeanAudItem] = useState<any>();
  const [reload, setReload] = useState(false);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);



  const _onLeanAudItemSelected = async (evt: any) => {
    setSelLeanAudItem(evt.value);
  };

  const _onNewLeanAudItem: any = (evt: any) => {
    setSelLeanAudItem({});
  };

  const _onDelete: (evt: any) => void = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selLeanAudItem.id) {
      return;
    }
    confirmDialog({
      message: 'Are you sure you want to delete measure type?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .deleteLeanAudItem(selLeanAudItem.id)
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
            }
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          })
          .finally(() => {
            setSelLeanAudItem(null);
            setReload(!reload);
          });
      },
      reject: () => {},
    });
  };

  let listTitle = 'Available Andon reports';
  let authLevel = AuthUtils.getAuthLevel({auth});
  let canEdit =
    authLevel < AuthConst.AUTH_LVL_NB ||
    AuthUtils.isAuth({auth, roles: ['FACTORY_ADMIN', 'NB_ADMIN']});

  return (
    <>
      <Toast ref={toastRef} />

      <div className="p-grid">
        <div className="p-col-12 p-lg-8">
          <QuickChangeOverReportList
            header={listTitle}
            byAudit={audit}
            reload={reload}
            selection={selLeanAudItem}
            onSelect={_onLeanAudItemSelected}
          />
          {canEdit && false && (
            <div className="p-d-flex p-jc-end p-my-2">
              <Button
                label="New measure"
                icon="pi pi-plus"
                className="p-ml-2"
                onClick={_onNewLeanAudItem}
              />
              {selLeanAudItem && selLeanAudItem.id && (
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  className="p-ml-2"
                  onClick={_onDelete}
                  disabled={selLeanAudItem.flag === 1}
                />
              )}
            </div>
          )}
        </div>
        {/* <div className="p-col-12 p-lg-4">
          <PfaReportForm
            readOnly={!canEdit}
            leanAudItem={selLeanAudItem}
            onAction={_onLeanAudItemAction}
            cutOffDate={audit.cutOffDate}
          />
        </div> */}
      </div>
      {/*<Dialog
        header="Confirm delete production line"
        footer={cfmDlgFooter}
        visible={showCfmDlg}
        style={{width: '400px'}}
        modal={true}
        onHide={() => {
          setShowCfmDlg(false);
        }}>
        <span>Are you sure to delete selected production line?</span>
      </Dialog>*/}
    </>
  );
}
