//@flow
import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {Route, Switch, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {AuthConst} from './service/AppConstant';
import {AuthUtils} from './service/AuthUtils';

//$FlowFixMe[cannot-resolve-module]
import 'primereact/resources/primereact.min.css';
//$FlowFixMe[cannot-resolve-module]
import 'primeicons/primeicons.css';
//$FlowFixMe[cannot-resolve-module]
import 'primeflex/primeflex.css';

//$FlowFixMe[cannot-resolve-module]
import './App.scss';

import AppTopbar from './AppTopbar';
import {AppBreadcrumb} from './AppBreadcrumb';
import AppInlineMenu from './AppInlineMenu';
import {AppFooter} from './AppFooter';
import AppMenu from './AppMenu';
import AppConfig from './AppConfig';
import AppRightMenu from './AppRightMenu';

// import {Dashboard} from './components/Dashboard';
import {DashboardAnalytics} from './components/DashboardAnalytics';
import {ButtonDemo} from './components/ButtonDemo';
import {ChartDemo} from './components/ChartDemo';
import {ImportDataByModels} from './lmrs/import/ImportDataByModels';
import {MessagesDemo} from './components/MessagesDemo';
// import {Documentation} from './components/Documentation';
import {FileDemo} from './components/FileDemo';
import {FormLayoutDemo} from './components/FormLayoutDemo';
import {InputDemo} from './components/InputDemo';
import {ListDemo} from './components/ListDemo';
import {MiscDemo} from './components/MiscDemo';
import {MenuDemo} from './components/MenuDemo';
import {OverlayDemo} from './components/OverlayDemo';
import {PanelDemo} from './components/PanelDemo';
import {TableDemo} from './components/TableDemo';
import {TreeDemo} from './components/TreeDemo';
import {FloatLabelDemo} from './components/FloatLabelDemo';
import {InvalidStateDemo} from './components/InvalidStateDemo';

import {MiniLineAudit} from './lmrs/lean/MiniLineAudit';
import {MachModel} from './lmrs/setting/MachModel';
import {DisplayDemo} from './utilities/DisplayDemo';
import {ElevationDemo} from './utilities/ElevationDemo';
// import {FlexBoxDemo} from './utilities/FlexboxDemo';
import {GridDemo} from './utilities/GridDemo';
// import {IconsDemo} from './utilities/IconsDemo';
// import {SpacingDemo} from './utilities/SpacingDemo';
import {TextDemo} from './utilities/TextDemo';
import {TypographyDemo} from './utilities/TypographyDemo';
import {WidgetsDemo} from './utilities/WidgetsDemo';

import {Crud} from './pages/Crud';
import {Calendar} from './pages/Calendar';
import {EmptyPage} from './pages/EmptyPage';
import {Invoice} from './pages/Invoice';
import {Help} from './pages/Help';
import {TimelineDemo} from './pages/TimelineDemo';

import {Profile} from './lmrs/profile/Profile';
import {UserGrp} from './lmrs/profile/UserGrp';
import {Role} from './lmrs/profile/Role';

import {Factory} from './lmrs/setting/Factory';
import {MachLine} from './lmrs/setting/MachLine';
import {Operator} from './lmrs/setting/Operator';
import {Country} from './lmrs/setting/Country';
import {Region} from './lmrs/setting/Region';
import {LineGroup} from './lmrs/setting/LineGroup';
import {Shift} from './lmrs/setting/Shift';
import {MachError} from './lmrs/setting/MachError';
import {FacGroup} from './lmrs/setting/FacGroup';
import {ProdKpi} from './lmrs/kpi/ProdKpi';
import {FilterMlt} from './lmrs/setting/FilterMlt';

import {ProdRec} from './lmrs/prod/ProdRec';
import {ProdRecMd} from './lmrs/prod/ProdRecMd';
import {ProdRecBts} from './lmrs/prod/ProdRecBts';
import {ErrorRec} from './lmrs/error/ErrorRec';
import {ChartPage} from './lmrs/chart/ChartPage';
import {MltRec} from './lmrs/mlt/MltRec';
import {ImportData} from './lmrs/import/ImportData';
import {ImportMltData} from './lmrs/import/ImportMltData';
import {ImportDataBts} from './lmrs/import/ImportDataBts';
import {AudMeasure} from './lmrs/lean/AudMeasure';
import {LeanAudit} from './lmrs/lean/LeanAudit';
import {LeanReport} from './lmrs/lean/LeanReport';
import {ETRReport} from './lmrs/lean/ETRReport';
import {OnePagerReport} from './lmrs/lean/OnePagerReport';
import {LeanActivityPage} from './lmrs/lean/LeanActivityPage';
import {ScoreCardKpi} from './lmrs/kpi/ScoreCardKpi';
import {LeanScoreCard} from './lmrs/lean/LeanScoreCard';
import {LeanMetric} from './lmrs/leanmetric/LeanMetric';
import {StdDashboard} from './lmrs/home/StdDashboard';
import {LeanAuditNb} from './lmrs/lean/leannbadmin/LeanAuditNb';
import {CommonKpi} from './lmrs/setting/CommonKpi';
import {SetPointMlt} from './lmrs/setting/SetPointMlt';
//import {AndonDetail} from './lmrs/pfa/AndonDetail';
import {AndonMeasure} from './lmrs/andon/AndonMeasure';
import {PfaMeasure} from './lmrs/pfa/PfaMeasure';
import {AndonAudit} from './lmrs/andon/AndonAudit';
import {PfaAudit} from './lmrs/pfa/PfaAudit';
import {QuickOverChangeMeasure} from './lmrs/quickoverchange/QuickOverChangeMeasure';
import {QuickOverChangeAudit} from './lmrs/quickoverchange/QuickOverChangeAudit';
//$FlowFixMe[cannot-resolve-module]
import PrimeReact from 'primereact/api';
import {WarningThreshold} from './lmrs/setting/WarningThreshold';
import {Alarm} from './lmrs/alarm/Alarm';
import {MailSetting} from './lmrs/setting/MailSetting';
import {PfaAuditGroup} from './lmrs/pfa/PfaAuditGroup';
//$FlowFixMe[signature-verification-failure]
export const RTLContext = React.createContext();

function App(): React$Node {
  // const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const [menuMode, setMenuMode] = useState('static');
  const [inlineMenuPosition, setInlineMenuPosition] = useState('bottom');
  const [desktopMenuActive, setDesktopMenuActive] = useState(true);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [activeTopbarItem, setActiveTopbarItem] = useState(null);
  const [colorMode, setColorMode] = useState('light');
  const [rightMenuActive, setRightMenuActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [inputStyle, setInputStyle] = useState('filled');
  const [isRTL, setRTL] = useState(false);
  const [ripple, setRipple] = useState(true);
  const [mobileTopbarActive, setMobileTopbarActive] = useState(false);
  const [menuTheme, setMenuTheme] = useState('light');
  const [topbarTheme, setTopbarTheme] = useState('green');
  const [theme, setTheme] = useState('indigo');
  const [isInputBackgroundChanged, setIsInputBackgroundChanged] =
    useState(false);
  const [inlineMenuActive, setInlineMenuActive] = useState({});
  const [newThemeLoaded, setNewThemeLoaded] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  let currentInlineMenuKey = useRef(null);

  PrimeReact.ripple = true;

  let searchClick;
  let topbarItemClick;
  let menuClick;
  let inlineMenuClick;

  const settingMis = [
    {
      label: 'Factory KPI',
      icon: 'pi pi-fw pi-key',
      to: '/setting/kpis',
    },
    {
      label: 'NB Lean KPI',
      icon: 'pi pi-fw pi-key',
      to: '/setting/commonkpis',
    },
    {
      label: 'Production lines',
      icon: 'pi pi-fw pi-sliders-v',
      to: '/setting/machlines',
    },
    {
      label: 'Production models',
      icon: 'pi pi-fw pi-sliders-v',
      to: '/setting/models',
    },

    {
      label: 'Factories',
      icon: 'pi pi-fw pi-cog',
      to: '/setting/factories',
    },
  ];
  let authLvl = AuthUtils.getAuthLevel({auth});
  let showFacGroupMenu = false;
  if (
    AuthUtils.isAuth({auth, roles: ['FACTORY_ADMIN', 'NB_ADMIN']}) ||
    authLvl < AuthConst.AUTH_LVL_FACTORY
  ) {
    showFacGroupMenu = true;
  }

  if (showFacGroupMenu) {
    settingMis.push({
      label: 'Factory Groups',
      icon: 'pi pi-fw pi-globe',
      to: '/setting/facgroups',
    });
  }

  let showUserMenu = false;
  if (
    AuthUtils.isAuth({auth, roles: ['FACTORY_ADMIN', 'NB_ADMIN']}) ||
    authLvl < AuthConst.AUTH_LVL_NB
  ) {
    showUserMenu = true;
  }
  // let showUserMenu = authLvl < AuthConst.AUTH_LVL_FACTORY || hasFacAdminPerm;
  if (showUserMenu) {
    settingMis.push({
      label: 'Users',
      icon: 'pi pi-fw pi-users',
      to: '/setting/profiles',
    });
  }

  const prodMis = [
    {
      label: 'Dashboard',
      icon: 'pi pi-fw pi-chart-bar',
      to: '/home/std-dashboard',
    },

    {
      label: 'PPH by Line Data',
      icon: 'pi pi-fw pi-list',
      to: '/production/records',
    },

    {
      label: 'PPH by Model Data',
      icon: 'pi pi-fw pi-list',
      to: '/productionmd/records',
    },
  ];

  const notificationMis = [
    {
      label: 'Notifications',
      icon: 'pi pi-fw pi-chart-bar',
      to: '/notification/notifications',
    },
    {
        label: 'Warning Thresholds',
        icon: 'pi pi-filter',
        to: '/setting/warningthresholds',
      },
  ];

  if (
    AuthUtils.isAuth({auth, roles: ['ADMIN']}) ||
    authLvl < AuthConst.AUTH_LVL_NB
  ) {
    prodMis.push({
      label: 'Error Data',
      icon: 'pi pi-fw pi-list',
      to: '/production/errors',
    });

    settingMis.push(
      {
        label: 'Operator',
        icon: 'pi pi-fw pi-id-card',
        to: '/setting/operators',
      },
      {
        label: 'Working shifts',
        icon: 'pi pi-fw pi-sliders-h',
        to: '/setting/shifts',
      },
      {
        label: 'Error codes',
        icon: 'pi pi-fw pi-exclamation-circle',
        to: '/setting/error-codes',
      },
    );
    settingMis.splice(
      5,
      0,
      {
        label: 'Filter MLT',
        icon: 'pi pi-filter',
        to: '/setting/filtermlt',
      },
      {
        label: 'Set Point MLT',
        icon: 'pi pi-filter',
        to: '/setting/setpointmlt',
      },

      {
        label: 'Mail Setting',
        icon: 'pi pi-filter',
        to: '/setting/mail',
      },
    );
  }

  const showImportMenu =
    authLvl <= AuthConst.AUTH_LVL_APP ||
    AuthUtils.isAuth({
      auth,
      roles: ['FACTORY_ADMIN', 'FACTORY_EDITOR'],
    });

  if (showImportMenu) {
    prodMis.push({
      label: 'Import PPH by Line',
      icon: 'pi pi-fw pi-list',
      to: '/production/imports',
    });
    prodMis.push({
      label: 'Import PPH by Model',
      icon: 'pi pi-fw pi-list',
      to: '/production/importsbymodels',
    });
  }

  const leanMis = [
    {
      label: 'BTS Data',
      icon: 'pi pi-fw pi-list',
      to: '/productionbts/records',
    },
    {
      label: 'Import BTS Data ',
      icon: 'pi pi-fw pi-list',
      to: '/production/importdatabtss',
    },

    {
      label: 'MLT Data',
      icon: 'pi pi-fw pi-list',
      to: '/mlt/records',
    },
    {
      label: 'Import MLT Data',
      icon: 'pi pi-fw pi-list',
      to: '/mlt/imports',
    },
    {
      label: 'Lean measure criterias',
      icon: 'pi pi-fw pi-list',
      to: '/lean/measures',
    },
    {
      label: 'Mini line',
      icon: 'pi pi-fw pi-list',
      to: '/lean/mini-lines',
    },
    {
      label: 'Lean assessments',
      icon: 'pi pi-fw pi-list',
      to: '/lean/leanaudits',
    },
    {
      label: 'Lean activities',
      icon: 'pi pi-fw pi-list',
      to: '/lean/lean-activities',
    },

    {
      label: 'Lean report',
      icon: 'pi pi-fw pi-list',
      to: '/lean/leanreports',
    },
  ];

  leanMis.push({
    label: 'One pager report',
    icon: 'pi pi-fw pi-list',
    to: '/lean/one-pager-report',
  });

  leanMis.push({
    label: 'ETR report',
    icon: 'pi pi-fw pi-list',
    to: '/lean/etrreport',
  });

  if (
    AuthUtils.getAuthLevel({auth}) < AuthConst.AUTH_LVL_FACTORY ||
    AuthUtils.isAuth({auth, roles: ['NB_ADMIN']})
  ) {
    leanMis.push({
      label: 'Lean Score Card',
      icon: 'pi pi-fw pi-list',
      to: '/lean/leanscorecard',
    });

    leanMis.push({
      label: 'Lean Assessment Overview',
      icon: 'pi pi-fw pi-list',
      to: '/lean/leanauditsnb',
    });

    leanMis.push({
      label: 'Monthly Lean Metric Report',
      icon: 'pi pi-fw pi-list',
      to: '/lean/leanmetric',
    });

    settingMis.splice(2, 0, {
      label: 'Score Card KPI',
      icon: 'pi pi-fw pi-sliders-h',
      to: '/setting/scorecardkpi',
    });

    settingMis.push({
      label: 'Regions',
      icon: 'pi pi-fw pi-globe',
      to: '/setting/regions',
    });

    settingMis.push({
      label: 'Countries',
      icon: 'pi pi-fw pi-globe',
      to: '/setting/countries',
    });
  }

  const leanForNBCosting = [
    {
      label: 'PPH review',
      icon: 'pi pi-fw pi-chart-bar',
      to: '/home/std-dashboard',
    },
    {
      label: 'One pager report',
      icon: 'pi pi-fw pi-list',
      to: '/lean/one-pager-report',
    },

    {
      label: 'ETR report',
      icon: 'pi pi-fw pi-list',
      to: '/lean/etrreport',
    },
    {
      label: 'Monthly Lean Metric Report',
      icon: 'pi pi-fw pi-list',
      to: '/lean/leanmetric',
    },
    {
      label: 'Lean Score Card',
      icon: 'pi pi-fw pi-list',
      to: '/lean/leanscorecard',
    },
  ];

  const pfaMis = [
    {
      label: 'Andon Audits',
      icon: 'pi pi-fw pi-list',
      to: '/lean/andonaudits',
    },

    {
      label: 'Andon Measures',
      icon: 'pi pi-fw pi-list',
      to: '/lean/andonmeasures',
    },
    {
      label: 'PFA Measures',
      icon: 'pi pi-fw pi-list',
      to: '/lean/pfameasures',
    },
    {
      label: 'PFA Audits',
      icon: 'pi pi-fw pi-list',
      to: '/lean/pfaaudits',
    },
    {
      label: 'Quick Changeover Measures',
      icon: 'pi pi-fw pi-list',
      to: '/lean/quickchangeovermeasures',
    },
    {
      label: 'Quick Changeover Audits',
      icon: 'pi pi-fw pi-list',
      to: '/lean/quickchangeoveraudits',
    },
    {
        label: 'PFA Audits Group',
        icon: 'pi pi-fw pi-list',
        to: '/lean/pfaauditgroups',
      },
  ];

  const menu = [
    {
      label: 'Production',
      icon: 'pi pi-fw pi-home',
      items: [...prodMis],
    },
    {
      label: 'Lean Management',
      icon: 'pi pi-fw pi-home',
      items: [...leanMis],
    },

    {
      label: 'PFA - Andon - QCO',
      icon: 'pi pi-fw pi-home',
      items: [...pfaMis],
    },{
      label: 'Notifications',
      icon: 'pi pi-fw pi-home',
      items: [...notificationMis],
    },

    {
        label: 'Settings',
        icon: 'pi pi-cog',
        items: [...settingMis],
    },



    //       label: 'Form Layout',
    //       icon: 'pi pi-fw pi-id-card',
    //       to: '/uikit/formlayout',
    //       badge: '6',
    //       badgeClassName: 'p-badge-warning',
    //     },
    //     {
    //       label: 'Input',
    //       icon: 'pi pi-fw pi-check-square',
    //       to: '/uikit/input',
    //       badge: '6',
    //       badgeClassName: 'p-badge-danger',
    //     },
    //     {
    //       label: 'Float Label',
    //       icon: 'pi pi-fw pi-bookmark',
    //       to: '/uikit/floatlabel',
    //     },
    //     {
    //       label: 'Invalid State',
    //       icon: 'pi pi-fw pi-exclamation-circle',
    //       to: '/uikit/invalidstate',
    //     },
    //     {
    //       label: 'Button',
    //       icon: 'pi pi-fw pi-mobile',
    //       to: '/uikit/button',
    //       className: 'rotated-icon',
    //     },
    //     {
    //       label: 'Table',
    //       icon: 'pi pi-fw pi-table',
    //       to: '/uikit/table',
    //       badge: '6',
    //       badgeClassName: 'p-badge-help',
    //     },
    //     {label: 'List', icon: 'pi pi-fw pi-list', to: '/uikit/list'},
    //     {label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/uikit/tree'},
    //     {label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/uikit/panel'},
    //     {label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/uikit/overlay'},
    //     {label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/uikit/menu'},
    //     {label: 'Message', icon: 'pi pi-fw pi-comment', to: '/uikit/message'},
    //     {label: 'File', icon: 'pi pi-fw pi-file', to: '/uikit/file'},
    //     {label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/uikit/chart'},
    //     {label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/uikit/misc'},
    //   ],
    // },
    // {
    //   label: 'Utilities',
    //   icon: 'pi pi-fw pi-compass',
    //   items: [
    //     {
    //       label: 'Display',
    //       icon: 'pi pi-fw pi-desktop',
    //       to: '/utilities/display',
    //     },
    //     {
    //       label: 'Elevation',
    //       icon: 'pi pi-fw pi-external-link',
    //       to: '/utilities/elevation',
    //     },
    //     {
    //       label: 'Flexbox',
    //       icon: 'pi pi-fw pi-directions',
    //       to: '/utilities/flexbox',
    //     },
    //     {label: 'Icons', icon: 'pi pi-fw pi-search', to: '/utilities/icons'},
    //     {
    //       label: 'Widgets',
    //       icon: 'pi pi-fw pi-star-o',
    //       to: '/utilities/widgets',
    //     },
    //     {
    //       label: 'Grid System',
    //       icon: 'pi pi-fw pi-th-large',
    //       to: '/utilities/grid',
    //     },
    //     {
    //       label: 'Spacing',
    //       icon: 'pi pi-fw pi-arrow-right',
    //       to: '/utilities/spacing',
    //     },
    //     {
    //       label: 'Typography',
    //       icon: 'pi pi-fw pi-align-center',
    //       to: '/utilities/typography',
    //     },
    //     {label: 'Text', icon: 'pi pi-fw pi-pencil', to: '/utilities/text'},
    //   ],
    // },
    // {
    //   label: 'Pages',
    //   icon: 'pi pi-fw pi-briefcase',
    //   items: [
    //     {label: 'Crud', icon: 'pi pi-fw pi-pencil', to: '/pages/crud'},
    //     {
    //       label: 'Calendar',
    //       icon: 'pi pi-fw pi-calendar-plus',
    //       to: '/pages/calendar',
    //     },
    //     {
    //       label: 'Timeline',
    //       icon: 'pi pi-fw pi-calendar',
    //       to: '/pages/timeline',
    //     },
    //     {
    //       label: 'Landing',
    //       icon: 'pi pi-fw pi-globe',
    //       badge: '2',
    //       badgeClassName: 'p-badge-warning',
    //       items: [
    //         {
    //           label: 'Static',
    //           icon: 'pi pi-fw pi-globe',
    //           url: 'assets/pages/landing.html',
    //           target: '_blank',
    //         },
    //         {label: 'Component', icon: 'pi pi-fw pi-globe', to: '/landing'},
    //       ],
    //     },
    //     {label: 'Login', icon: 'pi pi-fw pi-sign-in', to: '/login'},
    //     {label: 'Invoice', icon: 'pi pi-fw pi-dollar', to: '/pages/invoice'},
    //     {label: 'Help', icon: 'pi pi-fw pi-question-circle', to: '/pages/help'},
    //     {label: 'Error', icon: 'pi pi-fw pi-times-circle', to: '/error'},
    //     {
    //       label: 'Not Found',
    //       icon: 'pi pi-fw pi-exclamation-circle',
    //       to: '/notfound',
    //     },
    //     {label: 'Access Denied', icon: 'pi pi-fw pi-lock', to: '/access'},
    //     {
    //       label: 'Empty Page',
    //       icon: 'pi pi-fw pi-circle-off',
    //       to: '/pages/empty',
    //     },
    //   ],
    // },
    // {
    //   label: 'Hierarchy',
    //   icon: 'pi pi-fw pi-align-left',
    //   items: [
    //     {
    //       label: 'Submenu 1',
    //       icon: 'pi pi-fw pi-align-left',
    //       items: [
    //         {
    //           label: 'Submenu 1.1',
    //           icon: 'pi pi-fw pi-align-left',
    //           items: [
    //             {label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-align-left'},
    //             {label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-align-left'},
    //             {label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-align-left'},
    //           ],
    //         },
    //         {
    //           label: 'Submenu 1.2',
    //           icon: 'pi pi-fw pi-align-left',
    //           items: [
    //             {label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-align-left'},
    //             {label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-align-left'},
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       label: 'Submenu 2',
    //       icon: 'pi pi-fw pi-align-left',
    //       items: [
    //         {
    //           label: 'Submenu 2.1',
    //           icon: 'pi pi-fw pi-align-left',
    //           items: [
    //             {label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-align-left'},
    //             {label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-align-left'},
    //             {label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-align-left'},
    //           ],
    //         },
    //         {
    //           label: 'Submenu 2.2',
    //           icon: 'pi pi-fw pi-align-left',
    //           items: [
    //             {label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-align-left'},
    //             {label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-align-left'},
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   label: 'Start',
    //   icon: 'pi pi-fw pi-download',
    //   items: [
    //     {
    //       label: 'Documentation',
    //       icon: 'pi pi-fw pi-question',
    //       to: '/start/documentation',
    //     },
    //     {
    //       label: 'Buy Now',
    //       icon: 'pi pi-fw pi-shopping-cart',
    //       command: () => {
    //         window.location = 'https://www.primefaces.org/store';
    //       },
    //     },
    //   ],
    // },
  ];

  if (
    AuthUtils.isAuth({auth, roles: ['NB_VIEWER']}) &&
    auth.username === 'NB_LEAN_Costing'
  ) {
    menu.splice(0, menu.length);
    menu.push({
      label: 'Lean Management',
      icon: 'pi pi-fw pi-home',
      items: [...leanForNBCosting],
    });
  }

  // const _renderRouteAdmin = (auth) => {
  //   if (
  //     AuthUtils.getAuthLevel({auth}) < AuthConst.AUTH_LVL_FACTORY ||
  //     AuthUtils.isAuth({auth, roles: ['NB_ADMIN']})
  //   ) {
  //     return <Route path="/mlt/imports" component={ImportMltData} />;
  //   } else {
  //     <Redirect to="/home/std-dashboard" />;
  //   }
  // };

  const routes = [
    {parent: '', label: ''},
    {parent: 'Favorites', label: 'Dashboard Analytics'},
    {parent: 'UI Kit', label: 'Form Layout'},
    {parent: 'UI Kit', label: 'Input'},
    {parent: 'UI Kit', label: 'Float Label'},
    {parent: 'UI Kit', label: 'Invalid State'},
    {parent: 'UI Kit', label: 'Button'},
    {parent: 'UI Kit', label: 'Table'},
    {parent: 'UI Kit', label: 'List'},
    {parent: 'UI Kit', label: 'Panel'},
    {parent: 'UI Kit', label: 'Tree'},
    {parent: 'UI Kit', label: 'Overlay'},
    {parent: 'UI Kit', label: 'Menu'},
    {parent: 'UI Kit', label: 'Message'},
    {parent: 'UI Kit', label: 'File'},
    {parent: 'UI Kit', label: 'Chart'},
    {parent: 'UI Kit', label: 'Misc'},
    {parent: 'Utilities', label: 'Display'},
    {parent: 'Utilities', label: 'Elevation'},
    {parent: 'Utilities', label: 'Flexbox'},
    {parent: 'Utilities', label: 'Icons'},
    {parent: 'Utilities', label: 'Widgets'},
    {parent: 'Utilities', label: 'Grid'},
    {parent: 'Utilities', label: 'Spacing'},
    {parent: 'Utilities', label: 'Typography'},
    {parent: 'Utilities', label: 'Text'},
    {parent: 'Pages', label: 'Crud'},
    {parent: 'Pages', label: 'Calendar'},
    {parent: 'Pages', label: 'Timeline'},
    {parent: 'Pages', label: 'Invoice'},
    {parent: 'Pages', label: 'Login'},
    {parent: 'Pages', label: 'Help'},
    {parent: 'Pages', label: 'Empty'},
    {parent: 'Pages', label: 'Access'},
    {parent: 'Start', label: 'Documentation'},
  ];

  useEffect(() => {
    if (menuMode === 'overlay') {
      hideOverlayMenu();
    }
    if (menuMode === 'static') {
      setDesktopMenuActive(true);
    }
  }, [menuMode]);

  useEffect(() => {
    onColorModeChange(colorMode);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onMenuThemeChange = (theme) => {
    setMenuTheme(theme);
  };

  const onTopbarThemeChange = (theme) => {
    setTopbarTheme(theme);
  };

  useEffect(() => {
    const appLogoLink: any = document.getElementById('app-logo');
    if (!appLogoLink) {
      return;
    }
    if (
      topbarTheme === 'white' ||
      topbarTheme === 'yellow' ||
      topbarTheme === 'amber' ||
      topbarTheme === 'orange' ||
      topbarTheme === 'lime'
    ) {
      appLogoLink.src = 'assets/layout/images/nb-logo-layout.png';
    } else {
      appLogoLink.src = 'assets/layout/images/nb-logo-layout.png';
    }
  }, [topbarTheme]);

  const onThemeChange = (theme) => {
    setTheme(theme);
    const themeLink = document.getElementById('theme-css');
    const themeHref = 'assets/theme/' + theme + '/theme-' + colorMode + '.css';
    replaceLink(themeLink, themeHref);
  };

  const onColorModeChange = (mode) => {
    setColorMode(mode);
    setIsInputBackgroundChanged(true);

    if (isInputBackgroundChanged) {
      if (mode === 'dark') {
        setInputStyle('filled');
      } else {
        setInputStyle('outlined');
      }
    }

    if (mode === 'dark') {
      setMenuTheme('dark');
      setTopbarTheme('dark');
    } else {
      setMenuTheme('light');
      setTopbarTheme('green');
    }

    const layoutLink = document.getElementById('layout-css');
    const layoutHref = 'assets/layout/css/layout-' + mode + '.css';
    replaceLink(layoutLink, layoutHref);

    const themeLink = document.getElementById('theme-css') || {};
    let href = themeLink.getAttribute('href') || '';
    const urlTokens = href.split('/');
    urlTokens[urlTokens.length - 1] = 'theme-' + mode + '.css';
    const newURL = urlTokens.join('/');

    replaceLink(themeLink, newURL, () => {
      setNewThemeLoaded(true);
    });
  };

  const replaceLink = (linkElement, href, callback) => {
    if (!linkElement) {
      return;
    }
    if (isIE()) {
      linkElement.setAttribute('href', href);
      if (callback) {
        callback();
      }
    } else {
      const id: any = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      if (linkElement.parentNode) {
        linkElement.parentNode.insertBefore(
          cloneLinkElement,
          linkElement.nextSibling,
        );
      }

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);

        if (callback) {
          callback();
        }
      });
    }
  };

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onInlineMenuPositionChange = (mode) => {
    setInlineMenuPosition(mode);
  };

  const onMenuModeChange = (mode) => {
    setMenuMode(mode);
  };

  const onRTLChange = () => {
    setRTL((prevState) => !prevState);
  };

  const onMenuClick = (event) => {
    menuClick = true;
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;

    if (isDesktop()) setDesktopMenuActive((prevState) => !prevState);
    else setMobileMenuActive((prevState) => !prevState);

    event.preventDefault();
  };

  const onTopbarItemClick = (event) => {
    topbarItemClick = true;
    if (activeTopbarItem === event.item) setActiveTopbarItem(null);
    else {
      setActiveTopbarItem(event.item);
    }

    event.originalEvent.preventDefault();
  };

  const onSearch = (event) => {
    searchClick = true;
    setSearchActive(event);
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items && (menuMode === 'overlay' || !isDesktop())) {
      hideOverlayMenu();
    }

    if (!event.item.items && (isHorizontal() || isSlim())) {
      setMenuActive(false);
    }
  };

  const onRootMenuItemClick = (event) => {
    setMenuActive((prevState) => !prevState);
  };

  const onRightMenuButtonClick = () => {
    setRightMenuActive((prevState) => !prevState);
  };

  const onMobileTopbarButtonClick = (event) => {
    setMobileTopbarActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onDocumentClick = (event) => {
    if (!searchClick && event.target.localName !== 'input') {
      setSearchActive(false);
    }

    if (!topbarItemClick) {
      setActiveTopbarItem(null);
    }

    if (!menuClick && (menuMode === 'overlay' || !isDesktop())) {
      if (isHorizontal() || isSlim()) {
        setMenuActive(false);
      }
      hideOverlayMenu();
    }

    let key = currentInlineMenuKey.current || '';
    if (inlineMenuActive[key] && !inlineMenuClick) {
      let menuKeys: any = {...inlineMenuActive};
      menuKeys[currentInlineMenuKey.current] = false;
      setInlineMenuActive(menuKeys);
    }

    if (!menuClick && (isSlim() || isHorizontal())) {
      setMenuActive(false);
    }

    searchClick = false;
    topbarItemClick = false;
    inlineMenuClick = false;
    menuClick = false;
  };

  const hideOverlayMenu = () => {
    setMobileMenuActive(false);
    setDesktopMenuActive(false);
  };

  const isDesktop = () => {
    return window.innerWidth > 1024;
  };

  const isHorizontal = () => {
    return menuMode === 'horizontal';
  };

  const isSlim = () => {
    return menuMode === 'slim';
  };

  const isIE = () => {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  };

  const onInlineMenuClick = (e, key) => {
    let menuKeys: any = {...inlineMenuActive};
    if (key !== currentInlineMenuKey.current && currentInlineMenuKey.current) {
      menuKeys[currentInlineMenuKey.current] = false;
    }

    menuKeys[key] = !menuKeys[key];
    setInlineMenuActive(menuKeys);
    currentInlineMenuKey.current = key;
    inlineMenuClick = true;
  };

  const layoutContainerClassName = classNames(
    'layout-wrapper ',
    'layout-menu-' + menuTheme + ' layout-topbar-' + topbarTheme,
    {
      'layout-menu-static': menuMode === 'static',
      'layout-menu-overlay': menuMode === 'overlay',
      'layout-menu-slim': menuMode === 'slim',
      'layout-menu-horizontal': menuMode === 'horizontal',
      'layout-menu-active': desktopMenuActive,
      'layout-menu-mobile-active': mobileMenuActive,
      'layout-topbar-mobile-active': mobileTopbarActive,
      'layout-rightmenu-active': rightMenuActive,
      'p-input-filled': inputStyle === 'filled',
      'p-ripple-disabled': !ripple,
      'layout-rtl': isRTL,
    },
  );

  return (
    <RTLContext.Provider value={isRTL}>
      <div className={layoutContainerClassName} onClick={onDocumentClick}>
        <AppTopbar
          horizontal={isHorizontal()}
          activeTopbarItem={activeTopbarItem}
          onMenuButtonClick={onMenuButtonClick}
          onTopbarItemClick={onTopbarItemClick}
          onRightMenuButtonClick={onRightMenuButtonClick}
          onMobileTopbarButtonClick={onMobileTopbarButtonClick}
          mobileTopbarActive={mobileTopbarActive}
          searchActive={searchActive}
          onSearch={onSearch}
        />

        <div className="menu-wrapper" onClick={onMenuClick}>
          <div className="layout-menu-container">
            {(inlineMenuPosition === 'top' ||
              inlineMenuPosition === 'both') && (
              <AppInlineMenu
                menuKey="top"
                inlineMenuActive={inlineMenuActive}
                onInlineMenuClick={onInlineMenuClick}
                horizontal={isHorizontal()}
                menuMode={menuMode}
              />
            )}
            <AppMenu
              model={menu}
              onMenuItemClick={onMenuItemClick}
              onRootMenuItemClick={onRootMenuItemClick}
              menuMode={menuMode}
              active={menuActive}
            />
            {false &&
              (inlineMenuPosition === 'bottom' ||
                inlineMenuPosition === 'both') && (
                <AppInlineMenu
                  menuKey="bottom"
                  inlineMenuActive={inlineMenuActive}
                  onInlineMenuClick={onInlineMenuClick}
                  horizontal={isHorizontal()}
                  menuMode={menuMode}
                />
              )}
          </div>
        </div>

        <div className="layout-main">
          <AppBreadcrumb routes={routes} />

          <div className="layout-content">
            <Switch>
              {/*<Route
                path="/"
                exact
                render={() => (
                  <Dashboard
                    colorMode={colorMode}
                    isNewThemeLoaded={newThemeLoaded}
                    onNewThemeChange={(e) => setNewThemeLoaded(e)}
                  />
                )}
              />*/}
              <Route path="/" exact>
                <Redirect to="/home/std-dashboard" />
              </Route>
              {/* <Route path="/start/documentation" component={Documentation} /> */}
              <Route
                path="/favorites/dashboardanalytics"
                render={() => (
                  <DashboardAnalytics
                    colorMode={colorMode}
                    isNewThemeLoaded={newThemeLoaded}
                    onNewThemeChange={(e) => setNewThemeLoaded(e)}
                  />
                )}
              />
              <Route path="/uikit/formlayout" component={FormLayoutDemo} />
              <Route path="/uikit/floatlabel" component={FloatLabelDemo} />
              <Route path="/uikit/input" component={InputDemo} />
              <Route path="/uikit/invalidstate" component={InvalidStateDemo} />
              <Route path="/uikit/button" component={ButtonDemo} />
              <Route path="/uikit/table" component={TableDemo} />
              <Route path="/uikit/list" component={ListDemo} />
              <Route path="/uikit/tree" component={TreeDemo} />
              <Route path="/uikit/panel" component={PanelDemo} />
              <Route path="/uikit/overlay" component={OverlayDemo} />
              <Route path="/uikit/menu" component={MenuDemo} />
              <Route path="/uikit/message" component={MessagesDemo} />
              <Route path="/uikit/file" component={FileDemo} />
              <Route
                path="/uikit/chart"
                render={() => (
                  <ChartDemo
                    colorMode={colorMode}
                    isNewThemeLoaded={newThemeLoaded}
                    onNewThemeChange={(e) => setNewThemeLoaded(e)}
                  />
                )}
              />
              <Route path="/uikit/misc" component={MiscDemo} />
              <Route path="/utilities/display" component={DisplayDemo} />
              <Route path="/utilities/elevation" component={ElevationDemo} />
              {/* <Route path="/utilities/flexbox" component={FlexBoxDemo} />
              <Route path="/utilities/icons" component={IconsDemo} /> */}
              <Route
                path="/utilities/widgets"
                render={() => (
                  <WidgetsDemo
                    colorMode={colorMode}
                    isNewThemeLoaded={newThemeLoaded}
                    onNewThemeChange={(e) => setNewThemeLoaded(e)}
                  />
                )}
              />
              <Route path="/utilities/grid" component={GridDemo} />
              {/* <Route path="/utilities/spacing" component={SpacingDemo} /> */}
              <Route path="/utilities/typography" component={TypographyDemo} />
              <Route path="/utilities/text" component={TextDemo} />
              <Route path="/pages/crud" component={Crud} />
              <Route path="/pages/calendar" component={Calendar} />
              <Route path="/pages/help" component={Help} />
              <Route path="/pages/invoice" component={Invoice} />
              <Route path="/pages/empty" component={EmptyPage} />
              <Route path="/pages/timeline" component={TimelineDemo} />
              <Route path="/setting/profiles" component={Profile} />
              <Route path="/setting/usergroups" component={UserGrp} />
              <Route path="/setting/roles" component={Role} />
              <Route path="/setting/factories" component={Factory} />
              <Route path="/setting/machlines" component={MachLine} />
              <Route path="/setting/models" component={MachModel} />
              <Route path="/setting/operators" component={Operator} />
              <Route path="/setting/regions" component={Region} />
              <Route path="/setting/countries" component={Country} />
              <Route path="/setting/linegroups" component={LineGroup} />
              <Route path="/setting/shifts" component={Shift} />
              <Route path="/setting/error-codes" component={MachError} />
              <Route path="/setting/kpis" component={ProdKpi} />
              <Route path="/setting/commonkpis" component={CommonKpi} />
              <Route path="/setting/facgroups" component={FacGroup} />
              <Route path="/setting/scorecardkpi" component={ScoreCardKpi} />
              <Route path="/setting/filtermlt" component={FilterMlt} />
              <Route path="/setting/setpointmlt" component={SetPointMlt} />
              <Route
                path="/setting/warningthresholds"
                component={WarningThreshold}
              />
              <Route path="/setting/mail" component={MailSetting} />

              <Route
                path="/production/importsbymodels"
                component={ImportDataByModels}
              />
              <Route path="/production/records" component={ProdRec} />
              <Route path="/production/errors" component={ErrorRec} />
              <Route path="/production/imports" component={ImportData} />
              <Route path="/production/charts" component={ChartPage} />

              <Route path="/productionmd/records" component={ProdRecMd} />

              <Route path="/productionbts/records" component={ProdRecBts} />
              <Route
                path="/production/importdatabtss"
                component={ImportDataBts}
              />

              <Route path="/mlt/records" component={MltRec} />
              <Route path="/mlt/imports" component={ImportMltData} />

              <Route path="/lean/measures" component={AudMeasure} />
              <Route path="/lean/leanaudits/:filter" component={LeanAudit} />
              <Route path="/lean/leanaudits" component={LeanAudit} />
              <Route path="/lean/leanreports" component={LeanReport} />
              <Route path="/lean/one-pager-report" component={OnePagerReport} />
              <Route path="/lean/etrreport" component={ETRReport} />
              <Route path="/lean/mini-lines" component={MiniLineAudit} />
              <Route path="/lean/leanscorecard" component={LeanScoreCard} />
              <Route path="/lean/leanmetric" component={LeanMetric} />
              <Route path="/lean/leanauditsnb" component={LeanAuditNb} />
              <Route path="/notification/notifications/:filter" component={Alarm} />
              <Route path="/notification/notifications" component={Alarm} />
              <Route path="/lean/andonmeasures" component={AndonMeasure} />
              <Route path="/lean/pfameasures" component={PfaMeasure} />
              <Route path="/lean/andonaudits" component={AndonAudit} />
              <Route path="/lean/pfaaudits/:filter" component={PfaAudit} />
              <Route path="/lean/pfaaudits" component={PfaAudit} />
              <Route path="/lean/pfaauditgroups" component={PfaAuditGroup} />
              <Route
                path="/lean/quickchangeovermeasures"
                component={QuickOverChangeMeasure}
              />

              <Route
                path="/lean/quickchangeoveraudits"
                component={QuickOverChangeAudit}
              />

              <Route
                path="/lean/lean-activities"
                component={LeanActivityPage}
              />

              <Route path="/home/std-dashboard" component={StdDashboard} />
              <Redirect to="/" />
            </Switch>
          </div>

          <AppFooter colorMode={colorMode} />
        </div>

        {false && (
          <AppConfig
            inputStyle={inputStyle}
            onInputStyleChange={onInputStyleChange}
            rippleEffect={ripple}
            onRippleEffect={onRipple}
            menuMode={menuMode}
            onMenuModeChange={onMenuModeChange}
            inlineMenuPosition={inlineMenuPosition}
            onInlineMenuPositionChange={onInlineMenuPositionChange}
            colorMode={colorMode}
            onColorModeChange={onColorModeChange}
            menuTheme={menuTheme}
            onMenuThemeChange={onMenuThemeChange}
            topbarTheme={topbarTheme}
            onTopbarThemeChange={onTopbarThemeChange}
            theme={theme}
            onThemeChange={onThemeChange}
            isRTL={isRTL}
            onRTLChange={onRTLChange}
          />
        )}

        <AppRightMenu
          rightMenuActive={rightMenuActive}
          onRightMenuButtonClick={onRightMenuButtonClick}
        />

        {mobileMenuActive && <div className="layout-mask modal-in"></div>}
      </div>
    </RTLContext.Provider>
  );
}

export default App;
