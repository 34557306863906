// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';
import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
import {AuthUtils} from '../../service/AuthUtils';
import {AuthConst} from '../../service/AppConstant';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {FactoryFilter} from '../common/FactoryFilter';
import {QuickOverChangeAuditForm} from './QuickOverChangeAuditForm';
import {QuickOverChangeAuditList} from './QuickOverChangeAuditList';
import {QuickChangeOverReport} from './QuickChangeOverReport';
import {QuickChangeOverAudItem} from './QuickChangeOverAudItem';

type Props = {};

const defFilter = {
  factory: null,
  facGroups: [],
  years: [],
};

export function QuickOverChangeAudit(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const globalRef = useSelector((state) => state.global);
  const rtParams = useParams();
  const [selQuickOverChangeAudit, setSelQuickOverChangeAudit] = useState<any>();
  const [reload, setReload] = useState(false);
  const [filter, setFilter] = useState({...defFilter});
  const [showFacGroup, setShowFacGroup] = useState(false);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let initFil = {...defFilter};
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      initFil['factory'] = factories[0];
    }
    let di: any = Utils.getDateInfo() || {};
    initFil = {
      ...initFil,
      years: [di.year],
    };

    let filVal = rtParams.filter;
    if (filVal && globalRef) {
      let inpFilter = atob(filVal);

      let inpFil = globalRef[inpFilter];

      initFil = {
        ...initFil,
        ...inpFil,
      };

      let {facGroups} = initFil;
      if (facGroups && facGroups.length > 0) {
        setShowFacGroup(true);
      }
    }

    setFilter(initFil);
  }, [auth, globalRef, rtParams]);

  const _onQuickOverChangeAuditSelected = (evt: any) => {
    setSelQuickOverChangeAudit(evt.value);
  };

  const _onNewQuickOverChangeAudit: any = (evt: any) => {
    setSelQuickOverChangeAudit({});
  };

  const _onLeanAuditAction = (evt: any) => {
    setSelQuickOverChangeAudit({});
    setReload(!reload);
  };

  const _onDelete: (evt: any) => void = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selQuickOverChangeAudit.id) {
      return;
    }
    confirmDialog({
      message: 'Are you sure you want to delete assessment?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .deleteQuickOverChangeAudit(selQuickOverChangeAudit.id)
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors} = resp;
            if (errors.length > 0) {
              UIUtils.showError({errors, toast: toastRef.current});
            }
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            UIUtils.showError({error, toast: toastRef.current});
          })
          .finally(() => {
            setSelQuickOverChangeAudit(null);
            setReload(!reload);
          });
      },
      reject: () => {},
    });
  };

  const _onFilterAction: EvtHandler = (evt: any) => {
    let {type, value} = evt;
    if (type === 'filterChanged') {
      setFilter({...value});
      setSelQuickOverChangeAudit({});
    }
  };

  const _onItemAction: EvtHandler = (evt: any) => {
    setReload(!reload);
  };

  let listTitle = 'Available quick changeover audits';
  let authLevel = AuthUtils.getAuthLevel({auth});
  let canEdit =
    authLevel < AuthConst.AUTH_LVL_NB ||
    AuthUtils.isAuth({auth, roles: ['NB_EDITOR', 'NB_ADMIN']});
  let disFacGroup = authLevel >= AuthConst.AUTH_LVL_FACTORY;
  let {factory, years} = filter;


  return (
    <>
      <Toast ref={toastRef} />

      <div>
        <FactoryFilter
          scope="factory"
          filter={filter}
          showFacGroup={showFacGroup}
          disFacGroup={disFacGroup}
          multiFacGroups={true}
          showFactory={true}
          showYears={true}
          onAction={_onFilterAction}
        />
        {/*<LeanAuditFilter filter={initFilter} onAction={_onFilterAction} />*/}
      </div>

      <div className="grid grid-nogutter mb-2">
        <div className="col-12 lg:col-8 pr-2">
          <QuickOverChangeAuditList
            byFactory={factory}
            byYears={years}
            header={listTitle}
            reload={reload}
            selection={selQuickOverChangeAudit}
            onSelect={_onQuickOverChangeAuditSelected}
          />
          {canEdit && (
            <div className="p-d-flex p-jc-end p-my-2">
              <Button
                label="New audit"
                icon="pi pi-plus"
                className="p-ml-2"
                onClick={_onNewQuickOverChangeAudit}
              />
              {selQuickOverChangeAudit && selQuickOverChangeAudit.id && (
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  className="p-ml-2"
                  onClick={_onDelete}
                  disabled={selQuickOverChangeAudit.flag === 1}
                />
              )}
            </div>
          )}
        </div>
        <div className="col-12 lg:col-4">
          <QuickOverChangeAuditForm
            readOnly={!canEdit}
            audit={selQuickOverChangeAudit}
            onAction={_onLeanAuditAction}
          />
        </div>
      </div>

      <TabView>
        <TabPanel header="Quick over change audit details">
          {selQuickOverChangeAudit && selQuickOverChangeAudit.id && (
            <QuickChangeOverAudItem
              audit={selQuickOverChangeAudit}
              onAction={_onItemAction}
            />
          )}
        </TabPanel>
        <TabPanel header="Quick Change Over Reports">
          {selQuickOverChangeAudit && selQuickOverChangeAudit.id && (
            <QuickChangeOverReport
              audit={selQuickOverChangeAudit}
              onAction={_onItemAction}
            />
          )}
        </TabPanel>
      </TabView>
    </>
  );
}
