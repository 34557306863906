// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
// import {AutoComplete} from 'primereact/autocomplete';


import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

//$FlowFixMe[cannot-resolve-module]
import {Checkbox} from 'primereact/checkbox';
//$FlowFixMe[cannot-resolve-module]
import {Calendar} from 'primereact/calendar';
type Props = {
  readOnly?: boolean,
  leanAudItem: any,
  cutOffDate: any,
  onAction: (evt: any) => void,
};

const defLeanAudItem = {
  __cls__: 'com.nb.lmrs.model.entity.PfaAudItem',
  flag: 0,
  code: '',
  name: '',
  description: '',
  processType: null,
  applicable: false,
  qtyAvailableMachine: null,
  qtyImplementation: null,
  qtyRequiredMachine: null,
  adoptionRate: null,
  massProductionDate: null,
  isStrength: null,
  isComformance: null,
  isOfi: null,
};

export function PfaAudItemForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {readOnly, leanAudItem, cutOffDate} = props;
  const [editLeanAudItem, setEditLeanAudItem] = useState<any>();

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  console.log('cutOffDate', cutOffDate);
  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
    let obj = leanAudItem || {};
    obj = {
      ...defLeanAudItem,
      ...obj,
    };

    obj = Utils.adjustPercField(
      obj,
      ['adoptionRate'],
      true,
    );
    let {massProductionDate,applicable} = obj;
    if (massProductionDate) {
      obj = {
        ...obj,
        massProductionDate: Utils.toJsDate(massProductionDate),
      };
    }

    if (applicable === false) {
        obj = {
            ...obj,
           adoptionRate: 'N/A',
          };
    }

    setEditLeanAudItem(obj);
  }, [leanAudItem]);


  const _processValueChanged: (fd: string, val: any) => any = (
    fd: string,
    val: any,
  ) => {
    let newIt = {...editLeanAudItem};
    newIt[fd] = val;

    // Kiểm tra điều kiện và cập nhật adoptionRate
    if (newIt.applicable === false) {
      // Trường hợp applicable là false, lưu trữ giá trị "N/A"
      newIt.adoptionRate = 'N/A';
    } else if (
      newIt.applicable === true &&
      newIt.qtyAvailableMachine > 0 &&
      newIt.qtyImplementation === 0
    ) {
      // Trường hợp applicable = true, qtyAvailableMachine > 0 và qtyImplementation = 0, trả về 0
      newIt.adoptionRate = 0;
    } else if (newIt.applicable === true) {
      try {
        // Nếu applicable là true, thực hiện phép chia qtyImplementation/qtyRequiredMachine
        newIt.adoptionRate = (
          newIt.qtyImplementation / newIt.qtyRequiredMachine
        ).toFixed(2);
        newIt = Utils.adjustPercField(newIt, ['adoptionRate'], true);
      } catch (error) {
        // Bắt lỗi và trả về 0 nếu có lỗi
        newIt.adoptionRate = 0;
      }
    } else {
      // Nếu applicable không phải là true, trả về 0
      newIt.adoptionRate = 'N/A';
    }

    if (!newIt.qtyAvailableMachine || !newIt.massProductionDate) {
      newIt.implementationTime = ''; // Nếu một trong hai giá trị trống, implementationTime là rỗng
    } else {
      try {
        // Tính toán thời gian thực hiện (số tháng)
        const massProductionDate = new Date(newIt.massProductionDate);
        console.log('massProductionDate', massProductionDate);
        const cutOffDateJs = new Date(cutOffDate); // sử dụng cutOffDate từ props
        console.log('cutOffDate', cutOffDateJs);
        const monthsDiff = calculateMonthsDiff(
          massProductionDate,
          cutOffDateJs,
        );
        console.log(monthsDiff)
        newIt.implementationTime = monthsDiff; // Gán số tháng vào implementationTime
      } catch (error) {
        console.log('error', error);
        newIt.implementationTime = ''; // Nếu có lỗi trong việc tính toán
      }
    }

    setEditLeanAudItem(newIt);
  };

  const _validateLeanAudItem: () => any = () => {
    let obj = {...editLeanAudItem};
    let {applicable} = obj;
    if (applicable === false) {
        obj = {
            ...obj,
              adoptionRate: null,
            };
    }
    obj = Utils.adjustPercField(obj, ['adoptionRate'], false);
    let {massProductionDate} = obj;
    if (!massProductionDate) {
      UIUtils.showError({
        detail: 'Missing audit date',
        toast: toastRef.current,
      });
      return false;
    }
    obj['massProductionDate'] = Utils.jsDateToStr(massProductionDate);

    return obj;
  };
  const calculateMonthsDiff = (startDate, endDate) => {
    const yearsDiff = endDate.getFullYear() - startDate.getFullYear();
    const monthsDiff = endDate.getMonth() - startDate.getMonth();
    return yearsDiff * 12 + monthsDiff;
  };
  const _onSave: EvtHandler = (evt: any) => {
    // setShowCfmDlg(false);
    let fac = _validateLeanAudItem();
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update leanAudItem type?'
        : 'Are you sure you want to create leanAudItem type?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updatePfaAuditItem({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showWarning({
                summary: 'Warning',
                detail: String(errors[0].message),
                toast: toastRef.current,
              });
              let {onAction} = props;
              onAction &&
                onAction({
                  type: 'auditItemSaved',
                  value: data.entity,
                });
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'auditItemSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };



  if (!editLeanAudItem) {
    return null;
  }

  const saveLbl = editLeanAudItem.id
    ? 'Update leanAudItem type'
    : 'Create leanAudItem type';
  const isNew = !editLeanAudItem.id;
  // //console.log.*$

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/edit leanAudItem type">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editLeanAudItem.code}
                onChange={(e) => {
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    code: e.target.value,
                  });
                }}
                disabled={!isNew || readOnly}
              />
              <label>Code</label>
            </span>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editLeanAudItem.name}
                onChange={(e) => {
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    name: e.target.value,
                  });
                }}
                // onKeyDown={_onKeyDown}
                disabled={readOnly}
              />
              <label>Name</label>
            </span>
          </div>



          <div className="p-col-12 p-xl-6 p-lg-6">
            <Checkbox
              value={editLeanAudItem.applicable}
              onChange={(e) => _processValueChanged('applicable', e.checked)}
              checked={editLeanAudItem.applicable}
            />
            <label className="p-checkbox-label ml-1">Applicable</label>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputText
                keyfilter="pint"
                value={editLeanAudItem.qtyAvailableMachine}
                onChange={(e) =>
                  _processValueChanged('qtyAvailableMachine', e.target.value)
                }
                disabled={readOnly}
              />
              <label>Quantity available machine</label>
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputText
                keyfilter="pint"
                value={editLeanAudItem.qtyImplementation}
                onChange={(e) =>
                  _processValueChanged('qtyImplementation', e.target.value)
                }
                disabled={readOnly}
              />
              <label>Quantity implementation</label>
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputText
                keyfilter="pint"
                value={editLeanAudItem.qtyRequiredMachine}
                onChange={(e) =>
                  _processValueChanged('qtyRequiredMachine', e.target.value)
                }
                disabled={readOnly}
              />
              <label>Quantity required machine</label>
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <InputText value={editLeanAudItem.adoptionRate} disabled={true} />
              <label>Adoption rate (%)</label>
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <div className="p-col-12">
              <span className="p-float-label">
                <Calendar
                  value={editLeanAudItem.massProductionDate}
                  dateFormat="dd/mm/yy"
                  onChange={(e) =>
                    _processValueChanged('massProductionDate', e.value)
                  }></Calendar>
                <label>Mass production date</label>
              </span>
            </div>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editLeanAudItem.implementationTime}
                disabled={true}
              />
              <label>Implementation time(Month)</label>
            </span>
          </div>
          <div className="p-col-12 p-xl-4 p-lg-4">
            <Checkbox
              value={editLeanAudItem.isStrength}
              onChange={(e) => _processValueChanged('isStrength', e.checked)}
              checked={editLeanAudItem.isStrength}
            />
            <label className="p-checkbox-label ml-1">Strength</label>
          </div>
          <div className="p-col-12 p-xl-4 p-lg-4">
            <Checkbox
              value={editLeanAudItem.isConformance}
              onChange={(e) => _processValueChanged('isConformance', e.checked)}
              checked={editLeanAudItem.isConformance}
            />
            <label className="p-checkbox-label ml-1">Conformance</label>
          </div>
          <div className="p-col-12 p-xl-4 p-lg-4">
            <Checkbox
              value={editLeanAudItem.isOfi}
              onChange={(e) => _processValueChanged('isOfi', e.checked)}
              checked={editLeanAudItem.isOfi}
            />
            <label className="p-checkbox-label ml-1">OFI</label>
          </div>
          <div className="p-col-12 p-xl-4 p-lg-4">
            <Checkbox
              value={editLeanAudItem.isMinor}
              onChange={(e) => _processValueChanged('isMinor', e.checked)}
              checked={editLeanAudItem.isMinor}
            />
            <label className="p-checkbox-label ml-1">Minor</label>
          </div>
          <div className="p-col-12 p-xl-4 p-lg-4">
            <Checkbox
              value={editLeanAudItem.isMajor}
              onChange={(e) => _processValueChanged('isMajor', e.checked)}
              checked={editLeanAudItem.isMajor}
            />
            <label className="p-checkbox-label ml-1">Major</label>
          </div>



          <div className="p-col-12">
            <span className="p-float-label">
              <InputTextarea
                rows={2}
                value={editLeanAudItem.commment}
                onChange={(e) => {
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    commment: e.target.value,
                  });
                }}
                disabled={readOnly}
              />
              <label>Observation/Comment</label>
            </span>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
