// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';

import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
import {AuthUtils} from '../../service/AuthUtils';
import {AuthConst} from '../../service/AppConstant';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

import {FactoryFilter} from '../common/FactoryFilter';

import {MiniLineAuditForm} from './MiniLineAuditForm';
import {MiniLineAuditList} from './MiniLineAuditList';

type Props = {};

const defFilter = {
  factory: null,
  years: [],
};

export function MiniLineAudit(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const [selMiniLineAudit, setSelMiniLineAudit] = useState<any>();
  // const [showCfmDlg, setShowCfmDlg] = useState<any>(false);
  const [reload, setReload] = useState(false);
  const [filter, setFilter] = useState({...defFilter});
  // const [initFilter, setInitFilter] = useState({});

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  // const selMiniLineAuditRef = useRef();
  // useEffect(() => {
  //   selMiniLineAuditRef.current = selMiniLineAudit;
  // }, [selMiniLineAudit]);

  useEffect(() => {
    // console.log('effect // init api');
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    console.log('auth', auth);
    let authLvl = AuthUtils.getAuthLevel({auth});
    console.log('auth level ', authLvl);
    //init filter
    let initFil = {};
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      initFil['factory'] = factories[0];
    }
    let di: any = Utils.getDateInfo() || {};
    initFil = {
      ...initFil,
      years: [di.year],
    };

    setFilter(initFil);
  }, [auth]);

  // useEffect(() => {
  //   let di: any = Utils.getDateInfo() || {};
  //   let fil = {
  //     ...defFilter,
  //     years: [di.year],
  //   };
  //   setInitFilter({...fil});
  //   setFilter({...fil});
  // }, []);

  const _onMiniLineAuditSelected = (evt: any) => {
    // setSelMiniLineAudit(evt.value);
    console.log('select evt', evt);
    setSelMiniLineAudit(evt.value);
  };

  const _onNewMiniLineAudit: any = (evt: any) => {
    setSelMiniLineAudit({});
  };

  const _onMiniLineAuditAction = (evt: any) => {
    setSelMiniLineAudit({});
    setReload(!reload);
  };

  const _onDelete: (evt: any) => void = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    if (!selMiniLineAudit.id) {
      return;
    }
    confirmDialog({
      message: 'Are you sure you want to delete mini line record?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .deleteMiniLineAudit(selMiniLineAudit.id)
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors} = resp;
            if (errors.length > 0) {
              console.log('deleteMiniLineAudit // return errors', errors);
              UIUtils.showError({errors, toast: toastRef.current});
            }
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            console.log('deleteMiniLineAudit // network error', error);
            UIUtils.showError({error, toast: toastRef.current});
          })
          .finally(() => {
            setSelMiniLineAudit(null);
            setReload(!reload);
          });
      },
      reject: () => {},
    });
  };

  const _onFilterAction: EvtHandler = (evt: any) => {
    let {type, value} = evt;
    if (type === 'filterChanged') {
      setFilter({...value});
      setSelMiniLineAudit({});
    }
  };

  // const cfmDlgFooter = (
  //   <div className="text-right">
  //     <Button label="Yes" icon="pi pi-check" onClick={_onDeletMiniLineAudit} />
  //     <Button
  //       label="No"
  //       icon="pi pi-times"
  //       onClick={(e) => {
  //         setShowCfmDlg(false);
  //       }}
  //     />
  //   </div>
  // );

  let listTitle = 'Available mini line records';
  let authLevel = AuthUtils.getAuthLevel({auth});
  let canEdit =
    authLevel < AuthConst.AUTH_LVL_NB ||
    AuthUtils.isAuth({auth, roles: ['FACTORY_ADMIN', 'NB_ADMIN']});

  let {factory, years} = filter;
  // let showChart = factory && factory.id && years && years.length > 0;

  return (
    <>
      <Toast ref={toastRef} />

      <div>
        <FactoryFilter
          scope="factory"
          filter={filter}
          showFactory={true}
          showYears={true}
          onAction={_onFilterAction}
        />
        {/*<MiniLineAuditFilter filter={initFilter} onAction={_onFilterAction} />*/}
      </div>

      <div className="grid grid-nogutter mb-2">
        <div className="col-12 lg:col-8 pr-2">
          <MiniLineAuditList
            byFactory={factory}
            byYears={years}
            header={listTitle}
            reload={reload}
            selection={selMiniLineAudit}
            onSelect={_onMiniLineAuditSelected}
          />
          {canEdit && (
            <div className="p-d-flex p-jc-end p-my-2">
              <Button
                label="New"
                icon="pi pi-plus"
                className="p-ml-2"
                onClick={_onNewMiniLineAudit}
              />
              {selMiniLineAudit && selMiniLineAudit.id && (
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  className="p-ml-2"
                  onClick={_onDelete}
                  disabled={selMiniLineAudit.flag === 1}
                />
              )}
            </div>
          )}
        </div>
        <div className="col-12 lg:col-4">
          <MiniLineAuditForm
            readOnly={!canEdit}
            audit={selMiniLineAudit}
            onAction={_onMiniLineAuditAction}
          />
        </div>
      </div>

      {/*<Dialog
        header="Confirm delete production line"
        footer={cfmDlgFooter}
        visible={showCfmDlg}
        style={{width: '400px'}}
        modal={true}
        onHide={() => {
          setShowCfmDlg(false);
        }}>
        <span>Are you sure to delete selected production line?</span>
      </Dialog>*/}
    </>
  );
}
