// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
// import {Dialog} from 'primereact/dialog';
// import {Checkbox} from 'primereact/checkbox';
//$FlowFixMe[cannot-resolve-module]
import {AutoComplete} from 'primereact/autocomplete';
// import {Dropdown} from 'primereact/dropdown';
// import {InputNumber} from 'primereact/inputnumber';
//$FlowFixMe[cannot-resolve-module]
import {Calendar} from 'primereact/calendar';

//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';

import type {ApiProps} from '../../service/Api';

import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {updateAppAction} from '../../service/app/action';

type Props = {
  prodRec: any,
  onAction?: (evt: any) => void,
};

const defProdRec = {
  __cls__: 'com.nb.lmrs.model.entity.ProdRecBts',
  id: 0,
  flag: 0,
  summary: '',
  planPo: '',
  planSize: '',
  planTotalprs: 0,
  actualPo: '',
  actualSize: '',
  recDate: null,
  line: null,
  factory: null,
};

export function ProdRecBtsForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const cachedRec = useSelector((state) => state.app.prodRec);
  // let {t} = useTranslation();
  const dispatch = useDispatch();

  const {prodRec} = props;
  const [editProdRec, setEditProdRec] = useState<any>();
  const [suggFactories, setSuggFactories] = useState([]);
  const [suggLines, setSuggLines] = useState([]);
  // const [suggOperators, setSuggOperators] = useState([]);
  // const [suggShifts, setSuggShifts] = useState([]);
  const [defFactory, setDefFactory] = useState();

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      setDefFactory(factories[0]);
    }
  }, [auth]);

  useEffect(() => {
    // //console.log.*$
    //console.log.*$

    let curProdRec = prodRec || {};
    curProdRec = {
      ...defProdRec,
      recDate: new Date(),
      ...cachedRec,
      ...curProdRec,
    };

    // //console.log.*$

    let isNew = !curProdRec.id;
    if (isNew && (!curProdRec.factory || !curProdRec.factory.id)) {
      curProdRec['factory'] = defFactory;
    }

    let {factory, line} = curProdRec;
    if (factory) {
      factory.label = `${factory.code} :: ${factory.name}`;
    }
    if (line) {
      line.label = `${line.code} :: ${line.name}`;
    }

    if (
      Utils.isString(curProdRec.recDate) &&
      !Utils.isEmpty(curProdRec.recDate)
    ) {
      let jsDate = Utils.toJsDate(curProdRec.recDate);
      curProdRec = {
        ...curProdRec,
        recDate: jsDate,
      };
      //console.log.*$
    }

    // //console.log.*$
    setEditProdRec(curProdRec);
  }, [prodRec, cachedRec, defFactory]);

  const _validateProdRec: () => any = () => {
    let obj = {...editProdRec};
    if (obj.id === 0) {
      obj['id'] = undefined;
    }
    //console.log.*$

    if (!obj.factory || !obj.factory.id) {
      UIUtils.showError({
        detail: 'Please select factory',
        toast: toastRef.current,
      });
      return false;
    }

    if (!obj.line || !obj.line.id) {
      UIUtils.showError({
        detail: 'Please select production line',
        toast: toastRef.current,
      });
      return false;
    }

    if (!obj.recDate) {
      UIUtils.showError({
        detail: 'Please enter record date',
        toast: toastRef.current,
      });
      return false;
    }
    obj.recDate = Utils.jsDateToStr(obj.recDate);

    // let planSize = Number(obj.planSize);
    // if (isNaN(planSize)) {
    //   UIUtils.showError({
    //     detail: 'Invalid number of plan size',
    //     toast: toastRef.current,
    //   });
    //   return false;
    // }

    // let nbProds = Number(obj.nbProds);
    // if (isNaN(nbProds)) {
    //   UIUtils.showError({
    //     detail: 'Invalid number of output products',
    //     toast: toastRef.current,
    //   });
    //   return false;
    // }

    // let outSi = Number(obj.outSi);
    // if (isNaN(outSi)) {
    //   UIUtils.showError({
    //     detail: 'Invalid number of output SI',
    //     toast: toastRef.current,
    //   });
    //   return false;
    // }
    // Utils.assign(obj, {nbHours, nbProds, outSi});

    // if (nbHours > 0) {
    //   obj = {
    //     ...obj,
    //     pph: Utils.round(obj.nbProds / obj.nbHours),
    //     pphSi: Utils.round(obj.outSi / obj.nbHours),
    //   };
    // }

    // obj.rate = Number(obj.rate);
    return obj;
  };

  const _onSuggFactories: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getFactories({search, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        // //console.log.*$
        data.list.forEach((it) => (it.label = `${it.code} :: ${it.name}`));
        setSuggFactories([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onSuggLines: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let filters = {};
    let params = {};
    let {factory} = editProdRec;
    if (factory && factory.id) {
      filters = {
        ...filters,
        byFactory: 'obj.factory.id = :factoryId',
      };
      params = {
        ...params,
        factoryId: {
          type: 'long',
          value: factory.id,
        },
      };
    }
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getMachLines({search, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        data.list.forEach((it) => (it.label = `${it.code} :: ${it.name}`));
        setSuggLines([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onSave = (evt: any) => {
    // setShowCfmDlg(false);
    let obj = _validateProdRec();
    if (!obj) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    if (!obj.id) {
      let prodRec = {
        factory: obj.factory,
        line: obj.line,
        shift: obj.shift,
      };
      dispatch && dispatch(updateAppAction({prodRec}));
    }

    confirmDialog({
      message: obj.id
        ? 'Are you sure you want to update production record?'
        : 'Are you sure you want to create new production record?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateProdrecsBts({...obj})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'prodRecSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _onCancel: (evt: any) => any = (evt: any) => {
    let {onAction} = props;
    onAction &&
      onAction({
        type: 'canceled',
      });
  };

  if (!editProdRec) {
    return null;
  }

  const saveLbl = 'Save';

  let {factory} = editProdRec;
  let lineSelDisabled = !factory || !factory.id;

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/edit prodRec">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12 p-lg-3 p-md-6">
            <span className="p-float-label">
              <InputText keyfilter="pint" value={editProdRec.id} disabled />
              <label>ID</label>
            </span>
          </div>
          <div className="p-col-12 p-lg-3 p-md-6">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                suggestions={suggFactories}
                dataKey="id"
                field="label"
                value={editProdRec.factory}
                completeMethod={_onSuggFactories}
                onChange={(e) =>
                  setEditProdRec({
                    ...editProdRec,
                    factory: e.value,
                    shift: null,
                    line: null,
                    operator: null,
                  })
                }
              />
              <label>Factory</label>
            </span>
          </div>

          <div className="p-col-12 p-lg-3 p-md-6">
            <span className="p-float-label">
              <AutoComplete
                dropdown
                suggestions={suggLines}
                field="label"
                value={editProdRec.line}
                completeMethod={_onSuggLines}
                onChange={(e) =>
                  setEditProdRec({
                    ...editProdRec,
                    line: e.value,
                  })
                }
                disabled={lineSelDisabled}
              />
              <label>Line</label>
            </span>
          </div>

          <div className="p-col-12 p-lg-3 p-md-6">
            <span className="p-float-label">
              <Calendar
                value={editProdRec.recDate}
                onChange={(e) =>
                  setEditProdRec({
                    ...editProdRec,
                    recDate: e.value,
                  })
                }></Calendar>
              <label>Record date</label>
            </span>
          </div>

          <div className="p-col-12 p-lg-3 p-md-6">
            <span className="p-float-label">
              <InputText
                value={editProdRec.planPo}
                onChange={(e) => {
                  setEditProdRec({...editProdRec, planPo: e.target.value});
                }}
              />
              <label>Plan PO</label>
            </span>
          </div>

          <div className="p-col-12 p-lg-3 p-md-6">
            <span className="p-float-label">
              <InputText
                value={editProdRec.planSize}
                onChange={(e) => {
                  setEditProdRec({...editProdRec, planSize: e.target.value});
                }}
              />
              <label>Plan size</label>
            </span>
          </div>

          <div className="p-col-12 p-lg-3 p-md-6">
            <span className="p-float-label">
              <InputText
                keyfilter="pint"
                value={editProdRec.planTotalprs}
                onChange={(e) => {
                  setEditProdRec({
                    ...editProdRec,
                    planTotalprs: e.target.value,
                  });
                }}
              />
              <label>Plan Total prs</label>
            </span>
          </div>

          <div className="p-col-12 p-lg-3 p-md-6">
            <span className="p-float-label">
              <InputText
                keyfilter="pint"
                value={editProdRec.actualPo}
                onChange={(e) => {
                  setEditProdRec({...editProdRec, actualPo: e.target.value});
                }}
              />
              <label>Actual PO</label>
            </span>
          </div>

          <div className="p-col-12 p-lg-3 p-md-6">
            <span className="p-float-label">
              <InputText
                value={editProdRec.actualSize}
                onChange={(e) => {
                  setEditProdRec({...editProdRec, actualSize: e.target.value});
                }}
              />
              <label>Actual size</label>
            </span>
          </div>

          <div className="p-col-12 p-lg-3 p-md-6">
            <span className="p-float-label">
              <InputText
                keyfilter="pint"
                value={editProdRec.actualTotalprs}
                onChange={(e) => {
                  setEditProdRec({
                    ...editProdRec,
                    actualTotalprs: e.target.value,
                  });
                }}
              />
              <label>Actual Total prs</label>
            </span>
          </div>

          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputTextarea
                  rows={2}
                  value={editProdRec.description}
                  onChange={(e) => {
                    setEditProdRec({
                      ...editProdRec,
                      description: e.target.value,
                    });
                  }}
                />
                <label>Summary</label>
              </span>
            </div>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-ml-2"
          onClick={_onCancel}
        />
      </div>
    </>
  );
}
