// @flow
import React, {useEffect, useRef, useState} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

//$FlowFixMe
import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
// import {TabView, TabPanel} from 'primereact/tabview';

//$FlowFixMe[cannot-resolve-module]
import type {ApiProps} from '../../service/Api';
//$FlowFixMe[cannot-resolve-module]
import type {EvtHandler} from '../types';
//import {AuthUtils} from '../../../service/AuthUtils'
import {Utils} from '../../../service/Utils';
import {AdminApi} from '../../../service/AdminApi';
// import {updateAppAction} from '../../service/app/action';

import {FactoryFilter} from '../../common/FactoryFilter';
// import {ChartFilter} from './ChartFilter';
import {PphDailyFacV2} from './PphDailyFacV2';
import {PphWeeklyFacV2} from './PphWeeklyFacV2';
import {PphMonthlyFacV2} from './PphMonthlyFacV2';
import {MltHistogramOfMonFacV2} from './MltHistogramOfMonFacV2';
import {MltMonthlyFacV2} from './MltMonthlyFacV2';
import {LeanScoreQuarterlyFacV2} from './LeanScoreQuarterlyFacV2';
import {MiniLinePieMonthlyFacV2} from './MiniLinePieMonthlyFacV2';
import {MiniLineVolBarMonthlyFacV2} from './MiniLineVolBarMonthlyFacV2';
import {MiniLineBarMonthlyFacV2} from './MiniLineBarMonthlyFacV2';
import {MiniLineVolPieMonthlyFacV2} from './MiniLineVolPieMonthlyFacV2';
import {PoMonthlyFacV2} from './PoMonthlyFacV2';
// import {PphDailyData} from './PphDailyData';
import {BtsMonthlyFacV2} from './BtsMonthlyFacV2';
// import {PphWeeklyData} from './PphWeeklyData';
// import {PphMonthlyData} from './PphMonthlyData';

type Props = {
  scope?: string,
  filter?: any,
  onAction?: (evt: any) => void,
};

export function FactoryV2Overview(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const globalRef = useSelector((stat) => stat.global);
  const history = useHistory();
  // const dispatch = useDispatch();

  const [pphDailyExpanded, setPphDailyExpanded] = useState(false);
  const [pphWeeklyExpanded, setPphWeeklyExpanded] = useState(false);
  const [pphMonthlyExpanded, setPphMonthlyExpanded] = useState(false);

  const [mltHistExpanded, setMltHistExpanded] = useState(false);
  const [mltMonExpanded, setMltMonExpanded] = useState(false);
  const [minlMonExpanded, setMinlMonExpanded] = useState(false);
  const [minlVolBarMonExpanded, setMinlVolBarMonExpanded] = useState(false);
  const [minlVolPieMonExpanded, setMinlVolPieMonExpanded] = useState(false);
  const [minlPieMonExpanded, setMinlPieMonExpanded] = useState(false);
  const [leanHoyExpanded, setLeanHoyExpanded] = useState(false);

  const {onAction, filter} = props;
  const scope = props.scope || 'region';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // console.log('effect // init api');
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    // console.log('Filter', filter, ' scope ', scope);
  }, [filter, scope]);

  const _onFilterAction: EvtHandler = (evt: any) => {
    // console.log('filter action event', evt);
    let {type, value} = evt;
    if (type === 'filterChanged') {
      // setFilter({...value});
      onAction &&
        onAction({
          type,
          value: {
            ...value,
            scope,
          },
        });
    }
  };

  // const _onShowDetails: EvtHandler = (evt: any) => {
  //   onAction &&
  //     onAction({
  //       type: 'showDetail',
  //       value: {
  //         scope,
  //       },
  //     });
  // };

  const _handleLeanScoreChartEvent: EvtHandler = (evt: any) => {
    let {type} = evt;
    if (!filter) {
      return;
    }
    // console.log('chart click // cur filter', {...filter});
    switch (type) {
      case 'chartClick':
        let facs = filter.factories || [];
        let factory = facs.length > 0 ? facs[0] : undefined;
        let chartFilter = {
          ...filter,
          years: [filter.year],
          factory,
        };
        let facGroups = filter.facGroups || [];
        chartFilter = {
          ...chartFilter,
          facGroups,
        };
        console.log('saving chart filter', chartFilter);
        // console.log('globalRef', globalRef);
        if (globalRef) {
          globalRef['chartFilter'] = {...chartFilter};
        }
        // dispatch(updateAppAction({chartFilter}));
        // let filStr: string = JSON.stringify('chartFilter') || '';
        let filStr = btoa('chartFilter');
        console.log(
          'Lean score chart clicked // redirect to audit page: ',
          filStr,
        );
        setTimeout(() => {
          history.push(`/lean/leanaudits/${filStr}`);
        }, 100);
        break;
      default:
        break;
    }
  };

  const _onChartAction: EvtHandler = (evt: any) => {
    console.log('RegionOverview // chart event', evt);
    let {src} = evt;
    switch (src) {
      case 'leanScoreQuarterly':
        _handleLeanScoreChartEvent(evt);
        break;
      default:
        break;
    }
  };

  if (!filter) {
    return null;
  }

  let {year, month, regions, countries, facGroups, factories, lines} = filter;
  if (
    !year ||
    (scope === 'country' && Utils.isEmpty(regions)) ||
    (scope === 'factory' && Utils.isEmpty(countries))
  ) {
    return null;
  }

  // let showDetailBtn =
  //   (scope === 'region' && regions && regions.length > 0) ||
  //   (scope === 'country' && countries && countries.length > 0) ||
  //   (scope === 'factory' && factories && factories.length > 0);
  let scRegion = scope === 'region';
  let scCountry = scope === 'country';
  let scGroup = scope === 'facGroup';
  // let scFactory = scope === 'factory';
  let scLine = scope === 'line';
  // console.log('filter', filter);
  // let filter2: any = filter;
  // let {regions} = filter2;
  // console.log('Regions', regions);
  // console.log('filter', filter);
  // console.log('scope', scope);
  return (
    <>
      <Toast ref={toastRef} />
      <div className="p-my-2">
        <FactoryFilter
          filter={filter}
          showRegion={scRegion}
          multiRegions={true}
          showCountry={scCountry}
          multiCountries={true}
          showFacGroup={scGroup}
          multiFacGroups={true}
          showFactory={true}
          multiFactories={true}
          showLine={scLine}
          multiLines={true}
          showYear={true}
          showMonth={true}
          onAction={_onFilterAction}
        />
      </div>
      <div className="grid">
        <div
          className={classNames({
            'col-12': true,
            'xl:col-4': !pphDailyExpanded,
          })}>
          <PphDailyFacV2
            scope={scope}
            regions={regions}
            countries={countries}
            facGroups={facGroups}
            factories={factories}
            lines={lines}
            year={year}
            month={month}
            expanded={pphDailyExpanded}
            onAction={(e) => {
              let {type} = e;
              if (type === 'toggleExpand') {
                setPphDailyExpanded(!pphDailyExpanded);
              }
            }}
          />
        </div>

        <div
          className={classNames({
            'col-12': true,
            'xl:col-4': !pphWeeklyExpanded,
          })}>
          <PphWeeklyFacV2
            scope={scope}
            regions={regions}
            countries={countries}
            facGroups={facGroups}
            factories={factories}
            lines={lines}
            year={year}
            month={month}
            expanded={pphWeeklyExpanded}
            onAction={(e) => {
              let {type} = e;
              if (type === 'toggleExpand') {
                setPphWeeklyExpanded(!pphWeeklyExpanded);
              }
            }}
          />
        </div>

        <div
          className={classNames({
            'col-12': true,
            'xl:col-4': !pphMonthlyExpanded,
          })}>
          <PphMonthlyFacV2
            scope={scope}
            regions={regions}
            countries={countries}
            facGroups={facGroups}
            factories={factories}
            lines={lines}
            year={year}
            month={month}
            expanded={pphMonthlyExpanded}
            onAction={(e) => {
              let {type} = e;
              if (type === 'toggleExpand') {
                setPphMonthlyExpanded(!pphMonthlyExpanded);
              }
            }}
          />
        </div>

        {scope !== 'line' && (
          <div
            className={classNames({
              'col-12': true,
              'xl:col-4': !mltHistExpanded,
            })}>
            <MltHistogramOfMonFacV2
              scope={scope}
              regions={regions}
              countries={countries}
              facGroups={facGroups}
              factories={factories}
              year={year}
              month={month}
              expanded={mltHistExpanded}
              onAction={(e) => {
                let {type} = e;
                if (type === 'toggleExpand') {
                  setMltHistExpanded(!mltHistExpanded);
                }
              }}
            />
          </div>
        )}

        {scope !== 'line' && (
          <div
            className={classNames({
              'col-12': true,
              'xl:col-4': !mltMonExpanded,
            })}>
            <MltMonthlyFacV2
              scope={scope}
              regions={regions}
              countries={countries}
              facGroups={facGroups}
              factories={factories}
              year={year}
              month={month}
              expanded={mltMonExpanded}
              onAction={(e) => {
                let {type} = e;
                if (type === 'toggleExpand') {
                  setMltMonExpanded(!mltMonExpanded);
                }
              }}
            />
          </div>
        )}

        {scope !== 'line' && (
          <div
            className={classNames({
              'col-12': true,
              'xl:col-4': !mltMonExpanded,
            })}>
            <PoMonthlyFacV2
              scope={scope}
              regions={regions}
              countries={countries}
              facGroups={facGroups}
              factories={factories}
              year={year}
              month={month}
              expanded={mltMonExpanded}
              onAction={(e) => {
                let {type} = e;
                if (type === 'toggleExpand') {
                  setMltMonExpanded(!mltMonExpanded);
                }
              }}
            />
          </div>
        )}
        {scope !== 'line' && (
          <div
            className={classNames({
              'col-12': true,
              'xl:col-4': !mltMonExpanded,
            })}>
            <BtsMonthlyFacV2
              scope={scope}
              regions={regions}
              countries={countries}
              facGroups={facGroups}
              factories={factories}
              year={year}
              month={month}
              expanded={mltMonExpanded}
              onAction={(e) => {
                let {type} = e;
                if (type === 'toggleExpand') {
                  setMltMonExpanded(!mltMonExpanded);
                }
              }}
            />
          </div>
        )}

        {scope !== 'line' && (
          <div
            className={classNames({
              'col-12': true,
              'xl:col-4': !minlVolBarMonExpanded,
            })}>
            <MiniLineVolBarMonthlyFacV2
              scope={scope}
              regions={regions}
              countries={countries}
              facGroups={facGroups}
              factories={factories}
              year={year}
              month={month}
              expanded={minlVolBarMonExpanded}
              onAction={(e) => {
                let {type} = e;
                if (type === 'toggleExpand') {
                  setMinlVolBarMonExpanded(!minlVolBarMonExpanded);
                }
              }}
            />
          </div>
        )}

        {scope !== 'line' && (
          <div
            className={classNames({
              'col-12': true,
              'xl:col-4': !minlMonExpanded,
            })}>
            <MiniLineBarMonthlyFacV2
              scope={scope}
              regions={regions}
              countries={countries}
              facGroups={facGroups}
              factories={factories}
              year={year}
              month={month}
              expanded={minlMonExpanded}
              onAction={(e) => {
                let {type} = e;
                if (type === 'toggleExpand') {
                  setMinlMonExpanded(!minlMonExpanded);
                }
              }}
            />
          </div>
        )}

        {scope !== 'line' && (
          <div
            className={classNames({
              'col-12': true,
              'xl:col-4': !minlVolPieMonExpanded,
            })}>
            <MiniLineVolPieMonthlyFacV2
              scope={scope}
              regions={regions}
              countries={countries}
              facGroups={facGroups}
              factories={factories}
              year={year}
              month={month}
              expanded={minlVolPieMonExpanded}
              onAction={(e) => {
                let {type} = e;
                if (type === 'toggleExpand') {
                  setMinlVolPieMonExpanded(!minlVolPieMonExpanded);
                }
              }}
            />
          </div>
        )}

        {scope !== 'line' && (
          <div
            className={classNames({
              'col-12': true,
              'xl:col-4': !minlPieMonExpanded,
            })}>
            <MiniLinePieMonthlyFacV2
              scope={scope}
              regions={regions}
              countries={countries}
              facGroups={facGroups}
              factories={factories}
              year={year}
              month={month}
              expanded={minlPieMonExpanded}
              onAction={(e) => {
                let {type} = e;
                if (type === 'toggleExpand') {
                  setMinlPieMonExpanded(!minlPieMonExpanded);
                }
              }}
            />
          </div>
        )}
        {scope !== 'line' && (
          <div
            className={classNames({
              'col-12': true,
              'xl:col-4': !leanHoyExpanded,
            })}>
            <LeanScoreQuarterlyFacV2
              scope={scope}
              regions={regions}
              countries={countries}
              facGroups={facGroups}
              factories={factories}
              year={year}
              month={month}
              expanded={leanHoyExpanded}
              onAction={(e) => {
                let {type} = e;
                if (type === 'toggleExpand') {
                  setLeanHoyExpanded(!leanHoyExpanded);
                  return;
                }
                _onChartAction(e);
              }}
            />
          </div>
        )}
      </div>

      {/*
      </div>
      <div className="flex justify-content-end">
        {showDetailBtn && (
          <Button
            label="Details"
            icon="pi pi-arrow-right"
            onClick={_onShowDetails}
          />
        )}
      </div> */}
    </>
  );
}
