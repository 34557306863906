// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
import {Subject} from 'rxjs';
//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  byType?: string,
  byFactory?: any,
  byFactories?: any[],
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function LeanActivityList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const {t} = useTranslation();

  const {byType, byFactory, byFactories} = props;

  const [leanActivitiesTblData, setLeanActivitiesTblData] = useState([]);
  const [leanActivitiesTblStart, setLeanActivitiesTblStart] = useState(0);
  const [leanActivitiesTblLimit] = useState(10);
  const [leanActivitiesTblNbRows, setLeanActivitiesTblNbRows] = useState(0);
  const [selLeanActivity, setSelLeanActivity] = useState();
  const [selLeanActivities, setSelLeanActivities] = useState([]);
  const [sumWeight, setSumWeight] = useState(0);
  const [sortMeta, setSortMeta] = useState([
    {
      field: 'obj.createdAt',
      order: -1,
    },
  ]);
  const [sortField, setSortField] = useState('obj.idNo');
  const [sortOrder, setSortOrder] = useState(1);

  const {reload, selection, header, command} = props;
  const selMode = props.selectionMode || 'single';
  const {search} = app || '';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  const _fetchData = useCallback(async (options) => {
    // //console.log.*$
    // //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let {
      byType,
      byFactory,
      byFactories,
      search,
      leanActivitiesTblStart,
      leanActivitiesTblLimit,
      sortMeta,
    } = options;
    let start = leanActivitiesTblStart;
    let limit = leanActivitiesTblLimit;

    let joins = [];
    let filters = {};
    let params = {};

    if (byType) {
      filters = {
        ...filters,
        byType: 'obj.type = :byType',
      };
      params = {
        ...params,
        byType: {
          type: 'enum:com.nb.lmrs.model.ActivityType',
          value: byType,
        },
      };
    }

    let factories = byFactories || [];
    if (byFactory && byFactory.id) {
      factories.push(byFactory);
    }
    if (factories.length > 0) {
      filters = {
        ...filters,
        byFactories: 'obj.factory.id in (:byFacIds)',
      };
      let byFacIds = factories.map((it) => {
        return {
          type: 'long',
          value: it.id,
        };
      });
      params = {
        ...params,
        byFacIds,
      };
    }

    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });
    //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);

    api
      .getLeanActivities({
        search,
        joins,
        filters,
        params,
        start,
        limit,
        sorts,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        ////console.log.*$
        setLeanActivitiesTblData([...data.list]);
        setLeanActivitiesTblNbRows(data.count);
        let sw = data.sumWeight || 0;
        setSumWeight(sw);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    let subj = new Subject();
    //console.log.*$
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      //console.log.*$
      subj.complete();
    };
  }, [_fetchData]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelLeanActivity({...selection});
      } else {
        setSelLeanActivities([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelLeanActivity(null);
      } else {
        setSelLeanActivities([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      byType,
      byFactory,
      byFactories,
      search,
      leanActivitiesTblStart,
      leanActivitiesTblLimit,
      sortMeta,
    });
  }, [
    byType,
    byFactory,
    byFactories,
    search,
    leanActivitiesTblStart,
    leanActivitiesTblLimit,
    reload,
    sortMeta,
  ]);

  const _onLeanActivitySelected = (evt: any) => {
    if (selMode === 'single') {
      setSelLeanActivity(evt.value);
    } else {
      setSelLeanActivities(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    // //console.log.*$
    setLeanActivitiesTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };

  const _renderFactoryCol: TblColRender = (row, col) => {
    let {factory} = row;
    return factory ? `${factory.code} :: ${factory.name}` : null;
  };

  const _renderPlStaDateCol: TblColRender = (row, col) => {
    let {plStaDate} = row;
    return plStaDate ? Utils.reFmtDate(plStaDate, 'DD/MM/YYYY') : null;
  };

  const _renderPlFinDateCol: TblColRender = (row, col) => {
    let {plFinDate} = row;
    return plFinDate ? Utils.reFmtDate(plFinDate, 'DD/MM/YYYY') : null;
  };

  let pagingLeft = <span></span>;
  let pagingRight = (
    <span>Total weight: {Utils.fmtDecimal(sumWeight * 100, 2)}%</span>
  );

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header}
          value={leanActivitiesTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          paginatorLeft={pagingLeft}
          paginatorRight={pagingRight}
          rows={leanActivitiesTblLimit}
          first={leanActivitiesTblStart}
          totalRecords={leanActivitiesTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selLeanActivity}
          onSelectionChange={_onLeanActivitySelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            field="name"
            header="Name"
            headerStyle={{width: 300}}
            sortable
            sortField="obj.name"
          />
          <Column
            field="status"
            header="Status"
            headerStyle={{width: 100}}
            sortable={true}
            sortField="obj.status"
          />
          <Column
            body={_renderFactoryCol}
            header="Factory"
            headerStyle={{width: 300}}
            sortable={true}
            sortField="obj.factory.name"
          />
          <Column
            body={_renderPlStaDateCol}
            header="Updated Date"
            headerStyle={{width: 120}}
            sortable={true}
            sortField="obj.plStaDate"
          />
          <Column
            body={_renderPlFinDateCol}
            header="Due Date"
            headerStyle={{width: 120}}
            sortable={true}
            sortField="obj.plFinDate"
          />
          <Column
            field="beneDesc"
            header="Benefit"
            headerStyle={{width: 300}}
          />
          <Column field="impaDesc" header="Impact" headerStyle={{width: 300}} />
          <Column
            field="description"
            header="Status summary"
            headerStyle={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={leanActivitiesTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={leanActivitiesTblLimit}
          first={leanActivitiesTblStart}
          totalRecords={leanActivitiesTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selection={selLeanActivities}
          onSelectionChange={_onLeanActivitySelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            selectionMode="multiple"
            headerStyle={{width: 50}}
            headerClassName="text-left"
            bodyClassName="text-left"></Column>
          <Column
            field="name"
            header="Name"
            headerStyle={{width: 300}}
            sortable
            sortField="obj.name"
          />
          <Column
            field="status"
            header="Status"
            headerStyle={{width: 100}}
            sortable={true}
            sortField="obj.status"
          />
          <Column
            body={_renderFactoryCol}
            header="Factory"
            headerStyle={{width: 300}}
            sortable={true}
            sortField="obj.factory.name"
          />
          <Column
            body={_renderPlStaDateCol}
            header="Start Date"
            headerStyle={{width: 120}}
            sortable={true}
            sortField="obj.plStaDate"
          />
          <Column
            body={_renderPlFinDateCol}
            header="Due Date"
            headerStyle={{width: 120}}
            sortable={true}
            sortField="obj.plFinDate"
          />
          <Column
            field="beneDesc"
            header="Benefit"
            headerStyle={{width: 300}}
          />
          <Column field="impaDesc" header="Impact" headerStyle={{width: 300}} />
          <Column
            field="description"
            header="Status summary"
            headerStyle={{width: 300}}
          />
          <Column
            field="id"
            header="#"
            headerStyle={{width: 100}}
            headerClassName="text-right pr-4"
            bodyClassName="text-right pr-4"
          />
        </DataTable>
      )}
    </>
  );
}
