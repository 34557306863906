// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
import {Subject} from 'rxjs';
//$FlowFixMe[cannot-resolve-module]
import {debounceTime} from 'rxjs/operators';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
type Props = {
  byAudit?: any,
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
};

export function QuickChangeOverAudItemList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const {t} = useTranslation();

  const {byAudit, reload, selection, header, command} = props;
 console.log(byAudit)
  const [leanAudItemsTblData, setLeanAudItemsTblData] = useState([]);
  const [leanAudItemsTblStart, setLeanAudItemsTblStart] = useState(0);
  const [leanAudItemsTblLimit] = useState(10);
  const [leanAudItemsTblNbRows, setLeanAudItemsTblNbRows] = useState(0);
  const [selLeanAudItem, setSelLeanAudItem] = useState();
  const [selLeanAudItems, setSelLeanAudItems] = useState([]);
  const [sortMeta, setSortMeta] = useState([
    {
      field: 'obj.rowNo',
      order: 1,
    },
  ]);
  const [sortField, setSortField] = useState('obj.rowNo');
  const [sortOrder, setSortOrder] = useState(1);

  const selMode = props.selectionMode || 'single';
  const {search} = app || '';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  const _fetchData = useCallback( (options) => {
    // //console.log.*$
    // //console.log.*$
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let {
      byAudit,
      search,
      leanAudItemsTblStart,
      leanAudItemsTblLimit,
      sortMeta,
    } = options;

    let start = leanAudItemsTblStart;
    let limit = leanAudItemsTblLimit;
    let joins = [];
    let filters = {};
    let params = {

    };

    if (byAudit && byAudit.id) {
      filters = {
        ...filters,
        byAdit: 'obj.audit.id = :auditId',
      };
      params = {
        ...params,
        auditId: {
          type: 'long',
          value: byAudit.id,
        },
      };
    }

    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });
    //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);

    api
      .getQuickOverChangeAuditItems({
        search,
        joins,
        filters,
        params,
        start,
        limit,
        sorts,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }

        setLeanAudItemsTblData([...data.list]);
        setLeanAudItemsTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    let subj = new Subject();
    //console.log.*$
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      //console.log.*$
      subj.complete();
    };
  }, [_fetchData]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelLeanAudItem({...selection});
      } else {
        setSelLeanAudItems([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelLeanAudItem(null);
      } else {
        setSelLeanAudItems([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.createdAt',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      byAudit,
      search,
      leanAudItemsTblStart,
      leanAudItemsTblLimit,
      sortMeta,
    });
  }, [
    byAudit,
    search,
    leanAudItemsTblStart,
    leanAudItemsTblLimit,
    reload,
    sortMeta,
  ]);

  const _onLeanAudItemSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelLeanAudItem(evt.value);
    } else {
      setSelLeanAudItems(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    // //console.log.*$
    setLeanAudItemsTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
  };


  const _renderProportionCol = (row, col) => {
    let {proportion} = row;
    return Utils.isNum(proportion)
      ? Utils.fmtDecimal(proportion * 100, 2)
      : null;
  };

  const _renderC2BScoreCol = (row, col) => {
    let {c2bScore} = row;
    let backgroundColor = "#808080";
    if(Number(c2bScore) === 0) {
        backgroundColor = "#FF0000";
    }else if(Number(c2bScore) === 0.5) {
        backgroundColor = "#ffff00";
    }else if(Number(c2bScore) === 1) {
        backgroundColor = "#92d050";
    }

    return (
        <div style={{
          backgroundColor,
          position: 'absolute',
          inset: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // borderRadius: '4px'
        }}>
            {Utils.isNum(c2bScore) ? Utils.fmtDecimal(c2bScore, 2)  : null}

        </div>
      );
    };


  const _renderTreatmentScoreCol = (row, col) => {
    let {treatmentScore} = row;
    let backgroundColor = "#808080";
    if(Number(treatmentScore) === 0) {
        backgroundColor = "#FF0000";
    }else if(Number(treatmentScore) === 0.5) {
        backgroundColor = "#ffff00";
    }else if(Number(treatmentScore) === 1) {
        backgroundColor = "#92d050";
    }

    return (
        <div style={{
          backgroundColor,
          position: 'absolute',
          inset: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // borderRadius: '4px'
        }}>
            {Utils.isNum(treatmentScore) ? Utils.fmtDecimal(treatmentScore, 2)  : null}

        </div>
      );
  };

  const _renderOutsolePressScoreCol = (row, col) => {
    let {outsolePressScore} = row;
    let backgroundColor = "#808080";
    if(Number(outsolePressScore) === 0) {
        backgroundColor = "#FF0000";
    }else if(Number(outsolePressScore) === 0.5) {
        backgroundColor = "#ffff00";
    }else if(Number(outsolePressScore) === 1) {
        backgroundColor = "#92d050";
    }

    return (
        <div style={{
          backgroundColor,
          position: 'absolute',
          inset: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // borderRadius: '4px'
        }}>
            {Utils.isNum(outsolePressScore) ? Utils.fmtDecimal(outsolePressScore, 2)  : null}

        </div>
      );
  };

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="#" rowSpan={2} style={{width: '120px'}} />
        <Column header="Type" rowSpan={2} style={{width: '150px'}} />
        <Column header="Name" rowSpan={2} style={{width: '350px'}} />
        <Column header="Proportion (%)" rowSpan={2} style={{width: '150px'}} />
        <Column header="Process" colSpan={3} style={{textAlign: 'center'}} />
        <Column header="Observation/Comment" rowSpan={2} style={{width: '300px'}} />
      </Row>
      <Row>
        {/* <Column header="#" field="rowNo" style={{width: '60px'}} sortable />
        <Column header="Type" field="measureType" style={{width: '150px'}} sortable />
        <Column header="Name" field="name" style={{width: '350px'}} sortable /> */}
        <Column
          header="C2B"
          body={_renderC2BScoreCol}
          style={{width: '100px'}}
          sortable
          sortField="obj.c2bScore"
        />
        <Column
          header="Treatment"
          body={_renderTreatmentScoreCol}
          style={{width: '100px'}}
          sortable
          sortField="obj.treatmentScore"
        />
        <Column
          header="Outsole Press"
          body={_renderOutsolePressScoreCol}
          style={{width: '100px'}}
          sortable
          sortField="obj.outsolePressScore"
        />
        {/* <Column
          header="Observation/Comment"
          field="comment"
          style={{width: '300px'}}
          body={(rowData) => (
            <div style={{whiteSpace: 'pre-wrap'}}>{rowData.comment}</div>
          )}
        /> */}
      </Row>
    </ColumnGroup>
    );

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={leanAudItemsTblData}
          dataKey="id"
          lazy={true}
          scrollable
          headerColumnGroup={headerGroup}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={leanAudItemsTblLimit}
          first={leanAudItemsTblStart}
          totalRecords={leanAudItemsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selLeanAudItem}
          onSelectionChange={_onLeanAudItemSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
       <Column
            field="rowNo"
            header="#"
            headerStyle={{width: 60}}
            sortable={true}
            sortField="obj.rowNo"
          />
          <Column
            field="measureType"
            header="Type"
            headerStyle={{width: 150}}
            sortable
            sortField="obj.measureType"
          />
          <Column
            field="name"
            header="Name"
            headerStyle={{width: 350}}
            sortable
            sortField="obj.name"
          />

          <Column
            body={_renderProportionCol}
            header="Proportion (%)"
            headerStyle={{width: 150}}
            // headerClassName="text-right"
            // bodyClassName="text-right"
            sortable={true}
            sortField="obj.proportion"
          />

          <Column
            body={_renderC2BScoreCol}
            header="C2B"
            headerStyle={{width: 100}}
            // headerClassName="text-right"
             bodyClassName="relative"
            sortable={true}
            sortField="obj.c2bScore"
          />

          <Column
            body={_renderTreatmentScoreCol}
            header="Treatment"
            headerStyle={{width: 100}}
            // headerClassName="text-right"
            bodyClassName="relative"
            sortable={true}
            sortField="obj.treatmentScore"
          />

          <Column
            body={_renderOutsolePressScoreCol}
            header="Outsole press"
            headerStyle={{width: 100}}
            // headerClassName="text-right"
            bodyClassName="relative"
            sortable={true}
            sortField="obj.outsolePressScore"
          />

          <Column
            field="comment"
            header="Observation/Comment"
            headerStyle={{width: 300}}
            headerClassName="text-right"
            bodyClassName="text-right"
            body={(rowData) => (
              <div style={{whiteSpace: 'pre-wrap'}}>{rowData.comment}</div>
            )}
          />
        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header || t('factory.title')}
          value={leanAudItemsTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={leanAudItemsTblLimit}
          first={leanAudItemsTblStart}
          totalRecords={leanAudItemsTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          scrollable
          selection={selLeanAudItems}
          onSelectionChange={_onLeanAudItemSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
           <Column
            field="rowNo"
            header="#"
            headerStyle={{width: 60}}
            sortable={true}
            sortField="obj.rowNo"
          />
          <Column
            field="measureType"
            header="Type"
            headerStyle={{width: 150}}
            sortable
            sortField="obj.measureType"
          />
          <Column
            field="name"
            header="Name"
            headerStyle={{width: 350}}
            sortable
            sortField="obj.name"
          />

          <Column
            body={_renderProportionCol}
            header="Proportion (%)"
            headerStyle={{width: 150}}
            // headerClassName="text-right"
            // bodyClassName="text-right"
            sortable={true}
            sortField="obj.proportion"
          />

          <Column
            body={_renderC2BScoreCol}
            header="C2B"
            headerStyle={{width: 100}}
            // headerClassName="text-right"
             bodyClassName="relative"
            sortable={true}
            sortField="obj.c2bScore"
          />

          <Column
            body={_renderTreatmentScoreCol}
            header="Treatment"
            headerStyle={{width: 100}}
            // headerClassName="text-right"
            bodyClassName="relative"
            sortable={true}
            sortField="obj.treatmentScore"
          />

          <Column
            body={_renderOutsolePressScoreCol}
            header="Outsole press"
            headerStyle={{width: 100}}
            // headerClassName="text-right"
            bodyClassName="relative"
            sortable={true}
            sortField="obj.outsolePressScore"
          />

          <Column
            field="comment"
            header="Observation/Comment"
            headerStyle={{width: 300}}
            headerClassName="text-right"
            bodyClassName="text-right"
            body={(rowData) => (
              <div style={{whiteSpace: 'pre-wrap'}}>{rowData.comment}</div>
            )}
          />
        </DataTable>
      )}
    </>
  );
}
