// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe
import {TabView, TabPanel} from 'primereact/tabview';
//import {AuthConst} from '../../service/AppConstant';
import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
import {AdminApi} from '../../service/AdminApi';
import {Utils} from '../../service/Utils';
import {FactoryFilter} from '../common/FactoryFilter';
//import {AuthConst} from '../../service/AppConstant';
import {ByScopeProdKpi} from './ByScopeProdKpi';
import {ProdKpiFilter} from './ProdKpiFilter';

type Props = {};

const defFilter = {
  line: null,
  lines: [],
  shift: null,
  factory: null,
};

export function ProdKpi(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const [filter, setFilter] = useState({...defFilter});

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let initFil = {};
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      initFil['factory'] = factories[0];
    }

    let di: any = Utils.getDateInfo() || {};
    initFil = {
      ...initFil,
      month: di.month,
      year: di.year,
    };

    setFilter(initFil);
  }, [auth]);

  const _onFilterAction: EvtHandler = (evt: any) => {
    let {type, value} = evt;
    if (type === 'filterChanged') {
      setFilter({...value});
      //forward action to higher level
      return;
    }
  };

  let scope = 'factory';

  let {factory} = filter;

  return (
    <>
      <Toast ref={toastRef} />
      <FactoryFilter
        scope="factory"
        filter={filter}
        showFactory={true}
        showYear={true}
        showMonth={true}
        onAction={_onFilterAction}
      />

      {/*<ProdKpiFilter
        scope="factory"
        filter={filter}
        onAction={_onFilterAction}
      />*/}

      <TabView>
        <TabPanel header="PPH">
          <ByScopeProdKpi scope={scope} kpiType="PPH" filter={filter} />
          {factory && factory.id && (
            <Panel header="Line Level PPH KPI" toggleable collapsed={false}>
              <ProdKpiFilter
                scope="line"
                filter={filter}
                onAction={_onFilterAction}
              />
              <ByScopeProdKpi scope="line" kpiType="PPH" filter={filter} />
            </Panel>
          )}
        </TabPanel>
        <TabPanel header="MLT">
          <ByScopeProdKpi scope={scope} kpiType="MLT" filter={filter} />
        </TabPanel>
        {/*  <TabPanel header="SI">
          <ByScopeProdKpi scope={scope} kpiType="SI" filter={filter} />
          </TabPanel>  */}
        {/* <TabPanel header="Mini Line">
          <ByScopeProdKpi scope={scope} kpiType="MINL_RATE" filter={filter} />
        </TabPanel> */}
        <TabPanel header="LEAN SCORE">
          <ByScopeProdKpi scope={scope} kpiType="LEAN_SCORE" filter={filter} />
        </TabPanel>
        <TabPanel header="BTS">
          <ByScopeProdKpi scope={scope} kpiType="BTS" filter={filter} />
        </TabPanel>
        <TabPanel header="PO">
          <ByScopeProdKpi scope={scope} kpiType="PO" filter={filter} />
        </TabPanel>
        {/* <TabPanel header="PPH YTD">
           <ByScopeProdKpi scope={scope} kpiType="PPH_YTD" filter={filter} />
       </TabPanel> */}
        <TabPanel header="PPH YTD TARGET">
          <ByScopeProdKpi scope={scope} kpiType="PPH_YTD_TAR" filter={filter} />
        </TabPanel>

        <TabPanel header="MLT YTD TARGET">
          <ByScopeProdKpi scope={scope} kpiType="MLT_YTD_TAR" filter={filter} />
        </TabPanel>
      </TabView>
    </>
  );
}
