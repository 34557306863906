// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {TabView, TabPanel} from 'primereact/tabview';

import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';

// import {AuthConst} from '../../service/AppConstant';
import {Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

import {FactoryFilter} from '../common/FactoryFilter';
// import {LeanAuditFilter} from './LeanAuditFilter';
import {PphRoadmapChart} from './PphRoadmapChart';
import {MltRoadmapChart} from './MltRoadmapChart';
import {LeanReportData} from './LeanReportData';
import {PphActivityList} from './PphActivityList';
import {MltActivityList} from './MltActivityList';

type Props = {};

const defFilter = {
  factory: null,
  years: [],
};

export function OnePagerReport(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  // const [reload, setReload] = useState(false);
  const [filter, setFilter] = useState({...defFilter});
  const [tabInd, setTabInd] = useState(0);
  // const [initFilter, setInitFilter] = useState({});

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  // const selPphRoadmapRef = useRef();
  // useEffect(() => {
  //   selPphRoadmapRef.current = selPphRoadmap;
  // }, [selPphRoadmap]);

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let initFil = {};
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      initFil['factory'] = factories[0];
    }
    let di: any = Utils.getDateInfo() || {};
    initFil = {
      ...initFil,
      years: [di.year],
    };

    setFilter(initFil);
  }, [auth]);

  const _onFilterAction: EvtHandler = (evt: any) => {
    let {type, value} = evt;
    if (type === 'filterChanged') {
      setFilter({...value});
    }
  };

  // const cfmDlgFooter = (
  //   <div className="text-right">
  //     <Button label="Yes" icon="pi pi-check" onClick={_onDeletPphRoadmap} />
  //     <Button
  //       label="No"
  //       icon="pi pi-times"
  //       onClick={(e) => {
  //         setShowCfmDlg(false);
  //       }}
  //     />
  //   </div>
  // );

  // let authLevel = AuthUtils.getAuthLevel({auth});
  // let canEdit: boolean =
  //   authLevel < AuthConst.AUTH_LVL_NB ||
  //   AuthUtils.isAuth({auth, roles: ['FACTORY_ADMIN']});

  let {factory, years} = filter;
  let showChart = factory && factory.id && years && years.length > 0;
  let adjYears = years
    ? years.map((it) => Number(it)).filter((it) => !isNaN(it))
    : [];

  return (
    <>
      <Toast ref={toastRef} />

      <TabView activeIndex={tabInd} onTabChange={(e) => setTabInd(e.index)}>
        <TabPanel header="One Pager Report">
          <div className="py-2">
            <FactoryFilter
              scope="factory"
              filter={filter}
              showFactory={true}
              showYears={true}
              showSwitchYear={true}
              onAction={_onFilterAction}
            />
            {/*<LeanAuditFilter filter={initFilter} onAction={_onFilterAction} />*/}
          </div>

          {showChart && (
            <div className="grid">
              <div className="col-12 xl:col-6">
                <PphRoadmapChart factory={factory} years={adjYears} />
              </div>
              <div className="col-12 xl:col-6">
                <MltRoadmapChart factory={factory} years={adjYears} />
              </div>
            </div>
          )}
          {showChart && (
            <div className="grid">
              <div className="col-12 xl:col-6">
                <LeanReportData
                  type="pph"
                  byFactory={factory}
                  byYears={adjYears}
                />
                <PphActivityList byFactory={factory} byYears={adjYears} />
              </div>
              <div className="col-12 xl:col-6">
                <LeanReportData
                  type="mlt"
                  byFactory={factory}
                  byYears={adjYears}
                />
                <MltActivityList byFactory={factory} byYears={adjYears} />
              </div>
            </div>
          )}
        </TabPanel>
      </TabView>

      {/*<Dialog
        header="Confirm delete production line"
        footer={cfmDlgFooter}
        visible={showCfmDlg}
        style={{width: '400px'}}
        modal={true}
        onHide={() => {
          setShowCfmDlg(false);
        }}>
        <span>Are you sure to delete selected production line?</span>
      </Dialog>*/}
    </>
  );
}
