// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

// import {Toast} from 'primereact/toast';
// import {Panel} from 'primereact/panel';
// import {InputText} from 'primereact/inputtext';
// import {InputTextarea} from 'primereact/inputtextarea';
// import {Button} from 'primereact/button';
// import {Dialog} from 'primereact/dialog';
// import {Checkbox} from 'primereact/checkbox';
//$FlowFixMe[cannot-resolve-module]
import {AutoComplete} from 'primereact/autocomplete';
// import {confirmDialog} from 'primereact/confirmdialog';

import type {ApiProps} from '../../service/Api';
// import {AuthUtils} from '../../service/AuthUtils';
// import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  scope?: string,
  filter: any,
  onAction: (evt: any) => void,
};

const defFilter = {
  search: '',
  shift: null,
  operator: null,
  line: null,
  lines: [],
  factory: null,
  lineGrp: null,
};

export function ProdKpiFilter(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {filter} = props;
  const scope = props.scope || 'factory';
  const [editFilter, setEditFilter] = useState({...defFilter});
  const [suggFactories, setSuggFactories] = useState([]);
  const [suggLines, setSuggLines] = useState([]);
  const [suggShifts, setSuggShifts] = useState([]);

  // const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
    let curFilter = filter || {};
    curFilter = {
      ...defFilter,
      ...curFilter,
    };
    // //console.log.*$
    setEditFilter(curFilter);
  }, [filter]);

  const _processFilterChange: (field: string, value: any) => any = (
    field: string,
    value: any,
  ) => {
    let newFil = {...editFilter};
    newFil[field] = value;

    if (field === 'factory') {
      newFil = {
        ...newFil,
        lines: [],
      };
    }

    setEditFilter({...newFil});
    let {onAction} = props;
    onAction &&
      onAction({
        type: 'filterChanged',
        value: {...newFil},
      });
  };

  const _onSuggFactories: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getFactories({search, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          // UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        data.list.forEach((it) => (it.label = `${it.code} :: ${it.name}`));
        setSuggFactories([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        // UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onSuggLines: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let filters = {};
    let params = {};
    let {factory} = editFilter;
    if (factory && factory.id) {
      filters = {
        ...filters,
        byFactory: 'obj.factory.id = :factoryId',
      };
      params = {
        ...params,
        factoryId: {
          type: 'long',
          value: factory.id,
        },
      };
    }
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getMachLines({search, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          // UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        setSuggLines([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        // UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onSuggShifts: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let filters = {};
    let params = {};
    let {factory} = editFilter;
    if (factory && factory.id) {
      filters = {
        ...filters,
        byFactory: 'obj.factory.id = :factoryId',
      };
      params = {
        ...params,
        factoryId: {
          type: 'long',
          value: factory.id,
        },
      };
    }
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getShifts({search, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          // UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        setSuggShifts([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        // UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const showFac = scope === 'factory';
  const showLine = scope === 'line';
  const showShift = scope === 'shift';

  return (
    <>
      <div className="grid p-fluid">
        {showFac && (
          <div className="col-12 xl:col-3 lg:col-6">
            <AutoComplete
              placeholder="Select factory"
              dropdown
              suggestions={suggFactories}
              field="label"
              value={editFilter.factory}
              completeMethod={_onSuggFactories}
              onChange={(e) => _processFilterChange('factory', e.value)}
            />
          </div>
        )}
        {showLine && (
          <>
            <div className="col-12 xl:col-3 lg:col-6">
              <AutoComplete
                placeholder="Select lines"
                dropdown
                suggestions={suggLines}
                field="name"
                value={editFilter.line}
                completeMethod={_onSuggLines}
                onChange={(e) => _processFilterChange('line', e.value)}
                disabled={!editFilter.factory || !editFilter.factory.id}
              />
            </div>
            {/*    <div className="col-12 xl:col-3 lg:col-6">
              <AutoComplete
                placeholder="Select shift"
                dropdown
                suggestions={suggShifts}
                field="name"
                value={editFilter.shift}
                completeMethod={_onSuggShifts}
                onChange={(e) => _processFilterChange('shift', e.value)}
                disabled={!editFilter.factory || !editFilter.factory.id}
              />
        </div> */}
          </>
        )}
        {showShift && (
          <div className="col-12 xl:col-3 lg:col-6">
            <AutoComplete
              placeholder="Select shift"
              dropdown
              suggestions={suggShifts}
              field="name"
              value={editFilter.shift}
              completeMethod={_onSuggShifts}
              onChange={(e) => _processFilterChange('shift', e.value)}
              disabled={!editFilter.factory || !editFilter.factory.id}
            />
          </div>
        )}
      </div>
    </>
  );
}
