// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';
import {Subject} from 'rxjs';
//$FlowFixMe
import {debounceTime} from 'rxjs/operators';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {DataTable} from 'primereact/datatable';
//$FlowFixMe[cannot-resolve-module]
import {Column} from 'primereact/column';

import type {ApiProps} from '../../service/Api';
import type {CommandType, TblColRender} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  byScope?: any,
  byKpiType?: string,
  byFactory?: any,
  byLine?: any,
  byLines?: any[],
  byYears?: any[],
  byShift?: any,
  byLiveFlag?: number,
  defSort?: any[],
  selectionMode?: string,
  selection?: any,
  header?: string,
  reload?: boolean,
  command?: CommandType,
  onSelect?: (evt: any) => void,
  onAction?: (evt: any) => void,
};

export function ScoreCardList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  // const app = useSelector((state) => state.app);
  // const {t} = useTranslation();
  const [prodKpisTblData, setProdKpisTblData] = useState([]);
  const [prodKpisTblStart, setProdKpisTblStart] = useState(0);
  const [prodKpisTblLimit] = useState(10);
  const [prodKpisTblNbRows, setProdKpisTblNbRows] = useState(0);
  const [selProdKpi, setSelProdKpi] = useState();
  const [selProdKpis, setSelProdKpis] = useState([]);
  const [sortMeta, setSortMeta] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);

  const {
    byScope,
    byKpiType,
    byFactory,
    byLine,
    byYears,
    byLines,
    byShift,
    byLiveFlag,
    defSort,
    selection,
    reload,
    header,
    command,
  } = props;
  const selMode = props.selectionMode || 'single';

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
    // //console.log.*$
  }, [conf, auth]);

  useEffect(() => {
    if (defSort && defSort.length > 0) {
      setSortMeta([...defSort]);
      setSortField(defSort[0].field);
      setSortOrder(defSort[0].order);
    }
  }, [defSort]);

  useEffect(() => {
    // //console.log.*$
    if (selection) {
      if (selMode === 'single') {
        setSelProdKpi({...selection});
      } else {
        setSelProdKpis([...selection]);
      }
    } else {
      if (selMode === 'single') {
        setSelProdKpi(null);
      } else {
        setSelProdKpis([]);
      }
    }
  }, [selection, selMode]);

  useEffect(() => {
    if (!command) {
      return;
    }
    let {name} = command;
    switch (name) {
      case 'resetSort':
        // //console.log.*$
        setSortMeta([
          {
            field: 'obj.qoy',
            order: -1,
          },
        ]);
        break;
      default:
        break;
    }
  }, [command]);

  const _fetchData = useCallback((options: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let {
      byKpiType,
      byFactory,
      byLine,
      byLines,
      byShift,
      byYears,
      byLiveFlag,
      prodKpisTblStart,
      prodKpisTblLimit,
      sortMeta,
    } = options;

    let joins = [
      // {
      //   type: 'left join',
      //   expr: 'obj.factory',
      //   alias: 'factory',
      // },
      // {
      //   type: 'left join',
      //   expr: 'obj.line',
      //   alias: 'line',
      // },
    ];

    byKpiType = byKpiType || 'PPH';
    let filters = {
      byType: 'obj.kpiType = :kpiType',
    };
    let params = {
      kpiType: {
        type: 'enum:com.nb.lmrs.model.KpiType',
        value: byKpiType,
      },
    };
    if (byYears && byYears.length > 0) {
      filters = {
        ...filters,
        byYears: 'obj.year in (:years)',
      };

      params = {
        ...params,
        years: [...byYears].map((year) => Number(year)),
      };
    }
    /*if (byScope) {
      filters = {
        ...filters,
        byScope: 'obj.scope = :scope',
      };
      params = {
        ...params,
        scope: byScope,
      };
    }
*/
    if (byFactory && byFactory.id) {
      filters = {
        ...filters,
        byFactory: 'obj.factoryId = :factoryId',
      };
      params = {
        ...params,
        factoryId: {
          type: 'long',
          value: byFactory.id,
        },
      };
    }

    if (byLine && byLine.id) {
      filters = {
        ...filters,
        byLine: 'obj.lineId = :lineId',
      };
      params = {
        ...params,
        lineId: {
          type: 'long',
          value: byLine.id,
        },
      };
    }

    if (byLines && byLines.length > 0) {
      filters = {
        ...filters,
        byLines: 'obj.lineId in (:lineIds)',
      };
      let lineIds = byLines.map((it) => {
        return {
          type: 'long',
          value: it.id,
        };
      });
      params = {
        ...params,
        lineIds,
      };
    }

    if (byShift && byShift.id) {
      filters = {
        ...filters,
        byShift: 'obj.shiftId = :shiftId',
      };
      params = {
        ...params,
        shiftId: {
          type: 'long',
          value: byShift.id,
        },
      };
    }

    if (byLiveFlag !== undefined) {
      filters = {
        ...filters,
        liveFlag: 'obj.flag = :liveFlag',
      };
      params = {
        ...params,
        liveFlag: byLiveFlag,
      };
    }

    let start = prodKpisTblStart;
    let limit = prodKpisTblLimit;

    // let sorts = ['-obj.invDate', '-obj.createdAt'];
    let sorts = sortMeta.map((sm) => {
      return sm.order > 0 ? sm.field : `-${sm.field}`;
    });
    //console.log.*$
    // let sortFds = sortMeta.map((sm) => sm.field);

    api
      .getLeanScoreCard({joins, filters, params, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }

        /*  list.forEach((rec) => {
          if (rec.factoryId) {
            rec['factory'] = factoryMap[rec.factoryId];
          }
          if (rec.lineId) {
            rec['line'] = lineMap[rec.lineId];
          }
          if (rec.shiftId) {
            rec['shift'] = shiftMap[rec.shiftId];
          }
          if (rec.operatorId) {
            rec['operator'] = operatorMap[rec.operatorId];
          }
        });*/
        //console.log.*$
        setProdKpisTblData([...data.list]);
        setProdKpisTblNbRows(data.count);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  }, []);

  useEffect(() => {
    let subj: any = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      //stop event source
      //console.log.*$
      subj.complete();
    };
  }, [_fetchData]);

  useEffect(() => {
    // //console.log.*$
    // //console.log.*$
    let fetchDataEvtSrc = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      byScope,
      byKpiType,
      byFactory,
      byLine,
      byLines,
      byYears,
      byShift,
      byLiveFlag,
      prodKpisTblStart,
      prodKpisTblLimit,
      sortMeta,
    });
  }, [
    byScope,
    byKpiType,
    byFactory,
    byLine,
    byLines,
    byYears,
    byShift,
    byLiveFlag,
    prodKpisTblStart,
    prodKpisTblLimit,
    reload,
    sortMeta,
  ]);

  const _onProdKpiSelected = (evt: any) => {
    if (selMode === 'single') {
      setSelProdKpi(evt.value);
    } else {
      setSelProdKpis(evt.value);
    }
    let {onSelect} = props;
    onSelect && onSelect(evt);
  };

  const _onInvsPaging = (evt: any) => {
    // //console.log.*$
    setProdKpisTblStart(evt.first);
  };

  const _onSort = (evt: any) => {
    //console.log.*$
    // Process multisort
    // let newMeta = [...evt.multiSortMeta];
    // let sortFds = newMeta.map((sm) => sm.field);
    // for (let sm of sortMeta) {
    //   if (!sortFds.includes(sm.field)) {
    //     sortFds.push(sm.field);
    //     newMeta.push(sm);
    //   }
    // }
    // setSortMeta([...newMeta]);

    //Single sort
    let newMeta = sortMeta.filter((sm) => sm.field !== evt.sortField);
    newMeta = [
      {
        field: evt.sortField,
        order: evt.sortOrder,
      },
      ...newMeta,
    ];
    // //console.log.*$
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
    setSortMeta(newMeta);
    let {onAction} = props;
    onAction &&
      onAction({
        type: 'sortChanged',
        value: [...newMeta],
      });
  };

  /*const _renderShiftCol: TblColRender = (row: any, col: any) => {
    let {shift} = row;
    return shift ? `${shift.name}` : null;
  };*/

  const _renderTargetValueCol: TblColRender = (row: any, col: any) => {
    let {targetValue} = row;
    return Utils.isNum(targetValue) ? Utils.fmtDecimal(targetValue) : null;
  };

  const _renderExpectationValueCol: TblColRender = (row: any, col: any) => {
    let {value1} = row;
    return Utils.isNum(value1) ? Utils.fmtDecimal(value1) : null;
  };

  const _renderCrCol: TblColRender = (row: any, col: any) => {
    let {createdAt} = row;
    return createdAt ? Utils.reFmtDate(createdAt, 'DD/MM/YY HH:mm') : null;
  };

  const _renderUpCol: TblColRender = (row: any, col: any) => {
    let {updatedAt} = row;
    return updatedAt ? Utils.reFmtDate(updatedAt, 'DD/MM/YY HH:mm') : null;
  };

  const _renderMonthCol: TblColRender = (row: any, col: any) => {
    let {month} = row;
    if (isNaN(month)) {
      month = -1;
    }
    return month > -1 ? month : '---';
  };

  const _renderDayCol: TblColRender = (row: any, col: any) => {
    let {day} = row;
    if (isNaN(day)) {
      day = -1;
    }
    return day > -1 ? day : '---';
  };

  const _renderWomCol: TblColRender = (row: any, col: any) => {
    let {wom} = row;
    if (isNaN(wom)) {
      wom = -1;
    }
    return wom > -1 ? wom : '---';
  };

  const _renderHoyCol: TblColRender = (row: any, col: any) => {
    let {hoy} = row;
    if (isNaN(hoy)) {
      hoy = -1;
    }
    return hoy > -1 ? hoy : '---';
  };

  const _renderQoyCol: TblColRender = (row: any, col: any) => {
    let {qoy} = row;
    if (isNaN(qoy)) {
      qoy = -1;
    }
    if (qoy === 5) {
      return 'YTD';
    }

    return qoy > -1 ? qoy : '---';
  };

  //const showShift = !byScope || byScope === 'shift';

  return (
    <>
      <Toast ref={toastRef} />
      {selMode === 'single' && (
        <DataTable
          header={header}
          value={prodKpisTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={prodKpisTblLimit}
          first={prodKpisTblStart}
          totalRecords={prodKpisTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selProdKpi}
          onSelectionChange={_onProdKpiSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            field="kpiType"
            header="KPI"
            headerStyle={{width: 100}}
            sortable
            sortField="obj.kpiType"
          />

          <Column
            field="periodType"
            header="Period"
            headerStyle={{width: 120}}
            sortable
            sortField="obj.periodType"
          />

          <Column
            body={_renderTargetValueCol}
            header="Target Value"
            headerStyle={{width: 150}}
            headerClassName="text-right"
            bodyClassName="text-right"
            sorttable
            sortField="obj.targetValue"
          />

          <Column
            body={_renderExpectationValueCol}
            header="Min Expectation"
            headerStyle={{width: 150}}
            headerClassName="text-right"
            bodyClassName="text-right"
            sorttable
            sortField="obj.targetValue"
          />

          <Column
            field="year"
            header="Year"
            headerStyle={{width: 60}}
            sortable
            sortField="obj.year"
          />

          <Column body={_renderQoyCol} header="Qoy" headerStyle={{width: 60}} />

          <Column
            body={_renderMonthCol}
            header="Month"
            headerStyle={{width: 60}}
          />

          <Column body={_renderDayCol} header="Day" headerStyle={{width: 60}} />

          <Column body={_renderWomCol} header="WOM" headerStyle={{width: 60}} />

          <Column body={_renderHoyCol} header="HOY" headerStyle={{width: 60}} />

          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />

          <Column
            body={_renderCrCol}
            header="Created On"
            headerStyle={{width: 120}}
          />

          <Column
            field="createdBy"
            header="Created By"
            headerStyle={{width: 100}}
          />

          <Column
            body={_renderUpCol}
            header="Updated On"
            headerStyle={{width: 120}}
          />

          <Column
            field="updatedBy"
            header="Updated By"
            headerStyle={{width: 100}}
          />

          <Column
            field="id"
            header="#"
            headerStyle={{width: 60}}
            headerClassName="text-end"
            bodyClassName="text-end"
          />
        </DataTable>
      )}

      {selMode !== 'single' && (
        <DataTable
          header={header}
          value={prodKpisTblData}
          dataKey="id"
          lazy={true}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={prodKpisTblLimit}
          first={prodKpisTblStart}
          totalRecords={prodKpisTblNbRows}
          resizableColumns={true}
          columnResizeMode="expand"
          selectionMode="single"
          selection={selProdKpis}
          onSelectionChange={_onProdKpiSelected}
          onPage={_onInvsPaging}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={_onSort}>
          <Column
            field="kpiType"
            header="KPI"
            headerStyle={{width: 100}}
            sortable
            sortField="obj.kpiType"
          />

          <Column
            field="periodType"
            header="Period"
            headerStyle={{width: 120}}
            sortable
            sortField="obj.periodType"
          />

          <Column
            body={_renderTargetValueCol}
            header="Target Value"
            headerStyle={{width: 150}}
            headerClassName="text-right"
            bodyClassName="text-right"
            sorttable
            sortField="obj.targetValue"
          />

          <Column
            body={_renderExpectationValueCol}
            header="Min Expectation"
            headerStyle={{width: 150}}
            headerClassName="text-right"
            bodyClassName="text-right"
            sorttable
            sortField="obj.targetValue"
          />

          <Column
            field="year"
            header="Year"
            headerStyle={{width: 60}}
            sorttable
            //   sortField="obj.year"
          />

          <Column body={_renderQoyCol} header="Qoy" headerStyle={{width: 60}} />

          <Column
            body={_renderMonthCol}
            header="Month"
            headerStyle={{width: 60}}
          />

          <Column body={_renderDayCol} header="Day" headerStyle={{width: 60}} />

          <Column body={_renderWomCol} header="WOM" headerStyle={{width: 60}} />

          <Column body={_renderHoyCol} header="HOY" headerStyle={{width: 60}} />

          <Column
            field="description"
            header="Summary"
            headerStyle={{width: 300}}
          />

          <Column
            body={_renderCrCol}
            header="Created On"
            headerStyle={{width: 120}}
          />

          <Column
            field="createdBy"
            header="Created By"
            headerStyle={{width: 100}}
          />

          <Column
            body={_renderUpCol}
            header="Updated On"
            headerStyle={{width: 120}}
          />

          <Column
            field="updatedBy"
            header="Updated By"
            headerStyle={{width: 100}}
          />

          <Column
            field="id"
            header="#"
            headerStyle={{width: 60}}
            headerClassName="text-end"
            bodyClassName="text-end"
          />
        </DataTable>
      )}
    </>
  );
}
