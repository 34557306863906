// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';

//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';

//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
// import {Dialog} from 'primereact/dialog';
//$FlowFixMe[cannot-resolve-module]
import {AutoComplete} from 'primereact/autocomplete';
// import {Dropdown} from 'primereact/dropdown';

import type {ApiProps} from '../../service/Api';

import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';

type Props = {
  readOnly?: boolean,
  shift: any,
  onAction: (evt: any) => void,
};

const defShift = {
  __cls__: 'com.nb.lmrs.model.entity.Shift',
  flag: 0,
  code: '',
  name: '',
  description: '',
  factory: null,
};

export function ShiftForm(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {readOnly, shift} = props;
  const [editShift, setEditShift] = useState<any>();
  const [suggFactories, setSuggFactories] = useState([]);
  const [defFactory, setDefFactory] = useState();

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    let {factoryUsers} = auth;
    let factories = (factoryUsers || [])
      .filter((it) => it.role)
      .map((it) => it.factory)
      .filter((it) => it);
    if (factories.length > 0) {
      setDefFactory(factories[0]);
    }
  }, [auth]);

  useEffect(() => {
    let curShift = shift || {};
    curShift = {
      ...defShift,
      ...curShift,
    };

    let isNew = !curShift.id;
    if (isNew && (!curShift.factory || !curShift.factory.id)) {
      curShift['factory'] = defFactory;
    }

    // //console.log.*$
    setEditShift(curShift);
  }, [shift, defFactory]);

  const _onSuggFactories: (evt: any) => any = (evt: any) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let search = evt.query;
    let start = 0;
    let limit = 1000;
    let sorts = ['obj.name', 'obj.createdAt'];
    api
      .getFactories({search, start, limit, sorts})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        //console.log.*$
        setSuggFactories([...data.list]);
      })
      .catch((err) => {
        //console.log.*$
        UIUtils.showError({error: err, toast: toastRef.current});
      });
  };

  const _onSave = (evt: any) => {
    // setShowCfmDlg(false);
    let fac = _validateShift();
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update working shift?'
        : 'Are you sure you want to create working shift?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updateShift({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showError({errors, toast: toastRef.current});
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'shiftSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _validateShift: () => any = () => {
    let obj = {...editShift};

    if (Utils.isEmpty(obj.code)) {
      UIUtils.showError({
        detail: 'Please enter shift code',
        toast: toastRef.current,
      });
      return false;
    }

    if (Utils.isEmpty(obj.name)) {
      UIUtils.showError({
        detail: 'Please enter shift name',
        toast: toastRef.current,
      });
      return false;
    }

    if (!obj.factory || !obj.factory.id) {
      UIUtils.showError({
        detail: 'Please select factory',
        toast: toastRef.current,
      });
      return false;
    }

    // if (isNaN(obj.shiftNo) || Number(obj.shiftNo) < 0) {
    //   UIUtils.showError({
    //     detail: 'Invalid shift',
    //     toast: toastRef.current,
    //   });
    //   return false;
    // }

    // obj.rate = Number(obj.rate);
    return obj;
  };

  if (!editShift) {
    return null;
  }

  const saveLbl = editShift.id
    ? 'Update working shift'
    : 'Create working shift';
  const isNew = !editShift.id;
  // //console.log.*$

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/edit working shift">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editShift.code}
                onChange={(e) => {
                  setEditShift({...editShift, code: e.target.value});
                }}
                disabled={!isNew || readOnly}
              />
              <label>Code</label>
            </span>
          </div>

          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  value={editShift.name}
                  onChange={(e) => {
                    setEditShift({
                      ...editShift,
                      name: e.target.value,
                    });
                  }}
                  disabled={readOnly}
                />
                <label>Name</label>
              </span>
            </div>
          </div>
          <div className="p-col-12">
            <AutoComplete
              placeholder="Select factory"
              dropdown
              suggestions={suggFactories}
              field="name"
              value={editShift.factory}
              completeMethod={_onSuggFactories}
              onChange={(e) =>
                setEditShift({
                  ...editShift,
                  factory: e.value,
                })
              }
              disabled={readOnly}
            />
          </div>

          <div className="p-col-12">
            <div className="p-field">
              <span className="p-float-label">
                <InputTextarea
                  rows={2}
                  value={editShift.description}
                  onChange={(e) => {
                    setEditShift({
                      ...editShift,
                      description: e.target.value,
                    });
                  }}
                  disabled={readOnly}
                />
                <label>Summary</label>
              </span>
            </div>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
