// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';
//$FlowFixMe
import {debounceTime} from 'rxjs/operators';

//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {Chart} from 'primereact/chart';
//$FlowFixMe
import {Button} from 'primereact/button';

//$FlowFixMe[cannot-resolve-module]
import ChartDataLabels from 'chartjs-plugin-datalabels';

import type {ApiProps} from '../../../service/Api';
import type {EvtHandler} from '../../types';
// import {AuthUtils} from '../service/AuthUtils';
import {UIUtils, Utils} from '../../../service/Utils';
import {AdminApi} from '../../../service/AdminApi';
import {pphColors, siColors, getChartOptions} from '../CommonChart';

type Props = {
  scope?: string,
  regions?: any[],
  countries?: any[],
  facGroups?: any[],
  factories?: any[],
  lines?: any[],
  year?: any,
  month?: any,
  expanded?: boolean,
  onAction?: EvtHandler,
};

const chartPlugins = [ChartDataLabels];

export function MltHistogramOfMonFacV2(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {
    scope,
    regions,
    countries,
    facGroups,
    factories,
    lines,
    year,
    month,
    expanded,
    onAction,
  } = props;

  const [barData, setBarData] = useState();
  const [chartOptions] = useState(getChartOptions());

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchFilterMlt = useCallback(async (options) => {
    let {month, year} = options;
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let start = 0;
    let limit = 10;
    let months = [month, -1];
    let filters = {
      byYear: 'obj.year = :year',
      byMonth: 'obj.month in (:months)',
    };

    let params = {
      year: year,
      months: months,
    };

    return await api
      .getFilterMlt({start, limit, filters, params})
      .then((resp) => resp.data)
      .then((resp) => {
        let {errors, data} = resp;
        if (errors.length > 0) {
          //console.log.*$
          UIUtils.showError({errors, toast: toastRef.current});
          return;
        }
        let lstFilter = data.list;
        if (lstFilter.length > 1) {
          let filterMlt = lstFilter.find((dt) => dt.month === month);
          //console.log.*$
          return {
            minmlt: filterMlt.ucl,
            maxmlt: filterMlt.lcl,
          };
        } else if (lstFilter.length === 1) {
          let filterMlt = lstFilter[0];
          return {
            minmlt: filterMlt.ucl,
            maxmlt: filterMlt.lcl,
          };
        } else if (lstFilter.length === 0) {
          return {
            minmlt: 0,
            maxmlt: 0,
          };
        }
      })
      .catch((errors) => {});
  }, []);

  const _fetchData = useCallback(
    async (options: any) => {
      let {factories, year, month} = options;
      let api: ?AdminApi = apiRef.current;
      if (!api) {
        return;
      }

      let filterMlt: any = await _fetchFilterMlt({year, month});

      let {maxmlt, minmlt} = filterMlt;

      let joins = [
        {
          type: 'join',
          expr: 'obj.factory',
          alias: 'fac',
        },
        {
          type: 'join',
          expr: 'fac.country',
          alias: 'coun',
        },
        {
          type: 'join',
          expr: 'coun.region',
          alias: 'reg',
        },
      ];
      let filters = {};
      let params = {};
      // let groupBys = ['obj.mlt'];
      let groupBys = [];

      let facIds = Utils.getVals(factories || [], 'id', [0]).map((it) => {
        return {
          type: 'long',
          value: it,
        };
      });
      filters = {
        ...filters,
        byFacIds: 'fac.id in (:facIds)',
      };
      params = {
        ...params,
        facIds,
      };

      filters = {
        ...filters,
        siYear: 'obj.siYear = :year',
        siMonth: 'obj.siMonth = :month',
      };

      params = {
        ...params,
        year,
        month,
      };

      if (minmlt > 0) {
        filters = {
          ...filters,
          byMin: 'obj.mlt > :minmlt',
        };
        params = {
          ...params,
          minmlt,
        };
      }

      if (maxmlt > 0) {
        filters = {
          ...filters,
          byMax: 'obj.mlt < :maxmlt',
        };
        params = {
          ...params,
          maxmlt,
        };
      }

      // let fields = [...groupBys, 'count(obj.mlt)'];
      // let sorts = [...groupBys];
      let fields = ['obj.mlt'];
      let sorts = ['obj.mlt'];
      return await api
        .fetchMltRecData({
          fields,
          joins,
          groupBys,
          filters,
          params,
          sorts,
          start: 0,
          limit: 0,
        })
        .then((resp) => resp.data)
        .then((resp) => {
          //console.log.*$
          let {errors, data} = resp;
          if (errors.length > 0) {
            //console.log.*$
            UIUtils.showError({errors, toast: toastRef.current});
            return [];
          }
          return [...data.list];
        })
        .catch((error) => {
          //console.log.*$
          UIUtils.showError({error, toast: toastRef.current});
          return [];
        });
    },
    [_fetchFilterMlt],
  );

  const _fetchChartData = useCallback(
    async (options: any) => {
      let recs: any[] = (await _fetchData(options)) || [];
      if (recs.length < 1) {
        //set empty chart data
        let chartData = {
          labels: [],
          datasets: [],
        };
        setBarData(chartData);
        return;
      }
      let medians = Utils.median(recs);
      // let minVal = recs[0];
      // let maxVal = recs[recs.length - 1];
      let labels = [];
      // for (let i = minVal; i <= maxVal; i++) {
      //   labels.push(i);
      // }
      recs.forEach((it) => {
        if (!labels.includes(it)) {
          labels.push(it);
        }
      });
      let cntMap = {};
      recs.forEach((it) => {
        let val = cntMap[it];
        if (val === undefined) {
          cntMap[it] = 1;
        } else {
          cntMap[it] = val + 1;
        }
      });
      let data = labels.map((it) => cntMap[it] || 0);
      let bgColors = labels.map((it) => {
        return medians.includes(it) ? siColors[0] : pphColors[0];
      });
      let datasets = [
        {
          label: 'Histogram',
          data,
          // backgroundColor: pphColors[0],
          backgroundColor: [...bgColors],
          datalabels: {
            anchor: 'end',
            align: 'top',
            offset: 1,
            color: '#ff0000',
          },
        },
      ];
      let chartData = {
        labels,
        datasets,
      };
      setBarData(chartData);
    },
    [_fetchData],
  );

  useEffect(() => {
    let subj = new Subject();
    subj.pipe(debounceTime(300)).subscribe({
      next: (data) => {
        _fetchChartData(data);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      //console.log.*$
      subj.complete();
    };
  }, [_fetchChartData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      scope,
      regions,
      countries,
      facGroups,
      factories,
      lines,
      year,
      month,
    });
  }, [scope, regions, countries, facGroups, factories, lines, year, month]);

  const _onToggleExpand: EvtHandler = (evt: any) => {
    onAction &&
      onAction({
        type: 'toggleExpand',
        value: !expanded,
      });
  };

  const iconName = expanded
    ? 'pi pi-angle-double-left'
    : 'pi pi-angle-double-right';

  const icons = (
    <Button
      icon={iconName}
      className="p-button-rounded p-button-text"
      style={{padding: 0, height: '1.2rem', color: '#FFFFFF'}}
      onClick={_onToggleExpand}
    />
  );

  let title = 'MLT Monthly - ' + (month || 0) + '/' + (year || 0);

  const chartOptions1 = {
    ...chartOptions,
    plugins: {
      legend: {
        labels: {
          generateLabels: (chart) => {
            var a = [
              {
                text: 'Median',
                fillStyle: siColors[0],
                borderRadius: 'rgba(0,0,0,0)',
                strokeStyle: 'rgba(0,0,0,0)',
              },
              {
                text: 'Histogram',
                fillStyle: pphColors[0],
                borderRadius: 'rgba(0,0,0,0)',
                strokeStyle: 'rgba(0,0,0,0)',
              },
            ];
            return a;
          },
        },
      },
    },
  };

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header={title} icons={icons}>
        <Chart
          type="bar"
          data={barData}
          options={chartOptions1}
          plugins={chartPlugins}
        />
      </Panel>
    </>
  );
}
