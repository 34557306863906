// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe
import type {ApiProps} from '../../service/Api';
import {UIUtils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
//$FlowFixMe[cannot-resolve-module]
import {nanoid} from 'nanoid';

type Props = {
  filter: any,
};
export function CommonKpiList(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const [dataKpi, setDataKpi] = useState([]);
  const [mltSetPoint, setMltSetPoint] = useState(0);
  const {filter} = props;
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();

  const _renderPphKpi = (dataKpi, min?) => {
    let render = [];
    let lstPphDataKpi = dataKpi.filter((dt) => {
      return dt.kpiType === 'PPH';
    });

    if (lstPphDataKpi.length > 0) {
      if (min) {
        [1, 2, 3, 4, 5].forEach((qoy) => {
          let pphDataKpi = lstPphDataKpi.find((dt) => dt.qoy === qoy);
          if (typeof pphDataKpi !== 'undefined') {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                {pphDataKpi.value1}
              </td>,
            );
          } else {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                0
              </td>,
            );
          }
        });
      } else {
        [1, 2, 3, 4, 5].forEach((qoy) => {
          let pphDataKpi = lstPphDataKpi.find((dt) => dt.qoy === qoy);
          if (typeof pphDataKpi !== 'undefined') {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                {pphDataKpi.targetValue}
              </td>,
            );
          } else {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                0
              </td>,
            );
          }
        });
      }
    } else {
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0
        </td>,
      );
    }

    return render;
  };

  const _renderMltKpi = (dataKpi, min?) => {
    let render = [];
    let lstMltDataKpi = dataKpi.filter((dt) => {
      return dt.kpiType === 'MLT';
    });
    if (lstMltDataKpi.length > 0) {
      if (min) {
        [1, 2, 3, 4, 5].forEach((qoy) => {
          let mltDataKpi = lstMltDataKpi.find((dt) => dt.qoy === qoy);
          if (typeof mltDataKpi !== 'undefined') {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                {mltDataKpi.value1}
              </td>,
            );
            // if (qoy === 4) {
            //   render.push(
            //     <td key={nanoid()} style={{textAlign: 'center'}}>
            //       {mltDataKpi.value1}
            //     </td>,
            //   );
            // }
          } else {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                0
              </td>,
            );
            // if (qoy === 4) {
            //   render.push(
            //     <td key={nanoid()} style={{textAlign: 'center'}}>
            //       0
            //     </td>,
            //   );
            // }
          }
        });
      } else {
        [1, 2, 3, 4, 5].forEach((qoy) => {
          let mltDataKpi = lstMltDataKpi.find((dt) => dt.qoy === qoy);
          if (typeof mltDataKpi !== 'undefined') {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                {mltDataKpi.targetValue}
              </td>,
            );
            // if (qoy === 4) {
            //   render.push(
            //     <td key={nanoid()} style={{textAlign: 'center'}}>
            //       {mltDataKpi.targetValue}
            //     </td>,
            //   );
            // }
          } else {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                0
              </td>,
            );
            // if (qoy === 4) {
            //   render.push(
            //     <td key={nanoid()} style={{textAlign: 'center'}}>
            //       0
            //     </td>,
            //   );
            // }
          }
        });
      }
    } else {
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0
        </td>,
      );
    }

    return render;
  };

  const _renderLeanscoreKpi = (dataKpi, min?) => {
    let render = [];
    let lstLeanScoreDataKpi = dataKpi.filter((dt) => {
      return dt.kpiType === 'LEAN_SCORE';
    });
    if (lstLeanScoreDataKpi.length > 0) {
      if (min) {
        [1, 2, 3, 4, 5].forEach((qoy) => {
          let leanScoreKpi = lstLeanScoreDataKpi.find((dt) => dt.qoy === qoy);
          if (typeof leanScoreKpi !== 'undefined') {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                {leanScoreKpi.value1}
              </td>,
            );
            // if (qoy === 4) {
            //   render.push(
            //     <td key={nanoid()} style={{textAlign: 'center'}}>
            //       {leanScoreKpi.value1}
            //     </td>,
            //   );
            // }
          } else {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                0
              </td>,
            );
            // if (qoy === 4) {
            //   render.push(
            //     <td key={nanoid()} style={{textAlign: 'center'}}>
            //       0
            //     </td>,
            //   );
            // }
          }
        });
      } else {
        [1, 2, 3, 4, 5].forEach((qoy) => {
          let leanScoreKpi = lstLeanScoreDataKpi.find((dt) => dt.qoy === qoy);
          if (typeof leanScoreKpi !== 'undefined') {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                {leanScoreKpi.targetValue}
              </td>,
            );
            // if (qoy === 4) {
            //   render.push(
            //     <td key={nanoid()} style={{textAlign: 'center'}}>
            //       {leanScoreKpi.targetValue}
            //     </td>,
            //   );
            // }
          } else {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                0
              </td>,
            );
            // if (qoy === 4) {
            //   render.push(
            //     <td key={nanoid()} style={{textAlign: 'center'}}>
            //       0
            //     </td>,
            //   );
            // }
          }
        });
      }
    } else {
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0
        </td>,
      );
    }

    return render;
  };

  const _renderMiniLineKpi = (dataKpi, min?) => {
    let render = [];
    let lstMiniLineDataKpi = dataKpi.filter((dt) => {
      return dt.kpiType === 'MINL_RATE';
    });

    if (lstMiniLineDataKpi.length > 0) {
      if (min) {
        [1, 2, 3, 4, 5].forEach((qoy) => {
          let miniLineDataKpi = lstMiniLineDataKpi.find((dt) => dt.qoy === qoy);
          if (typeof miniLineDataKpi !== 'undefined') {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                {miniLineDataKpi.value1}%
              </td>,
            );
          } else {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                0%
              </td>,
            );
          }
        });
      } else {
        [1, 2, 3, 4, 5].forEach((qoy) => {
          let miniLineDataKpi = lstMiniLineDataKpi.find((dt) => dt.qoy === qoy);
          if (typeof miniLineDataKpi !== 'undefined') {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                {miniLineDataKpi.targetValue}%
              </td>,
            );
          } else {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                0%
              </td>,
            );
          }
        });
      }
    } else {
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0%
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0%
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0%
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0%
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0%
        </td>,
      );
    }
    return render;
  };

  const _renderPoKpi = (dataKpi, min?) => {
    let render = [];
    let lstPoDataKpi = dataKpi.filter((dt) => {
      return dt.kpiType === 'PO';
    });

    if (lstPoDataKpi.length > 0) {
      if (min) {
        [1, 2, 3, 4, 5].forEach((qoy) => {
          let minPoDataKpi = lstPoDataKpi.find((dt) => dt.qoy === qoy);
          if (typeof minPoDataKpi !== 'undefined') {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                {minPoDataKpi.value1}%
              </td>,
            );
          } else {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                0%
              </td>,
            );
            // if (qoy === 4) {
            //   render.push(
            //     <td key={nanoid()} style={{textAlign: 'center'}}>
            //       0
            //     </td>,
            //   );
            // }
          }
        });
      } else {
        [1, 2, 3, 4, 5].forEach((qoy) => {
          let poDataKpi = lstPoDataKpi.find((dt) => dt.qoy === qoy);
          if (typeof poDataKpi !== 'undefined') {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                {poDataKpi.targetValue}%
              </td>,
            );
          } else {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                0%
              </td>,
            );
          }
        });
      }
    } else {
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0%
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0%
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0%
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0%
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0%
        </td>,
      );
    }
    return render;
  };

  const _renderBtsKpi = (dataKpi, min?) => {
    let render = [];
    let lstBtsDataKpi = dataKpi.filter((dt) => {
      return dt.kpiType === 'BTS';
    });

    if (lstBtsDataKpi.length > 0) {
      if (min) {
        [1, 2, 3, 4, 5].forEach((qoy) => {
          let minBtsDataKpi = lstBtsDataKpi.find((dt) => dt.qoy === qoy);
          if (typeof minBtsDataKpi !== 'undefined') {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                {minBtsDataKpi.value1}%
              </td>,
            );
          } else {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                0%
              </td>,
            );
          }
        });
      } else {
        [1, 2, 3, 4, 5].forEach((qoy) => {
          let btsDataKpi = lstBtsDataKpi.find((dt) => dt.qoy === qoy);
          if (typeof btsDataKpi !== 'undefined') {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                {btsDataKpi.targetValue}%
              </td>,
            );
          } else {
            render.push(
              <td key={nanoid()} style={{textAlign: 'center'}}>
                0%
              </td>,
            );
          }
        });
      }
    } else {
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0%
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0%
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0%
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0%
        </td>,
      );
      render.push(
        <td key={nanoid()} style={{textAlign: 'center'}}>
          0%
        </td>,
      );
    }
    return render;
  };

  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchDataSetPointMlt = useCallback(async (options) => {
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }

    let {year} = options;
    let joins = [];
    let filters = {};
    let params = {};
    let sorts = [];
    let start = 0;
    let limit = 0;
    if (year && year > 0) {
      filters = {
        ...filters,
        byYear: 'obj.year = :year',
        byMonth: 'obj.month = :month',
      };
      params = {
        ...params,
        year: Number(year),
        month: -1,
      };

      api
        .getMltSetpoint({joins, filters, params, start, limit, sorts})
        .then((resp) => resp.data)
        .then((resp) => {
          let {errors, data} = resp;
          if (errors.length > 0) {
            // //console.log.*$
            UIUtils.showError({errors, toast: toastRef.current});
            return;
          }
          if (data.list.length > 0) {
            setMltSetPoint([...data.list][0].value);
          }
        })
        .catch((err) => {
          // //console.log.*$
          UIUtils.showError({error: err, toast: toastRef.current});
        });
    }
  }, []);
  const _fetchDataLeanScoreCardKpi = useCallback(async (options) => {
    //console.log('ghihihi');
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    let {year} = options;
    let joins = [];
    let filters = {};
    let params = {};
    let sorts = ['+obj.qoy'];
    let start = 0;
    let limit = 0;
    if (year && year > 0) {
      filters = {
        ...filters,
        byYear: 'obj.year = :year',
      };
      params = {
        ...params,
        year: Number(year),
      };

      api
        .getLeanScoreCard({joins, filters, params, start, limit, sorts})
        .then((resp) => resp.data)
        .then((resp) => {
          let {errors, data} = resp;
          if (errors.length > 0) {
            // //console.log.*$
            UIUtils.showError({errors, toast: toastRef.current});
            return;
          }

          setDataKpi([...data.list]);
        })
        .catch((err) => {
          // //console.log.*$
          UIUtils.showError({error: err, toast: toastRef.current});
        });
    }
  }, []);
  useEffect(() => {
    let {year} = filter;
    _fetchDataLeanScoreCardKpi({year});
    _fetchDataSetPointMlt({year});
  }, [filter, _fetchDataLeanScoreCardKpi, _fetchDataSetPointMlt]);

  const styleTable = {
    width: '100%',
    borderCollapse: 'collapse',
    border: '1px solid black',
  };
  const styleHeader = {
    border: '1px solid black',
    height: '50px',
  };

  const styleBorderHeader = {
    border: '1px solid black',
    backgroundColor: '#a9d08f',
  };
  return (
    <>
      <Toast ref={toastRef} />
      <h1>
        {filter.year} TARGET{' '}
        <span style={{color: '#417fa7'}}>
          (Sourced Manufacturing Inititaves-Lean Metrics Report)
        </span>
      </h1>
      <table className="table" style={styleTable}>
        <thead style={styleHeader}>
          <tr style={styleBorderHeader}>
            <th style={{border: '1px solid black'}}>KPI</th>
            <th style={{border: '1px solid black'}}>Time Period</th>
            <th style={{border: '1px solid black'}}>
              Target & Min Expectation
            </th>
            <th style={{border: '1px solid black'}}>Q1</th>
            <th style={{border: '1px solid black'}}>Q2</th>
            <th style={{border: '1px solid black'}}>Q3</th>
            <th style={{border: '1px solid black'}}>Q4</th>
            <th style={{border: '1px solid black'}}>
              YTD Rolling weighted average
            </th>
          </tr>
        </thead>
        <tbody style={styleHeader}>
          <tr>
            <td rowSpan="2">PPH</td>
            <td rowSpan="2">6 months rolling weighted avg</td>
            <td>Target</td>
            {_renderPphKpi(dataKpi)}
          </tr>
          <tr>
            <td>Min Expectation (-10%)</td>
            {_renderPphKpi(dataKpi, true)}
          </tr>
          <tr>
            <td rowSpan="2">MLT-Median</td>
            <td rowSpan="2">6 months rolling median</td>
            <td>Target</td>
            {_renderMltKpi(dataKpi)}
          </tr>
          <tr>
            <td>Min Expectation (-10%)</td>
            {_renderMltKpi(dataKpi, true)}
          </tr>
          <tr>
            <td rowSpan="2">% PO Completion {`(<=${mltSetPoint} days)`}</td>
            <td rowSpan="2">6 months rolling (Total PO)</td>
            <td>Target</td>
            {_renderPoKpi(dataKpi)}
          </tr>
          <tr>
            <td>Min Expectation (-10%)</td>
            {_renderPoKpi(dataKpi, true)}
          </tr>
          <tr>
            <td rowSpan="2">Lean Assessment</td>
            <td rowSpan="2">Latest Score (Q2/Q4)</td>
            <td>Target</td>
            {_renderLeanscoreKpi(dataKpi)}
          </tr>
          <tr>
            <td>Min Expectation (-10%)</td>
            {_renderLeanscoreKpi(dataKpi, true)}
          </tr>
          <tr>
            <td rowSpan="2">BTS</td>
            <td rowSpan="2">6 months pure average</td>
            <td>Target</td>
            {_renderBtsKpi(dataKpi)}
          </tr>
          <tr>
            <td>Min Expectation (-10%)</td>
            {_renderBtsKpi(dataKpi, true)}
          </tr>
          <tr>
            <td rowSpan="2">Mini line %</td>
            <td rowSpan="2">Latest month</td>
            <td>Target</td>
            {_renderMiniLineKpi(dataKpi)}
          </tr>
          <tr>
            <td>Min Expectation (-10%)</td>
            {_renderMiniLineKpi(dataKpi, true)}
          </tr>
        </tbody>
      </table>
    </>
  );
}
