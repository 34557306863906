// @flow
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subject} from 'rxjs';
//$FlowFixMe
import {debounceTime} from 'rxjs/operators';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {Chart} from 'primereact/chart';
//$FlowFixMe
import {Button} from 'primereact/button';

//$FlowFixMe[cannot-resolve-module]
import ChartDataLabels from 'chartjs-plugin-datalabels';
//$FlowFixMe[cannot-resolve-module]
import type {ApiProps} from '../../service/Api';
//$FlowFixMe[cannot-resolve-module]
import type {EvtHandler} from '../types';
// import {AuthUtils} from '../service/AuthUtils';
import {UIUtils, Utils} from '../../../service/Utils';
import {AdminApi} from '../../../service/AdminApi';
import {getColor} from '../../../service/util/color';

import {getChartOptions, kpiColors, pphColors} from '../CommonChart';

type Props = {
  scope?: string,
  regions?: any[],
  countries?: any[],
  facGroups?: any[],
  factories?: any[],
  lines?: any[],
  year?: any,
  month?: any,
  expanded?: boolean,
  onAction?: EvtHandler,
};

export const biglColors: any = [
  'purple-700',
  'purple-500',
  'purple-300',
  'purple-100',
  'purple-800',
  'purple-600',
  'purple-400',
  'purple-200',
  'purple-50',
  'purple-900',
].map((c) => getColor(c));

export const minlColors: any = [
  'yellow-700',
  'yellow-500',
  'yellow-300',
  'yellow-100',
  'yellow-800',
  'yellow-600',
  'yellow-400',
  'yellow-200',
  'yellow-50',
  'yellow-900',
].map((c) => getColor(c));

export const minlRmColors: any = [
  'red-700',
  'red-500',
  'red-300',
  'red-100',
  'red-900',
].map((c) => getColor(c));

export const minlRateColors: any = [
  'green-700',
  'green-500',
  'green-300',
  'green-100',
  'green-800',
  'green-600',
  'green-400',
  'green-200',
  'green-50',
  'green-900',
].map((c) => getColor(c));

export const minlVolRateColors: any = [
  'blue-700',
  'blue-500',
  'blue-300',
  'blue-100',
  'blue-800',
  'blue-600',
  'blue-400',
  'blue-200',
  'blue-50',
  'blue-900',
].map((c) => getColor(c));

let chartOptions = getChartOptions();
const chartPlugins = [ChartDataLabels];

export function PoMonthlyFacV2(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);
  const [mltSetpoint, setMltSetpoint] = useState(0);
  const {t} = useTranslation();
  const {
    scope,
    regions,
    countries,
    facGroups,
    factories,
    lines,
    month,
    year,
    expanded,
    onAction,
  } = props;

  const [barData, setBarData] = useState();
  // const [chartOptions] = useState(getChartOptions());

  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  const fetchDataEvtRef = useRef();

  useEffect(() => {
    // //console.log.*$
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  const _fetchMltData = useCallback(
    async (options: any, controller?: AbortController) => {
      let {scope, factories, year, month} = options;
      let api: ?AdminApi = apiRef.current;
      if (!api) {
        return;
      }
      // scope = scope || 'region';
      let reqData = {
        scope,
        year,
        month,
      };

      if (factories && factories.length > 0) {
        let factoryIds = factories.map((fac) => {
          return fac.id;
        });

        reqData = {
          ...reqData,
          factoryIds,
        };
      }

      return await api
        .getPoComletion({data: {...reqData}}, controller)
        .then((resp) => resp.data)
        .then((resp) => {
          //console.log.*$
          let {errors, data} = resp;
          if (errors.length > 0) {
            //console.log.*$
            UIUtils.showError({errors, toast: toastRef.current});
            return [];
          }
          setMltSetpoint(data.mltsetpoint);
          return [
            {
              ...data,
            },
          ];
        })
        .catch((error) => {
          //console.log.*$
          UIUtils.showError({error, toast: toastRef.current});
          return [];
        });
    },
    [],
  );

  const _fetchChartData = useCallback(
    async (options: any, controller?: AbortController) => {
      let months = [];
      for (let i = 0; i < 12; i++) {
        months.push(i + 1);
      }
      let {year} = options;
      let labels = months.map((it) => it);
      labels = labels.map((it) => t(`mon.${it}`));
      labels.push('6 months roll');
      let pos = (await _fetchMltData(options, controller)) || [];
      if (!pos || pos.length === 0) return;
      let poComletionDataset = pos.map((po, ind) => {
        let ci = ind % pphColors.length;
        let results = [...po.results].map((res) => {
          return Utils.isNum(res) ? Utils.roundToInterger(res) : 0;
        });

        let initDate = Utils.getDateInfo();
        // let currentDate = new Date(initDate.year, initDate.month, initDate.day);
        let currentMonth = initDate.month || 0;
        let currentYear = initDate.year || 0;

        //    let selectedMonth = month || 0;
        let selectedYear = year || 0;
        //  let selectedDate = new Date(selectedYear, selectedMonth, 1);
        if (selectedYear >= currentYear) {
          for (let i = currentMonth; i < results.length - 1; i++) {
            results[i] = 0;
          }
        }

        return {
          label: `PO`,
          backgroundColor: pphColors[ci],
          data: results,
          datalabels: {
            anchor: 'end',
            align: 'top',
            offset: 1,
            color: '#ff0000',
          },
        };
      });

      let kpiDatasets = [];
      if (pos && pos.length > 0 && scope === 'factoryv2') {
        let kpis = pos[0].kpis || [];
        kpiDatasets = [
          {
            type: 'line',
            label: 'Target',
            backgroundColor: kpiColors[0],
            borderColor: kpiColors[0],
            borderWidth: 1,
            data: [...kpis],
            datalabels: {
              display: false,
            },
          },
        ];
      }

      let chartData = {
        labels,
        datasets: [...kpiDatasets, ...poComletionDataset],
      };
      setBarData(chartData);
    },
    [t, _fetchMltData, scope],
  );

  useEffect(() => {
    const abortController = new AbortController();

    let subj = new Subject();
    subj.pipe(debounceTime(800)).subscribe({
      next: (data) => {
        _fetchChartData(data, abortController);
      },
    });
    fetchDataEvtRef.current = subj;
    return () => {
      // //console.log.*$
      abortController.abort();
      subj.complete();
    };
  }, [_fetchChartData]);

  useEffect(() => {
    let fetchDataEvtSrc: any = fetchDataEvtRef.current;
    if (!fetchDataEvtSrc) {
      return;
    }
    fetchDataEvtSrc.next({
      scope,
      regions,
      countries,
      facGroups,
      factories,
      lines,
      month,
      year,
    });
  }, [scope, regions, countries, facGroups, factories, lines, year, month]);

  const _onToggleExpand: EvtHandler = (evt: any) => {
    onAction &&
      onAction({
        type: 'toggleExpand',
        value: !expanded,
      });
  };

  const iconName = expanded
    ? 'pi pi-angle-double-left'
    : 'pi pi-angle-double-right';

  const icons = (
    <Button
      icon={iconName}
      className="p-button-rounded p-button-text"
      style={{padding: 0, height: '1.2rem', color: '#FFFFFF'}}
      onClick={_onToggleExpand}
    />
  );

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header={`% PO Completion (<= ${mltSetpoint} days)`} icons={icons}>
        <Chart
          type="bar"
          data={barData}
          options={chartOptions}
          plugins={chartPlugins}></Chart>
      </Panel>
    </>
  );
}
