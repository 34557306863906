// @flow
import React, {useState, useEffect, useRef} from 'react';
// import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// import classNames from 'classnames';

//$FlowFixMe[cannot-resolve-module]
import {confirmDialog} from 'primereact/confirmdialog';
//$FlowFixMe[cannot-resolve-module]
import {Toast} from 'primereact/toast';
//$FlowFixMe[cannot-resolve-module]
import {Panel} from 'primereact/panel';
//$FlowFixMe[cannot-resolve-module]
import {InputText} from 'primereact/inputtext';
//$FlowFixMe[cannot-resolve-module]
import {InputTextarea} from 'primereact/inputtextarea';
//$FlowFixMe[cannot-resolve-module]
import {Button} from 'primereact/button';
// import {AutoComplete} from 'primereact/autocomplete';
import type {ApiProps} from '../../service/Api';
import type {EvtHandler} from '../types';
// import {AuthUtils} from '../../service/AuthUtils';
import {UIUtils, Utils} from '../../service/Utils';
import {AdminApi} from '../../service/AdminApi';
//$FlowFixMe[cannot-resolve-module]
import {AutoComplete} from 'primereact/autocomplete';
//$FlowFixMe[cannot-resolve-module]
import {Checkbox} from 'primereact/checkbox';

type Props = {
  readOnly?: boolean,
  leanAudItem: any,
  cutOffDate: any,
  onAction: (evt: any) => void,
};

const defLeanAudItem = {
  __cls__: 'com.nb.lmrs.model.entity.PfaAudItem',
  flag: 0,
  code: '',
  name: '',
  description: '',
  processType: null,
  applicable: false,
  qtyAvailableMachine: null,
  qtyImplementation: null,
  qtyRequiredMachine: null,
  adoptionRate: null,
  massProductionDate: null,
  isStrength: null,
  isComformance: null,
  isOfi: null,
  complianceWorkInstruction: null,
  compliancePms: null,
  assessmentWorkInstruction: null,
  assessmentPms: null,
};

export function PfaAudItemFormII(props: Props): React$Node {
  const conf = useSelector((state) => state.settings);
  const auth = useSelector((state) => state.auth);

  const {readOnly, leanAudItem, cutOffDate} = props;
  const [editLeanAudItem, setEditLeanAudItem] = useState<any>();
  const [suggDepartments, setSuggDepartments] = useState([]);
  const [suggCompliances, setSuggCompliances] = useState([]);
  const toastRef = useRef();
  const apiRef = useRef<?AdminApi>();
  console.log('cutOffDate', cutOffDate);
  useEffect(() => {
    let apiProps: ApiProps = {
      ...conf,
      token: auth.token,
    };
    let api = new AdminApi(apiProps);
    apiRef.current = api;
  }, [conf, auth]);

  useEffect(() => {
    // //console.log.*$
    let obj = leanAudItem || {};
    obj = {
      ...defLeanAudItem,
      ...obj,
    };

    obj = Utils.adjustPercField(
      obj,
      ['evaluation', 'c2bWg', 'sfWg', 'offlineWg', 'osPressWg'],
      true,
    );
    let {massProductionDate} = obj;
    if (massProductionDate) {
      obj = {
        ...obj,
        massProductionDate: Utils.toJsDate(massProductionDate),
      };
    }

    setEditLeanAudItem(obj);
  }, [leanAudItem]);



  const _processValueChanged: (fd: string, val: any) => any = (
    fd: string,
    val: any,
  ) => {
    let newIt = {...editLeanAudItem};
    newIt[fd] = val;
    console.log('newIt', newIt);
    setEditLeanAudItem(newIt);
  };

  const _validateLeanAudItem: () => any = () => {
    let obj = {...editLeanAudItem};

    return obj;
  };

  const _onSave: EvtHandler = (evt: any) => {
    // setShowCfmDlg(false);
    let fac = _validateLeanAudItem();
    if (!fac) {
      return;
    }
    let api: ?AdminApi = apiRef.current;
    if (!api) {
      return;
    }
    confirmDialog({
      message: fac.id
        ? 'Are you sure you want to update leanAudItem type?'
        : 'Are you sure you want to create leanAudItem type?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        api
          .updatePfaAuditItem({...fac})
          .then((resp) => resp.data)
          .then((resp) => {
            let {errors, data} = resp;
            if (errors.length > 0) {
              //console.log.*$
              UIUtils.showWarning({
                summary: 'Warning',
                detail: String(errors[0].message),
                toast: toastRef.current,
              });
              let {onAction} = props;
              onAction &&
                onAction({
                  type: 'auditItemSaved',
                  value: data.entity,
                });
              return;
            }
            let {onAction} = props;
            onAction &&
              onAction({
                type: 'auditItemSaved',
                value: data.entity,
              });
            UIUtils.showInfo({detail: 'Success', toast: toastRef.current});
          })
          .catch((error) => {
            //console.log.*$
            UIUtils.showError({error, toast: toastRef.current});
          });
      },
      reject: () => {},
    });
  };

  const _onSuggDepartments = () => {
    setSuggDepartments([
      {
        label: 'C2B',
        value: 'C2B',
      },
      {
        label: 'STOCKFIT',
        value: 'STOCKFIT',
      },
      {
        label: 'OUTSOLE',
        value: 'OUTSOLE',
      },
      {
        label: 'TREATMENT',
        value: 'TREATMENT',
      },
      {
        label: 'OTHER',
        value: 'OTHER',
      },
      {
        label: 'OTHERMACHINEINPUT',
        value: 'OTHERMACHINEINPUT',
      },
    ]);
  };
  const _onSuggCompliances = () => {
    setSuggCompliances([
      {
        label: '0',
        value: '0',
      },
      {
        label: '0.25',
        value: 0.25,
      },
      {
        label: '0.5',
        value: 0.5,
      },
      {
        label: '0.75',
        value: 0.75,
      },
      {
        label: '1.0',
        value: 1,
      },
    ]);
  };

  if (!editLeanAudItem) {
    return null;
  }

  const saveLbl = editLeanAudItem.id
    ? 'Update leanAudItem type'
    : 'Create leanAudItem type';
  const isNew = !editLeanAudItem.id;
  // //console.log.*$

  return (
    <>
      <Toast ref={toastRef} />
      <Panel header="New/edit leanAudItem type">
        <div className="p-grid py-3 p-fluid">
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editLeanAudItem.code}
                onChange={(e) => {
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    code: e.target.value,
                  });
                }}
                disabled={!isNew || readOnly}
              />
              <label>Code</label>
            </span>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                value={editLeanAudItem.name}
                onChange={(e) => {
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    name: e.target.value,
                  });
                }}
                // onKeyDown={_onKeyDown}
                disabled={readOnly}
              />
              <label>Name</label>
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <AutoComplete
                placeholder="Select process type"
                dropdown
                suggestions={suggDepartments}
                field="label"
                value={editLeanAudItem.processType}
                completeMethod={_onSuggDepartments}
                onChange={(e) =>
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    processType: e.value.value,
                  })
                }
                disabled={readOnly}
              />
            </span>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <Checkbox
              value={editLeanAudItem.applicable}
              onChange={(e) => _processValueChanged('applicable', e.checked)}
              checked={editLeanAudItem.applicable}
            />
            <label className="p-checkbox-label ml-1">Applicable</label>
          </div>

          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <AutoComplete
                placeholder="Select compliance work instruction"
                dropdown
                suggestions={suggCompliances}
                field="label"
                value={editLeanAudItem.complianceWorkInstruction}
                completeMethod={_onSuggCompliances}
                onChange={(e) =>
                  _processValueChanged(
                    'complianceWorkInstruction',
                    e.value.value,
                  )
                }
                disabled={readOnly}
              />
            </span>
          </div>
          <div className="p-col-12 p-xl-6 p-lg-6">
            <span className="p-float-label">
              <AutoComplete
                placeholder="Select compliance PMS"
                dropdown
                suggestions={suggCompliances}
                field="label"
                value={editLeanAudItem.compliancePms}
                completeMethod={_onSuggCompliances}
                onChange={(e) =>
                  _processValueChanged('compliancePms', e.value.value)
                }
                disabled={readOnly}
              />
            </span>
          </div>

          <div className="p-col-12 p-xl-4 p-lg-4">
            <Checkbox
              value={editLeanAudItem.isStrength}
              onChange={(e) => _processValueChanged('isStrength', e.checked)}
              checked={editLeanAudItem.isStrength}
            />
            <label className="p-checkbox-label ml-1">Strength</label>
          </div>
          <div className="p-col-12 p-xl-4 p-lg-4">
            <Checkbox
              value={editLeanAudItem.isConformance}
              onChange={(e) => _processValueChanged('isConformance', e.checked)}
              checked={editLeanAudItem.isConformance}
            />
            <label className="p-checkbox-label ml-1">Conformance</label>
          </div>
          <div className="p-col-12 p-xl-4 p-lg-4">
            <Checkbox
              value={editLeanAudItem.isOfi}
              onChange={(e) => _processValueChanged('isOfi', e.checked)}
              checked={editLeanAudItem.isOfi}
            />
            <label className="p-checkbox-label ml-1">OFI</label>
          </div>
          <div className="p-col-12 p-xl-4 p-lg-4">
            <Checkbox
              value={editLeanAudItem.isMinor}
              onChange={(e) => _processValueChanged('isMinor', e.checked)}
              checked={editLeanAudItem.isMinor}
            />
            <label className="p-checkbox-label ml-1">Minor</label>
          </div>
          <div className="p-col-12 p-xl-4 p-lg-4">
            <Checkbox
              value={editLeanAudItem.isMajor}
              onChange={(e) => _processValueChanged('isMajor', e.checked)}
              checked={editLeanAudItem.isMajor}
            />
            <label className="p-checkbox-label ml-1">Major</label>
          </div>

          <div className="p-col-12">
            <span className="p-float-label">
              <InputTextarea
                rows={2}
                value={editLeanAudItem.comment}
                onChange={(e) => {
                  setEditLeanAudItem({
                    ...editLeanAudItem,
                    comment: e.target.value,
                  });
                }}
                disabled={readOnly}
              />
              <label>Observation/Comment</label>
            </span>
          </div>
        </div>
      </Panel>
      <div className="p-d-flex p-jc-end p-my-2">
        {!readOnly && (
          <Button label={saveLbl} icon="pi pi-save" onClick={_onSave} />
        )}
      </div>
    </>
  );
}
